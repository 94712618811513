import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderNavItemComponent } from "./header-nav-item.component";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TranslateModule } from "@ngx-translate/core";
import { NzBadgeModule } from "ng-zorro-antd/badge";

@NgModule({
  declarations: [HeaderNavItemComponent],
  imports: [CommonModule, NzIconModule, TranslateModule, NzBadgeModule],
  exports: [HeaderNavItemComponent],
})
export class HeaderNavItemModule {}
