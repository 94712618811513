import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MissionPartner } from "../../../../../../api/sign-up/signup.types";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "mh-profile-invoice-address",
  templateUrl: "./profile-invoice-address.component.html",
  styleUrls: ["./profile-invoice-address.component.less"],
})
export class ProfileInvoiceAddressComponent implements OnInit, OnDestroy {
  @Input() missionPartner!: MissionPartner;
  @Output() formChange = new EventEmitter<MissionPartner>();
  @Output() submitChange = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      invoiceAddress: this.fb.group({
        companyName: [this.missionPartner.invoiceAddress?.companyName, []],
        contactPersonName: [this.missionPartner.invoiceAddress?.contactPersonName, []],
        reference: [this.missionPartner.invoiceAddress?.reference, []],
        costCenter: [this.missionPartner.invoiceAddress?.costCenter, []],
        addressLine1: [this.missionPartner.invoiceAddress?.addressLine1, []],
        addressLine2: [this.missionPartner.invoiceAddress?.addressLine2, []],
        postalCode: [this.missionPartner.invoiceAddress?.postalCode, []],
        city: [this.missionPartner.invoiceAddress?.city, []],
        country: [this.missionPartner.invoiceAddress?.country, []],
      }),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.missionPartner,
        invoiceAddress: {
          ...this.missionPartner.invoiceAddress,
          ...value.invoiceAddress,
        },
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if (!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
