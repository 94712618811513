<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{'notification-settings.title' | translate}}</nz-page-header-title>
</nz-page-header>

<nz-divider nzPlain [nzText]="'notification-settings.new-message.title' | translate" nzOrientation="left"></nz-divider>

<div nz-row [nzGutter]="[12, 12]">
  <div nz-col [nzSpan]="24">
    <div *ngFor="let item of notificationsSettings?.email">
      <label nz-checkbox [(ngModel)]="item.enabled">{{'notification-settings.email.label' | translate}}</label>
    </div>
    <div *ngFor="let item of notificationsSettings?.sms">
      <label nz-checkbox [(ngModel)]="item.enabled">{{'notification-settings.sms.label' | translate}}</label>
    </div>
  </div>

  <div nz-col [nzSpan]="24">
    <button nz-button [nzType]="'primary'" (click)="updateNotificationSettings()">{{'save.button' | translate}}</button>
  </div>
</div>
