<nz-tabset>
  <nz-tab nzTitle="{{'job-offer.active.tab' | translate}}">
    <ng-template nz-tab>
      <mh-job-offer-list-active [jobOfferList]="jobOfferListActive" (archiveJobOffer)="onArchive($event)" [hasReviewedStatus]="hasReviewedStatus"></mh-job-offer-list-active>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="{{'job-offer.archived.tab' | translate}}">
    <ng-template nz-tab>
      <mh-job-offer-list-archived [jobOfferList]="jobOfferListArchived" (unArchiveJobOffer)="onUnArchive($event)"></mh-job-offer-list-archived>
    </ng-template>
  </nz-tab>
</nz-tabset>
