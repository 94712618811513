import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { TalentProfileCompleteness } from "../../../../../api/dashboard/dashboard.types";
import { Subject, take, takeUntil } from "rxjs";
import { CmsService } from "../../../../../api/cms/cms.service";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "@momhunting/core";

@Component({
  selector: "mh-talent-dashboard-status-box",
  templateUrl: "./talent-dashboard-status-box.component.html",
  styleUrls: ["./talent-dashboard-status-box.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TalentDashboardStatusBoxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() completeness!: TalentProfileCompleteness;
  content: string | undefined = '';
  destroy$ = new Subject<void>();

  constructor(
    private readonly cmsService: CmsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly localeService: LocaleService,
  ) {}

  ngOnInit() {
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.loadCmsContent();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['completeness'] && !changes['completeness'].firstChange) {
      this.loadCmsContent();
    }
  }

  loadCmsContent() {
    const boxName = this.resolveBoxName();

    this.cmsService.loadBox(boxName, this.localeService.currentLocale)
      .pipe(take(1))
      .subscribe((content) => {
        this.content = boxName === 'TalentDashboardProfileIncomplete' ? this.generateMissingFieldsHTML(content) : content;
        this.changeDetectorRef.markForCheck();
      });
  }

  private resolveBoxName() {
    if (+this.completeness.completenessPercentage === 100 && this.completeness.isProfileFilledOut) {
      return 'TalentDashboardProfileComplete';
    } else if (this.completeness.isProfileFilledOut) {
      return 'TalentDashboardProfilePartial';
    } else {
      return 'TalentDashboardProfileIncomplete';
    }
  }

  private generateMissingFieldsHTML(content: string): string {
    let missingFieldsList = this.completeness.sections.flatMap((section) => section.unfilledOutFields);

    const limit = content.match(/\{\{ missingFieldsList \| \{limit: (\d+)} }}/);
    if (limit && limit[1]) {
      missingFieldsList = missingFieldsList.slice(0, +limit[1]);
    }

    const missingFieldsHTML = `<ul class="missing-fields-list">${missingFieldsList.map((field) => `<li>${this.translateService.instant('profile.missing.'+field)}</li>`).join('')}</ul>`;
    return content.replace(/\{\{ missingFieldsList \| \{limit: \d+} }}/g, missingFieldsHTML);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
