import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SalaryExpectationInputComponent } from "./salary-expectation-input.component";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzSelectModule} from "ng-zorro-antd/select";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NzFormModule} from "ng-zorro-antd/form";
import {ExplanationLabelModule} from "../explanation-label/explanation-label.module";

@NgModule({
  declarations: [SalaryExpectationInputComponent],
    imports: [CommonModule, NzGridModule, NzSelectModule, ReactiveFormsModule, TranslateModule, NzFormModule, ExplanationLabelModule],
  exports: [
    SalaryExpectationInputComponent
  ]
})
export class SalaryExpectationInputModule {}
