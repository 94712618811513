import { Injectable } from "@angular/core";
import { ReferencesApi } from "./references.api";
import {map, Observable} from "rxjs";
import {CreateReferenceRequest, Reference, ReferenceRequest} from "./references.types";
import {ReferencesSerializer} from "./references.serializer";

@Injectable({ providedIn: "root" })
export class ReferencesService {
  constructor(
    private referencesApi: ReferencesApi,
    private referencesSerializer: ReferencesSerializer
  ) {}

  createReferenceRequest(referenceRequest: CreateReferenceRequest): Observable<boolean> {
    return this.referencesApi.createReferenceRequest(referenceRequest);
  }

  loadReferences(): Observable<Array<Reference>> {
    return this.referencesApi.loadReferences().pipe(map((dto) => this.referencesSerializer.deserializeReferences(dto)));
  }

  loadReferencesRequest(): Observable<Array<ReferenceRequest>> {
    return this.referencesApi.loadReferencesRequest().pipe(map((dto) => this.referencesSerializer.deserializeReferencesRequest(dto)));
  }

  deleteReference(referenceId: string): Observable<boolean> {
    return this.referencesApi.deleteReference(referenceId);
  }

  deleteReferenceRequest(referenceId: string): Observable<boolean> {
    return this.referencesApi.deleteReferenceRequest(referenceId);
  }

  resendReferenceRequest(referenceId: string): Observable<boolean> {
    return this.referencesApi.resendReferenceRequest(referenceId);
  }

  confirmReferenceRequest(referenceText: string, confirmed: boolean, authToken: string): Observable<boolean> {
    return this.referencesApi.confirmReferenceRequest(referenceText, confirmed, authToken);
  }

  validationReferenceRequest(token: string): Observable<boolean> {
    return this.referencesApi.validationReferenceRequest(token);
  }
}
