import { Injectable } from "@angular/core";
import { UserApi } from "./user.api";
import { map, Observable } from "rxjs";
import { MissionPartnerData, TalentData } from "../sign-up/signup.types";
import { ProfileSerializer } from "../profile/profile.serializer";

@Injectable({ providedIn: "root" })
export class UserService {
  constructor(
    private userApi: UserApi,
    private profileSerializer: ProfileSerializer,
  ) {}

  getTalentUserById(id: string): Observable<TalentData> {
    return this.userApi.getTalentUserById(id).pipe(map(dto => this.profileSerializer.deserializeTalentData(dto)));
  }

  getMissionPartnerUserById(id: string): Observable<MissionPartnerData> {
    return this.userApi.getMissionPartnerUserById(id).pipe(map(dto => this.profileSerializer.deserializeMissionPartnerData(dto)));
  }

  getMyNewsletter(): Observable<boolean> {
    return this.userApi.getMyNewsletter();
  }

  postNewsletterEnabled(enabled: boolean): Observable<boolean> {
    return this.userApi.postNewsletterEnabled(enabled);
  }
}
