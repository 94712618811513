import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingPageComponent } from "./landing-page.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzCardModule } from "ng-zorro-antd/card";
import {RouterLink} from "@angular/router";
import {NzImageModule} from "ng-zorro-antd/image";
import {LocaleSelectModule} from "@momhunting/common-ui";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NzButtonModule,
        NzLayoutModule,
        NzDividerModule,
        NzFormModule,
        NzCardModule,
        NzMenuModule,
        NzIconModule,
        RouterLink,
        NzImageModule,
        LocaleSelectModule,
    ],
  declarations: [LandingPageComponent],
  exports: [LandingPageComponent],
})
export class LandingPageModule {}
