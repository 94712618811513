import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerMyVisitsComponent } from "./mission-partner-my-visits.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { RouterLink } from "@angular/router";
import { CreateConversationDialogModule } from "../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.module";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { TagsOutputModule } from "libs/common-ui/src/lib/tags-output/tags-output.module";
import { LocationLabelModule } from "../../../../../../../common-ui/src/lib/location-label/location-label.module";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

@NgModule({
  declarations: [MissionPartnerMyVisitsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzPageHeaderModule,
    NzGridModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzAvatarModule,
    NzCardModule,
    NzToolTipModule,
    RouterLink,
    CreateConversationDialogModule,
    TagsOutputModule,
    LocationLabelModule,
  ],
})
export class MissionPartnerMyVisitsModule {}
