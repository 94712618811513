import { Component, Input } from "@angular/core";

@Component({
  selector: "mh-profile-preview-navigation",
  templateUrl: "./profile-preview-navigation.component.html",
  styleUrls: ["./profile-preview-navigation.component.less"],
})
export class ProfilePreviewNavigationComponent {
  @Input() isMobile: boolean | null = false;
}
