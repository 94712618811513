import { Component, OnDestroy, OnInit } from "@angular/core";
import { catchError, EMPTY, Subject, takeUntil } from "rxjs";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TestimonialsService } from "../../api/testimonials/testimonials.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-make-testimonials",
  templateUrl: "./make-testimonials.component.html",
  styleUrls: ["./make-testimonials.component.less"],
})
export class MakeTestimonialsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  public formGroup!: FormGroup;
  private token?: string;

  public authorName = "";
  public targetName = "";

  public testimonialComplete = false;
  public testimonialRejected = false;

  get testimonialTextControl(): AbstractControl | null {
    return this.formGroup.get("testimonialText");
  }

  constructor(
    private fb: FormBuilder,
    private testimonialsService: TestimonialsService,
    private route: ActivatedRoute,
    private readonly router: Router,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.translateService.instant('make-testimonial.testimonial.label') }));
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      testimonialText: [undefined, [Validators.required]],
    });
    this.validationToken();
    this.authorName = decodeURIComponent(this.route.snapshot.queryParamMap.get("author") || "").replace(/ /g, "\u00A0");
    this.targetName = decodeURIComponent(this.route.snapshot.queryParamMap.get("target") || "").replace(/ /g, "\u00A0");
  }

  private validationToken() {
    const testimonialToken = this.route.snapshot.queryParamMap.get("testimonialToken");
    if (testimonialToken) {
      this.testimonialsService
        .validationTestimonialRequest(testimonialToken)
        .pipe(takeUntil(this.destroy$))
        .pipe(
          catchError((err) => {
            this.router.navigate(["/expired-token"]);
            return EMPTY;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.token = testimonialToken;
          } else {
            this.router.navigate(["/expired-token"]);
          }
        });
    }
  }

  onMake() {
    if (!this.token) return;
    this.testimonialsService
      .confirmTestimonialRequest(this.testimonialTextControl?.value, true, this.token)
      .subscribe((result) => {
        this.testimonialComplete = result;
      });
  }

  onReject() {
    if (!this.token) return;
    this.testimonialsService
      .confirmTestimonialRequest(this.testimonialTextControl?.value, false, this.token)
      .subscribe((result) => {
        this.testimonialRejected = result;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
