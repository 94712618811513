import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { JobOfferService } from "../../../../../../api/job-offer/job-offer.service";
import { JobOfferAction } from "./job-offer.action";

@Injectable()
export class JobOfferEffects {
  constructor(private actions$: Actions, private jobOfferService: JobOfferService) {}

  loadJobOfferList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.loadJobOfferList),
      switchMap((action) =>
        this.jobOfferService.loadMyJobOfferList().pipe(
          map((response) => JobOfferAction.loadJobOfferListSuccess({ data: response })),
          catchError((error) => of(JobOfferAction.loadJobOfferListSuccessError({ error }))),
        ),
      ),
    );
  });

  loadJobOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.loadJobOffer),
      switchMap(({ id }) =>
        this.jobOfferService.loadJobOffer(id).pipe(
          map((response) => JobOfferAction.loadJobOfferSuccess({ data: response })),
          catchError((error) => of(JobOfferAction.loadJobOfferError({ error }))),
        ),
      ),
    );
  });

  createJobOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.createJobOffer),
      switchMap(({ entity }) =>
        this.jobOfferService.createJopOffer(entity).pipe(
          map((data) => JobOfferAction.createJobOfferSuccess({ data: entity })),
          catchError((error) => of(JobOfferAction.createJobOfferError({ error }))),
        ),
      ),
    );
  });

  updateJobOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.updateJobOffer),
      switchMap(({ entity }) =>
        this.jobOfferService.updateJobOffer(entity).pipe(
          map((data) => JobOfferAction.updateJobOfferSuccess({ data: entity })),
          catchError((error) => of(JobOfferAction.updateJobOfferError({ error }))),
        ),
      ),
    );
  });

  archiveJobOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.archiveJobOffer),
      switchMap(({ entity }) => {
        entity = { ...entity, archived: true };
        return this.jobOfferService.archiveJobOffer(entity.id).pipe(
          map((success) => JobOfferAction.archiveJobOfferSuccess({ entity })),
          catchError((error) => of(JobOfferAction.archiveJobOfferError({ error }))),
        );
      }),
    );
  });

  unArchiveJobOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.unArchiveJobOffer),
      switchMap(({ entity }) => {
        entity = { ...entity, archived: false };
        return this.jobOfferService.unArchiveJobOffer(entity).pipe(
          map((data) => JobOfferAction.unArchiveJobOfferSuccess({ entity })),
          catchError((error) => of(JobOfferAction.unArchiveJobOfferError({ error }))),
        );
      }),
    );
  });

  loadJobOfferMatchedTalentList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.loadJobOfferMatchedTalentList),
      switchMap(({ filter }) =>
        this.jobOfferService.loadJobOfferMatchedTalentList(filter).pipe(
          map((response) => JobOfferAction.loadJobOfferMatchedTalentListSuccess({ data: response })),
          catchError((error) => of(JobOfferAction.loadJobOfferMatchedTalentListError({ error }))),
        ),
      ),
    );
  });

  archiveJobOfferMatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.archiveJobOfferMatch),
      switchMap(({ jobOfferId, entity }) => {
        return this.jobOfferService.archiveJobOfferMatch( jobOfferId, entity.accountId).pipe(
          map((success) => JobOfferAction.archiveJobOfferMatchSuccess({ entity })),
          catchError((error) => of(JobOfferAction.archiveJobOfferMatchError({ error }))),
        );
      }),
    );
  });

  unArchiveJobOfferMatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(JobOfferAction.unArchiveJobOfferMatch),
      switchMap(({ jobOfferId, entity }) => {
        return this.jobOfferService.unArchiveJobOfferMatch( jobOfferId, entity.accountId).pipe(
          map((success) => JobOfferAction.unArchiveJobOfferMatchSuccess({ entity })),
          catchError((error) => of(JobOfferAction.unArchiveJobOfferMatchError({ error }))),
        );
      }),
    );
  });
}
