import { ForwardPage, PageName, PageParam } from "../../common-componnets/common-types";

export function getForwardPageUrl(forwardPage: ForwardPage) {
  let uri = "";
  switch (forwardPage.page) {
    case PageName.TALENT_DASHBOARD:
      uri = "talent/dashboard";
      break;
    case PageName.TALENT_INBOX:
      uri = "talent/inbox";
      break;
    case PageName.TALENT_INBOX_CONVERSATION:
      uri = "talent/inbox/conversation/" + forwardPage.pageParams[PageParam.CONVERSATION_ID];
      break;
    case PageName.TALENT_PROFILE:
      uri = "talent/profile";
      break;
    case PageName.TALENT_MISSION_PARTNERS:
      uri = "talent/mission-partners";
      break;
    case PageName.TALENT_REFERENCES:
      uri = "talent/references";
      break;
    case PageName.MP_DASHBOARD:
      uri = "mission-partner/dashboard";
      break;
    case PageName.MP_JOB_OFFERS:
      uri = "mission-partner/job-offers";
      break;
    case PageName.MP_JOB_OFFER_MATCHES:
      uri = "mission-partner/job-offers/matches/" + forwardPage.pageParams[PageParam.JOB_OFFER_ID];
      break;
    case PageName.MP_INBOX:
      uri = "mission-partner/inbox";
      break;
    case PageName.MP_INBOX_CONVERSATION:
      uri = "mission-partner/inbox/conversation/" + forwardPage.pageParams[PageParam.CONVERSATION_ID];
      break;
    case PageName.MP_PROFILE:
      uri = "mission-partner/profile";
      break;
    case PageName.MP_ASSESSMENT:
      uri = "mission-partner/assessment";
      break;
    case PageName.MP_MICRO_SITE:
      uri = "mission-partner/micro-site";
      break;
    case PageName.MP_INVOICES:
      uri = "mission-partner/invoices";
      break;
    case PageName.MP_VIEW_TALENT: {
      const talentId = forwardPage.pageParams[PageParam.TALENT_ID];
      const jobOfferId = forwardPage.pageParams[PageParam.JOB_OFFER_ID];
      const openConversation = forwardPage.pageParams[PageParam.OPEN_CONVERSATION];

      uri = `mission-partner/view-talent/${talentId}`;

      const queryParams = [];
      if (jobOfferId) {
        queryParams.push(`jobOfferId=${jobOfferId}`);
      }
      if (openConversation) {
        queryParams.push("openConversation=true");
      }

      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`;
      }

      break;
    }
    case PageName.MP_TESTIMONIALS:
      uri = "mission-partner/testimonials";
      break;
  }
  return "/" + uri;
}
