<nz-badge [nzCount]="unreadCount" [nzSize]="mobile?'small':'default'" [nzOffset]="(mobile?[-5,5]:[-10, 10])" [nzOverflowCount]="15">
  <button nz-button nzType="text" [nzSize]="mobile?'default':'large'" nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
    <span nz-icon nzType="bell" nzTheme="outline"></span>
  </button>
</nz-badge>
<nz-dropdown-menu #menu1="nzDropdownMenu">
  <ul nz-menu *ngIf="unreadCount">
    <li nz-menu-item (click)="handleNotificationClick(notification)"
      *ngFor="let notification of limitedNotifications">
      <ng-container *ngIf="notification.type === MhNotificationType.CV_PARSED">
        <span class="dot" [ngClass]="{read: notification.read}"></span> <nz-tag nzColor="default">{{notification.createdAt | date: 'dd.MM.YYYY HH:mm'}}</nz-tag> {{'notification.cv-parsed.label' | translate}}
      </ng-container>
    </li>
  </ul>
</nz-dropdown-menu>
