import { Component, Input } from "@angular/core";
import { DataSpaceAttribute } from "../../../api/dataspace/dataspace.types";
import { format } from 'date-fns';

@Component({
  selector: "mh-data-space-attribute-view",
  templateUrl: "./data-space-attribute-view.component.html",
  styleUrls: ["./data-space-attribute-view.component.less"],
})
export class DataSpaceAttributeViewComponent {
  @Input() attr!: DataSpaceAttribute;

  get attrValue() {
    if(!this.attr) return '';
    return this.getAttrValue(this.attr);
  }

  private getAttrValue(attr: DataSpaceAttribute) {
    if (attr.name.includes('TIMESTAMP')) {
      return format(new Date(attr.value as number), 'dd.MM.yyyy HH:mm');
    }
    return attr.valueAsString;
  }
}
