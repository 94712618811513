import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { filter, map, Observable, take, tap } from "rxjs";
import { ActivatedRouteSnapshot } from "@angular/router";
import { messagesFeature, MessagesState } from "./store/messages.reducer";
import { Conversation } from "../../api/messages/messages.types";
import { MessagesAction } from "./store/messages.action";
import { selectConversationState } from "./store/messages.selectors";

@Injectable({
  providedIn: "root",
})
export class MessagesConversationResolver {
  constructor(private readonly store: Store<{ [messagesFeature]: MessagesState }>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Conversation | undefined> {
    return this.getFromStoreOrAPI(route.params["id"]);
  }

  getFromStoreOrAPI(id: string): Observable<Conversation | undefined> {
    return this.store
      .select(selectConversationState)
      .pipe(
        tap((conversationState) => {
          if (!conversationState.status.loading) {
            this.store.dispatch(MessagesAction.loadConversation({id}));
          }
        }),
        filter((conversationState) => !conversationState.status.loading),
        map((conversationState) => conversationState.data),
        take(1),
      )
  }
}
