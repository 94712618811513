import { Data } from "@angular/router";

export interface ApiResponseDTO {
  success: boolean;
  message: string;
  errorKey: string;
  results: any;
}

export interface ApiPaginatedResponseDTO<T> {
  success: boolean;
  message: string;
  errorKey: string;
  results: ResponseData<T>;
}

export interface ResponseData<T> {
  data: PaginatedContent<T>;
}

export interface PaginatedContent<T> {
  paging: {
    page: number;
    itemsOnPage: number;
    items: number;
  };
  searchTerm?: string;
  timeRange?: Array<Data>;
  sort?: {
    direction: "DESC" | "ASC";
    field: string;
  };
  items: Array<T>;
  additionalData?: any;
}

export const initialPaginatedContent = {
  paging: {
    page: 1,
    itemsOnPage: 10,
    items: 0,
  },
  items: [],
};

export type StatusState = {
  loaded: boolean;
  loading: boolean;
  error: unknown;
  id?: string;
};

export const initialStatusState: StatusState = {
  loading: false,
  loaded: false,
  error: null,
};
