import { Component, Input } from "@angular/core";
import { ParticipantInfo } from "../../../../../api/messages/messages.types";
import { AccountType } from "../../../../../pages/login-page/login-page-data/login.types";

@Component({
  selector: "mh-chat-participant-info",
  templateUrl: "./mh-chat-participant-info.component.html",
  styleUrls: ["./mh-chat-participant-info.component.less"],
})
export class MhChatParticipantInfoComponent {
  @Input() participantProfile: ParticipantInfo | undefined;
  @Input() accountType: AccountType | undefined;
  protected readonly AccountType = AccountType;

  get workExperienceYearsLabel() {
    return this.participantProfile?.workExperience || 0 > 1 ? 'years.label' : 'year.label';
  }

  get leadershipYearsLabel() {
    return this.participantProfile?.leadershipExperience || 0 > 1 ? 'years.label' : 'year.label';
  }
}
