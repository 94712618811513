<nz-page-header nzTitle="{{'mp.profile.company-details.sub-title' | translate}}"></nz-page-header>
<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24">
    <ng-container formGroupName="companyOverview">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.number-of-employees.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select nzSize="default" formControlName="numberOfEmployees" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of numberOfEmployees$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.company-industry.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select nzSize="default" formControlName="companyIndustry" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of industry$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.company-sector.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select nzSize="default" formControlName="companySector" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of sector$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.company-type.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select nzSize="default" formControlName="companyType" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of companyType$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.company-headquarters.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select nzSize="default" formControlName="companyHeadquarters" [compareWith]="compareCmsNameValue">
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of companyHeadquarters$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save-and-next.button' | translate}}</button>
    </div>
  </div>
</form>
