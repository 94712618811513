import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileAudioUploadComponent } from "./profile-audio-upload.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TranslateModule } from "@ngx-translate/core";
import { AudioRecordingService } from "../../../../../core/src/lib/recording/audio-recording.service";

@NgModule({
  declarations: [ProfileAudioUploadComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzIconModule,
    TranslateModule,
  ],
  exports: [
    ProfileAudioUploadComponent
  ],
  providers: [AudioRecordingService],
})
export class ProfileAudioUploadModule {
}
