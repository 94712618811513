import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotificationSettingsComponent } from "./notification-settings.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { FormsModule } from "@angular/forms";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";

@NgModule({
  declarations: [NotificationSettingsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzPageHeaderModule,
    NzDividerModule,
    TranslateModule,
    NzCheckboxModule,
    FormsModule,
    NzGridModule,
    NzButtonModule,
  ],
  exports: [NotificationSettingsComponent],
})
export class NotificationSettingsModule {}
