<nz-select
  nzShowSearch
  [nzAllowClear]="true"
  [nzPlaceHolder]="'admin.jo.country.label' | translate"
  [(ngModel)]="selectedValue"
  [nzShowArrow]="false"
  [nzFilterOption]="nzFilterOption"
  (nzOnSearch)="search($event)"
  (ngModelChange)="onLocationSelected($event)"
  [disabled]="disabled"
  [ngClass]="{'ant-select-status-error': isError}"
>
  <nz-option *ngFor="let o of listOfOption" [nzLabel]="o.name" [nzValue]="o.iso2"></nz-option>
</nz-select>
