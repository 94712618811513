import { Component, Input } from "@angular/core";
import { AccountDataSpace } from "../../api/dataspace/dataspace.types";

@Component({
  selector: "mh-admin-talent-data-spaces",
  templateUrl: "./admin-talent-data-spaces.component.html",
  styleUrls: ["./admin-talent-data-spaces.component.less"],
})
export class AdminTalentDataSpacesComponent {
  @Input() dataSpace: Array<AccountDataSpace> = [];
}
