import { AbstractControl, ValidationErrors } from "@angular/forms"

export const UpperCaseRequiredValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null
  }

  let upperCaseCharacters = /[A-Z]+/g;
  if (!upperCaseCharacters.test(value)) {
    return { upperCaseRequired: `Upper case required` };
  }

  return null;
}
