import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Talent } from "../../../../../../api/sign-up/signup.types";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { CmsNameValue, compareCmsNameValue } from "../../../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../../../api/cms/cms-cached.service";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: "mh-profile-mom-skills",
  templateUrl: "./profile-mom-skills.component.html",
  styleUrls: ["./profile-mom-skills.component.less"],
})
export class ProfileMomSkillsComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<boolean>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public momSkillOptions$: Observable<Array<CmsNameValue>>;

  constructor(private fb: FormBuilder,
              private cmsCachedService: CmsCachedService,
              private translateService: TranslateService,
              private notificationService: NzNotificationService) {
    this.momSkillOptions$ = this.cmsCachedService.getUserDataByName("mom-skill");
  }

  getMomSkillsControl(): AbstractControl | null {
    return this.formGroup.get('momSkills');
  }

  onMomSkillsChange(event: Array<CmsNameValue>) {
    if(this.getMomSkillsControl()?.value.length > 3) {
      this.notificationService.warning('', this.translateService.instant('max-allowed-num.warning', {max: 3}));
      this.getMomSkillsControl()?.setValue(event.slice(0, 3));
    };
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      momSkills: [this.talent.momSkills, [Validators.maxLength(3)]],
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        ...value,
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
