import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { SimpleLocation } from "../../../../platform-pages/src/lib/api/google-geocoding/google-geocoding.types";

export function LocationMultipleLargeInputValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as Array<SimpleLocation>;

    if (!value || value.length == 0) {
      return { required: true };
    }

    const invalidItems = value.filter((item) => !ValidateSimpleLocation(item));

    return invalidItems.length > 0 ? { required: true } : null;
  };
}

export function ValidateSimpleLocation(location: SimpleLocation): boolean {
  if (!location) return false;
  const hasPostalCode = !!location.postalCode;
  const hasCity = !!location.city;
  const hasCountry = !!location.country;

  return hasPostalCode && hasCity && hasCountry;
}

export const DuplicatePostalCodeValidator = function(locations: Array<SimpleLocation>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value || !locations?.length) {
      return null;
    }

    const hasDuplicates = locations.some((item) => item.postalCode === value);

    return hasDuplicates ? { duplicatePostalCode: true } : null;
  };
}
