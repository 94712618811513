import {Component, Inject, Input} from '@angular/core';
import { LocaleService } from '@momhunting/core';
import {APP_CONFIGURATION} from "../../../../core/src/lib/core.di";
import {AppConfig} from "../../../../core/src/lib/app-config/config.types";

@Component({
  selector: 'mh-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.less'],
})
export class LocaleSelectComponent {
  @Input() mode: 'default' | 'dropdown' = 'default';

  currentLocale = '';

  constructor(protected readonly localeService: LocaleService,
              @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig) {
    this.currentLocale = this.localeService.currentLocale
  }

  onModelChange(){
    this.localeService.setLocale(this.currentLocale);
  }
}
