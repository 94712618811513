<nz-page-header [nzTitle]="'mp.compliance-kpi.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <h3>{{'profile.compliance-kpi.subtitle' | translate}}</h3>
  <h4>{{'profile.compliance-kpi.text' | translate}}</h4>

  <div nz-row [nzGutter]="24" formGroupName="complianceKpi">

    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-1.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion1"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-2.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion2"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-3.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion3"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-4.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion4"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-5.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion5"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-6.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion6"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-7.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion7"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-8.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion8"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-9.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion9"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.question-10.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="kpiQuestion10"/>
        </nz-form-control>
      </nz-form-item>
    </div>

  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save-and-next.button' | translate}}</button>
    </div>
  </div>
</form>
