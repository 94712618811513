import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { messagesFeature, MessagesState } from "../messages/store/messages.reducer";
import { SocketService } from "../../../../../core/src/lib/soket/socket.service";
import { selectUnseenMessageCount } from "../messages/store/messages.selectors";
import { MessagesAction } from "../messages/store/messages.action";
import { MhNotificationType, MhSocketMessage } from "../../api/notification/notification.types";
import { Router } from "@angular/router";

@Component({
  selector: "mh-header-unseen-messages-icon",
  templateUrl: "./header-unseen-messages-icon.component.html",
  styleUrls: ["./header-unseen-messages-icon.component.less"],
})
export class HeaderUnseenMessagesIconComponent implements OnInit, OnDestroy {
  @Input() routerLink!: Array<any> | string;
  badgeCount: number | undefined | NzSafeAny;
  private conversationId?: string | null;
  private isInboxPage = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<{ [messagesFeature]: MessagesState }>,
    private socketService: SocketService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.store
      .select(selectUnseenMessageCount)
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => (this.badgeCount = count));
    this.store.dispatch(MessagesAction.getUnseenMessageCount());
    this.socketService.messages$.pipe(takeUntil(this.destroy$)).subscribe((message: MhSocketMessage) => {
      this.checkCurrentUrl();
      if (
        message.action === MhNotificationType.COMMUNICATION_MESSAGE
        && !this.conversationId && message.message?.conversationId !== this.conversationId
        &&!this.isInboxPage
      ) {
        this.store.dispatch(MessagesAction.increaseUnseenMessageCount());
      }
    });
  }

  private checkCurrentUrl() {
    const url = this.router.url;
    this.conversationId = url?.includes("inbox/conversation") ? url.substring(url.lastIndexOf("/") + 1) : "";
    this.isInboxPage = url?.endsWith("/inbox");
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
