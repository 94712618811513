import { AbstractControl, ValidationErrors } from "@angular/forms"

export const NumberRequiredValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null
  }

  let numberCharacters = /[0-9]+/g;

  if (!numberCharacters.test(value)) {
    return { numberRequired: `Number required` };
  }

  return null;
}
