import { Injectable } from '@angular/core';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import * as RecordRTC from 'recordrtc';
import { Observable, Subject } from 'rxjs';
import { RecordedVideoOutput } from './recording.types';

@Injectable()
export class VideoRecordingService {
  private stream: MediaStream | null = null;
  private recorder: any;
  private interval: any;
  private startTime: Date | null = null;
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  private _videoStream = new Subject<MediaStream | null>();

  getVideoStream(): Observable<MediaStream | null> {
    return this._videoStream.asObservable();
  }

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.recorder) {
        reject('Recording is already in progress.');
        return;
      }

      this._recordingTime.next('00:00');
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          this.stream = stream;
          this.record();
          resolve(this.stream);
        })
        .catch((error) => {
          this._recordingFailed.next('Recording failed');
          reject(error);
        });
    });
  }


  abortRecording() {
    this.stopMedia();
  }

  private record() {
    if (!this.stream) {
      return;
    }
    this.recorder = new RecordRTC.MediaStreamRecorder(this.stream, {
      type: 'video',
      mimeType: 'video/webm',
    });

    this.recorder.record();
    this.startTime = new Date();
    this.interval = setInterval(() => {
      const currentTime = new Date();
      const diffTimeMinutes = differenceInMinutes(currentTime, this.startTime!);
      const diffTimeSeconds = differenceInSeconds(currentTime, this.startTime!) % 60;
      const time =
        this.toString(diffTimeMinutes) +
        ':' +
        this.toString(diffTimeSeconds);
      this._recordingTime.next(time);
    }, 500);
  }

  private toString(value: number | string): string {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (+value < 10) {
      val = '0' + value;
    }
    return val.toString();
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop(
        (blob: Blob) => {
          if (this.startTime) {
            const videoName = encodeURIComponent(
              'video_' + new Date().getTime() + '.webm'
            );
            this.stopMedia();
            this._recorded.next({ blob: blob, title: videoName });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next('Recording failed');
        }
      );
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        this.stream = null;
      }
    }
  }
}
