import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MissionPartner } from "../../../../../../api/sign-up/signup.types";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { CmsNameValue, compareCmsNameValue } from "../../../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../../../api/cms/cms-cached.service";

@Component({
  selector: "mh-profile-company-details",
  templateUrl: "./profile-company-details.component.html",
  styleUrls: ["./profile-company-details.component.less"],
})
export class ProfileCompanyDetailsComponent implements OnInit, OnDestroy {
  @Input() missionPartner!: MissionPartner;
  @Output() formChange = new EventEmitter<MissionPartner>();
  @Output() submitChange = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public numberOfEmployees$: Observable<Array<CmsNameValue>>;
  public industry$: Observable<Array<CmsNameValue>>;
  public sector$: Observable<Array<CmsNameValue>>;
  public companyType$: Observable<Array<CmsNameValue>>;
  public companyHeadquarters$: Observable<Array<CmsNameValue>>;

  constructor(private cmsCachedService: CmsCachedService,
              private fb: FormBuilder) {
    this.numberOfEmployees$ = this.cmsCachedService.getUserDataByName("number-of-employees", false);
    this.industry$ = this.cmsCachedService.getUserDataByName("industry");
    this.sector$ = this.cmsCachedService.getUserDataByName("sector");
    this.companyType$ = this.cmsCachedService.getUserDataByName("company-type");
    this.companyHeadquarters$ = this.cmsCachedService.getUserDataByName("company-headquarters", false);
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      companyOverview: this.fb.group({
        numberOfEmployees: [this.missionPartner.companyOverview?.numberOfEmployees, []],
        companyIndustry: [this.missionPartner.companyOverview?.companyIndustry, []],
        companySector: [this.missionPartner.companyOverview?.companySector, []],
        companyType: [this.missionPartner.companyOverview?.companyType, []],
        companyHeadquarters: [this.missionPartner.companyOverview?.companyHeadquarters, []],
      }),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.missionPartner,
        companyOverview: {
          ...this.missionPartner.companyOverview,
          ...value.companyOverview,
        },
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
