import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Invoice } from "../../../../api/invoices/invoices.types";
import { InvoicesService } from "../../../../api/invoices/invoices.service";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { map, Observable, take, tap } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { BreakpointObserver } from "@angular/cdk/layout";

@Component({
  selector: "mh-mission-partner-invoices",
  templateUrl: "./mission-partner-invoices.component.html",
  styleUrls: ["./mission-partner-invoices.component.less"],
})
export class MissionPartnerInvoicesComponent implements OnInit {
  invoiceList?: Array<Invoice>;
  loading = true;
  preventDoubleFetch = true;

  isMobileView$: Observable<boolean>;

  constructor(
    private invoicesService: InvoicesService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit(): void {
    this.fetchInvoiceList();
  }

  fetchInvoiceList() {
    this.loading = true;
    this.invoicesService.loadInvoiceListMy()
      .pipe(take(1))
      .subscribe((invoices) => {
        this.invoiceList = invoices;
        this.loading = false;
      });
  }

  onChangeStatus(status: string, invoiceId?: string) {
    if (invoiceId) {
      this.invoicesService.changeInvoiceStatus(invoiceId, status)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.notificationService.success("", this.translateService.instant("invoices.change-status.success"));
          }
      });
    }
  }
}
