import { Injectable } from "@angular/core";
import { format } from "date-fns";
import {ReferenceDTO, ReferenceRequestDTO} from "./references-api.types";
import {Reference, ReferenceRequest} from "./references.types";

@Injectable({ providedIn: "root" })
export class ReferencesSerializer {
  deserializeReferences(dtos: Array<ReferenceDTO>): Array<Reference> {
    return dtos.map((dto) => this.deserializeReference(dto));
  }

  deserializeReference(dto: ReferenceDTO): Reference {
    return {
      ...dto,
      creationTimestamp: format(new Date(dto.creationTimestamp), 'yyyy-MM-dd HH:mm:ss'),
    };
  }

  deserializeReferencesRequest(dtos: Array<ReferenceRequestDTO>): Array<ReferenceRequest> {
    return dtos.map((dto) => this.deserializeReferenceRequest(dto));
  }

  deserializeReferenceRequest(dto: ReferenceRequestDTO): ReferenceRequest {
    return {
      ...dto,
      creationTimestamp: format(new Date(dto.creationTimestamp), 'yyyy-MM-dd HH:mm:ss'),
    };
  }
}
