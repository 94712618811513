import { AccountId } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";

export interface RecordedAudioOutput {
  blob: Blob | null;
  title: string;
}

export interface AudioFile {
  id: string;
  name: string;
  accountId: AccountId;
  fileUrl: string;
  fileType: string;
  createdAt: number;
  updatedAt: number;
}

export interface RecordedVideoOutput {
  blob: Blob | null;
  title: string;
}

export interface PresentationMedia {
  id: string;
  name: string;
  accountId: AccountId;
  fileUrl: string;
  fileType: string;
  createdAt: number;
  updatedAt: number;
}

export enum MediaType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}
