<ng-container *ngIf="appConfig.featureStatus.englishSite" [ngTemplateOutlet]="mode == 'default' ? modeDefault : modeDropdown"></ng-container>
<ng-template #modeDefault>
  <nz-radio-group [(ngModel)]="currentLocale" (ngModelChange)="onModelChange()">
    <label nz-radio-button nzValue="en"><span>EN</span></label>
    <label nz-radio-button nzValue="de"><span>DE</span></label>
    <label nz-radio-button nzValue="keys" *ngIf="appConfig.featureStatus.showKeysSwitch"><span>keys</span></label>
  </nz-radio-group>
</ng-template>

<ng-template #modeDropdown>
  <nz-select [(ngModel)]="currentLocale" (ngModelChange)="onModelChange()">
    <nz-option nzLabel="EN" nzValue="en"></nz-option>
    <nz-option nzLabel="DE" nzValue="de"></nz-option>
    <nz-option nzLabel="keys" nzValue="keys" *ngIf="appConfig.featureStatus.showKeysSwitch"></nz-option>
  </nz-select>
</ng-template>
