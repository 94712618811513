<nz-layout class="auth">
  <div nz-row class="auth__container">
    <div nz-col nzXs="0" nzMd="12" class="auth__bg">
      <div class="auth__bg-img"></div>
    </div>
    <div nz-col nzXs="24" nzMd="12" class="auth__content">

      <mh-locale-select mode="dropdown"></mh-locale-select>

      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24" class="auth__logo">
          <img class="logo-img" src="/assets/images/logo.svg" alt="Momhunting logo" />
        </div>
        <h1 class="auth__title" nz-col nzSpan="24">{{'login.title' | translate}}</h1>
        <form nz-form class="auth__form" [formGroup]="formGroup" (ngSubmit)="onLoginFormSubmit()">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>{{'login.email.label' | translate}}</nz-form-label>
            <nz-form-control [nzSpan]="24" nzDisableAutoTips [nzErrorTip]="'field.required.label' | translate">
              <input nz-input nzSize="large" formControlName="login" [placeholder]="'login.email.placeholder' | translate" data-lpshow="true" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{'login.password.label' | translate}}</nz-form-label>
            <nz-form-control [nzSpan]="24" nzDisableAutoTips [nzErrorTip]="'login.password.required' | translate">
              <input nz-input nzSize="large" type="password" formControlName="password" [placeholder]="'login.password.placeholder' | translate" data-lpshow="true" />
            </nz-form-control>
            <div class="auth__forgot-btn">
              <a [routerLink]="['/password-forgotten']">{{'login.forgot-password.btn' | translate}}</a>
            </div>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzSpan]="24">
              <button nz-button nzType="primary" nzSize="large" nzBlock>{{'login.submit.btn' | translate}}</button>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzSpan]="24">
              <a [routerLink]="[appConfig.featureStatus.mpForMp? '/landing':'/sign-up-talent']"  nz-button nzType="link" nzSize="large" nzBlock>{{'login.register.btn' | translate}}</a>
            </nz-form-control>
          </nz-form-item>

          <!--<div role="alert" *ngIf="errorResponse" class="auth__error-message">{{ errorResponse ?? "toast.general.error" | translate }}</div>-->
        </form>
      </div>
    </div>
  </div>
</nz-layout>
