import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Education } from "../../api/sign-up/signup.types";

export function EducationsInputValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as Array<Education>;

    if (!value || value.length == 0) {
      return { required: true };
    }

    const invalidItems = value.filter((item) => !ValidateEducation(item));

    return invalidItems.length > 0 ? { required: true } : null;
  };
}

export function ValidateEducation(education: Education): boolean {
  if (!education) return false;
  //const hasGraduationYear = !!education.graduationYear;
  //const hasUniversityName = education.universityName?.length > 0;
  //const hasDegree = !!education.degree;
  //const hasCourseOfStudy = !!education.courseOfStudy;

  return true;
}
