import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentDashboardComponent } from "./talent-dashboard.component";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { TalentDashboardCompletenessModule } from "./talent-dashboard-completeness/talent-dashboard-completeness.module";
import { TranslateModule } from "@ngx-translate/core";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { TalentDashboardStatusBoxModule } from "./talent-dashboard-status-text-box/talent-dashboard-status-box.module";
import { TalentDashboardSearchingStatusModule } from "./talent-dashboard-searching-status/talent-dashboard-searching-status.module";
import { TalentDashboardNotificationSettingsModule } from "./talent-dashboard-notification-settings/talent-dashboard-notification-settings.module";

@NgModule({
  declarations: [TalentDashboardComponent],
  imports: [
    CommonModule,
    NzCardModule,
    NzFormModule,
    NzProgressModule,
    NzButtonModule,
    RouterLink,
    TalentDashboardCompletenessModule,
    TalentDashboardSearchingStatusModule,
    TranslateModule,
    NzPageHeaderModule,
    TalentDashboardStatusBoxModule,
    TalentDashboardNotificationSettingsModule,
  ],
})
export class TalentDashboardModule {}
