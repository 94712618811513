import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { missionPartnerProfileReducer, missionPartnerProfileFeatureName } from "./mission-partner-profile.reducer";
import { MissionPartnerProfileEffects } from "./mission-partner-profile-effects.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(missionPartnerProfileFeatureName, missionPartnerProfileReducer),
    EffectsModule.forFeature([MissionPartnerProfileEffects]),
  ],
})
export class MissionPartnerProfileStoreModule {}
