import { Injectable } from "@angular/core";
import { VisitMissionPartner, VisitMissionPartnerDTO, VisitTalent, VisitTalentDTO } from "./visits.types";
import { CommonService } from "../common.service";
import { CmsSerializer } from "../cms/cms.serializer";

@Injectable({
  providedIn: "root",
})
export class VisitsSerializer {
  constructor(private commonService: CommonService, private cmsSerializer: CmsSerializer) {}

  deserializeVisitsTalent(dtos: Array<VisitTalentDTO>): Array<VisitTalent> {
    return dtos.map((dto) => this.deserializeVisitTalent(dto));
  }

  deserializeVisitTalent(dto: VisitTalentDTO): VisitTalent {
    return {
      ...dto,
      missionPartnerLogo: this.commonService.toAbsUrl(dto.missionPartnerLogo),
    };
  }

  deserializeVisitsMissionPartner(dtos: Array<VisitMissionPartnerDTO>): Array<VisitMissionPartner> {
    return dtos.map((dto) => this.deserializeVisitMissionPartner(dto));
  }

  deserializeVisitMissionPartner(dto: VisitMissionPartnerDTO): VisitMissionPartner {
    return {
      ...dto,
      recipientInfo: {
        ...dto.recipientInfo,
        avatarUrl: this.commonService.toAbsUrl(dto.recipientInfo.avatar?.avatarUrl),
        domainExpertises: this.cmsSerializer.deserializeNameValues(dto.recipientInfo.domainExpertises),
      },
    };
  }
}
