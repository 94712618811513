import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MaturityAssessment, MaturityAssessmentStatus } from "../../../../api/assessment/assessment.types";

@Component({
  selector: "mh-admin-assessment-view",
  templateUrl: "./admin-assessment-view.component.html",
  styleUrls: ["./admin-assessment-view.component.less"],
})
export class AdminAssessmentViewComponent {
  assessment: MaturityAssessment | null;
  copied = false;
  constructor(private activatedRoute: ActivatedRoute) {
    this.assessment = this.activatedRoute.snapshot.data["assessment"];
  }
  onCopyToClipboard() {
    this.copied = true;
  }

  protected readonly MaturityAssessmentStatus = MaturityAssessmentStatus;
}
