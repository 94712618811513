import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RegisterTalentComponent } from "./register-talent.component";
import { RouterLink } from "@angular/router";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {NzStepsModule} from "ng-zorro-antd/steps";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {SignupTalentGeneralModule} from "./steps/signup-talent-general/signup-talent-general.module";
import {
  SignupTalentJobPreferencesModule
} from "./steps/signup-talent-job-preferences/signup-talent-job-preferences.module";
import {NzSelectModule} from "ng-zorro-antd/select";
import {SignupEmailConfirmModule} from "../register-common-steps/signup-email/signup-email-confirm.module";
import {SignupPhoneVerifyModule} from "../register-common-steps/signup-phone/signup-phone-verify.module";
import {LocaleSelectModule} from "@momhunting/common-ui";
import {
    ScrollToValidationErrorModule
} from "../../common-componnets/scroll-to-validation-error/scroll-to-validation-error.module";

@NgModule({
  declarations: [RegisterTalentComponent],
    imports: [
        CommonModule,
        RouterLink,
        NzLayoutModule,
        NzImageModule,
        NzButtonModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        NzWaveModule,
        ReactiveFormsModule,
        TranslateModule,
        NzStepsModule,
        NzCheckboxModule,
        SignupTalentGeneralModule,
        SignupTalentJobPreferencesModule,
        SignupEmailConfirmModule,
        SignupPhoneVerifyModule,
        NzSelectModule,
        LocaleSelectModule,
        ScrollToValidationErrorModule
    ],
})
export class RegisterTalentModule {}
