<ng-container *nzModalTitle>
  {{ "inbox.agreementTerms.title" | translate }}
</ng-container>
<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.companyName.label'"></mh-explanation-label>
        <nz-form-control>
          <input nz-input formControlName="companyName" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.position.label'"></mh-explanation-label>
        <nz-form-control>
          <input nz-input formControlName="position" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.date.label'"></mh-explanation-label>
        <nz-form-control>
          <nz-date-picker formControlName="date" style="width: 100%" nzPlaceHolder=" " nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.salary.label'"></mh-explanation-label>
        <nz-form-control>
          <nz-input-number nz-input formControlName="salary" style="width: 100%"></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.workingHours.label'"></mh-explanation-label>
        <nz-form-control>
          <nz-input-number nz-input formControlName="workingHours" style="width: 100%"></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.placeOfWork.label'"></mh-explanation-label>
        <nz-form-control>
          <input nz-input formControlName="placeOfWork" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onReject()">
    {{ "inbox.agreementTerms.reject.button" | translate }}
  </button>
  <button nz-button nzType="primary" (click)="onConfirm()">
    {{ "inbox.agreementTerms.confirm.button" | translate }}
  </button>
</ng-container>
