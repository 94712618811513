import {inject, Pipe, PipeTransform} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ParticipantInfo } from "../../api/messages/messages.types";
import {Observable, of} from "rxjs";

export const CONVERSATION_PRO_ACTIVE_TALENT_SIDE_TITLE = "conversation.pro.active.talent.side.title";
export const CONVERSATION_PRO_ACTIVE_MP_SIDE_TITLE = "conversation.pro.active.mp.side.title";
export const CONVERSATION_CLOSED_SYSTEM_MESSAGE = "conversation.closed.text";
export const CONVERSATION_CLOSED_BY_OTHER_SYSTEM_MESSAGE = "conversation.closed.by.other.text";

export const AGREEMENT_REACHED_SENDER= "conversation.agreement.reached.sender.text";
export const AGREEMENT_REACHED_RECIPIENT= "conversation.agreement.reached.recipient.text";

export const PROFILE_DETAILS_REQUEST_MP_SIDE = "conversation.profile.details.request.mp.side";
export const PROFILE_DETAILS_REQUEST_TALENT_SIDE = "conversation.profile.details.request.talent.side";
export const PROFILE_DETAILS_GRANT_ACCESS_TALENT_SIDE = "conversation.profile.details.grant.access.talent.side";
export const PROFILE_DETAILS_GRANT_ACCESS_MP_SIDE = "conversation.profile.details.grant.access.mp.side";
export const PROFILE_DETAILS_ACCESS_RESTRICTED_TALENT_SIDE =
  "conversation.profile.details.access.restricted.talent.side";
export const PROFILE_DETAILS_ACCESS_RESTRICTED_MP_SIDE = "conversation.profile.details.access.restricted.mp.side";

@Pipe({
  name: "conversationTranslate",
  standalone: true
})
export class ConversationTranslatePipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(value: string, participantInfo?: ParticipantInfo): Observable<string> {
    if (value.startsWith("conversation.")) {
      switch (value) {
        case PROFILE_DETAILS_GRANT_ACCESS_MP_SIDE:
        case PROFILE_DETAILS_REQUEST_MP_SIDE:
        case PROFILE_DETAILS_ACCESS_RESTRICTED_MP_SIDE: {
          return this.translateService.stream(value, { talentName: participantInfo?.companyName || participantInfo?.name });
        }
        case PROFILE_DETAILS_GRANT_ACCESS_TALENT_SIDE:
        case PROFILE_DETAILS_REQUEST_TALENT_SIDE:
        case PROFILE_DETAILS_ACCESS_RESTRICTED_TALENT_SIDE: {
          return this.translateService.stream(value, { mpName: participantInfo?.companyName || participantInfo?.name });
        }
        case AGREEMENT_REACHED_RECIPIENT: {
          return this.translateService.stream(value, { name: participantInfo?.companyName || participantInfo?.name });
        }
        default:
          return this.translateService.stream(value);
      }
    }
    return of(value);
  }
}
