import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileMediaUploadComponent } from "./profile-media-upload.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TranslateModule } from "@ngx-translate/core";
import { AudioRecordingService } from "../../../../../core/src/lib/recording/audio-recording.service";
import { VideoRecordingService } from "../../../../../core/src/lib/recording/video-recording.service";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { ExplanationLabelModule } from "../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSpinModule } from "ng-zorro-antd/spin";

@NgModule({
  declarations: [ProfileMediaUploadComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzIconModule,
    TranslateModule,
    NzUploadModule,
    ExplanationLabelModule,
    NzFormModule,
    NzSpinModule,
  ],
  exports: [
    ProfileMediaUploadComponent
  ],
  providers: [AudioRecordingService, VideoRecordingService],
})
export class ProfileMediaUploadModule {
}
