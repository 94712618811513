import { Injector } from "@angular/core";
import { ApiResponseDTO } from "./common.types";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";

export class BaseApi {
  protected notificationService: NzNotificationService;
  protected translateService: TranslateService;
  protected router: Router;

  constructor(injector: Injector) {
    this.notificationService = injector.get(NzNotificationService);
    this.router = injector.get(Router);
    this.translateService = injector.get(TranslateService);
  }

  handleErrorResponse(response: ApiResponseDTO) {
    const message = this.translateService.instant(response.errorKey ?? response.message);
    this.notificationService.error("", message);
    throw new Error(response.errorKey);
  }
  handleHttpError(error: HttpErrorResponse) {
    return throwError(() => new Error(JSON.stringify(error)));
  }
}
