<form class="admin-filters" nz-form [formGroup]="searchForm" (ngSubmit)="submitFilterForm()">
  <div class="admin-filters__fields">
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input type="text" formControlName="searchTerm" nz-input [placeholder]="'admin.filter.search.placeholder' | translate" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span *ngIf="!searchForm.get('searchTerm')?.value" nz-icon nzType="search"></span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchForm.get('searchTerm')?.value"
            (click)="searchForm.get('searchTerm')?.setValue(null)"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select [nzPlaceHolder]="'admin.filter.status.placeholder' | translate" formControlName="negotiationStatuses">
          <nz-option [nzLabel]="'admin.negotiation-status.all' | translate" [nzValue]="NegotiationStatus.ALL"></nz-option>
          <nz-option [nzLabel]="'admin.negotiation-status.initiated' | translate" [nzValue]="NegotiationStatus.INITIATED"></nz-option>
          <nz-option [nzLabel]="'admin.negotiation-status.active' | translate" [nzValue]="NegotiationStatus.ACTIVE"></nz-option>
          <nz-option [nzLabel]="'admin.negotiation-status.closed' | translate" [nzValue]="NegotiationStatus.CLOSED"></nz-option>
          <nz-option [nzLabel]="'admin.negotiation-status.mp-agreement-reached' | translate" [nzValue]="NegotiationStatus.MP_AGREEMENT_REACHED"></nz-option>
          <nz-option [nzLabel]="'admin.negotiation-status.talent-agreement-reached' | translate" [nzValue]="NegotiationStatus.TALENT_AGREEMENT_REACHED"></nz-option>
          <nz-option [nzLabel]="'admin.negotiation-status.completed' | translate" [nzValue]="NegotiationStatus.COMPLETED"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

  </div>
  <div class="admin-filters__actions">
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" [disabled]="!searchForm.valid">{{'admin.filter.submit' | translate}}</button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="negotiations?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="negotiations?.paging?.items || 0"
  [nzPageSize]="negotiations?.paging?.itemsOnPage || 0"
  [nzPageIndex]="negotiations?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th>{{ 'admin.initiator-name.title' | translate }}</th>
    <th>{{ 'admin.other-participant-name.title' | translate }}</th>
    <th>{{ 'admin.closer.title' | translate }}</th>
    <th>{{ 'admin.negotiation-status.title' | translate }}</th>
    <th>{{ 'admin.creation-timestamp.title' | translate }}</th>
    <th>{{ 'admin.update-timestamp.title' | translate }}</th>
    <th>{{ 'admin.duration.title' | translate }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of basicTable.data">
    <td>
      <div *ngIf="data.initiatorName" class="table-value">
        {{ data.initiatorName }}
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="(data.initiatorType === AccountType.TALENT ? 'admin.account-type.talent.tooltip' : 'admin.account-type.mission-partner.tooltip') | translate"
          nzTooltipPlacement="top"
          nz-tooltip
        >
          <span nz-icon [nzType]="data.initiatorType === AccountType.TALENT ? 'user' : 'bank'" nzTheme="outline"></span>
        </button>
      </div>
    </td>
    <td>
      <div *ngIf="data.otherParticipantName" class="table-value">
        {{ data.otherParticipantName }}
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="(data.otherParticipantType === AccountType.TALENT ? 'admin.account-type.talent.tooltip' : 'admin.account-type.mission-partner.tooltip') | translate"
          nzTooltipPlacement="top"
          nz-tooltip
        >
          <span nz-icon [nzType]="data.otherParticipantType === AccountType.TALENT ? 'user' : 'bank'" nzTheme="outline"></span>
        </button>
      </div>
    </td>
    <td>
      <div *ngIf="data.closer" class="table-value">
        {{ data.closer }}
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="(data.closerType === AccountType.TALENT ? 'admin.account-type.talent.tooltip' : 'admin.account-type.mission-partner.tooltip') | translate"
          nzTooltipPlacement="top"
          nz-tooltip
        >
          <span nz-icon [nzType]="data.closerType === AccountType.TALENT ? 'user' : 'bank'" nzTheme="outline"></span>
        </button>
      </div>
    </td>
    <td>
      <ng-container *ngIf="data.status === NegotiationStatus.INITIATED">
        {{ 'admin.negotiation-status.initiated' | translate }}
      </ng-container>
      <ng-container *ngIf="data.status === NegotiationStatus.ACTIVE">
        {{ 'admin.negotiation-status.active' | translate }}
      </ng-container>
      <ng-container *ngIf="data.status === NegotiationStatus.CLOSED">
        {{ 'admin.negotiation-status.closed' | translate }}
      </ng-container>
      <ng-container *ngIf="data.status === NegotiationStatus.MP_AGREEMENT_REACHED">
        {{ 'admin.negotiation-status.mp-agreement-reached' | translate }}
      </ng-container>
      <ng-container *ngIf="data.status === NegotiationStatus.TALENT_AGREEMENT_REACHED">
        {{ 'admin.negotiation-status.talent-agreement-reached' | translate }}
      </ng-container>
      <ng-container *ngIf="data.status === NegotiationStatus.COMPLETED">
        {{ 'admin.negotiation-status.completed' | translate }}
      </ng-container>
    </td>
    <td>
      <div class="nowrap">{{ data.creationTimestamp | date : "yyyy-MM-dd" }}</div>
      <div class="nowrap">{{ data.creationTimestamp | date : "HH:mm:ss" }}</div>
    </td>
    <td>
      <div class="nowrap">{{ data.updateTimestamp | date : "yyyy-MM-dd" }}</div>
      <div class="nowrap">{{ data.updateTimestamp | date : "HH:mm:ss" }}</div>
    </td>
    <td>
      <span>
        {{ data.formattedDuration }}
      </span>
    </td>
  </tr>
  </tbody>
</nz-table>
