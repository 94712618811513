<h1 class="auth__title" nz-col nzSpan="24">{{'reg.talent.email-confirmation.title' | translate}}</h1>

  <nz-form-item>
    {{'reg.talent.email-confirmation.text' | translate: {email: account?.email} }}
  </nz-form-item>

  <nz-form-item *ngIf="isGoogleEmail">
    <nz-form-control [nzSpan]="24">
      <a nz-button nzType="default" nzSize="large" nzBlock
         [href]="'https://mail.google.com/mail/u/' + account?.email" target="_blank">
        <span nz-icon nzType="google:logo"></span>
        {{'email.check.label' | translate}}
      </a>
    </nz-form-control>
  </nz-form-item>

  <nz-divider [nzText]="'email.resend-confirmation.divider' | translate"></nz-divider>

  <nz-form-item>
    <nz-form-control [nzSpan]="24">
      <button nz-button nzType="primary" nzSize="large" nzBlock
              (click)="onResendEmail()" [disabled]="disableResendEmail"
      >{{'email.resend-confirmation.label' | translate}}</button>
    </nz-form-control>
  </nz-form-item>
