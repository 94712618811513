<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'admin.invoices.form.mission-partner.label'"></mh-explanation-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            [nzDisabled]="!missionPartnerEditable"
            [nzPlaceHolder]="'select.placeholder' | translate"
            formControlName="missionPartnerId">
            <nz-option *ngFor="let option of missionPartners" [nzLabel]="option.companyName" [nzValue]="option.accountId.internalId"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'admin.invoices.form.number.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="number" [placeholder]="'input.placeholder' | translate" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'admin.invoices.form.date.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-date-picker formControlName="date" [nzPlaceHolder]="'datepicker.placeholder' | translate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'admin.invoices.form.amount.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input type="number" formControlName="amount" [placeholder]="'input.placeholder' | translate" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'admin.invoices.form.performance-month.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-date-picker nzMode="month" formControlName="performanceMonth" [nzPlaceHolder]="'datepicker.placeholder' | translate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <nz-upload
        class="upload-file"
        nzAccept=".pdf"
        [class.upload-file--error]="formGroup.get('invoiceFileId')?.touched && !fileList.length"
        [nzDisabled]="uploadDisabled"
        [nzType]="'drag'"
        nzListType="text"
        [(nzFileList)]="fileList"
        [nzBeforeUpload]="beforeUpload"
      >
        <ng-container *ngIf="!uploadDisabled">
          <p class="ant-upload-drag-icon">
            <span nz-icon nzType="inbox"></span>
          </p>
          <p class="ant-upload-text">{{'upload-drop-area.label' | translate}}</p>
        </ng-container>
      </nz-upload>
    </div>
  </div>
</form>
