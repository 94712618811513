import { Component, Input } from "@angular/core";

@Component({
  selector: "mh-micro-site-navigation",
  templateUrl: "./micro-site-navigation.component.html",
  styleUrls: ["./micro-site-navigation.component.less"],
})
export class MicroSiteNavigationComponent {
  @Input() isMobile: boolean | null = false;
}
