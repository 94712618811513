import { Component, forwardRef, Input } from "@angular/core";
import { CmsNameValue, compareCmsNameValue } from "../../../../platform-pages/src/lib/api/cms/cms.types";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn,
} from "@angular/forms";
import { SalaryExpectation } from "./salary-expectation.type";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "mh-salary-expectation-input",
  templateUrl: "./salary-expectation-input.component.html",
  styleUrls: ["./salary-expectation-input.component.less"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SalaryExpectationInputComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SalaryExpectationInputComponent,
    }
  ],
})
export class SalaryExpectationInputComponent implements ControlValueAccessor {
  @Input() nzSize: 'large' | 'default' | 'small' = 'default';
  @Input() usePlaceholder = false;
  @Input() limitMaxOptions = true;
  @Input() validators: ValidatorFn | null | undefined;
  @Input() set mark(isTouched: boolean) {
    if (isTouched) {
      Object.values(this.controlGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  @Input()
  set value(value: SalaryExpectation) {
    this._value = value;
    this.controlGroup?.patchValue(value, { emitEvent: false });
    if(value?.min) this.onSalaryMinChange(value.min);
    //check if specified value exist in generated options;
    const minOptionFound = this.salaryOptions.find(option => option.value == value.min?.value);
    if(!minOptionFound && value.min) {
      this.salaryOptions.push(value.min);
    }
    const maxOptionFound = this.salaryMaxOptions.find(option => option.value == value.max?.value);
    if(!maxOptionFound && value.max) {
      this.salaryMaxOptions.push(value.max);
    }
  }
  get value(): SalaryExpectation {
    return this._value;
  }
  private _value!: SalaryExpectation;

  controlGroup!: FormGroup;
  private destroy$ = new Subject<void>();

  public salaryOptions: Array<CmsNameValue>;
  public salaryMaxOptions: Array<CmsNameValue>;

  constructor(private fb: FormBuilder) {
    this.salaryOptions = this.populateSalaryOptions();
    this.salaryMaxOptions = [...this.salaryOptions];
  }

  ngOnInit() {
    this.createControlGroup();
  }

  private createControlGroup(): void {
    const currentValue: SalaryExpectation = this.value ? this.value : this.getEmpty();

    this.controlGroup = this.fb.group(
      {
        min: [currentValue.min],
        max: [currentValue.max],
      },
    );

    if (this.validators) {
      this.controlGroup.get('min')?.setValidators(this.validators);
      this.controlGroup.get('max')?.setValidators(this.validators);
    }

    this.controlGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.onChange(value)
    });
  }

  private getEmpty(): SalaryExpectation {
    return {
      min: undefined,
      max: undefined,
    };
  }

  onChange = (value: SalaryExpectation) => {};
  onTouched = () => {};

  writeValue(value: SalaryExpectation) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  onSalaryMinChange(current: CmsNameValue, clearMaxValue = false) {
    if (clearMaxValue) {
      this.controlGroup.get('max')?.setValue(null);
    }
    if (current?.value && this.limitMaxOptions) {
      this.salaryMaxOptions = this.populateSalaryMaxOptions(current.value);
    } else {
      this.salaryMaxOptions = [...this.salaryOptions];
    }
  }

  private populateSalaryMaxOptions(selectedMin: number): Array<CmsNameValue> {
    const options = [];
    let counter = selectedMin;
    let increment = 1;
    let maxValue = 970;
    if(selectedMin < 100) {
      maxValue = selectedMin + 10;
    }
    if(selectedMin >= 100){
      maxValue = selectedMin + 15;
    }

    while (counter < maxValue) {
      if (counter >= 100) increment = 5;
      if (counter >= 150) increment = 10;
      counter = counter + increment;
      options.push({value: counter, name: this.formatSalary(counter)});
    }
    return options;
  }

  private populateSalaryOptions(): Array<CmsNameValue> {
    const options = [];
    let counter = 20;
    let increment = 1;
    while (counter <= 980) {
      options.push({value: counter, name: this.formatSalary(counter)});
      if (counter >= 100) increment = 5;
      if (counter >= 150) increment = 10;
      counter = counter + increment;
    }
    return options;
  }

  private formatSalary(value: number): string {
    return value >= 1000 ? (value / 1000) + "M" : value + "K";
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const min = this.controlGroup.get('min');
    const max = this.controlGroup.get('max');

    return min?.valid && max?.valid ? null : { required: true };
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
