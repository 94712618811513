import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { JobOffer } from "../../api/job-offer/job-offer.types";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "mh-job-offer-matches-offer-view",
  templateUrl: "./job-offer-matches-offer-view.component.html",
  styleUrls: ["./job-offer-matches-offer-view.component.less"],
  animations: [
    trigger('showMore', [
      state('show', style({
        height: '*',
      })),
      state('hide', style({
        height: '63px',
      })),
      transition('show <=> hide', [
        animate('.7s')
      ]),
    ]),
  ],
})
export class JobOfferMatchesOfferViewComponent implements OnInit {
  @Input() jobOffer!: JobOffer;

  isExpanded = false;
  canExpand = false;

  @ViewChild('jobOfferDescription') jobOfferDescription?: ElementRef;

  ngOnInit() {
    setTimeout(() => {
      this.checkCanExpand();
    })
  }

  private checkCanExpand() {
    if (this.jobOfferDescription) {
      const contentHeight = this.jobOfferDescription.nativeElement.scrollHeight;
      this.canExpand = contentHeight > 63;
    }
  }

  toggleShowMore() {
    this.isExpanded = !this.isExpanded;
  }

}
