import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "mh-explanation-label",
  templateUrl: "./explanation-label.component.html",
  styleUrls: ["./explanation-label.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExplanationLabelComponent implements OnInit, OnDestroy {
  @Input() labelKey!: string;
  @Input() divider = false;
  explanationKey = "";
  hasExplanation = false;
  onLangChangeSub: Subscription;

  constructor(private translateService: TranslateService, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.onLangChangeSub = this.translateService.onLangChange.subscribe(() => {
      this.initExplanation();
    });
  }

  ngOnInit() {
    this.initExplanation();
  }

  private initExplanation() {
    this.explanationKey = `${this.labelKey}.info`;
    this.hasExplanation = this.translateService.instant(this.explanationKey) !== this.explanationKey;
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.onLangChangeSub.unsubscribe();
  }
}
