import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentDashboardCompletenessComponent } from "./talent-dashboard-completeness.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

@NgModule({
  declarations: [TalentDashboardCompletenessComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzCardModule,
    NzProgressModule,
    NzWaveModule,
    RouterLink,
    TranslateModule,
    NzIconModule,
    NzToolTipModule,
  ],
  exports: [
    TalentDashboardCompletenessComponent
  ]
})
export class TalentDashboardCompletenessModule {}
