import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MakeTestimonialsComponent } from "./make-testimonials.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { ExplanationLabelModule } from "../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { ReactiveFormsModule } from "@angular/forms";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzResultModule } from "ng-zorro-antd/result";

@NgModule({
  declarations: [MakeTestimonialsComponent],
    imports: [
      CommonModule,
      NzButtonModule,
      NzGridModule,
      NzLayoutModule,
      NzWaveModule,
      TranslateModule,
      RouterLink,
      ExplanationLabelModule,
      NzFormModule,
      NzInputModule,
      ReactiveFormsModule,
      NzModalModule,
      NzResultModule
    ],
})
export class MakeTestimonialsModule {}
