<ng-container *nzModalTitle>
  {{'modal.cv-parsed.title' | translate}}
</ng-container>

<h3>{{'modal.cv-parsed.educations.label' | translate}}</h3>
<mh-educations-input [value]="parsedCV.education" [showAddBtn]="false"></mh-educations-input>

<h3 style="margin-top: 24px">{{'modal.cv-parsed.track-record.label' | translate}}</h3>
<mh-track-records-input [value]="parsedCV.trackRecord" [showAddBtn]="false"></mh-track-records-input>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">{{'cancel.button' | translate}}</button>
  <button nz-button nzType="primary" (click)="onSave()" [disabled]="loading">{{'update.button' | translate}}</button>
</ng-container>
