import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SignupService } from "../../../api/sign-up/signup.service";
import {GoogleAnalyticsService} from "@hakimio/ngx-google-analytics";

@Component({
  selector: "mh-signup-phone-verify",
  templateUrl: "./signup-phone-verify.component.html",
  styleUrls: ["./signup-phone-verify.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupPhoneVerifyComponent implements OnInit {
  @Output() nextStep = new EventEmitter<void>();
  validateForm!: UntypedFormGroup;
  validationCode!: string;
  phoneSent = false;
  canSubmitCode = false;
  codeValidated = false;

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private cd: ChangeDetectorRef,
    private gaService: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      phoneNumber: [undefined, [Validators.required]],
    });
  }

  submitPhone(): void {
    if (this.validateForm.valid) {
      this.sendPhoneForValidation();
      this.gaService.event("sign_up_phone_attempt", { category: 'talent_sign_up', label: 'Phone number sent to validation'});
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  sendPhoneForValidation() {
    const phoneNumber = this.validateForm.value.phoneNumber;
    this.signupService.phoneValidationSend(phoneNumber).subscribe((result) => {
      if (result) {
        this.phoneSent = true;
        this.cd.markForCheck();
      }
    });
  }
  onCodeChanged(code: string) {
    this.validationCode = code;
    this.canSubmitCode = false;
  }
  onCodeCompleted(code: string) {
    this.validationCode = code;
    this.canSubmitCode = true;
    this.submitCode();
  }
  submitCode() {
    const phoneNumber = this.validateForm.value.phoneNumber;
    this.signupService.phoneValidationCheck(phoneNumber, this.validationCode).subscribe((result) => {
      if (result) {
        this.gaService.event("sign_up_phone_success", {category: 'talent_sign_up', label: 'Phone number validated with a code'});
        this.codeValidated = true;
        this.cd.markForCheck();
        this.goToNextStep();
      } else {
        this.gaService.event("sign_up_phone_validation_fail", {category: 'talent_sign_up', label: 'Phone validation code invalid'});
      }
    });
  }

  onChangePhoneNumber() {
    this.phoneSent = false;
  }

  goToNextStep() {
    this.nextStep.emit();
  }
}
