import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { NotificationApi } from "./notification.api";
import { MhNotification, MhNotificationSettings } from "./notification.types";

@Injectable({ providedIn: "root" })
export class NotificationService {
  constructor(private notificationApi: NotificationApi) {}

  loadNotificationList(): Observable<Array<MhNotification>> {
    return this.notificationApi
      .loadNotificationList()
      .pipe(
        map((dtos) => dtos.sort((a, b) => b.createdAt - a.createdAt)),
      );
  }

  markReadNotifications(ids: Array<string>): Observable<boolean> {
    return this.notificationApi.markReadNotifications(ids);
  }

  loadNotificationSettings(): Observable<MhNotificationSettings> {
    return this.notificationApi.loadNotificationSettings();
  }

  saveNotificationSettings(settings: MhNotificationSettings): Observable<boolean> {
    return this.notificationApi.saveNotificationSettings(settings);
  }
}
