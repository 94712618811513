<ng-container
  *ngIf="navItem"
  [ngTemplateOutlet]="
    navItem.badgeType == 'unseenMessages' ? navItemWithBadge :
    navItem.badgeType == 'profileFilledOut' && profileCompleteness?.isProfileFilledOut ? navItemWithSuccessBadge :
    regularNavItem"
></ng-container>

<ng-template #regularNavItem>
  <span *ngIf="navItem.icon" nz-icon [nzType]="navItem.icon" nzTheme="outline"></span>
  {{ navItem.nameKey | translate }}
</ng-template>
<ng-template #navItemWithBadge>
  <nz-badge nzSize="small" [nzCount]="badgeCount" [nzOverflowCount]="9" [nzOffset]="[5,-2]">
    <span *ngIf="navItem.icon" nz-icon [nzType]="navItem.icon" nzTheme="outline"></span>
    {{ navItem.nameKey | translate }}
  </nz-badge>
</ng-template>
<ng-template #navItemWithSuccessBadge>
  <nz-badge nzSize="small" [nzCount]="iconSuccess" [nzOffset]="[5,-2]">
    <span *ngIf="navItem.icon" nz-icon [nzType]="navItem.icon" nzTheme="outline"></span>
    {{ navItem.nameKey | translate }}
  </nz-badge>
  <ng-template #iconSuccess>
    <span nz-icon nzType="check-circle" nzTheme="fill" class="ant-scroll-number-custom-component"></span>
  </ng-template>
</ng-template>
