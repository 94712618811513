import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "../../../../../api/user/user.service";
import { Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: "mh-talent-settings-newsletter",
  templateUrl: "./talent-settings-newsletter.component.html",
  styleUrls: ["./talent-settings-newsletter.component.less"],
})
export class TalentSettingsNewsletterComponent implements OnInit, OnDestroy {
  @Input() newsletterEnabled!: boolean;
  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private translateService: TranslateService,
    private notificationService: NzNotificationService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      newsletter: [this.newsletterEnabled, []],
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.updateNewsletterEnabled(value.newsletter));
  }

  private updateNewsletterEnabled(enabled: boolean) {
    this.userService.postNewsletterEnabled(enabled).subscribe((result) => {
      if (result) this.notificationService.success("", this.translateService.instant("profile.updated.notification"));
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
