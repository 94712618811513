import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupMissionPartnerDetailsComponent } from "./signup-mission-partner-details.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { LocationInputModule } from "../../../../../../../common-ui/src/lib/location-input/location-input.module";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { ExplanationLabelModule } from "libs/common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [SignupMissionPartnerDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzFormModule,
    NzGridModule,
    NzWaveModule,
    TranslateModule,
    LocationInputModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    ExplanationLabelModule,
  ],
  exports: [SignupMissionPartnerDetailsComponent],
})
export class SignupMissionPartnerDetailsModule {}
