import { Injectable } from "@angular/core";
import {
  AccessGrant,
  AccessGrantDTO,
  AccountId,
  AgreementTerms,
  AgreementTermsDTO,
  Conversation,
  ConversationDTO,
  ConversationNegotiation,
  ConversationNegotiationDTO,
  MessagingAttachment,
  MessagingAttachmentDTO,
  MessagingMessage,
  MessagingMessageDTO,
  ParticipantInfo,
  ParticipantInfoDTO,
} from "./messages.types";
import { CommonService } from "../common.service";
import { CmsSerializer } from "../cms/cms.serializer";

@Injectable({
  providedIn: "root",
})
export class MessagesSerializer {
  constructor(private commonService: CommonService, private cmsSerializer: CmsSerializer) {}

  deserializeConversations(dtos: Array<ConversationDTO>, ownId: string): Array<Conversation> {
    return dtos.map((dto) => this.deserializeConversation(dto, ownId));
  }

  deserializeConversation(dto: ConversationDTO, ownId: string): Conversation {
    return {
      ...dto,
      otherParticipantId: dto.otherParticipantId.internalId,
      ownerId: dto.ownerId.internalId,
      creationTimestamp: new Date(dto.creationTimestamp),
      lastMessageTimestamp: new Date(dto.lastMessageTimestamp),
      recipientInfo: dto.recipientInfo ? this.deserializeRecipientInfo(dto.recipientInfo) : undefined,
      ownerInfo: dto.ownerInfo ? this.deserializeRecipientInfo(dto.ownerInfo) : undefined,
      messages: this.deserializeMessagingMessages(dto.messages, ownId),
      isOwner: dto.ownerId.internalId === ownId,
      accessGrant: dto.accessGrant ? this.deserializeAccessGrant(dto.accessGrant) : undefined,
      negotiation: dto.negotiation ? this.deserializeNegotiation(dto.negotiation) : undefined,
    };
  }

  deserializeRecipientInfo(dto: ParticipantInfoDTO): ParticipantInfo {
    return {
      ...dto,
      avatarUrl: this.commonService.toAbsUrl(dto.avatar?.avatarUrl),
      domainExpertises: this.cmsSerializer.deserializeNameValues(dto.domainExpertises),
    };
  }

  deserializeAccessGrant(dto: AccessGrantDTO): AccessGrant {
    return {
      ...dto,
      talentId: dto.talentId?.internalId,
      missionPartnerId: dto.missionPartnerId?.internalId,
    };
  }

  deserializeMessagingMessages(dtos: Array<MessagingMessageDTO>, ownerId?: string): Array<MessagingMessage> {
    return dtos?.map((message) => this.deserializeMessagingMessage(message, ownerId)) || [];
  }

  deserializeMessagingMessage(dto: MessagingMessageDTO, ownerId?: string): MessagingMessage {
    return {
      ...dto,
      senderId: this.accountIdToString(dto.senderId),
      creationTimestamp: dto.creationTimestamp ? new Date(dto.creationTimestamp) : new Date(),
      ownMessage: ownerId ? this.accountIdToString(dto.senderId) === ownerId : false,
      attachment: dto.attachment ? this.deserializeMessageAttachment(dto.attachment) : undefined,
    };
  }

  deserializeMessageAttachment(dto: MessagingAttachmentDTO): MessagingAttachment {
    return {
      ...dto,
      fileUrl: this.commonService.toAbsUrl(dto.fileUrl),
      createdAt: dto.createdAt ? new Date(dto.createdAt) : new Date(),
      updatedAt: dto.updatedAt ? new Date(dto.updatedAt) : new Date(),
    };
  }

  deserializeNegotiation(dto: ConversationNegotiationDTO): ConversationNegotiation | undefined {
    if (!dto) {
      return undefined;
    }
    return {
      ...dto,
      creationTimestamp: dto.creationTimestamp ? new Date(dto.creationTimestamp) : new Date(),
      updatedTimestamp: dto.updatedTimestamp ? new Date(dto.updatedTimestamp) : new Date(),
      closer: this.accountIdToString(dto.closer),
      initiator: this.accountIdToString(dto.initiator),
      missionPartnerId: this.accountIdToString(dto.missionPartnerId),
      talentId: this.accountIdToString(dto.talentId),
      agreementTerms: dto.agreementTerms ? this.deserializeAgreementTerms(dto.agreementTerms) : undefined,
    };
  }

  accountIdToString(accountId: string | AccountId | undefined) {
    if (!accountId) {
      return "";
    }
    if (typeof accountId === "string") {
      return accountId;
    } else {
      return accountId.internalId;
    }
  }

  deserializeAgreementTerms(dto: AgreementTermsDTO): AgreementTerms {
    return {
      ...dto,
      date: dto.date ? new Date(+dto.date) : null,
    };
  }

  serializeAgreementTerms(terms: AgreementTerms): AgreementTermsDTO {
    return {
      ...terms,
      date: terms.date?.getTime() || 0,
    };
  }
}
