import { Injectable } from "@angular/core";
import { ProfileApi } from "./profile.api";
import { map, Observable } from "rxjs";
import { KnownFrom, MissionPartner, Talent, TalentData } from "../sign-up/signup.types";
import { ProfileSerializer } from "./profile.serializer";
import { AccountFilter } from "../../pages/home-admin/home-admin-data/account.types";
import { PaginatedContent } from "../common.types";
import { AccountSerializer } from "../../pages/home-admin/home-admin-data/account.serializer";
import { MissionPartnerDTO } from "../sign-up/signup-api.types";
import {AccessGrant} from "../messages/messages.types";
import {MessagesSerializer} from "../messages/messages-serializer.service";

@Injectable({ providedIn: "root" })
export class ProfileService {
  constructor(
    private profileApi: ProfileApi,
    private profileSerializer: ProfileSerializer,
    private accountSerializer: AccountSerializer,
    private messagesSerializer: MessagesSerializer
  ) {}

  loadTalentProfile(): Observable<Talent> {
    return this.profileApi.loadTalentProfile().pipe(map((dto) => this.profileSerializer.deserializeTalent(dto)));
  }

  loadMissionPartnerProfile(): Observable<MissionPartner> {
    return this.profileApi
      .loadMissionPartnerProfile()
      .pipe(map((dto) => this.profileSerializer.deserializeMissionPartner(dto)));
  }

  talentUpdate(user: Talent): Observable<boolean> {
    return this.profileApi.talentUpdate(this.profileSerializer.serializeTalent(user));
  }

  missionPartnerUpdate(user: MissionPartner): Observable<boolean> {
    return this.profileApi.missionPartnerUpdate(this.profileSerializer.serializeMissionPartner(user));
  }

  uploadUserAvatar(formData: FormData): Observable<boolean> {
    return this.profileApi.uploadUserAvatar(formData);
  }

  updateOpenStatus(open: boolean): Observable<boolean> {
    return this.profileApi.updateOpenStatus(open);
  }

  loadFieldSuggestions(searchTerm: string, fieldName: string): Observable<Array<string>> {
    return this.profileApi.loadFieldSuggestions(searchTerm, fieldName);
  }

  linkedInAuth(code: string): Observable<any> {
    return this.profileApi.linkedInAuth(code);
  }

  loadTalentProfileAnonymised(): Observable<TalentData> {
    return this.profileApi.loadTalentProfileAnonymised().pipe(map((dto) => this.profileSerializer.deserializeTalentData(dto)));
  }

  loadTalentProfileTrusted(): Observable<TalentData> {
    return this.profileApi.loadTalentProfileTrusted().pipe(map((dto) => this.profileSerializer.deserializeTalentData(dto)));
  }

  saveKnownFrom(knownFrom: KnownFrom): Observable<boolean> {
    return this.profileApi.saveKnownFrom(knownFrom);
  }

  getTalentParsed(): Observable<Talent> {
    return this.profileApi.getTalentParsed().pipe(map(dto => this.profileSerializer.deserializeTalent(dto)));
  }

  loadMissionPartnerList(filter: AccountFilter): Observable<PaginatedContent<MissionPartner>> {
    return this.profileApi.loadMissionPartnerList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.profileSerializer.deserializeMissionPartners(paginatedContent.items as Array<MissionPartnerDTO>),
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  requestProfileAccess(talentId: string): Observable<AccessGrant> {
    return this.profileApi.requestProfileAccess(talentId).pipe(map((dto) => this.messagesSerializer.deserializeAccessGrant(dto)));
  }

  grantProfileAccess(missionPartnerId: string): Observable<AccessGrant> {
    return this.profileApi.grantProfileAccess(missionPartnerId).pipe(map((dto) => this.messagesSerializer.deserializeAccessGrant(dto)));
  }

  rejectProfileAccess(missionPartnerId: string): Observable<any> {
    return this.profileApi.rejectProfileAccess(missionPartnerId);
  }
}
