import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocationsTableListComponent } from "./locations-table-list.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [LocationsTableListComponent],
  imports: [CommonModule, NzFormModule, TranslateModule],
  exports: [
    LocationsTableListComponent
  ]
})
export class LocationsTableListModule {}
