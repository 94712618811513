<nz-radio-group class="radio-group-vertical" [(ngModel)]="radioSelected" (ngModelChange)="onRadioGroupModelChange()">
  <ng-container *ngFor="let option of selection">
  <label *ngIf="option.type === MAAnswerOptionType.SELECTABLE" nz-radio [ngModel]="option.id == radioSelected?.id" [nzValue]="option">
    <span>{{option.id | translate}}</span>
    <ng-container *ngIf="option.linkedAnswerOption && linkedFreeTextOption">
      <input type="text" nz-input [(ngModel)]="linkedFreeText" (ngModelChange)="onLinkedFreeTextChange($event, linkedFreeTextOption)"
             [placeholder]="'assessment.please-explain.placeholder' | translate" (click)="stopPropagation($event)"/>
    </ng-container>
  </label>
  </ng-container>
  <ng-container *ngIf="radioFreeTextItem">
    <label nz-radio [ngModel]="radioFreeTextItem.id == radioSelected?.id" [nzValue]="radioFreeTextItem">
      <input type="text" nz-input [(ngModel)]="radioFreeText" (ngModelChange)="onRadioFreeTextChange($event, radioFreeTextItem)"
             [placeholder]="'assessment.answer-other.placeholder' | translate" (click)="stopPropagation($event)"/>
    </label>
  </ng-container>
</nz-radio-group>
