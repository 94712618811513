import { Injectable } from "@angular/core";
import { VisitsApi } from "./visits.api";
import { map, Observable } from "rxjs";
import { VisitsSerializer } from "./visits.serializer";
import { VisitMissionPartner, VisitTalent } from "./visits.types";

@Injectable({ providedIn: "root" })
export class VisitsService {
  constructor(
    private visitsApi: VisitsApi,
    private visitsSerializer: VisitsSerializer,
  ) {}

  loadVisitTalentList(): Observable<Array<VisitTalent>> {
    return this.visitsApi.loadVisitTalentList().pipe(map((dto) => this.visitsSerializer.deserializeVisitsTalent(dto)));
  }

  loadVisitMissionPartnerList(): Observable<Array<VisitMissionPartner>> {
    return this.visitsApi.loadVisitMissionPartnerList().pipe(map((dto) => this.visitsSerializer.deserializeVisitsMissionPartner(dto)));
  }
}
