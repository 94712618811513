import { Injectable } from "@angular/core";
import { TestimonialsApi } from "./testimonials.api";
import { map, Observable } from "rxjs";
import { CreateTestimonialRequest, Testimonial, TestimonialRequest } from "./testimonials.types";
import { TestimonialsSerializer } from "./testimonials.serializer";

@Injectable({ providedIn: "root" })
export class TestimonialsService {
  constructor(
    private testimonialsApi: TestimonialsApi,
    private testimonialsSerializer: TestimonialsSerializer
  ) {}

  createTestimonialRequest(testimonialRequest: CreateTestimonialRequest): Observable<boolean> {
    return this.testimonialsApi.createTestimonialRequest(testimonialRequest);
  }

  loadTestimonials(): Observable<Array<Testimonial>> {
    return this.testimonialsApi.loadTestimonials().pipe(map((dto) => this.testimonialsSerializer.deserializeTestimonials(dto)));
  }

  loadTestimonialsRequest(): Observable<Array<TestimonialRequest>> {
    return this.testimonialsApi.loadTestimonialsRequest().pipe(map((dto) => this.testimonialsSerializer.deserializeTestimonialsRequest(dto)));
  }

  deleteTestimonial(testimonialId: string): Observable<boolean> {
    return this.testimonialsApi.deleteTestimonial(testimonialId);
  }

  deleteTestimonialRequest(testimonialId: string): Observable<boolean> {
    return this.testimonialsApi.deleteTestimonialRequest(testimonialId);
  }

  resendTestimonialRequest(testimonialId: string): Observable<boolean> {
    return this.testimonialsApi.resendTestimonialRequest(testimonialId);
  }

  confirmTestimonialRequest(testimonialText: string, confirmed: boolean, authToken: string): Observable<boolean> {
    return this.testimonialsApi.confirmTestimonialRequest(testimonialText, confirmed, authToken);
  }

  validationTestimonialRequest(token: string): Observable<boolean> {
    return this.testimonialsApi.validationTestimonialRequest(token);
  }
}
