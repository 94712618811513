<ng-container *nzModalTitle>
  {{'admin.user-admin.create.title' | translate}}
</ng-container>

<form nz-form [formGroup]="validateForm">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired nzFor="email">{{'admin.user-admin.create.name.label' | translate}}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Required">
      <input nz-input formControlName="name" id="name" type="text" [placeholder]="'admin.user-admin.create.name.placeholder' | translate"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzFor="email">{{'admin.user-admin.create.email.label' | translate}}</nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="emailErrorTpl">
      <input
        nz-input
        formControlName="email"
        id="email"
        type="email"
        [placeholder]="'admin.user-admin.create.email.placeholder' | translate"
      />
      <ng-template #emailErrorTpl let-control>
        <ng-container *ngIf="control.hasError('email')">{{'admin.user-admin.create.error.email' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{'admin.user-admin.create.error.required' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired nzFor="password">{{'admin.user-admin.create.password.label' | translate}}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Required">
      <input nz-input formControlName="password" id="password" type="password" [placeholder]="'admin.user-admin.create.password.placeholder' | translate"/>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">{{'admin.user-admin.create.confirm.cancel.btn' | translate}}</button>
  <button nz-button nzType="primary" (click)="onSave()">{{'admin.user-admin.create.confirm.ok.btn' | translate}}</button>
</ng-container>
