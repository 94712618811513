import {Component, Input} from "@angular/core";
import {TalentProfileCompleteness} from "../../../../../api/dashboard/dashboard.types";

@Component({
  selector: "mh-talent-dashboard-completeness",
  templateUrl: "./talent-dashboard-completeness.component.html",
  styleUrls: ["./talent-dashboard-completeness.component.less"],
})
export class TalentDashboardCompletenessComponent {
  @Input() completeness!: TalentProfileCompleteness;

  get profilePercentage(): number {
    return Math.ceil(+this.completeness?.completenessPercentage);
  }

}
