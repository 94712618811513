import { Component, Inject, OnInit } from "@angular/core";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { JobOfferTalentComment, MatchedTalent } from "../../../../../../api/job-offer/job-offer.types";
import { take } from "rxjs";
import { JobOfferService } from "../../../../../../api/job-offer/job-offer.service";
import { JobOfferAction } from "../../job-offer-data/store/job-offer.action";
import { Store } from "@ngrx/store";
import { jobOfferFeature, JobOfferState } from "../../job-offer-data/store/job-offer.reducer";

@Component({
  selector: "mh-job-offer-talent-comments-dialog",
  templateUrl: "./job-offer-talent-comments-dialog.component.html",
  styleUrls: ["./job-offer-talent-comments-dialog.component.less"],
})
export class JobOfferTalentCommentsDialogComponent implements OnInit {
  jobOfferId: string;
  talent: MatchedTalent;
  comments: JobOfferTalentComment[] = [];

  constructor(
    private readonly store: Store<{ [jobOfferFeature]: JobOfferState }>,
    private jobOfferService: JobOfferService,
    @Inject(NZ_MODAL_DATA) public modalData: any,
  ) {
    this.jobOfferId = this.modalData["jobOfferId"];
    this.talent = this.modalData["talent"];
  }

  ngOnInit() {
    this.jobOfferService.loadJobOfferTalentComments(this.jobOfferId, this.talent.accountId)
      .pipe(take(1))
      .subscribe((comments) => {
        this.comments = comments;
      });
  }

  onSendComment(text: string) {
    this.jobOfferService.sendJobOfferTalentComment(this.jobOfferId, this.talent.accountId, text)
      .pipe(take(1))
      .subscribe((comment) => {
        if (comment) {
          this.comments = [...this.comments, comment];
          this.store.dispatch(JobOfferAction.updateJobOfferMatchedTalentSuccess({ entity: { ...this.talent, hasComments: true } }));
        }
      });
  }

  onUpdateComment(event: { id: string; text: string }) {
    this.jobOfferService
      .updateJobOfferTalentComment(event.id, event.text)
      .pipe(take(1))
      .subscribe((comment) => {
        if (comment) {
          this.comments = this.comments.map((c) => (c.id === comment.id ? comment : c));
        }
      });
  }

  onDeleteComment(commentId: string) {
    this.jobOfferService.deleteJobOfferTalentComment(this.jobOfferId, commentId)
      .pipe(take(1))
      .subscribe((success) => {
        if (success) {
          this.comments = this.comments.filter((comment) => comment.id !== commentId);
          if (!this.comments.length) {
            this.store.dispatch(JobOfferAction.updateJobOfferMatchedTalentSuccess({ entity: { ...this.talent, hasComments: false } }));
          }
        }
      });
  }
}
