import { Component, Input } from "@angular/core";

@Component({
  selector: "mh-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.less"],
})
export class WidgetComponent {
  @Input() label!: string;
  @Input() value!: number | string | null;
  @Input() iconType!: string;
  @Input() link!: string | string[];
  @Input() queryParams?: { [key: string]: string | number };
  @Input() backgroundColor = "#6FC4C2";
  @Input() textColor = "#ffffff";
  @Input() iconSize = "174px";
  @Input() iconTop = "-20px";
  @Input() iconLeft = "10px";
  @Input() iconRotate = "15deg";
  @Input() moreBtn = "more.button";
  @Input() iconTheme: "fill" | "outline" | "twotone" = "outline";
}
