import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MpJobOfferListComponent } from "./mp-job-offer-list.component";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {JobOfferListActiveModule} from "./job-offer-list-active/job-offer-list-active.module";
import {JobOfferListArchivedModule} from "./job-offer-list-archived/job-offer-list-archived.module";

@NgModule({
  declarations: [MpJobOfferListComponent],
  imports: [CommonModule, NzTabsModule, NzGridModule, NzButtonModule, RouterLink, TranslateModule,
  JobOfferListActiveModule, JobOfferListArchivedModule],
  exports: [MpJobOfferListComponent],
})
export class MpJobOfferListModule {}
