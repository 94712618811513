<ng-container [ngTemplateOutlet]="jobOffer.countryCode ? joCountry : joLocations"></ng-container>
<ng-template #joCountry>
  <div *ngIf="jobOffer.countryCode; else noValueSpecified" class="location">
    <span class="location__value">{{ countryLabel }}</span>
  </div>
</ng-template>
<ng-template #joLocations>
  <ng-container *ngIf="jobOffer.locations?.length; else noValueSpecified">
    <div *ngFor="let location of jobOffer.locations" class="location">
      <span *ngIf="location.postalCode" class="location__value">{{ location.postalCode }}</span>
      <span *ngIf="location.city" class="location__value">{{ location.city }}</span>
      <span *ngIf="location.radius" class="location__value">+{{ location.radius.name | translate }} km</span>
      <span *ngIf="location.country" class="location__value">{{ location.country }}</span>
    </div>
  </ng-container>
</ng-template>
<ng-template #noValueSpecified>-</ng-template>
