import { Injectable } from "@angular/core";
import { CmsNameValueDTO } from "./cms-api.types";
import { CmsNameValue } from "./cms.types";

@Injectable({
  providedIn: "root",
})
export class CmsSerializer {
  deserializeNameValues(dtos: Array<CmsNameValueDTO>): Array<CmsNameValue> {
    return dtos.filter((dto) => !!dto).map((dto) => this.deserializeNameValue(dto));
  }

  deserializeNameValue(dto: CmsNameValueDTO): CmsNameValue {
    return {
      ...dto,
    };
  }
  serializeNameValues(entries: Array<CmsNameValue>): Array<CmsNameValueDTO> {
    return entries.map((entry) => this.serializeNameValue(entry));
  }

  serializeNameValue(entry: CmsNameValue): CmsNameValueDTO {
    return {
      ...entry,
    };
  }
}
