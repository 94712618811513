import { Component, Inject, OnInit, ViewContainerRef } from "@angular/core";
import { take } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { MissionPartner } from "../../../../api/sign-up/signup.types";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { UserService } from "../../../../api/user/user.service";
import { MessagesService } from "../../../../api/messages/messages.service";
import { CreateConversationDialogComponent } from "../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.component";
import { InitiateConversationRequest } from "../../../../api/messages/messages.types";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { Reference } from "libs/platform-pages/src/lib/api/references/references.types";

@Component({
  selector: "mh-admin-mission-partner-view",
  templateUrl: "./talent-view-micro-site.component.html",
  styleUrls: ["./talent-view-micro-site.component.less"],
})
export class TalentViewMicroSiteComponent implements OnInit {
  missionPartnerId = '';
  missionPartner: MissionPartner | undefined;
  conversationId?: string;
  testimonials: Array<Reference> = [];

  constructor(
    private userService: UserService,
    private messagesService: MessagesService,
    private route: ActivatedRoute,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
  }

  ngOnInit() {
    const accountId = this.route.snapshot.paramMap.get("id");
    if (accountId) {
      this.missionPartnerId = accountId;
      this.loadMissionPartner();
      this.loadConversation();
    }
  }

  private loadMissionPartner() {
    this.userService
      .getMissionPartnerUserById(this.missionPartnerId)
      .pipe(take(1))
      .subscribe((missionPartner) => {
        if (missionPartner) {
          this.missionPartner = missionPartner.data;
          this.testimonials = missionPartner?.references || [];
        }
      });
  }

  private loadConversation() {
    this.messagesService
      .loadConversationByAccountId(this.missionPartnerId)
      .pipe(take(1))
      .subscribe((conversation) => {
        if (conversation?.id) {
          this.conversationId = conversation?.id;
        }
      });
  }

  openCreateConversationModal() {
    if (!this.missionPartner) {
      return;
    }
    const modal = this.modal.create({
      nzContent: CreateConversationDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { suggestRequest: { missionPartnerId: this.missionPartner.accountId?.internalId } },
      nzWidth: 800,
      nzOnOk: (modalCmp) => this.doCreateConversation(this.missionPartner!, modalCmp.formGroup.value)
    });
  }

  doCreateConversation(missionPartner: MissionPartner, formData: Partial<InitiateConversationRequest>) {
    if (!missionPartner.accountId?.internalId) {
      return;
    }
    this.messagesService.initiateConversation(missionPartner.accountId.internalId, formData.messageText as string).subscribe((conversation) => {
      if (conversation) {
        this.notificationService.success("", this.translateService.instant("notification.success"));
        this.conversationId = conversation.id;
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  protected readonly AccountType = AccountType;
}
