<div class="media-upload">
  <div class="button-box">
    <button nz-button type="button" nzSize="large" nzType="link" nzShape="circle" class="cancel-button" (click)="onCancel()">
      <span nz-icon nzType="close" nzTheme="outline"></span>
    </button>

    <div *ngIf="blobUrl && !isRecording" class="audio-box">
      <audio controls class="audio">
        <source [src]="blobUrl" type="audio/wav">
      </audio>
    </div>

    <div *ngIf="!blobUrl" class="timer">{{ recordedTime }}</div>

    <button *ngIf="!isRecording && !blobUrl" nz-button type="button" nzType="primary" nzShape="circle" class="start-button" (click)="startAudioRecording()">
      <span nz-icon nzType="audio" nzTheme="outline"></span>
    </button>
    <button *ngIf="isRecording" [nzLoading]="recordingLoading" nz-button type="button" nzType="primary" nzShape="circle" class="stop-button" (click)="stopAudioRecording()">
      <span *ngIf="!recordingLoading" class="stop-button__icon"></span>
    </button>
    <button *ngIf="blobUrl" nz-button type="button" nzType="primary" nzShape="circle" class="submit-button" (click)="onSubmit()">
      <span nz-icon nzType="send" nzTheme="outline"></span>
    </button>
  </div>
</div>
