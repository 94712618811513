<ng-container *ngIf="conversationList$ | async as conversationsList; else loadingTmpl">
  <mh-messages-conversation-list
    [loading]="$any(isConversationsLoading$ | async)"
    [conversations]="conversationsList"
    [tabActive]="tabActive"
    (filterChange)="onFilterChange($event)"
    (conversationClick)="onConversationClick($event)"
    (conversationClose)="onConversationClose($event)"
    (conversationDelete)="onConversationDelete($event)"
  ></mh-messages-conversation-list>
</ng-container>

<ng-template #loadingTmpl><nz-spin nzSimple></nz-spin></ng-template>
