import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Talent } from "../../../../../../api/sign-up/signup.types";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable, Subject, Subscription, takeUntil } from "rxjs";
import { NzMarks } from "ng-zorro-antd/slider";
import { TranslateService } from "@ngx-translate/core";
import { CmsNameValue, compareCmsNameValue } from "../../../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../../../api/cms/cms-cached.service";

@Component({
  selector: "mh-profile-change-ready",
  templateUrl: "./profile-change-ready.component.html",
  styleUrls: ["./profile-change-ready.component.less"],
})
export class ProfileChangeReadyComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public changeDate$: Observable<Array<CmsNameValue>>;
  private onLangChangeSub: Subscription;

  marksReadiness: NzMarks = {};

  get changeEagerness(): string {
    return this.formGroup.get("changeEagerness")?.value || 1;
  }
  constructor(
    private fb: FormBuilder,
    private readonly translateService: TranslateService,
    private cmsCachedService: CmsCachedService,
  ) {
    this.changeDate$ = this.cmsCachedService.getUserDataByName("ready-to-start", false);

    this.onLangChangeSub = this.translateService.onLangChange.subscribe(() => {
      this.setMarksReadiness();
    });
    this.setMarksReadiness();
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      changeDate: [this.talent.changeDate, []],
      changeEagerness: [this.talent.changeEagerness || 1, []],
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        ...value,
      }),
    );
    this.formGroup.updateValueAndValidity();
  }

  setMarksReadiness() {
    this.marksReadiness = {
      1: this.translateService.instant("lowest.label"),
      5: this.translateService.instant("immediate.label"),
    };
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if (!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.onLangChangeSub.unsubscribe();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
