<h1 class="auth__title" nz-col nzSpan="24">{{'reg.talent.title' | translate}}</h1>
<form nz-form class="auth__form" [formGroup]="formGroup">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.email.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="emailErrorTpl">
      <input nz-input nzSize="large" [placeholder]="'reg.talent.email.placeholder' | translate"
             formControlName="email"
             autocomplete="email" (paste)="onEmailPaste('email')"/>
      <ng-template #emailErrorTpl let-control>
        <ng-container *ngIf="control.hasError('email')">{{'field.invalid.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('invalid')">{{'field.invalid.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('confirm')">{{'reg.talent.email-confirm.error' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.email-confirm.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="emailConfirmErrorTpl" [nzDisableAutoTips]="true">
      <input nz-input nzSize="large" [placeholder]="'reg.talent.email.placeholder' | translate"
             formControlName="emailConfirm"
             autocomplete="email" (paste)="onEmailPaste('emailConfirm')"/>
      <ng-template #emailConfirmErrorTpl let-control>
        <ng-container *ngIf="control.hasError('email')">{{'field.invalid.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('invalid')">{{'field.invalid.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('confirm')">{{'reg.talent.email-confirm.error' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.password.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" nzDisableAutoTips>
      <input nz-input type="password" [placeholder]="'reg.talent.password.placeholder' | translate"
             formControlName="password"
             minlength="8"
             autocomplete="new-password"
             nzSize="large"
      />
      <mh-password-validation-tips [passwordControl]="password"></mh-password-validation-tips>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'">
      <mh-explanation-label [labelKey]="'reg.talent.salutation.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="salutationErrorTpl">
      <input nz-input nzSize="large" [placeholder]="'reg.talent.salutation.placeholder' | translate"
             formControlName="salutation"/>
      <ng-template #salutationErrorTpl let-control>
        <ng-container *ngIf="control.hasError('invalid')">{{'field.invalid.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.firstname.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="firstNameErrorTpl">
      <input nz-input nzSize="large" [placeholder]="'reg.talent.firstname.placeholder' | translate"
             formControlName="firstName"/>
      <ng-template #firstNameErrorTpl let-control>
        <ng-container *ngIf="control.hasError('invalid')">{{'field.invalid.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.lastname.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="lastNameErrorTpl">
      <input nz-input nzSize="large" [placeholder]="'reg.talent.lastname.placeholder' | translate"
             formControlName="lastName"/>
      <ng-template #lastNameErrorTpl let-control>
        <ng-container *ngIf="control.hasError('invalid')">{{'field.invalid.label' | translate}}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.birth-date.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzErrorTip]="birthDateErrorTpl">
      <nz-date-picker nzSize="large" formControlName="birthDate" [nzDisabledDate]="disabledDate"
                      [nzFormat]="'dd.MM.yyyy'"
                      [nzPlaceHolder]="'profile.birth-date.placeholder' | translate"
                      [nzDefaultPickerValue]="defaultPickerValue"></nz-date-picker>
    </nz-form-control>
    <ng-template #birthDateErrorTpl let-control>
      <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
    </ng-template>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSpan]="24">
      <label nz-checkbox formControlName="updatesSubscription">
        <span>{{'reg.talent.subscribe.label' | translate}}</span>
      </label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="24" nzDisableAutoTips [nzErrorTip]="termsErrorTpl">
      <label nz-checkbox formControlName="termsAndConditions">
        <span (click)="stopPropagation($event)" [innerHtml]="'reg.talent.terms.label' | translate:{ dataSafetyLink, termsLink}"></span>
        <span class="required-text"></span>
      </label>
      <ng-template #termsErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">{{'reg.talent.terms.validation' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <p>{{'reg.required.explanation' | translate}}</p>

  <nz-form-item>
    <nz-form-control [nzSpan]="24">
      <button nz-button nzType="primary" nzSize="large" nzBlock (click)="submitForm()" [disabled]="loading">{{'reg.talent.register.btn' | translate}}</button>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="24">
      <a (click)="onAlreadyHaveAccount()" (keydown.enter)="onAlreadyHaveAccount()" nz-button nzType="link" nzSize="large" nzBlock>{{'reg.talent.login.link' | translate}}</a>
    </nz-form-control>
  </nz-form-item>
</form>
