<nz-page-header class="large p-r-0">
  <nz-page-header-title>{{ "inbox.title" | translate }}</nz-page-header-title>
</nz-page-header>

<nz-tabset nzLinkRouter>
  <nz-tab (nzSelect)="onTabSelect('messages')">
    <a
      *nzTabLink
      nz-tab-link
      [routerLink]="['.']"
      [queryParams]="{ tab: 'messages' }"
      queryParamsHandling="merge"
      class="tab-link"
    >
      {{ "inbox.tab.messages" | translate }}
    </a>
    <mh-messages-inbox
      [tabActive]="this.tab === 'messages'"
      (conversationClick)="onConversationClick($event, 'messages')"
    ></mh-messages-inbox>
  </nz-tab>
  <nz-tab (nzSelect)="onTabSelect('contactrequests')">
    <a
      *nzTabLink
      nz-tab-link
      [routerLink]="['.']"
      [queryParams]="{ tab: 'contactrequests' }"
      queryParamsHandling="merge"
      class="tab-link"
    >
      {{ "inbox.tab.contactrequests" | translate }}
    </a>
    <mh-messages-inbox
      [tabActive]="this.tab === 'contactrequests'"
      (conversationClick)="onConversationClick($event, 'contactrequests')"
    ></mh-messages-inbox>
  </nz-tab>
  <nz-tab (nzSelect)="onTabSelect('hirings')">
    <a
      *nzTabLink
      nz-tab-link
      [routerLink]="['.']"
      [queryParams]="{ tab: 'hirings' }"
      queryParamsHandling="merge"
      class="tab-link"
    >
      {{ "inbox.tab.hirings" | translate }}
    </a>
    <mh-messages-inbox
      [tabActive]="this.tab === 'hirings'"
      (conversationClick)="onConversationClick($event, 'hirings')"
    ></mh-messages-inbox>
  </nz-tab>
</nz-tabset>
