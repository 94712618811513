import { Component, Input } from "@angular/core";
import { TalentListStatistic } from "../../../../../api/admin-talent/admin-talent.types";

@Component({
  selector: "mh-admin-talent-statistic",
  templateUrl: "./admin-talent-statistic.component.html",
  styleUrls: ["./admin-talent-statistic.component.less"],
})
export class AdminTalentStatisticComponent {
  @Input() stats!: TalentListStatistic;
}
