import {AccountId, ConversationStatus, MessagingAttachmentDTO, MessagingMessageDTO} from "../messages/messages.types";

export interface MhSocketMessage {
  action: MhNotificationType;
  notification?: MhNotification;
  message?: MessagingMessageDTO;
  accountId?: AccountId;
  closer?: AccountId;
  status?: ConversationStatus;
  conversationId?: string;
  jobOpportunityId?: string;
  file?: MessagingAttachmentDTO;
}

export interface MhNotification {
  id: string;
  accountId: string;
  partnerId: string;
  type: MhNotificationType;
  read: boolean;
  createdAt: number;
  data?: any;
}

export enum MhNotificationType {
  CV_PARSED = "CV_PARSED",
  COMMUNICATION_MESSAGE = "COMMUNICATION_MESSAGE",
  CONVERSATION_STATUS_UPDATED = "CONVERSATION_STATUS_UPDATED",
  TALENTS_SEARCH_STARTED = "TALENTS_SEARCH_STARTED",
  TALENTS_SEARCH_FINISHED = "TALENTS_SEARCH_FINISHED",
  VIDEO_TRANSCODED = "VIDEO_TRANSCODED"
}

export interface MhNotificationSettings {
  email: MhNotificationSettingsConfig[];
  sms: MhNotificationSettingsConfig[];
}

export interface MhNotificationSettingsConfig {
  type: string;
  enabled: boolean;
}
