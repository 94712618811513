import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { map, Observable } from "rxjs";
import { ApiResponseDTO } from "../common.types";
import { MissionPartnerDataDTO, TalentDataDTO } from "../sign-up/signup-api.types";

@Injectable({ providedIn: "root" })
export class UserApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  getTalentUserById(id: string): Observable<TalentDataDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/${id}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  getMissionPartnerUserById(id: string): Observable<MissionPartnerDataDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/${id}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  getMyNewsletter(): Observable<boolean> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/my/newsletter`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  postNewsletterEnabled(enabled: boolean): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/user/my/newsletter/${enabled}`, {status: enabled}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
}
