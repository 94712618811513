import { Component, Input } from "@angular/core";
import { Reference, ReferenceRequest } from "../../../api/references/references.types";
import { AccountType } from "../../../pages/login-page/login-page-data/login.types";

@Component({
  selector: "mh-micro-site-testimonials",
  templateUrl: "./micro-site-testimonials.component.html",
  styleUrls: ["./micro-site-testimonials.component.less"],
})
export class MicroSiteTestimonialsComponent {
  @Input() trustedView = false;
  @Input() testimonials: Array<Reference> = [];
  @Input() testimonialsRequest: Array<ReferenceRequest> = [];
  @Input() viewerAccountType?: AccountType;

  protected readonly AccountType = AccountType;
}
