import { Injectable } from "@angular/core";
import { DashboardApi } from "./dashboard.api";
import { map, Observable } from "rxjs";
import {
  JobOffersInfo,
  InvoicesInfo,
  TalentProfileCompleteness,
  DashboardConversationInfo,
  MissionPartnerProfileCompleteness,
} from "./dashboard.types";
import { CompletenessSerializer } from "./completeness.serilializer";
import { DashboardSerializer } from "./dashboard.serializer";

@Injectable({ providedIn: "root" })
export class DashboardService {
  constructor(
    private dashboardApi: DashboardApi,
    private completenessSerializer: CompletenessSerializer,
    private dashboardSerializer: DashboardSerializer,
  ) {}

  getTalentProfileCompleteness(): Observable<TalentProfileCompleteness> {
    return this.dashboardApi
      .getTalentProfileCompleteness()
      .pipe(map((dto) => this.completenessSerializer.deserializeTalent(dto)));
  }

  getMissionPartnerProfileCompleteness(): Observable<MissionPartnerProfileCompleteness> {
    return this.dashboardApi
      .getMissionPartnerProfileCompleteness()
      .pipe(map((dto) => this.completenessSerializer.deserializeMissionPartner(dto)));
  }

  getMissionPartnerJobOffers(): Observable<JobOffersInfo> {
    return this.dashboardApi.getMissionPartnerJobOffers();
  }

  getTalentLastReplies(): Observable<DashboardConversationInfo> {
    return this.dashboardApi.getTalentLastReplies()
      .pipe(map((dto) => this.dashboardSerializer.deserializeDashboardConversationInfo(dto)));;
  }

  getProactiveContactAttempts(): Observable<DashboardConversationInfo> {
    return this.dashboardApi.getProactiveContactAttempts()
      .pipe(map((dto) => this.dashboardSerializer.deserializeDashboardConversationInfo(dto)));
  }

  getHirings(): Observable<DashboardConversationInfo> {
    return this.dashboardApi.getHirings()
      .pipe(map((dto) => this.dashboardSerializer.deserializeDashboardConversationInfo(dto)));
  }


  getInvoicesNew(): Observable<InvoicesInfo> {
    return this.dashboardApi.getInvoicesNew();
  }
}
