import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import {JobOffer, JobOpportunitySearchResultStatus} from "../../../../../../api/job-offer/job-offer.types";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: "mh-mp-job-offer-item",
  templateUrl: "./mp-job-offer-item.component.html",
  styleUrls: ["./mp-job-offer-item.component.less"],
  animations: [
    trigger('showMore', [
      state('show', style({
        height: '*',
      })),
      state('hide', style({
        height: '63px',
      })),
      transition('show <=> hide', [
        animate('.7s')
      ]),
    ]),
  ],
})
export class MpJobOfferItemComponent {
  @Input() jobOffer!: JobOffer;

  isExpanded = false;
  canExpand = false;

  @ViewChild('jobOfferDescription') jobOfferDescription?: ElementRef;

  ngOnInit() {
    setTimeout(() => {
      this.checkCanExpand();
    })
  }

  private checkCanExpand() {
    if (this.jobOfferDescription) {
      const contentHeight = this.jobOfferDescription.nativeElement.scrollHeight;
      this.canExpand = contentHeight > 63;
    }
  }

  toggleShowMore(event: Event) {
    event.stopImmediatePropagation();
    this.isExpanded = !this.isExpanded;
  }

  protected readonly JobOpportunitySearchResultStatus = JobOpportunitySearchResultStatus;
}
