import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileChangeReadyComponent } from "./profile-change-ready.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { ReactiveFormsModule } from "@angular/forms";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { ExplanationLabelModule } from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzSelectModule } from "ng-zorro-antd/select";

@NgModule({
  declarations: [ProfileChangeReadyComponent],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        ReactiveFormsModule,
        NzSliderModule,
        NzButtonModule,
        NzWaveModule,
        TranslateModule,
        ExplanationLabelModule,
        NzSelectModule,
    ],
  exports: [ProfileChangeReadyComponent],
})
export class ProfileChangeReadyModule {}
