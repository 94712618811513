import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerMicroSiteComponent } from "./mission-partner-micro-site.component";
import { MicroSiteModule } from "../../../../common-componnets/micro-site/micro-site.module";

@NgModule({
  declarations: [MissionPartnerMicroSiteComponent],
  imports: [
    CommonModule,
    MicroSiteModule,
  ],
})
export class MissionPartnerMicroSiteModule {}
