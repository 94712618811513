import { Component, Inject, OnInit } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MessagesService } from "../../api/messages/messages.service";
import { MissionPartnerMessageSuggest, TalentMessageSuggest } from "../../api/messages/messages.types";

@Component({
  selector: "mh-create-conversation-dialog",
  templateUrl: "./create-conversation-dialog.component.html",
  styleUrls: ["./create-conversation-dialog.component.less"],
})
export class CreateConversationDialogComponent implements OnInit {
  suggestRequest: TalentMessageSuggest | MissionPartnerMessageSuggest;
  formGroup!: UntypedFormGroup;
  messageSuggestions: string[] = [];
  messageSuggestionsActive: number | null = null;
  messageSuggestionsLoading = false;

  constructor(
    @Inject(NZ_MODAL_DATA)
    public modalData: any,
    private fb: UntypedFormBuilder,
    private modal: NzModalRef,
    private messagesService: MessagesService,
  ) {
    this.suggestRequest = this.modalData["suggestRequest"];
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      messageText: ["", [Validators.required]],
    });
  }

  onCancel() {
    this.modal.close();
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    return this.modal.triggerOk();
  }

  getMessageSuggestion() {
    if (!this.suggestRequest) {
      return;
    }
    this.messageSuggestionsLoading = true;
    if ((this.suggestRequest as TalentMessageSuggest).missionPartnerId) {
      this.messagesService
        .getMessageSuggestionTalent(this.suggestRequest as TalentMessageSuggest)
        .subscribe((messages) => {
          this.messageSuggestions = messages;
          this.messageSuggestionsLoading = false;
        });
    } else if ((this.suggestRequest as MissionPartnerMessageSuggest).talentId) {
      this.messagesService
        .getMessageSuggestionMissionPartner(this.suggestRequest as MissionPartnerMessageSuggest)
        .subscribe((messages) => {
          this.messageSuggestions = messages;
          this.messageSuggestionsLoading = false;
        });
    }
  }

  onAcceptMessageSuggestion() {
    if (this.messageSuggestionsActive === null) {
      return;
    }
    this.formGroup.get("messageText")?.setValue(this.messageSuggestions[this.messageSuggestionsActive]);
    this.messageSuggestions = [];
    this.messageSuggestionsActive = null;
  }

  onRejectMessageSuggestion() {
    this.messageSuggestions = [];
    this.messageSuggestionsActive = null;
  }

  onSelectMessageSuggestion(index: number) {
    this.messageSuggestionsActive = index;
  }
}
