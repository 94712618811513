<nz-card class="dashboard__card" [nzTitle]="'profile.status.title' | translate" [nzActions]="[actionComplete]">
  <form nz-form nzLayout="vertical">
    <nz-form-item>
      <nz-form-label>{{ "profile.status.label" | translate }}</nz-form-label>
      <nz-form-control>
        <nz-select
          [nzDropdownMatchSelectWidth]="false"
          [(ngModel)]="activeStatus"
          [ngModelOptions]="{ standalone: true }"
        >
          <nz-option [nzLabel]="'profile.vacationing.off.label' | translate" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'profile.vacationing.label' | translate" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>
<ng-template #actionComplete>
  <button nz-button nzType="primary" (click)="onSave()">{{'save.button' | translate}}</button>
</ng-template>
