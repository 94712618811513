import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import { CmsNameValue } from "../../../../platform-pages/src/lib/api/cms/cms.types";
import { TranslateService } from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
  selector: "mh-tags-output",
  templateUrl: "./tags-output.component.html",
  styleUrls: ["./tags-output.component.less"],
})
export class TagsOutputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() value!: Array<CmsNameValue | string> | undefined;
  @Input() sortABC = false;
  @Input() prefix = '';
  @Input() tagClass = '';
  @Input() nzColor = '';
  @Input() placeholder = '-';
  public translatedValues!: Array<string>;

  onTranslationChangeSub?: Subscription;
  onLangChangeSub?: Subscription;
  onDefaultLangChangeSub?: Subscription;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["value"] && this.value) {
      this.initTranslatedValues();
    }
  }

  ngOnInit() {
    if (!this.onTranslationChangeSub) {
      this.onTranslationChangeSub = this.translateService.onTranslationChange.subscribe(() => this.initTranslatedValues());
    }
    if (!this.onLangChangeSub) {
      this.onLangChangeSub = this.translateService.onLangChange.subscribe(() => this.initTranslatedValues());
    }
    if (!this.onDefaultLangChangeSub) {
      this.onDefaultLangChangeSub = this.translateService.onDefaultLangChange.subscribe(() => this.initTranslatedValues());
    }
  }

  private initTranslatedValues() {
    this.translatedValues = [];
    if(!this.value) return;
    this.value?.map((val) => {
      if (val && (val as CmsNameValue).name && typeof val !== "string") {
        this.translatedValues.push(this.translateService.instant(val.name));
      } else if(typeof val === "string") {
        this.translatedValues.push(val);
      }
    });
    if (this.sortABC) this.translatedValues.sort((a, b) => a?.localeCompare(b));
  }

  ngOnDestroy() {
    if (typeof this.onTranslationChangeSub !== 'undefined') {
      this.onTranslationChangeSub.unsubscribe();
      this.onTranslationChangeSub = undefined;
    }
    if (typeof this.onLangChangeSub !== 'undefined') {
      this.onLangChangeSub.unsubscribe();
      this.onLangChangeSub = undefined;
    }
    if (typeof this.onDefaultLangChangeSub !== 'undefined') {
      this.onDefaultLangChangeSub.unsubscribe();
      this.onDefaultLangChangeSub = undefined;
    }
  }
}
