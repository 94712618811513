import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AvatarUploadComponent } from "./avatar-upload.component";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ImageCropperModule } from "ngx-image-cropper";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [AvatarUploadComponent],
    imports: [CommonModule, NzUploadModule, NzIconModule, ImageCropperModule, NzAvatarModule, NzModalModule, NzButtonModule, NzSpinModule, TranslateModule],
  exports: [AvatarUploadComponent],
})
export class AvatarUploadModule {}
