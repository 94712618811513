import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PasswordResetComponent} from "./password-reset.component";
import {TranslateModule} from "@ngx-translate/core";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzFormModule} from "ng-zorro-antd/form";
import {RouterLink} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";
import {PasswordValidationTipsModule} from "../../../../../common-ui/src/lib/password-validation-tips/password-validation-tips.module";

@NgModule({
  declarations: [PasswordResetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzLayoutModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    RouterLink,
    PasswordValidationTipsModule,
  ],
  exports: [PasswordResetComponent],
})
export class PasswordResetModule {
}
