import {ChangeDetectorRef, Component, OnDestroy, ViewChild} from "@angular/core";
import {JobOffer} from "../../../../../api/job-offer/job-offer.types";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {jobOfferFeature, JobOfferState} from "../job-offer-data/store/job-offer.reducer";
import {MpJobOfferFormComponent} from "../mp-job-offer-form/mp-job-offer-form.component";
import {JobOfferAction} from "../job-offer-data/store/job-offer.action";
import {filter, map, Observable, Subject, take, takeUntil, tap} from "rxjs";
import {BreakpointObserver} from "@angular/cdk/layout";
import {AuthorizationService} from "../../../../../api/authorization/authorization.service";

@Component({
  selector: "mh-mp-job-offer-edit",
  templateUrl: "./mp-job-offer-edit.component.html",
  styleUrls: ["./mp-job-offer-edit.component.less"],
})
export class MpJobOfferEditComponent implements OnDestroy {
  jobOffer: JobOffer;
  isSaving = false;
  isMobileView$: Observable<boolean>;
  hasReviewedStatus$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  @ViewChild(MpJobOfferFormComponent) form!: MpJobOfferFormComponent;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly store: Store<{ [jobOfferFeature]: JobOfferState }>,
              private router: Router,
              private readonly breakpointObserver: BreakpointObserver,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private authorizationService: AuthorizationService,) {
    this.jobOffer = this.activatedRoute.snapshot.data["jobOffer"];

    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 767px)"]).pipe(
      takeUntil(this.destroy$),
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
    this.hasReviewedStatus$ = this.authorizationService.hasReviewedStatus().pipe(takeUntil(this.destroy$));
  }

  validate(): boolean {
    return this.form?.isValid();
  }

  onFormChange(jobOffer: JobOffer) {
    this.jobOffer = {...this.jobOffer, ...jobOffer};
  }

  onSave(): void {
    if (this.isSaving || !this.validate()) {
      return;
    }
    this.isSaving = true;

    this.updateJobOffer(this.jobOffer, () => {
      return this.onBack();
    });
  }

  onBack(): Promise<boolean> {
    const origin = this.activatedRoute.snapshot.queryParams['origin'];
    const originId = this.activatedRoute.snapshot.queryParams['originId'];
    if (origin == 'job-offers-matches' && originId) {
      return this.router.navigate(['/', 'mission-partner', 'job-offers', 'matches', originId]);
    }
    return this.router.navigate(["/", "mission-partner", "job-offers"]);
  }

  private updateJobOffer(entity: JobOffer, cb: () => void) {
    this.store.dispatch(JobOfferAction.updateJobOffer({ entity }));

    this.store
      .select((state) => state[jobOfferFeature].entity.status)
      .pipe(
        tap((state) => (state.error ? (this.isSaving = false) : "")),
        filter((state) => !state.loading && !state.error),
        take(1),
      )
      .subscribe(() => {
        cb();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
