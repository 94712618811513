import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { Subscription, take } from "rxjs";
import { CmsService } from "../../api/cms/cms.service";
import { ActivatedRoute } from "@angular/router";
import { LocaleService } from "@momhunting/core";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "../login-page/login-page-data/login.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "mh-content-page",
  templateUrl: "./content-page.component.html",
  styleUrls: ["./content-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPageComponent implements OnDestroy {
  boxName = "";
  content: string | undefined = "";
  onLangChangeSub: Subscription;

  constructor(
    private readonly cmsService: CmsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private readonly translateService: TranslateService,
    private readonly localeService: LocaleService,
    private titleService: Title,
  ) {
    this.boxName = this.activatedRoute.snapshot.data["boxName"];
    this.onLangChangeSub = this.translateService.onLangChange.subscribe(() => {
      this.loadCmsContent();
    });
    this.loadCmsContent();
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.boxName }));
  }

  loadCmsContent() {
    this.cmsService
      .loadBox(this.boxName, this.localeService.currentLocale)
      .pipe(take(1))
      .subscribe((content) => {
        this.content = content;
        this.changeDetectorRef.markForCheck();
      });
  }

  onLogout() {
    this.loginService.logout();
  }

  ngOnDestroy() {
    this.onLangChangeSub.unsubscribe();
  }
}
