<nz-layout class="auth">
  <div nz-row class="auth__container">
    <div nz-col nzXs="0" nzMd="12" class="auth__bg">
      <div class="auth__bg-img"></div>
    </div>
    <div nz-col nzXs="24" nzMd="12" class="auth__content">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24"  class="auth__logo">
          <img class="logo-img" src="/assets/images/logo.svg" alt="Momhunting logo" />
        </div>
        <ng-container *ngIf="showResetForm">
          <h1 class="auth__title" nz-col nzSpan="24">{{'password-forgotten.title' | translate}}</h1>
          <form nz-form class="auth__form" [nzAutoTips]="autoTips" [formGroup]="formGroup" (ngSubmit)="onForgottenPasswordFormSubmit()">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>{{'password-forgotten.email.label' | translate}}</nz-form-label>
              <nz-form-control [nzSpan]="24">
                <input nz-input nzSize="large" formControlName="email" [placeholder]="'password-forgotten.email.placeholder' | translate" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <button nz-button nzType="primary" nzSize="large" nzBlock>{{'password-forgotten.submit.btn' | translate}}</button>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <a [routerLink]="['/login']" nz-button nzType="link" nzSize="large" nzBlock>{{'password-forgotten.back' | translate}}</a>
              </nz-form-control>
            </nz-form-item>

            <div role="alert" *ngIf="errorResponse" class="auth__error-message">{{ errorResponse ?? "toast.general.error" | translate }}</div>
          </form>
        </ng-container>
        <ng-container *ngIf="!showResetForm">
          <h1 class="auth__title" nz-col nzSpan="24">{{'password-forgotten.success.title' | translate}}</h1>
          <p class="auth__text" nz-col nzSpan="24">{{'password-forgotten.success.text' | translate}}</p>
          <div class="auth__form">
            <button nz-button nzType="primary" nzSize="large" nzBlock [routerLink]="['/login']">
              {{'password-forgotten.success.back' | translate}}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</nz-layout>
