import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {LocaleSelectModule} from "@momhunting/common-ui";

@NgModule({
  declarations: [LoginPageComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterLink,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzCardModule,
        NzLayoutModule,
        LocaleSelectModule,
    ],
  exports: [LoginPageComponent]
})
export class LoginPageModule { }
