import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocaleSelectComponent } from "./locale-select.component";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { FormsModule } from "@angular/forms";
import { NzSelectModule } from "ng-zorro-antd/select";

@NgModule({
  declarations: [LocaleSelectComponent],
  imports: [CommonModule, NzRadioModule, FormsModule, NzSelectModule],
  exports: [LocaleSelectComponent],
})
export class LocaleSelectModule {}
