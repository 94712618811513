<nz-page-header [nzTitle]="'profile.willing-change.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.willing-change.change-date.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select nzSize="default" formControlName="changeDate" nzAllowClear [compareWith]="compareCmsNameValue" nzShowSearch>
            <nz-option
              [nzLabel]="option.name | translate"
              [nzValue]="option"
              *ngFor="let option of changeDate$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label>
        <mh-explanation-label [labelKey]="'profile.willing-change.change-eagerness.label'">
          {{'profile.willing-change.change-eagerness.label' | translate}} <b>{{changeEagerness}}</b>
        </mh-explanation-label>
      </nz-form-label>
      <div nz-row>
        <div nz-col [nzSpan]="22" nzOffset="1" [nzMd]="{ span: 24, offset: 0 }">
          <nz-form-control>
            <nz-slider [nzMin]="1" [nzMax]="5" [nzMarks]="marksReadiness" nzTooltipVisible="never" formControlName="changeEagerness"></nz-slider>
          </nz-form-control>
        </div>
      </div>
    </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save-and-next.button' | translate}}</button>
    </div>
  </div>
</form>
