<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>
<ng-template #desktopView>
  <nz-layout class="layout" mhScrollToValidationError>
    <nz-header class="common-header">
      <div class="container">
        <div nz-row nzJustify="center" style="flex-flow: nowrap">
          <div nz-col nzFlex="auto">
            <div nz-row nzJustify="center">
              <div nz-col nzFlex="67px">
                <a class="logo-container" href="/">
                  <img class="logo-img" width="67px" src="/assets/images/logo.svg" alt="Momhunting logo" />
                </a>
              </div>
              <div nz-col nzFlex="auto">
                <ul nz-menu nzTheme="light" nzMode="horizontal">
                  <li
                    nz-menu-item
                    *ngFor="let navItem of navigationBar"
                    [nzSelected]="currentNavItem === navItem"
                    [routerLink]="navItem.routerLink"
                    [queryParams]="navItem.queryParams"
                    [ngClass]="{ hidden: navItem.hidden }"
                  >
                    <mh-header-nav-item [navItem]="navItem"></mh-header-nav-item>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div nz-col nzFlex="auto">
            <div class="cnt-buttons">
              <mh-notification-dropdown></mh-notification-dropdown>

              <button
                nz-button
                nzType="text"
                nzSize="large"
                nz-dropdown
                [nzDropdownMenu]="menu"
                nzPlacement="bottomRight"
              >
                <span nz-icon nzType="setting" nzTheme="outline"></span>
              </button>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>{{ (user$ | async)?.email }}</li>
                  <li nz-menu-divider></li>
                  <li
                    nz-menu-item
                    [routerLink]="navItem.routerLink"
                    *ngFor="let navItem of navigationDropdown"
                    [ngClass]="{ hidden: navItem.hidden }"
                  >
                    {{ navItem.nameKey | translate }}
                  </li>
                  <li nz-menu-item (click)="onLogout()" (keydown.enter)="onLogout()">
                    {{ "navi.logout.label" | translate }}
                  </li>
                  <li nz-menu-item>
                    <mh-locale-select></mh-locale-select>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </nz-header>
    <nz-content class="with-header container">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</ng-template>

<ng-template #mobileView>
  <Navbar [icon]="icon" [rightContent]="rightContent" [mode]="'light'">
    {{ currentNavItem?.nameKey || "" | translate }}
  </Navbar>

  <Drawer
    class="my-drawer"
    [ngStyle]="{ minHeight: this.height + 'px' }"
    [enableDragHandle]="false"
    [sidebar]="sidebar"
    [open]="this.state.open"
    (onOpenChange)="toggleDrawer()"
  >
    <WingBlank>
      <WhiteSpace [size]="'lg'"></WhiteSpace>
      <router-outlet></router-outlet>
      <WhiteSpace [size]="'lg'"></WhiteSpace>
    </WingBlank>
  </Drawer>

  <ng-template #icon>
    <img class="logo-img" width="47px" src="/assets/images/logo.svg" alt="Momhunting logo" />
  </ng-template>

  <ng-template #rightContent>
    <mh-header-unseen-messages-icon [routerLink]="['inbox']"></mh-header-unseen-messages-icon>
    <mh-notification-dropdown [mobile]="true"></mh-notification-dropdown>
    <button nz-button nzType="text" (click)="toggleDrawer()">
      <span nz-icon nzType="menu" nzTheme="outline"></span>
    </button>
  </ng-template>

  <ng-template #sidebar>
    <List>
      <ListItem
        *ngFor="let navItem of navigation"
        [routerLink]="navItem.routerLink"
        (onClick)="toggleDrawer(navItem)"
        [ngClass]="{ hidden: navItem.hidden }"
      >
        <span nz-icon [nzType]="navItem.icon" nzTheme="outline"></span>
        <span [ngClass]="{ bold: navItem === currentNavItem }">{{ navItem.nameKey | translate }}</span>
      </ListItem>

      <ListItem (click)="onLogout()">{{ "navi.logout.label" | translate }}</ListItem>

      <ListItem>
        <mh-locale-select></mh-locale-select>
      </ListItem>
    </List>
  </ng-template>
</ng-template>
