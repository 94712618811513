import { HttpClient } from "@angular/common/http";
import { map, Observable, of } from "rxjs";
import { TranslateLoader, TranslateService } from "@ngx-translate/core";
import { Injector, isDevMode } from "@angular/core";
import { LOCATION_INITIALIZED } from "@angular/common";
import { AppConfig } from "../app-config/config.types";
import { LocaleService } from "@momhunting/core";
import { GtagFn, IGoogleAnalyticsSettings } from "@hakimio/ngx-google-analytics";

export function appInitializerFactory(
  translate: TranslateService,
  localeService: LocaleService,
  injector: Injector,
  appConfig: AppConfig,
): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet =
          localeService.getSelectedLocale() ||
          (appConfig.featureStatus.englishSite ? translate.getBrowserLang() : appConfig.defaultLocale) ||
          appConfig.defaultLocale;
        return localeService.initLocale(langToSet, appConfig.keysLocale)?.subscribe(
          () => {},
          (err) => {
            console.error(`Error loading translations for locale: '${langToSet}`);
          },
          () => {
            resolve(null);
          },
        );
      });
    });
}

export class CustomTranslateLoader implements TranslateLoader {
  private apiBasePath: string;
  constructor(private readonly httpClient: HttpClient, private appConfig: AppConfig) {
    this.apiBasePath = this.appConfig.apiEndpointUrl;
  }

  public getTranslation(lang: string): Observable<any> {
    if (lang == this.appConfig.keysLocale) {
      return of({});
    }
    if (!this.appConfig.translationLocales.includes(lang)) {
      lang = this.appConfig.defaultLocale;
    }
    return this.httpClient.get(`${this.apiBasePath}/localization/messages/` + lang).pipe(
      map((response: any) => response.results.messages),
      map((translations: any) => ({ ...translations })),
    );
  }
}

export function GoogleAnalyticsInitializer(settings: IGoogleAnalyticsSettings, gtag: GtagFn, document: Document) {
  return async () => {
    if (!settings.ga4TagId) {
      if (!isDevMode()) {
        console.error(
          "Empty tracking code for Google Analytics. Make sure to provide one when initializing NgxGoogleAnalyticsModule.",
        );
      }

      return;
    }

    if (!gtag) {
      if (!isDevMode()) {
        console.error(
          "Couldn't create or read gtag() fn. Make sure this module is running on a Browser w/ access to Window interface.",
        );
      }

      return;
    }

    if (!document) {
      if (!isDevMode()) {
        console.error(
          "Couldn't to access Document interface. Make sure this module is running on a Browser w/ access to Document interface.",
        );
      }
    }

    // Set default ga.js uri
    settings.uri = settings.uri || `https://www.googletagmanager.com/gtag/js?id=${settings.ga4TagId}`;

    // these commands should run first!
    settings.initCommands = settings?.initCommands ?? [];

    // assert config command
    if (!settings.initCommands.find((x) => x.command === "config")) {
      settings.initCommands.unshift({ command: "config", values: [settings.ga4TagId] });
    }

    // assert js command
    if (!settings.initCommands.find((x) => x.command === "js")) {
      settings.initCommands.unshift({ command: "js", values: [new Date()] });
    }

    for (const command of settings.initCommands) {
      gtag(command.command, ...command.values);
    }

    const s: HTMLScriptElement = document.createElement("script");
    s.async = true;
    s.src = settings.uri;

    if (settings.nonce) {
      s.setAttribute("nonce", settings.nonce);
    }

    const head: HTMLHeadElement = document.getElementsByTagName("head")[0];
    head.appendChild(s);
  };
}
