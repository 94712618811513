import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { map, Observable } from "rxjs";
import { ApiResponseDTO } from "../common.types";
import { VisitTalentDTO, VisitMissionPartnerDTO } from "./visits.types";

@Injectable({ providedIn: "root" })
export class VisitsApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadVisitTalentList(): Observable<Array<VisitTalentDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/visit/visits/talent`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.visits;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  loadVisitMissionPartnerList(): Observable<Array<VisitMissionPartnerDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/visit/visits/mission-partner`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.visits;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

}
