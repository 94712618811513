import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmploymentDurationLabelComponent } from "./employment-duration-label.component";

@NgModule({
  declarations: [EmploymentDurationLabelComponent],
  imports: [CommonModule],
  exports: [
    EmploymentDurationLabelComponent
  ]
})
export class EmploymentDurationLabelModule {}
