import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OldTrackRecordViewComponent } from "./old-track-record-view.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { ReactiveFormsModule } from "@angular/forms";
import { TrackRecordsInputModule } from "../track-record-input/track-records-input.module";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TranslateModule } from "@ngx-translate/core";
import { NzTagModule } from "ng-zorro-antd/tag";

@NgModule({
  declarations: [OldTrackRecordViewComponent],
    imports: [
        CommonModule,
        NzGridModule,
        ReactiveFormsModule,
        TrackRecordsInputModule,
        NzButtonModule,
        NzIconModule,
        TranslateModule,
        NzTagModule,
    ],
  exports: [OldTrackRecordViewComponent],
})
export class OldTrackRecordViewModule {}
