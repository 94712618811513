import { Component, Inject, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { Talent } from "../../../../api/sign-up/signup.types";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Reference, ReferenceRequest } from "../../../../api/references/references.types";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { Observable, Subject, take, takeUntil } from "rxjs";
import { UserService } from "../../../../api/user/user.service";
import { CommonService } from "../../../../api/common.service";
import { Store } from "@ngrx/store";
import { jobOfferFeature, JobOfferState } from "../mission-partner-job-offers/job-offer-data/store/job-offer.reducer";
import { messagesFeature, MessagesState } from "../../../../common-componnets/messages/store/messages.reducer";
import { MessagesService } from "../../../../api/messages/messages.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { InitiateConversationRequest, MissionPartnerMessageSuggest } from "../../../../api/messages/messages.types";
import { MessagesAction } from "../../../../common-componnets/messages/store/messages.action";
import { CreateConversationDialogComponent } from "../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.component";
import { AuthorizationService } from "../../../../api/authorization/authorization.service";

@Component({
  selector: "mh-mission-partner-view-talent",
  templateUrl: "./mission-partner-view-talent.component.html",
  styleUrls: ["./mission-partner-view-talent.component.less"],
})
export class MissionPartnerViewTalentComponent implements OnInit, OnDestroy {
  talentId = "";
  talent: Talent | undefined;
  avatarUrl = "";
  cvList: NzUploadFile[] = [];
  fileList: NzUploadFile[] = [];
  references: Reference[] = [];
  referencesRequest: ReferenceRequest[] = [];
  jobOfferId?: string;
  conversationId?: string;
  private destroy$ = new Subject<void>();
  hasReviewedStatus$: Observable<boolean>;
  hasSelfServiceStatus$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private readonly store: Store<{ [jobOfferFeature]: JobOfferState; [messagesFeature]: MessagesState }>,
    private messagesService: MessagesService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private router: Router,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private authorizationService: AuthorizationService,
  ) {
    this.jobOfferId = this.route.snapshot.queryParams["jobOfferId"];
    this.hasReviewedStatus$ = this.authorizationService.hasReviewedStatus().pipe(takeUntil(this.destroy$));
    this.hasSelfServiceStatus$ = this.authorizationService.hasSelfServiceStatus().pipe(takeUntil(this.destroy$));
  }

  ngOnInit() {
    const accountId = this.route.snapshot.paramMap.get("id");
    if (accountId) {
      this.talentId = accountId;
      this.loadTalentUser();
      this.loadConversation();
    }
  }

  private loadTalentUser() {
    this.userService
      .getTalentUserById(this.talentId)
      .pipe(take(1))
      .subscribe((talent) => {
        if (talent) {
          this.talent = talent.data;
          this.cvList = talent.data.cv ? [talent.data.cv] : [];
          this.fileList = talent.files;
          this.avatarUrl = this.commonService.toAbsUrl(talent.data.avatar?.avatarUrl);
          this.references = talent.references;
        }

        if (this.route.snapshot.queryParams["openConversation"]) {
          this.openCreateConversationModal();
        }
      });
  }

  private loadConversation() {
    this.messagesService
      .loadConversationByAccountId(this.talentId)
      .pipe(take(1))
      .subscribe((conversation) => {
        if (conversation?.id) {
          this.conversationId = conversation?.id;
        }
      });
  }

  openCreateConversationModal() {
    if (!this.talent) {
      return;
    }
    this.modal.create({
      nzContent: CreateConversationDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        suggestRequest: { talentId: this.talentId, jobOpportunityId: this.jobOfferId } as MissionPartnerMessageSuggest,
      },
      nzWidth: 800,
      nzOnOk: (modalCmp) => this.doCreateConversation(modalCmp.formGroup.value),
    });
  }

  doCreateConversation(formData: Partial<InitiateConversationRequest>) {
    if (!this.jobOfferId) {
      return;
    }
    this.messagesService
      .initiateConversation(this.talentId, formData.messageText as string, this.jobOfferId)
      .subscribe((conversation) => {
        if (conversation) {
          this.notificationService.success("", this.translateService.instant("notification.success"));
          this.store.dispatch(MessagesAction.initiateConversationSuccess({ data: conversation }));
          this.router.navigate(["/mission-partner", "inbox", "conversation", conversation.id]);
        } else {
          this.notificationService.error("", this.translateService.instant("notification.error"));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
