import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccountCommentsComponent } from "./account-comments.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { ReactiveFormsModule } from "@angular/forms";
import { NzIconModule } from "ng-zorro-antd/icon";

@NgModule({
  declarations: [AccountCommentsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzListModule,
    NzCommentModule,
    NzAvatarModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzIconModule,
  ],
  exports: [AccountCommentsComponent],
})
export class AccountCommentsModule {}
