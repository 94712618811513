import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MicroSiteTestimonialsComponent } from "./micro-site-testimonials.component";
import { NzListModule } from "ng-zorro-antd/list";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzGridModule } from "ng-zorro-antd/grid";

@NgModule({
  declarations: [MicroSiteTestimonialsComponent],
  imports: [
    CommonModule,
    NzListModule,
    NzDividerModule,
    TranslateModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzCardModule,
    NzGridModule,
  ],
  exports: [MicroSiteTestimonialsComponent],
})
export class MicroSiteTestimonialsModule {}
