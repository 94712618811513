import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Talent } from "../../api/sign-up/signup.types";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Reference, ReferenceRequest } from "../../api/references/references.types";
import { differenceInYears } from "date-fns";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { AccountType } from "../../pages/login-page/login-page-data/login.types";
import { map, Observable, tap } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";

@Component({
  selector: "mh-profile-preview",
  templateUrl: "./profile-preview.component.html",
  styleUrls: ["./profile-preview.component.less"],
})
export class ProfilePreviewComponent implements OnInit {
  @Input() talent!: Talent;
  @Input() trustedView = false;
  @Input() avatarUrl = '';
  @Input() cvList: NzUploadFile[] = [];
  @Input() fileList: NzUploadFile[] = [];
  @Input() references: Reference[] = [];
  @Input() referencesRequest: ReferenceRequest[] = [];
  @Input() jobOfferId?: string;
  @Input() conversationId?: string;
  @Input() disableConversation = false;
  @Output() startConversation = new EventEmitter();
  @Input() profileEditable = false;
  @Input() viewerAccountType?: AccountType;
  @Output() signInAs = new EventEmitter<void>();
  @Output() talentUpdate = new EventEmitter<Talent>();
  audioUrl = '';
  isMobileView$: Observable<boolean>;

  get profileLink(): string {
    if (this.talent.profileLink) {
      const hasProtocol = this.talent.profileLink.startsWith('http') || this.talent.profileLink.startsWith('ftp');
      return hasProtocol ? this.talent.profileLink : `//${this.talent.profileLink}`;
    }
    return '';
  }

  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
        map((breakpoint) => breakpoint.matches),
        tap((mobileView) => {
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  ngOnInit() {
    this.audioUrl = this.talent.profileAudio?.fileUrl ? `${this.appConfig.siteUrl}/${this.talent.profileAudio?.fileUrl}` : '';
  }

  calculateAge(birthDate: Date): number {
    const currentDate = new Date();
    return differenceInYears(currentDate, birthDate);
  }

  onStartConversation(): void {
    this.startConversation.emit();
  }

  onUpdateKeySummary() {
    this.talentUpdate.emit(this.talent);
  }

  onSignInAs() {
    this.signInAs.emit();
  }

  protected readonly AccountType = AccountType;

}
