import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScrollToValidationErrorComponent } from "./scroll-to-validation-error.component";
import { ScrollToValidationErrorDirective } from "./scroll-to-validation-error.directive";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ScrollToValidationErrorComponent, ScrollToValidationErrorDirective],
  imports: [CommonModule, NzAlertModule, TranslateModule],
  exports: [
    ScrollToValidationErrorDirective
  ]
})
export class ScrollToValidationErrorModule {}
