import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileComplianceKpiComponent } from "./profile-compliance-kpi.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ExplanationLabelModule } from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzSelectModule } from "ng-zorro-antd/select";

@NgModule({
  declarations: [ProfileComplianceKpiComponent],
  imports: [
    CommonModule,
    NzFormModule,
    NzPageHeaderModule,
    ReactiveFormsModule,
    TranslateModule,
    ExplanationLabelModule,
    NzInputModule,
    NzButtonModule,
    NzWaveModule,
    NzSelectModule,
  ],
  exports: [ProfileComplianceKpiComponent],
})
export class ProfileComplianceKpiModule {}
