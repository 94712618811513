import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StrictEmailValidator } from "@momhunting/core";
import { TranslateService } from "@ngx-translate/core";
import { LoginData } from "../../../../login-page/login-page-data/login.types";
import { LoginService } from "../../../../login-page/login-page-data/login.service";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "mh-mission-partner-settings-email",
  templateUrl: "./mission-partner-settings-email.component.html",
  styleUrls: ["./mission-partner-settings-email.component.less"],
})
export class MissionPartnerSettingsEmailComponent implements OnInit {
  @Input() loginData!: LoginData;
  public formGroup!: FormGroup;
  changeEmailMode = false;

  get saveDisabled(): boolean {
    return this.loginData.email == this.formGroup.get('email')?.value;
  }

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private loginService: LoginService,
    private modal: NzModalService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      email: [
        { disabled: !this.changeEmailMode, value: this.loginData.email },
        [Validators.required, StrictEmailValidator],
      ],
    });
  }

  onChangeEmail(mailInputEl: HTMLInputElement) {
    this.changeEmailMode = true;
    this.formGroup.controls["email"].enable();
    mailInputEl.focus();
  }

  onSaveEmail() {
    if (!this.isEmailValid()) return;
    const emailControl = this.formGroup.get("email");
    this.loginService.validateEmailChange(emailControl?.value).subscribe((response) => {
      if (response && !response.errorKey) {
        this.modal.confirm({
          nzTitle: this.translateService.instant("settings.email-changed.dialog.title"),
          nzContent: this.translateService.instant("settings.email-changed.dialog.content"),
          nzOkText: this.translateService.instant("settings.email-changed.dialog.ok"),
          nzCancelText: this.translateService.instant("settings.email-changed.dialog.cancel"),
          nzOkType: "primary",
          nzOkDanger: true,
          nzOnOk: () => this.emailChangeRequested(),
        });
      } else if (response.errorKey === 'EMAIL_ALREADY_EXISTS') {
        emailControl?.setErrors({ emailAlreadyExists: true });
      }
    });
  }

  emailChangeRequested() {
    const emailControl = this.formGroup.get("email");
    this.loginService.emailChange(emailControl?.value).subscribe((result) => {
      if (result) {
        this.loginService.logout();
      }
    });
  }

  private isEmailValid() {
    const emailControl = this.formGroup.get("email");
    if (emailControl && emailControl.invalid) {
      emailControl.markAsDirty();
      emailControl.updateValueAndValidity({ onlySelf: true });
      return false;
    }
    return true;
  }
}
