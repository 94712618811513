import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { filter, map, Observable, take } from "rxjs";
import { LoginService } from "./login.service";
import { Store } from "@ngrx/store";
import * as actions from "./store/login.action";
import { isUserDataLoadedOrLoading, selectLoginData } from "./store/login.selectors";
import { loginFeatureName, LoginState } from "./store/login.reducer";
import { AccountType, LoginData } from "./login.types";
import { switchMap } from "rxjs/operators";
import { ForwardPage } from "../../../common-componnets/common-types";
import { getForwardPageUrl } from "../../../api/authorization/forward-page";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({ providedIn: "root" })
export class LoginGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private store: Store<{ [loginFeatureName]: LoginState }>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.loginService.isAuthenticated()) {
      this.loadUserDataIfNotLoaded();
      let forwardPage;
      let forwardParam;
      try {
        forwardParam = route.queryParams["forwardPage"];
        forwardPage = forwardParam ? (JSON.parse(atob(forwardParam)) as ForwardPage) : undefined;
      } catch (e) {
        console.error(e + " forwardParam=" + forwardParam);
        Sentry.captureException({ error: e, forwardParam });
      }
      return this.checkIfAllowedToUseSite(route.url.toString(), forwardPage);
    } else {
      this.loginService.logout();
      return false;
    }
  }
  private loadUserDataIfNotLoaded() {
    this.store
      .select(isUserDataLoadedOrLoading)
      .pipe(take(1))
      .subscribe((loadedOrLoading) => {
        if (!loadedOrLoading) {
          this.store.dispatch(actions.getUserByToken({ token: this.loginService.getToken() }));
        }
      });
  }

  private checkIfAllowedToUseSite(url: string, forwardPage?: ForwardPage): Observable<boolean> {
    return this.store
      .select((state) => state.login)
      .pipe(
        filter((state) => state.status.loaded),
        take(1),
        switchMap(() => {
          return this.store.select(selectLoginData).pipe(
            map((loginData: LoginData) => {
              if (!(loginData.token || loginData.email)) {
                this.loginService.logout();
                return false;
              }
              if (
                (loginData.accountType === AccountType.MISSION_PARTNER && !url.startsWith("mission-partner")) ||
                (loginData.accountType === AccountType.TALENT && !url.startsWith("talent")) ||
                (loginData.accountType === AccountType.ADMIN && !url.startsWith("admin"))
              ) {
                this.navigateByType(loginData.accountType, forwardPage);
                return false;
              }
              return true;
            }),
          );
        }),
      );
  }

  private navigateByType(type: AccountType | undefined, forwardPage?: ForwardPage) {
    if (type === AccountType.MISSION_PARTNER) {
      if (forwardPage) {
        this.router.navigateByUrl(getForwardPageUrl(forwardPage));
      } else {
        this.router.navigate(["/mission-partner"]);
      }
    }
    if (type === AccountType.TALENT) {
      if (forwardPage) {
        this.router.navigateByUrl(getForwardPageUrl(forwardPage));
      } else {
        this.router.navigate(["/talent"]);
      }
    }
    if (type === AccountType.ADMIN) {
      this.router.navigate(["/admin"]);
    }
  }

  private navigateToSignUpByType(type: AccountType | undefined) {
    if (type === AccountType.MISSION_PARTNER) {
      this.router.navigate(["/sign-up-mission-partner"]);
    }
    if (type === AccountType.TALENT) {
      this.router.navigate(["/sign-up-talent"]);
    }
  }
}
