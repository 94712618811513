import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerInvoicesComponent } from "./mission-partner-invoices.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzTableModule } from "ng-zorro-antd/table";
import { RouterLink } from "@angular/router";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";

@NgModule({
  declarations: [MissionPartnerInvoicesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzToolTipModule,
    NzTableModule,
    RouterLink,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    FormsModule,
    NzIconModule,
    NzGridModule,
    NzPageHeaderModule,
  ],
})
export class MissionPartnerInvoicesModule {}
