import { Component, Inject, OnInit } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Conversation } from "libs/platform-pages/src/lib/api/messages/messages.types";

@Component({
  selector: "mh-agreement-terms-dialog.component",
  templateUrl: "./agreement-terms-dialog.component.html",
  styleUrls: ["./agreement-terms-dialog.component.less"],
})
export class AgreementTermsDialogComponent implements OnInit {
  formGroup!: UntypedFormGroup;
  conversation: Conversation;
  isRespond = false;

  constructor(
    @Inject(NZ_MODAL_DATA)
    public modalData: any,
    private fb: UntypedFormBuilder,
    private modal: NzModalRef,
  ) {
    this.conversation = this.modalData["conversation"];
    this.isRespond = this.modalData["isRespond"];
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      companyName: [
        { value: this.conversation.negotiation?.agreementTerms?.companyName || "", disabled: this.isRespond },
        [Validators.required],
      ],
      position: [
        { value: this.conversation.negotiation?.agreementTerms?.position || "", disabled: this.isRespond },
        [Validators.required],
      ],
      date: [
        { value: this.conversation.negotiation?.agreementTerms?.date || "", disabled: this.isRespond },
        [Validators.required],
      ],
      salary: [
        { value: this.conversation.negotiation?.agreementTerms?.salary || "", disabled: this.isRespond },
        [Validators.required],
      ],
      workingHours: [
        { value: this.conversation.negotiation?.agreementTerms?.workingHours || "", disabled: this.isRespond },
        [Validators.required],
      ],
      placeOfWork: [
        { value: this.conversation.negotiation?.agreementTerms?.placeOfWork || "", disabled: this.isRespond },
        [Validators.required],
      ],
    });
  }

  onReject() {
    this.modal.close('reject');
  }

  onConfirm() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    return this.modal.triggerOk();
  }
}
