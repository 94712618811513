import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit, Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Education } from "../../api/sign-up/signup.types";
import { Observable, Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { CmsNameValue, compareCmsNameValue } from "../../api/cms/cms.types";
import { ValidateEducation } from "./educations-input.validator";
import {endOfDay, isWithinInterval, sub} from "date-fns";
import { CmsCachedService } from "../../api/cms/cms-cached.service";

@Component({
  selector: "mh-educations-input",
  templateUrl: "./educations-input.component.html",
  styleUrls: ["./educations-input.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EducationsInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: EducationsInputComponent, multi: true },
  ],
})
export class EducationsInputComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() value!: Array<Education>;
  @Input() showAddBtn = true;
  @Output() educationSaved = new EventEmitter();
  private destroy$ = new Subject<void>();
  public degree$: Observable<Array<CmsNameValue>>;
  public editingIndex: number | null = null;
  private currentEducation: Education | null = null;
  private today = endOfDay(new Date());
  form = this.formBuilder.group({
    educations: this.formBuilder.array([]),
  });
  get educationsFormArray() {
    return this.form.controls["educations"] as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private modal: NzModalService,
    private cmsCachedService: CmsCachedService,
  ) {
    this.degree$ = this.cmsCachedService.getUserDataByName("degree");
  }

  disabledDate = (current: Date): boolean => {
    const minGraduationYear = sub(this.today, { years: 60 });
    return !isWithinInterval(current, { start: minGraduationYear, end: this.today });
  };

  ngOnInit() {
    this.createForm();
  }

  isInvalid(education: Education): boolean {
    return !ValidateEducation(education);
  }

  addEducation(education?: Education, emitEvent?: boolean) {
    if (!education) education = this.getBlankEducation();
    const educationForm = this.formBuilder.group({
      graduationYear: [education.graduationYear, Validators.required],
      universityName: [education.universityName, Validators.required],
      degree: [education.degree, Validators.required],
      courseOfStudy: [education.courseOfStudy, Validators.required],
    });
    this.educationsFormArray.insert(0, educationForm, { emitEvent });
  }

  addNewEducation() {
    if(this.editingIndex !== null) return;
    this.addEducation();
    this.toggleEditing(0, false);
    this.changeDetectorRef.markForCheck();
  }

  confirmDeleteEducation(index: number) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("confirm.delete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.doDeleteTrackRecord(index),
    });
  }

  doDeleteTrackRecord(index: number) {
    this.educationsFormArray.removeAt(index);
    this.notifyOfChange();
    this.changeDetectorRef.markForCheck();
  }

  cancelEducationEdit(index: number) {
    if (this.currentEducation) {
      this.educationsFormArray.at(index).patchValue(this.currentEducation);
    } else {
      this.doDeleteTrackRecord(index);
    }
    this.toggleEditing(null, false);
  }

  toggleEditing(index: number | null, saveCurrentEducation = true) {
    this.editingIndex = index;
    if (index !== null && saveCurrentEducation) {
      this.currentEducation = this.educationsFormArray.at(index).value;
    } else {
      this.currentEducation = null;
    }
    this.notifyOfChange();
  }

  saveEducation(event: Event, index: number) {
    event.preventDefault();
    if (this.educationsFormArray.at(index).invalid) {
      Object.values((this.educationsFormArray.at(index) as FormGroup)["controls"]).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.sortTrackRecordFormArray();
    this.educationsFormArray.controls[index].markAllAsTouched();
    if (!this.educationsFormArray.controls[index].valid) return;
    this.toggleEditing(null);
    this.educationSaved.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private createForm() {
    const currentEducations = this.value ? this.value : [];
    this.initializeFormArray(currentEducations, false);
    this.educationsFormArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: Education[]) => {
      this.value = value;
    });
  }

  private initializeFormArray(educations: Array<Education>, emitEvent: boolean) {
    this.educationsFormArray.clear({ emitEvent });
    educations.forEach((education: Education) => {
      this.addEducation(education, emitEvent);
    });
    this.sortTrackRecordFormArray();
    if (!emitEvent) this.changeDetectorRef.markForCheck();
  }

  private sortTrackRecordFormArray() {
    this.educationsFormArray.controls = this.educationsFormArray.controls.sort(
      (a: AbstractControl, b: AbstractControl) => {
        const aEducation = a.value as Education;
        const bEducation = b.value as Education;
        const aGraduationYear = aEducation.graduationYear ? aEducation.graduationYear?.getTime() || 0 : 0;
        const bGraduationYear = bEducation.graduationYear ? bEducation.graduationYear?.getTime() || 0 : 0;
        return bGraduationYear - aGraduationYear;
      },
    );
  }

  private getBlankEducation(): Education {
    return {
      graduationYear: undefined,
      universityName: "",
      degree: undefined,
      courseOfStudy: "",
    };
  }

  private notifyOfChange() {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  onChange = (value: Array<Education>) => {};
  onTouched = () => {};

  writeValue(value: Array<Education>) {
    this.value = value;
    if (this.educationsFormArray) {
      this.initializeFormArray(this.value, false);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const companyNameControl = this.educationsFormArray.controls.map((control) => control.get("companyName"));

    const invalidControls = [companyNameControl].filter((control) => {
      if (this.editingIndex !== null) {
        return control && !control[this.editingIndex]?.valid;
      }
      return false;
    });

    const allInputsValid = invalidControls.length === 0;
    return control.value && allInputsValid && this.editingIndex === null ? null : { required: true };
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
