import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExplanationLabelComponent } from "./explanation-label.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzDividerModule } from "ng-zorro-antd/divider";

@NgModule({
  declarations: [ExplanationLabelComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzButtonModule,
    NzIconModule,
    NzPopoverModule,
    NzDividerModule,
  ],
  exports: [ExplanationLabelComponent],
})
export class ExplanationLabelModule {}
