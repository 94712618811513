import { Injectable } from "@angular/core";
import { Invoice, InvoiceDTO } from "./invoices.types";

@Injectable({
  providedIn: "root",
})
export class InvoicesSerializer {
  deserializeInvoices(dtos: Array<InvoiceDTO>): Array<Invoice> {
    return dtos.map((dto) => this.deserializeInvoice(dto));
  }

  deserializeInvoice(dto: InvoiceDTO): Invoice {
    return {
      ...dto,
      missionPartnerId: dto.missionPartnerId?.internalId,
      date: new Date(dto.date),
      performanceMonth: new Date(+dto.performanceMonth),
    };
  }

  serializeInvoice(invoice: Invoice): InvoiceDTO {
    return {
      ...invoice,
      missionPartnerId: { internalId: invoice.missionPartnerId },
      date: invoice.date.getTime(),
      performanceMonth: invoice.performanceMonth.getTime(),
    };
  }
}
