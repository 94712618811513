import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessagesConversationListComponent } from "./messages-conversation-list.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { ConversationTranslatePipe } from "../../conversation-translate.pipe";

@NgModule({
  declarations: [MessagesConversationListComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzGridModule,
    NzIconModule,
    NzTableModule,
    NzTagModule,
    NzToolTipModule,
    TranslateModule,
    NzBadgeModule,
    NzAvatarModule,
    ConversationTranslatePipe,
  ],
  exports: [MessagesConversationListComponent],
})
export class MessagesConversationListModule {}
