import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { MissionPartnerDTO, TalentDataDTO, TalentDTO } from "../sign-up/signup-api.types";
import { catchError, map, Observable } from "rxjs";
import { ApiPaginatedResponseDTO, ApiResponseDTO, initialPaginatedContent, PaginatedContent } from "../common.types";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { KnownFrom } from "../sign-up/signup.types";
import { AccountFilterDTO } from "../../pages/home-admin/home-admin-data/account.types";
import {AccessGrant, AccessGrantDTO} from "../messages/messages.types";

@Injectable({ providedIn: "root" })
export class ProfileApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadTalentProfile(): Observable<TalentDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  loadMissionPartnerProfile(): Observable<MissionPartnerDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  talentUpdate(user: TalentDTO): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/user/talent/update`, user).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  missionPartnerUpdate(user: MissionPartnerDTO): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/user/mission-partner/update`, user).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  uploadUserAvatar(formData: FormData) {
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data");
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/user/avatar`, formData, {
        headers,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  updateOpenStatus(open: boolean): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/user/talent/status`, { open }).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  loadFieldSuggestions(searchTerm: string, fieldName: string): Observable<Array<string>> {
    const params = new HttpParams({
      fromObject: { searchTerm },
    });
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/talent/${fieldName}`, { params }).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.results.data || [];
      }),
    );
  }

  linkedInAuth(code: string): Observable<any> {
    const body = {
      grant_type: "authorization_code",
      code,
      redirect_uri: "https://www.momhunting.anotheria-services.net/linkedin/redirect",
      client_id: "78v1ct87ykvhnv",
      client_secret: "CsMwqeC6IQJO1mlQ",
    };
    return this.httpClient.post<ApiResponseDTO>(`https://www.linkedin.com/oauth/v2/accessToken`, body).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  loadTalentProfileAnonymised(): Observable<TalentDataDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/anonymised/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  loadTalentProfileTrusted(): Observable<TalentDataDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/trusted/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  saveKnownFrom(knownFrom: KnownFrom): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/registration/known-from`, knownFrom).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  getTalentParsed(): Observable<TalentDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/talent/parsed`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.results || {};
      }),
    );
  }

  loadMissionPartnerList(filter: AccountFilterDTO): Observable<PaginatedContent<MissionPartnerDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<any>>(`${this.basePath}/user/mission-partner/list`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  requestProfileAccess(talentId: string): Observable<AccessGrantDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/profile-access/request/${talentId}`, { talentId })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data || {};
        }),
      );
  }

  grantProfileAccess(missionPartnerId: string): Observable<AccessGrantDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/profile-access/grant/${missionPartnerId}`, { missionPartnerId })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data || {};
        }),
      );
  }

  rejectProfileAccess(missionPartnerId: string): Observable<AccessGrantDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/profile-access/reject/${missionPartnerId}`, { missionPartnerId })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results || {};
        }),
      );
  }

}
