import { Injectable } from "@angular/core";
import { LoginData, LoginDataDTO, UserStateDTO } from "./login.types";

@Injectable({
  providedIn: "root",
})
export class LoginSerializer {
  deserializeUserState(dto: UserStateDTO): LoginData {
    return {
      token: undefined,
      accountId: dto.accountId.internalId,
      accountType: dto.accountType,
      email: dto.email,
      accountStatuses: dto.accountStatuses,
      adminOverrideMode: dto.adminOverrideMode,
    };
  }

  deserializeLoginData(dto: LoginDataDTO): LoginData {
    return {
      token: dto.auth_token,
      accountId: dto.user_state.accountId.internalId,
      accountType: dto.user_state.accountType,
      email: dto.user_state.email,
      accountStatuses: dto.user_state.accountStatuses,
      adminOverrideMode: dto.user_state.adminOverrideMode,
    };
  }
}
