import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewJobOfferLocationComponent } from "./view-job-offer-location.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [ViewJobOfferLocationComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ViewJobOfferLocationComponent],
})
export class ViewJobOfferLocationModule {}
