import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MissionPartner } from "../../../../api/sign-up/signup.types";
import { Subject, takeUntil } from "rxjs";
import { flattenControls } from "@momhunting/common-ui";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-signup-mission-partner-contact",
  templateUrl: "./signup-mission-partner-contact.component.html",
  styleUrls: ["./signup-mission-partner-contact.component.less"],
})
export class SignupMissionPartnerContactComponent implements OnInit, OnDestroy {
  @Input() profile!: MissionPartner;
  @Output() formChange = new EventEmitter<MissionPartner>();
  @Output() updateUser = new EventEmitter<void>();

  private readonly destroy$ = new Subject<void>();
  loading = false;
  public formGroup!: FormGroup;

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant("field.required.label"),
      invalid: this.translateService.instant("field.invalid.label"),
    },
  };

  constructor(private fb: FormBuilder, private translateService: TranslateService) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      address: [this.profile.address, [Validators.required]],
      street: [this.profile.street, [Validators.required]],
      houseNumber: [this.profile.houseNumber, [Validators.required]],
      contactPerson: this.fb.group({
        title: [this.profile.contactPerson?.title, [Validators.required]],
        firstName: [this.profile.contactPerson?.firstName, [Validators.required]],
        lastName: [this.profile.contactPerson?.lastName, [Validators.required]],
        department: [this.profile.contactPerson?.department, [Validators.required]],
        phoneNumber: [this.profile.contactPerson?.phoneNumber, []],
      }),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.profile,
        ...value,
        contactPerson: {
          ...this.profile.contactPerson,
          ...value.contactPerson,
        },
      }),
    );
  }

  submitForm() {
    if (this.formGroup.invalid) {
      flattenControls(this.formGroup).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.updateUser.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
