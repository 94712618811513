import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AssessmentService } from "./assessment.service";
import { MAAssessmentFormError, MaturityAssessment } from "./assessment.types";
import { ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ExternalAssessmentCheckResolver {
  constructor(private assessmentService: AssessmentService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any | MAAssessmentFormError> {
    return this.assessmentService.checkMaturityAssessmentByToken(route.params["token"]);
  }
}
