<div nz-row [nzGutter]="12" [formGroup]="controlGroup">
  <div nz-col [nzSpan]="12">
    <nz-form-item>
      <nz-form-label *ngIf="!usePlaceholder" [nzRequired]="!!validators">
        <mh-explanation-label [labelKey]="'salary.from.label'"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control [nzErrorTip]="minErrorTpl">
        <nz-select [nzSize]="nzSize" formControlName="min"
                   (ngModelChange)="onSalaryMinChange($event, true)"
                   [compareWith]="compareCmsNameValue"
                   [nzPlaceHolder]="usePlaceholder ? ('from.label' | translate) : ''"
                   nzAllowClear
                   nzShowSearch
        >
          <nz-option [nzLabel]="option.name" [nzValue]="option" *ngFor="let option of salaryOptions"></nz-option>
        </nz-select>
      </nz-form-control>
      <ng-template #minErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
      </ng-template>
    </nz-form-item>
  </div>
  <div nz-col [nzSpan]="12">
    <nz-form-item>
      <nz-form-label *ngIf="!usePlaceholder" [nzRequired]="!!validators">
        <mh-explanation-label [labelKey]="'salary.to.label'"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control [nzErrorTip]="maxErrorTpl">
        <nz-select [nzSize]="nzSize" formControlName="max"
                   [compareWith]="compareCmsNameValue"
                   [nzPlaceHolder]="usePlaceholder ? ('to.label' | translate) : ''"
                   nzAllowClear
                   nzShowSearch
        >
          <nz-option [nzLabel]="option.name" [nzValue]="option" *ngFor="let option of salaryMaxOptions"></nz-option>
        </nz-select>
      </nz-form-control>
      <ng-template #maxErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
      </ng-template>
    </nz-form-item>
  </div>
</div>
