<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="newsletter">
            <span>{{'settings.newsletter.checkbox.label' | translate}}</span>
          </label>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
