<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label nzRequired>{{ "settings.email.label" | translate }}</nz-form-label>
        <nz-form-control [nzErrorTip]="emailErrorTpl">
          <input
            nz-input
            nzSize="default"
            [placeholder]="'reg.talent.email.placeholder' | translate"
            formControlName="email"
            autocomplete="new-password"
            #emailInputEl
          />
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('email')">{{'field.invalid' | translate}}</ng-container>
            <ng-container *ngIf="control.hasError('emailAlreadyExists')">{{'EMAIL_ALREADY_EXISTS' | translate}}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <button nz-button [nzType]="'default'" nzBlock *ngIf="!changeEmailMode"
              (click)="onChangeEmail(emailInputEl)">
        {{ "change-email.button" | translate }}
      </button>
      <button nz-button [nzType]="'primary'" nzBlock *ngIf="changeEmailMode"
              [disabled]="saveDisabled" (click)="onSaveEmail()">
        {{ "save.button" | translate }}
      </button>
    </div>
  </div>
</form>
