import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NzCheckBoxOptionInterface } from "ng-zorro-antd/checkbox/checkbox-group.component";
import { MAAnswerOption, MAAnswerOptionType } from "../../../api/assessment/assessment.types";
import { CmsNameValue } from "../../../api/cms/cms.types";

@Component({
  selector: "mh-assessment-dropdown-select",
  templateUrl: "./assessment-dropdown-select.component.html",
  styleUrls: ["./assessment-dropdown-select.component.less"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssessmentDropdownSelectComponent), multi: true },
  ],
})
export class AssessmentDropdownSelectComponent implements ControlValueAccessor {
  @Input() set options(val: Array<MAAnswerOption>) {
    this._options = val
      .filter((maOpt) => maOpt.type === MAAnswerOptionType.SELECTABLE);
  }
  _options: Array<MAAnswerOption> = [];
  private selection: Array<MAAnswerOption> = [];

  selectedOption?: MAAnswerOption;

  constructor() {}

  onSelectModelChange(): void {
    this.selection.forEach((sel) => (sel.selected = sel.id === this.selectedOption?.id));
    this.onChange(this.selection);
  }

  onChange = (value: Array<MAAnswerOption>) => {};
  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  writeValue(value: Array<MAAnswerOption>) {
    value = value || [];
    this.selectedOption = value.find((val) => val.selected);
    this.selection = value;
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  protected readonly compareCheckBoxOption = (o1: MAAnswerOption, o2: MAAnswerOption): boolean =>
    o1 && o2 ? o1.id === o2.id : o1 === o2;
}
