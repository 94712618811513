import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExternalAssessmentQuestionerRoutingModule } from "./external-assessment-questioner-routing.module";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { TranslateModule } from "@ngx-translate/core";
import { LocaleSelectModule } from "@momhunting/common-ui";
import {
  CheckBoxGroupSelectModule
} from "../../../../../common-ui/src/lib/check-box-group-select/check-box-group-select.module";
import {AssessmentNavigatorModule} from "./assessment-navigator/assessment-navigator.module";
import {
  ExternalAssessmentQuestionertModule
} from "./external-assessment-questioner/external-assessment-questionert.module";

@NgModule({
  imports: [
    CommonModule,
    ExternalAssessmentQuestionerRoutingModule,
    NzButtonModule,
    NzGridModule,
    NzLayoutModule,
    CheckBoxGroupSelectModule,
    TranslateModule,
    LocaleSelectModule,
    AssessmentNavigatorModule,
    ExternalAssessmentQuestionertModule
  ],
})
export class ExternalAssessmentQuestionerModule {}
