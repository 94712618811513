import { Component } from "@angular/core";
import { MhNotificationSettings } from "../../../../api/notification/notification.types";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "mh-talent-notifications",
  templateUrl: "./talent-notifications.component.html",
  styleUrls: ["./talent-notifications.component.less"],
})
export class TalentNotificationsComponent {
  notificationsSettings: MhNotificationSettings;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.notificationsSettings = this.activatedRoute.snapshot.data["notificationsSettings"];
  }
}
