import {combineReducers, createReducer, on} from "@ngrx/store";
import {
  Conversation,
  ConversationStatus, MessagingAttachment,
  MessagingMessage
} from "../../../api/messages/messages.types";
import {MessagesAction} from "./messages.action";
import {initialPaginatedContent, PaginatedContent} from "../../../api/common.types";
import {compareAsc, compareDesc} from "date-fns";
import {generateMessagePreview} from "../conversation-translate.types";

export const messagesFeature = "messages";

export type StatusState = {
  loaded: boolean;
  loading: boolean;
  error: unknown;
};

export type ConversationListState = {
  status: StatusState;
  data: PaginatedContent<Conversation>;
};

export type ConversationState = {
  status: StatusState;
  data: Conversation | undefined;
};

export type UnseenMessageCountState = {
  status: StatusState;
  data: number;
};

const initialStatusState: StatusState = {
  loading: false,
  loaded: false,
  error: null,
};

export const ConversationListReducer = createReducer<ConversationListState>(
  { status: initialStatusState, data: initialPaginatedContent },
  on(MessagesAction.loadConversationList, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MessagesAction.loadConversationListSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data,
    };
  }),
  on(MessagesAction.loadConversationListError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),

  on(MessagesAction.closeConversation, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MessagesAction.closeConversationSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: { ...state.data, items: changeConversationStatus(state.data?.items, action.id, ConversationStatus.ENDED) },
    };
  }),
  on(MessagesAction.closeConversationError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),

  on(MessagesAction.deleteConversation, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MessagesAction.deleteConversationSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: { ...state.data, items: state.data?.items.filter((conversation) => conversation.id !== action.id) },
    };
  }),
  on(MessagesAction.deleteConversationError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(MessagesAction.increaseConversationMessageCount, (state, action) => {
    return {
      ...state,
      data: { ...state.data, items: increaseConversationMessageCount(state.data?.items, action.message) },
    };
  })
);

export const ConversationReducer = createReducer<ConversationState>(
  { status: initialStatusState, data: undefined },
  on(MessagesAction.loadConversation, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MessagesAction.loadConversationSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data,
    };
  }),
  on(MessagesAction.loadConversationError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(MessagesAction.updateAgreementTerms, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MessagesAction.updateAgreementTermsSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: { ...state.data as Conversation, negotiation: action.data.negotiation },
    };
  }),
  on(MessagesAction.updateAgreementTermsError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(MessagesAction.receiveMessageSuccess, (state, action) => {
    return {
      ...state,
      data: { ...state.data, messages: updateConversationMessage(state.data?.messages || [], action.message) },
    } as ConversationState;
  }),
  on(MessagesAction.updateMessageVideo, (state, action) => {
    return {
      ...state,
      data: { ...state.data, messages: updateVideoAttachment(state.data?.messages || [], action.message) },
    } as ConversationState;
  }),
  on(MessagesAction.loadOlderMessagesSuccess, (state, action) => {
    return {
      ...state,
      data: { ...state.data, messages: conversationAddOlderMessages(state.data?.messages || [], action.data) },
    } as ConversationState;
  }),

  on(MessagesAction.closeConversation, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MessagesAction.closeConversationSuccess, (state, {id, success}) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: (state.data?.id === id)?{ ...state.data, status: ConversationStatus.ENDED }:state.data,
    } as ConversationState;
  }),
  on(MessagesAction.closeConversationError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(MessagesAction.requestProfileAccessSuccess, (state, {data, conversationId}) => {
    return {
      ...state,
      data: (state.data?.id === conversationId)?{ ...state.data, accessGrant: data }:state.data,
    } as ConversationState;
  }),
  on(MessagesAction.rejectProfileAccessSuccess, (state, {data}) => {
    return {
      ...state,
      data: { ...state.data,  accessGrant: data },
    } as ConversationState;
  }),
  on(MessagesAction.grantProfileAccessSuccess, (state, {data}) => {
    return {
      ...state,
      data: { ...state.data,  accessGrant: data },
    } as ConversationState;
  }),
  on(MessagesAction.updateNegotiationStatus, (state, {status}) => {
    return {
      ...state,
      data: { ...state.data,  negotiation: {...state.data?.negotiation, status } },
    } as ConversationState;
  }),
);

export const UnseenMessageCountReducer = createReducer<UnseenMessageCountState>(
  { status: initialStatusState, data: 0 },
  on(MessagesAction.getUnseenMessageCount, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MessagesAction.getUnseenMessageCountSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.count,
    };
  }),
  on(MessagesAction.getUnseenMessageCountError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(MessagesAction.loadConversationListSuccess, (state, action) => {
    return {
      ...state,
      data: 0,
    };
  }),
  on(MessagesAction.increaseUnseenMessageCount, (state, action) => {
    return {
      ...state,
      data: state.data+1,
    };
  })
  );

export const updateConversationMessage = (
  list: Array<MessagingMessage>,
  newItem: MessagingMessage,
): Array<MessagingMessage> => {
  const matchesUpdate = [...list];
  const indexToUpdate = matchesUpdate.findIndex((item) => item.id === newItem.id);
  if (indexToUpdate !== -1) {
    matchesUpdate[indexToUpdate] = newItem;
  } else {
    matchesUpdate.push(newItem);
  }
  return matchesUpdate;
};

export const updateVideoAttachment = (
  list: Array<MessagingMessage>,
  newItem: MessagingMessage | Partial<MessagingMessage>,
): Array<MessagingMessage> => {
  const matchesUpdate = [...list];
  const indexToUpdate = matchesUpdate.findIndex((item) => item.attachment?.name === newItem.attachment?.name);
  if (indexToUpdate !== -1) {
    matchesUpdate[indexToUpdate] = {...matchesUpdate[indexToUpdate], ...newItem,
      update: true,
      attachment: {...matchesUpdate[indexToUpdate].attachment, ...newItem.attachment } as MessagingAttachment,
    };
  } else {
    matchesUpdate.push(newItem as MessagingMessage);
  }
  return matchesUpdate;
};

export const conversationAddOlderMessages = (
  list: Array<MessagingMessage>,
  newItems: Array<MessagingMessage>,
): Array<MessagingMessage> => {
  return [...newItems, ...list];
};

export const changeConversationStatus = (
  list: Array<Conversation>,
  conversationId: string,
  newStatus: ConversationStatus,
): Array<Conversation> => {
  return list.map((conversation) => {
    return conversation.id === conversationId ? { ...conversation, status: newStatus } : conversation
  });
};

export const increaseConversationMessageCount = (
  list: Array<Conversation>,
  message: MessagingMessage,
): Array<Conversation> => {
  return list.map((conversation) => {
    return conversation.id === message.conversationId
      ? { ...conversation,
        numberOfUnreadMessages: (conversation.numberOfUnreadMessages + 1),
        latestMessagePreview: generateMessagePreview(message),
        lastMessageTimestamp: message.creationTimestamp
      } : conversation
  }).sort((a, b) => compareDesc(a.lastMessageTimestamp, b.lastMessageTimestamp));
};

export interface MessagesState {
  conversationList: ConversationListState;
  conversation: ConversationState;
  unseenCounter: UnseenMessageCountState;
}

export const MessagesReducer = combineReducers<MessagesState>({
  conversationList: ConversationListReducer,
  conversation: ConversationReducer,
  unseenCounter: UnseenMessageCountReducer
});
