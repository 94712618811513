import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupMissionPartnerContactComponent } from "./signup-mission-partner-contact.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzInputModule } from "ng-zorro-antd/input";
import { ExplanationLabelModule } from "../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { LocationInputModule } from "../../../../../../../common-ui/src/lib/location-input/location-input.module";
import { SignupPhoneVerifyModule } from "../../../register-common-steps/signup-phone/signup-phone-verify.module";
import { NzIntlTelInputModule } from "@momhunting/common-ui";

@NgModule({
  declarations: [SignupMissionPartnerContactComponent],
  imports: [
    CommonModule,
    NzGridModule,
    TranslateModule,
    FormsModule,
    NzFormModule,
    NzButtonModule,
    NzWaveModule,
    NzInputModule,
    ReactiveFormsModule,
    ExplanationLabelModule,
    NzDatePickerModule,
    LocationInputModule,
    SignupPhoneVerifyModule,
    NzIntlTelInputModule,
  ],
  exports: [SignupMissionPartnerContactComponent],
})
export class SignupMissionPartnerContactModule {}
