<div [class.scrollable]="scrollable" #scrollContainer>
  <nz-list [nzItemLayout]="'horizontal'">
    <nz-comment
      *ngFor="let comment of comments"
      [nzAuthor]="comment.authorName"
      [nzDatetime]="comment.creationTimestamp"
    >
      <nz-comment-content>
        <div class="comment">
          <p>{{ comment.text }}</p>
          <div class="comment__actions">
            <button
              *ngIf="!editingCommentId || editingCommentId === comment.id"
              class="comment__edit-btn"
              nz-button
              nzType="link"
              nzShape="circle"
              (click)="startEditing(comment)"
            >
              <span nz-icon nzType="edit" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="editingCommentId === comment.id"
              class="comment__cancel-btn"
              nz-button
              nzType="link"
              nzShape="circle"
              (click)="cancelEditing()"
            >
              <span nz-icon nzType="close" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="!editingCommentId"
              class="comment__delete-btn"
              nz-button
              nzType="link"
              nzShape="circle"
              (click)="onDeleteComment(comment.id)"
            >
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </div>
        </div>
      </nz-comment-content>
    </nz-comment>
  </nz-list>
</div>

<nz-comment>
  <nz-comment-content>
    <form nz-form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="textErrorTpl">
          <textarea nz-input rows="4" formControlName="text"></textarea>
          <ng-template #textErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              {{ "admin.comment.error.required" | translate }}
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="submit-btn">
        <button nz-button nzType="primary">
          {{ (editingCommentId ? "admin.comment.button.update" : "admin.comment.button.add") | translate }}
        </button>
      </nz-form-item>
    </form>
  </nz-comment-content>
</nz-comment>
