import { Inject, Injectable } from "@angular/core";
import { APP_CONFIGURATION } from "../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../core/src/lib/app-config/config.types";
import { MediaType } from "../../../../core/src/lib/recording/recording.types";

@Injectable({ providedIn: "root" })
export class CommonService {
  constructor(@Inject(APP_CONFIGURATION) readonly appConfig: AppConfig) {}

  toAbsUrl(url: string | undefined): string {
    return url ? `${this.appConfig.siteUrl}/${url}` : "";
  }

  getFileType(filename: string): MediaType | undefined {
    const extension = this.getFileExtension(filename);
    const videoExtensions = ["mp4", "avi", "mkv", "mov", "webm"];
    const audioExtensions = ["mp3", "wav", "ogg", "aac"];

    if (videoExtensions.includes(extension)) {
      return MediaType.VIDEO;
    } else if (audioExtensions.includes(extension)) {
      return MediaType.AUDIO;
    } else {
      return undefined;
    }
  }

  private getFileExtension(filename: string): string {
    return filename.split(".").pop()?.toLowerCase() || "";
  }
}
