import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { MaturityAssessment, MaturityAssessmentStatus } from "../../../../api/assessment/assessment.types";
import { AdminAssessmentCreateComponent } from "./admin-assessment-create/admin-assessment-create.component";
import { AssessmentService } from "../../../../api/assessment/assessment.service";
import { AccountFilter } from "../../home-admin-data/account.types";
import { take } from "rxjs";
import { DocumentService } from "../../../../api/document/document.service";
import { compareDesc } from "date-fns";
import { AdminAssessmentHashtagsEditComponent } from "./admin-assessment-hashtags-edit/admin-assessment-hashtags-edit.component";

@Component({
  selector: "mh-admin-assessments-list",
  templateUrl: "./admin-assessment-list.component.html",
  styleUrls: ["./admin-assessment-list.component.less"],
})
export class AdminAssessmentListComponent implements OnInit {
  loading = false;
  preventDoubleFetch = true;

  assessments?: PaginatedContent<MaturityAssessment>;

  constructor(
    private modal: NzModalService,
    private readonly translateService: TranslateService,
    private viewContainerRef: ViewContainerRef,
    private assessmentService: AssessmentService,
    private documentService: DocumentService,
  ) {}

  ngOnInit() {
    this.fetchAssessmentList(this.getDefaultQueryParams());
  }

  fetchAssessmentList(filter: AccountFilter) {
    this.loading = true;
    this.assessmentService
      .loadMaturityAssessmentList(filter)
      .pipe(take(1))
      .subscribe((response) => {
        response.items.sort((a, b) => compareDesc(a.createdAt, b.createdAt));
        this.assessments = response;
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const { pageSize, pageIndex, sort } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
    };

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == "ascend" ? "ASC" : "DESC",
            field: sortField,
          },
        ],
      };
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchAssessmentList(filter);
  }

  confirmCompleteAssessment(assessment: MaturityAssessment) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("confirm.complete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.completeAssessment(assessment.id),
    });
  }

  completeAssessment(assessmentId: string) {
    this.assessmentService.complete(assessmentId).subscribe((success) => {
      if (success && this.assessments?.items) {
        const listUpdate = [...this.assessments.items];
        const indexToUpdate = listUpdate.findIndex((item) => item.id === assessmentId);
        if (indexToUpdate !== -1) {
          listUpdate[indexToUpdate] = { ...listUpdate[indexToUpdate], status: MaturityAssessmentStatus.COMPLETED };
        }
        this.assessments.items = listUpdate;
      }
    });
  }

  confirmUncompleteAssessment(assessment: MaturityAssessment) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("confirm.uncomplete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.uncompleteAssessment(assessment.id),
    });
  }

  uncompleteAssessment(assessmentId: string) {
    this.assessmentService.uncomplete(assessmentId).subscribe((success) => {
      if (success && this.assessments?.items) {
        const listUpdate = [...this.assessments.items];
        const indexToUpdate = listUpdate.findIndex((item) => item.id === assessmentId);
        if (indexToUpdate !== -1) {
          listUpdate[indexToUpdate] = { ...listUpdate[indexToUpdate], status: MaturityAssessmentStatus.ACTIVE };
        }
        this.assessments.items = listUpdate;
      }
    });
  }

  confirmDeleteReport(assessment: MaturityAssessment) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("confirm.assignment.delete.report"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.deleteReport(assessment.id),
    });
  }

  deleteReport(assessmentId: string) {
    this.assessmentService.deleteReport(assessmentId).subscribe((success) => {
      if (success && this.assessments?.items) {
        const listUpdate = [...this.assessments.items];
        const indexToUpdate = listUpdate.findIndex((item) => item.id === assessmentId);
        if (indexToUpdate !== -1) {
          listUpdate[indexToUpdate] = {
            ...listUpdate[indexToUpdate],
            resultFile: undefined,
            status: MaturityAssessmentStatus.COMPLETED,
          };
        }
        this.assessments.items = listUpdate;
      }
    });
  }

  confirmDeleteAssessment(assessment: MaturityAssessment) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("confirm.delete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.deleteAssessment(assessment.id),
    });
  }

  deleteAssessment(assessmentId: string) {
    this.assessmentService.delete(assessmentId).subscribe((success) => {
      if (success && this.assessments?.items) {
        this.fetchAssessmentList(this.getDefaultQueryParams());
      }
    });
  }

  openSetupAssessmentModal() {
    const modal = this.modal.create({
      nzContent: AdminAssessmentCreateComponent,
      nzMaskClosable: false,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 800,
    });

    modal.afterClose.subscribe((result: MaturityAssessment) => {
      if (result) {
        this.fetchAssessmentList(this.getDefaultQueryParams());
      }
    });
  }

  downloadAssessmentCSV(assessment: MaturityAssessment) {
    this.assessmentService.getMaturityAssessmentResults(assessment).subscribe();
  }

  downloadAllAssessmentCSV() {
    this.assessmentService.getAllMaturityAssessmentResults().subscribe();
  }

  filesSelected(fileList: FileList | null, assessment: MaturityAssessment) {
    if (fileList?.length) {
      this.uploadReport(fileList, assessment);
    }
  }

  uploadReport(fileList: FileList, assessment: MaturityAssessment) {
    if (fileList?.length) {
      const formData = new FormData();
      const file = fileList[0];
      formData.append("file", file, file.name);
      formData.append("maturityAssessmentId", assessment.id);
      this.documentService.uploadMAReport(formData).subscribe((response) => {
        if (response.resultFile && this.assessments?.items) {
          const listUpdate = [...this.assessments.items];
          const indexToUpdate = listUpdate.findIndex((item) => item.id === assessment.id);
          if (indexToUpdate !== -1) {
            listUpdate[indexToUpdate] = response;
          }
          this.assessments.items = listUpdate;
        }
      });
    }
  }

  editHashtags(assessment: MaturityAssessment) {
    this.openEditHashtagsModal(assessment);
  }

  openEditHashtagsModal(assessment: MaturityAssessment) {
    const modal = this.modal.create({
      nzContent: AdminAssessmentHashtagsEditComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 800,
      nzData: { assessment },
      nzMaskClosable: false,
    });

    modal.afterClose.subscribe((result: MaturityAssessment) => {
      if (result && this.assessments?.items) {
        console.log("result", result);
      }
    });
  }

  getDefaultQueryParams() {
    return {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: "",
    };
  }

  onCopyLinkClick(listEntry: MaturityAssessment) {
    listEntry.linkCopied = true;
    setTimeout(() => (listEntry.linkCopied = false), 2000);
  }

  protected readonly MaturityAssessmentStatus = MaturityAssessmentStatus;
  protected readonly setTimeout = setTimeout;
}
