import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentConversationComponent } from "./talent-conversation.component";
import {
  MessagesConversationModule
} from "../../../../../../common-componnets/messages/messages-conversation/messages-conversation.module";

@NgModule({
  declarations: [TalentConversationComponent],
  imports: [CommonModule, MessagesConversationModule],
})
export class TalentConversationModule {}
