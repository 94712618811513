import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import * as TalentProfileActions from "./talent-profile.action";
import { DashboardService } from "../../dashboard/dashboard.service";
import { ProfileService } from "../profile.service";

@Injectable()
export class TalentProfileEffects {
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private profileService: ProfileService,
  ) {}

  loadTalentProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TalentProfileActions.loadTalentProfile),
      switchMap(() =>
        this.profileService.loadTalentProfile().pipe(
          map((response) => TalentProfileActions.loadTalentProfileSuccess({ data: response })),
          catchError((error) => of(TalentProfileActions.loadTalentProfileError({ error }))),
        ),
      ),
    );
  });

  updateTalentProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TalentProfileActions.updateTalentProfile),
      switchMap(({data}) =>
        this.profileService.talentUpdate(data).pipe(
          map((success) => TalentProfileActions.updateTalentProfileSuccess({ data })),
          catchError((error) => of(TalentProfileActions.updateTalentProfileError({ error }))),
        ),
      ),
    );
  });

  loadTalentProfileCompleteness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TalentProfileActions.loadTalentProfileCompleteness),
      switchMap(() =>
        this.dashboardService.getTalentProfileCompleteness().pipe(
          map((response) => TalentProfileActions.loadTalentProfileCompletenessSuccess({ data: response })),
          catchError((error) => of(TalentProfileActions.loadTalentProfileCompletenessError({ error }))),
        ),
      ),
    );
  });
}
