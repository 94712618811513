import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MpJobOfferFormComponent } from "./mp-job-offer-form.component";
import { ExplanationLabelModule } from "../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { TranslateModule } from "@ngx-translate/core";
import { SalaryExpectationInputModule } from "../../../../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation-input.module";
import { LocationMultipleLargeInputModule } from "@momhunting/common-ui";
import { CountryInputModule } from "../../../../../../../../common-ui/src/lib/country-input/country-input.module";

@NgModule({
  declarations: [MpJobOfferFormComponent],
  imports: [
    CommonModule,
    ExplanationLabelModule,
    FormsModule,
    NzCheckboxModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    NzSliderModule,
    ReactiveFormsModule,
    TranslateModule,
    SalaryExpectationInputModule,
    LocationMultipleLargeInputModule,
    CountryInputModule,
  ],
  exports: [MpJobOfferFormComponent],
})
export class MpJobOfferFormModule {}
