import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { Talent } from "../../../../../api/sign-up/signup.types";
import { SignupService } from "../../../../../api/sign-up/signup.service";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: "mh-talent-settings-phone",
  templateUrl: "./talent-settings-phone.component.html",
  styleUrls: ["./talent-settings-phone.component.less"],
})
export class TalentSettingsPhoneComponent implements OnInit {
  @Input() talent!: Talent;
  public formGroup!: FormGroup;
  changePhoneMode = false;
  validationCode!: string;
  phoneSent = false;
  canSubmitCode = false;
  codeValidated = false;

  get canSubmitPhoneNumber(): boolean {
    return this.talent.phoneNumber != this.formGroup.get("phoneNumber")?.value;
  }

  get phoneControl(): AbstractControl | null {
    return this.formGroup.get("phoneNumber");
  }

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private modal: NzModalService,
    private signupService: SignupService,
    private cd: ChangeDetectorRef,
    private notificationService: NzNotificationService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      phoneNumber: [{ disabled: !this.changePhoneMode, value: this.talent.phoneNumber }, [Validators.required]],
    });
  }

  onStartChangePhoneNumber() {
    this.changePhoneMode = true;
    this.phoneControl?.enable();
  }

  submitPhone(): void {
    if (this.formGroup.valid) {
      this.sendPhoneForValidation();
    } else {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  sendPhoneForValidation() {
    const phoneNumber = this.formGroup.value.phoneNumber;
    this.signupService.phoneValidationSend(phoneNumber).subscribe((result) => {
      if (result) {
        this.phoneSent = true;
        this.cd.markForCheck();
      }
    });
  }
  onCodeChanged(code: string) {
    this.validationCode = code;
    this.canSubmitCode = false;
  }
  onCodeCompleted(code: string) {
    this.validationCode = code;
    this.canSubmitCode = true;
    this.submitCode();
  }
  submitCode() {
    const phoneNumber = this.formGroup.value.phoneNumber;
    this.signupService.phoneValidationCheck(phoneNumber, this.validationCode).subscribe((result) => {
      if (result) {
        this.codeValidated = true;
        this.phoneSent = false;
        this.changePhoneMode = false;
        this.phoneControl?.disable();
        this.cd.markForCheck();
        this.notificationService.success(
          this.translateService.instant("settings.phone-changed.title"),
          this.translateService.instant("settings.phone-changed.content"),
        );
      }
    });
  }

  onChangePhoneNumber() {
    this.phoneSent = false;
  }
}
