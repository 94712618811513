import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { NotificationService } from "./notification.service";
import { MhNotificationSettings } from "./notification.types";

@Injectable({
  providedIn: "root",
})
export class NotificationSettingsResolver {
  constructor(
    private readonly notificationService: NotificationService,
  ) {}

  resolve(): Observable<MhNotificationSettings> {
    return this.notificationService.loadNotificationSettings();
  }
}
