<ng-container *nzModalTitle>
  {{'admin.assessment.create.label' | translate}}
</ng-container>

<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'admin.filter.mission-partner.placeholder'"></mh-explanation-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            [nzPlaceHolder]="'select.placeholder' | translate"
            formControlName="missionPartnerId">
            <nz-option *ngFor="let option of missionPartners" [nzLabel]="option.companyName" [nzValue]="option.accountId.internalId"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">
    {{ "cancel.button" | translate }}
  </button>
  <button nz-button nzType="primary" [nzLoading]="uploading" (click)="onSave()">
    {{ "add.button" | translate }}
  </button>
</ng-container>
