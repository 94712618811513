import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssessmentNavigatorComponent } from "./assessment-navigator.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ExplanationLabelModule } from "../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { AssessmentCheckBoxGroupSelectModule } from "../assessment-check-box-group-select/check-box-group-select.module";
import { AssessmentRadioGroupSelectModule } from "../assessment-radio-group-select/assessment-radio-group-select.module";
import { AssessmentNumberSelectModule } from "../assessment-number-select/assessment-number-select.module";
import { AssessmentDropdownSelectModule } from "../assessment-dropdown-select/assessment-dropdown-select.module";
import { AssessmentNavigatorQuestionComponent } from "./assessment-navigator-question/assessment-navigator-question.component";
import { AssessmentNavigatorQuestionWrapperComponent } from "./assessment-navigator-question-wrapper/assessment-navigator-question-wrapper.component";

@NgModule({
  declarations: [
    AssessmentNavigatorComponent,
    AssessmentNavigatorQuestionComponent,
    AssessmentNavigatorQuestionWrapperComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzSpaceModule,
    NzWaveModule,
    TranslateModule,
    NzFormModule,
    ReactiveFormsModule,
    ExplanationLabelModule,
    NzInputModule,
    NzRadioModule,
    NzProgressModule,
    AssessmentCheckBoxGroupSelectModule,
    FormsModule,
    AssessmentRadioGroupSelectModule,
    AssessmentNumberSelectModule,
    AssessmentDropdownSelectModule,
  ],
  exports: [AssessmentNavigatorComponent],
})
export class AssessmentNavigatorModule {}
