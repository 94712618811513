import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerConversationComponent } from "./mission-partner-conversation.component";
import { MessagesConversationModule } from "../../../../../../common-componnets/messages/messages-conversation/messages-conversation.module";

@NgModule({
  declarations: [MissionPartnerConversationComponent],
  imports: [CommonModule, MessagesConversationModule],
})
export class MissionPartnerConversationModule {}
