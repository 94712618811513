import { Component, Input } from "@angular/core";
import { JobOffer } from "../../../../platform-pages/src/lib/api/job-offer/job-offer.types";
import {CountryCode} from "../nz-intl-tel-input/country-code";
import {Country} from "../nz-intl-tel-input/nz-intl-tel-input-types";
import {arEG, is} from "date-fns/locale";

@Component({
  selector: "mh-view-job-offer-location",
  templateUrl: "./view-job-offer-location.component.html",
  styleUrls: ["./view-job-offer-location.component.less"],
})
export class ViewJobOfferLocationComponent {
  @Input() jobOffer!: JobOffer;
  allCountries: Array<Country> = [];

  get countryLabel(): string {
    return this.jobOffer.countryCode ? this.findCountryLabel(this.jobOffer.countryCode) : '';
  }

  constructor(private countryCodeData: CountryCode) {
    this.fetchCountryData();
  }

  findCountryLabel(iso2: string): string {
    return this.allCountries.find(c=> c.iso2 == iso2)?.name || iso2;
  }

  protected fetchCountryData(): void {
    this.countryCodeData.allCountries.forEach((c) => {
      const country: Country = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: (c[4] as string[]) || undefined,
        flagClass: c[1].toString().toUpperCase(),
        placeHolder: "",
      };

      this.allCountries.push(country);
    });
  }
}
