import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, switchMap } from "rxjs/operators";
import {of, tap} from "rxjs";
import * as loginActions from "./login.action";
import { LoginService } from "../login.service";
import { LoginData } from "../login.types";
import { SignupService } from "../../../../api/sign-up/signup.service";
import {Router} from "@angular/router";

@Injectable()
export class LoginEffects {
  constructor(private actions$: Actions, private loginService: LoginService, private signupService: SignupService,
              private router: Router) {}

  doLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.login),
      concatMap(({ loginRequest }) =>
        this.loginService.login(loginRequest).pipe(
          map((loginData: LoginData) => loginActions.loginSuccess({ data: loginData })),
          catchError((error) => of(loginActions.loginError({ error }))),
        ),
      ),
    );
  });

  loadLoginData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.getUserByToken),
      switchMap(({token}) =>
        this.loginService.getLoginData().pipe(
          map((response) => loginActions.getUserByTokenSuccess({ loginData: {...response, token} })),
          catchError((error) => of(loginActions.loginError({ error }))),
        ),
      ),
    );
  });

  doLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.logout),
      concatMap(() => {
        if (this.loginService.getToken()) {
          return this.loginService.doLogout().pipe(
            map(() => loginActions.logoutSuccess()),
            catchError((error) => of(loginActions.logoutError({ error }))),
          );
        } else {
          return of(loginActions.logoutError({ error: { message: "no token" } }));
        }
      }),
    );
  });

  doRegistrationComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginActions.completeRegistration),
      concatMap(() => {
        return this.signupService.completeRegistration().pipe(
          switchMap((success) => {
            return this.loginService.getLoginData().pipe(
              map((loginData) => loginActions.completeRegistrationSuccess({loginData}))
            );
          }),
          tap(()=> {
            this.router.navigate(['/login']);
          }),
          catchError((error) => of(loginActions.completeRegistrationError({ error }))),
        );
      }),
    );
  });
}
