import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DocumentService } from "./document.service";
import { NzUploadFile } from "ng-zorro-antd/upload";

@Injectable({
  providedIn: "root",
})
export class UserDocumentsResolver {
  constructor(private readonly documentService: DocumentService) {}

  resolve(): Observable<Array<NzUploadFile>> {
    return this.documentService.loadAllUserFiles();
  }
}
