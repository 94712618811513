<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{'debug.label' | translate}}</nz-page-header-title>
</nz-page-header>

<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="journeyEnabled" (nzCheckedChange)="onJourneyEnabledChange()">
            <span>Enable journey</span>
          </label>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>Journey name</nz-form-label>
        <nz-form-control nzErrorTip="Required">
          <input nz-input nzSize="default" formControlName="journeyName" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <button nz-button [nzType]="'primary'" nzBlock (click)="onSave()">
        {{ "save.button" | translate }}
      </button>
    </div>
  </div>
</form>
