<nz-layout class="auth auth--reset-password">
  <div nz-row class="auth__container">
    <div nz-col nzSpan="24" class="auth__content">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24" class="auth__logo">
          <div class="auth__logo-wrap">
            <img class="logo-img" src="/assets/images/logo.svg" alt="Momhunting logo" />
          </div>
        </div>
        <ng-container *ngIf="!testimonialComplete && !testimonialRejected">
          <div nz-col nzSpan="24">
            <h1 class="auth__title" [innerHtml]="'make-testimonial.title' | translate : { authorName: authorName, targetName: targetName }"></h1>
          </div>
          <div nz-col nzSpan="24">
            <p class="auth__text" [innerHtml]="'make-testimonial.text' | translate : { authorName: authorName, targetName: targetName }"></p>
          </div>
          <div nz-col nzSpan="24">
            <form nz-form [formGroup]="formGroup" nzLayout="vertical">
              <nz-form-item>
                <nz-form-label>
                  <mh-explanation-label [labelKey]="'make-testimonial.testimonial.label'"></mh-explanation-label>
                </nz-form-label>
                <nz-form-control>
                  <textarea rows="4" formControlName="testimonialText" nz-input></textarea>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
          <div class="auth__form auth__form--space-between">
            <button nz-button nzType="default" nzSize="large" [disabled]="testimonialComplete" (click)="onReject()">
              {{ "reject.button" | translate }}
            </button>
            <button nz-button nzType="primary" nzSize="large" [disabled]="testimonialComplete" (click)="onMake()">
              {{ "make-testimonial.button" | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="testimonialComplete">
          <div nz-col nzSpan="24">
            <p class="auth__text" [innerHtml]="'make-testimonial.complete.text' | translate : { authorName: authorName, targetName: targetName }"></p>
          </div>
          <nz-result nzStatus="success"></nz-result>
        </ng-container>
        <ng-container *ngIf="testimonialRejected">
          <div nz-col nzSpan="24">
            <h1 class="auth__title" [innerHtml]="'make-testimonial.reject.title' | translate : { authorName: authorName, targetName: targetName }"></h1>
          </div>
          <div nz-col nzSpan="24">
            <p class="auth__text" [innerHtml]="'make-testimonial.reject.text' | translate : { authorName: authorName, targetName: targetName }"></p>
          </div>
          <nz-result nzStatus="error"></nz-result>
        </ng-container>
      </div>
    </div>
  </div>
</nz-layout>
