import { Component, OnInit } from "@angular/core";
import {
  FactsAndFigures,
  MaturityAssessment,
  MaturityAssessmentStatus,
} from "../../../../api/assessment/assessment.types";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AssessmentService } from "libs/platform-pages/src/lib/api/assessment/assessment.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-mission-partner-assessment",
  templateUrl: "./mission-partner-assessment.component.html",
  styleUrls: ["./mission-partner-assessment.component.less"],
})
export class MissionPartnerAssessmentComponent implements OnInit {
  assessment: MaturityAssessment | null = null;
  copied = false;
  factsAndFigures: FactsAndFigures | null = null;
  factsAndFiguresForm!: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private assessmentService: AssessmentService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
  ) {
    this.assessment = this.activatedRoute.snapshot.data["assessment"];
    this.initFactsAndFiguresForm();
  }

  ngOnInit() {
    this.assessmentService.getFactsAndFigures().subscribe((factsAndFigures) => {
      this.factsAndFigures = factsAndFigures;
      this.initFactsAndFiguresForm();
    });
  }

  initFactsAndFiguresForm() {
    this.factsAndFiguresForm = this.formBuilder.group({
      staffTurnoverPercentage: [this.factsAndFigures?.staffTurnoverPercentage || 0],
      averageAge: [this.factsAndFigures?.averageAge || 0],
      averageLengthOfService: [this.factsAndFigures?.averageLengthOfService || 0],
      partTimeEmployeesPercentage: [this.factsAndFigures?.partTimeEmployeesPercentage || 0],
      partTimeEmployeesInManagementPercentage: [this.factsAndFigures?.partTimeEmployeesInManagementPercentage || 0],
      womenProportion: [this.factsAndFigures?.womenProportion || 0],
      womenInManagementPercentage: [this.factsAndFigures?.womenInManagementPercentage || 0],
    });
  }

  saveFactsAndFigures() {
    if (!this.factsAndFiguresForm) {
      return;
    }
    this.assessmentService.saveFactsAndFigures(this.factsAndFiguresForm.value).subscribe((factsAndFigures) => {
      this.factsAndFigures = factsAndFigures;
      this.notificationService.success("", this.translateService.instant("assessment.facts-and-figures-saved"));
    });
  }

  onCopyToClipboard() {
    this.copied = true;
  }

  formatterPercent = (value: number): string => `${value}%`;
  parserPercent = (value: string): string => value.replace("%", "");

  protected readonly MaturityAssessmentStatus = MaturityAssessmentStatus;
}
