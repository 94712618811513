<nz-page-header class="large p-r-0">
  <nz-page-header-title>{{ "visit.title" | translate }}</nz-page-header-title>
</nz-page-header>

<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>

<ng-template #desktopView>
  <div nz-col [nzSpan]="24">
    <nz-table
      class="matches-list conversation-list-table"
      nzSize="small"
      nzShowSizeChanger="true"
      nzFrontPagination="false"
      [nzData]="visitsTalentList || []"
      [nzLoading]="loading"
    >
      <thead>
        <tr>
          <th>{{ "visit.mp-name.label" | translate }}</th>
          <th>{{ "visit.date-of-last-visit.label" | translate }}</th>
          <th>{{ "visit.number-of-visits.label" | translate }}</th>
          <th>{{ "visit.actions.label" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let visit of visitsTalentList">
          <tr>
            <td>
              <div class="cell-content">
                <div class="contact" [routerLink]="['/talent/view-micro-site/', visit.missionPartnerId]">
                  <nz-avatar
                    class="mh-avatar mh-avatar--logo"
                    [nzSrc]="visit.missionPartnerLogo"
                    [nzSize]="60"
                  ></nz-avatar>
                  <div class="contact-name">
                    {{ visit.missionPartnerName }}

                    <nz-tag
                      *ngIf="visit.missionPartnerStatusTag"
                      nzColor="#6FC4C2"
                      class="contact__tag"
                      [title]="visit.missionPartnerStatusTag"
                    >
                      {{ visit.missionPartnerStatusTag | translate }}
                    </nz-tag>
                  </div>
                </div>
              </div>
            </td>
            <td>
              {{ visit.visitTimestamp | date : "dd.MM.YYYY HH:mm" }}
            </td>
            <td>
              {{ visit.visitCount }}
            </td>
            <td>
              <button
                *ngIf="visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.send-btn.label' | translate"
                [routerLink]="['/talent/inbox/conversation/', visit.conversationId]"
                [queryParams]="{ origin: 'talent-my-visits' }"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
              <button
                *ngIf="!visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.start-conversation.label' | translate"
                (click)="openCreateConversationModal($event, visit)"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
            </td>
          </tr>
          <tr *ngIf="expandSet.has(visit.missionPartnerId)" class="expanded-row">
            <td colspan="5">
              <div class="expanded-content"></div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</ng-template>

<ng-template #mobileView>
  <div nz-col [nzSpan]="24">
    <nz-table
      class="matches-list conversation-list-table"
      nzSize="small"
      nzShowSizeChanger="true"
      nzFrontPagination="false"
      [nzData]="visitsTalentList || []"
      [nzLoading]="loading"
    >
      <thead>
        <tr>
          <th nzWidth="30px">
            <button
              nz-button
              nzType="link"
              [class.ant-table-row-expand-icon-expanded]="allExpanded"
              [class.ant-table-row-expand-icon-collapsed]="!allExpanded"
              (click)="onAllExpandChange()"
            >
              <span nz-icon [nzType]="allExpanded ? 'minus-circle' : 'plus-circle'" nzTheme="outline"></span>
            </button>
          </th>
          <th>{{ "visit.mp-name.label" | translate }}</th>
          <th>{{ "visit.actions.label" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let visit of visitsTalentList">
          <tr>
            <td
              [nzExpand]="expandSet.has(visit.missionPartnerId)"
              (nzExpandChange)="onExpandChange(visit.missionPartnerId, $event)"
            ></td>
            <td>
              <div class="cell-content">
                <div class="contact" [routerLink]="['/talent/view-micro-site/', visit.missionPartnerId]">
                  <nz-avatar
                    class="mh-avatar mh-avatar--logo"
                    [nzSrc]="visit.missionPartnerLogo"
                    [nzSize]="40"
                  ></nz-avatar>
                  <div class="contact-name">
                    {{ visit.missionPartnerName }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <button
                *ngIf="visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.send-btn.label' | translate"
                [routerLink]="['/talent/inbox/conversation/', visit.conversationId]"
                [queryParams]="{ origin: 'talent-my-visits' }"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
              <button
                *ngIf="!visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.start-conversation.label' | translate"
                (click)="openCreateConversationModal($event, visit)"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
            </td>
          </tr>
          <tr *ngIf="expandSet.has(visit.missionPartnerId)" class="expanded-row">
            <td colspan="4">
              <div class="expanded-content">
                <div class="expanded-section">
                  <div class="expanded-title">{{ "visit.date-of-last-visit.label" | translate }}</div>
                  <div>{{ visit.visitTimestamp | date : "dd.MM.YYYY HH:mm" }}</div>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "visit.number-of-visits.label" | translate }}</div>
                  <div>{{ visit.visitCount }}</div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</ng-template>
