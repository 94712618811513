import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from "@angular/core";

export interface OffsetPosition {
  offsetTop: number;
  offsetLeft: number;
}

const HEADER_WARNING_HIDDEN_CLASS = "warn-hidden";
const FORM_FIELD_CLASS = "grid-layout_field";

@Component({
  selector: "mh-scroll-to-validation-error",
  templateUrl: "./scroll-to-validation-error.component.html",
  styleUrls: ["./scroll-to-validation-error.component.less"],
  host: { class: 'scroll-to-validation-error-container' },
})
export class ScrollToValidationErrorComponent {
  public scrollBodyElement!: HTMLElement;
  private errorElements: HTMLElement[] = [];

  @ViewChild("warningElement", { read: ElementRef }) warningElementRef!: ElementRef;

  @HostListener("click", ["$event"]) handleClickEvent($event: MouseEvent): void {
    $event.stopPropagation();

    const errorElement = this.errorElements[0];
    const wrapperElement = this.findErrorElementWrapper(errorElement);

    this.scrollBodyElement.scrollTop = this.getPositionRelativeToParent(
      wrapperElement,
      "validation-scroll-cnt",
    ).offsetTop - 35;
  }

  get contentText(): string {
    const firstErrorElement = this.errorElements[0];
    if (!firstErrorElement) return "";
    const errorElementLabel = this.findErrorElementLabel(firstErrorElement);
    return errorElementLabel ? `${errorElementLabel}: ${firstErrorElement.innerText}` : firstErrorElement.innerText;
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public hide(): void {
    this.warningElementRef.nativeElement.classList.add(HEADER_WARNING_HIDDEN_CLASS);
    this.changeDetectorRef.detectChanges();
  }

  public show(errorElements: HTMLElement[]): void {
    this.errorElements = errorElements;
    this.warningElementRef.nativeElement.classList.remove(HEADER_WARNING_HIDDEN_CLASS);
    this.changeDetectorRef.detectChanges();
  }

  private findErrorElementLabel(errorElement: HTMLElement): string {
    const errorElementFormField = this.findParentByClass(errorElement, FORM_FIELD_CLASS);
    return (errorElementFormField?.querySelector("mat-label") as HTMLElement)?.innerText;
  }

  private findErrorElementWrapper(errorElement: HTMLElement): HTMLElement {
    const wrapperElement = this.findParentByClass(errorElement, FORM_FIELD_CLASS);
    return wrapperElement ?? errorElement;
  }

  private getPositionRelativeToParent(srcElement: HTMLElement, parentElementClass: string): OffsetPosition {
    let offsetLeft = 0;
    let offsetTop = 0;

    let el = srcElement;

    while (el) {
      if (!el.className.includes(parentElementClass)) {
        offsetLeft += el.offsetLeft;
        offsetTop += el.offsetTop;
        el = el.offsetParent as HTMLElement;
      } else {
        break;
      }
    }
    return { offsetTop: offsetTop, offsetLeft: offsetLeft };
  }

  private findParentByClass(child: HTMLElement, parentElementClass: string) {
    let el: HTMLElement | null = child;
    while (el) {
      if (!el.className.includes(parentElementClass)) {
        el = el.parentElement;
      } else {
        return el;
      }
    }
    return null;
  }
}
