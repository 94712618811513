<div class="profile-preview">
  <div class="profile-preview__info">
    <div class="profile-preview__avatar">
      <nz-avatar class="mh-avatar" [nzSrc]="avatarUrl" [nzSize]="(isMobileView$ | async) ? 150 : 180"></nz-avatar>
      <ng-container *ngIf="(isMobileView$ | async)">
        <div *ngIf="jobOfferId && !conversationId" class="profile-preview__action">
          <button nz-button nzType="primary" nzShape="circle" nzSize="large"
                  [disabled]="disableConversation"
                  (click)="onStartConversation()">
            <span nz-icon nzType="mail" nzTheme="outline"></span>
          </button>
        </div>
        <div *ngIf="conversationId" class="profile-preview__action">
          <button nz-button nzType="primary" nzShape="circle" nzSize="large"
                  [disabled]="disableConversation"
                  [routerLink]="['/mission-partner/inbox/conversation/', conversationId]"
                  [queryParams]="{origin: 'mp-talent-view', originId:talent.accountId?.internalId, jobOfferId}">
            <span nz-icon nzType="mail" nzTheme="outline"></span>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="profile-preview__info-col">
      <div class="profile-preview__info-top">
        <h2 class="profile-preview__name">
          {{ talent.salutation }} {{ talent.firstName }} {{ talent.lastName }}
          <button
            *ngIf="viewerAccountType === AccountType.ADMIN"
            nz-button
            nzType="link"
            nzSize="small"
            [nzTooltipTitle]="'admin.sign-in-as.tooltip' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            (click)="onSignInAs()"
          >
            <span nz-icon nzType="login" nzTheme="outline"></span>
          </button>
        </h2>

        <ng-container *ngIf="!(isMobileView$ | async)">
          <div *ngIf="jobOfferId && !conversationId" class="profile-preview__action">
            <button nz-button nzType="primary"
                    [disabled]="disableConversation"
                    (click)="onStartConversation()">{{'profile.start-conversation.button' | translate}}</button>
          </div>
          <div *ngIf="conversationId" class="profile-preview__action">
            <button nz-button nzType="primary"
                    [disabled]="disableConversation"
                    [routerLink]="['/mission-partner/inbox/conversation/', conversationId]"
                    [queryParams]="{origin: 'mp-talent-view', originId:talent.accountId?.internalId, jobOfferId}">
              {{'profile.to-conversation.button' | translate}}
            </button>
          </div>
        </ng-container>
      </div>
      <div class="job-title">{{ talent.oldTrackRecord?.jobTitle }}</div>

      <ng-container *ngIf="talent.contactData?.postalCode || talent.contactData?.country || talent.contactData?.city">
        <p>
          <mh-location-label [location]="talent.contactData"></mh-location-label>
        </p>
      </ng-container>

      <ng-container *ngIf="talent.profileLink">
        <p>
          <a *ngIf="trustedView" [href]="profileLink" target="_blank">{{talent.profileLink}}</a>
          <span *ngIf="!trustedView">{{talent.profileLink}}</span>
        </p>
      </ng-container>

      <ng-container *ngIf="!(isMobileView$ | async) && (talent.keySummary || profileEditable)">
        <mh-profile-preview-key-summary [talent]="talent" [profileEditable]="profileEditable" (talentUpdate)="onUpdateKeySummary()"></mh-profile-preview-key-summary>
      </ng-container>
    </div>
  </div>

  <nav class="profile-preview__nav">
    <mh-profile-preview-navigation [isMobile]="(isMobileView$ | async)"></mh-profile-preview-navigation>
  </nav>

  <ng-container *ngIf="(isMobileView$ | async) && (talent.keySummary || profileEditable)">
    <div class="profile-preview__key-summary">
      <mh-profile-preview-key-summary [talent]="talent" [profileEditable]="profileEditable" (talentUpdate)="onUpdateKeySummary()"></mh-profile-preview-key-summary>
    </div>
  </ng-container>

<!-- Empty sections are hidden in talent-profile-preview.less.-->
  <div class="profile-preview__section" id="about-me">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="user" nzTheme="outline"></span>
      {{'profile.talent.contact-information.title' | translate}}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="talent.email">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.talent.email.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18" class="break-word">
          <a [href]="'mailto:'+talent.email">{{talent.email}}</a>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.phoneNumber">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.talent.phone-number.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <a [href]="'tel:'+talent.phoneNumber">{{talent.phoneNumber}}</a>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.reasonWhy?.name && viewerAccountType === AccountType.ADMIN">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.reason-why.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <span [innerHTML]="talent.reasonWhy!.name | translate"></span>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.birthDate && viewerAccountType === AccountType.ADMIN">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.age.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{calculateAge(talent.birthDate)}}
        </p>
      </ng-container>
      <ng-container *ngIf="audioUrl">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.profile-audio.title' | translate}}</h4>
        <audio nz-col [nzSpan]="24" [nzMd]="18" controls class="audio">
          <source [src]="audioUrl" type="audio/wav">
        </audio>
      </ng-container>
    </div>
  </div><!-- About me -->

  <div class="profile-preview__section" id="personality">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="smile" nzTheme="outline"></span>
      {{'profile.personality.title' | translate}}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="talent.hashTags?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.hashtags.title' | translate}}</h4>
        <div nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.hashTags" [sortABC]="true" prefix="#"></mh-tags-output>
        </div>
      </ng-container>
      <ng-container *ngIf="talent.momSkills?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.mom-skills.title' | translate}}</h4>
        <div nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.momSkills" [sortABC]="true"></mh-tags-output>
        </div>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityCoreValues">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.core-values.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityCoreValues}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityDinnerDate">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.dinner-date.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityDinnerDate}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalitySuperpower">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.superpower.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalitySuperpower}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityBalance">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.balance.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityBalance}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityProud">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.proud.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityProud}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityElement">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.element.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityElement}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityRoleModel">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.role-model.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityRoleModel}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityInspiration">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.inspiration.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityInspiration}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityPaths">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.paths.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityPaths}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.personality?.personalityIrritations">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'admin.talent-view.personality.irritations.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.personality!.personalityIrritations}}
        </p>
      </ng-container>
    </div>
  </div><!-- Personality -->

  <div class="profile-preview__section" id="desired-job">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="solution" nzTheme="outline"></span>
      {{'profile.desired-job.title' | translate}}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="talent.desiredJob?.developmentPath">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.dev-path.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.desiredJob.developmentPath?.name! | translate }}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredJob?.wishSector?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.wish-sector.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.desiredJob.wishSector" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredJob?.wishIndustry?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.wish-industry.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.desiredJob.wishIndustry" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredJob?.wishDepartment?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.wish-department.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.desiredJob.wishDepartment" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredJob?.preferredWorkingLanguage?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile-preview.preferred-language.title' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.desiredJob.preferredWorkingLanguage"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredJob?.desiredCompanyTypeText || talent.desiredJob?.desiredCompanyType?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.company-type.title' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18" *ngIf="!talent.desiredJob?.desiredCompanyTypeText">
          <mh-tags-output [value]="talent.desiredJob.desiredCompanyType"></mh-tags-output>
        </p>
        <p nz-col [nzSpan]="24" [nzMd]="18" *ngIf="talent.desiredJob?.desiredCompanyTypeText">
          {{talent.desiredJob.desiredCompanyTypeText}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredJob?.leadershipResponsibility">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.leadership-responsibility.title' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.desiredJob.leadershipResponsibility!.name | translate}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.salaryExpectations && (talent.salaryExpectations.min?.name || talent.salaryExpectations.max?.name)">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.salary-expectation.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.salaryExpectations.min?.name}} - {{talent.salaryExpectations.max?.name}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.changeDate">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile-preview.willing-change.change-date.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.changeDate.name | translate }}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.changeEagerness">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile-preview.willing-change.change-eagerness.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.changeEagerness}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredJob?.familyFriendlinessExpectations">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.family-friendly.title' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.desiredJob.familyFriendlinessExpectations }}
        </p>
      </ng-container>

      <nz-divider
        *ngIf="talent.desiredSchedule?.workingHoursPerWeek?.length || talent.desiredSchedule?.workingHoursPerWeekPlanned?.length || talent.desiredSchedule?.plannedSlots"
        nz-col [nzSpan]="24"
        nzPlain [nzText]="'profile.desired-schedule.sub-title' | translate" nzOrientation="left"
      ></nz-divider>
      <ng-container *ngIf="talent.desiredSchedule?.workingHoursPerWeek?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.whours-per-week.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.desiredSchedule.workingHoursPerWeek" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredSchedule?.workingHoursPerWeekPlanned?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.planned-whours-per-week.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.desiredSchedule.workingHoursPerWeekPlanned" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredSchedule?.plannedSlots">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.planned-slots.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.desiredSchedule.plannedSlots!.name | translate }}
        </p>
      </ng-container>

      <nz-divider
        *ngIf="talent.desiredPlaceOfWork?.idealWorkingAssignments?.length || talent.desiredPlaceOfWork?.idealWorkingLocation?.postalCode"
        nz-col [nzSpan]="24"
        nzPlain [nzText]="'profile.desired-place.sub-title' | translate" nzOrientation="left"
      ></nz-divider>
      <ng-container *ngIf="talent.desiredPlaceOfWork?.idealWorkingAssignments?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.ideal-local-assignments.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.desiredPlaceOfWork.idealWorkingAssignments" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredPlaceOfWork?.idealWorkingLocation?.postalCode">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.ideal-location.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-location-label [location]="talent.desiredPlaceOfWork.idealWorkingLocation"></mh-location-label>
          <span *ngIf="talent.desiredJob.idealWorkingLocationDistance?.name">+{{ talent.desiredJob.idealWorkingLocationDistance!.name | translate }} km</span>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.desiredPlaceOfWork.travelWillingness">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.travel-willingness.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{talent.desiredPlaceOfWork.travelWillingness}}
        </p>
      </ng-container>

      <nz-divider
        nz-col [nzSpan]="24"
        nzPlain [nzText]="'profile.job-sharing.title' | translate" nzOrientation="left"
      ></nz-divider>
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile-preview.ready-for-job-sharing.label' | translate}}</h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        <ng-container>
          {{ (talent.desiredJob.openForTandem ? 'yes.label' : 'no.label') | translate }}
        </ng-container>
      </p>
      <ng-container *ngIf="!talent.desiredJob.openForTandem">
        <ng-container *ngIf="talent.desiredJob?.openForTandemReason">
          <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile-preview.not-ready-for-job-sharing-reason.label' | translate}}</h4>
          <p nz-col [nzSpan]="24" [nzMd]="18">
            {{ talent.desiredJob.openForTandemReason }}
          </p>
        </ng-container>
      </ng-container>
    </div>
  </div><!-- Desired job -->

  <div class="profile-preview__section" id="education">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="book" nzTheme="outline"></span>
      {{'profile.education.title' | translate}}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="talent.qualifications?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.qualifications.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.qualifications" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.languages?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.languages.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.languages"></mh-tags-output>
        </p>
      </ng-container>

      <div class="profile-card profile-card--read-only" *ngIf="talent.education?.length">
        <ng-container *ngFor="let education of talent.education; let index = index">
          <div class="profile-card__item">
            <div class="profile-card__header">
              <h3 class="profile-card__title">
                {{ education.graduationYear | date : "yyyy" }}
              </h3>
            </div>
            <div class="profile-card__body">
              <div nz-row [nzGutter]="[20, 20]">
                <ng-container *ngIf="education.degree">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4>{{'profile.degree.label' | translate}}</h4>
                    <p>
                      {{ education.degree.name! | translate }}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="education.universityName">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4>{{'profile.university-name.label' | translate}}</h4>
                    <p>
                      {{ education.universityName }}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="education.courseOfStudy">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4>{{'profile.course-of-study.label' | translate}}</h4>
                    <p>
                      {{ education.courseOfStudy }}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="education.graduationYear">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4>{{'profile.graduation-year.label' | translate}}</h4>
                    <p>
                      {{ education.graduationYear | date : "yyyy" }}
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div><!-- Education-->

  <div class="profile-preview__section" id="experience">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="bar-chart" nzTheme="outline"></span>
      {{'profile.experience.title' | translate}}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="talent.workExperience?.yearsOfExperience !== null">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.work-exp.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.workExperience.yearsOfExperience }} {{(talent.workExperience.yearsOfExperience == 1 ? "year.label" : "years.label") | translate}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.workExperience?.yearsOfLeadership !== null">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.leadership-exp.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.workExperience.yearsOfLeadership }} {{(talent.workExperience.yearsOfLeadership == 1 ? "year.label" : "years.label") | translate}}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.leadershipTeamSize">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.leadership-team-size.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.leadershipTeamSize }}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.careerLevel">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.career-level.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ talent.careerLevel.name | translate }}
        </p>
      </ng-container>
      <ng-container *ngIf="talent.workExperience?.expertFields?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.expert-fields.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.workExperience.expertFields" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.workExperience?.otherExpertFields?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.expert-fields-other.label' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.workExperience.otherExpertFields" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="talent.skills?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.skills.title' | translate}}</h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="talent.skills" [sortABC]="true"></mh-tags-output>
        </p>
      </ng-container>

      <div class="profile-card profile-card--read-only"
           *ngIf="!talent.trackRecord.length
           && (talent.oldTrackRecord?.companyName
           || talent.oldTrackRecord?.sector?.length
           || talent.oldTrackRecord?.industry?.length
           || talent.oldTrackRecord?.department?.length
           || talent.oldTrackRecord?.companyName)"
      >
        <div class="profile-card__item">
          <div class="profile-card__header">
            <h3 class="profile-card__title">
              {{ talent.oldTrackRecord?.jobTitle }}
            </h3>
          </div>
          <div class="profile-card__body">
            <div nz-row [nzGutter]="12">
              <ng-container *ngIf="talent.oldTrackRecord?.sector?.length">
                <div nz-col [nzSpan]="24" [nzMd]="8">
                  <h4 class="profile-card__item-label">{{'profile.sector.label' | translate}}</h4>
                  <p class="profile-card__item-value">
                    <mh-tags-output [value]="talent.oldTrackRecord!.sector" [sortABC]="true"></mh-tags-output>
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="talent.oldTrackRecord?.industry?.length">
                <div nz-col [nzSpan]="24" [nzMd]="8">
                  <h4 class="profile-card__item-label">{{'profile.industry.label' | translate}}</h4>
                  <p class="profile-card__item-value">
                    <mh-tags-output [value]="talent.oldTrackRecord!.industry" [sortABC]="true"></mh-tags-output>
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="talent.oldTrackRecord?.department?.length">
                <div nz-col [nzSpan]="24" [nzMd]="8">
                  <h4 class="profile-card__item-label">{{'profile.department.label' | translate}}</h4>
                  <p class="profile-card__item-value">
                    <mh-tags-output [value]="talent.oldTrackRecord!.department" [sortABC]="true"></mh-tags-output>
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="talent.oldTrackRecord?.jobTitle">
                <div nz-col [nzSpan]="24" [nzMd]="8">
                  <h4 class="profile-card__item-label">{{'profile.job-title.label' | translate}}</h4>
                  <p class="profile-card__item-value">
                    {{ talent.oldTrackRecord?.jobTitle }}
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf="talent.oldTrackRecord?.companyName">
                <div nz-col [nzSpan]="24" [nzMd]="8">
                  <h4 class="profile-card__item-label">{{'profile.company-name.label' | translate}}</h4>
                  <p class="profile-card__item-value">
                    {{ talent.oldTrackRecord!.companyName }}
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-card profile-card--read-only" *ngIf="talent.trackRecord.length">
        <ng-container *ngFor="let trackRecord of talent.trackRecord">
          <div class="profile-card__item">
            <div class="profile-card__header">
              <h3 class="profile-card__title">
                {{ trackRecord.jobTitle }}
              </h3>
              <p class="profile-card__subtitle">
                <mh-employment-duration-label
                  [employmentDurationRange]="trackRecord.employmentDurationRange"
                  [employmentDurationFromToNow]="trackRecord.employmentDurationFromToNow"
                  [workingUntilNow]="trackRecord.workingUntilNow"
                  [hideLabel]="true"
                ></mh-employment-duration-label>
              </p>
            </div>
            <div class="profile-card__body">
              <div nz-row [nzGutter]="[20, 20]">
                <ng-container *ngIf="trackRecord.employmentDurationRange">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.employment-duration.label' | translate}}</h4>
                    <p class="profile-card__item-value">
                      <mh-employment-duration-label
                        [employmentDurationRange]="trackRecord.employmentDurationRange"
                        [employmentDurationFromToNow]="trackRecord.employmentDurationFromToNow"
                        [workingUntilNow]="trackRecord.workingUntilNow"
                      ></mh-employment-duration-label>
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="trackRecord.sector">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.sector.label' | translate}}</h4>
                    <p class="profile-card__item-value">
                      {{trackRecord.sector.name | translate}}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="trackRecord.industry">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.industry.label' | translate}}</h4>
                    <p class="profile-card__item-value">
                      {{trackRecord.industry.name | translate}}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="trackRecord.department">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.department.label' | translate}}</h4>
                    <p class="profile-card__item-value">
                      {{trackRecord.department.name | translate}}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="trackRecord.jobTitle">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.job-title.label' | translate}}</h4>
                    <p class="profile-card__item-value">{{trackRecord.jobTitle}}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="trackRecord.companyName">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.company-name.label' | translate}}</h4>
                    <p class="profile-card__item-value">{{trackRecord.companyName}}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="trackRecord.companyType">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.type-of-company.label' | translate}}</h4>
                    <p class="profile-card__item-value">
                      {{trackRecord.companyType.name | translate}}
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="trackRecord.roleDetails">
                  <div nz-col [nzSpan]="24" [nzMd]="8">
                    <h4 class="profile-card__item-label">{{'profile.role-details.label' | translate}}</h4>
                    <p class="profile-card__item-value">{{trackRecord.roleDetails}}</p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div><!-- Experience -->

  <div class="profile-preview__section" id="files">
    <div nz-row [nzGutter]="[24, 24]">
      <div nz-col [nzSpan]="24">
        <h2 class="profile-preview__section-title">
          <span nz-icon nzType="file" nzTheme="outline"></span>
          {{ 'profile.documents.title' | translate }}
        </h2>
        <div nz-row class="profile-preview__section-content">
          <ng-container *ngIf="cvList.length">
            <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.cv.title' | translate}}</h4>
            <ng-container *ngFor="let cv of cvList; let index = index">
              <span nz-col [nzSpan]="0" [nzMd]="6" *ngIf="index > 0" class="profile-preview__item-label">&nbsp;</span>
              <p nz-col [nzSpan]="24" [nzMd]="18" class="break-word">
                <a *ngIf="trustedView" [href]="cv.url" target="_blank">
                  <span nz-icon nzType="file" nzTheme="outline"></span>
                  {{ cv.name }}
                </a>
                <span *ngIf="!trustedView">
                  <span nz-icon nzType="file" nzTheme="outline"></span>
                  {{ cv.name }}
                </span>
              </p>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="fileList.length">
            <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.files.title' | translate}}</h4>
            <ng-container *ngFor="let file of fileList; let index = index">
              <span nz-col [nzSpan]="0" [nzMd]="6" *ngIf="index > 0" class="profile-preview__item-label">&nbsp;</span>
              <p nz-col [nzSpan]="24" [nzMd]="18" class="break-word">
                <a *ngIf="trustedView" [href]="file.url" target="_blank">
                  <span nz-icon nzType="file" nzTheme="outline"></span>
                  {{ file.name }}
                </a>
                <span *ngIf="!trustedView">
                  <span nz-icon nzType="file" nzTheme="outline"></span>
                  {{ file.name }}
                </span>
              </p>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div><!-- Files -->

  <div class="profile-preview__section" id="references" *ngIf="references.length || referencesRequest.length">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="solution" nzTheme="outline"></span>
      {{ 'profile.references.title' | translate }}
    </h2>
    <mh-profile-preview-references
      [trustedView]="trustedView"
      [references]="references"
      [referencesRequest]="referencesRequest"
      [viewerAccountType]="viewerAccountType"
    ></mh-profile-preview-references>
  </div><!-- References -->

</div>
