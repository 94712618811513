import { Component, ElementRef, ViewChild } from "@angular/core";
import { LoginData } from "../../../login-page/login-page-data/login.types";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "../../../login-page/login-page-data/login.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { MissionPartner } from "../../../../api/sign-up/signup.types";

@Component({
  selector: "mh-mission-partner-settings",
  templateUrl: "./mission-partner-settings.component.html",
  styleUrls: ["./mission-partner-settings.component.less"],
})
export class MissionPartnerSettingsComponent {
  @ViewChild("passwordInputEl") passwordInputEl?: ElementRef;
  public loginData: LoginData;
  public profile: MissionPartner;
  public newsletterEnabled: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private modal: NzModalService,
    private translateService: TranslateService,
    private readonly router: Router,
  ) {
    this.loginData = this.activatedRoute.snapshot.data["loginData"];
    this.profile = this.activatedRoute.snapshot.data["profile"];
    this.newsletterEnabled = this.activatedRoute.snapshot.data["newsletterEnabled"];
  }

  showDeleteConfirm(): void {
    this.modal.confirm({
      nzTitle: this.translateService.instant("settings.account-delete.dialog.title"),
      nzContent: this.translateService.instant("settings.account-delete.dialog.content"),
      nzOkText: this.translateService.instant("ok.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.onDeleteConfirm(),
      nzCancelText: this.translateService.instant("cancel.button"),
    });
  }

  onDeleteConfirm() {
    this.loginService.accountDelete().subscribe((result) => {
      if (result) {
        const isSessionStorage = !!sessionStorage.getItem("token");
        this.loginService.setToken(undefined, isSessionStorage);
        this.router.navigate(["/account-deleted"]);
      }
    });
  }
}
