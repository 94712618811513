import { Component, OnInit } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import { AccountFilter, ActiveConversation } from "../../home-admin-data/account.types";
import { take } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountService } from "../../home-admin-data/account.service";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage } from "../../../../api/filter-storage/filter-storage.types";

const FILTERS_PAGE = FiltersPage.ACTIVE_CONVERSATIONS;

@Component({
  selector: "mh-admin-communications",
  templateUrl: "./admin-communications.component.html",
  styleUrls: ["./admin-communications.component.less"],
})
export class AdminCommunicationsComponent implements OnInit {
  activeConversations?: PaginatedContent<ActiveConversation>;
  loading = true;
  searchForm!: UntypedFormGroup;
  preventDoubleFetch = true;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private filterStorageService: FilterStorageService,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchTerm: [null],
    });

    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE];
    if (storedFilters) {
      this.searchForm.patchValue(storedFilters);
    }

    this.fetchAccounts({...this.getDefaultQueryParams(), ...this.getFilterFormValues()});
  }

  fetchAccounts(filter: AccountFilter) {
    this.loading = true;
    this.accountService.loadActiveConversationsList(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.activeConversations = paginatedContent;
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const {pageSize, pageIndex, sort, filter} = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      ...this.getFilterFormValues(),
    }

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == 'ascend' ? 'ASC' : 'DESC',
            field: sortField
          }
        ]
      }
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.activeConversations?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    });
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");

    return {
      searchTerm: searchField?.value || "",
    };
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchAccounts(filter);
  }

  getDefaultQueryParams() {
    return {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: '',
    };
  }

  protected readonly AccountType = AccountType;
}
