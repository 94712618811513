import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { AccountFilterDTO } from "../../pages/home-admin/home-admin-data/account.types";
import { catchError, map, Observable } from "rxjs";
import { ApiPaginatedResponseDTO, ApiResponseDTO, initialPaginatedContent, PaginatedContent } from "../common.types";
import { JobOfferDTO } from "../job-offer/job-offer.types";
import { saveAs } from "file-saver";

@Injectable({ providedIn: "root" })
export class AdminJobOfferApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadJobOfferList(filter: AccountFilterDTO): Observable<PaginatedContent<JobOfferDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<JobOfferDTO>>(`${this.basePath}/admin/job-opportunity/list`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  getJobOpportunity(id: string): Observable<JobOfferDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${id}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.results.data;
      }),
    );
  }

  createJobOpportunity(jobOffer: JobOfferDTO): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/create`, jobOffer).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  updateJobOpportunity(jobOffer: JobOfferDTO): Observable<JobOfferDTO> {
    return this.httpClient.put<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/update`, jobOffer).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.results.data;
      }),
    );
  }

  archiveJobOpportunity(jobOfferId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}/archive`, {})
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  deleteJobOpportunity(jobOfferId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  archiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    const request = {
      accountId: { internalId: talentId },
      jobOpportunityId: jobOfferId,
    };
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/${jobOfferId}/talents/${talentId}/archive`, request)
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  unArchiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    const request = {
      accountId: { internalId: talentId },
      jobOpportunityId: jobOfferId,
    };
    return this.httpClient
      .post<ApiResponseDTO>(
        `${this.basePath}/admin/job-opportunity/${jobOfferId}/talents/${talentId}/unarchive`,
        request,
      )
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  setTalentFavourite(jobOfferId: string, talentId: string, setFavourite: boolean): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(
        `${this.basePath}/admin/job-opportunity/${jobOfferId}/talents/${talentId}/favourite/${setFavourite}`,
        {},
      )
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  downloadJobOfferList(fileName: string): Observable<void> {
    return this.httpClient
      .get<Blob>(`${this.basePath}/admin/job-opportunity/list/csv`, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          if (response.body) {
            saveAs(response.body, fileName);
          } else {
            console.error('Failed to download file: response body is null');
          }
        }),
      );
  }

  setJobOpportunityStage(jobOpportunityId: string, stage: number): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/jobOpportunityStage/${jobOpportunityId}/${stage}`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  setTalentStage(jobOpportunityId: string, accountId: string, stage: number): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/talentStage/${jobOpportunityId}/${accountId}/${stage}`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  setJobOpportunityForMissionPartner(jobOpportunityId: string, accountId: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/job-opportunity/jobOpportunityForMissionPartner/${jobOpportunityId}/${accountId}`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
}
