import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfilePreviewKeySummaryComponent } from "./profile-preview-key-summary.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";

@NgModule({
  declarations: [ProfilePreviewKeySummaryComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
  ],
  exports: [ProfilePreviewKeySummaryComponent],
})
export class ProfilePreviewKeySummaryModule {}
