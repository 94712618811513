<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{ "talent.dashboard.title" | translate }}</nz-page-header-title>
</nz-page-header>

<div nz-row [nzGutter]="[24, 24]" class="dashboard__container">
  <div nz-col nzSpan="24" nzMd="12" nzLg="8">
    <mh-talent-dashboard-completeness
      [completeness]="$any(profileCompleteness$ | async)"
    ></mh-talent-dashboard-completeness>
  </div>
  <div nz-col nzSpan="24" nzMd="12" nzLg="8" *ngIf="appConfig.featureStatus.profilePreviewLink">
    <nz-card
      class="dashboard__card"
      [nzTitle]="'talent.dashboard.profile-preview.title' | translate"
      [nzActions]="[actionPreviewAnonymized, actionPreviewTrusted]"
    >
      {{ "talent.dashboard.profile-preview.text" | translate }}
    </nz-card>
    <ng-template #actionPreviewAnonymized>
      <button nz-button nzType="primary" [routerLink]="['/talent', 'profile-preview-anonymized']">
        {{ "talent.dashboard.profile-preview.anonymized" | translate }}
      </button>
    </ng-template>
    <ng-template #actionPreviewTrusted>
      <button nz-button nzType="primary" [routerLink]="['/talent', 'profile-preview-trusted']">
        {{ "talent.dashboard.profile-preview.trusted" | translate }}
      </button>
    </ng-template>
  </div>
  <div *ngIf="!profile.communityMember && appConfig.featureStatus.communityLink" nz-col nzSpan="24" nzMd="12" nzLg="8">
    <nz-card
      class="dashboard__card"
      [nzTitle]="'talent.dashboard.community-member.title' | translate"
      [nzActions]="[actionCommunityMember]"
    >
      {{ "talent.dashboard.community-member.text.true" | translate }}
    </nz-card>
    <ng-template #actionCommunityMember>
      <a
        nz-button
        nzType="primary"
        href="https://mom-hunting.circle.so/join?invitation_token=c07282dac3d88172561fe57f5f5d0aba0159e263-d76d85ae-a01a-497b-9f94-428474432ed0"
        target="_blank"
      >
        {{ "talent.dashboard.community-member.link" | translate }}
      </a>
    </ng-template>
  </div>

  <div nz-col nzSpan="24" nzMd="12" nzLg="8">
    <mh-talent-dashboard-searching-status [loginData]="loginData"></mh-talent-dashboard-searching-status>
  </div>

  <div nz-col nzSpan="24" nzMd="12" nzLg="8">
    <mh-talent-dashboard-notification-settings
      [notificationsSettings]="notificationsSettings"
    ></mh-talent-dashboard-notification-settings>
  </div>

  <div nz-col nzSpan="24">
    <mh-talent-dashboard-status-box
      [completeness]="$any(profileCompleteness$ | async)"
    ></mh-talent-dashboard-status-box>
  </div>
</div>
