import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { MissionPartnerJobOffersComponent } from "./mission-partner-job-offers.component";
import { MpJobOfferEditComponent } from "./mp-job-offer-edit/mp-job-offer-edit.component";
import { MpJobOfferCreateComponent } from "./mp-job-offer-create/mp-job-offer-create.component";
import { JobOfferEntityResolver } from "./job-offer-data/job-offer-entity-resolver";
import { MpJobOfferMatchesComponent } from "./mp-job-offer-matches/mp-job-offer-matches.component";
import { LoginDataResolver } from "../../../login-page/login-page-data/login-data.resolver";

const routes: Routes = [
  {
    path: "",
    component: MissionPartnerJobOffersComponent,
  },
  {
    path: "edit/:id",
    component: MpJobOfferEditComponent,
    resolve: {
      jobOffer: JobOfferEntityResolver,
    },
    runGuardsAndResolvers: "always",
  },
  {
    path: "create",
    component: MpJobOfferCreateComponent,
    resolve: {
      jobOffer: JobOfferEntityResolver,
      loginData: LoginDataResolver,
    },
    runGuardsAndResolvers: "always",
  },
  {
    path: "matches/:id",
    component: MpJobOfferMatchesComponent,
    resolve: {
      jobOffer: JobOfferEntityResolver,
      //matches: JobOfferMatchedTalentsListResolver,
    },
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MissionPartnerJobOffersRoutingModule {}
