import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DashboardService } from "./dashboard.service";
import { DashboardConversationInfo } from "./dashboard.types";

@Injectable({
  providedIn: "root",
})
export class ProactiveContactAttemptsResolver {
  constructor(private readonly dashboardService: DashboardService) {}

  resolve(): Observable<DashboardConversationInfo> {
    return this.dashboardService.getProactiveContactAttempts();
  }
}
