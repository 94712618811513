import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Conversation } from "../../../../../../api/messages/messages.types";

@Component({
  selector: "mh-mission-partner-conversation",
  templateUrl: "./mission-partner-conversation.component.html",
  styleUrls: ["./mission-partner-conversation.component.less"],
})
export class MissionPartnerConversationComponent {
  conversation: Conversation;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.conversation = this.activatedRoute.snapshot.data["conversation"];
  }

  onBack() {
    const origin = this.activatedRoute.snapshot.queryParams["origin"];
    const originId = this.activatedRoute.snapshot.queryParams["originId"];
    const tab = this.activatedRoute.snapshot.queryParams["tab"];
    if (origin === "job-offer-matches" && originId) {
      this.router.navigate(["/", "mission-partner", "job-offers", "matches", originId]);
    } else if (origin === "mp-talent-view" && originId) {
      const jobOfferId = this.activatedRoute.snapshot.queryParams["jobOfferId"];
      this.router.navigate(["/", "mission-partner", "view-talent", originId], { queryParams: { jobOfferId } });
    } else if (origin === "mission-partner-my-visits") {
      this.router.navigate(["/", "mission-partner", "visits"]);
    } else if (origin === "inbox" && tab) {
      this.router.navigate(["/", "mission-partner", "inbox"], { queryParams: { tab } });
    } else {
      this.router.navigate(["/", "mission-partner", "inbox"]);
    }
  }
}
