import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessagesConversationComponent } from "./messages-conversation.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { MessagesConversationChatModule } from "./messages-conversation-chat/messages-conversation-chat.module";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { IconModule, NavBarModule, PopoverItemModule, PopoverModule } from "ng-zorro-antd-mobile";
import { TranslateModule } from "@ngx-translate/core";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { RouterLink } from "@angular/router";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { AgreementTermsDialogModule } from "./agreement-terms-conversation-dialog/agreement-terms-dialog.module";

@NgModule({
  declarations: [MessagesConversationComponent],
  imports: [
    CommonModule,
    AgreementTermsDialogModule,
    NzGridModule,
    MessagesConversationChatModule,
    NzSpinModule,
    NzAvatarModule,
    NzButtonModule,
    NzIconModule,
    NavBarModule,
    TranslateModule,
    IconModule,
    NzDividerModule,
    NzToolTipModule,
    PopoverModule,
    PopoverItemModule,
    RouterLink,
    NzAlertModule,
  ],
  exports: [MessagesConversationComponent],
})
export class MessagesConversationModule {}
