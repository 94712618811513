<form class="admin-filters" nz-form>
  <div class="admin-filters__actions">
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="default" type="button" (click)="downloadAllAssessmentCSV()">
          {{ "admin.assessment.download-csv-all.label" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" type="button" (click)="openSetupAssessmentModal()">
          {{ "admin.assessment.create.label" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="assessments?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="assessments?.paging?.items || 0"
  [nzPageSize]="assessments?.paging?.itemsOnPage || 0"
  [nzPageIndex]="assessments?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
    <tr>
      <th>Created at</th>
      <th>Link</th>
      <th>Mission partner</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.view-details.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          [routerLink]="['/admin/assessment-view', data.id]"
        >
          {{ data.createdAt | date : "dd.MM.YYYY HH:mm" }}
        </button>
      </td>
      <td>
        <a [href]="data.link" target="_blank" class="m-r-8">link</a>
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nz-tooltip]="data.linkCopied ? 'Copied' : 'Copy'"
          [cdkCopyToClipboard]="data.link"
          (click)="onCopyLinkClick(data)"
        >
          <span *ngIf="!data.linkCopied" nz-icon nzType="link" nzTheme="outline"></span>
          <span
            *ngIf="data.linkCopied"
            nz-icon
            nzType="check-circle"
            nzTheme="twotone"
            [twoToneColor]="'#52c41a'"
          ></span>
        </button>
      </td>
      <td>
        <a [routerLink]="['/admin/mission-partner-view', data.missionPartnerId]" target="_blank">
          {{ data.missionPartnerName }}
        </a>
      </td>
      <td>{{ data.status }}</td>
      <td>
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.view-details.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          [routerLink]="['/admin/assessment-view', data.id]"
        >
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </button>
        <button
          *ngIf="data.status === MaturityAssessmentStatus.ACTIVE"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.complete-assessment.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="confirmCompleteAssessment(data)"
        >
          <span nz-icon nzType="unlock" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="data.status !== MaturityAssessmentStatus.ACTIVE"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.uncomplete-assessment.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="confirmUncompleteAssessment(data)"
        >
          <span nz-icon nzType="lock" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.assessment-download-csv.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="downloadAssessmentCSV(data)"
        >
          <span nz-icon nzType="file-text" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="data.status !== MaturityAssessmentStatus.ACTIVE"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.assessment-hashtags.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="editHashtags(data)"
        >
          <span nz-icon nzType="tags" nzTheme="outline"></span>
        </button>

        <input
          type="file"
          accept=""
          aria-label="File upload"
          class="assessment__file-input"
          id="fileInput"
          #fileInput
          (change)="filesSelected(fileInput.files, data)"
        />
        <button
          *ngIf="data.status !== MaturityAssessmentStatus.ACTIVE"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.assessment-upload-report.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="fileInput.click()"
        >
          <span nz-icon nzType="upload" nzTheme="outline"></span>
        </button>

        <a
          *ngIf="data.resultFile"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.assessment-download-report.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          [href]="data.resultFile"
          target="_blank"
        >
          <span nz-icon nzType="download" nzTheme="outline"></span>
        </a>

        <button
          *ngIf="data.resultFile"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.assessment-delete-report.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="confirmDeleteReport(data)"
        >
          <span nz-icon nzType="file-excel" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'delete.button' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="confirmDeleteAssessment(data)"
        >
          <span nz-icon nzType="delete" nzTheme="outline"></span>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
