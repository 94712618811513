import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileDocumentsUploadComponent } from "./profile-documents-upload.component";
import {NzUploadModule} from "ng-zorro-antd/upload";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd/icon";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ProfileDocumentsUploadComponent],
    imports: [CommonModule, NzUploadModule, NzButtonModule, NzIconModule, TranslateModule],
  exports: [
    ProfileDocumentsUploadComponent
  ]
})
export class ProfileDocumentsUploadModule {}
