import { Component, Inject, OnInit } from "@angular/core";
import { AccountService } from "../../home-admin-data/account.service";
import { take } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Talent } from "../../../../api/sign-up/signup.types";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { DocumentService } from "../../../../api/document/document.service";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Reference, ReferenceRequest } from "../../../../api/references/references.types";
import { CommonService } from "../../../../api/common.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { AccountType } from "../../../login-page/login-page-data/login.types";

@Component({
  selector: "mh-admin-talent-view",
  templateUrl: "./admin-talent-view.component.html",
  styleUrls: ["./admin-talent-view.component.less"],
})
export class AdminTalentViewComponent implements OnInit {
  talentId = "";
  talent: Talent | undefined;
  avatarUrl = "";
  cvList: NzUploadFile[] = [];
  fileList: NzUploadFile[] = [];
  references: Reference[] = [];
  referencesRequest: ReferenceRequest[] = [];

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private documentsService: DocumentService,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private commonService: CommonService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    const accountId = this.route.snapshot.paramMap.get("id");
    if (accountId) {
      this.talentId = accountId;
      this.accountService
        .getTalentProfile(this.talentId)
        .pipe(take(1))
        .subscribe((talent) => {
          if (talent) {
            this.talent = talent;
            this.avatarUrl = this.commonService.toAbsUrl(talent.avatar?.avatarUrl);
          }
        });

      this.documentsService.loadUserCV(this.talentId).subscribe((list) => (this.cvList = list));
      this.documentsService.loadAllUserFiles(this.talentId).subscribe((list) => (this.fileList = list));

      this.accountService
        .loadReferencesById(this.talentId)
        .pipe(take(1))
        .subscribe((references) => {
          this.references = references;
        });
      this.accountService
        .loadReferencesRequestById(this.talentId)
        .pipe(take(1))
        .subscribe((referencesRequest) => {
          this.referencesRequest = referencesRequest;
        });
    }
  }

  onTalentUpdate(talent: Talent) {
    this.accountService.updateTalent(talent).subscribe((result) => {
      if (result) this.notificationService.success("", this.translateService.instant("profile.updated.notification"));
    });
  }

  onSignInAs() {
    this.accountService.signInAs(this.talentId);
  }

  protected readonly AccountType = AccountType;
}
