import { Injectable } from "@angular/core";
import { InvoicesApi } from "./invoices.api";
import { map, Observable } from "rxjs";
import { InvoicesSerializer } from "./invoices.serializer";
import { Invoice, InvoiceDTO } from "./invoices.types";
import { AccountFilter } from "../../pages/home-admin/home-admin-data/account.types";
import { PaginatedContent } from "../common.types";
import { AccountSerializer } from "../../pages/home-admin/home-admin-data/account.serializer";
import { DocumentFileDTO } from "../document/document.types";

@Injectable({ providedIn: "root" })
export class InvoicesService {
  constructor(
    private invoicesApi: InvoicesApi,
    private invoicesSerializer: InvoicesSerializer,
    private accountSerializer: AccountSerializer,
  ) {}

  loadInvoiceList(filter: AccountFilter): Observable<PaginatedContent<Invoice>> {
    return this.invoicesApi.loadInvoiceList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.invoicesSerializer.deserializeInvoices(paginatedContent.items as Array<InvoiceDTO>),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  loadInvoiceListMy(): Observable<Array<Invoice>> {
    return this.invoicesApi.loadInvoiceListMy().pipe(map((dto) => this.invoicesSerializer.deserializeInvoices(dto)));
  }

  changeInvoiceStatus(invoiceId: string, status: string): Observable<boolean> {
    return this.invoicesApi.changeInvoiceStatus(invoiceId, status);
  }

  createInvoice(invoice: Invoice) {
    return this.invoicesApi.createInvoice(this.invoicesSerializer.serializeInvoice(invoice));
  }

  deleteInvoice(id: string): Observable<boolean> {
    return this.invoicesApi.deleteInvoice(id);
  }

  uploadInvoiceFile(missionPartnerId: string, formData: FormData): Observable<DocumentFileDTO> {
    return this.invoicesApi.uploadInvoiceFile(missionPartnerId, formData);
  }
}
