import { LoginService } from "../../pages/login-page/login-page-data/login.service";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "@momhunting/core";
import {DebugService} from "../../pages/debug/debug.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private localeService: LocaleService,
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService,
    private debugService: DebugService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const manualAuthToken = request.headers.get('authToken');
    const setHeaders: any = {
      ['Accept-Language']: this.localeService.currentLocale,
    }
    if(this.loginService.isAuthenticated()) setHeaders.authToken = manualAuthToken || this.loginService.getToken();
    if(this.debugService.isJourneyEnabled()) setHeaders['JourneyName'] = this.debugService.getJourneyName();
    request = request.clone({
      setHeaders,
    });

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.loginService.logout();
        }
        let error = err.error?.errorKey ?? err.error?.message ?? err.message;
        error = this.translateService.instant(error);
        if (err.status !== 401) {
          this.nzNotificationService.error("", error, { nzDuration: 5000 });
        }
        return throwError(error);
      }),
    );
  }
}
