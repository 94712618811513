import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { jobOfferFeature, JobOfferReducer } from "./store/job-offer.reducer";
import { JobOfferEffects } from "./store/job-offer.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(jobOfferFeature, JobOfferReducer),
    EffectsModule.forFeature([JobOfferEffects]),
  ],
})
export class JobOfferDataModule {}
