import { Injectable } from "@angular/core";
import { SimpleLocation } from "./google-geocoding.types";

@Injectable({
  providedIn: "root",
})
export class GoogleGeocodingSerializer {
  deserializeGeocoderResult(geocoderResult: google.maps.GeocoderResult): SimpleLocation {
    if (!geocoderResult) return {};
    let postalCode = "";
    let city = "";
    let country = "";
    let countryCode = "";

    geocoderResult.address_components.forEach((address_component, i) => {
      if (address_component.types[0] == "postal_code") {
        postalCode = address_component.long_name;
      }
      if (address_component.types[0] == "country") {
        country = address_component.long_name;
        countryCode = address_component.short_name
      }
      if (address_component.types[0] == "locality") {
        city = address_component.long_name;
      }
    });
    let cityOptions: string[] = [...geocoderResult.postcode_localities || []];
    return {
      geocoderResult: JSON.stringify(geocoderResult),
      formattedAddress: geocoderResult.formatted_address,
      country, postalCode, city, cityOptions, countryCode,
      location: {lat: geocoderResult.geometry.location.lat(), lng: geocoderResult.geometry.location.lng()} };
  }
}
