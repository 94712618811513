import { Component, Input } from "@angular/core";
import { MhNotificationSettings } from "../../api/notification/notification.types";
import { NotificationService } from "../../api/notification/notification.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs";

@Component({
  selector: "mh-notification-settings",
  templateUrl: "./notification-settings.component.html",
  styleUrls: ["./notification-settings.component.less"],
})
export class NotificationSettingsComponent {
  @Input() notificationsSettings!: MhNotificationSettings;

  constructor(
    private mhNotificationService: NotificationService,
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService,
  ) {}

  updateNotificationSettings() {
    this.mhNotificationService.saveNotificationSettings(this.notificationsSettings)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.nzNotificationService.success("", this.translateService.instant("notification-settings.update.success"));
        }
      });
  }
}
