import { CmsNameValue } from "../cms/cms.types";
import { CmsNameValueDTO } from "../cms/cms-api.types";
import { SimpleLocation } from "../google-geocoding/google-geocoding.types";
import { NegotiationStatus } from "../../pages/home-admin/home-admin-data/account.types";

export interface InitiateConversationRequest {
  partnerId: AccountId;
  messageText: string;
  jobOpportunityId?: string;
}
export interface AccountId {
  internalId: string;
}
export interface GetConversationMessagesRequest {
  conversationId: string;
  paging: Paging;
}
export interface Paging {
  page: number;
  itemsOnPage: number;
  byPass: boolean;
  offset: number;
}

export interface ConversationDTO {
  creationTimestamp: number;
  id: string;
  linkedConversationId: string;
  messages: any;
  numberOfUnreadMessages: number;
  otherParticipantId: AccountId;
  ownerId: AccountId;
  profileDeanonymised: boolean;
  status: ConversationStatus;
  title: string;
  recipientInfo?: ParticipantInfoDTO;
  ownerInfo?: ParticipantInfoDTO;
  latestMessagePreview: string;
  lastMessageSenderKey: string | null;
  lastMessageTimestamp: number;
  accessGrant: AccessGrantDTO;
  negotiation?: ConversationNegotiationDTO;
}
export interface Conversation {
  creationTimestamp: Date;
  id: string;
  linkedConversationId: string;
  messages: Array<MessagingMessage>;
  numberOfUnreadMessages: number;
  otherParticipantId: string;
  ownerId: string;
  profileDeanonymised: boolean;
  status: ConversationStatus;
  title: string;
  recipientInfo?: ParticipantInfo;
  ownerInfo?: ParticipantInfo;
  latestMessagePreview: string;
  lastMessageSenderKey: string | null;
  lastMessageTimestamp: Date;
  isOwner: boolean;
  accessGrant?: AccessGrant;
  negotiation?: ConversationNegotiation;
}

export interface ParticipantInfo {
  name?: string;
  companyName?: string;
  avatarUrl: string;
  salaryMin: number;
  salaryMax: number;
  domainExpertises: Array<CmsNameValue>;
  workExperience: number;
  leadershipExperience: number;
  location: SimpleLocation;
  idealWorkingLocation: SimpleLocation;
  numberOfEmployees: CmsNameValue;
  industry: CmsNameValue;
  companyType: CmsNameValue;
  sector: CmsNameValue;
  companyHeadQuarters: CmsNameValue;
}

export interface ParticipantInfoDTO {
  name?: string;
  companyName?: string;
  avatar: {
    id: string;
    accountId: AccountId;
    avatarUrl: string;
    name: string;
  };
  salaryMin: number;
  salaryMax: number;
  domainExpertises: Array<CmsNameValueDTO>;
  workExperience: number;
  leadershipExperience: number;
  location: SimpleLocation;
  idealWorkingLocation: SimpleLocation;
  numberOfEmployees: CmsNameValueDTO;
  industry: CmsNameValueDTO;
  companyType: CmsNameValueDTO;
  sector: CmsNameValueDTO;
  companyHeadQuarters: CmsNameValueDTO;
}

export enum ConversationStatus {
  INITIATED = "INITIATED",
  ACTIVE = "ACTIVE",
  ENDED = "ENDED",
  AGREEMENT_REACHED_ONE_SIDED = "AGREEMENT_REACHED_ONE_SIDED",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
  OTHER_SIDE_DELETED = "OTHER_SIDE_DELETED",
}

export interface MessagingMessage {
  action: SocketAction;
  conversationId: string;
  messageText: string;
  creationTimestamp: Date;
  read?: boolean;
  attachment?: MessagingAttachment;
  attachmentId?: string;
  id?: string;
  messageType?: MessageType;
  subType?: MessageSubType;
  senderId?: string;
  ownMessage: boolean;
  update?: boolean;
}

export interface MessagingMessageDTO {
  action: SocketAction;
  conversationId: string;
  messageText: string;
  creationTimestamp?: number;
  read?: boolean;
  attachment?: MessagingAttachmentDTO;
  attachmentId?: string;
  id?: string;
  messageType?: MessageType;
  subType?: MessageSubType;
  senderId?: { internalId: string };
}

export interface MessagingAttachment {
  id: string;
  name: string;
  fileUrl: string;
  fileType: FileType;
  subType: FileSubType;
  createdAt: Date;
  updatedAt: Date;
  conversationId: string;
  beingTranscoded: boolean;
}

export interface MessagingAttachmentDTO {
  id: string;
  name: string;
  fileUrl: string;
  fileType: FileType;
  subType: FileSubType;
  createdAt: number;
  updatedAt: number;
  conversationId: string;
  beingTranscoded: boolean;
}

export enum SocketAction {
  SEND = "SEND",
  KEEP_ALIVE = "KEEP_ALIVE",
  READ = "READ",
}
export enum MessageType {
  USER_MESSAGE = "USER_MESSAGE",
  SYSTEM_MESSAGE = "SYSTEM_MESSAGE",
}
export enum MessageSubType {
  PROFILE_ACCESS_QUESTION = "PROFILE_ACCESS_QUESTION",
  PROFILE_ACCESS_GRANTED = "PROFILE_ACCESS_GRANTED",
  PROFILE_ACCESS_REJECTED = "PROFILE_ACCESS_REJECTED",
  AGREEMENT_REACHED_ONE_SIDE = "AGREEMENT_REACHED_ONE_SIDE",
  AGREEMENT_REACHED_COMPLETED = "AGREEMENT_REACHED_COMPLETED",
  AGREEMENT_TERMS_CONFIRMED = "AGREEMENT_TERMS_CONFIRMED",
  AGGREEMENT_TERMS_REJECTED = "AGGREEMENT_TERMS_REJECTED",
}
export enum FileType {
  FILE = "FILE",
}
export enum FileSubType {
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
}

export interface MissionPartnerMessageSuggest {
  talentId: string;
  jobOpportunityId: string;
}
export interface TalentMessageSuggest {
  missionPartnerId: string;
}

export interface AccessGrantDTO {
  missionPartnerId: AccountId;
  talentId: AccountId;
  createdAt: number;
  resolvedAt: number;
  status: AccessGrantStatus;
}
export interface AccessGrant {
  missionPartnerId: string;
  talentId: string;
  createdAt: number;
  resolvedAt: number;
  status: AccessGrantStatus;
}

export enum AccessGrantStatus {
  INITIAL = "INITIAL",
  PENDING = "PENDING",
  GRANTED = "GRANTED",
}

export interface ConversationNegotiationDTO {
  talentId: AccountId;
  missionPartnerId: AccountId;
  initiator: AccountId;
  closer?: AccountId;
  status: NegotiationStatus;
  creationTimestamp: number;
  updatedTimestamp: number;
  talentConversationId: string;
  missionPartnerConversationId: string;
  agreementTerms?: AgreementTermsDTO;
}

export interface ConversationNegotiation {
  talentId: string;
  missionPartnerId: string;
  initiator: string;
  closer?: string;
  status: NegotiationStatus;
  creationTimestamp: Date;
  updatedTimestamp: Date;
  talentConversationId: string;
  missionPartnerConversationId: string;
  agreementTerms?: AgreementTerms;
}

export interface AgreementTermsDTO {
  companyName: string;
  position: string;
  date: number
  salary: number;
  workingHours: number;
  placeOfWork: string;
}

export interface AgreementTerms {
  companyName: string;
  position: string;
  date: Date | null;
  salary: number;
  workingHours: number;
  placeOfWork: string;
}