<nz-layout class="assessment">
  <div class="assessment__container">
    <div class="assessment__content">
      <div class="assessment__header">
        <div class="assessment__logo">
          <div class="logo-wrapper">
            <img class="logo-img" src="/assets/images/logo.svg" alt="Mom hunting logo" />
          </div>
        </div>
        <div class="assessment__title">
          <h2>
            {{ "assessment.title" | translate }}
          </h2>
        </div>
        <div class="assessment__locale-select">
          <mh-locale-select mode="dropdown"></mh-locale-select>
        </div>
      </div>

      <ng-container *ngIf="error" [ngSwitch]="error">
        <ng-container *ngSwitchCase="MAAssessmentFormError.MATURITY_ASSESSMENT_INVALID_TOKEN">
          <nz-result
            nzStatus="error"
            [nzTitle]="'MATURITY_ASSESSMENT_INVALID_TOKEN' | translate"
          ></nz-result>
        </ng-container>
        <ng-container *ngSwitchCase="MAAssessmentFormError.MATURITY_ASSESSMENT_NOT_FOUND">
          <nz-result
            nzStatus="error"
            [nzTitle]="'MATURITY_ASSESSMENT_NOT_FOUND' | translate"
          ></nz-result>
        </ng-container>
        <ng-container *ngSwitchCase="MAAssessmentFormError.MATURITY_ASSESSMENT_COMPLETED">
          <nz-result
            nzStatus="info"
            [nzTitle]="'MATURITY_ASSESSMENT_COMPLETED' | translate"
          ></nz-result>
        </ng-container>
        <ng-container *ngSwitchCase="MAAssessmentFormError.MATURITY_ASSESSMENT_FORM_NOT_FOUND">
          <nz-result
            nzStatus="error"
            [nzTitle]="'MATURITY_ASSESSMENT_FORM_NOT_FOUND' | translate"
          ></nz-result>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="assessment && !assessmentForm">
        <p class="p-t-10 whitespace-pre-line" [innerHTML]="maturityAssessmentIntro"></p>
        <mh-assessment-setup-form [token]="token" (setupResponse)="onSetupDone($event)"></mh-assessment-setup-form>
      </ng-container>

      <ng-container *ngIf="assessmentForm">
        <mh-assessment-navigator
          *ngIf="!assessmentForm.completed"
          [assessment]="assessmentForm"
          (completeForm)="onCompleteForm()"
        ></mh-assessment-navigator>

        <nz-result
          *ngIf="assessmentForm.completed"
          nzStatus="success"
          [nzTitle]="'assessment.completed.title' | translate"
          [nzSubTitle]="'assessment.completed.description' | translate"
        ></nz-result>
      </ng-container>
    </div>
  </div>
</nz-layout>
