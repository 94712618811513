import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AdminTalentListComponent } from "./admin-talent-list.component";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzTagModule } from "ng-zorro-antd/tag";
import { AccountPasswordDialogModule } from "../../../../../../../common-ui/src/lib/account-password-dialog/account-password-dialog.module";
import { AccountCommentsDialogModule } from "../../../../../../../common-ui/src/lib/account-comments-dialog/account-comments-dialog.module";
import { SalaryExpectationInputModule } from "../../../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation-input.module";
import { TagsOutputModule } from "../../../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { LocationInputModule } from "../../../../../../../common-ui/src/lib/location-input/location-input.module";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { LocaleSelectModule } from "@momhunting/common-ui";
import { AdminTalentStatisticModule } from "./admin-talent-statistic/admin-talent-statistic.module";

@NgModule({
  declarations: [AdminTalentListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzTableModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    RouterLink,
    NzDatePickerModule,
    NzSelectModule,
    NzTagModule,
    AccountPasswordDialogModule,
    AccountCommentsDialogModule,
    SalaryExpectationInputModule,
    TagsOutputModule,
    NzSwitchModule,
    LocationInputModule,
    NzDropDownModule,
    LocaleSelectModule,
    AdminTalentStatisticModule,
  ],
})
export class AdminTalentListModule {}
