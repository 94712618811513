import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TagsOutputComponent } from "./tags-output.component";
import { NzTagModule } from "ng-zorro-antd/tag";

@NgModule({
  declarations: [TagsOutputComponent],
  imports: [CommonModule, NzTagModule],
  exports: [TagsOutputComponent],
})
export class TagsOutputModule {}
