import { Component, OnDestroy } from "@angular/core";
import { MissionPartner } from "../../api/sign-up/signup.types";
import { ActivatedRoute } from "@angular/router";
import { AccountStatus, LoginData } from "../login-page/login-page-data/login.types";
import { ProfileService } from "../../api/profile/profile.service";
import { Subject } from "rxjs";
import { GoogleAnalyticsService } from "@hakimio/ngx-google-analytics";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";

@Component({
  selector: "mh-register-mission-partner",
  templateUrl: "./register-mission-partner.component.html",
  styleUrls: ["./register-mission-partner.component.less"],
})
export class RegisterMissionPartnerComponent implements OnDestroy {
  currentStep = 0;
  profile!: MissionPartner;
  loginData: LoginData;
  loading = false;
  destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private gaService: GoogleAnalyticsService,
    private translateService: TranslateService,
    private titleService: Title,
  ) {
    this.profile = this.activatedRoute.snapshot.data["profile"];
    this.loginData = this.activatedRoute.snapshot.data["loginData"];

    if (!this.loginData?.accountStatuses?.includes(AccountStatus.CONFIRMED)) {
      this.currentStep = 3;
    }
    if (!this.profile.companyOverview?.companyType) {
      this.currentStep = 2;
    }
    if (!this.profile.contactPerson?.firstName) {
      this.currentStep = 1;
    }
    if (!this.profile.email) {
      this.currentStep = 0;
    }
    this.trackCurrentStep(this.currentStep);
  }

  pre(): void {
    this.currentStep -= 1;
  }

  next(): void {
    this.currentStep += 1;
    this.trackCurrentStep(this.currentStep);
  }

  done(): void {
    console.log("done");
  }

  onNextStep() {
    this.next();
    window.scrollTo({ top: 0 });
  }

  onFormChange(value: MissionPartner) {
    this.profile = {
      ...this.profile,
      ...value,
    };
  }

  onRegistered(data: { token: string; account: MissionPartner }) {
    this.profile = data.account;
  }

  onUpdateUser(): void {
    this.loading = true;
    this.profile = {...this.profile, stepNumber: this.currentStep, lastStep: this.currentStep == 2};
    this.profileService.missionPartnerUpdate(this.profile as MissionPartner).subscribe((result) => {
      this.loading = false;
      if (result) this.next();
    });
  }

  private trackCurrentStep(currentStep: number) {
    switch (currentStep) {
      case 0:
        this.gaService.event("sign_up_0", { category: "mp_sign_up", label: "Email password form" });
        this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.translateService.instant('reg.mp.title') }));
        break;
      case 1:
        this.gaService.event("sign_up_1", { category: "mp_sign_up", label: "Contact form" });
        this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.translateService.instant('reg.mp.company-contacts.title') }));
        break;
      case 2:
        this.gaService.event("sign_up_2", { category: "mp_sign_up", label: "Short profile form" });
        this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.translateService.instant('reg.mp.company-details.title') }));
        break;
      case 3:
        this.gaService.event("sign_up_email_doi_block", { category: "mp_sign_up", label: "Email verification" });
        this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.translateService.instant('reg.talent.email-confirmation.title') }));
        break;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
