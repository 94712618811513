import { CmsNameValueDTO } from "../../../api/cms/cms-api.types";
import { CmsNameValue } from "../../../api/cms/cms.types";
import { SalaryExpectation } from "../../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation.type";
import { AccountStatus, AccountType } from "../../login-page/login-page-data/login.types";
import { SimpleLocation } from "../../../api/google-geocoding/google-geocoding.types";

export interface AccountId {
  internalId: string;
}

export interface AccountDTO {
  accountId: AccountId;
  email: string;
  statuses: string[];
  registrationTimestamp: number;
}

export interface Account {
  accountId: AccountId;
  email: string;
  statuses: string[];
  registrationTimestamp: number;
}

export interface TalentAccountDTO extends AccountDTO {
  salutation: string;
  firstName: string;
  lastName: string;
  commentsExist: boolean;
  jobTitle: CmsNameValueDTO;
  careerLevel: CmsNameValueDTO;
  sector: CmsNameValueDTO;
  salaryExpectationMin?: number;
  salaryExpectationMax?: number;
  coursesOfStudy: string[];
  expertFields: CmsNameValueDTO[];
  wishDepartments: CmsNameValueDTO[];
  industries: CmsNameValueDTO[];
  jobTitles: string[];
  city: string;
  cvExist: boolean;
  cvUrl?: string;
  communityMember: boolean;
}

export interface TalentAccount extends Account {
  salutation: string;
  firstName: string;
  lastName: string;
  commentsExist: boolean;
  jobTitle: CmsNameValue;
  careerLevel: CmsNameValue;
  salaryExpectations?: SalaryExpectation;
  sector: CmsNameValue;
  city: string;
  coursesOfStudy: string[];
  jobTitles: string[];
  industries: CmsNameValue[];
  wishDepartments: CmsNameValue[];
  expertFields: CmsNameValue[];
  cvExist: boolean;
  cvUrl?: string;
  communityMember: boolean;
}

export interface MissionPartnerAccountDTO extends AccountDTO {
  accountId: { internalId: string };
  companyName: string;
  contactName: string;
  email: string;
  firstName: string;
  registrationTimestamp: number;
  statuses: Array<AccountStatus>;
  commentsExist: boolean;
  stage: number | null;
}

export interface MissionPartnerAccount extends Account {
  accountId: { internalId: string };
  companyName: string;
  contactName: string;
  email: string;
  firstName: string;
  registrationTimestamp: number;
  statuses: Array<AccountStatus>;
  commentsExist: boolean;
  stage: number | null;
}

export interface AdminAccountDTO extends AccountDTO {
  firstName: string;
}

export interface AdminAccount extends Account {
  firstName: string;
}

export interface AccountFilter {
  paging: {
    page: number;
    itemsOnPage: number;
  };
  searchTerm?: string;
  timeRange?: Date[];
  sort?: Sort[];
  filters?: Filter[];
  groupFilters?: GroupFilter[];
  location?: SimpleLocation;
}

export interface Sort {
  order: "ASC" | "DESC";
  field: string;
}

export interface Filter {
  value: string | number;
  field: string;
}

export interface GroupFilter {
  value: string | number[];
  field: string;
}

export interface AccountFilterDTO {
  paging: {
    page: number;
    itemsOnPage: number;
  };
  timeRange?: FilterRangeDTO;
  searchTerm: string;
  sort?: Sort[];
  filters?: Filter[];
  groupFilters?: GroupFilter[];
  location?: LocationFilterDTO;
}

export interface LocationFilterDTO {
  location: {
    postalCode?: string;
    country?: string;
    city?: string;
    cityOptions?: string[];
    location?: { lat: number; lng: number };
    geocoderResult?: string;
    formattedAddress?: string;
  };
  radius?: CmsNameValueDTO;
}

export interface FilterRangeDTO {
  start: number;
  end: number;
}

export interface CommentDTO {
  commentId: string;
  text: string;
  authorName: string;
  creationTimestamp: string;
}

export interface Comment {
  commentId: string;
  text: string;
  authorName: string;
  creationTimestamp: string;
}

export interface CommentFromJoDTO extends CommentDTO {
  jobOpportunityId: string;
  jobOpportunityName: string | null;
  missionPartnerName: string | null;
  missionPartnerId: string | null;
  forMissionPartnerName: string | null;
  forMissionPartnerId: string | null;
  stage: CmsNameValue | null;
}

export interface CommentFromJo extends Comment {
  jobOpportunityId: string;
  jobOpportunityName: string | null;
  missionPartnerName: string | null;
  missionPartnerId: string | null;
  forMissionPartnerName: string | null;
  forMissionPartnerId: string | null;
  stage: CmsNameValue | null;
}

export interface ActiveConversation {
  talentId: AccountId;
  missionPartnerId: AccountId;
  missionPartnerCompanyName: string;
  talentName: string;
  creationTimestamp: number;
  startedBy: AccountType;
  lastMessageBy: AccountType;
  lastMessageTimestamp: number;
  lastMessageDistanceToNow: string;
  profileOpened: boolean;
}

export interface ActiveConversationDTO {
  talentId: AccountId;
  missionPartnerId: AccountId;
  missionPartnerCompanyName: string;
  talentName: string;
  creationTimestamp: number;
  startedBy: AccountType;
  lastMessageBy: AccountType;
  lastMessageTimestamp: number;
  profileOpened: boolean;
}

export interface Negotiation {
  initiatorName: string;
  initiatorType: AccountType;
  otherParticipantName: string;
  otherParticipantType: AccountType;
  closer: string;
  closerType: AccountType;
  status: NegotiationStatus;
  creationTimestamp: number;
  updateTimestamp: number;
  duration: number;
  formattedDuration: string;
}

export interface NegotiationDTO {
  initiatorName: string;
  initiatorType: AccountType;
  otherParticipantName: string;
  otherParticipantType: AccountType;
  closer: string;
  closerType: AccountType;
  status: NegotiationStatus;
  creationTimestamp: number;
  updateTimestamp: number;
  duration: number;
}

export enum NegotiationStatus {
  ALL = "ALL",
  INITIATED = "INITIATED",
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  MP_AGREEMENT_REACHED = "MP_AGREEMENT_REACHED",
  TALENT_AGREEMENT_REACHED = "TALENT_AGREEMENT_REACHED",
  COMPLETED = "COMPLETED",
}

export function getStatusShortName(status: string): string {
  switch (status) {
    case "VERIFIED":
      return "Ve";
    case "VACATIONING":
      return "Va";
    case "REVIEWED":
      return "Rev";
    case "MARKED_FOR_DELETION":
      return "D";
    case "REGISTERED":
    case "CONFIRMED":
    default:
      return status[0];
  }
}
