import { Component, Inject, ViewChild } from "@angular/core";
import { JobOffer } from "../../../../../api/job-offer/job-offer.types";
import { JobOfferFormComponent } from "../job-offer-form/job-offer-form.component";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../../../home-admin-data/account.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { compareCmsNameValue } from "../../../../../api/cms/cms.types";
import { map } from "rxjs";
import { MissionPartnerAccount } from "../../../home-admin-data/account.types";
import { AdminJobOfferService } from "../../../../../api/admin-job-offer/admin-job-offer.service";

@Component({
  selector: "mh-admin-job-offer-create",
  templateUrl: "./admin-job-offer-create.component.html",
  styleUrls: ["./admin-job-offer-create.component.less"],
})
export class AdminJobOfferCreateComponent {
  jobOffer!: JobOffer;
  @ViewChild(JobOfferFormComponent) form!: JobOfferFormComponent;

  missionPartners!: Array<MissionPartnerAccount>;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private readonly translateService: TranslateService,
    private accountService: AccountService,
    private adminJobOfferService: AdminJobOfferService,
    private notificationService: NzNotificationService,
  ) {
    this.accountService
      .loadMissionPartnerList({
        paging: {
          page: 1,
          itemsOnPage: 100,
        },
        searchTerm: "",
      })
      .pipe(map((response) => response.items))
      .subscribe((data) => {
        this.missionPartners = data;
      });
  }

  onFormChange(change: JobOffer) {
    this.jobOffer = change;
  }

  onCancel(): void {
    this.modal.close();
  }

  onSave(): void {
    if (!this.form.isValid()) {
      return;
    }
    this.adminJobOfferService.createJobOpportunity(this.jobOffer).subscribe((success) => {
      if (success) {
        this.notificationService.success("", "Job offer created");
        this.modal.close("success");
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
