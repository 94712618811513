import { Injectable } from "@angular/core";
import { AssessmentApi } from "./assessment.api";
import { map, Observable } from "rxjs";
import {
  FactsAndFigures,
  MAAnswerSaveRequest,
  MAAssessmentFormError,
  MaturityAssessment,
  MaturityAssessmentForm,
} from "./assessment.types";
import { AssessmentSerializer } from "./assessment.serializer";
import { ExternalFormSetup, Hashtags, MaturityAssessmentDTO } from "./assessment.api.types";
import { saveAs } from "file-saver";
import { AccountFilter } from "../../pages/home-admin/home-admin-data/account.types";
import { PaginatedContent } from "../common.types";
import { AccountSerializer } from "../../pages/home-admin/home-admin-data/account.serializer";

@Injectable({ providedIn: "root" })
export class AssessmentService {
  constructor(
    private assessmentApi: AssessmentApi,
    private assessmentSerializer: AssessmentSerializer,
    private accountSerializer: AccountSerializer,
  ) {}

  getMaturityAssessmentList(): Observable<Array<MaturityAssessment>> {
    return this.assessmentApi
      .getMaturityAssessmentList()
      .pipe(map((dtos) => this.assessmentSerializer.deserializeMaturityAssessments(dtos)));
  }

  loadMaturityAssessmentList(filter: AccountFilter): Observable<PaginatedContent<MaturityAssessment>> {
    return this.assessmentApi.loadMaturityAssessmentList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.assessmentSerializer.deserializeMaturityAssessments(
            paginatedContent.items as Array<MaturityAssessmentDTO>,
          ),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  setUp(missionPartnerId: string): Observable<MaturityAssessment> {
    return this.assessmentApi
      .setUp(missionPartnerId)
      .pipe(map((dto) => this.assessmentSerializer.deserializeMaturityAssessment(dto)));
  }

  delete(maturityAssessmentId: string): Observable<boolean> {
    return this.assessmentApi.delete(maturityAssessmentId);
  }

  complete(maturityAssessmentId: string): Observable<MaturityAssessment> {
    return this.assessmentApi
      .complete(maturityAssessmentId)
      .pipe(map((dto) => this.assessmentSerializer.deserializeMaturityAssessment(dto)));
  }

  uncomplete(maturityAssessmentId: string): Observable<MaturityAssessment> {
    return this.assessmentApi
      .uncomplete(maturityAssessmentId)
      .pipe(map((dto) => this.assessmentSerializer.deserializeMaturityAssessment(dto)));
  }

  deleteReport(maturityAssessmentId: string): Observable<boolean> {
    return this.assessmentApi.deleteReport(maturityAssessmentId);
  }

  //MP: assessment state
  getMaturityAssessmentState(): Observable<MaturityAssessment | null> {
    return this.assessmentApi.getMaturityAssessmentState().pipe(
      map((dto) => {
        return dto ? this.assessmentSerializer.deserializeMaturityAssessment(dto) : null;
      }),
    );
  }

  // admin: get assessment state by id
  getMaturityAssessmentStateById(maturityAssessmentId: string): Observable<MaturityAssessment | null> {
    return this.assessmentApi.getMaturityAssessmentStateById(maturityAssessmentId).pipe(
      map((dto) => {
        return dto ? this.assessmentSerializer.deserializeMaturityAssessment(dto) : null;
      }),
    );
  }

  externalFormSetUp(request: ExternalFormSetup): Observable<MaturityAssessmentForm> {
    return this.assessmentApi
      .externalFormSetUp(this.assessmentSerializer.serializeExternalFormSetUp(request))
      .pipe(map((dto) => this.assessmentSerializer.deserializeMaturityAssessmentForm(dto)));
  }

  // External user: get assessment form by token
  getMaturityAssessmentForm(token: string): Observable<MaturityAssessmentForm | MAAssessmentFormError> {
    return this.assessmentApi.getMaturityAssessmentForm(token).pipe(
      map((dto) => {
        if (typeof dto == "string") {
          return dto as MAAssessmentFormError;
        } else {
          return this.assessmentSerializer.deserializeMaturityAssessmentForm(dto);
        }
      }),
    );
  }
  // External user: get assessment form by token
  checkMaturityAssessmentByToken(token: string): Observable<{ id: string } | MAAssessmentFormError> {
    return this.assessmentApi.checkMaturityAssessmentByToken(token).pipe(
      map((dto) => {
        if (typeof dto == "string") {
          return dto as MAAssessmentFormError;
        } else {
          return dto;
        }
      }),
    );
  }
  // External user: save form answer
  saveAnswer(answer: MAAnswerSaveRequest): Observable<MaturityAssessment> {
    return this.assessmentApi
      .saveAnswer(answer)
      .pipe(map((dto) => this.assessmentSerializer.deserializeMaturityAssessment(dto)));
  }
  // External user: complete form
  completeForm(token: string): Observable<MaturityAssessmentForm> {
    return this.assessmentApi
      .completeForm(token)
      .pipe(map((dto) => this.assessmentSerializer.deserializeMaturityAssessmentForm(dto)));
  }

  getMaturityAssessmentResults(maturityAssessment: MaturityAssessment): Observable<void> {
    return this.assessmentApi.getMaturityAssessmentResults(maturityAssessment.id).pipe(
      map((response) => {
        saveAs((response as any).body, maturityAssessment.missionPartnerName + ".csv");
      }),
    );
  }

  getAllMaturityAssessmentResults(): Observable<void> {
    return this.assessmentApi.getAllMaturityAssessmentResults().pipe(
      map((response) => {
        saveAs((response as any).body, "maturity-assessment-all.csv");
      }),
    );
  }

  getFactsAndFigures(): Observable<FactsAndFigures> {
    return this.assessmentApi.getFactsAndFigures();
  }

  saveFactsAndFigures(factsAndFigures: FactsAndFigures): Observable<FactsAndFigures> {
    return this.assessmentApi.saveFactsAndFigures(factsAndFigures);
  }

  setHashtags(accountId: string, hashtags: Hashtags): Observable<Hashtags> {
    return this.assessmentApi.setHashtags(accountId, hashtags);
  }
}
