import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentSettingsPhoneComponent } from "./talent-settings-phone.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import {CodeInputModule, NzIntlTelInputModule} from "@momhunting/common-ui";

@NgModule({
  declarations: [TalentSettingsPhoneComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzWaveModule,
    ReactiveFormsModule,
    TranslateModule,
    CodeInputModule,
    NzIntlTelInputModule,
  ],
  exports: [TalentSettingsPhoneComponent],
})
export class TalentSettingsPhoneModule {}
