import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { JobOfferMatchesOfferViewComponent } from "./job-offer-matches-offer-view.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTagModule } from "ng-zorro-antd/tag";
import { TranslateModule } from "@ngx-translate/core";
import { TagsOutputModule } from "../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { ViewJobOfferLocationModule } from "@momhunting/common-ui";

@NgModule({
  declarations: [JobOfferMatchesOfferViewComponent],
  exports: [JobOfferMatchesOfferViewComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzGridModule,
    NzIconModule,
    NzTagModule,
    TagsOutputModule,
    TranslateModule,
    ViewJobOfferLocationModule,
  ],
})
export class JobOfferMatchesOfferViewModule {}
