import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoginService} from "../login-page/login-page-data/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {gridResponsiveMap, NzBreakpointEnum, NzBreakpointService} from "ng-zorro-antd/core/services";
import {catchError, EMPTY, Observable, Subject, takeUntil} from "rxjs";
import {LoginData} from "../login-page/login-page-data/login.types";
import {AuthorizationService} from "../../api/authorization/authorization.service";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";

@Component({
  selector: "mh-account-confirmed",
  templateUrl: "./account-confirmed.component.html",
  styleUrls: ["./account-confirmed.component.less"],
})

export class AccountConfirmedComponent implements OnInit, OnDestroy {
  user$!: Observable<LoginData>;
  private readonly destroy$ = new Subject<void>();
  public isMdBreakpoint: boolean = false;

  constructor(
    private readonly loginService: LoginService,
    private authorizationService: AuthorizationService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointService: NzBreakpointService,
    private readonly translateService: TranslateService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: 'Account confirmed' }));
    this.user$ = this.authorizationService.getAuthorizedUser();
  }

  ngOnInit() {
    this.handleToken();

    this.breakpointService
      .subscribe(gridResponsiveMap)
      .pipe(takeUntil(this.destroy$))
      .subscribe(breakpoint => {
        this.isMdBreakpoint =
          breakpoint === NzBreakpointEnum.md ||
          breakpoint === NzBreakpointEnum.lg ||
          breakpoint === NzBreakpointEnum.xl ||
          breakpoint === NzBreakpointEnum.xxl;
      });
  }

  private handleToken(): void {
    const emailToken = this.activatedRoute.snapshot.queryParams['token'];
    if (emailToken) {
      this.loginService.emailChangedConfirmationCheck(emailToken)
        .pipe(takeUntil(this.destroy$))
        .pipe(
          catchError((err) => {
            this.router.navigate(['/expired-token']);
            return EMPTY;
          })
        )
        .subscribe((token) => {
          if (token) {
            this.loginService.setToken(token);
            this.authorizationService.updateAuthorizedUser();
          } else {
            this.router.navigate(['/expired-token']);
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
