import { Component, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MAAssessmentFormError,
  MaturityAssessment,
  MaturityAssessmentForm,
} from "../../../api/assessment/assessment.types";
import { AssessmentService } from "../../../api/assessment/assessment.service";
import { CmsService } from "../../../api/cms/cms.service";
import { Subscription, take } from "rxjs";
import { LocaleService } from "@momhunting/core";

@Component({
  selector: "mh-external-assessment-questioner",
  templateUrl: "./external-assessment-questioner.component.html",
  styleUrls: ["./external-assessment-questioner.component.less"],
})
export class ExternalAssessmentQuestionerComponent implements OnDestroy {
  token: string;
  assessment: Partial<MaturityAssessment> | undefined;
  assessmentForm: MaturityAssessmentForm | undefined;
  error: MAAssessmentFormError | undefined;
  maturityAssessmentIntro = "";
  onLangChangeSub: Subscription;

  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private assessmentService: AssessmentService,
    private router: Router,
    private cmsService: CmsService,
    private readonly localeService: LocaleService,
  ) {
    this.titleService.setTitle(
      this.translateService.instant("page.title", { pageName: this.translateService.instant("assessment-page.title") }),
    );
    this.token = this.activatedRoute.snapshot.paramMap.get("token") || "";
    const assessmentForm = this.activatedRoute.snapshot.data["assessmentForm"];
    const assessment = this.activatedRoute.snapshot.data["assessment"];
    if (assessmentForm?.form) {
      this.assessmentForm = assessmentForm;
    }
    if (assessment?.id) {
      this.assessment = assessment;
    }
    if (!assessmentForm?.form && !assessment?.id) {
      this.error = assessment || assessmentForm;
    }

    this.onLangChangeSub = this.translateService.onLangChange.subscribe(() => {
      this.loadMaturityAssessmentIntro();
    });
    this.loadMaturityAssessmentIntro();
  }

  loadMaturityAssessmentIntro() {
    this.cmsService
      .loadBox("MaturityAssessmentIntro", this.localeService.currentLocale)
      .pipe(take(1))
      .subscribe((content) => {
        this.maturityAssessmentIntro = content;
      });
  }

  onSetupDone(form: MaturityAssessmentForm) {
    this.assessmentForm = form;
    this.error = undefined;
    return this.router.navigate(["/assessment/personal", form.token]);
  }
  onCompleteForm() {
    this.assessmentService.completeForm(this.assessmentForm?.token as string).subscribe((response) => {
      this.assessmentForm = response;
    });
  }

  ngOnDestroy() {
    this.onLangChangeSub.unsubscribe();
  }

  protected readonly MAAssessmentFormError = MAAssessmentFormError;
}
