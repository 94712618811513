import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { Subject, takeUntil } from "rxjs";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { VideoRecordingService } from "../../../../../../../../core/src/lib/recording/video-recording.service";
import {getSeekableBlob} from "recordrtc";

@Component({
  selector: "mh-message-record-video-dialog",
  templateUrl: "./mh-message-record-video-dialog.component.html",
  styleUrls: ["./mh-message-record-video-dialog.component.less"],
})
export class MhMessageRecordVideoDialogComponent {
  @ViewChild('videoElement') videoElement!: ElementRef;

  private readonly destroy$ = new Subject<void>();
  isRecording = false;
  recordingLoading = false;
  recordedTime = '';
  blobUrl?: SafeUrl | null;
  private blob?: Blob;
  private mediaName = '';
  previewVideoElement: any;
  fileList: FileList | null | undefined;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private videoRecordingService: VideoRecordingService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    this.setupVideoRecordingSubscriptions();
  }

  private setupVideoRecordingSubscriptions() {
    this.videoRecordingService.recordingFailed().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleRecordingFailure();
    });

    this.videoRecordingService.getRecordedTime().pipe(takeUntil(this.destroy$)).subscribe((time) => {
      this.handleRecordedTime(time);
    });

    this.videoRecordingService.getRecordedBlob().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.handleRecordedBlob(data, () => this.handleVideoUpload());
    });
  }

  private handleRecordingFailure() {
    this.isRecording = false;
    this.ref.detectChanges();
  }

  private handleRecordedTime(time: any) {
    this.recordedTime = time;
    this.ref.detectChanges();
  }

  private handleRecordedBlob(data: any, handleUpload: () => void) {
    if (data.blob) {
      this.blob = data.blob;
      this.mediaName = data.title;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.ref.detectChanges();
      handleUpload();
    }
  }

  ngAfterViewInit() {
    this.previewVideoElement = this.videoElement.nativeElement;
  }

  startVideoRecording() {
    if (!this.isRecording && this.previewVideoElement) {
      this.recordingLoading = true;
      this.isRecording = true;
      this.previewVideoElement.controls = false;
      this.previewVideoElement.muted = true;
      this.videoRecordingService.startRecording().then(stream => {
        this.previewVideoElement.srcObject = stream;
        this.previewVideoElement.play();
        this.recordingLoading = false;
      })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
    }
  }

  abortVideoRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.videoRecordingService.abortRecording();
    }
  }

  stopVideoRecording() {
    if (this.isRecording) {
      this.videoRecordingService.stopRecording();
      this.isRecording = false;
      this.previewVideoElement.srcObject = this.blobUrl;
      this.previewVideoElement.controls = true;
      this.previewVideoElement.muted = false;
    }
  }

  handleVideoUpload(): void {
    if (this.blob) {
      let self = this;
        getSeekableBlob(this.blob, function(seekableBlob) {
          const file = self.modifyFileMimeType(new File([seekableBlob], self.mediaName, { type:seekableBlob.type }));
          const dt = new DataTransfer();
          dt.items.add(file);
          self.fileList = dt.files;
        });
    }
  }

  retakeVideo(): void {
    this.blobUrl = null;
    this.previewVideoElement.srcObject = null;
    this.startVideoRecording();
  };

  onCancel() {
    this.modal.close();
  }

  onSubmit() {
    return this.modal.triggerOk();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.abortVideoRecording();
  }

  private modifyFileMimeType(originalFile: File): File {
    const codecsMatch = originalFile.type.match(/codecs=([^;]*)/);
    const codecs = codecsMatch ? codecsMatch[1] : "";

    if (codecs) {
      const modifiedMimeType = originalFile.type.replace(codecs, `"${codecs}"`);
      const modifiedFile = new File([originalFile], originalFile.name, { type: modifiedMimeType, lastModified: originalFile.lastModified });
      return modifiedFile;
    }

    return originalFile;
  }
}
