import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { JobOfferListActiveComponent } from "./job-offer-list-active.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TagsOutputModule } from "../../../../../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { MpJobOfferItemModule } from "../mp-job-offer-item/mp-job-offer-item.module";
import { NzEmptyModule } from "ng-zorro-antd/empty";

@NgModule({
  declarations: [JobOfferListActiveComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzGridModule,
    NzIconModule,
    TagsOutputModule,
    TranslateModule,
    RouterModule,
    NzToolTipModule,
    MpJobOfferItemModule,
    NzEmptyModule,
  ],
  exports: [JobOfferListActiveComponent],
})
export class JobOfferListActiveModule {}
