import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderUnseenMessagesIconComponent } from "./header-unseen-messages-icon.component";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import {RouterLink} from "@angular/router";

@NgModule({
  declarations: [HeaderUnseenMessagesIconComponent],
  imports: [CommonModule, NzIconModule, NzBadgeModule, NzButtonModule, NzDropDownModule, RouterLink],
  exports: [HeaderUnseenMessagesIconComponent],
})
export class HeaderUnseenMessagesIconModule {}
