<div class="container">
  <div nz-row [nzGutter]="[0, 30]">
    <div nz-col [nzSpan]="24">
      <div class="tabs">
        <ul nz-menu nzMode="horizontal">
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/admin', 'mission-partner-view', missionPartnerId]">
              {{'profile.mission-partner.tab.info' | translate}}
            </a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/admin', 'job-offer-list']" [queryParams]="{ missionPartnerId: missionPartnerId }">
              {{'profile.mission-partner.tab.job-offers' | translate}}
            </a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/admin', 'invoices']" [queryParams]="{ missionPartnerId: missionPartnerId }">
              {{'profile.mission-partner.tab.invoices' | translate}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div nz-col [nzSpan]="24">
      <mh-micro-site
        *ngIf="missionPartner"
        [missionPartner]="missionPartner"
        [viewerAccountType]="AccountType.ADMIN"
        [testimonials]="testimonials"
        [testimonialsRequest]="testimonialsRequest"
        (signInAs)="onSignInAs()"
      ></mh-micro-site>
    </div>

    <div nz-col [nzSpan]="24" *ngIf="missionPartner">
      <mh-admin-talent-data-spaces [dataSpace]="missionPartner?.dataspace || []"></mh-admin-talent-data-spaces>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="profile-preview__section" id="profile-info">
        <h2 class="profile-preview__section-title">
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
          {{'admin.talent-view.section.title.profile-info' | translate}}
        </h2>
        <div nz-row class="profile-preview__section-content" *ngIf="missionPartner">
          <ng-container *ngIf="missionPartner?.updateTimestamp">
            <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">{{'profile.update-timestamp.label' | translate}}</h4>
            <p nz-col [nzSpan]="24" [nzMd]="18">
              {{ missionPartner.updateTimestamp | date : "dd.MM.YYYY HH:mm" }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24">
      <div class="profile-preview__section" id="comments">
        <h2 class="profile-preview__section-title">
          <span nz-icon nzType="message" nzTheme="outline"></span>
          {{'admin.talent-view.section.title.comments' | translate}}
        </h2>
        <mh-account-comments
          [talentId]="missionPartnerId"
        ></mh-account-comments>
      </div>
    </div>
  </div>
</div>
