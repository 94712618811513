import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerSettingsComponent } from "./mission-partner-settings.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { MissionPartnerSettingsEmailModule } from "./mission-partner-settings-email/mission-partner-settings-email.module";
import { MissionPartnerSettingsPasswordModule } from "./mission-partner-settings-password/mission-partner-settings-password.module";
import { TranslateModule } from "@ngx-translate/core";
import { MissionPartnerSettingsPhoneModule } from "./mission-partner-settings-phone/mission-partner-settings-phone.module";
import { MissionPartnerSettingsNewsletterModule } from "./mission-partner-settings-newsletter/mission-partner-settings-newsletter.module";

@NgModule({
  declarations: [MissionPartnerSettingsComponent],
  imports: [
    CommonModule,
    MissionPartnerSettingsPasswordModule,
    MissionPartnerSettingsEmailModule,
    NzButtonModule,
    NzDividerModule,
    NzGridModule,
    NzPageHeaderModule,
    NzWaveModule,
    TranslateModule,
    MissionPartnerSettingsPhoneModule,
    MissionPartnerSettingsNewsletterModule,
  ],
})
export class MissionPartnerSettingsModule {}
