import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeTalentComponent } from "./home-talent.component";
import { HomeTalentRoutingModule } from "./home-talent-routing.module";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzCardModule } from "ng-zorro-antd/card";
import { RouterLink } from "@angular/router";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { LocaleSelectModule } from "@momhunting/common-ui";
import {
  DrawerModule,
  IconModule,
  ListModule,
  NavBarModule,
  PopoverItemModule,
  PopoverModule,
  WhiteSpaceModule,
  WingBlankModule,
} from "ng-zorro-antd-mobile";
import { TalentProfileModule } from "./subpages/talent-profile/talent-profile.module";
import { TalentDashboardModule } from "./subpages/talent-dashboard/talent-dashboard.module";
import { TalentProfilePreviewModule } from "./subpages/talent-profile-preview/talent-profile-preview.module";
import { TalentSettingsModule } from "./subpages/talent-settings/talent-settings.module";
import { TalentInboxModule } from "./subpages/talent-inbox/talent-inbox.module";
import { TalentReferencesModule } from "./subpages/talent-references/talent-references.module";
import { DebugModule } from "../debug/debug.module";
import { NotificationDropdownModule } from "../../common-componnets/notification-dropdown/notification-dropdown.module";
import { TalentMissionPartnersModule } from "./subpages/talent-mission-partners/talent-mission-partners.module";
import { TalentNotificationsModule } from "./subpages/talent-notifications/talent-notifications.module";
import { ScrollToValidationErrorModule } from "../../common-componnets/scroll-to-validation-error/scroll-to-validation-error.module";
import { HeaderNavItemModule } from "../../common-componnets/header-nav-item/header-nav-item.module";
import { HeaderUnseenMessagesIconModule } from "../../common-componnets/header-unseen-messages-icon/header-unseen-messages-icon.module";
import { TalentViewMicroSiteModule } from "./subpages/talent-view-micro-site/talent-view-micro-site.module";
import { TalentMyVisitsModule } from "./subpages/talent-my-visits/talent-my-visits.module";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        HomeTalentRoutingModule,
        TalentProfileModule,
        TalentDashboardModule,
        TalentProfilePreviewModule,
        TalentSettingsModule,
        TalentInboxModule,
        TalentMissionPartnersModule,
        TalentNotificationsModule,
        TalentViewMicroSiteModule,
        TalentMyVisitsModule,
        DebugModule,
        NzButtonModule,
        NzLayoutModule,
        NzDividerModule,
        NzFormModule,
        NzCardModule,
        NzMenuModule,
        NzIconModule,
        RouterLink,
        NzDropDownModule,
        LocaleSelectModule,
        NavBarModule,
        IconModule,
        WingBlankModule,
        ListModule,
        PopoverModule,
        PopoverItemModule,
        DrawerModule,
        WhiteSpaceModule,
        TalentReferencesModule,
        NotificationDropdownModule,
        ScrollToValidationErrorModule,
        HeaderNavItemModule,
        HeaderUnseenMessagesIconModule,
    ],
  declarations: [HomeTalentComponent],
  exports: [HomeTalentComponent],
})
export class HomeTalentModule {}
