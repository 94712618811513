import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TestimonialsService } from "../../../../api/testimonials/testimonials.service";
import { ActivatedRoute } from "@angular/router";
import { Testimonial, TestimonialRequest } from "../../../../api/testimonials/testimonials.types";
import { TranslateService } from "@ngx-translate/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "mh-mission-partner-testimonials",
  templateUrl: "./mission-partner-testimonials.component.html",
  styleUrls: ["./mission-partner-testimonials.component.less"],
})
export class MissionPartnerTestimonialsComponent implements OnInit {
  testimonials: Testimonial[];
  testimonialsRequest: TestimonialRequest[];
  public formGroup!: FormGroup;
  isSendingTestimonial = false;
  isResendingTestimonial = false;

  constructor(
    private readonly testimonialsService: TestimonialsService,
    private activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
  ) {
    this.testimonials = this.activatedRoute.snapshot.data["testimonials"];
    this.testimonialsRequest = this.activatedRoute.snapshot.data["testimonialsRequest"];
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      company: new FormControl("", [Validators.required]),
      position: new FormControl("", [Validators.required]),
    });
  }

  submitTestimonialRequest() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.isSendingTestimonial = true;
    this.testimonialsService.createTestimonialRequest(this.formGroup.value).subscribe((success) => {
      if (success) {
        this.testimonialsService.loadTestimonialsRequest().subscribe((testimonialsRequest) => {
          this.testimonialsRequest = testimonialsRequest;
          this.notificationService.success('', this.translateService.instant('testimonials.testimonial-request.send.success'));
        });
        this.formGroup.reset();
      }
      this.isSendingTestimonial = false;
    });
  }

  confirmDeleteTestimonial(testimonialId: string) {
    this.modal.confirm({
      nzTitle: this.translateService.instant('testimonials.delete-testimonial.confirm.title'),
      nzContent: `<b>${this.translateService.instant('testimonials.delete-testimonial.confirm.text')}</b>`,
      nzOkText: this.translateService.instant('testimonials.delete-testimonial.confirm.ok.btn'),
      nzCancelText: this.translateService.instant('testimonials.delete-testimonial.confirm.cancel.btn'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteTestimonial(testimonialId),
    });
  }

  deleteTestimonial(testimonialId: string) {
    this.testimonialsService.deleteTestimonial(testimonialId).subscribe((success) => {
      if (success) {
        this.testimonials = this.testimonials.filter((testimonialRequest) => testimonialRequest.id !== testimonialId)
        this.notificationService.success('', this.translateService.instant('testimonials.delete-testimonial.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  confirmDeleteTestimonialRequest(testimonialId: string) {
    this.modal.confirm({
      nzTitle: this.translateService.instant('testimonials.delete-testimonial-request.confirm.title'),
      nzContent: `<b>${this.translateService.instant('testimonials.delete-testimonial-request.confirm.text')}</b>`,
      nzOkText: this.translateService.instant('testimonials.delete-testimonial-request.confirm.ok.btn'),
      nzCancelText: this.translateService.instant('testimonials.delete-testimonial-request.confirm.cancel.btn'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteTestimonialRequest(testimonialId),
    });
  }

  deleteTestimonialRequest(testimonialId: string) {
    this.testimonialsService.deleteTestimonialRequest(testimonialId).subscribe((success) => {
      if (success) {
        this.testimonialsRequest = this.testimonialsRequest.filter((testimonialRequest) => testimonialRequest.id !== testimonialId)
        this.notificationService.success('', this.translateService.instant('testimonials.delete-testimonial-request.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  resendTestimonialRequest(testimonialId: string) {
    this.isResendingTestimonial = true;
    this.testimonialsService.resendTestimonialRequest(testimonialId).subscribe((success) => {
      if (success) {
        this.notificationService.success('', this.translateService.instant('testimonials.testimonial-request.resend.success'));
      }
      this.isResendingTestimonial = false;
    });
  }
}
