import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NzCheckBoxOptionInterface } from "ng-zorro-antd/checkbox/checkbox-group.component";
import { TranslateService } from "@ngx-translate/core";
import { MAAnswerOption, MAAnswerOptionType } from "../../../api/assessment/assessment.types";

@Component({
  selector: "mh-assessment-check-box-group-select",
  templateUrl: "./assessment-check-box-group-select.component.html",
  styleUrls: ["./assessment-check-box-group-select.component.less"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssessmentCheckBoxGroupSelectComponent), multi: true },
  ],
})
export class AssessmentCheckBoxGroupSelectComponent implements ControlValueAccessor {
  @Input() set options(val: Array<MAAnswerOption>) {
    this._options = val
      .filter((maOpt) => maOpt.type === MAAnswerOptionType.SELECTABLE)
      .map((maOpt) => ({
        label: this.translate.instant(maOpt.id),
        value: maOpt.id,
      }));
    const freeTextOption = val.find((opt) => opt.type !== MAAnswerOptionType.SELECTABLE);
  }
  @Input() customInput = false;
  _options: Array<NzCheckBoxOptionInterface> = [];
  private selection: Array<MAAnswerOption> = [];
  customText: string | undefined;

  constructor(private translate: TranslateService) {}

  onCheckBoxesModelChange(value: Array<NzCheckBoxOptionInterface>): void {
    value.forEach((val) => {
      const index = this.selection.findIndex((op) => op.id == val.value);
      if (index > -1) this.selection[index].selected = !!val.checked;
    });

    this.onChange(this.selection);
  }
  onCustomInputChange(value: string): void {
    const index = this.selection.findIndex((sel) => sel.type === MAAnswerOptionType.FREE_TEXT);
    if (index > -1) this.selection[index] = { ...this.selection[index], value, selected: !!value };

    this.onChange(this.selection);
  }

  onChange = (value: Array<MAAnswerOption>) => {};
  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  writeValue(value: Array<MAAnswerOption>) {
    value = value || [];
    this.customText = value.find((cmsVal) => cmsVal.type == MAAnswerOptionType.FREE_TEXT)?.value;
    value.forEach((val) => {
      const index = this._options.findIndex((op) => op.value == val.id);
      if (index > -1) this._options[index].checked = val.selected;
    });
    this.selection = value;
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
}
