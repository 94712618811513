import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileCompanyDetailsComponent } from "./profile-company-details.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { TranslateModule } from "@ngx-translate/core";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { LocationInputModule } from "../../../../../../../../../common-ui/src/lib/location-input/location-input.module";
import { SalaryExpectationInputModule } from "../../../../../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation-input.module";
import { ExplanationLabelModule } from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [ProfileCompanyDetailsComponent],
  imports: [
    CommonModule,
    NzPageHeaderModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzWaveModule,
    NzDividerModule,
    TranslateModule,
    ExplanationLabelModule,
    SalaryExpectationInputModule,
    LocationInputModule,
    NzCheckboxModule,
  ],
  exports: [ProfileCompanyDetailsComponent],
})
export class ProfileCompanyDetailsModule {}
