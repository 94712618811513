import { ChangeDetectorRef, Component, OnInit, ViewContainerRef } from "@angular/core";
import { map, Observable, take, tap } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import { BreakpointObserver } from "@angular/cdk/layout";
import { InitiateConversationRequest } from "../../../../api/messages/messages.types";
import { MessagesService } from "../../../../api/messages/messages.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  CreateConversationDialogComponent
} from "../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.component";
import { VisitsService } from "libs/platform-pages/src/lib/api/visits/visits.service";
import { VisitTalent } from "libs/platform-pages/src/lib/api/visits/visits.types";

@Component({
  selector: "mh-talent-my-visits",
  templateUrl: "./talent-my-visits.component.html",
  styleUrls: ["./talent-my-visits.component.less"],
})
export class TalentMyVisitsComponent implements OnInit {
  visitsTalentList?: VisitTalent[];
  loading = true;
  searchForm!: UntypedFormGroup;
  preventDoubleFetch = true;

  isMobileView$: Observable<boolean>;

  constructor(
    private visitsService: VisitsService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private messagesService: MessagesService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap(() => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit(): void {
    this.fetchAccounts();
  }

  fetchAccounts() {
    this.loading = true;
    this.visitsService.loadVisitTalentList()
      .pipe(take(1))
      .subscribe((data) => {
        this.visitsTalentList = data;
        this.loading = false;
      });
  }

  openCreateConversationModal(event: Event, visitor: VisitTalent) {
    event.stopPropagation();
    this.modal.create({
      nzContent: CreateConversationDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { suggestRequest: { missionPartnerId: visitor.missionPartnerId } },
      nzWidth: 800,
      nzOnOk: (modalCmp) => this.doCreateConversation(visitor, modalCmp.formGroup.value)
    });
  }

  doCreateConversation(visitor: VisitTalent, formData: Partial<InitiateConversationRequest>) {
    if (!visitor.missionPartnerId) {
      return;
    }
    this.messagesService.initiateConversation(visitor.missionPartnerId, formData.messageText as string).subscribe((conversation) => {
      if (conversation) {
        this.notificationService.success("", this.translateService.instant("notification.success"));
        visitor.conversationId = conversation.id;
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  
  allExpanded = false;
  onAllExpandChange(): void {
    this.allExpanded = !this.allExpanded;
    if (this.allExpanded && this.visitsTalentList) {
      this.expandSet = new Set(this.visitsTalentList.map((item) => item.missionPartnerId));
    } else {
      this.expandSet.clear();
    }
  }

  expandSet = new Set<string>();
  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
    this.allExpanded = this.visitsTalentList?.every((item) => this.expandSet.has(item.missionPartnerId)) ?? false;
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
