import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Talent } from "../../../../../../api/sign-up/signup.types";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { CmsNameValue, compareCmsNameValue } from "../../../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../../../api/cms/cms-cached.service";
import { TranslateService } from "@ngx-translate/core";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: "mh-profile-experience",
  templateUrl: "./profile-experience.component.html",
  styleUrls: ["./profile-experience.component.less"],
})
export class ProfileExperienceComponent {
  @Input() talent!: Talent;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<void>();
  @Output() lastStepSaved = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public expertFields$: Observable<Array<CmsNameValue>>;
  public sectors$: Observable<Array<CmsNameValue>>;
  public careerLevel$: Observable<Array<CmsNameValue>>;
  public companyTypeOptions$: Observable<Array<CmsNameValue>>;
  public department$: Observable<Array<CmsNameValue>>;
  public industry$: Observable<Array<CmsNameValue>>;

  get showOldTrackRecord() {
    const oldTrackRecord = this.talent.oldTrackRecord;
    return (
      oldTrackRecord?.sector?.length ||
      oldTrackRecord?.industry?.length ||
      oldTrackRecord?.department?.length ||
      oldTrackRecord?.jobTitle ||
      oldTrackRecord?.companyName
    );
  }

  getExpertFieldsControl(): AbstractControl | null {
    return this.formGroup.controls["workExperience"].get("expertFields");
  }

  getOtherExpertFieldsControl(): AbstractControl | null {
    return this.formGroup.controls["workExperience"].get("otherExpertFields");
  }

  get experienceYears(): string {
    const exp = this.formGroup.controls["workExperience"].get("yearsOfExperience")?.value || 0;
    return this.numToYearString(exp, 30);
  }

  get leadershipYears(): string {
    const exp = this.formGroup.controls["workExperience"].get("yearsOfLeadership")?.value || 0;
    return this.numToYearString(exp, 30);
  }

  private numToYearString(value: number, max = 10) {
    let suffix = value == 1 ? "year.label" : "years.label";
    suffix = this.translateService.instant(suffix);
    return "<b>"+(value == max ? value + "+" : value)+"</b>" + " " + suffix;
  }

  constructor(
    private cmsCachedService: CmsCachedService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private notificationService: NzNotificationService,
  ) {
    this.expertFields$ = this.cmsCachedService.getUserDataByName("expert-field");
    this.sectors$ = this.cmsCachedService.getUserDataByName("sector");
    this.careerLevel$ = this.cmsCachedService.getUserDataByName("career-level", false);
    this.companyTypeOptions$ = this.cmsCachedService.getUserDataByName("company-type");
    this.department$ = this.cmsCachedService.getUserDataByName("expert-field");
    this.industry$ = this.cmsCachedService.getUserDataByName("industry");
  }

  onExpertFieldsChange(event: Array<CmsNameValue>) {
    if(this.getExpertFieldsControl()?.value.length > 10) {
      this.notificationService.warning('', this.translateService.instant("max-allowed-num.warning", { max: 10 }));
      this.getExpertFieldsControl()?.setValue(event.slice(0, 10));
    }
  }
  onOtherExpertFieldsChange() {
    if (this.getOtherExpertFieldsControl()?.value.length == 10) {
      this.notificationService.warning("", this.translateService.instant("max-allowed-num.warning", { max: 10 }));
    }
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      workExperience: this.fb.group({
        yearsOfExperience: [this.talent.workExperience?.yearsOfExperience || 0, []],
        yearsOfLeadership: [this.talent.workExperience?.yearsOfLeadership || 0, []],
        expertFields: [this.talent.workExperience?.expertFields, Validators.maxLength(10)],
        otherExpertFields: [this.talent.workExperience?.otherExpertFields, []],
      }),
      leadershipTeamSize: [this.talent.leadershipTeamSize, []],
      careerLevel: [this.talent.careerLevel, []],
      trackRecord: [this.talent.trackRecord, []],
      skills: [this.talent.skills, []],
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        ...value,
      }),
    );

    this.formGroup.updateValueAndValidity();
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    //this.loading = true;
    if (!this.talent.filledForTheFirstTime) {
      this.lastStepSaved.emit();
    }
    this.submitChange.emit();
  }

  onDeleteOldTrackRecord() {
    this.talent.oldTrackRecord = { sector: [], industry: [], department: [], jobTitle: "", companyName: "" };
    this.formChange.emit(this.talent);
  }

  onTrackRecordSave() {

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
