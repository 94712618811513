import { ConversationState, messagesFeature, MessagesState, StatusState } from "./messages.reducer";
import { Conversation } from "../../../api/messages/messages.types";
import { PaginatedContent } from "../../../api/common.types";

export function selectConversationList(state: { [messagesFeature]: MessagesState }): PaginatedContent<Conversation> {
  return state[messagesFeature].conversationList.data;
}

export function selectConversation(state: { [messagesFeature]: MessagesState }): Conversation {
  return state[messagesFeature].conversation.data as Conversation;
}

export function isConversationListLoading(state: { [messagesFeature]: MessagesState }): boolean {
  return state[messagesFeature].conversationList.status.loading;
}

export function selectConversationState(state: { [messagesFeature]: MessagesState }): ConversationState {
  return state[messagesFeature].conversation;
}

export function isConversationLoading(state: { [messagesFeature]: MessagesState }): boolean {
  return state[messagesFeature].conversation.status.loading;
}

export function selectUnseenMessageCount(state: { [messagesFeature]: MessagesState }): number {
  return state[messagesFeature].unseenCounter.data;
}
