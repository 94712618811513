import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MpJobOfferMatchesComponent } from "./mp-job-offer-matches.component";
import { JobOfferMatchesListModule } from "../../../../../common-componnets/job-offer-matches-list/job-offer-matches-list.module";
import { JobOfferMatchesOfferViewModule } from "../../../../../common-componnets/job-offer-mathces-offer-view/job-offer-matches-offer-view.module";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { CreateConversationDialogModule } from "../../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.module";
import { NzSelectModule } from "ng-zorro-antd/select";
import { ReactiveFormsModule } from "@angular/forms";
import { JobOfferTalentCommentsDialogModule } from "./job-offer-talent-comments-dialog/job-offer-talent-comments-dialog.module";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { RouterLink } from "@angular/router";

@NgModule({
  declarations: [MpJobOfferMatchesComponent],
  imports: [
    CommonModule,
    JobOfferMatchesListModule,
    JobOfferMatchesOfferViewModule,
    CreateConversationDialogModule,
    NzSpinModule,
    NzButtonModule,
    NzPageHeaderModule,
    NzWaveModule,
    TranslateModule,
    NzSelectModule,
    JobOfferTalentCommentsDialogModule,
    NzFormModule,
    NzGridModule,
    ReactiveFormsModule,
    NzIconModule,
    RouterLink,
  ],
})
export class MpJobOfferMatchesModule {}
