import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { map, Observable } from "rxjs";
import { ApiResponseDTO } from "../common.types";
import { CreateTestimonialRequest } from "./testimonials.types";
import { TestimonialDTO, TestimonialRequestDTO } from "./testimonials-api.types";

@Injectable({ providedIn: "root" })
export class TestimonialsApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  createTestimonialRequest(testimonialRequest: CreateTestimonialRequest): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/create`, testimonialRequest).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  loadTestimonials(): Observable<Array<TestimonialDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/reference/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  loadTestimonialsRequest(): Observable<Array<TestimonialRequestDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/reference/reference-request/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  deleteTestimonial(testimonialId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/reference/${testimonialId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  deleteTestimonialRequest(testimonialId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/reference/reference-request/${testimonialId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  resendTestimonialRequest(testimonialId: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/${testimonialId}/resend`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  confirmTestimonialRequest(referenceText: string, confirmed: boolean, authToken: string): Observable<boolean> {
    const headers = new HttpHeaders().set("authToken", authToken);

    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/confirm`, {referenceText, confirmed}, {headers}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  validationTestimonialRequest(token: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/validation`, {token}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
}
