import {Component, OnDestroy, OnInit} from "@angular/core";
import {LoginService} from "../login-page/login-page-data/login.service";
import {Router} from "@angular/router";
import {gridResponsiveMap, NzBreakpointEnum, NzBreakpointService} from "ng-zorro-antd/core/services";
import {Subject, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {LocaleService} from "@momhunting/core";
import {Title} from "@angular/platform-browser";

const DEFAULT_AUTHENTICATED_ROUTE = "/accounts/manage";

@Component({
  selector: "mh-account-deleted",
  templateUrl: "./account-deleted.component.html",
  styleUrls: ["./account-deleted.component.less"],
})

export class AccountDeletedComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  public isMdBreakpoint: boolean = false;

  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private breakpointService: NzBreakpointService,
    private readonly translateService: TranslateService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: 'Account deleted' }));
  }

  ngOnInit() {
    if(this.loginService.isAuthenticated()) {
      this.router.navigate([DEFAULT_AUTHENTICATED_ROUTE]);
    }

    this.breakpointService
      .subscribe(gridResponsiveMap)
      .pipe(takeUntil(this.destroy$))
      .subscribe(breakpoint => {
        this.isMdBreakpoint =
          breakpoint === NzBreakpointEnum.md ||
          breakpoint === NzBreakpointEnum.lg ||
          breakpoint === NzBreakpointEnum.xl ||
          breakpoint === NzBreakpointEnum.xxl;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
