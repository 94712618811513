import { Injectable } from "@angular/core";
import { ProfileService } from "../../api/profile/profile.service";
import { Observable } from "rxjs";
import {Education, Talent} from "../../api/sign-up/signup.types";

@Injectable({
  providedIn: "root",
})
export class ParsedCVResolver {
  constructor(private readonly profileService: ProfileService) {}

  resolve(): Observable<Talent> {
    return this.profileService.getTalentParsed();
  }
}
