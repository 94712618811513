import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { messagesFeature, MessagesState } from "../store/messages.reducer";
import { Observable } from "rxjs";
import { PaginatedContent } from "../../../api/common.types";
import { Conversation } from "../../../api/messages/messages.types";
import { MessagesAction } from "../store/messages.action";
import { isConversationListLoading, selectConversationList } from "../store/messages.selectors";
import { AccountFilter } from "../../../pages/home-admin/home-admin-data/account.types";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "mh-messages-inbox",
  templateUrl: "./messages-inbox.component.html",
  styleUrls: ["./messages-inbox.component.less"],
})
export class MessagesInboxComponent {
  @Input() tabActive = true;
  @Output() conversationClick = new EventEmitter<Conversation>();

  isConversationsLoading$: Observable<boolean>;
  conversationList$: Observable<PaginatedContent<Conversation>>;

  constructor(
    private readonly store: Store<{ [messagesFeature]: MessagesState }>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isConversationsLoading$ = this.store.select(isConversationListLoading);
    this.conversationList$ = this.store.select(selectConversationList);
  }

  onFilterChange(filter: AccountFilter) {
    if (!filter) {
      filter = {
        paging: {
          page: 0,
          itemsOnPage: 10
        }
      }
    }
    const tab = this.activatedRoute.snapshot.queryParams["tab"];
    this.store.dispatch(MessagesAction.loadConversationList({ filter, tab}));
  }

  onConversationClick(conversation: Conversation) {
    this.conversationClick.emit(conversation);
  }

  onConversationClose(conversation: Conversation) {
    this.store.dispatch(MessagesAction.closeConversation({ id: conversation.id }));
  }

  onConversationDelete(conversation: Conversation) {
    this.store.dispatch(MessagesAction.deleteConversation({ id: conversation.id }));
  }
}
