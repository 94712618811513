<div nz-col [nzSpan]="24" [class.loading]="loading">
  <nz-spin [nzSpinning]="loading">
    <div class="matches-list m-b-16">
      <div *ngFor="let talent of matches.items" class="matches-item" [class.matches-item--favourite]="talent.favourite">
        <div class="matches-item__header">
          <a
            (click)="onTalentClick($event, talent)"
            target="_blank"
            class="matches-item__header-col matches-item__avatar"
          >
            <nz-avatar class="mh-avatar" [nzSrc]="talent.avatarUrl" [nzSize]="84"></nz-avatar>
            <div class="matches-item__status">
              <ng-container
                *ngTemplateOutlet="
                  talent.status?.includes(MatchedTalentStatus.ARCHIVED)
                    ? statusArchived
                    : talent.status?.includes(MatchedTalentStatus.REJECTED)
                    ? statusRejected
                    : talent.status?.includes(MatchedTalentStatus.CLOSED)
                    ? statusClosed
                    : talent.status?.includes(MatchedTalentStatus.IN_COMMUNICATION)
                    ? statusInCommunication
                    : talent.status?.includes(MatchedTalentStatus.UNSEEN)
                    ? statusUnseen
                    : null
                "
              ></ng-container>
              <ng-template #statusUnseen>
                <button
                  nz-button
                  nzType="link"
                  nzSize="small"
                  [nzTooltipTitle]="'job-offer-matches.status.unseen' | translate"
                  nzTooltipPlacement="top"
                  nz-tooltip
                >
                  <span nz-icon nzType="eye-invisible" nzTheme="outline"></span>
                </button>
              </ng-template>

              <ng-template #statusInCommunication>
                <button
                  nz-button
                  nzType="link"
                  nzSize="small"
                  [nzTooltipTitle]="'job-offer-matches.status.in-communication' | translate"
                  nzTooltipPlacement="top"
                  nz-tooltip
                >
                  <span nz-icon nzType="message" nzTheme="outline"></span>
                </button>
              </ng-template>

              <ng-template #statusClosed>
                <button
                  nz-button
                  nzType="link"
                  nzSize="small"
                  [nzTooltipTitle]="'job-offer-matches.status.closed' | translate"
                  nzTooltipPlacement="top"
                  nz-tooltip
                >
                  <span nz-icon nzType="check" nzTheme="outline"></span>
                </button>
              </ng-template>

              <ng-template #statusRejected>
                <button
                  nz-button
                  nzType="link"
                  nzSize="small"
                  [nzTooltipTitle]="'job-offer-matches.status.rejected' | translate"
                  nzTooltipPlacement="top"
                  nz-tooltip
                >
                  <span nz-icon nzType="close" nzTheme="outline"></span>
                </button>
              </ng-template>

              <ng-template #statusArchived>
                <button
                  nz-button
                  nzType="link"
                  nzSize="small"
                  [nzTooltipTitle]="'archived.label' | translate"
                  nzTooltipPlacement="top"
                  nz-tooltip
                >
                  <span nz-icon nzType="stop" nzTheme="outline"></span>
                </button>
              </ng-template>
            </div>
          </a>
          <div class="matches-item__header-col">
            <div class="matches-item__name">
              <a (click)="onTalentClick($event, talent)" target="_blank">
                {{ talent.salutation }} {{ talent.firstName }} {{ talent.lastName }}
              </a>
            </div>
            <div *ngIf="talent.jobTitle" class="matches-item__job-title">
              {{ talent.jobTitle }}
            </div>
            <div *ngIf="isMobile">
              <ng-container *ngTemplateOutlet="salaryTemplate; context: { talent: talent }"></ng-container>
            </div>
            <div class="matches-item__info-row">
              <div *ngIf="talent.locationCity" class="matches-item__info-value">
                <div class="matches-item__info-value-body">
                  <span
                    *ngIf="talent.locationMatch"
                    nz-icon
                    [nzType]="'check-circle'"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#52c41a'"
                  ></span>
                  <div class="location">
                    <span *ngIf="talent.locationPostalCode" class="location__value">{{
                      talent.locationPostalCode
                    }}</span>
                    <span *ngIf="talent.locationCity" class="location__value">{{ talent.locationCity }}</span>
                    <span *ngIf="talent.radius" class="location__value">+{{ talent.radius.name | translate }} km</span>
                    <span *ngIf="talent.locationCountry" class="location__value">{{ talent.locationCountry }} </span>
                  </div>
                </div>
              </div>
              <div *ngIf="talent.workExperience !== null" class="matches-item__info-value nowrap">
                <div class="matches-item__info-value-body">
                  <span
                    *ngIf="talent.workExperienceMatch"
                    nz-icon
                    [nzType]="'check-circle'"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#52c41a'"
                  ></span>
                  {{ "job-offer-matches.list.work-experience.title" | translate }}
                  {{ talent.workExperience }}
                  {{ (talent.workExperience === 1 ? "year.label" : "years.label") | translate }}
                </div>
              </div>
              <div *ngIf="talent.leadershipExperience !== null" class="matches-item__info-value nowrap">
                <div class="matches-item__info-value-body">
                  <span
                    *ngIf="talent.leadershipExperienceMatch"
                    nz-icon
                    [nzType]="'check-circle'"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#52c41a'"
                  ></span>
                  {{ "job-offer-matches.list.leadership-experience.title" | translate }}
                  {{ talent.leadershipExperience }}
                  {{ (talent.leadershipExperience === 1 ? "year.label" : "years.label") | translate }}
                </div>
              </div>
              <div class="matches-item__info-value nowrap">
                <div class="matches-item__info-value-body">
                  {{ "job-offer-matches.detail.job-sharing.label" | translate }}
                  {{
                    (jobOffer.jobSharing
                      ? "job-offer-matches.detail.job-sharing.true"
                      : "job-offer-matches.detail.job-sharing.false"
                    ) | translate
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="matches-item__header-col matches-item__header-col--right">
            <div *ngIf="!isMobile">
              <ng-container *ngTemplateOutlet="salaryTemplate; context: { talent: talent }"></ng-container>
            </div>

            <ng-template #salaryTemplate let-talent="talent">
              <div class="matches-item__salary nowrap">
                <span
                  *ngIf="talent.salaryExpectationMatch"
                  class="matches-item__salary-check"
                  nz-icon
                  [nzType]="'check-circle'"
                  [nzTheme]="'twotone'"
                  [nzTwotoneColor]="'#52c41a'"
                ></span>
                <span class="matches-item__salary-value">
                  {{ talent.salaryExpectations?.min?.name }} - {{ talent.salaryExpectations?.max?.name }}
                </span>
              </div>
            </ng-template>

            <div class="matches-item__score">
              <span class="matches-item__score-label">
                {{ "job-offer-matches.list.score.title" | translate }}
              </span>
              <span class="matches-item__score-value">
                {{ talent?.score }}
              </span>
            </div>
          </div>
        </div>
        <div class="matches-item__body">
          <!-- mom skills -->
          <div class="matches-item__body-item">
            <div class="matches-item__label">
              {{ "profile.mom-skills.title" | translate }}
            </div>
            <div class="matches-item__value">
              <mh-tags-output [value]="talent.momSkills"></mh-tags-output>
            </div>
          </div>
          <!-- / mom skills -->
          <!-- domain-expertise -->
          <div class="matches-item__body-item">
            <div class="matches-item__label">
              <div>
                {{ "job-offer-matches.list.domain-expertise.title" | translate }}
              </div>
            </div>
            <div
              *ngIf="talent.domainExpertiseMatch?.length || talent.domainExpertiseOther?.length; else noValueSpecified"
              class="matches-item__value"
            >
              <mh-tags-output
                [value]="talent.domainExpertiseMatch"
                [placeholder]="''"
                [nzColor]="'#DAF4E1'"
              ></mh-tags-output>
              <mh-tags-output [value]="talent.domainExpertiseOther" [placeholder]="''"></mh-tags-output>
            </div>
          </div>
          <!-- / domain-expertise -->
          <!-- career level -->
          <div class="matches-item__body-item">
            <div class="matches-item__label">
              <div>
                {{ "profile.career-level.label" | translate }}
              </div>
            </div>
            <div *ngIf="talent.careerLevel; else noValueSpecified" class="matches-item__value">
              <nz-tag [nzColor]="talent.careerLevelMatch ? '#DAF4E1' : ''">
                {{ talent.careerLevel.name | translate }}
              </nz-tag>
            </div>
          </div>
          <!-- / career level -->
          <!-- industries -->
          <div class="matches-item__body-item">
            <div class="matches-item__label">
              <div>
                {{ "job-offer-matches.list.industries.title" | translate }}
              </div>
            </div>
            <div
              *ngIf="talent.industriesMatch?.length || talent.industriesOther?.length; else noValueSpecified"
              class="matches-item__value"
            >
              <mh-tags-output
                [value]="talent.industriesMatch"
                [placeholder]="''"
                [nzColor]="'#DAF4E1'"
              ></mh-tags-output>
              <mh-tags-output [value]="talent.industriesOther" [placeholder]="''"></mh-tags-output>
            </div>
          </div>
          <!-- / industries -->
          <!-- hashtags -->
          <div class="matches-item__body-item">
            <div class="matches-item__label">
              {{ "profile.hashtags.title" | translate }}
            </div>
            <div *ngIf="talent.hashTags?.length; else noValueSpecified" class="matches-item__value">
              <nz-tag *ngFor="let item of talent.hashTags">{{ item }}</nz-tag>
            </div>
          </div>
          <!-- / hashtags -->
          <!-- willingness to travel -->
          <div class="matches-item__body-item">
            <div class="matches-item__label">
              {{ "profile.travel-willingness.label" | translate }}
            </div>
            <div *ngIf="talent.willingnessToTravel !== null; else noValueSpecified" class="matches-item__value">
              {{ talent.willingnessToTravel }}
            </div>
          </div>
          <!-- / willingness to travel -->
          <!-- ideal working assignments -->
          <div class="matches-item__body-item">
            <div class="matches-item__label">
              {{ "profile.ideal-local-assignments.label" | translate }}
            </div>
            <div class="matches-item__value">
              <mh-tags-output [value]="talent.idealWorkingAssignments"></mh-tags-output>
            </div>
          </div>
          <!-- / ideal working assignments -->
        </div>

        <div class="matches-item__actions nowrap">
          <nz-select
            *ngIf="viewerAccountType === AccountType.ADMIN"
            nzShowSearch
            [nzPlaceHolder]="'admin.stage.placeholder' | translate"
            [ngModel]="talent.stage"
            (ngModelChange)="onSetTalentStage(talent, $event)"
            style="width: 200px"
          >
            <nz-option
              *ngFor="let stage of talentStages"
              [nzLabel]="stage.name | translate"
              [nzValue]="stage.value"
            ></nz-option>
          </nz-select>

          <button
            *ngIf="talent.favourite"
            nz-button
            nzSize="large"
            nzType="link"
            [nzTooltipTitle]="'job-offer-matches.favourite.tooltip' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            (click)="onSetTalentFavourite(talent, $event)"
          >
            <span nz-icon nzType="star" [nzTheme]="'fill'"></span>
          </button>
          <button
            *ngIf="!talent.favourite"
            nz-button
            nzSize="large"
            nzType="link"
            [nzTooltipTitle]="'job-offer-matches.favourite.tooltip' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            (click)="onSetTalentFavourite(talent, $event)"
          >
            <span nz-icon nzType="star" [nzTheme]="'outline'"></span>
          </button>

          <button
            *ngIf="!talent.status?.includes(MatchedTalentStatus.ARCHIVED)"
            nz-button
            nzSize="large"
            nzType="link"
            [nzTooltipTitle]="'job-offer-matches.action.archive' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            (click)="onArchive(talent, $event)"
          >
            <span nz-icon nzType="container" nzTheme="outline"></span>
          </button>
          <button
            *ngIf="talent.status?.includes(MatchedTalentStatus.ARCHIVED)"
            nz-button
            nzSize="large"
            nzType="link"
            [nzTooltipTitle]="'job-offer-matches.action.un-archive' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            (click)="onUnArchive(talent, $event)"
          >
            <span nz-icon nzType="container" nzTheme="outline"></span>
          </button>

          <button
            nz-button
            nzSize="large"
            nzType="link"
            [nzTooltipTitle]="'admin.comment.tooltip' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            (click)="onOpenCommentModal(talent, $event)"
          >
            <span
              nz-icon
              nzType="message"
              [nzTheme]="talent.hasComments ? 'fill' : 'outline'"
              [class.comment-icon--active]="talent.hasComments"
            ></span>
          </button>

          <ng-container *ngIf="viewerAccountType === AccountType.MISSION_PARTNER">
            <ng-container
              *ngTemplateOutlet="
                talent.status?.includes(MatchedTalentStatus.IN_COMMUNICATION) ? toConversation : startConversation
              "
            ></ng-container>
            <ng-template #toConversation>
              <button
                [disabled]="!hasReviewedStatus || !hasSelfServiceStatus"
                nz-button
                nzSize="large"
                nzType="link"
                [nzTooltipTitle]="'job-offer-matches.action.to-communication' | translate"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="onToConversation(talent, $event)"
              >
                <span nz-icon nzType="send" nzTheme="outline"></span>
              </button>
            </ng-template>

            <ng-template #startConversation>
              <button
                [disabled]="!hasReviewedStatus || !hasSelfServiceStatus"
                nz-button
                nzSize="large"
                nzType="link"
                [nzTooltipTitle]="'job-offer-matches.action.start-communication' | translate"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="onStartConversation(talent, $event)"
              >
                <span nz-icon nzType="send" nzTheme="outline"></span>
              </button>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="viewerAccountType === AccountType.ADMIN">
            <ng-container
              *ngTemplateOutlet="
                talent.status?.includes(MatchedTalentStatus.IN_COMMUNICATION) ? toConversation : startConversation
              "
            ></ng-container>
            <ng-template #toConversation>
              <button
                nz-button
                nzSize="large"
                nzType="link"
                [nzTooltipTitle]="'job-offer-matches.action.to-communication' | translate"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="onToConversationAsAdmin(talent, $event)"
              >
                <span nz-icon nzType="send" nzTheme="outline"></span>
              </button>
            </ng-template>

            <ng-template #startConversation>
              <button
                nz-button
                nzSize="large"
                nzType="link"
                [nzTooltipTitle]="'job-offer-matches.action.start-communication' | translate"
                nzTooltipPlacement="top"
                nz-tooltip
                (click)="onStartConversationAsAdmin(talent, $event)"
              >
                <span nz-icon nzType="send" nzTheme="outline"></span>
              </button>
            </ng-template>
          </ng-container>
        </div>
      </div>

      <nz-pagination
        class="matches-list__pagination"
        [nzTotal]="matches?.paging?.items || 0"
        [nzPageSize]="matches?.paging?.itemsOnPage || 0"
        [nzPageIndex]="matches?.paging?.page || 0"
        [nzShowTotal]="rangeTemplate"
        nzShowSizeChanger
        nzSize="small"
        (nzPageIndexChange)="onPageIndexChange($event)"
        (nzPageSizeChange)="onPageSizeChange($event)"
      ></nz-pagination>

      <nz-empty *ngIf="!matches.items?.length && !loading" nzNotFoundImage="simple"></nz-empty>
    </div>
  </nz-spin>
</div>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} {{ "of.label" | translate }} {{ total }} items
</ng-template>

<ng-template #noValueSpecified>-</ng-template>
