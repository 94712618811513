import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileExperienceComponent } from "./profile-experience.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { ReactiveFormsModule } from "@angular/forms";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";
import {TranslateModule} from "@ngx-translate/core";
import {ExplanationLabelModule} from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import {
  TrackRecordsInputModule
} from "../../../../../../common-componnets/track-record-input/track-records-input.module";
import {
  OldTrackRecordViewModule
} from "../../../../../../common-componnets/old-track-record-view/old-track-record-view.module";
import {NzIconModule} from "ng-zorro-antd/icon";
import {SuggestionsInputModule} from "../../../../../../../../../common-ui/src/lib/suggestions-input/suggestions-input.module";

@NgModule({
  declarations: [ProfileExperienceComponent],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzDividerModule,
        NzDatePickerModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        NzSelectModule,
        ReactiveFormsModule,
        NzSliderModule,
        NzButtonModule,
        NzWaveModule,
        NzInputNumberModule,
        TranslateModule,
        ExplanationLabelModule,
        TrackRecordsInputModule,
        OldTrackRecordViewModule,
        NzIconModule,
        SuggestionsInputModule,
    ],
  exports: [ProfileExperienceComponent],
})
export class ProfileExperienceModule {}
