import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { map, Observable } from "rxjs";
import { ApiResponseDTO } from "../common.types";
import { CreateReferenceRequest } from "./references.types";
import { ReferenceDTO, ReferenceRequestDTO } from "./references-api.types";

@Injectable({ providedIn: "root" })
export class ReferencesApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  createReferenceRequest(referenceRequest: CreateReferenceRequest): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/create`, referenceRequest).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  loadReferences(): Observable<Array<ReferenceDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/reference/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  loadReferencesRequest(): Observable<Array<ReferenceRequestDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/reference/reference-request/my`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  deleteReference(referenceId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/reference/${referenceId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  deleteReferenceRequest(referenceId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/reference/reference-request/${referenceId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  resendReferenceRequest(referenceId: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/${referenceId}/resend`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  confirmReferenceRequest(referenceText: string, confirmed: boolean, authToken: string): Observable<boolean> {
    const headers = new HttpHeaders().set("authToken", authToken);

    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/confirm`, {referenceText, confirmed}, {headers}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  validationReferenceRequest(token: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/reference/reference-request/validation`, {token}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
}
