import {FileSubType, MessagingAttachment, MessagingMessage} from "../../api/messages/messages.types";

export const CONVERSATION_FILE_UPLOADED_MESSAGE = "conversation.file.uploaded.text";
export const CONVERSATION_VIDEO_UPLOADED_MESSAGE = "conversation.video.uploaded.text";
export const CONVERSATION_AUDIO_UPLOADED_MESSAGE = "conversation.audio.uploaded.text";
export const CONVERSATION_IMAGE_UPLOADED_MESSAGE = "conversation.image.uploaded.text";

export function generateMessagePreview(message: MessagingMessage) {
  if (message.attachment) {
    return generateAttachmentTextPreview(message.attachment);
  } else {
    return truncate(message.messageText, 200);
  }
}

export function truncate(source: string, size: number): string {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

export function generateAttachmentTextPreview(attachment: MessagingAttachment) {
  let messageText = "";
  if (attachment != null) {
    if (!attachment.subType) {
      messageText = CONVERSATION_FILE_UPLOADED_MESSAGE;
    } else {
      switch (attachment.subType) {
        case FileSubType.VIDEO:
          messageText = CONVERSATION_VIDEO_UPLOADED_MESSAGE;
          break;
        case FileSubType.IMAGE:
          messageText = CONVERSATION_IMAGE_UPLOADED_MESSAGE;
          break;
        case FileSubType.AUDIO:
          messageText = CONVERSATION_AUDIO_UPLOADED_MESSAGE;
          break;
      }
    }
  }
  return messageText;
}
