import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeMissionPartnerComponent } from "./home-mission-partner.component";
import { MissionPartnerDashboardComponent } from "./subpages/mission-partner-dashboard/mission-partner-dashboard.component";
import { MissionPartnerAssessmentComponent } from "./subpages/mission-partner-assessment/mission-partner-assessment.component";
import { MissionPartnerMicroSiteComponent } from "./subpages/mission-partner-micro-site/mission-partner-micro-site.component";
import { MissionPartnerInvoicesComponent } from "./subpages/mission-partner-invoices/mission-partner-invoices.component";
import { MissionPartnerSettingsComponent } from "./subpages/mission-partner-settings/mission-partner-settings.component";
import { MissionPartnerNotificationsComponent } from "./subpages/mission-partner-notifications/mission-partner-notifications.component";
import { MissionPartnerJobOffersModule } from "./subpages/mission-partner-job-offers/mission-partner-job-offers.module";
import { MissionPartnerViewTalentComponent } from "./subpages/mission-partner-view-talent/mission-partner-view-talent.component";
import { LoginDataResolver } from "../login-page/login-page-data/login-data.resolver";
import { MissionPartnerProfileResolver } from "../../api/profile/mission-partner-profile.resolver";
import { MissionPartnerInboxModule } from "./subpages/mission-partner-inbox/mission-partner-inbox.module";
import { MissionPartnerProfileComponent } from "./subpages/mission-partner-profile/mission-partner-profile.component";
import { JobOffersInfoResolver } from "../../api/dashboard/job-offers-info.resolver";
import { NotificationSettingsResolver } from "../../api/notification/notification-settings.resolver";
import { TalentLastRepliesResolver } from "../../api/dashboard/talent-last-replies.resolver";
import { ProactiveContactAttemptsResolver } from "../../api/dashboard/proactive-contact-attempts.resolver";
import { InvoicesInfoResolver } from "../../api/dashboard/invoices-info.resolver";
import { MissionPartnerTestimonialsComponent } from "./subpages/mission-partner-testimonials/mission-partner-testimonials.component";
import { TestimonialsResolver } from "../../api/testimonials/testimonials.resolver";
import { TestimonialsRequestResolver } from "../../api/testimonials/testimonials-request.resolver";
import { UserNewsletterSettingResolver } from "../../api/user/user-newsletter-setting.resolver";
import { MissionPartnerAssessmentResolver } from "../../api/assessment/mission-partner-assessment.resolver";
import { MissionPartnerMyVisitsComponent } from "./subpages/mission-partner-my-visits/mission-partner-my-visits.component";
import { MissionPartnerProfileCompletenessResolver } from "../../api/dashboard/mission-partner-profile-completenesss.resolver";
import { HiringsConversationResolver } from "../../api/dashboard/hirings-conversations";

const routes: Routes = [
  {
    path: "",
    component: HomeMissionPartnerComponent,
    children: [
      {
        path: "dashboard",
        component: MissionPartnerDashboardComponent,
        resolve: {
          jobOffers: JobOffersInfoResolver,
          talentLastReplies: TalentLastRepliesResolver,
          proactiveContactAttempts: ProactiveContactAttemptsResolver,
          hiringConversation: HiringsConversationResolver,
          invoicesInfo: InvoicesInfoResolver,
          notificationsSettings: NotificationSettingsResolver,
          profileCompleteness: MissionPartnerProfileCompletenessResolver,
        },
      },
      {
        path: "job-offers",
        loadChildren: () => MissionPartnerJobOffersModule,
      },
      {
        path: "inbox",
        loadChildren: () => MissionPartnerInboxModule,
      },
      {
        path: "profile",
        component: MissionPartnerProfileComponent,
        resolve: {
          profile: MissionPartnerProfileResolver,
        },
      },
      {
        path: "assessment",
        component: MissionPartnerAssessmentComponent,
        resolve: {
          assessment: MissionPartnerAssessmentResolver,
        },
      },
      {
        path: "micro-site",
        component: MissionPartnerMicroSiteComponent,
        resolve: {
          profile: MissionPartnerProfileResolver,
          testimonials: TestimonialsResolver,
        },
      },
      {
        path: "invoices",
        component: MissionPartnerInvoicesComponent,
      },
      {
        path: "testimonials",
        component: MissionPartnerTestimonialsComponent,
        resolve: {
          testimonials: TestimonialsResolver,
          testimonialsRequest: TestimonialsRequestResolver,
        },
      },
      {
        path: "visits",
        component: MissionPartnerMyVisitsComponent,
      },
      {
        path: "settings",
        component: MissionPartnerSettingsComponent,
        resolve: {
          loginData: LoginDataResolver,
          profile: MissionPartnerProfileResolver,
          newsletterEnabled: UserNewsletterSettingResolver,
        },
      },
      {
        path: "notifications",
        component: MissionPartnerNotificationsComponent,
        resolve: {
          notificationsSettings: NotificationSettingsResolver,
        },
      },
      {
        path: "view-talent/:id",
        component: MissionPartnerViewTalentComponent,
      },
      {
        path: "**",
        redirectTo: "dashboard",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeMissionPartnerRoutingModule {}
