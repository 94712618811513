import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { catchError, map, Observable } from "rxjs";
import { ApiPaginatedResponseDTO, ApiResponseDTO, initialPaginatedContent, PaginatedContent } from "../common.types";
import { InvoiceDTO } from "./invoices.types";
import { AccountFilterDTO } from "../../pages/home-admin/home-admin-data/account.types";
import { DocumentFileDTO } from "../document/document.types";

@Injectable({ providedIn: "root" })
export class InvoicesApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadInvoiceList(filter: AccountFilterDTO): Observable<PaginatedContent<InvoiceDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<InvoiceDTO>>(`${this.basePath}/admin/invoice/list`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadInvoiceListMy(): Observable<Array<InvoiceDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/invoice/my/list`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return response;
        }
      }),
    );
  }

  changeInvoiceStatus(invoiceId: string, status: string): Observable<boolean> {
    return this.httpClient.put<ApiResponseDTO>(`${this.basePath}/invoice/${invoiceId}/change-status/${status}`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  createInvoice(invoice: InvoiceDTO) {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/admin/invoice/create`, invoice).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  deleteInvoice(id: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/admin/invoice/${id}`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  uploadInvoiceFile(missionPartnerId: string, formData: FormData): Observable<DocumentFileDTO> {
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data");
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/admin/invoice/mission-partner/${missionPartnerId}/invoice/upload`, formData, {
        headers,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }
}
