import { Injectable } from "@angular/core";
import {AccountDataSpace, AccountDataSpaceDTO, DataSpaceAttribute, DataSpaceAttributeDTO} from "./dataspace.types";

@Injectable({
  providedIn: "root",
})
export class DataSpaceSerializer {
  constructor() {}

  deserializeDataSpaces(dtos: Array<AccountDataSpaceDTO>): Array<AccountDataSpace> {
    return dtos.map((dto) => this.deserializeDataSpace(dto));
  }

  deserializeDataSpace(dto: AccountDataSpaceDTO): AccountDataSpace {
    return {
      ...dto,
      attributes: this.deserializeAttributes(Object.values(dto.attributes))
    };
  }

  deserializeAttributes(dtos: Array<DataSpaceAttributeDTO>): Array<DataSpaceAttribute> {
    return dtos.map((dto) => this.deserializeAttribute(dto));
  }

  deserializeAttribute(dto: DataSpaceAttributeDTO): DataSpaceAttribute {
    return {
      ...dto,
    };
  }
}
