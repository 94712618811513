import { Component, OnInit } from "@angular/core";
import { Conversation } from "../../../../api/messages/messages.types";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  messagesFeature,
  MessagesState,
} from "libs/platform-pages/src/lib/common-componnets/messages/store/messages.reducer";
import { MessagesAction } from "libs/platform-pages/src/lib/common-componnets/messages/store/messages.action";

@Component({
  selector: "mh-mission-partner-inbox",
  templateUrl: "./mission-partner-inbox.component.html",
  styleUrls: ["./mission-partner-inbox.component.less"],
})
export class MissionPartnerInboxComponent implements OnInit {
  tab?: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly store: Store<{ [messagesFeature]: MessagesState }>,
  ) {}

  ngOnInit() {
   this.initTab();
  }

  initTab() {
    this.tab = this.activatedRoute.snapshot.queryParams["tab"];
    if (!this.tab) {
      this.router.navigate([], {
        queryParams: { tab: "messages" },
        queryParamsHandling: "merge",
      });
      this.tab = "messages";
    }
  }

  onConversationClick(conversation: Conversation, tab: string) {
    this.router.navigate(["/mission-partner", "inbox", "conversation", conversation.id], {
      queryParams: {
        origin: "inbox",
        tab,
      },
    });
  }

  onTabSelect(tab: string) {
    const filter = {
      paging: {
        page: 1,
        itemsOnPage: 10,
      },
      searchTerm: "",
      filters: [],
      groupFilters: [],
    };
    this.store.dispatch(MessagesAction.loadConversationList({ filter, tab }));
  }
}
