<nz-page-header [nzTitle]="'profile.about.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" [nzAutoTips]="autoTips" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" class="col-avatar">
      <mh-avatar-upload [avatarUrl]="avatarUrl"></mh-avatar-upload>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.personal-info.label'" [divider]="true"></mh-explanation-label>
    </div>

    <div nz-col [nzSpan]="24">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24" [nzOrder]="1" [nzSm]="{ span: 4, order: 0 }">
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" class="p-lr-0">
            <mh-explanation-label [labelKey]="'profile.salutation.label'"></mh-explanation-label>
          </nz-form-label>
        </div>

        <div nz-col [nzSpan]="24" [nzOrder]="3" [nzSm]="{ span: 10, order: 0 }">
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" class="p-lr-0" nzRequired>
            <mh-explanation-label [labelKey]="'profile.firstname.label'"></mh-explanation-label>
          </nz-form-label>
        </div>

        <div nz-col [nzSpan]="24" [nzOrder]="5" [nzSm]="{ span: 10, order: 0 }">
          <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" class="p-lr-0" nzRequired>
            <mh-explanation-label [labelKey]="'profile.lastname.label'"></mh-explanation-label>
          </nz-form-label>
        </div>

        <div nz-col [nzSpan]="24" [nzOrder]="2" [nzSm]="{ span: 4, order: 0 }">
          <nz-form-item>
            <nz-form-control [nzSpan]="24">
              <input nz-input [placeholder]="'profile.salutation.placeholder' | translate"
                     formControlName="salutation"/>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="24" [nzOrder]="4" [nzSm]="{ span: 10, order: 0 }">
          <nz-form-item>
            <nz-form-control [nzSpan]="24">
              <input nz-input [placeholder]="'profile.firstname.placeholder' | translate"
                     formControlName="firstName"/>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="24" [nzOrder]="6" [nzSm]="{ span: 10, order: 0 }">
          <nz-form-item>
            <nz-form-control [nzSpan]="24">
              <input nz-input [placeholder]="'profile.lastname.placeholder' | translate"
                     formControlName="lastName"/>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" class="p-lr-0" nzRequired>
        <mh-explanation-label [labelKey]="'profile.profile-link.label'"></mh-explanation-label>
      </nz-form-label>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" class="p-lr-0">
        <mh-explanation-label [labelKey]="'profile.birth-date.label'"></mh-explanation-label>
      </nz-form-label>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="urlErrorTpl">
          <input nz-input [placeholder]="'profile.profile-link.placeholder' | translate"
                 formControlName="profileLink"/>
        </nz-form-control>
        <ng-template #urlErrorTpl let-control>
          <ng-container *ngIf="control.hasError('url')">{{'field.invalid-url.label' | translate}}</ng-container>
        </ng-template>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-control>
          <nz-date-picker
            formControlName="birthDate"
            [nzDisabledDate]="disabledDate"
            [nzFormat]="'dd.MM.yyyy'"
            [nzPlaceHolder]="'profile.birth-date.placeholder' | translate"
            [nzDefaultPickerValue]="defaultPickerValue"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <!--      <nz-divider nzPlain [nzText]="'profile.contact-data.label' | translate" nzOrientation="left"></nz-divider>-->
      <nz-form-item>
        <nz-form-control nzDisableAutoTips>
          <mh-location-input
            [showLabels]="true"
            formControlName="contactData"
            [validators]="formGroup.get('contactData')?.validator"
            [mark]="formGroup.get('contactData')?.dirty || false"
          ></mh-location-input>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.key-summary.label'" [divider]="true"></mh-explanation-label>
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'talent.profile.keySummary.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <textarea rows="4" formControlName="keySummary" nz-input></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'profile.reason-why.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control [nzErrorTip]="reasonWhyErrorTpl">
          <nz-radio-group formControlName="reasonWhy">
            <label nz-radio *ngFor="let option of (reasonWhy$ | async)"
                   [nzValue]="option"
                   [ngModel]="option.value == reasonWhyControl?.value?.value"
                   [ngModelOptions]="{standalone: true}"
            >
              <span [innerHTML]="option.name | translate"></span>
            </label>
          </nz-radio-group>
        </nz-form-control>
        <ng-template #reasonWhyErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">{{'field.required.label' | translate}}</ng-container>
        </ng-template>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24">
      <!--<nz-divider nzPlain [nzText]="'profile.hashtags.title' | translate" nzOrientation="left"></nz-divider>-->
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.hashtags.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="tags"
            [nzMaxMultipleCount]="4"
            [nzCustomTemplate]="defaultTemplate"
            [nzNotFoundContent]="notFoundTemplate"
            (keydown)="onKeydown($event)"
            (paste)="onPaste($event)"
            (ngModelChange)="onHashTagsChange($event)"
            formControlName="hashTags">
          </nz-select>
          <ng-template #defaultTemplate let-selected>
            <span>#</span>{{ selected.nzLabel }}&nbsp;
          </ng-template>
          <ng-template #notFoundTemplate>
            <div class="hashtags-description">
              {{'profile.hashtags.desctiption' | translate}}
            </div>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-col [nzSpan]="24">
    <mh-explanation-label [labelKey]="'profile.cv-upload.label'" [divider]="true"></mh-explanation-label>
    <mh-profile-documents-upload [uploadCV]="true" [fileList]="talentCvList" [mobileView]="mobileView" (fileListChange)="updateFileList($event, true)"></mh-profile-documents-upload>
  </div>

  <div nz-col [nzSpan]="24">
    <mh-explanation-label [labelKey]="'profile.documents.label'" [divider]="true"></mh-explanation-label>
    <mh-profile-documents-upload [fileList]="userDocuments" [mobileView]="mobileView" (fileListChange)="updateFileList($event)"></mh-profile-documents-upload>
  </div>

  <div nz-col [nzSpan]="24">
    <mh-explanation-label [labelKey]="'profile.audio.label'" [divider]="true"></mh-explanation-label>
    <mh-profile-audio-upload [profileAudio]="talent.profileAudio" (profileAudioChange)="updateProfileAudio($event)"></mh-profile-audio-upload>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <p class="required-explanation">{{'reg.required.explanation' | translate}}</p>
    </div>

    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save-and-next.button' | translate}}</button>
    </div>
  </div>
</form>
