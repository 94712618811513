import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidatorFn } from "@angular/forms";
import { BehaviorSubject, debounceTime, Observable, of, Subject, switchMap } from "rxjs";
import { ProfileService } from "../../../../platform-pages/src/lib/api/profile/profile.service";

@Component({
  selector: "mh-suggestions-input",
  templateUrl: "./suggestions-input.component.html",
  styleUrls: ["./suggestions-input.component.less"],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SuggestionsInputComponent), multi: true }],
})
export class SuggestionsInputComponent implements ControlValueAccessor, OnDestroy, OnInit {
  private value!: Array<string>;
  @Input() validators: ValidatorFn | null | undefined;
  @Input() fieldName!: string;

  private destroy$ = new Subject<void>();
  separatorKeyCodes: number[] = [ENTER, COMMA];
  suggestionCtrl = new FormControl("");
  suggestions: string[] = [];
  searchChange$ = new BehaviorSubject("");
  optionList: string[] = [];
  isLoading = false;

  @ViewChild("suggestionInput") suggestionInput!: ElementRef<HTMLInputElement>;

  constructor(private profileService: ProfileService) {}

  @Input() set mark(isTouched: boolean) {
    if (isTouched) {
      this.suggestionCtrl.markAsTouched();
    }
  }

  ngOnInit() {
    if (this.validators) {
      this.suggestionCtrl.setValidators(this.validators);
    }

    const loadFieldSuggestions = (name: string): Observable<any> => {
      if (name.trim().length > 2) {
        return this.profileService.loadFieldSuggestions(name.trim(), this.fieldName);
      }
      return of([]);
    }

    const optionList$: Observable<string[]> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(250))
      .pipe(switchMap(loadFieldSuggestions));
    optionList$.subscribe((data) => {
      data = data.filter(val=> !this.suggestions.includes(val));
      this.optionList = [...data];
      this.isLoading = false;
    });
  }

  onSearch(value: string): void {
    this.isLoading = true;
    this.searchChange$.next(value);
  }

  handleInputConfirm(): void {
    if (this.suggestionCtrl.value) this.suggestionCtrl.setValue(this.suggestionCtrl.value.trim());
    if (this.suggestionCtrl.value && !this.value.find(val => this.suggestionCtrl.value?.toLowerCase() == val.toLowerCase())) {
      const foundOption = this.optionList.find(option=> option.toLowerCase() == this.suggestionCtrl.value?.toLowerCase());
      const suggestionToAdd = foundOption || this.suggestionCtrl.value;
      this.suggestions = [...this.suggestions, suggestionToAdd];
      this.updateValues();
    }
    this.suggestionCtrl.setValue('');
  }
  onAutoCompleteSelect(selected: any) {
    setTimeout(()=>this.suggestionInput.nativeElement.blur(),0);
  }

  onSelectClick() {
    setTimeout(()=>this.suggestionInput.nativeElement.focus(),0);
  }

  remove(fruit: string): void {
    this.suggestions = this.suggestions.filter((suggestion) => suggestion !== fruit);
    this.updateValues();
  }

  private updateValues() {
    this.suggestions = [...new Set(this.suggestions)];
    this.value = this.suggestions;

    this.notifyOfChange();
  }

  private notifyOfChange() {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  onChange = (value: Array<string>) => {};
  onTouched = () => {};

  writeValue(value: Array<string>) {
    this.value = value;
    this.suggestions = [...value];
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  ngOnDestroy() {
    this.destroy$.next();
  }
}
