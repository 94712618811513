import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "libs/platform-pages/src/lib/api/notification/notification.service";
import { MhNotificationSettings } from "libs/platform-pages/src/lib/api/notification/notification.types";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { take } from "rxjs";

@Component({
  selector: "mh-talent-dashboard-notification-settings",
  templateUrl: "./talent-dashboard-notification-settings.component.html",
  styleUrls: ["./talent-dashboard-notification-settings.component.less"],
})
export class TalentDashboardNotificationSettingsComponent {
  @Input() notificationsSettings!: MhNotificationSettings;

  constructor(
    private mhNotificationService: NotificationService,
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService,
  ) {}

  updateNotificationSettings() {
    this.mhNotificationService
      .saveNotificationSettings(this.notificationsSettings)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.nzNotificationService.success("", this.translateService.instant("notification-settings.update.success"));
        }
      });
  }
}
