<nz-upload
  [nzDisabled]="uploadDisabled"
  [nzType]="mobileView?'select':'drag'"
  [nzAction]="actionUrl"
  nzListType="text"
  [(nzFileList)]="fileList"
  (nzChange)="handleChange($event)"
  [nzRemove]="handleRemove">
  <ng-container *ngIf="!uploadDisabled">
    <ng-container *ngIf="!mobileView">
      <p class="ant-upload-drag-icon">
        <span nz-icon nzType="inbox"></span>
      </p>
      <p class="ant-upload-text">{{'upload-drop-area.label' | translate}}</p>
    </ng-container>
    <ng-container *ngIf="mobileView">
        <button nz-button nzType="primary">Upload</button>
    </ng-container>
  </ng-container>
</nz-upload>
