import { Component } from "@angular/core";
import { MhNotificationSettings } from "../../../../api/notification/notification.types";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "mh-mission-partner-notifications",
  templateUrl: "./mission-partner-notifications.component.html",
  styleUrls: ["./mission-partner-notifications.component.less"],
})
export class MissionPartnerNotificationsComponent {
  notificationsSettings: MhNotificationSettings;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.notificationsSettings = this.activatedRoute.snapshot.data["notificationsSettings"];
  }
}

