<nz-card class="dashboard__card" [nzTitle]="'notification-settings.title' | translate" [nzActions]="[actionComplete]">
  <form nz-form nzLayout="vertical">
    <nz-form-label>{{ "notification-settings.new-message.title" | translate }}</nz-form-label>
    <div *ngFor="let item of notificationsSettings?.email">
      <label nz-checkbox [(ngModel)]="item.enabled" name="email">{{'notification-settings.email.label' | translate}}</label>
    </div>
    <div *ngFor="let item of notificationsSettings?.sms">
      <label nz-checkbox [(ngModel)]="item.enabled" name="sms">{{'notification-settings.sms.label' | translate}}</label>
    </div>
  </form>
</nz-card>

<ng-template #actionComplete>
  <button nz-button nzType="primary" (click)="updateNotificationSettings()">{{'save.button' | translate}}</button>
</ng-template>
