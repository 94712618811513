import { Injectable } from "@angular/core";
import {
  JobOffer,
  JobOfferDTO,
  JobOfferLocationDTO, JobOfferTalentComment,
  JobOfferTalentCommentDTO,
  MatchedTalent,
  MatchedTalentDTO
} from "./job-offer.types";
import { FilterRangeDTO } from "../../pages/home-admin/home-admin-data/account.types";
import { SimpleLocation } from "../google-geocoding/google-geocoding.types";
import { CmsNameValue } from "../cms/cms.types";
import { format } from "date-fns";
import { CommonService } from "../common.service";

@Injectable({
  providedIn: "root",
})
export class JobOfferSerializer {
  constructor(private commonService: CommonService) {}

  deserializeJobOffers(dtos: Array<JobOfferDTO>): Array<JobOffer> {
    return dtos.map((dto) => this.deserializeJobOffer(dto));
  }

  deserializeJobOffer(dto: JobOfferDTO): JobOffer {
    return {
      ...dto,
      missionPartnerId: dto.missionPartnerId?.internalId,
      forMissionPartnerId: dto.forMissionPartnerId?.internalId,
      salaryExpectations: {
        min: this.salaryToCmsValue(dto.salaryMin),
        max: this.salaryToCmsValue(dto.salaryMax),
      },
      locations: dto.locations?.map((loc) => this.deserializeJOLocation(loc)),
      leadershipTeamSize: !dto.leadershipTeamSize ? undefined : dto.leadershipTeamSize
    };
  }

  deserializeRange(range: FilterRangeDTO): Array<Date> {
    return [
      (new Date(range.start)),
      (new Date(range.end))
    ]
  }

  deserializeJOLocation(dto: JobOfferLocationDTO): SimpleLocation  {
    return {
      ...dto,
    }
  }

  serializeJobOffer(jobOffer: JobOffer): JobOfferDTO {
    return {
      ...jobOffer,
      missionPartnerId: {internalId: jobOffer.missionPartnerId},
      forMissionPartnerId: jobOffer.forMissionPartnerId ? {internalId: jobOffer.forMissionPartnerId} : undefined,
      salaryMin: jobOffer.salaryExpectations?.min?.value,
      salaryMax: jobOffer.salaryExpectations?.max?.value,
      locations: jobOffer.locations.map((loc) => this.serializeJOLocation(loc)),
    };
  }

  serializeJOLocation(location: SimpleLocation): JobOfferLocationDTO {
    return {
      postalCode: location.postalCode || '',
      city: location.city || '',
      country: location.country || '',
      radius: location.radius
    }
  }


  deserializeMatchedTalents(dtos: Array<MatchedTalentDTO>): Array<MatchedTalent> {
    return dtos.map((dto) => this.deserializeMatchedTalent(dto));
  }

  deserializeMatchedTalent(dto: MatchedTalentDTO): MatchedTalent {
    return {
      ...dto,
      accountId: dto.accountId?.internalId,
      avatarUrl: this.commonService.toAbsUrl(dto.avatar?.avatarUrl),
      salaryExpectations: {
        min: this.salaryToCmsValue(dto.salaryExpectationMin),
        max: this.salaryToCmsValue(dto.salaryExpectationMax),
      },
      domainExpertiseOther: dto.domainExpertise.filter((expertise) => {
        return !dto.domainExpertiseMatch.some((match) => match.value === expertise.value);
      }),
      industriesOther: dto.industries.filter((industry) => {
        return !dto.industriesMatch.some((match) => match.value === industry.value);
      }),
    };
  }

  private salaryToCmsValue(salary?: number): CmsNameValue | undefined {
    if(!salary) return undefined;
    return { value: salary, name: salary + "K" };
  }

  deserializeComments(dtos: Array<JobOfferTalentCommentDTO>): Array<JobOfferTalentComment> {
    return dtos.map((dto) => this.deserializeComment(dto));
  }

  deserializeComment(dto: JobOfferTalentCommentDTO): JobOfferTalentComment {
    return {
      ...dto,
      creationTimestamp: format(new Date(dto.creationTimestamp), 'yyyy-MM-dd HH:mm:ss'),
    };
  }
}
