<form class="admin-filters" nz-form [formGroup]="searchForm" (ngSubmit)="submitFilterForm()">
  <div class="admin-filters__fields">
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input type="text" formControlName="searchTerm" nz-input [placeholder]="'admin.filter.search.placeholder' | translate" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span *ngIf="!searchForm.get('searchTerm')?.value" nz-icon nzType="search"></span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchForm.get('searchTerm')?.value"
            (click)="searchForm.get('searchTerm')?.setValue(null)"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="admin-filters__actions">
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" [disabled]="!searchForm.valid">{{'admin.filter.submit' | translate}}</button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="activeConversations?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="activeConversations?.paging?.items || 0"
  [nzPageSize]="activeConversations?.paging?.itemsOnPage || 0"
  [nzPageIndex]="activeConversations?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th nzColumnKey="MP_COMPANY_NAME" [nzSortFn]="true">{{'admin.mp-company-name.title' | translate}}</th>
    <th nzColumnKey="TALENT_NAME" [nzSortFn]="true">{{'admin.talent-name.title' | translate}}</th>
    <th nzColumnKey="CREATION_TIMESTAMP" [nzSortFn]="true">{{'admin.conversation-creation-date.title' | translate}}</th>
    <th nzColumnKey="STARTED_BY" [nzSortFn]="true">{{'admin.conversation-started-by.title' | translate}}</th>
    <th>{{'admin.last-message-sent-by.title' | translate}}</th>
    <th nzColumnKey="LAST_MESSAGE_TIMESTAMP" [nzSortFn]="true" nzSortOrder="descend">{{'admin.since-last-message.title' | translate}}</th>
    <th nzColumnKey="PROFILE_OPENED" [nzSortFn]="true">{{'admin.profile-status.title' | translate}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of basicTable.data">
    <td>
      <a [routerLink]="['/admin/mission-partner-view', data.missionPartnerId.internalId]" target="_blank">
        {{ data.missionPartnerCompanyName }}
      </a>
    </td>
    <td>
      <a [routerLink]="['/admin/talent-view', data.talentId.internalId]" target="_blank">
        {{ data.talentName }}
      </a>
    </td>
    <td>{{ data.creationTimestamp | date : "yyyy-MM-dd HH:mm:ss" }}</td>
    <td>
      <ng-container *ngIf="data.startedBy === AccountType.TALENT">
        {{ 'admin.account-type.talent' | translate}}
      </ng-container>
      <ng-container *ngIf="data.startedBy === AccountType.MISSION_PARTNER">
        {{ 'admin.account-type.mission-partner' | translate}}
      </ng-container>
    </td>
    <td>
      <ng-container *ngIf="data.lastMessageBy === AccountType.TALENT">
        {{ 'admin.account-type.talent' | translate}}
      </ng-container>
      <ng-container *ngIf="data.lastMessageBy === AccountType.MISSION_PARTNER">
        {{ 'admin.account-type.mission-partner' | translate}}
      </ng-container>
    </td>
    <td>
      <span
        nz-tooltip
        [nzTooltipTitle]="data.lastMessageTimestamp | date : 'yyyy-MM-dd HH:mm:ss'"
      >
        {{ data.lastMessageDistanceToNow }}
      </span>
    </td>
    <td>
      {{data.profileOpened}}
    </td>
  </tr>
  </tbody>
</nz-table>
