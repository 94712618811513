import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminTalentDataSpacesComponent } from "./admin-talent-data-spaces.component";
import {NzCardModule} from "ng-zorro-antd/card";
import {TranslateModule} from "@ngx-translate/core";
import {NzFormModule} from "ng-zorro-antd/form";
import {DataSpaceAttributeViewModule} from "./data-space-attribute-view/data-space-attribute-view.module";
import {NzIconModule} from "ng-zorro-antd/icon";

@NgModule({
  declarations: [AdminTalentDataSpacesComponent],
  imports: [CommonModule, NzCardModule, TranslateModule, NzFormModule, DataSpaceAttributeViewModule, NzIconModule],
  exports: [
    AdminTalentDataSpacesComponent
  ]
})
export class AdminTalentDataspacesModule {}
