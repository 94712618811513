import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {MAAnswerOption, MAQuestion} from "../../../../api/assessment/assessment.types";

@Component({
  selector: "mh-assessment-navigator-question-wrapper",
  templateUrl: "./assessment-navigator-question-wrapper.component.html",
  styleUrls: ["./assessment-navigator-question-wrapper.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentNavigatorQuestionWrapperComponent implements OnChanges {
  @Input() question!: MAQuestion;
  @Output() answered = new EventEmitter<Array<MAAnswerOption>>();

  linkedQuestions: Array<MAQuestion> = [];

  ngOnChanges(changes: SimpleChanges) {
    if(changes['question']) {
      this.linkedQuestions = this.getLinkedQuestions(this.question);
    }
  }

  onAnswer(answers: Array<MAAnswerOption>) {
    this.linkedQuestions = this.getLinkedQuestions(this.question)
    this.answered.emit(answers);
  }

  getLinkedQuestions(question: MAQuestion): Array<MAQuestion> {
    let linkedQuestions: Array<MAQuestion> = [];
    question.answerOptions.forEach(answer => {
      if(answer.selected && answer.linkedQuestions?.length) {
        linkedQuestions = [...linkedQuestions, ...answer.linkedQuestions];
      }
    });
    return linkedQuestions;
  }

}
