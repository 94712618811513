import { Injectable } from "@angular/core";
import {
  MissionPartnerProfileCompleteness,
  MissionPartnerProfileCompletenessDTO,
  TalentProfileCompleteness,
  TalentProfileCompletenessDTO,
} from "./dashboard.types";

@Injectable({
  providedIn: "root",
})
export class CompletenessSerializer {
  deserializeTalent(dto: TalentProfileCompletenessDTO): TalentProfileCompleteness {
    return {
      ...dto.data,
      dictionary: Object.fromEntries(dto.data.sections.map((section) => [section.section, section])),
      isProfileFilledOut: dto.isProfileFilledOut,
    };
  }

  deserializeMissionPartner(dto: MissionPartnerProfileCompletenessDTO): MissionPartnerProfileCompleteness {
    return {
      ...dto,
      completenessPercentage: +dto.completenessPercentage,
    };
  }
}
