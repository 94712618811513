import { Inject, Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { BaseApi } from "../base-api";
import { ApiResponseDTO } from "../common.types";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { MhNotification, MhNotificationSettings } from "./notification.types";

@Injectable({ providedIn: "root" })
export class NotificationApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadNotificationList(): Observable<Array<MhNotification>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/notification/list`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  markReadNotifications(ids: Array<string>): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/notification/read`, {ids}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  loadNotificationSettings(): Observable<MhNotificationSettings> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/notification/settings`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  saveNotificationSettings(settings: MhNotificationSettings): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/notification/settings`, settings).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
}
