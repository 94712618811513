import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { filter, map, Observable, take } from "rxjs";
import { LoginService } from "../../pages/login-page/login-page-data/login.service";
import { Store } from "@ngrx/store";
import * as actions from "../../pages/login-page/login-page-data/store/login.action";
import {
  isUserDataLoadedOrLoading,
  selectLoginData,
} from "../../pages/login-page/login-page-data/store/login.selectors";
import { loginFeatureName, LoginState } from "../../pages/login-page/login-page-data/store/login.reducer";
import { AccountStatus, AccountType, LoginData } from "../../pages/login-page/login-page-data/login.types";
import { switchMap } from "rxjs/operators";
import { ForwardPage } from "../../common-componnets/common-types";
import { getForwardPageUrl } from "./forward-page";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({ providedIn: "root" })
export class AutoLoginGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private store: Store<{ [loginFeatureName]: LoginState }>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (route.data["fromEmail"]) {
      this.loginService.loginFromEmail().pipe(take(1)).subscribe();
    }
    if (route.queryParams["token"]) {
      this.loginService.setToken(route.queryParams["token"], true);
    }
    if (this.loginService.isAuthenticated()) {
      this.loadUserDataIfNotLoaded();
      let forwardPage;
      let forwardParam;
      try {
        forwardParam = route.queryParams["forwardPage"];
        forwardPage = forwardParam ? (JSON.parse(atob(forwardParam)) as ForwardPage) : undefined;
      } catch (e) {
        console.error(e + " forwardParam=" + forwardParam);
        Sentry.captureException({ error: e, forwardParam });
      }
      return this.redirectIfLoggedIn(route.url.toString(), forwardPage);
    } else {
      return true;
    }
  }
  private loadUserDataIfNotLoaded() {
    this.store
      .select(isUserDataLoadedOrLoading)
      .pipe(take(1))
      .subscribe((loadedOrLoading) => {
        if (!loadedOrLoading) {
          this.store.dispatch(actions.getUserByToken({ token: this.loginService.getToken() }));
        }
      });
  }

  private redirectIfLoggedIn(url: string, forwardPage?: ForwardPage): Observable<boolean> {
    return this.store
      .select((state) => state.login)
      .pipe(
        filter((state) => state.status.loaded),
        take(1),
        switchMap(() => {
          return this.store.select(selectLoginData).pipe(
            map((loginData: LoginData) => {
              if (
                loginData.accountType === AccountType.MISSION_PARTNER ||
                loginData.accountType === AccountType.TALENT ||
                loginData.accountType === AccountType.ADMIN
              ) {
                const registered = loginData.accountStatuses.includes(AccountStatus.REGISTERED);
                const confirmed = loginData.accountStatuses.includes(AccountStatus.CONFIRMED);
                //url /landing, /login, /sign-up-*, /talent*, /mission-partner*
                if (url.startsWith("landing") || url.startsWith("login")) {
                  if (registered && confirmed) {
                    this.navigateToDomainByType(loginData.accountType, forwardPage);
                  } else {
                    this.navigateToSignUpByType(loginData.accountType);
                  }
                }
                if ((url.startsWith("talent") || url.startsWith("mission-partner")) && (!registered || !confirmed)) {
                  this.navigateToSignUpByType(loginData.accountType);
                }
                if (url.startsWith("sign-up-") && registered && confirmed) {
                  this.navigateToDomainByType(loginData.accountType, forwardPage);
                }
                return true;
              }
              return true;
            }),
          );
        }),
      );
  }

  private navigateToDomainByType(type: AccountType | undefined, forwardPage?: ForwardPage): void {
    if (type === AccountType.MISSION_PARTNER) {
      if (forwardPage) {
        this.router.navigateByUrl(getForwardPageUrl(forwardPage));
      } else {
        this.router.navigate(["/mission-partner"]);
      }
    }
    if (type === AccountType.TALENT) {
      if (forwardPage) {
        this.router.navigateByUrl(getForwardPageUrl(forwardPage));
      } else {
        this.router.navigate(["/talent"]);
      }
    }
    if (type === AccountType.ADMIN) {
      this.router.navigate(["/admin"]);
    }
  }
  private navigateToSignUpByType(type: AccountType | undefined): UrlTree | boolean {
    if (type === AccountType.MISSION_PARTNER) {
      this.router.navigate(["/sign-up-mission-partner"]);
      return this.router.createUrlTree(["/sign-up-mission-partner"]);
    }
    if (type === AccountType.TALENT) {
      this.router.navigate(["/sign-up-talent"]);
    }
    return true;
  }
}
