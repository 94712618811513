import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { TalentProfileCvDialogComponent } from "./talent-profile-cv-dialog.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzModalModule } from "ng-zorro-antd/modal";

@NgModule({
  declarations: [TalentProfileCvDialogComponent],
  imports: [CommonModule, TranslateModule, NzImageModule, NgOptimizedImage, NzButtonModule, NzModalModule],
})
export class TalentProfileCvDialogModule {}
