import { AbstractControl, ValidationErrors } from "@angular/forms";
import { SimpleLocation } from "../../../../platform-pages/src/lib/api/google-geocoding/google-geocoding.types";


export function ValidateLocation(control: AbstractControl): ValidationErrors | null {
  if (!control.value?.postalCode) {
    return null;
  }

  const location = control.value as SimpleLocation;

  const hasPostalCode = !!location.postalCode;
  const hasCountry = !!location.country;

  return hasPostalCode && hasCountry ? null : { zip: true };
}

