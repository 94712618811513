import {Injectable} from "@angular/core";
import {Observable, shareReplay, take} from "rxjs";
import {CmsService} from "./cms.service";
import {CmsNameValue, CmsFeature, CmsFeatureType, CmsFeatureStatus} from "./cms.types";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class CmsCachedService {
  private cache: { [key: string]: Observable<Array<CmsNameValue>> } = {};
  private sortABC: {[key: string]: boolean} = {};

  constructor(
    private readonly cmsService: CmsService,
    private readonly translateService: TranslateService,
  ) {
    this.translateService.onLangChange.subscribe((event) => {
      this.sortCache();
    });
  }

  private sortByName(data: Array<CmsNameValue>): Array<CmsNameValue> {
    return data.sort((a: CmsNameValue, b: CmsNameValue) => {
      const aName = this.translateService.instant(a.name).trim();
      const bName = this.translateService.instant(b.name).trim();
      return aName.localeCompare(bName);
    });
  }

  private sortByValue(data: Array<CmsNameValue>): Array<CmsNameValue> {
    return data.sort((a: CmsNameValue, b: CmsNameValue) => a.value - b.value);
  }

  private sortByPosition(data: Array<CmsNameValue>): Array<CmsNameValue> {
    return data.sort((a: CmsNameValue, b: CmsNameValue) => (a.position || 0) - (b.position || 0));
  }

  private sortData(data: Array<CmsNameValue>, sortABC: boolean): Array<CmsNameValue> {
    if (data[0].position) {
      return this.sortByPosition(data);
    }
    if (sortABC) {
      return this.sortByName(data);
    }
    return this.sortByValue(data);
  }

  private sortCache() {
    for (const name in this.cache) {
      this.cache[name] = this.cache[name].pipe(
        map((data: Array<CmsNameValue>) => this.sortData(data, this.sortABC[name])),
      );
      this.cache[name].pipe(take(1)).subscribe();
    }
  }

  getUserDataByName(name: string, sortABC = true): Observable<Array<CmsNameValue>> {
    if(!this.cache[name]) {
      this.cache[name] = this.cmsService.loadUserDataByName(name).pipe(
        map((cached) => this.sortData(cached, sortABC)),
        shareReplay(1)
      );
      this.sortABC[name] = sortABC;
    }
    return this.cache[name];
  }
}
