import { Component, Inject } from "@angular/core";
import { TalentProfileCompleteness } from "../../../../api/dashboard/dashboard.types";
import { Store } from "@ngrx/store";
import { talentProfileFeatureName, TalentProfileState } from "../../../../api/profile/store/talent-profile.reducer";
import { Observable } from "rxjs";
import * as TalentProfileActions from "../../../../api/profile/store/talent-profile.action";
import { selectTalentProfileCompleteness } from "../../../../api/profile/store/talent-profile.selectors";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Talent } from "../../../../api/sign-up/signup.types";
import { LoginData } from "../../../login-page/login-page-data/login.types";
import { MhNotificationSettings } from "libs/platform-pages/src/lib/api/notification/notification.types";

@Component({
  selector: "mh-talent-dashboard",
  templateUrl: "./talent-dashboard.component.html",
  styleUrls: ["./talent-dashboard.component.less"],
})
export class TalentDashboardComponent {
  profileCompleteness$: Observable<TalentProfileCompleteness>;
  public profile: Talent;
  public loginData: LoginData;
  public notificationsSettings: MhNotificationSettings;

  constructor(
    private readonly store: Store<{ [talentProfileFeatureName]: TalentProfileState }>,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private translateService: TranslateService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) {
    this.profileCompleteness$ = this.store.select(selectTalentProfileCompleteness);
    this.profile = this.activatedRoute.snapshot.data["profile"];
    this.loginData = this.activatedRoute.snapshot.data["loginData"];
    this.notificationsSettings = this.activatedRoute.snapshot.data["notificationsSettings"];
    this.store.dispatch(TalentProfileActions.loadTalentProfileCompleteness());
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: "Dashboard" }));
  }
}
