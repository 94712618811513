<div *ngIf="stats" class="card-list">
  <div class="card-list__item">
    <div class="card-list__body">
      <div class="job-offer job-offer--in-list">
        <div nz-row [nzGutter]="[16, 16]" class="job-offer__details">
          <div nz-col [nzSpan]="24" [nzMd]="3">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.avg-salary-min.label" | translate }}</div>
              <ng-container *ngIf="stats.averageMinSalary">{{ stats.averageMinSalary }}K</ng-container>
              <ng-container *ngIf="!stats.averageMinSalary"> - </ng-container>
            </div>
          </div>
          <div nz-col [nzSpan]="24" [nzMd]="3">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.avg-salary-max.label" | translate }}</div>
              <ng-container *ngIf="stats.averageMaxSalary">{{ stats.averageMaxSalary }}K</ng-container>
              <ng-container *ngIf="!stats.averageMaxSalary"> - </ng-container>
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="3">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.avg-work-exp.label" | translate }}</div>
              <ng-container *ngIf="stats.averageWorkExperience">
                {{ stats.averageWorkExperience }}
                {{ (stats.averageWorkExperience == 1 ? "year.label" : "years.label") | translate }}
              </ng-container>
              <ng-container *ngIf="!stats.averageWorkExperience"> - </ng-container>
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="3">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.avg-age.label" | translate }}</div>
              <ng-container *ngIf="stats.averageAge">{{ stats.averageAge }}</ng-container>
              <ng-container *ngIf="!stats.averageAge"> - </ng-container>
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="6">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.avg-leadership-exp.label" | translate }}</div>
              <ng-container *ngIf="stats.averageLeadershipExperience">
                {{ stats.averageLeadershipExperience }}
                {{ (stats.averageLeadershipExperience == 1 ? "year.label" : "years.label") | translate }}
              </ng-container>
              <ng-container *ngIf="!stats.averageLeadershipExperience"> - </ng-container>
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="6">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.avg-will-to-change.label" | translate }}</div>
              {{ stats.averageWillingnessToChange }}
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="6">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.top-industries.label" | translate }}</div>
              <mh-tags-output [value]="stats.topIndustries"></mh-tags-output>
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="6">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.top-departments.label" | translate }}</div>
              <mh-tags-output [value]="stats.topDepartments"></mh-tags-output>
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="6">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.top-expert-fields.label" | translate }}</div>
              <mh-tags-output [value]="stats.topExpertFields"></mh-tags-output>
            </div>
          </div>

          <div nz-col [nzSpan]="24" [nzMd]="6">
            <div class="job-offer__field">
              <div class="job-offer__title">{{ "stats.top-whours-per-week.label" | translate }}</div>
              <mh-tags-output [value]="[stats.topSelectedWorkingHoursPerWeek]"></mh-tags-output>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
