import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ProfileService } from "./profile.service";
import { TalentData } from "../sign-up/signup.types";

@Injectable({
  providedIn: "root",
})
export class TalentProfileAnonymisedResolver {
  constructor(
    private readonly profileService: ProfileService,
  ) {}

  resolve(): Observable<TalentData> {
    return this.profileService.loadTalentProfileAnonymised();
  }
}
