import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {compareAsc} from "date-fns";
import {
  AccessGrant,
  AccessGrantStatus,
  ConversationNegotiation,
  MessageSubType,
  MessagingMessage,
  ParticipantInfo
} from "../../../../api/messages/messages.types";
import {Subscription} from "rxjs";
import {ChatUserInput, UiMessage, UiMessageAction} from "./messages-chat.types";
import {DeviceDetectorService} from "ngx-device-detector";
import {AccountType, LoginData} from "../../../../pages/login-page/login-page-data/login.types";

@Component({
  selector: "mh-messages-conversation-chat",
  templateUrl: "./messages-conversation-chat.component.html",
  styleUrls: ["./messages-conversation-chat.component.less"],
})
export class MessagesConversationChatComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() loginData!: LoginData;
  @Input() messages: Array<MessagingMessage> = [];
  @Input() disabled = false;
  @Input() ownerInfo: ParticipantInfo | undefined;
  @Input() recipientInfo: ParticipantInfo | undefined;
  @Input() uploadingFile: File | null = null;
  @Input() accessGrant?: AccessGrant;
  @Input() negotiation?: ConversationNegotiation;
  @Output() sendMessage = new EventEmitter<ChatUserInput>();
  @Output() loadOlderMessages = new EventEmitter<string>();
  @Output() messageAction = new EventEmitter<UiMessageAction>();

  private subscriptions: Subscription[] = [];

  data: UiMessage[] = [];
  private oldMessageLoading = false;
  private containerHeight: number | undefined;

  isMobile: boolean

  direction: "bottom-to-top" | "top-to-bottom" = "bottom-to-top";
  isUserScrolled: boolean | undefined;
  private prevScrollTop: number | undefined;

  @ViewChild("scrollContainer") private scrollContainer!: ElementRef<HTMLElement>;

  constructor(private ngZone: NgZone, private renderer: Renderer2, private deviceDetectorService: DeviceDetectorService) {
    this.renderer.addClass(document.body, 'chat-open');
    this.isMobile = this.deviceDetectorService.isMobile();
  }

  ngAfterViewInit() {
    this.updateView();
    this.ngZone.runOutsideAngular(() => {
      this.scrollContainer.nativeElement.addEventListener("scroll", () => this.scrolled());
    });
  }

  updateView() {
    if (this.oldMessageLoading) {
      this.oldMessageLoading = false;
      this.preserveScrollbarPosition();
      return;
    }
    this.scrollListBottom();
  }

  scrollListBottom() {
    if (this.scrollContainer?.nativeElement) {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["messages"]) {
      const now = new Date();
      const newMessages = this.messages.filter(msg => msg.update || !this.data.some(item => item.id === msg.id));
      if (newMessages.length > 0) {
        const uiMessages = newMessages.map(msg => this.createUiMessage(msg));
        const oldMsgs = this.data.filter(m=> !newMessages.some(item=> item.id== m.id))
        this.data = [...oldMsgs, ...uiMessages];
      }
      this.data.sort((a, b) => compareAsc(a.created, b.created));
      this.markLastActionMessage();

      setTimeout(() => this.updateView(), 0);
    }
    if(changes["uploadingFile"] && this.uploadingFile) {
      setTimeout(() => this.scrollListBottom(), 0);
    }
  }

  private markLastActionMessage() {
    //if(!((this.accessGrant?.status === AccessGrantStatus.PENDING && this.loginData.accountType === AccountType.TALENT)
    //  || (this.negotiation?.status === NegotiationStatus.INITIATED))
    //) return; TODO: implement method exit if conversation should not show action buttons
    let profileAccessFound= false;
    let agreementReachedFound = false;
    this.data.map(msg=> msg.lastActionMessage = false);
    Object.keys(this.data).reverse()
      .every((index) => {
        const message: UiMessage = this.data[index as unknown as number] as UiMessage;
        if(!profileAccessFound && message.subType === MessageSubType.PROFILE_ACCESS_QUESTION && this.loginData.accountType === AccountType.TALENT) {
          message.lastActionMessage = true;
          profileAccessFound = true;
        }
        if(!agreementReachedFound && message.subType === MessageSubType.AGREEMENT_REACHED_ONE_SIDE) {
          message.lastActionMessage = true;
          agreementReachedFound = true;
        }
        if(profileAccessFound && agreementReachedFound) return false; //leave loop
        return true;
      });
  }

  createUiMessage(msg: MessagingMessage): UiMessage {
    return {
      id: msg.id,
      avatar: this.getAvatar(msg.ownMessage),
      author: this.getAuthor(msg.ownMessage),
      content: msg.messageText,
      attachment: msg.attachment,
      created: msg.creationTimestamp,
      ownMessage: msg.ownMessage,
      messageType: msg.messageType,
      subType: msg.subType
    } as UiMessage;
  }

  scrolled() {
    if (this.scrollContainer.nativeElement.scrollHeight === this.scrollContainer.nativeElement.clientHeight) {
      return;
    }
    const scrollPosition = this.getScrollPosition();

    const isUserScrolled = this.direction === "bottom-to-top" ? scrollPosition !== "bottom" : scrollPosition !== "top";
    if (this.isUserScrolled !== isUserScrolled) {
      this.ngZone.run(() => {
        this.isUserScrolled = isUserScrolled;
      });
    }

    if (this.shouldLoadMoreMessages(scrollPosition)) {
      this.ngZone.run(() => {
        this.containerHeight = this.scrollContainer.nativeElement.scrollHeight;
        let direction: "newer" | "older";
        if (this.direction === "top-to-bottom") {
          direction = scrollPosition === "top" ? "newer" : "older";
        } else {
          direction = scrollPosition === "top" ? "older" : "newer";
        }
        if (direction === "older" && this.data.length > 10) {
          this.loadOlderMessages.emit(this.data[0].id);
          this.oldMessageLoading = true;
        }
      });
    }
    this.prevScrollTop = this.scrollContainer.nativeElement.scrollTop;
  }

  onSendMessage(userInput: ChatUserInput) {
    this.sendMessage.emit(userInput);
  }

  onMessageAction(action: UiMessageAction) {
    this.messageAction.emit(action);
  }

  private getScrollPosition(): "top" | "bottom" | "middle" {
    let position: "top" | "bottom" | "middle" = "middle";
    if (
      Math.floor(this.scrollContainer.nativeElement.scrollTop) <= 0 &&
      (this.prevScrollTop === undefined || this.prevScrollTop > 0)
    ) {
      position = "top";
    } else if (
      Math.ceil(this.scrollContainer.nativeElement.scrollTop) + this.scrollContainer.nativeElement.clientHeight >=
      this.scrollContainer.nativeElement.scrollHeight
    ) {
      position = "bottom";
    }

    return position;
  }

  private shouldLoadMoreMessages(scrollPosition: "top" | "bottom" | "middle") {
    return scrollPosition !== "middle";
  }

  private preserveScrollbarPosition() {
    this.scrollContainer.nativeElement.scrollTop =
      (this.prevScrollTop || 0) + (this.scrollContainer.nativeElement.scrollHeight - this.containerHeight!);
  }

  private getAuthor(ownMessage: boolean): string {
    return (
      (ownMessage
        ? this.ownerInfo?.name || this.ownerInfo?.companyName
        : this.recipientInfo?.name || this.recipientInfo?.companyName) || ""
    );
  }

  private getAvatar(ownMessage: boolean): string {
    return (ownMessage ? this.ownerInfo?.avatarUrl : this.recipientInfo?.avatarUrl) || "";
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.renderer.removeClass(document.body, 'chat-open');
  }
}
