import { Component, forwardRef, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { debounceTime, Observable, Subject, takeUntil } from "rxjs";
import { SimpleLocation } from "../../../../platform-pages/src/lib/api/google-geocoding/google-geocoding.types";
import { SalaryExpectation } from "../salary-expectation-input/salary-expectation.type";
import { GoogleGeocodingService } from "../../../../platform-pages/src/lib/api/google-geocoding/google-geocoding.service";
import { CmsCachedService } from "../../../../platform-pages/src/lib/api/cms/cms-cached.service";
import { CmsNameValue, compareCmsNameValue } from "../../../../platform-pages/src/lib/api/cms/cms.types";

@Component({
  selector: "mh-location-input",
  templateUrl: "./location-input.component.html",
  styleUrls: ["./location-input.component.less"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LocationInputComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: LocationInputComponent,
    },
  ],
})
export class LocationInputComponent implements OnInit, ControlValueAccessor {
  @Input() nzSize: "large" | "default" | "small" = "default";
  @Input() validators: ValidatorFn | null | undefined;
  @Input() groupValidators: ValidatorFn | null | undefined;
  @Input() groupError = false;
  @Input() distanceInput = false;
  @Input() zipOnly = false;
  @Input() set mark(isTouched: boolean) {
    if (isTouched) {
      Object.values(this.controlGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  @Input() showLabels = false;
  @Input()
  set value(value: SimpleLocation) {
    this._value = value;
    this.controlGroup?.patchValue(value, { emitEvent: false });
    if (value?.city) this.cityOptions.push(value.city);
    this.location = value;
  }
  get value(): SimpleLocation {
    return this._value;
  }
  private _value!: SimpleLocation;
  disabled = false;

  public cityOptions: string[] = [];
  private location: SimpleLocation = {};
  zipFocused = false;
  private zipBlurTimeout: ReturnType<typeof setTimeout> | undefined;
  controlGroup!: FormGroup;
  public radiusOptions$: Observable<Array<CmsNameValue>>;
  private destroy$ = new Subject<void>();
  public zipSpan = 6;
  public loading = false

  get countryControl(): AbstractControl | null {
    return this.controlGroup.get("country");
  }

  constructor(
    private fb: FormBuilder,
    private geocodingService: GoogleGeocodingService,
    private cmsCachedService: CmsCachedService,
  ) {
    this.radiusOptions$ = this.cmsCachedService.getUserDataByName("radius", false);
  }

  ngOnInit() {
    const currentValue: SimpleLocation = this.value ? this.value : this.getEmpty();

    this.controlGroup = this.fb.group({
      postalCode: [currentValue.postalCode, []],
      city: [currentValue.city, []],
      country: [{ value: currentValue.country, disabled: true }, []],
      radius: [currentValue.radius, []],
    });

    if (this.validators) {
      this.controlGroup.get("postalCode")?.setValidators(this.validators);
    }

    if (this.groupValidators) {
      this.controlGroup.setValidators(this.groupValidators);
    }

    this.controlGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.onChange({ ...this.location, ...this.controlGroup.value, country: "" });
    });

    this.controlGroup.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((value) => {
      this.handleZipChange();
    });

    this.zipSpan = this.calculateZipSpan();
  }

  handleZipChange() {
    const zipControl = this.controlGroup.get("postalCode");
    const cityControl = this.controlGroup.get("city");
    const countryControl = this.countryControl;
    if (zipControl?.valid && this.zipFocused) {
      this.loading = true;
      this.geocodingService.decodeAddress(zipControl.value).subscribe((location) => {
        countryControl?.patchValue(location.country, { emitEvent: false });
        cityControl?.patchValue(location.city, { emitEvent: false });
        this.cityOptions = [];
        if (location.city) this.cityOptions.push(location.city);
        //if (location.cityOptions?.length) this.cityOptions = [...this.cityOptions, ...location.cityOptions];
        this.location = location;
        this.onChange({ ...location, ...this.controlGroup.value, country: this.countryControl?.getRawValue() });
        this.loading = false;
      });
    } else if (this.zipFocused) {
      countryControl?.patchValue("", { emitEvent: false });
      cityControl?.patchValue("", { emitEvent: false });
      this.cityOptions = [];
    }
    this.onChange({ ...this.location, ...this.controlGroup.value, country: this.countryControl?.getRawValue() });
  }

  private getEmpty(): SimpleLocation {
    return {
      postalCode: undefined,
      country: undefined,
      city: undefined,
    };
  }

  onChange = (value: SalaryExpectation) => {};
  onTouched = () => {};

  writeValue(value: SimpleLocation) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if(isDisabled) {
      this.controlGroup.get('postalCode')?.disable({onlySelf: true, emitEvent: false});
      this.controlGroup.get('city')?.disable({onlySelf: true, emitEvent: false});
      this.controlGroup.get('radius')?.disable({onlySelf: true, emitEvent: false});
    } else {
      this.controlGroup.get('postalCode')?.enable({onlySelf: true, emitEvent: false});
      this.controlGroup.get('city')?.enable({onlySelf: true, emitEvent: false});
      this.controlGroup.get('radius')?.enable({onlySelf: true, emitEvent: false});
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const postalCode = this.controlGroup.get("postalCode");
    if (postalCode) {
      return !postalCode.valid ? postalCode.errors : null;
    }

    return null;
  }

  handleZipBlur() {
    this.zipBlurTimeout = setTimeout(() => {
      this.zipFocused = false;
    }, 1000);
  }

  handleZipFocus() {
    this.zipFocused = true;
    if (this.zipBlurTimeout) {
      clearTimeout(this.zipBlurTimeout);
      this.zipBlurTimeout = undefined;
    }
  }

  calculateZipSpan(): number {
    if (this.zipOnly && this.distanceInput) {
      return 12;
    } else if (this.zipOnly) {
      return 24;
    } else if (this.distanceInput) {
      return 4;
    } else {
      return 6;
    }
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
