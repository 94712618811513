import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {Observable, Subject, takeUntil} from "rxjs";
import { SignupService } from "../../../../api/sign-up/signup.service";
import {
  NumberRequiredValidator,
  SpecialCharacterRequiredValidator,
  StrictEmailValidator,
  UpperCaseRequiredValidator,
} from "@momhunting/core";
import { MissionPartner } from "../../../../api/sign-up/signup.types";
import {CmsNameValue, compareCmsNameValue} from "../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../api/cms/cms-cached.service";
import {TranslateService} from "@ngx-translate/core";
import {ProfileService} from "../../../../api/profile/profile.service";
import {LoginService} from "../../../login-page/login-page-data/login.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MissionPartnerProfileFactory} from "../../../../api/profile/mission-partner-profile.factory";

@Component({
  selector: "mh-signup-mission-partner-general",
  templateUrl: "./signup-mission-partner-general.component.html",
  styleUrls: ["./signup-mission-partner-general.component.less"],
})
export class SignupMissionPartnerGeneralComponent implements OnInit, OnDestroy {
  @Input() profile!: MissionPartner;
  @Output() nextStep = new EventEmitter<void>();
  @Output() formChange = new EventEmitter<MissionPartner>();
  @Output() registered = new EventEmitter<{ token: string; account: MissionPartner }>();

  public formGroup!: FormGroup;
  public knownFromFormGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public knownFrom$: Observable<Array<CmsNameValue>>;
  loading = false;

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant('field.required.label'),
      invalid: this.translateService.instant('field.invalid.label'),
    },
  };

  get dataSafetyLink(): string {
    const linkLabel = this.translateService.instant('reg.mp.datasafety.link');
    return '<a href="/data-protection" target="_blank">'+linkLabel+'</a>';
  }

  get termsLink(): string {
    const linkLabel = this.translateService.instant('reg.mp.terms.link');
    return '<a href="/terms-and-conditions" target="_blank">'+linkLabel+'</a>';
  }

  constructor(private signupService: SignupService,
              private profileService: ProfileService,
              private loginService: LoginService,
              private cmsCachedService: CmsCachedService,
              private translateService: TranslateService,
              private deviceDetectorService: DeviceDetectorService,
              private missionPartnerProfileFactory: MissionPartnerProfileFactory,
              private fb: FormBuilder) {
    this.knownFrom$ = this.cmsCachedService.getUserDataByName('known-from', false);
  }

  get password(): FormControl {
    return this.formGroup.get("password") as FormControl;
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      companyName: new FormControl(this.profile.companyName, [Validators.required]),
      email: new FormControl(this.profile.email, {validators: [this.confirmationValidator], updateOn:'blur'}),
      emailConfirm: new FormControl(this.profile.email, {validators: [this.confirmationValidator], updateOn:'blur'}),
      password: new FormControl("", [
        Validators.required,
        NumberRequiredValidator,
        SpecialCharacterRequiredValidator,
        UpperCaseRequiredValidator,
      ]),
      updatesSubscription: new FormControl(this.profile.updatesSubscription, []),
      termsAndConditions: new FormControl(this.profile.termsAndConditions, [Validators.requiredTrue]),
      interestedInDadHunting: new FormControl(this.profile.interestedInDadHunting, []),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.formChange.emit(value));

    this.knownFromFormGroup = this.fb.group({
      knownFrom: [undefined, []],
      knownFromText: [undefined, []],
    });
  }

  submitForm() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.loading = true;
    this.signupService.registerMissionPartner({
      ...this.missionPartnerProfileFactory.getNewInstance(),
      ...this.formGroup.value,
      userAgent: this.deviceDetectorService.userAgent,
      deviceType: this.deviceDetectorService.deviceType
    }).subscribe((token) => {
      this.loginService.setToken(token);
      this.profileService.saveKnownFrom(this.knownFromFormGroup.value).subscribe(()=> {
        this.loading = false;
        this.registered.emit({ token, account: this.formGroup.value });
        this.nextStep.emit();
      });
    });
  }

  onEmailPaste(controlName: string) {
    const otherControl = controlName === 'email' ? this.formGroup?.get("emailConfirm") : this.formGroup?.get("email");
    setTimeout(()=>otherControl?.updateValueAndValidity({onlySelf: true}), 0);
  }

  confirmationValidator: ValidatorFn = (control: AbstractControl): { [s: string]: boolean } => {
    const otherControl = this.getControlName(control) === 'email' ? this.formGroup?.get("emailConfirm") : this.formGroup?.get("email");
    if (!control.value) {
      return {required: true};
    } else if(StrictEmailValidator(control)) {
      return { 'email': true };
    } else if (control?.value !== otherControl?.value) {
      otherControl?.setErrors(null);
      return { confirm: true, error: true };
    }
    control?.setErrors(null);
    otherControl?.setErrors(null);
    return {};
  };

  private getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent?.controls;
    return formGroup && Object.keys(formGroup).find(name => c === (formGroup as any)[name]) || null;
  }

  get showKnownFromDetailsControl(): boolean {
    const knownFromControl = this.knownFromFormGroup.get("knownFrom");
    return [4, 6, 9, 11].includes((knownFromControl?.value as CmsNameValue)?.value);
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
