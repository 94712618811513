<nz-page-header [nzTitle]="'profile.personality.title' | translate"></nz-page-header>


<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <h3>{{'profile.personality.subtitle' | translate}}</h3>
  <h4>{{'profile.personality.text' | translate}}</h4>

  <div nz-row [nzGutter]="24" formGroupName="personality">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-core-values.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityCoreValues"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-dinner-date.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityDinnerDate"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-superpower.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalitySuperpower"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-balance.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityBalance"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-proud.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityProud"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-element.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityElement"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-role-model.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityRoleModel"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-inspiration.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityInspiration"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-paths.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityPaths"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.personality-irritations.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="personalityIrritations"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save-and-next.button' | translate}}</button>
    </div>
  </div>
</form>

