import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { MapGeocoder } from "@angular/google-maps";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class GoogleGeocodingApi extends BaseApi {
  constructor(
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private readonly geocoder: MapGeocoder,
  ) {
    super(injector);
  }

  decodeAddress(address: string, language = 'de'): Observable<Array<google.maps.GeocoderResult>> {
    //return this.geocoder.geocode({ address, language, region: 'de' }).pipe(
    if(!this.appConfig.production) console.info(JSON.stringify({address, language}));
    return this.geocoder.geocode({ address, language, region: 'de,ch,at,en' }).pipe(
      map((response) => {
        if (response.status === "OK") {
          console.log(response);
          return response.results;
        }
        console.log(response);
        return [];
      }),
    );
  }
}
