import { Inject, Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { BaseApi } from "../base-api";
import { ApiResponseDTO } from "../common.types";
import { CmsNameValueDTO } from "./cms-api.types";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import {CmsFeature} from "./cms.types";

@Injectable({ providedIn: "root" })
export class CmsApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadUserData(sectors: Array<string>): Observable<Array<CmsNameValueDTO>> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/cms-data/user-data`, {sectors}).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  loadUserDataByName(name: string): Observable<Array<CmsNameValueDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/cms-data/${name}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  loadBox(boxName: string, locale: string): Observable<string> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/localization/box/content/${boxName}/${locale}`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.box;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }
}
