import { createAction, props } from "@ngrx/store";
import { TalentProfileCompleteness } from "../../dashboard/dashboard.types";
import { Talent } from "../../sign-up/signup.types";

export const loadTalentProfileCompleteness = createAction("[Talent profile] Load completeness");
export const loadTalentProfileCompletenessSuccess = createAction("[Talent profile][Success] Load completeness", props<{ data: TalentProfileCompleteness }>());
export const loadTalentProfileCompletenessError = createAction("[Talent profile][Error] Load completeness", props<{ error: unknown }>());

export const loadTalentProfile = createAction("[Talent profile] Load profile");
export const loadTalentProfileSuccess = createAction("[Talent profile][Success] Load profile", props<{ data: Talent }>());
export const loadTalentProfileError = createAction("[Talent profile][Error] Load profile", props<{ error: unknown }>());


export const updateStoreTalentProfile = createAction("[Talent profile] Update profile", props<{ data: Talent }>());

export const updateTalentProfile = createAction("[Talent profile] Update store profile", props<{ data: Talent }>());
export const updateTalentProfileSuccess = createAction("[Talent profile][Success] Update profile", props<{ data: Talent }>());
export const updateTalentProfileError = createAction("[Talent profile][Error] Update profile", props<{ error: unknown }>());

export const clearTalentProfile = createAction('[Talent Profile] Clear');
