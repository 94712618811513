import { Injectable } from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { FiltersPage, FilterStorage } from "./filter-storage.types";

@Injectable({
  providedIn: 'root'
})
export class FilterStorageService {

  constructor() { }

  saveFilters(formValue: UntypedFormGroup, filtersPage: FiltersPage): void {
    if (!formValue) {
      return;
    }
    const storedFilters = this.getStoredFilters();
    const updatedFilters = { ...storedFilters, [filtersPage]: formValue.value };

    localStorage.setItem('storedFilters', JSON.stringify(updatedFilters));
  }

  getStoredFilters(): FilterStorage {
    const savedForm = localStorage.getItem('storedFilters');
    return savedForm ? JSON.parse(savedForm) : {};
  }
}
