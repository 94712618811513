import { combineReducers, createReducer, on } from "@ngrx/store";
import * as TalentProfileActions from "./talent-profile.action";
import { TalentProfileCompleteness } from "../../dashboard/dashboard.types";
import { Talent } from "../../sign-up/signup.types";
import * as LoginActions from "../../../pages/login-page/login-page-data/store/login.action";
import {LoginData} from "../../../pages/login-page/login-page-data/login.types";

export const talentProfileFeatureName = "talentProfile";

export type StateStatus = {
  loading: boolean;
  loaded: boolean;
  error: unknown;
};

export interface TalentProfileCompletenessState {
  status: StateStatus;
  data: TalentProfileCompleteness;
}

const initialCompletenessState: TalentProfileCompletenessState = {
  status: { loaded: false, loading: false, error: null },
  data: {
    sections: [],
    totalNumberOfFields: 0,
    totalFilledOutNumberOfFields: 0,
    completenessPercentage: "0",
    isProfileFilledOut: false,
  },
};

export const talentProfileCompletenessReducer = createReducer(
  initialCompletenessState,
  on(TalentProfileActions.loadTalentProfileCompleteness, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(TalentProfileActions.loadTalentProfileCompletenessSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data,
    };
  }),
  on(TalentProfileActions.loadTalentProfileCompletenessError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
);

export interface TalentProfileEntityState {
  status: StateStatus;
  data: Talent | undefined;
}

export interface TalentProfileState {
  completeness: TalentProfileCompletenessState;
  entity: TalentProfileEntityState;
}

const initialEntityState: TalentProfileEntityState = {
  status: { loaded: false, loading: false, error: null },
  data: undefined,
};

export const talentProfileEntityReducer = createReducer(
  initialEntityState,
  on(TalentProfileActions.loadTalentProfile, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(TalentProfileActions.loadTalentProfileSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data,
    };
  }),
  on(TalentProfileActions.loadTalentProfileError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(TalentProfileActions.updateStoreTalentProfile, (state, action) => {
    return {
      ...state,
      data: action.data,
    };
  }),
  on(TalentProfileActions.updateTalentProfile, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(TalentProfileActions.updateTalentProfileSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data
    };
  }),
  on(TalentProfileActions.updateTalentProfileError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(TalentProfileActions.clearTalentProfile, (state, action) => {
    return {
      status: { loading: false, loaded: false, error: null },
      data: undefined,
    };
  }),
);

export const talentProfileReducer = combineReducers<TalentProfileState>({
  completeness: talentProfileCompletenessReducer,
  entity: talentProfileEntityReducer,
});
