import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AssessmentFormResolver } from "../../api/assessment/assessment-form-resolver.service";
import {
  ExternalAssessmentQuestionerComponent
} from "./external-assessment-questioner/external-assessment-questioner.component";
import {ExternalAssessmentCheckResolver} from "../../api/assessment/external-assessment-check.resolver";

const routes: Routes = [
  {
    path: ":token",
    component: ExternalAssessmentQuestionerComponent,
    resolve: {
      assessment: ExternalAssessmentCheckResolver,
    },
  },
  {
    path: "personal/:token",
    component: ExternalAssessmentQuestionerComponent,
    resolve: {
      assessmentForm: AssessmentFormResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExternalAssessmentQuestionerRoutingModule {}
