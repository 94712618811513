import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DashboardService } from "./dashboard.service";
import { DashboardConversationInfo } from "./dashboard.types";

@Injectable({
  providedIn: "root",
})
export class TalentLastRepliesResolver {
  constructor(private readonly dashboardService: DashboardService) {}

  resolve(): Observable<DashboardConversationInfo> {
    return this.dashboardService.getTalentLastReplies();
  }
}
