<nz-layout class="auth" mhScrollToValidationError>
  <div nz-row class="auth__container">
    <div nz-col nzXs="0" nzMd="12" class="auth__bg">
      <div class="auth__bg-img"></div>
    </div>
    <div nz-col nzXs="24" nzMd="12" class="auth__content">
      <mh-locale-select mode="dropdown"></mh-locale-select>

      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24" class="auth__logo">
          <img class="logo-img" src="/assets/images/logo.svg" alt="Momhunting logo" />
        </div>
        <div nz-col nzSpan="24" class="auth__stepper">
          <nz-steps [nzCurrent]="currentStep" nzSize="small">
            <nz-step [nzTitle]="'reg.mp.step1' | translate"></nz-step>
            <nz-step [nzTitle]="'reg.mp.step2' | translate"></nz-step>
            <nz-step [nzTitle]="'reg.mp.step3' | translate"></nz-step>
            <nz-step [nzTitle]="'reg.mp.step4' | translate"></nz-step>
          </nz-steps>
        </div>

        <div nz-col nzSpan="24" class="steps-action">
          <ng-container *ngIf="currentStep === 0">
            <mh-signup-mission-partner-general
              [profile]="profile"
              (formChange)="onFormChange($event)"
              (registered)="onRegistered($event)"
              (nextStep)="onNextStep()"
            ></mh-signup-mission-partner-general>
          </ng-container>
          <ng-container *ngIf="currentStep === 1">
            <mh-signup-mission-partner-contact
              [profile]="profile"
              (formChange)="onFormChange($event)"
              (updateUser)="onUpdateUser()"
            ></mh-signup-mission-partner-contact>
          </ng-container>
          <ng-container *ngIf="currentStep === 2">
            <mh-signup-mission-partner-details
              [profile]="profile"
              (formChange)="onFormChange($event)"
              (updateUser)="onUpdateUser()"
            ></mh-signup-mission-partner-details>
          </ng-container>
          <ng-container *ngIf="currentStep === 3">
            <mh-signup-email-confirm [account]="profile"></mh-signup-email-confirm>
          </ng-container>
        </div>

        <ng-container *ngIf="false">
          <div nz-col nzSpan="24" class="steps-action">
            <button nz-button nzType="default" (click)="pre()" *ngIf="currentStep > 0">
              <span>Previous</span>
            </button>
            <button nz-button nzType="default" (click)="next()" *ngIf="currentStep < 3">
              <span>Next</span>
            </button>
            <button nz-button nzType="primary" (click)="done()" *ngIf="currentStep === 3">
              <span>Done</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</nz-layout>
