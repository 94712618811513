<nz-layout class="content-page">
  <nz-header class="common-header">
    <div class="container">
      <div>
        <a class="logo-container" href="/">
          <img class="logo-img" nz-image width="67px" nzSrc="/assets/images/logo.svg" alt="Momhunting logo" />
        </a>
      </div>

      <div class="cnt-buttons">
        <button nz-button nzType="primary" nzSize="large" [routerLink]="['landing']">
          {{'header.home.btn' | translate}}
        </button>
      </div>
    </div>
  </nz-header>
  <nz-content class="with-header container" [innerHTML]="content"></nz-content>
</nz-layout>
