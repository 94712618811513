import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminInvoiceCreateComponent } from "./admin-invoice-create.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { TranslateModule } from "@ngx-translate/core";
import { NzModalModule } from "ng-zorro-antd/modal";
import { InvoiceFormModule } from "../invoice-form/invoice-form.module";

@NgModule({
  declarations: [AdminInvoiceCreateComponent],
  imports: [CommonModule, InvoiceFormModule, NzButtonModule, TranslateModule, NzModalModule],
})
export class AdminInvoiceCreateModule {}
