<form nz-form nzLayout="vertical" [formGroup]="form" class="card-list">
  <ng-container formArrayName="trackRecords">
    <div *ngIf="editingIndex === null && showAddBtn" class="header-btn">
      <button nz-button (click)="addNewTrackRecord()" class="card-list__add-btn">
        <span nz-icon nzType="plus"></span>{{ "profile.add-truck-record.button" | translate }}
      </button>
    </div>
    <ng-container *ngFor="let trackRecordForm of trackRecordFormArray.controls; let index = index">
      <ng-container [formGroupName]="index" [class.card-list__item--edit]="editingIndex !== null">
        <div
          *ngIf="editingIndex !== index"
          class="card-list__item"
          [ngClass]="{ invalid: isInvalid(trackRecordForm.value) }"
        >
          <div class="card-list__body">
            <div nz-row>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.employment-duration.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <mh-employment-duration-label
                    [employmentDurationRange]="trackRecordForm.value.employmentDurationRange"
                    [employmentDurationFromToNow]="trackRecordForm.value.employmentDurationFromToNow"
                    [workingUntilNow]="trackRecordForm.value.workingUntilNow"
                  ></mh-employment-duration-label>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.sector.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="trackRecordForm.value.sector; else noValueSpecified">
                    {{trackRecordForm.value.sector.name | translate}}
                  </ng-container>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.industry.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="trackRecordForm.value.industry; else noValueSpecified">
                    {{trackRecordForm.value.industry.name | translate}}
                  </ng-container>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.department.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="trackRecordForm.value.department; else noValueSpecified">
                    {{trackRecordForm.value.department.name | translate}}
                  </ng-container>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.job-title.label' | translate}}</h4>
                <p class="card-list__item-value">{{trackRecordForm.value.jobTitle}}</p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.company-name.label' | translate}}</h4>
                <p class="card-list__item-value">{{trackRecordForm.value.companyName}}</p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.type-of-company.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="trackRecordForm.value.companyType; else noValueSpecified">
                    {{trackRecordForm.value.companyType.name | translate}}
                  </ng-container>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="8">
                <h4 class="card-list__item-label">{{'profile.role-details.label' | translate}}</h4>
                <p class="card-list__item-value">{{trackRecordForm.value.roleDetails}}</p>
              </div>
            </div>
          </div>
          <div class="card-list__actions">
            <button nz-button nzType="link" (click)="toggleEditing(index)">
              <span nz-icon nzType="edit"></span>
            </button>
            <button nz-button nzType="link" nzDanger
                    (click)="confirmDeleteTrackRecord(index)">
              <span nz-icon nzType="delete"></span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="editingIndex === index">
          <div class="card-list__item">
            <div class="card-list__body">
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item [style.display]="!trackRecordForm.value.workingUntilNow ? 'block' : 'none'">
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.employment-duration.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-range-picker
                        nzMode="month"
                        [nzDisabledDate]="disabledDate"
                        formControlName="employmentDurationRange"
                      ></nz-range-picker>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item [style.display]="trackRecordForm.value.workingUntilNow ? 'block' : 'none'">
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.employment-duration.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker
                        style="width: 100%"
                        nzMode="month"
                        [nzDisabledDate]="disabledDate"
                        formControlName="employmentDurationFromToNow"
                      ></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="12" [nzMd]="6">
                  <nz-form-item>
                    <mh-explanation-label [labelKey]="'profile.working-until-now.label'"></mh-explanation-label>
                    <nz-form-control class="card-list__switch-control">
                      <nz-switch formControlName="workingUntilNow"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="12" [nzMd]="6">
                  <nz-form-item>
                    <mh-explanation-label [labelKey]="'profile.current-track-record.label'"></mh-explanation-label>
                    <nz-form-control class="card-list__switch-control">
                      <nz-switch formControlName="current"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.sector.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-select nzSize="default" formControlName="sector" [compareWith]="compareCmsNameValue" nzShowSearch>
                        <nz-option
                          [nzLabel]="option.name | translate"
                          [nzValue]="option"
                          *ngFor="let option of sectors$ | async"
                        ></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.industry.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-select nzSize="default" formControlName="industry" [compareWith]="compareCmsNameValue" nzShowSearch>
                        <nz-option
                          [nzLabel]="option.name | translate"
                          [nzValue]="option"
                          *ngFor="let option of industry$ | async"
                        ></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.department.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-select nzSize="default" formControlName="department" [compareWith]="compareCmsNameValue" nzShowSearch>
                        <nz-option
                          [nzLabel]="option.name | translate"
                          [nzValue]="option"
                          *ngFor="let option of department$ | async"
                        ></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.job-title.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <input nz-input nzSize="default" formControlName="jobTitle" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.company-name.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <input nz-input nzSize="default" formControlName="companyName" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.type-of-company.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-select nzSize="default" formControlName="companyType" [compareWith]="compareCmsNameValue" nzShowSearch>
                        <nz-option
                          [nzLabel]="option.name | translate"
                          [nzValue]="option"
                          *ngFor="let option of companyTypeOptions$ | async"
                        ></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.role-details.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <input nz-input nzSize="default" formControlName="roleDetails"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzSpan]="24" class="card-list__form-buttons">
                  <button nz-button class="card-list__form-btn" [nzType]="'default'" (click)="cancelTrackRecord(index)">
                    {{ "cancel.button" | translate }}
                  </button>
                  <button nz-button class="card-list__form-btn" [nzType]="'primary'" (click)="saveTrackRecord($event, index)">
                    {{ "save.button" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <p *ngIf="!trackRecordFormArray.controls.length">{{'empty.list.label' | translate}}</p>
</form>
<ng-template #noValueSpecified>-</ng-template>
