import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { CoreModule } from "@momhunting/core";
import { PlatformPagesModule } from "@momhunting/platform-pages";
import { RouterModule } from "@angular/router";
import { AppConfigFromAssets } from "../../../../libs/core/src/lib/app-config/config.types";

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, RouterModule, CoreModule, PlatformPagesModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  static configure(appConfig: AppConfigFromAssets) {
    const CoreModuleWithProviders = CoreModule.configure(appConfig);

    return {
      ngModule: AppModule,
      providers: CoreModuleWithProviders.providers
    };
  }
}
