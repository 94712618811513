import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotificationDropdownComponent } from "./notification-dropdown.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {NzTagModule} from "ng-zorro-antd/tag";

@NgModule({
  declarations: [NotificationDropdownComponent],
    imports: [CommonModule, NzBadgeModule, NzButtonModule, NzIconModule, NzDropDownModule, RouterLink, TranslateModule, NzTagModule],
  exports: [NotificationDropdownComponent],
})
export class NotificationDropdownModule {}
