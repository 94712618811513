<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{'testimonials.title' | translate}}</nz-page-header-title>
</nz-page-header>

<div class="testimonials">
  <nz-divider *ngIf="testimonials?.length" nzPlain [nzText]="'testimonials.my-testimonials.title' | translate" nzOrientation="left"></nz-divider>
  <nz-list nzItemLayout="vertical">
    <nz-list-item *ngFor="let testimonial of testimonials" class="testimonial">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          {{ testimonial.referenceName }}
          <div class="testimonial__actions">
            <button
              nz-button
              [nzType]="'link'"
              nz-tooltip [nzTooltipTitle]="'testimonials.delete-testimonial.button' | translate"
              (click)="confirmDeleteTestimonial(testimonial.id)"
            >
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </div>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <div class="testimonial__info">
        <span>
          {{testimonial.referenceCompany}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span>
          {{testimonial.referencePosition}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <a [href]="'mailto:' + testimonial.referenceEmail">
          {{testimonial.referenceEmail}}
        </a>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span class="testimonial__timestamp"> {{ testimonial.creationTimestamp }}</span>
      </div>
      <div class="testimonial__text">
        {{ testimonial.referenceText }}
      </div>
    </nz-list-item>
  </nz-list>

  <nz-divider *ngIf="testimonialsRequest?.length" nzPlain [nzText]="'testimonials.my-testimonials-requests.title' | translate" nzOrientation="left"></nz-divider>
  <nz-list nzItemLayout="vertical">
    <nz-list-item *ngFor="let testimonial of testimonialsRequest" class="testimonial">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <div>
            <span>{{ testimonial.referenceName }}</span>
            <div class="testimonial__actions">
              <button
                nz-button
                [nzType]="'link'"
                nz-tooltip
                [nzTooltipTitle]="'testimonials.resend-testimonial.button' | translate"
                [nzLoading]="isResendingTestimonial"
                (click)="resendTestimonialRequest(testimonial.id)"
              >
                <span nz-icon nzType="mail" nzTheme="outline"></span>
              </button>
              <nz-divider nzPlain nzType="vertical"></nz-divider>
              <button
                nz-button
                [nzType]="'link'"
                nz-tooltip
                [nzTooltipTitle]="'testimonials.delete-testimonial.button' | translate"
                (click)="confirmDeleteTestimonialRequest(testimonial.id)"
              >
                <span nz-icon nzType="delete" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <div class="testimonial__info">
        <span>
          {{testimonial.referenceCompany}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span>
          {{testimonial.referencePosition}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <a [href]="'mailto:' + testimonial.referenceEmail">
          {{testimonial.referenceEmail}}
        </a>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span class="testimonial__timestamp"> {{ testimonial.creationTimestamp }}</span>
      </div>
    </nz-list-item>
  </nz-list>

  <nz-divider nzPlain [nzText]="'testimonials.new-testimonials.title' | translate" nzOrientation="left"></nz-divider>
  <p>{{'testimonials.new-testimonials.description' | translate}}</p>
  <form nz-form [formGroup]="formGroup" nzLayout="vertical" (ngSubmit)="submitTestimonialRequest()">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'testimonials.name.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="name" [placeholder]="'testimonials.name.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'testimonials.email.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="email" [placeholder]="'testimonials.email.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'testimonials.company.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="company" [placeholder]="'testimonials.company.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'testimonials.position.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="position" [placeholder]="'testimonials.position.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="24" class="testimonials__submit">
        <button nz-button [nzType]="'primary'" [disabled]="isSendingTestimonial">{{ 'testimonials.create-request.button' | translate }}</button>
      </div>
    </div>
  </form>
</div>
