import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmojiPickerComponent } from "./emoji-picker.component";
import {PickerModule} from "@ctrl/ngx-emoji-mart";

@NgModule({
  declarations: [EmojiPickerComponent],
  imports: [CommonModule, PickerModule],
  exports: [
    EmojiPickerComponent
  ]
})
export class EmojiPickerModule {}
