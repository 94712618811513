import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import {
  MaturityAssessmentForm,
  MAQuestionType,
  MASection,
  MASubSection,
  MAAnswerSaveRequest,
  MAAnswerOption,
  MAAnswerOptionType,
  MAQuestion,
} from "../../../api/assessment/assessment.types";
import { AssessmentService } from "../../../api/assessment/assessment.service";
import { debounceTime, Subject, takeUntil } from "rxjs";
import { CommonService } from "../../../api/common.service";

export interface AssessmentStep {
  section?: Partial<MASection>;
  subSection: MASubSection;
}

@Component({
  selector: "mh-assessment-navigator",
  templateUrl: "./assessment-navigator.component.html",
  styleUrls: ["./assessment-navigator.component.less"],
})
export class AssessmentNavigatorComponent implements OnChanges, OnDestroy {
  @Input() assessment!: MaturityAssessmentForm;
  @Output() completeForm = new EventEmitter<void>();
  currentStepIndex: number = 0;
  steps: Array<AssessmentStep> = [];
  private saveAnswersSubject = new Subject<MAAnswerSaveRequest>();
  private readonly destroy$ = new Subject<void>();

  get currentStep(): AssessmentStep {
    return this.steps[this.currentStepIndex];
  }

  get progressPercentage(): number {
    return Math.ceil((100 * (this.currentStepIndex + 1)) / this.steps.length);
  }

  constructor(private assessmentService: AssessmentService, private commonService: CommonService) {
    this.saveAnswersSubject.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe((data) => {
      this.assessmentService.saveAnswer(data).subscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["assessment"] && this.assessment?.form?.sections?.length) {
      this.populateSteps();
      this.addQuestionNumbers();
    }
  }

  private populateSteps(): void {
    this.steps = [];
    this.assessment.form.sections.forEach((section) => {
      section.subsections.forEach((subSection, index) => {
        if (subSection.questions.length && index == 0) {
          this.steps.push({
            section: { type: section.type, imageUrl: section.imageUrl },
            subSection: subSection,
          } as AssessmentStep);
        } else {
          this.steps.push({ subSection: subSection });
        }
      });
    });
    this.currentStepIndex = this.assessment.step;
  }

  private addQuestionNumbers() {
    let questionNumber = 1;
    this.steps.forEach((step) => {
      step.subSection.questions.forEach((q) => {
        q.number = String(questionNumber++);
        let abcIndex = 10;
        const foundLinkedQuestions: Array<MAQuestion> = [];
        q.answerOptions.forEach((a) =>
          a.linkedQuestions.forEach((lq) => {
            const foundLinkedQuestion = foundLinkedQuestions.find((flq) => flq.title == lq.title);
            if (!foundLinkedQuestion) {
              lq.number = q.number + (abcIndex++).toString(36);
              foundLinkedQuestions.push(lq);
            } else {
              lq.number = foundLinkedQuestion.number;
            }
          }),
        );
        return q;
      });
    });
  }

  get isPrevDisabled(): boolean {
    return this.currentStepIndex <= 0;
  }
  get isNextDisabled(): boolean {
    return this.currentStepIndex >= this.steps.length - 1;
  }

  onPrevStep() {
    this.currentStepIndex--;
    window.scroll(0, 0);
  }
  onNextStep() {
    this.currentStepIndex++;
    window.scroll(0, 0);
  }

  onComplete() {
    this.completeForm.emit();
  }

  getImageByKey(key: string | undefined): string {
    return this.commonService.toAbsUrl(key);
  }

  saveAnswers(answers: Array<MAAnswerOption>) {
    const request: MAAnswerSaveRequest = { token: this.assessment.token, step: this.currentStepIndex, answers: {} };
    answers.forEach((answer) => {
      if (answer.type === MAAnswerOptionType.SELECTABLE) {
        request.answers[answer.id] = answer.selected ? "true" : "false";
      } else {
        request.answers[answer.id] = answer.value;
      }
    });

    this.saveAnswersSubject.next(request);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly AssessmentQuestionType = MAQuestionType;
}
