import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NzUploadChangeParam, NzUploadFile } from "ng-zorro-antd/upload";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { DocumentService } from "../../api/document/document.service";
import { Observable, Subject } from "rxjs";
import { DocumentSerializer } from "../../api/document/document.serializer";

@Component({
  selector: "mh-profile-documents-upload",
  templateUrl: "./profile-documents-upload.component.html",
  styleUrls: ["./profile-documents-upload.component.less"],
})
export class ProfileDocumentsUploadComponent implements OnInit, OnDestroy {
  @Input() uploadCV!: boolean;
  @Input() mobileView!: boolean;
  @Input() fileList: NzUploadFile[] = [];
  @Output() fileListChange = new EventEmitter<NzUploadFile[]>();
  actionUrl!: string;
  private readonly destroy$ = new Subject<void>();

  get uploadDisabled(): boolean {
    return this.uploadCV && this.fileList.length > 0;
  }

  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private documentsService: DocumentService,
    private documentSerializer: DocumentSerializer,
  ) {}

  ngOnInit() {
    const endpointUri = this.uploadCV ? "/file/upload/cv" : "/file/upload/file";
    this.actionUrl = `${this.appConfig.apiEndpointUrl}${endpointUri}`;
  }

  handleChange(info: NzUploadChangeParam): void {
    let fileList = [...info.fileList];

    fileList = fileList
      .filter((file) => !file.error)
      .map((file) => {
        if (file.response) {
          file = {
            ...file,
            ...this.documentSerializer.deserializeDocument(file.response.results.data),
          };
        }
        return file;
      });

    this.fileListChange.emit(fileList);
  }

  handleRemove = (file: NzUploadFile): boolean | Observable<boolean> => {
    return this.documentsService.deleteUserFile(file.uid);
  };

  ngOnDestroy() {
    this.destroy$.next();
  }
}
