import { Injectable } from "@angular/core";
import { MessagesApi } from "./messages-api";
import { map, Observable, of, withLatestFrom } from "rxjs";
import { MessagesSerializer } from "./messages-serializer.service";
import {
  AgreementTerms,
  Conversation,
  ConversationDTO,
  MessagingMessage,
  MissionPartnerMessageSuggest,
  TalentMessageSuggest,
} from "./messages.types";
import { AccountFilter, NegotiationStatus } from "../../pages/home-admin/home-admin-data/account.types";
import { PaginatedContent } from "../common.types";
import { AccountSerializer } from "../../pages/home-admin/home-admin-data/account.serializer";
import { SocketService } from "../../../../../core/src/lib/soket/socket.service";
import { AuthorizationService } from "../authorization/authorization.service";

@Injectable({ providedIn: "root" })
export class MessagesService {
  constructor(
    private messagesApi: MessagesApi,
    private messagesSerializer: MessagesSerializer,
    private accountSerializer: AccountSerializer,
    private socketService: SocketService,
    private authorizationService: AuthorizationService,
  ) {}

  initiateConversation(talentId: string, messageText: string, joId?: string): Observable<Conversation> {
    return this.messagesApi.initiateConversation(talentId, messageText, joId).pipe(
      withLatestFrom(this.authorizationService.getAccountId()),
      map(([dto, ownId]) => this.messagesSerializer.deserializeConversation(dto, ownId as string)),
    );
  }

  loadConversationList(filter: AccountFilter, tab?: string): Observable<PaginatedContent<Conversation>> {
    const currentTab = tab && tab !== 'messages' ? tab : undefined;

    return this.messagesApi.loadConversationList(this.accountSerializer.serializeFilter(filter), currentTab).pipe(
      withLatestFrom(this.authorizationService.getAccountId()),
      map(([paginatedContent, ownId]) => {
        return {
          ...paginatedContent,
          items: this.messagesSerializer.deserializeConversations(
            paginatedContent.items as Array<ConversationDTO>,
            ownId as string,
          ),
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  loadConversation(id: string): Observable<Conversation> {
    return this.messagesApi.loadConversation(id).pipe(
      withLatestFrom(this.authorizationService.getAccountId()),
      map(([dto, ownId]) => this.messagesSerializer.deserializeConversation(dto, ownId as string)),
    );
  }

  sendMessage(message: MessagingMessage): Observable<boolean> {
    this.socketService.sendMessage(message);
    return of(true);
  }

  readMessage(ids: Array<string>): Observable<Array<string>> {
    this.socketService.readMessages(ids);
    return of(ids);
  }

  closeConversation(conversationId: string): Observable<boolean> {
    return this.messagesApi.closeConversation(conversationId);
  }
  deleteConversation(conversationId: string): Observable<boolean> {
    return this.messagesApi.deleteConversation(conversationId);
  }

  loadOlderMessages(conversationId: string, lastMsgId: string): Observable<Array<MessagingMessage>> {
    return this.messagesApi.loadOlderMessages(conversationId, lastMsgId).pipe(
      withLatestFrom(this.authorizationService.getAccountId()),
      map(([dtos, ownId]) => this.messagesSerializer.deserializeMessagingMessages(dtos, ownId)),
    );
  }

  getMessageSuggestionTalent(request: TalentMessageSuggest): Observable<Array<string>> {
    return this.messagesApi.getMessageSuggestionTalent(request);
  }

  getMessageSuggestionMissionPartner(request: MissionPartnerMessageSuggest): Observable<Array<string>> {
    return this.messagesApi.getMessageSuggestionMissionPartner(request);
  }

  loadConversationByAccountId(accountId: string): Observable<Conversation | null> {
    return this.messagesApi.loadConversationByAccountId(accountId).pipe(
      withLatestFrom(this.authorizationService.getAccountId()),
      map(([dto, ownId]) => {
        if (!dto) {
          return null;
        }
        return this.messagesSerializer.deserializeConversation(dto, ownId as string);
      }),
    );
  }

  getUnseenMessageCount(): Observable<number> {
    return this.messagesApi.getUnseenMessageCount();
  }

  proposeAgreementTerms(conversationId: string, terms: AgreementTerms): Observable<NegotiationStatus> {
    return this.messagesApi.proposeAgreementTerms(conversationId, this.messagesSerializer.serializeAgreementTerms(terms));
  }

  respondToAgreementTerms(conversationId: string, accepted: boolean): Observable<unknown> {
    return this.messagesApi.respondToAgreementTerms(conversationId, accepted);
  }
}
