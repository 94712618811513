import { Component, OnInit } from "@angular/core";
import { DebugService } from "./debug.service";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "mh-debug",
  templateUrl: "./debug.component.html",
  styleUrls: ["./debug.component.less"],
})
export class DebugComponent implements OnInit {
  public formGroup!: FormGroup;

  get journeyNameControl(): AbstractControl | null {
    return this.formGroup.get("journeyName");
  }

  get journeyEnabledControl(): AbstractControl | null {
    return this.formGroup.get("journeyEnabled");
  }

  constructor(private debugService: DebugService, private fb: FormBuilder) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      journeyEnabled: [this.debugService.isJourneyEnabled(), []],
      journeyName: [
        { value: this.debugService.getJourneyName(), disabled: !this.debugService.isJourneyEnabled() },
        [Validators.required],
      ],
    });
  }

  onJourneyEnabledChange() {
    this.journeyEnabledControl?.value ? this.journeyNameControl?.enable() : this.journeyNameControl?.disable();
  }

  onSave(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    if (this.journeyEnabledControl?.value) {
      this.debugService.enableNamedJourney(this.journeyNameControl?.value);
    } else {
      this.debugService.disableNamedJourney();
    }
  }
}
