import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminInvoiceListComponent } from "./admin-invoice-list.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { TranslateModule } from "@ngx-translate/core";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { RouterLink } from "@angular/router";
import { AdminInvoiceCreateModule } from "./admin-invoice-create/admin-invoice-create.module";
import { NzSelectModule } from "ng-zorro-antd/select";

@NgModule({
  declarations: [AdminInvoiceListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    AdminInvoiceCreateModule,
    NzTableModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    RouterLink,
    NzSelectModule,
    FormsModule,
  ],
})
export class AdminInvoiceListModule {}
