import { Component } from "@angular/core";
import {Router} from "@angular/router";
import {Conversation} from "../../../../api/messages/messages.types";

@Component({
  selector: "mh-talent-inbox",
  templateUrl: "./talent-inbox.component.html",
  styleUrls: ["./talent-inbox.component.less"],
})
export class TalentInboxComponent {
  constructor(private router: Router) {}

  onConversationClick(conversation: Conversation) {
    this.router.navigate(['/talent', 'inbox', 'conversation', conversation.id]);
  }
}
