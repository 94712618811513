import { talentProfileFeatureName, TalentProfileState} from "./talent-profile.reducer";
import { TalentProfileCompleteness } from "../../dashboard/dashboard.types";
import { Talent } from "../../sign-up/signup.types";

export function selectTalentProfile(state: { [talentProfileFeatureName]: TalentProfileState }): Talent | undefined {
  return state[talentProfileFeatureName].entity.data;
}

export function selectTalentProfileCompleteness(state: { [talentProfileFeatureName]: TalentProfileState; }): TalentProfileCompleteness {
  return state[talentProfileFeatureName].completeness.data;
}
