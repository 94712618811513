import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerSettingsNewsletterComponent } from "./mission-partner-settings-newsletter.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [MissionPartnerSettingsNewsletterComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzCheckboxModule,
    NzFormModule,
    NzGridModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [MissionPartnerSettingsNewsletterComponent],
})
export class MissionPartnerSettingsNewsletterModule {}
