<form nz-form nzLayout="vertical" [formGroup]="form" class="card-list">
  <ng-container formArrayName="educations">
    <div *ngIf="editingIndex === null && showAddBtn" class="header-btn">
      <button nz-button (click)="addNewEducation()" class="card-list__add-btn">
        <span nz-icon nzType="plus"></span>{{ "profile.add-truck-record.button" | translate }}
      </button>
    </div>
    <ng-container *ngFor="let educationForm of educationsFormArray.controls; let index = index">
      <ng-container [formGroupName]="index" [class.card-list__item--edit]="editingIndex !== null">
        <div
          *ngIf="editingIndex !== index"
          class="card-list__item"
          [ngClass]="{ invalid: isInvalid(educationForm.value) }"
        >
          <div class="card-list__body">
            <div nz-row>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <h4 class="card-list__item-label">{{'profile.university-name.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="educationForm.value.universityName; else noValueSpecified">{{educationForm.value.universityName}}</ng-container>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <h4 class="card-list__item-label">{{'profile.degree.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="educationForm.value.degree; else noValueSpecified">{{educationForm.value.degree.name | translate}}</ng-container>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <h4 class="card-list__item-label">{{'profile.course-of-study.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="educationForm.value.courseOfStudy; else noValueSpecified">
                  {{educationForm.value.courseOfStudy}}
                  </ng-container>
                </p>
              </div>
              <div nz-col [nzSpan]="24" [nzMd]="12">
                <h4 class="card-list__item-label">{{'profile.graduation-year.label' | translate}}</h4>
                <p class="card-list__item-value">
                  <ng-container *ngIf="educationForm.value.graduationYear; else noValueSpecified">{{educationForm.value.graduationYear | date:'yyyy'}}</ng-container>
                </p>
              </div>
            </div>
          </div>
          <div class="card-list__actions">
            <button nz-button nzType="link" (click)="toggleEditing(index)">
              <span nz-icon nzType="edit"></span>
            </button>
            <button nz-button nzType="link" nzDanger
                    (click)="confirmDeleteEducation(index)">
              <span nz-icon nzType="delete"></span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="editingIndex === index">
          <div class="card-list__item">
            <div class="card-list__body">
              <div nz-row [nzGutter]="24">
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.university-name.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <input nz-input nzSize="default" formControlName="universityName"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.degree.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-select nzSize="default" formControlName="degree" [compareWith]="compareCmsNameValue" nzShowSearch>
                        <nz-option [nzLabel]="option.name | translate" [nzValue]="option" *ngFor="let option of (degree$ | async)"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.course-of-study.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <input nz-input nzSize="default" formControlName="courseOfStudy"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col [nzSpan]="24" [nzMd]="12">
                  <nz-form-item>
                    <nz-form-label>
                      <mh-explanation-label [labelKey]="'profile.graduation-year.label'"></mh-explanation-label>
                    </nz-form-label>
                    <nz-form-control>
                      <nz-date-picker nzMode="year" [nzDisabledDate]="disabledDate" formControlName="graduationYear" [nzPlaceHolder]="'profile.graduation-year.placeholder' | translate"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzSpan]="24" class="card-list__form-buttons">
                  <button nz-button type="button" class="card-list__form-btn" [nzType]="'default'" (click)="cancelEducationEdit(index)">
                    {{ "cancel.button" | translate }}
                  </button>
                  <button nz-button type="button" class="card-list__form-btn" [nzType]="'primary'" (click)="saveEducation($event, index)">
                    {{ "save.button" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <p *ngIf="!educationsFormArray.controls.length">{{'empty.list.label' | translate}}</p>
</form>
<ng-template #noValueSpecified>-</ng-template>
