import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileEducationComponent } from "./profile-education.component";
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzFormModule} from "ng-zorro-antd/form";
import {ReactiveFormsModule} from "@angular/forms";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzSelectModule} from "ng-zorro-antd/select";
import {TranslateModule} from "@ngx-translate/core";
import {ExplanationLabelModule} from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzIconModule} from "ng-zorro-antd/icon";
import {EducationsInputModule} from "../../../../../../common-componnets/educations-input/educations-input.module";

@NgModule({
  declarations: [ProfileEducationComponent],
    imports: [CommonModule, NzPageHeaderModule, NzFormModule, ReactiveFormsModule, NzRadioModule, NzButtonModule, NzInputModule, NzDatePickerModule, NzSelectModule, TranslateModule, ExplanationLabelModule, NzDividerModule, NzIconModule, EducationsInputModule],
  exports: [
    ProfileEducationComponent
  ]
})
export class ProfileEducationModule {}
