import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ReferencesService } from "./references.service";
import { ReferenceRequest } from "./references.types";

@Injectable({
  providedIn: "root",
})
export class ReferencesRequestResolver {
  constructor(private readonly referencesService: ReferencesService) {}

  resolve(): Observable<Array<ReferenceRequest>> {
    return this.referencesService.loadReferencesRequest();
  }
}
