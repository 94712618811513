import {ChangeDetectorRef, Component, OnDestroy, ViewChild} from "@angular/core";
import { JobOffer } from "../../../../../api/job-offer/job-offer.types";
import { ActivatedRoute, Router } from "@angular/router";
import { JobOfferAction } from "../job-offer-data/store/job-offer.action";
import { MpJobOfferFormComponent } from "../mp-job-offer-form/mp-job-offer-form.component";
import { Store } from "@ngrx/store";
import { jobOfferFeature, JobOfferState } from "../job-offer-data/store/job-offer.reducer";
import {filter, map, Observable, Subject, take, takeUntil, tap} from "rxjs";
import { LoginData } from "../../../../login-page/login-page-data/login.types";
import { BreakpointObserver } from "@angular/cdk/layout";
import {AuthorizationService} from "../../../../../api/authorization/authorization.service";

@Component({
  selector: "mh-mp-job-offer-create",
  templateUrl: "./mp-job-offer-create.component.html",
  styleUrls: ["./mp-job-offer-create.component.less"],
})
export class MpJobOfferCreateComponent implements OnDestroy {
  jobOffer: JobOffer;
  loginData: LoginData;
  isSaving = false;
  isMobileView$: Observable<boolean>;
  hasReviewedStatus$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  @ViewChild(MpJobOfferFormComponent) form!: MpJobOfferFormComponent;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<{ [jobOfferFeature]: JobOfferState }>,
    private router: Router,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private authorizationService: AuthorizationService,
  ) {
    this.jobOffer = this.activatedRoute.snapshot.data["jobOffer"];
    this.loginData = this.activatedRoute.snapshot.data["loginData"];

    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 767px)"]).pipe(
      takeUntil(this.destroy$),
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
    this.hasReviewedStatus$ = this.authorizationService.hasReviewedStatus().pipe(takeUntil(this.destroy$));
  }

  validate(): boolean {
    return this.form?.isValid();
  }

  onFormChange(jobOffer: JobOffer) {
    this.jobOffer = jobOffer;
  }

  onCreate(): void {
    if (this.isSaving || !this.validate()) {
      return;
    }
    this.isSaving = true;

    this.createJobOffer(this.jobOffer, () => {
      return this.navigateToJOListPage();
    });
  }

  navigateToJOListPage(): Promise<boolean> {
    return this.router.navigate(['/', 'mission-partner', 'job-offers']);
  }

  private createJobOffer(entity: JobOffer, cb: () => void) {
    entity = {...entity, missionPartnerId: this.loginData.accountId as string};
    this.store.dispatch(JobOfferAction.createJobOffer({ entity }));

    this.store
      .select((state) => state[jobOfferFeature].entity.status)
      .pipe(
        tap((state) => (state.error ? (this.isSaving = false) : "")),
        filter((state) => !state.loading && !state.error),
        take(1),
      )
      .subscribe(() => {
        cb();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
