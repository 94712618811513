<nz-page-header [nzTitle]="'profile.education.title' | translate"></nz-page-header>
<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">

  <div class="divider-button-wrapper">
    <mh-explanation-label [labelKey]="'profile.education.title'" [divider]="true"></mh-explanation-label>
    <button class="divider-button-wrapper__btn" nz-button nzType="default" nzShape="circle" (click)="educationsInput.addNewEducation()">
      <span nz-icon nzType="plus"></span>
    </button>
  </div>

  <mh-educations-input formControlName="education" [value]="talent.education" [showAddBtn]="false" #educationsInput
                        (educationSaved)="submitForm(true)"></mh-educations-input>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.qualifications.title'" [divider]="true"></mh-explanation-label>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.qualifications.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="qualifications"
            nzMode="tags" nzDropdownClassName="hide-dropdown"
            [nzPlaceHolder]="'input.placeholder' | translate">
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <mh-explanation-label [labelKey]="'profile.languages.title'" [divider]="true"></mh-explanation-label>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.languages.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="tags"
            [nzPlaceHolder]="'select.placeholder' | translate"
            formControlName="languages" [compareWith]="compareCmsNameValue">
            <nz-option *ngFor="let option of (languageOptions$ | async)" [nzLabel]="option.name | translate" [nzValue]="option"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

  </div>
  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()" [disabled]="educationsInput.editingIndex !== null">
        {{'save-and-next.button' | translate}}
      </button>
    </div>
  </div>
</form>
