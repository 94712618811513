import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AssessmentService } from "./assessment.service";
import { MaturityAssessment } from "./assessment.types";

@Injectable({
  providedIn: "root",
})
export class MissionPartnerAssessmentResolver {
  constructor(private assessmentService: AssessmentService) {}

  resolve(): Observable<MaturityAssessment | null> {
    return this.assessmentService.getMaturityAssessmentState();
  }
}
