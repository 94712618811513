import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfilePersonalityComponent } from "./profile-personality.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NzInputModule } from "ng-zorro-antd/input";
import {ExplanationLabelModule} from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [ProfilePersonalityComponent],
  imports: [
    CommonModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzFormModule,
    NzGridModule,
    NzSelectModule,
    NzWaveModule,
    ReactiveFormsModule,
    TranslateModule,
    NzInputModule,
    ExplanationLabelModule,
  ],
  exports: [ProfilePersonalityComponent],
})
export class ProfilePersonalityModule {}
