import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssessmentDropdownSelectComponent } from "./assessment-dropdown-select.component";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NzSelectModule } from "ng-zorro-antd/select";

@NgModule({
  declarations: [AssessmentDropdownSelectComponent],
  imports: [CommonModule, NzCheckboxModule, FormsModule, TranslateModule, NzSelectModule],
  exports: [AssessmentDropdownSelectComponent],
})
export class AssessmentDropdownSelectModule {}
