<mh-widget
  [label]="'notification-settings.title' | translate"
  [value]="null"
  [backgroundColor]="'#9370DB'"
  [iconType]="'notification'"
  [iconTheme]="'fill'"
>
  <div class="widget__list">
    <div *ngFor="let item of notificationsSettings?.email" class="widget__list-item">
      <label nz-checkbox [(ngModel)]="item.enabled" name="email" class="widget__list-item-label">
        {{ "notification-settings.email.label" | translate }}
      </label>
    </div>
    <div *ngFor="let item of notificationsSettings?.sms" class="widget__list-item">
      <label nz-checkbox [(ngModel)]="item.enabled" name="sms" class="widget__list-item-label">
        {{ "notification-settings.sms.label" | translate }}
      </label>
    </div>
  </div>
  <button class="widget__more-btn" nz-button nzBlock nzType="default" nzGhost (click)="updateNotificationSettings()">
    {{ "save.button" | translate }}
  </button>
</mh-widget>
