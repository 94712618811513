<div class="col-summary">
  <h3>{{'profile.key-summary.label' | translate}}
    <a *ngIf="!editKeySummary && profileEditable" nz-button nzType="link" (click)="onEditKeySummary()"><span nz-icon nzType="edit" nzTheme="outline"></span></a>
    <ng-container *ngIf="editKeySummary">
      <button nz-button nzType="link" (click)="onCancelEditKeySummary()" [title]="'cancel.button' | translate"><span nz-icon nzType="close-circle" nzTheme="outline"></span></button>
      <button nz-button nzType="link" (click)="onUpdateKeySummary()" [title]="'save.button' | translate"><span nz-icon nzType="check-circle" nzTheme="outline"></span></button>
    </ng-container>
  </h3>
  <ng-container *ngIf="!editKeySummary">{{ talent.keySummary }}</ng-container>
  <ng-container *ngIf="editKeySummary">
    <textarea #keySummaryTextarea rows="4" nz-input [(ngModel)]="talent.keySummary" (input)="calcTextareaHeightKeySummary()"></textarea>
  </ng-container>
</div>
