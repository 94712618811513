<nz-layout class="auth auth--reset-password">
  <div nz-row class="auth__container">
    <div nz-col nzSpan="24" class="auth__content">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24"  class="auth__logo">
          <div class="auth__logo-wrap">
            <img class="logo-img" src="/assets/images/logo.svg" alt="Momhunting logo" />
          </div>
        </div>
        <ng-container *ngIf="showResetForm">
          <h1 class="auth__title" nz-col nzSpan="24">{{'password-reset.title' | translate}}</h1>
          <p class="auth__text" nz-col nzSpan="24">{{'password-reset.text1' | translate}} {{email}} {{'password-reset.text2' | translate}}</p>
          <form nz-form class="auth__form auth__form--small" [nzAutoTips]="autoTips" [formGroup]="formGroup" (ngSubmit)="onResetPasswordFormSubmit()">
            <input hidden type='text' name='email' autocomplete='username email' />
            <nz-form-label [nzSpan]="24" nzRequired>{{'password-reset.new-password.label' | translate}}</nz-form-label>
            <nz-form-control [nzSpan]="24" nzDisableAutoTips>
              <input nz-input
                     nzSize="large"
                     type="password"
                     formControlName="password"
                     autocomplete="new-password"
                     minlength="8"
                     [placeholder]="'password-reset.new-password.placeholder' | translate"
              />
              <mh-password-validation-tips [passwordControl]="password" [showBackground]="true"></mh-password-validation-tips>
            </nz-form-control>
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>{{'password-reset.confirm-password.label' | translate}}</nz-form-label>
              <nz-form-control [nzSpan]="24" nzDisableAutoTips [nzErrorTip]="'login.password.required' | translate">
                <input nz-input
                       nzSize="large"
                       type="password"
                       formControlName="confirmPassword"
                       autocomplete="new-password"
                       [placeholder]="'password-reset.confirm-password.placeholder' | translate"
                />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control [nzSpan]="24">
                <button nz-button [nzType]="isMdBreakpoint ? 'default' : 'primary'" class="ant-btn-primary" nzSize="large" nzBlock>
                  {{'password-reset.submit.btn' | translate}}
                </button>
              </nz-form-control>
            </nz-form-item>

            <div role="alert" *ngIf="errorResponse" class="auth__error-message">{{ errorResponse ?? "toast.general.error" | translate }}</div>
          </form>
        </ng-container>
        <ng-container *ngIf="!showResetForm">
          <div nz-col nzSpan="24">
            <h1 class="auth__title">{{'password-reset.success.title' | translate}}</h1>
          </div>
          <div nz-col nzSpan="24">
            <p class="auth__text">{{'password-reset.success.text' | translate}}</p>
          </div>
          <div class="auth__form auth__form--small">
            <button nz-button [nzType]="isMdBreakpoint ? 'default' : 'primary'" nzSize="large" class="ant-btn-primary" nzBlock [routerLink]="['/login']">
              {{'password-reset.success.back' | translate}}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</nz-layout>
