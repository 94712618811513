import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SignupApi } from "./signup.api";
import { MissionPartner, Talent } from "./signup.types";
import {ProfileSerializer} from "../profile/profile.serializer";

@Injectable({ providedIn: "root" })
export class SignupService {
  constructor(
    private signupApi: SignupApi,
    private profileSerializer: ProfileSerializer
  ) {}

  registerTalent(talent: Talent): Observable<string> {
    return this.signupApi.registerTalent(this.profileSerializer.serializeTalent(talent));
  }

  registerMissionPartner(missionPartner: MissionPartner): Observable<string> {
    return this.signupApi
      .registerMissionPartner(this.profileSerializer.serializeMissionPartner(missionPartner));
  }

  phoneValidationSend(phoneNumber: string): Observable<boolean> {
    return this.signupApi.phoneValidationSend(phoneNumber);
  }

  phoneValidationCheck(phoneNumber: string, verificationCode: string): Observable<boolean> {
    return this.signupApi.phoneValidationCheck(phoneNumber, verificationCode);
  }

  resendConfirmationEmail(): Observable<boolean> {
    return this.signupApi.resendConfirmationEmail();
  }

  resendConfirmationEmailChange(): Observable<boolean> {
    return this.signupApi.resendConfirmationEmailChange();
  }

  completeRegistration(): Observable<boolean> {
    return this.signupApi.completeRegistration();
  }

}
