import { Injectable } from '@angular/core';
import { Observable, take } from "rxjs";
import { NotTranslatedApi } from "./not-translated.api";
import { LOCALSTORAGE_LOCALE_KEY } from "../locale/locale.service";

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {
  constructor(private notTranslatedApi: NotTranslatedApi) {}

  notTranslated(key: string) {
    if(!key?.endsWith(".info") && localStorage.getItem(LOCALSTORAGE_LOCALE_KEY) !== "keys") {
      this.logMissingKey(key)
        .pipe(take(1))
        .subscribe();
      console.log('No translation: ', key);
    }
  }

  private logMissingKey(key: string): Observable<boolean> {
    return this.notTranslatedApi.logMissingKey(key);
  }
}
