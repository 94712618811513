<ng-container *nzModalTitle>{{'conversation.create.title' | translate}}</ng-container>

<form *ngIf="!messageSuggestions.length" nz-form [formGroup]="formGroup">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired nzFor="title">{{'message-text.label' | translate}}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Required">
      <textarea
        nz-input
        formControlName="messageText"
        id="title"
        [placeholder]="'message-text.placeholder' | translate"
        [nzAutosize]="{ minRows: 6, maxRows: 14 }"
      ></textarea>
    </nz-form-control>
  </nz-form-item>
</form>

<div *ngIf="messageSuggestions.length">
  <mh-explanation-label [labelKey]="'conversation.create.suggestions.label'"></mh-explanation-label>

  <div class="card-list">
    <div
      class="card-list__item"
      *ngFor="let messageSuggestion of messageSuggestions; let index = index"
      [class.card-list__item--active]="messageSuggestionsActive === index"
      (click)="onSelectMessageSuggestion(index)"
    >
      <div class="card-list__body">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            {{ messageSuggestion }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *nzModalFooter>
  <div class="footer-buttons">
    <div *ngIf="!messageSuggestions.length" class="suggestion-button">
      <button
        nz-button
        type="button"
        nzType="primary"
        (click)="getMessageSuggestion()"
        [nzLoading]="messageSuggestionsLoading"
      >
        {{ "message-text.suggestion.button" | translate }}
      </button>
    </div>

    <div class="action-button">
      <ng-container *ngIf="!messageSuggestions.length">
        <button nz-button nzType="default" (click)="onCancel()">
          {{ "cancel.button" | translate }}
        </button>
        <button nz-button nzType="primary" (click)="onSubmit()">
          {{ "submit.button" | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="messageSuggestions.length">
        <button
          nz-button
          type="button"
          nzType="default"
          (click)="onRejectMessageSuggestion()"
        >
          {{ "message-text.reject-suggestion.button" | translate }}
        </button>
        <button
          nz-button
          type="button"
          nzType="primary"
          [disabled]="messageSuggestionsActive === null"
          (click)="onAcceptMessageSuggestion()"
        >
          {{ "message-text.accept-suggestion.button" | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>
