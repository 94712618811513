import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CountryInputComponent } from "./country-input.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {ExplanationLabelModule} from "../explanation-label/explanation-label.module";

@NgModule({
  declarations: [CountryInputComponent],
    imports: [
        CommonModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        NzSelectModule,
        ReactiveFormsModule,
        TranslateModule,
        ExplanationLabelModule,
        FormsModule,
    ],
  exports: [
    CountryInputComponent
  ]
})
export class CountryInputModule {}
