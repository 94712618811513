import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CodeInputComponent } from "./code-input.component";
import { CodeInputComponentConfig, CodeInputComponentConfigToken } from "./code-inpur.component.config";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";

@NgModule({
  declarations: [CodeInputComponent],
  imports: [CommonModule, NzGridModule, NzInputModule, NzInputNumberModule],
  exports: [
    CodeInputComponent
  ]
})
export class CodeInputModule {
  static forRoot(config: CodeInputComponentConfig): ModuleWithProviders<CodeInputModule> {
    return {
      ngModule: CodeInputModule,
      providers: [{ provide: CodeInputComponentConfigToken, useValue: config }],
    };
  }
}
