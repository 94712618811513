import { Injectable } from "@angular/core";
import { CmsApi } from "./cms.api";
import { map, Observable } from "rxjs";
import { CmsSerializer } from "./cms.serializer";
import { CmsNameValue } from "./cms.types";

@Injectable({ providedIn: "root" })
export class CmsService {
  constructor(private cmsApi: CmsApi, private cmsSerializer: CmsSerializer) {}

  loadUserDataByName(name: string): Observable<Array<CmsNameValue>> {
    return this.cmsApi.loadUserDataByName(name).pipe(map((dtos) => this.cmsSerializer.deserializeNameValues(dtos)));
  }

  loadBox(boxName: string, locale: string): Observable<string> {
    return this.cmsApi.loadBox(boxName, locale);
  }

}
