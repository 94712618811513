import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ReferencesService} from "../../../../api/references/references.service";
import {ActivatedRoute} from "@angular/router";
import {Reference, ReferenceRequest} from "../../../../api/references/references.types";
import {TranslateService} from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";

@Component({
  selector: "mh-talent-references",
  templateUrl: "./talent-references.component.html",
  styleUrls: ["./talent-references.component.less"],
})
export class TalentReferencesComponent implements OnInit {
  references: Reference[];
  referencesRequest: ReferenceRequest[];
  public formGroup!: FormGroup;
  isSendingReference = false;
  isResendingReference = false;

  constructor(
    private readonly referencesService: ReferencesService,
    private activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
  ) {
    this.references = this.activatedRoute.snapshot.data["references"];
    this.referencesRequest = this.activatedRoute.snapshot.data["referencesRequest"];
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      company: new FormControl("", [Validators.required]),
      position: new FormControl("", [Validators.required]),
    });
  }

  submitReferenceRequest() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.isSendingReference = true;
    this.referencesService.createReferenceRequest(this.formGroup.value).subscribe((success) => {
      if (success) {
        this.referencesService.loadReferencesRequest().subscribe((referencesRequest) => {
          this.referencesRequest = referencesRequest;
          this.notificationService.success('', this.translateService.instant('references.reference-request.send.success'));
        });
        this.formGroup.reset();
      }
      this.isSendingReference = false;
    });
  }

  confirmDeleteReference(referenceId: string) {
    this.modal.confirm({
      nzTitle: this.translateService.instant('references.delete-reference.confirm.title'),
      nzContent: `<b>${this.translateService.instant('references.delete-reference.confirm.text')}</b>`,
      nzOkText: this.translateService.instant('references.delete-reference.confirm.ok.btn'),
      nzCancelText: this.translateService.instant('references.delete-reference.confirm.cancel.btn'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteReference(referenceId),
    });
  }

  deleteReference(referenceId: string) {
    this.referencesService.deleteReference(referenceId).subscribe((success) => {
      if (success) {
        this.references = this.references.filter((referenceRequest) => referenceRequest.id !== referenceId)
        this.notificationService.success('', this.translateService.instant('references.delete-reference.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  confirmDeleteReferenceRequest(referenceId: string) {
    this.modal.confirm({
      nzTitle: this.translateService.instant('references.delete-reference-request.confirm.title'),
      nzContent: `<b>${this.translateService.instant('references.delete-reference-request.confirm.text')}</b>`,
      nzOkText: this.translateService.instant('references.delete-reference-request.confirm.ok.btn'),
      nzCancelText: this.translateService.instant('references.delete-reference-request.confirm.cancel.btn'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteReferenceRequest(referenceId),
    });
  }

  deleteReferenceRequest(referenceId: string) {
    this.referencesService.deleteReferenceRequest(referenceId).subscribe((success) => {
      if (success) {
        this.referencesRequest = this.referencesRequest.filter((referenceRequest) => referenceRequest.id !== referenceId)
        this.notificationService.success('', this.translateService.instant('references.delete-reference-request.success'));
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
  }

  resendReferenceRequest(referenceId: string) {
    this.isResendingReference = true;
    this.referencesService.resendReferenceRequest(referenceId).subscribe((success) => {
      if (success) {
        this.notificationService.success('', this.translateService.instant('references.reference-request.resend.success'));
      }
      this.isResendingReference = false;
    });
  }
}
