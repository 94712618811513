<div class="profile-preview__section" id="data-space">
  <h2 class="profile-preview__section-title">
    <span nz-icon nzType="database" nzTheme="outline"></span>
    Data space attributes
  </h2>
  <div class="profile-preview__section-content">
    <ng-container *ngFor="let ds of dataSpace">
      <ng-container *ngIf="ds.attributes?.length">
        <ng-container *ngFor="let attr of ds.attributes">
          <mh-data-space-attribute-view nz-row [attr]="attr" class="profile-preview__section-content-row"></mh-data-space-attribute-view>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
