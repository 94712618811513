<nz-page-header [nzTitle]="'profile.mom-skills.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <h3>{{'profile.mom-skills.subtitle' | translate}}</h3>
  <h4>{{'profile.mom-skills.text' | translate}}</h4>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.mom-skills.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            [nzMaxMultipleCount]="4"
            (ngModelChange)="onMomSkillsChange($event)"
            [nzPlaceHolder]="'select.placeholder' | translate"
            formControlName="momSkills" [compareWith]="compareCmsNameValue">
            <nz-option *ngFor="let option of (momSkillOptions$ | async)" [nzLabel]="option.name | translate" [nzValue]="option"></nz-option>
          </nz-select>
        </nz-form-control>
        <div class="ant-form-item-explain-error" *ngIf="getMomSkillsControl()?.invalid">{{'max-allowed-num.warning' | translate:{max: 3}  }}</div>
      </nz-form-item>
    </div>
  </div>
  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save-and-next.button' | translate}}</button>
    </div>
  </div>
</form>
