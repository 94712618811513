import { AbstractControl, ValidationErrors } from "@angular/forms"

export const UrlValidator = function (control: AbstractControl): ValidationErrors | null {
  const urlRegEx = /^((https?|ftp)?(:\/\/)?(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?)$/i;

  if (control.value) {
    try {
      const pattern = new RegExp(urlRegEx);
      const valid = pattern.test(control.value);
      return valid ? null : { 'url': true };
    } catch (TypeError) {
      return null;
    }
  } else {
    return null;
  }
}
