import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocationSelectComponent } from "./location-select.component";
import { NzSelectModule } from "ng-zorro-antd/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzInputModule} from "ng-zorro-antd/input";

@NgModule({
  declarations: [LocationSelectComponent],
    imports: [CommonModule, NzSelectModule, FormsModule, NzFormModule, NzGridModule, NzInputModule, ReactiveFormsModule],
  exports: [LocationSelectComponent],
})
export class LocationSelectModule {}
