<div class="profile-preview">
  <div class="profile-preview__info">
    <div class="profile-preview__avatar">
      <nz-avatar
        class="mh-avatar mh-avatar--logo"
        [nzSrc]="missionPartner.avatarUrl"
        [nzSize]="(isMobileView$ | async) ? 150 : 180"
      ></nz-avatar>
      <ng-container *ngIf="(isMobileView$ | async) && viewerAccountType === AccountType.TALENT">
        <div *ngIf="!conversationId" class="profile-preview__action">
          <button
            nz-button
            nzType="primary"
            nzShape="circle"
            nzSize="large"
            [disabled]="!hasReviewedStatus"
            (click)="onStartConversation()"
          >
            <span nz-icon nzType="mail" nzTheme="outline"></span>
          </button>
        </div>
        <div *ngIf="conversationId" class="profile-preview__action">
          <button
            nz-button
            nzType="primary"
            nzShape="circle"
            nzSize="large"
            [routerLink]="['/talent/inbox/conversation/', conversationId]"
            [queryParams]="{ origin: 'view-micro-site', originId: missionPartner.accountId?.internalId }"
            [disabled]="!hasReviewedStatus"
          >
            <span nz-icon nzType="mail" nzTheme="outline"></span>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="profile-preview__info-col">
      <div class="profile-preview__info-top">
        <h2 class="profile-preview__name">
          {{ missionPartner.companyName }}
          <nz-tag
            *ngIf="missionPartner.statusTag"
            nzColor="#6FC4C2"
            class="profile-preview__tag"
            [title]="missionPartner.statusTag"
          >
            {{ missionPartner.statusTag | translate }}
          </nz-tag>
          <button
            *ngIf="viewerAccountType === AccountType.ADMIN"
            class="profile-preview__sign-in-as"
            nz-button
            nzType="link"
            nzSize="small"
            [nzTooltipTitle]="'admin.sign-in-as.tooltip' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            (click)="onSignInAs()"
          >
            <span nz-icon nzType="login" nzTheme="outline"></span>
          </button>
        </h2>

        <ng-container *ngIf="(isMobileView$ | async) === false && viewerAccountType === AccountType.TALENT">
          <div *ngIf="!conversationId" class="profile-preview__action">
            <button nz-button nzType="primary" [disabled]="!hasReviewedStatus" (click)="onStartConversation()">
              {{ "profile.start-conversation.button" | translate }}
            </button>
          </div>
          <div *ngIf="conversationId" class="profile-preview__action">
            <button
              nz-button
              nzType="primary"
              [routerLink]="['/talent/inbox/conversation/', conversationId]"
              [queryParams]="{ origin: 'view-micro-site', originId: missionPartner.accountId?.internalId }"
              [disabled]="!hasReviewedStatus"
            >
              {{ "profile.to-conversation.button" | translate }}
            </button>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="missionPartner.companyOverview?.numberOfEmployees">
        <p>
          {{ "profile.mission-partner.numberOfEmployees.label" | translate }}
          <span class="profile-preview__info-value profile-preview__number-of-employees">
            {{ missionPartner.companyOverview!.numberOfEmployees.name | translate }}
          </span>
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.profilePresentationExternalLink">
        <p>
          <a [href]="externalLink" target="_blank">{{ missionPartner.profilePresentationExternalLink }}</a>
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.welcomeMessage">
        <h4 class="profile-preview__welcome-message-title">
          {{ "profile.mission-partner.welcome-message.label" | translate }}
        </h4>
        <p>{{ missionPartner.welcomeMessage }}</p>
      </ng-container>
    </div>
  </div>

  <nav class="profile-preview__nav">
    <mh-micro-site-navigation [isMobile]="isMobileView$ | async"></mh-micro-site-navigation>
  </nav>

  <div *ngIf="presentationMediaUrl" class="profile-preview__media-section">
    <h2 class="profile-preview__section-title">
      {{ "profile.mission-partner.about-company.title" | translate }}
    </h2>
    <div class="profile-preview__media">
      <audio *ngIf="mediaType === MediaType.AUDIO" controls [src]="presentationMediaUrl"></audio>
      <video *ngIf="mediaType === MediaType.VIDEO" controls [src]="presentationMediaUrl"></video>
    </div>
  </div>

  <div class="profile-preview__section" id="contact-person">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="user" nzTheme="outline"></span>
      {{ "profile.mission-partner.contact-person.title" | translate }}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="missionPartner.contactPerson?.title">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.contact-person.title.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.contactPerson?.title }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.contactPerson?.firstName">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.contact-person.firstname.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.contactPerson?.firstName }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.contactPerson?.lastName">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.contact-person.lastname.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.contactPerson?.lastName }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.contactPerson?.department">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.contact-person.department.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.contactPerson?.department }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.email">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.email.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.email }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.contactPerson?.phoneNumber">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.contact-person.phone.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.contactPerson?.phoneNumber }}
        </p>
      </ng-container>
    </div>
  </div>

  <!-- Empty sections are hidden in talent-profile-preview.less.-->
  <div class="profile-preview__section" id="company-name">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="bank" nzTheme="outline"></span>
      {{ "profile.mission-partner.company-overview.title" | translate }}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="missionPartner.companyName">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.company-name.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.companyName }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.street || missionPartner.houseNumber || missionPartner.address">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.address.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <span *ngIf="missionPartner.street">{{ missionPartner.street }}, </span>
          <span *ngIf="missionPartner.houseNumber">{{ missionPartner.houseNumber }}, </span>
          <mh-location-label [location]="missionPartner.address"></mh-location-label>
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.companyOverview?.numberOfEmployees">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.numberOfEmployees.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.companyOverview!.numberOfEmployees.name | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.companyOverview?.companyIndustry">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.companyIndustry.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.companyOverview!.companyIndustry.name | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.companyOverview?.companySector">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.companySector.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.companyOverview!.companySector.name | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.companyOverview?.companyType">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.companyType.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.companyOverview!.companyType.name | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.companyOverview?.companyHeadquarters">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.companyHeadquarters.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.companyOverview!.companyHeadquarters.name | translate }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.companyOverview?.companyHeadquartersText">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.companyHeadquartersText.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.companyOverview!.companyHeadquartersText }}
        </p>
      </ng-container>

      <ng-container *ngIf="missionPartner.hashtags?.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.mission-partner.hashtags.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="missionPartner.hashtags"></mh-tags-output>
        </p>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="missionPartner.statuses?.includes(AccountStatus.REVIEWED)"
    class="profile-preview__section"
    id="diversities"
  >
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="global" nzTheme="outline"></span>
      {{ "mp.diversities.title" | translate }}
    </h2>

    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="missionPartner.diversities.diversity1">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-1.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity1 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity2">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-2.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity2 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity3">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-3.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity3 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity4">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-4.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity4 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity5">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-5.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity5 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity6">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-6.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity6 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity7">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-7.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity7 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity8">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-8.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity8 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity9">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-9.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity9 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.diversities.diversity10">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.diversity.question-10.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.diversities.diversity10 }}
        </p>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="missionPartner.statuses?.includes(AccountStatus.REVIEWED)"
    class="profile-preview__section"
    id="compliance-kpi"
  >
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="line-chart" nzTheme="outline"></span>
      {{ "mp.compliance-kpi.title" | translate }}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="missionPartner.complianceKpi.kpiPossibleWorkingAssignments.length">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.work-assignment.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <mh-tags-output [value]="missionPartner.complianceKpi.kpiPossibleWorkingAssignments"></mh-tags-output>
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiPercentagePartTimeLeadership">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.part-time-leadership.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <ng-container *ngIf="missionPartner.complianceKpi.kpiPercentagePartTimeLeadership === -1">
            {{ "kpi-percentage.unknown" | translate }}
          </ng-container>
          <ng-container *ngIf="missionPartner.complianceKpi.kpiPercentagePartTimeLeadership !== -1">
            {{ missionPartner.complianceKpi.kpiPercentagePartTimeLeadership }}%
          </ng-container>
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiPercentageFemaleLeadership">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.female-leadership.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          <ng-container *ngIf="missionPartner.complianceKpi.kpiPercentageFemaleLeadership === -1">
            {{ "kpi-percentage.unknown" | translate }}
          </ng-container>
          <ng-container *ngIf="missionPartner.complianceKpi.kpiPercentageFemaleLeadership !== -1">
            {{ missionPartner.complianceKpi.kpiPercentageFemaleLeadership }}%
          </ng-container>
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion1">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-1.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion1 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion2">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-2.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion2 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion3">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-3.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion3 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion4">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-4.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion4 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion5">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-5.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion5 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion6">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-6.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion6 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion7">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-7.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion7 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion8">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-8.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion8 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion9">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-9.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion9 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner.complianceKpi.kpiQuestion10">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "profile.compliance-kpi.question-10.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.complianceKpi.kpiQuestion10 }}
        </p>
      </ng-container>
    </div>
  </div>

  <div class="profile-preview__section" id="invoice-details" *ngIf="viewerAccountType === AccountType.ADMIN">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="credit-card" nzTheme="outline"></span>
      {{ "mp.profile.invoice-details.sub-title" | translate }}
    </h2>
    <div nz-row class="profile-preview__section-content">
      <ng-container *ngIf="missionPartner?.invoiceAddress?.companyName">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.company-name.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.companyName }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.contactPersonName">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.contact-person-name.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.contactPersonName }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.reference">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.reference.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.reference }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.costCenter">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.cost-center.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.costCenter }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.addressLine1">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.address-line-1.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.addressLine1 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.addressLine2">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.address-line-2.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.addressLine2 }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.postalCode">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.postal-code.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.postalCode }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.city">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.city.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.city }}
        </p>
      </ng-container>
      <ng-container *ngIf="missionPartner?.invoiceAddress?.country">
        <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
          {{ "mp.profile.country.label" | translate }}
        </h4>
        <p nz-col [nzSpan]="24" [nzMd]="18">
          {{ missionPartner.invoiceAddress!.country }}
        </p>
      </ng-container>
    </div>
  </div>

  <div class="profile-preview__section" id="references" *ngIf="testimonials.length">
    <h2 class="profile-preview__section-title">
      <span nz-icon nzType="solution" nzTheme="outline"></span>
      {{ "mp.profile.testimonials.title" | translate }}
    </h2>
    <mh-micro-site-testimonials
      [trustedView]="true"
      [testimonials]="testimonials"
      [testimonialsRequest]="testimonialsRequest"
      [viewerAccountType]="viewerAccountType"
    ></mh-micro-site-testimonials>
  </div>
  <!-- Testimonials -->
</div>
