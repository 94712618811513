import { Component, Inject } from "@angular/core";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { JobOfferTalentComment, MatchedTalent } from "../../../../../api/job-offer/job-offer.types";
import { take } from "rxjs";
import { AccountService } from "../../../home-admin-data/account.service";

@Component({
  selector: "mh-admin-job-offer-talent-comments-dialog",
  templateUrl: "./admin-job-offer-talent-comments-dialog.component.html",
  styleUrls: ["./admin-job-offer-talent-comments-dialog.component.less"],
})
export class AdminJobOfferTalentCommentsDialogComponent {
  jobOfferId: string;
  talent: MatchedTalent;
  comments: JobOfferTalentComment[] = [];

  constructor(
    private accountService: AccountService,
    @Inject(NZ_MODAL_DATA) public modalData: any
  ) {
    this.jobOfferId = this.modalData["jobOfferId"];
    this.talent = this.modalData["talent"];
  }

  ngOnInit() {
    this.accountService.loadJobOfferTalentComments(this.jobOfferId, this.talent.accountId)
      .pipe(take(1))
      .subscribe((comments) => {
        this.comments = comments;
      });
  }

  onSendComment(text: string) {
    this.accountService.sendJobOfferTalentComment(this.jobOfferId, this.talent.accountId, text)
      .pipe(take(1))
      .subscribe((comment) => {
        if (comment) {
          this.comments = [...this.comments, comment];
          this.talent.hasComments = true;
        }
      });
  }

  onUpdateComment(event: { id: string, text: string }) {
    this.accountService.updateJobOfferTalentComment(event.id, event.text)
      .pipe(take(1))
      .subscribe((comment) => {
        if (comment) {
          this.comments = this.comments.map((c) => c.id === comment.id ? comment : c);
        }
      });
  }

  onDeleteComment(commentId: string) {
    this.accountService.deleteJobOfferTalentComment(this.jobOfferId, commentId)
      .pipe(take(1))
      .subscribe((success) => {
        if (success) {
          this.comments = this.comments.filter((comment) => comment.id !== commentId);
          if (!this.comments.length) {
            this.talent.hasComments = false;
          }
        }
      });
  }
}
