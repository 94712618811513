import { Injectable } from '@angular/core';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import * as RecordRTC from 'recordrtc';
import { Observable, Subject } from 'rxjs';
import { RecordedAudioOutput } from "./recording.types";

@Injectable()
export class AudioRecordingService {
  private stream: MediaStream | null = null;
  private recorder: any;
  private interval: any;
  private startTime: Date | null = null;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      return;
    }

    this._recordingTime.next('00:00');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((s) => {
        this.stream = s;
        this.record();
      })
      .catch((error) => {
        this._recordingFailed.next('Recording failed');
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    if (!this.stream) {
      return;
    }
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/wav',
    });

    this.recorder.record();
    this.startTime = new Date();
    this.interval = setInterval(() => {
      const currentTime = new Date();
      const diffTimeMinutes = differenceInMinutes(currentTime, this.startTime!);
      const diffTimeSeconds = differenceInSeconds(currentTime, this.startTime!) % 60;
      const time =
        this.toString(diffTimeMinutes) +
        ':' +
        this.toString(diffTimeSeconds);
      this._recordingTime.next(time);
    }, 500);
  }

  private toString(value: number | string): string {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (+value < 10) {
      val = '0' + value;
    }
    return val.toString();
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop(
        (blob: Blob) => {
          if (this.startTime) {
            const wavName = encodeURIComponent(
              'audio_' + new Date().getTime() + '.wav'
            );
            this.stopMedia();
            this._recorded.next({ blob: blob, title: wavName });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next('Recording failed');
        }
      );
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track: MediaStreamTrack) => track.stop());
        this.stream = null;
      }
    }
  }
}
