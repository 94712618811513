import {combineReducers, createReducer, on} from "@ngrx/store";
import {
  JobOffer,
  JobOpportunitySearchResultStatus,
  MatchedTalent,
  MatchedTalentStatus
} from "../../../../../../api/job-offer/job-offer.types";
import {JobOfferAction} from "./job-offer.action";
import {initialPaginatedContent, PaginatedContent} from "../../../../../../api/common.types";
import {MessagesAction} from "../../../../../../common-componnets/messages/store/messages.action";
import {Conversation} from "../../../../../../api/messages/messages.types";

export const jobOfferFeature = "jobOffers";

export type StatusState = {
  loaded: boolean;
  loading: boolean;
  error: unknown;
};

export type JobOfferListState = {
  status: StatusState;
  data: Array<JobOffer>;
};

export type JobOfferEntityState = {
  status: StatusState;
  data: JobOffer | undefined;
};
export type JobOfferMatchedTalentListState = {
  status: StatusState;
  data: PaginatedContent<MatchedTalent>;
};

const initialStatusState: StatusState = {
  loading: false,
  loaded: false,
  error: null,
};

export const JobOfferListReducer = createReducer<JobOfferListState>(
  { status: initialStatusState, data: [] },
  on(JobOfferAction.loadJobOfferList, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(JobOfferAction.loadJobOfferListSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data,
    };
  }),
  on(JobOfferAction.loadJobOfferListSuccessError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(JobOfferAction.archiveJobOfferSuccess, (state, { entity }) => {
    return {
      ...state,
      data: updateList(state.data, entity),
    };
  }),
  on(JobOfferAction.unArchiveJobOfferSuccess, (state, { entity }) => {
    return {
      ...state,
      data: updateList(state.data, entity),
    };
  }),
  on(JobOfferAction.talentMatchSearchStarted, (state, { jobOfferId }) => {
    return {
      ...state,
      data: updateJOListItem(state.data, {id: jobOfferId, status: JobOpportunitySearchResultStatus.PENDING}),
    };
  }),
  on(JobOfferAction.talentMatchSearchSuccess, (state, { jobOfferId }) => {
    return {
      ...state,
      data: updateJOListItem(state.data, {id: jobOfferId, status: JobOpportunitySearchResultStatus.DONE}),
    };
  }),
);

export const updateList = (list: Array<JobOffer>, newItem: JobOffer): Array<JobOffer> => {
  const candidatesUpdate = [...list];
  const indexToUpdate = candidatesUpdate.findIndex((item) => item.id === newItem.id);
  if (indexToUpdate !== -1) {
    candidatesUpdate[indexToUpdate] = newItem;
  }
  return candidatesUpdate;
};

export const updateJOListItem = (
  list: Array<JobOffer>,
  itemUpdate: Partial<JobOffer>,
): Array<JobOffer> => {
  const listUpdate = [...list];
  const indexToUpdate = listUpdate.findIndex((item) => item.id === itemUpdate.id);
  if (indexToUpdate !== -1) {
    listUpdate[indexToUpdate] = {
      ...listUpdate[indexToUpdate],
      ...itemUpdate
    };
  }
  return listUpdate;
};

export const removeFromList = (jobOffers: Array<JobOffer>, id: string): Array<JobOffer> => {
  return jobOffers.filter((jo) => jo.id !== id);
};

export const JobOfferEntityReducer = createReducer<JobOfferEntityState>(
  { status: initialStatusState, data: undefined },
  on(JobOfferAction.loadJobOffer, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(JobOfferAction.loadJobOfferSuccess, (state, { data }) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data,
    };
  }),
  on(JobOfferAction.loadJobOfferError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),

  on(JobOfferAction.createJobOffer, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(JobOfferAction.createJobOfferSuccess, (state, { data }) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data,
    };
  }),
  on(JobOfferAction.createJobOfferError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(JobOfferAction.archiveJobOffer, (state, action) => ({
    ...state,
    status: { loading: true, loaded: false, error: null },
  })),
  on(JobOfferAction.archiveJobOfferSuccess, (state, action) => ({
    ...state,
    status: { loading: false, loaded: true, error: null },
  })),
  on(JobOfferAction.archiveJobOfferError, (state, { error }) => ({
    ...state,
    status: { loading: false, loaded: false, error },
  })),
  on(JobOfferAction.updateJobOffer, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(JobOfferAction.updateJobOfferSuccess, (state, { data }) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data,
    };
  }),
  on(JobOfferAction.updateJobOfferError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(JobOfferAction.unArchiveJobOffer, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(JobOfferAction.unArchiveJobOfferSuccess, (state, { entity }) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      entity,
    };
  }),
  on(JobOfferAction.unArchiveJobOfferError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
);

export const JobOfferMatchedTalentListReducer = createReducer<JobOfferMatchedTalentListState>(
  { status: initialStatusState, data: initialPaginatedContent },
  on(JobOfferAction.loadJobOfferMatchedTalentList, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(JobOfferAction.loadJobOfferMatchedTalentListSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data,
    };
  }),
  on(JobOfferAction.loadJobOfferMatchedTalentListError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(JobOfferAction.updateJobOfferMatchedTalentSuccess, (state, { entity }) => {
    return {
      ...state,
      data: { ...state.data, items: updateMatchesList(state.data.items, entity) },
    };
  }),
  on(MessagesAction.initiateConversationSuccess, (state, { data }) => {
    return {
      ...state,
      data: { ...state.data, items: setMatchedTalentConversation(state.data.items, data) },
    };
  }),
  on(JobOfferAction.archiveJobOfferMatchSuccess, (state, { entity }) => {
    return {
      ...state,
      data: { ...state.data,
        items: state.data.items.map(match => {
          if(match.accountId === entity.accountId) {
            return {
              ...match,
              status: [...match.status, MatchedTalentStatus.ARCHIVED],
            };
          }
          return match;
        }),
    }
    };
  }),
  on(JobOfferAction.unArchiveJobOfferMatchSuccess, (state, { entity }) => {
    return {
      ...state,
      data: { ...state.data,
        items: state.data.items.map(match => {
          if(match.accountId === entity.accountId) {
            return {
              ...match,
              status: match.status.filter((status) => status !== MatchedTalentStatus.ARCHIVED)
            };
          }
          return match;
        }),
      },
    };
  }),
);

export const updateMatchesList = (list: Array<MatchedTalent>, newItem: MatchedTalent): Array<MatchedTalent> => {
  const matchesUpdate = [...list];
  const indexToUpdate = matchesUpdate.findIndex((item) => item.accountId === newItem.accountId);
  if (indexToUpdate !== -1) {
    matchesUpdate[indexToUpdate] = newItem;
  }
  return matchesUpdate;
};

export const markHasCommentJobOfferMatchedTalent = (list: Array<MatchedTalent>, newItem: MatchedTalent): Array<MatchedTalent> => {
  const matchesUpdate = [...list];
  const indexToUpdate = matchesUpdate.findIndex((item) => item.accountId === newItem.accountId);
  if (indexToUpdate !== -1) {
    matchesUpdate[indexToUpdate] = newItem;
  }
  return matchesUpdate;
};

export const setMatchedTalentConversation = (
  list: Array<MatchedTalent>,
  conversation: Conversation,
): Array<MatchedTalent> => {
  const matchesUpdate = [...list];
  const indexToUpdate = matchesUpdate.findIndex((item) => item.accountId === conversation.otherParticipantId);
  if (indexToUpdate !== -1) {
    matchesUpdate[indexToUpdate] = {
      ...matchesUpdate[indexToUpdate],
      status: [...matchesUpdate[indexToUpdate].status, MatchedTalentStatus.IN_COMMUNICATION],
      conversationId: conversation.id,
    };
  }
  return matchesUpdate;
};

export interface JobOfferState {
  list: JobOfferListState;
  entity: JobOfferEntityState;
  matches: JobOfferMatchedTalentListState;
}

export const JobOfferReducer = combineReducers<JobOfferState>({
  entity: JobOfferEntityReducer,
  list: JobOfferListReducer,
  matches: JobOfferMatchedTalentListReducer,
});
