import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { filter, map, Observable, Subject, takeUntil, tap } from "rxjs";
import { LoginData } from "../login-page/login-page-data/login.types";
import { AuthorizationService } from "../../api/authorization/authorization.service";
import { LoginService } from "../login-page/login-page-data/login.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { NavigationEnd, Router } from "@angular/router";
import { NavItem } from "../../common-componnets/common-types";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-home-mission-partner",
  templateUrl: "./home-mission-partner.component.html",
  styleUrls: ["./home-mission-partner.component.less"],
})
export class HomeMissionPartnerComponent implements OnDestroy {
  user$!: Observable<LoginData>;
  isMobileView$: Observable<boolean>;
  destroy$ = new Subject<void>();

  height: number = document.documentElement.clientHeight - 60;
  state = {
    open: false,
  };

  navigationBar: Array<NavItem> = [
    { icon: "appstore", nameKey: "navi.dashboard.label", routerLink: ["dashboard"] },
    { icon: "", nameKey: "mission-partner.my-profile.label", routerLink: ["profile"] },
    { icon: "", nameKey: "mission-partner.my-visits.label", routerLink: ["visits"] },
    { icon: "", nameKey: "mission-partner.job-offers.label", routerLink: ["job-offers"] },
    {
      icon: "",
      nameKey: "navi.inbox.label",
      routerLink: ["inbox"],
      queryParams: { tab: "messages" },
      badgeType: "unseenMessages",
    },
    { icon: "", nameKey: "mission-partner.assessment.label", routerLink: ["assessment"] },
    { icon: "", nameKey: "mission-partner.invoices.label", routerLink: ["invoices"] },
    { icon: "", nameKey: "mission-partner.testimonials.label", routerLink: ["testimonials"] },
  ];

  navigationDropdown: Array<NavItem> = [
    { icon: "", nameKey: "navi.settings.label", routerLink: ["settings"] },
    { icon: "", nameKey: "navi.notification-settings.label", routerLink: ["notifications"] },
  ];
  navigation = [...this.navigationBar, ...this.navigationDropdown];

  currentNavItem?: NavItem;

  constructor(
    private loginService: LoginService,
    private authorizationService: AuthorizationService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.user$ = this.authorizationService.getAuthorizedUser();
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap(() => {
        this.changeDetectorRef.detectChanges();
      }),
    );
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        tap((event) => {
          this.handleRouteChange(event as NavigationEnd);
        }),
      )
      .subscribe();
  }

  onLogout() {
    this.loginService.logout();
  }

  toggleDrawer(navItem?: NavItem) {
    if (navItem) this.currentNavItem = navItem;
    this.state.open = !this.state.open;
  }

  handleRouteChange(event: NavigationEnd) {
    const activeRouteUrl = event.url;
    this.navigation.forEach((navItem) => {
      if (activeRouteUrl.includes(navItem.routerLink.join("/"))) {
        this.currentNavItem = navItem;
        this.titleService.setTitle(
          this.translateService.instant("page.title", { pageName: this.translateService.instant(navItem.nameKey) }),
        );
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
