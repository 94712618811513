import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RegisterMissionPartnerComponent } from "./register-mission-partner.component";
import { RouterLink } from "@angular/router";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { SignupEmailConfirmModule } from "../register-common-steps/signup-email/signup-email-confirm.module";
import { SignupTalentGeneralModule } from "../register-talent/steps/signup-talent-general/signup-talent-general.module";
import { SignupTalentJobPreferencesModule } from "../register-talent/steps/signup-talent-job-preferences/signup-talent-job-preferences.module";
import { SignupPhoneVerifyModule } from "../register-common-steps/signup-phone/signup-phone-verify.module";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {
  SignupMissionPartnerGeneralModule
} from "./steps/signup-mision-partner-general/signup-mission-partner-general.module";
import {LocaleSelectModule} from "@momhunting/common-ui";
import {
    SignupMissionPartnerContactModule
} from "./steps/signup-mission-partner-contact/signup-mission-partner-contact.module";
import {
  SignupMissionPartnerDetailsModule
} from "./steps/signup-mission-partner-details/signup-mission-partner-details.module";
import {
    ScrollToValidationErrorModule
} from "../../common-componnets/scroll-to-validation-error/scroll-to-validation-error.module";

@NgModule({
  declarations: [RegisterMissionPartnerComponent],
    imports: [
        CommonModule,
        RouterLink,
        NzImageModule,
        NzLayoutModule,
        NzGridModule,
        NzStepsModule,
        SignupEmailConfirmModule,
        SignupTalentGeneralModule,
        SignupTalentJobPreferencesModule,
        SignupPhoneVerifyModule,
        NzButtonModule,
        NzCheckboxModule,
        NzFormModule,
        NzInputModule,
        NzWaveModule,
        ReactiveFormsModule,
        TranslateModule,
        SignupMissionPartnerGeneralModule,
        LocaleSelectModule,
        SignupMissionPartnerContactModule,
        SignupMissionPartnerDetailsModule,
        ScrollToValidationErrorModule,
    ],
})
export class RegisterMissionPartnerModule {}
