import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfilePreviewNavigationComponent } from "./profile-preview-navigation.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzAnchorModule } from "ng-zorro-antd/anchor";

@NgModule({
  declarations: [ProfilePreviewNavigationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzAnchorModule,
  ],
  exports: [ProfilePreviewNavigationComponent],
})
export class ProfilePreviewNavigationModule {}
