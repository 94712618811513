import {Component, Inject, ViewChild} from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { Talent } from "../../api/sign-up/signup.types";
import {Store} from "@ngrx/store";
import {talentProfileFeatureName, TalentProfileState} from "../../api/profile/store/talent-profile.reducer";
import * as TalentProfileActions from "../../api/profile/store/talent-profile.action";
import {filter, map, take} from "rxjs";
import {EducationsInputComponent} from "../../common-componnets/educations-input/educations-input.component";
import {TrackRecordsInputComponent} from "../../common-componnets/track-record-input/track-records-input.component";

@Component({
  selector: "mh-review-parsed-cv-dialog",
  templateUrl: "./review-parsed-cv-dialog.component.html",
  styleUrls: ["./review-parsed-cv-dialog.component.less"],
})
export class ReviewParsedCvDialogComponent {
  parsedCV: Talent;
  talent: Talent;

  loading = false;

  @ViewChild(EducationsInputComponent) educationsInputComponent!: EducationsInputComponent;
  @ViewChild(TrackRecordsInputComponent) trackRecordsInputComponent!: TrackRecordsInputComponent;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private readonly store: Store<{ [talentProfileFeatureName]: TalentProfileState }>,
  ) {
    this.parsedCV = this.modalData["parsedCV"];
    this.talent = this.modalData["talent"];
  }

  onSave(): void {
    this.loading = true;
    this.store.dispatch(TalentProfileActions.updateTalentProfile({data:
        {
          ...this.talent,
          education: [...this.talent.education, ...this.educationsInputComponent.value],
          trackRecord: [...this.talent.trackRecord, ...this.trackRecordsInputComponent.value]
        }
    }));
    this.store
      .select((state) => state[talentProfileFeatureName].entity)
      .pipe(
        filter((entity) => entity.status.loaded),
        map((entity) => entity.data),
        take(1),
      )
      .subscribe((candidate) => {
        this.loading = false;
        this.modal.close();
      });
  }

  onCancel(): void {
    this.modal.close();
  }
}
