import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminTalentStatisticComponent } from "./admin-talent-statistic.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzIconModule } from "ng-zorro-antd/icon";
import {TagsOutputModule} from "../../../../../../../../common-ui/src/lib/tags-output/tags-output.module";
import {
  MpJobOfferItemModule
} from "../../../../home-mission-partner/subpages/mission-partner-job-offers/mp-job-offer-list/mp-job-offer-item/mp-job-offer-item.module";
import {NzGridModule} from "ng-zorro-antd/grid";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [AdminTalentStatisticComponent],
  imports: [CommonModule, NzButtonModule, NzPopoverModule, NzIconModule, TagsOutputModule, MpJobOfferItemModule, NzGridModule, TranslateModule],
  exports: [AdminTalentStatisticComponent],
})
export class AdminTalentStatisticModule {}
