import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerSettingsPasswordComponent } from "./mission-partner-settings-password.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { PasswordValidationTipsModule } from "../../../../../../../../common-ui/src/lib/password-validation-tips/password-validation-tips.module";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [MissionPartnerSettingsPasswordComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzWaveModule,
    PasswordValidationTipsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [MissionPartnerSettingsPasswordComponent],
})
export class MissionPartnerSettingsPasswordModule {}
