import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MissionPartner } from "../../api/sign-up/signup.types";
import { differenceInYears } from "date-fns";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { AccountStatus, AccountType } from "../../pages/login-page/login-page-data/login.types";
import { map, Observable, tap } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MediaType } from "../../../../../core/src/lib/recording/recording.types";
import { CommonService } from "../../api/common.service";
import { Reference, ReferenceRequest } from "../../api/references/references.types";

@Component({
  selector: "mh-micro-site",
  templateUrl: "./micro-site.component.html",
  styleUrls: ["./micro-site.component.less"],
})
export class MicroSiteComponent implements OnInit {
  @Input() missionPartner!: MissionPartner;
  @Input() conversationId?: string;
  @Input() viewerAccountType?: AccountType;
  @Input() testimonials: Reference[] = [];
  @Input() testimonialsRequest: ReferenceRequest[] = [];
  @Output() startConversation = new EventEmitter();
  @Output() signInAs = new EventEmitter<void>();
  presentationMediaUrl = '';
  mediaType?: MediaType;
  isMobileView$: Observable<boolean>;
  
  protected readonly AccountStatus = AccountStatus;

  get externalLink(): string {
    if (this.missionPartner.profilePresentationExternalLink) {
      const hasProtocol = this.missionPartner.profilePresentationExternalLink.startsWith('http') || this.missionPartner.profilePresentationExternalLink.startsWith('ftp');
      return hasProtocol ? this.missionPartner.profilePresentationExternalLink : `//${this.missionPartner.profilePresentationExternalLink}`;
    }
    return '';
  }

  get hasReviewedStatus (): boolean {
    return !!this.missionPartner.statuses?.includes(AccountStatus.REVIEWED);
  }

  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
  ) {
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
        map((breakpoint) => breakpoint.matches),
        tap((mobileView) => {
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  ngOnInit() {
    this.presentationMediaUrl = this.missionPartner.presentationMedia?.fileUrl ? `${this.appConfig.siteUrl}/${this.missionPartner.presentationMedia?.fileUrl}` : '';
    this.mediaType = this.commonService.getFileType(this.missionPartner.presentationMedia?.name || '');
  }

  calculateAge(birthDate: Date): number {
    const currentDate = new Date();
    return differenceInYears(currentDate, birthDate);
  }

  onStartConversation(): void {
    this.startConversation.emit();
  }

  onSignInAs() {
    this.signInAs.emit();
  }

  protected readonly AccountType = AccountType;
  protected readonly MediaType = MediaType;
}
