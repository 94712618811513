<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <ng-container *ngIf="changePasswordMode">
    <div nz-row nzGutter="24">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired>{{ "settings.current-password.label" | translate }}</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzSize="default"
              [placeholder]="'settings.current-password.placeholder' | translate"
              type="password"
              minlength="8"
              formControlName="currentPassword"
              autocomplete="new-password"
              #passwordInputEl
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzGutter="24">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired>{{ "settings.new-password.label" | translate }}</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              nzSize="default"
              [placeholder]="'settings.new-password.placeholder' | translate"
              type="password"
              minlength="8"
              formControlName="newPassword"
              autocomplete="new-password"
              (ngModelChange)="updateConfirmValidator()"
            />
            <mh-password-validation-tips [passwordControl]="newPassword"></mh-password-validation-tips>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzGutter="24">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired>{{ "settings.confirm-new-password.label" | translate }}</nz-form-label>
          <nz-form-control [nzErrorTip]="pwConfirmErrorTpl">
            <input
              nz-input
              nzSize="default"
              [placeholder]="'settings.confirm-new-password.placeholder' | translate"
              type="password"
              minlength="8"
              formControlName="newPasswordConfirmation"
              autocomplete="new-password"
            />
            <ng-template #pwConfirmErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">{{
                "settings.password-confirm.required.validation" | translate
                }}</ng-container>
              <ng-container *ngIf="control.hasError('confirm')">{{
                "settings.password-confirm.confirm.validation" | translate
                }}</ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <button nz-button [nzType]="'default'" nzBlock *ngIf="!changePasswordMode" (click)="onChangePassword()">
        {{ "change-password.button" | translate }}
      </button>
      <button nz-button [nzType]="'primary'" nzBlock *ngIf="changePasswordMode"
              (click)="onSavePassword()" [disabled]="loading">
        {{ "save.button" | translate }}
      </button>
    </div>
  </div>
</form>
