import { createAction, props } from "@ngrx/store";
import { MissionPartner } from "../../sign-up/signup.types";

export const loadMissionPartnerProfile = createAction("[MissionPartner profile] Load profile");
export const loadMissionPartnerProfileSuccess = createAction("[MissionPartner profile][Success] Load profile", props<{ data: MissionPartner }>());
export const loadMissionPartnerProfileError = createAction("[MissionPartner profile][Error] Load profile", props<{ error: unknown }>());


export const updateStoreMissionPartnerProfile = createAction("[MissionPartner profile] Update profile", props<{ data: MissionPartner }>());

export const updateMissionPartnerProfile = createAction("[MissionPartner profile] Update store profile", props<{ data: MissionPartner }>());
export const updateMissionPartnerProfileSuccess = createAction("[MissionPartner profile][Success] Update profile", props<{ data: MissionPartner }>());
export const updateMissionPartnerProfileError = createAction("[MissionPartner profile][Error] Update profile", props<{ error: unknown }>());
