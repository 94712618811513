<form class="admin-filters" nz-form [formGroup]="searchForm" (ngSubmit)="submitFilterForm()">
  <div class="admin-filters__fields">
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input type="text" formControlName="searchTerm" nz-input [placeholder]="'admin.filter.search.placeholder' | translate" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span *ngIf="!searchForm.get('searchTerm')?.value" nz-icon nzType="search"></span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchForm.get('searchTerm')?.value"
            (click)="searchForm.get('searchTerm')?.setValue(null)"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="admin-filters__actions">
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" [disabled]="!searchForm.valid">{{'admin.filter.submit' | translate}}</button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="default" type="button" (click)="openCreateAdminModal()">
          {{'admin.user-admin.create.open-modal' | translate}}
        </button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="accountList?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="accountList?.paging?.items || 0"
  [nzPageSize]="accountList?.paging?.itemsOnPage || 0"
  [nzPageIndex]="accountList?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th nzColumnKey="NAME" [nzSortFn]="true">{{'admin.name.title' | translate}}</th>
    <th nzColumnKey="EMAIL" [nzSortFn]="true">{{'admin.email.title' | translate}}</th>
    <th nzColumnKey="REG_TIMESTAMP" [nzSortFn]="true" nzSortOrder="descend">{{'admin.registered.title' | translate}}</th>
    <th>{{'admin.action.title' | translate}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of basicTable.data">
    <td>{{ data.firstName }}</td>
    <td>{{ data.email }}</td>
    <td>{{ data.registrationTimestamp | date : "yyyy-MM-dd HH:mm:ss" }}</td>
    <td class="nowrap">
      <button
        nz-button
        nzType="link"
        nzSize="small"
        [nzTooltipTitle]="'admin.password-reset-email.tooltip' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
        (click)="sendPasswordResetEmail(data.accountId, data.email)"
      >
        <span nz-icon nzType="send" nzTheme="outline"></span>
      </button>

      <button
        nz-button
        nzType="link"
        nzSize="small"
        [nzTooltipTitle]="data.accountId.internalId"
        nzTooltipPlacement="top"
        nz-tooltip
        (click)="copyId(data.accountId.internalId)"
      >
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
      </button>

      <button
        nz-button
        nzType="link"
        nzSize="small"
        [nzTooltipTitle]="'admin.set-password.tooltip' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
        (click)="openSetPasswordModal(data)"
      >
        <span nz-icon nzType="lock" nzTheme="outline"></span>
      </button>

      <button
        nz-button
        nzType="link"
        nzSize="small"
        [nzTooltipTitle]="'admin.delete-account.tooltip' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
        (click)="showDeleteConfirm(data)"
      >
        <span nz-icon nzType="delete" nzTheme="outline"></span>
      </button>
    </td>
  </tr>
  </tbody>
</nz-table>
