import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { JobOfferTalentCommentsDialogComponent } from "./job-offer-talent-comments-dialog.component";
import { NzModalModule } from "ng-zorro-antd/modal";
import { TranslateModule } from "@ngx-translate/core";
import { JobOfferTalentCommentsModule } from "../../../../../../../../../common-ui/src/lib/job-offer-talent-comments/job-offer-talent-comments.module";

@NgModule({
  declarations: [JobOfferTalentCommentsDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzModalModule,
    JobOfferTalentCommentsModule,
  ],
  exports: [JobOfferTalentCommentsDialogComponent],
})
export class JobOfferTalentCommentsDialogModule {}
