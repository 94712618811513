import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from "@angular/core";
import { MissionPartner } from "../../../../api/sign-up/signup.types";
import { ProfileService } from "../../../../api/profile/profile.service";
import { map, Observable, Subject, takeUntil, tap } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MissionPartnerProfileSection } from "../../../../api/dashboard/dashboard.types";
import { Store } from "@ngrx/store";
import {
  missionPartnerProfileFeatureName,
  MissionPartnerProfileState,
} from "../../../../api/profile/store/mission-partner-profile.reducer";
import * as MissionPartnerProfileActions from "../../../../api/profile/store/mission-partner-profile.action";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { PresentationMedia } from "../../../../../../../core/src/lib/recording/recording.types";
import { selectMissionPartnerProfile } from "../../../../api/profile/store/mission-partner-profile.selectors";
import { ProfileAboutComponent } from "./steps/profile-about/profile-about.component";
import { ProfileCompanyDetailsComponent } from "./steps/profile-company-details/profile-company-details.component";
import { ProfileComplianceKpiComponent } from "./steps/profile-compliance-kpi/profile-compliance-kpi.component";
import { ProfileDiversitiesComponent } from "./steps/profile-diversities/profile-diversities.component";
import { ProfileInvoiceAddressComponent } from "./steps/profile-invoice-address/profile-invoice-address.component";

export interface StepItem {
  index: number;
  titleKey: string;
  name: MissionPartnerProfileSection;
  component: any;
}

@Component({
  selector: "mh-mission-partner-profile",
  templateUrl: "./mission-partner-profile.component.html",
  styleUrls: ["./mission-partner-profile.component.less"],
})
export class MissionPartnerProfileComponent implements OnDestroy {
  missionPartner!: MissionPartner;
  isMobileView$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  @ViewChild(ProfileAboutComponent) profileAboutComponent!: ProfileAboutComponent;
  @ViewChild(ProfileCompanyDetailsComponent) profileCompanyDetailsComponent!: ProfileCompanyDetailsComponent;
  @ViewChild(ProfileDiversitiesComponent) profileDiversitiesComponent!: ProfileDiversitiesComponent;
  @ViewChild(ProfileComplianceKpiComponent) profileComplianceKpiComponent!: ProfileComplianceKpiComponent;
  @ViewChild(ProfileInvoiceAddressComponent) profileInvoiceAddressComponent!: ProfileInvoiceAddressComponent;

  steps: Array<StepItem> = [
    {
      index: 0,
      titleKey: "mp.profile.about.step",
      name: MissionPartnerProfileSection.ABOUT_ME,
      component: ProfileAboutComponent,
    },
    {
      index: 1,
      titleKey: "mp.profile.company-details.step",
      name: MissionPartnerProfileSection.COMPANY_DETAILS,
      component: ProfileCompanyDetailsComponent,
    },
    {
      index: 2,
      titleKey: "mp.profile.diversities.step",
      name: MissionPartnerProfileSection.DIVERSITIES,
      component: ProfileDiversitiesComponent,
    },
    //{
    //  index: 3,
    //  titleKey: "mp.profile.compliance-kpi.step",
    //  name: MissionPartnerProfileSection.COMPLIANCE_KPI,
    //  component: ProfileComplianceKpiComponent,
    //},
    {
      index: 3,
      titleKey: "mp.profile.invoice-address.step",
      name: MissionPartnerProfileSection.INVOICE_ADDRESS,
      component: ProfileInvoiceAddressComponent,
    },
  ];

  currentStepIndex = this.steps[0].index;

  get currentStepItem(): StepItem {
    return this.getStepItemByIndex(this.currentStepIndex);
  }

  get hasPrevStep(): boolean {
    return this.currentStepIndex > 0;
  }

  get hasNextStep(): boolean {
    return this.currentStepIndex < this.steps.length - 1;
  }

  constructor(
    private profileService: ProfileService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<{ [missionPartnerProfileFeatureName]: MissionPartnerProfileState }>,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
  ) {
    this.store
      .select(selectMissionPartnerProfile)
      .pipe(
        takeUntil(this.destroy$),
        tap((missionPartner) => (this.missionPartner = missionPartner as MissionPartner)),
        map((missionPartner) => missionPartner as MissionPartner),
      )
      .subscribe();

    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  getStepItemByIndex(index: number): StepItem {
    return this.steps[index];
  }

  onStepChange(index: number) {
    if (this.validateCurrentStep()) {
      this.currentStepIndex = index;
    }
  }

  onFormChange(change: MissionPartner) {
    this.store.dispatch(MissionPartnerProfileActions.updateStoreMissionPartnerProfile({ data: change }));
  }

  onSubmitChange(skipNavigationToNextStep?: boolean) {
    this.profileService.missionPartnerUpdate(this.missionPartner).subscribe((result) => {
      if (!skipNavigationToNextStep) this.navigateNextStep();
      this.notificationService.success("", this.translateService.instant("profile.updated.notification"));
    });
  }

  navigateNextStep() {
    const stepCandidate = this.currentStepIndex + 1;
    if (stepCandidate < this.steps.length) this.currentStepIndex = stepCandidate;
  }

  onPrevStep() {
    this.currentStepIndex--;
  }
  onNextStep() {
    this.currentStepIndex++;
  }

  updateProfileMedia(event: PresentationMedia | null) {
    this.onFormChange({ ...this.missionPartner, presentationMedia: event });
  }

  validateCurrentStep(): boolean {
    switch (this.currentStepItem.component) {
      case ProfileAboutComponent:
        return this.profileAboutComponent.isStepValid();
      case ProfileCompanyDetailsComponent:
        return this.profileCompanyDetailsComponent.isStepValid();
      case ProfileDiversitiesComponent:
        return this.profileDiversitiesComponent.isStepValid();
      case ProfileComplianceKpiComponent:
        return this.profileComplianceKpiComponent.isStepValid();
      case ProfileInvoiceAddressComponent:
        return this.profileInvoiceAddressComponent.isStepValid();
      default:
        return false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly MissionPartnerProfileSection = MissionPartnerProfileSection;
}
