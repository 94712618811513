<div
  class="widget"
  [class.widget--link]="link"
  [style.background]="backgroundColor"
  [style.color]="textColor"
  [routerLink]="link"
  [queryParams]="queryParams"
>
  <div class="widget__label">{{ label }}</div>
  <div class="widget__value">
    {{ value }}
  </div>
  <div
    class="widget__icon"
    [style.fontSize]="iconSize"
    [style.top]="iconTop"
    [style.left]="iconLeft"
    [style.transform]="'rotate(' + iconRotate + ')'"
  >
    <span nz-icon [nzType]="iconType" [nzTheme]="iconTheme"></span>
  </div>

  <ng-content></ng-content>

  <ng-container *ngIf="link">
    <button
      class="widget__more-btn"
      [routerLink]="link"
      [queryParams]="queryParams"
      nz-button
      nzBlock
      nzType="default"
      nzGhost
    >
      {{ moreBtn | translate }}
    </button>
  </ng-container>
</div>
