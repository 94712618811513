import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileFamilyFriendlyComponent } from "./profile-family-friendly.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import {TranslateModule} from "@ngx-translate/core";
import {ExplanationLabelModule} from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [ProfileFamilyFriendlyComponent],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzCheckboxModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        ReactiveFormsModule,
        NzButtonModule,
        NzWaveModule,
        TranslateModule,
        ExplanationLabelModule,
    ],
  exports: [ProfileFamilyFriendlyComponent],
})
export class ProfileFamilyFriendlyModule {}
