<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{ "mp.profile.title" | translate }}</nz-page-header-title>
</nz-page-header>

<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>
<ng-template #desktopView>
  <div nz-row class="profile__container">
    <div nz-col nzSpan="8" class="v-stepper__content">
      <!-- vertical stepper -->
      <nz-steps [nzCurrent]="currentStepIndex" (nzIndexChange)="onStepChange($event)" nzDirection="vertical">
        <nz-step [nzTitle]="step.titleKey | translate" *ngFor="let step of steps"></nz-step>
      </nz-steps>
    </div>
    <div nz-col nzSpan="16" class="main__content">
      <ng-container [ngTemplateOutlet]="stepOutput"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #mobileView>
  <div nz-row class="mobile_profile__container">
    <div nz-col nzSpan="24" class="mobile_profile__stepper">
      <Navbar [icon]="icon" [rightContent]="rightContent" [mode]="'light'">
        {{'navi.step.label' | translate:{currentStep: this.currentStepIndex + 1, totalSteps: this.steps.length,} }}
      </Navbar>
      <ng-template #icon>
        <Icon [type]="'left'" (click)="onPrevStep()" *ngIf="hasPrevStep"></Icon>
      </ng-template>
      <ng-template #rightContent>
        <Icon [type]="'right'" (click)="onNextStep()" *ngIf="hasNextStep"></Icon>
      </ng-template>
    </div>
    <div nz-col nzSpan="24" class="mobile_profile__step">
      <ng-container [ngTemplateOutlet]="stepOutput"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #stepOutput>
  <ng-container *ngIf="currentStepItem.name === MissionPartnerProfileSection.ABOUT_ME">
    <mh-profile-about
      [missionPartner]="missionPartner"
      [mobileView]="$any(isMobileView$ | async)"
      (formChange)="onFormChange($event)"
      (submitChange)="onSubmitChange($event)"
      (profileMediaChange)="updateProfileMedia($event)"
    ></mh-profile-about>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name === MissionPartnerProfileSection.COMPANY_DETAILS">
    <mh-profile-company-details
      [missionPartner]="missionPartner"
      (formChange)="onFormChange($event)"
      (submitChange)="onSubmitChange()"
    ></mh-profile-company-details>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name === MissionPartnerProfileSection.DIVERSITIES">
    <mh-profile-diversities
      [missionPartner]="missionPartner"
      (formChange)="onFormChange($event)"
      (submitChange)="onSubmitChange()"
    ></mh-profile-diversities>
  </ng-container>
  <!--
  <ng-container *ngIf="currentStepItem.name === MissionPartnerProfileSection.COMPLIANCE_KPI">
    <mh-profile-compliance-kpi [missionPartner]="missionPartner" (formChange)="onFormChange($event)" (submitChange)="onSubmitChange()"></mh-profile-compliance-kpi>
  </ng-container>
  -->
  <ng-container *ngIf="currentStepItem.name === MissionPartnerProfileSection.INVOICE_ADDRESS">
    <mh-profile-invoice-address
      [missionPartner]="missionPartner"
      (formChange)="onFormChange($event)"
      (submitChange)="onSubmitChange()"
    ></mh-profile-invoice-address>
  </ng-container>
</ng-template>
