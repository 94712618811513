import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocationLabelComponent } from "./location-label.component";

@NgModule({
  declarations: [LocationLabelComponent],
  imports: [CommonModule],
  exports: [
    LocationLabelComponent
  ]
})
export class LocationLabelModule {}
