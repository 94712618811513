import { Injectable } from "@angular/core";
import {
  DashboardConversation,
  DashboardConversationDTO,
  DashboardConversationInfo,
  DashboardConversationInfoDTO,
} from "./dashboard.types";

@Injectable({
  providedIn: "root",
})
export class DashboardSerializer {
  constructor() {
  }

  deserializeDashboardConversationInfo(dto: DashboardConversationInfoDTO): DashboardConversationInfo {
    return {
      ...dto,
      conversationsInfo: this.deserializeDashboardConversationArray(dto.conversationsInfo),
    };
  }

  private deserializeDashboardConversationArray(dtos: DashboardConversationDTO[]): DashboardConversation[] {

    return dtos.map((dto) => this.deserializeDashboardConversation(dto));
  }

  private deserializeDashboardConversation(dto: DashboardConversationDTO): DashboardConversation {
    return {
      ...dto,
      lastMessageTimestamp: new Date(dto.lastMessageTimestamp),
    };
  }
}
