import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { HomeAdminComponent } from "./home-admin.component";
import { AdminTalentListComponent } from "./subpages/admin-talent-list/admin-talent-list.component";
import { AdminMissionPartnerListComponent } from "./subpages/admin-mission-partner-list/admin-mission-partner-list.component";
import { AdminJobOfferListComponent } from "./subpages/admin-job-offer-list/admin-job-offer-list.component";
import { AdminNegotiationListComponent } from "./subpages/admin-negotiation-list/admin-negotiation-list.component";
import { AdminCommunicationsComponent } from "./subpages/admin-communications/admin-communications.component";
import { AdminTalentViewComponent } from "./subpages/admin-talent-view/admin-talent-view.component";
import { AdminUserAdminListComponent } from "./subpages/admin-user-admin-list/admin-user-admin-list.component";
import { AdminMissionPartnerViewComponent } from "./subpages/admin-mission-partner-view/admin-mission-partner-view.component";
import { AdminJobOfferMatchesComponent } from "./subpages/admin-job-offer-matches/admin-job-offer-matches.component";
import { AdminInvoiceListComponent } from "./subpages/admin-invoices-list/admin-invoice-list.component";
import {AdminAssessmentListComponent} from "./subpages/admin-assessment-list/admin-assessment-list.component";
import {AdminAssessmentViewComponent} from "./subpages/admin-assessment-view/admin-assessment-view.component";
import {AdminAssessmentResolver} from "../../api/assessment/admin-assessment.resolver";

const routes: Routes = [
  {
    path: '',
    component: HomeAdminComponent,
    children:[
      {
        path: 'talent-list',
        component: AdminTalentListComponent
      },
      {
        path: 'mission-partner-list',
        component: AdminMissionPartnerListComponent
      },
      {
        path: 'user-admin-list',
        component: AdminUserAdminListComponent
      },
      {
        path: 'job-offer-list',
        component: AdminJobOfferListComponent
      },
      {
        path: 'completion-list',
        component: AdminNegotiationListComponent
      },
      {
        path: 'communications',
        component: AdminCommunicationsComponent
      },
      {
        path: 'talent-view/:id',
        component: AdminTalentViewComponent,
      },
      {
        path: 'mission-partner-view/:id',
        component: AdminMissionPartnerViewComponent,
      },
      {
        path: 'job-offer-matches/:id',
        component: AdminJobOfferMatchesComponent,
      },
      {
        path: 'invoices',
        component: AdminInvoiceListComponent,
      },
      {
        path: 'assessments',
        component: AdminAssessmentListComponent,
      },
      {
        path: 'assessment-view/:id',
        component: AdminAssessmentViewComponent,
        resolve: {
          assessment: AdminAssessmentResolver
        }
      },
      {
        path: '**',
        redirectTo: 'talent-list'
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeAdminRoutingModule { }
