import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription, takeUntil, tap } from "rxjs";
import { SocketService } from "../../../../../core/src/lib/soket/socket.service";
import { NotificationService } from "../../api/notification/notification.service";
import { MhNotification, MhNotificationType, MhSocketMessage } from "../../api/notification/notification.types";
import { Router } from "@angular/router";

@Component({
  selector: "mh-notification-dropdown",
  templateUrl: "./notification-dropdown.component.html",
  styleUrls: ["./notification-dropdown.component.less"],
})
export class NotificationDropdownComponent implements OnInit, OnDestroy {
  @Input() mobile = false;
  private readonly destroy$ = new Subject<void>();
  messages$!: Subscription;
  notifications: Array<MhNotification> = [];

  get limitedNotifications(): Array<MhNotification> {
    return this.notifications.slice(0, 15);
  }

  get unreadCount(): number {
    const unread = this.notifications.filter((n) => !n.read);
    return unread?.length || 0;
  }

  constructor(
    private socketService: SocketService,
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  ngOnInit() {
      this.messages$ = this.socketService.messages$
        .pipe(
          takeUntil(this.destroy$),
          tap((msg: MhSocketMessage) => {
            if (msg.action === MhNotificationType.CV_PARSED) {
              this.notifications.unshift(msg.notification as MhNotification);
            }
          }),
        )
        .subscribe();
    this.updateNotifications();
  }

  handleNotificationClick(notification: MhNotification) {
    switch (notification.type) {
      case MhNotificationType.CV_PARSED:
        if (!notification.read) {
          this.notificationService.markReadNotifications([notification.id]).subscribe((success) => {
            if (success) {
              this.router.navigate(["/", { outlets: { overlay: ["talent", "parsed-cv"] } }]);
              this.updateNotifications();
            }
          });
        }
        break;
    }
  }

  private updateNotifications() {
    this.notificationService.loadNotificationList().subscribe((data) => {
      this.notifications = data;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly MhNotificationType = MhNotificationType;
}
