import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InvoiceFormComponent } from "./invoice-form.component";
import { ExplanationLabelModule } from "../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { TranslateModule } from "@ngx-translate/core";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzIconModule } from "ng-zorro-antd/icon";

@NgModule({
  declarations: [InvoiceFormComponent],
  imports: [
    CommonModule,
    ExplanationLabelModule,
    FormsModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzInputNumberModule,
    ReactiveFormsModule,
    TranslateModule,
    NzDatePickerModule,
    NzSelectModule,
    NzUploadModule,
    NzIconModule,
  ],
  exports: [InvoiceFormComponent],
})
export class InvoiceFormModule {}
