<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{ "mission-partner.dashboard.title" | translate }}</nz-page-header-title>
</nz-page-header>

<div class="dashboard">
  <div nz-row [nzGutter]="[20, 20]" class="dashboard__row">
    <div nz-col nzSpan="24" nzMd="12" nzLg="8">
      <mh-widget
        [label]="'mission-partner.dashboard.widget.job-offers' | translate"
        [value]="jobOffers.active || 0"
        [backgroundColor]="'#6FC4C2'"
        [iconType]="'shopping'"
        [link]="['/mission-partner/job-offers']"
      >
        <div class="widget__list" style="background-color: #6fc4c2">
          <div
            *ngFor="let jobOffer of jobOffers.lastJO"
            class="widget__list-item"
            [routerLink]="['/mission-partner/job-offers/matches', jobOffer.id]"
            (click)="stopPropagation($event)"
          >
            <div class="widget__list-item-label">
              {{ jobOffer.name }}
            </div>
            <div class="widget__list-item-value">
              {{ jobOffer.matchesAmount }}
              {{ (jobOffer.matchesAmount === 1 ? "match.label" : "matches.label") | translate }}
            </div>
          </div>
        </div>
      </mh-widget>
    </div>

    <div nz-col nzSpan="24" nzMd="12" nzLg="8">
      <mh-widget
        [label]="'mission-partner.dashboard.widget.invoices' | translate"
        [value]="invoicesInfo.newInvoicesCount || 0"
        [backgroundColor]="'#61CE70'"
        [iconType]="'file-text'"
        [link]="['/mission-partner/invoices']"
      >
        <div class="widget__list" style="background-color: #61ce70">
          <div
            *ngFor="let invoice of invoicesInfo.lastInvoices"
            class="widget__list-item"
            [routerLink]="['/mission-partner/invoices']"
            (click)="stopPropagation($event)"
          >
            <div class="widget__list-item-label">
              {{ invoice.performanceMonth | date : "MMM yyyy" }}
            </div>
            <div class="widget__list-item-value">
              {{ invoice.amount | currency : "EUR" }}
            </div>
          </div>
        </div>
      </mh-widget>
    </div>

    <div nz-col nzSpan="24" nzMd="12" nzLg="8">
      <mh-widget
        [label]="'mission-partner.micro-site.label' | translate"
        [value]="(profileCompleteness.completenessPercentage || 0) + '%'"
        [backgroundColor]="'#75a3ff'"
        [iconType]="'global'"
        [iconTheme]="'outline'"
        [link]="['/mission-partner/micro-site']"
      >
        <div class="widget__list" style="background-color: #75a3ff">
          <div class="widget__list-item">
            <div class="widget__list-item-label">
              {{ "mission-partner.dashboard.widget.micro-site" | translate }}
            </div>
            <div class="widget__list-item-value"></div>
          </div>
        </div>
      </mh-widget>
    </div>

    <div nz-col nzSpan="24" nzMd="12" nzLg="8">
      <mh-widget
        [label]="'mission-partner.dashboard.widget.proactive-contact-attempts' | translate"
        [value]="proactiveContactAttempts.totalAmount || 0"
        [backgroundColor]="'#F08B5E'"
        [iconType]="'solution'"
        [link]="['/mission-partner/inbox']"
        [queryParams]="{ tab: 'contactrequests' }"
      >
        <div class="widget__list" style="background-color: #f08b5e">
          <div
            *ngFor="let proactiveContact of proactiveContactAttempts.conversationsInfo"
            class="widget__list-item"
            [routerLink]="['/mission-partner/inbox/conversation/', proactiveContact.conversationId]"
            (click)="stopPropagation($event)"
          >
            <div class="widget__list-item-label">
              {{ proactiveContact.fullName }}
            </div>
            <div class="widget__list-item-value">
              {{ proactiveContact.lastMessageTimestamp | date : "dd.MM.yyyy HH:mm" }}
            </div>
          </div>
        </div>
      </mh-widget>
    </div>

    <div nz-col nzSpan="24" nzMd="12" nzLg="8">
      <mh-widget
        [label]="'mission-partner.dashboard.widget.last-reply' | translate"
        [value]="talentLastReplies.totalAmount || 0"
        [backgroundColor]="'#FF7980'"
        [iconType]="'mail'"
        [iconTheme]="'fill'"
        [link]="['/mission-partner/inbox']"
        [queryParams]="{ tab: 'messages' }"
      >
        <div class="widget__list" style="background-color: #ff7980">
          <div
            *ngFor="let lastReply of talentLastReplies.conversationsInfo"
            class="widget__list-item"
            [routerLink]="['/mission-partner/inbox/conversation/', lastReply.conversationId]"
            (click)="stopPropagation($event)"
          >
            <div class="widget__list-item-label">
              {{ lastReply.fullName }}
            </div>
            <div class="widget__list-item-value">
              {{ lastReply.lastMessageTimestamp | date : "dd.MM.yyyy HH:mm" }}
            </div>
          </div>
        </div>
      </mh-widget>
    </div>

    <div nz-col nzSpan="24" nzMd="12" nzLg="8">
      <mh-widget
        [label]="'mission-partner.dashboard.widget.hirings' | translate"
        [value]="hiringConversation.totalAmount || 0"
        [backgroundColor]="'#20B2AA'"
        [iconType]="'team'"
        [iconTheme]="'outline'"
        [link]="['/mission-partner/inbox']"
        [queryParams]="{ tab: 'hirings' }"
      >
        <div class="widget__list" style="background-color: #20b2aa">
          <div
            *ngFor="let hiring of hiringConversation.conversationsInfo"
            class="widget__list-item"
            [routerLink]="['/mission-partner/inbox/conversation/', hiring.conversationId]"
            (click)="stopPropagation($event)"
          >
            <div class="widget__list-item-label">
              {{ hiring.fullName }}
            </div>
            <div class="widget__list-item-value">
              {{ hiring.lastMessageTimestamp | date : "dd.MM.yyyy HH:mm" }}
            </div>
          </div>
        </div>
      </mh-widget>
    </div>

    <div nz-col nzSpan="24" nzMd="12" nzLg="8">
      <mh-mission-partner-dashboard-notification-settings
        [notificationsSettings]="notificationsSettings"
      ></mh-mission-partner-dashboard-notification-settings>
    </div>
  </div>
</div>
