import { Injectable } from "@angular/core";
import { JobOfferApi } from "./job-offer.api";
import { map, Observable } from "rxjs";
import {
  JobOffer,
  JobOfferTalentComment,
  MatchedTalent,
  MatchedTalentDTO
} from "./job-offer.types";
import { JobOfferSerializer } from "./job-offer.serializer";
import { AccountSerializer } from "../../pages/home-admin/home-admin-data/account.serializer";
import { AccountFilter } from "../../pages/home-admin/home-admin-data/account.types";
import { PaginatedContent } from "../common.types";

@Injectable({ providedIn: "root" })
export class JobOfferService {
  constructor(
    private jobOfferApi: JobOfferApi,
    private jobOfferSerializer: JobOfferSerializer,
    private accountSerializer: AccountSerializer,
  ) {}

  loadMyJobOfferList(): Observable<Array<JobOffer>> {
    return this.jobOfferApi
      .loadMyJobOfferList()
      .pipe(map((dtos) => this.jobOfferSerializer.deserializeJobOffers(dtos)));
  }

  loadJobOffer(id: string): Observable<JobOffer> {
    return this.jobOfferApi.loadJobOffer(id).pipe(map((dto) => this.jobOfferSerializer.deserializeJobOffer(dto)));
  }

  createJopOffer(jo: JobOffer) {
    return this.jobOfferApi.createJopOffer(this.jobOfferSerializer.serializeJobOffer(jo));
    //.pipe(map((dto) => this.jobOfferSerializer.deserializeJobOffer(dto)));
  }

  updateJobOffer(jo: JobOffer): Observable<boolean> {
    return this.jobOfferApi.updateJobOffer(this.jobOfferSerializer.serializeJobOffer(jo));
  }

  deleteJobOffer(id: string): Observable<boolean> {
    return this.jobOfferApi.deleteJobOffer(id);
  }

  archiveJobOffer(id: string): Observable<boolean> {
    return this.jobOfferApi.archiveJobOffer(id);
  }

  unArchiveJobOffer(jo: JobOffer): Observable<boolean> {
    return this.jobOfferApi.unArchiveJobOffer(this.jobOfferSerializer.serializeJobOffer(jo));
  }

  loadJobOfferMatchedTalents(id: string): Observable<Array<MatchedTalent>> {
    return this.jobOfferApi
      .loadJobOfferMatchedTalents(id)
      .pipe(map((dto) => this.jobOfferSerializer.deserializeMatchedTalents(dto)));
  }

  loadJobOfferMatchedTalentList(filter: AccountFilter): Observable<PaginatedContent<MatchedTalent>> {
    return this.jobOfferApi.loadJobOfferMatchedTalentList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.jobOfferSerializer.deserializeMatchedTalents(paginatedContent.items as Array<MatchedTalentDTO>),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  readJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    return this.jobOfferApi.readJobOfferMatch(jobOfferId, talentId);
  }

  archiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    return this.jobOfferApi.archiveJobOfferMatch(jobOfferId, talentId);
  }

  unArchiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    return this.jobOfferApi.unArchiveJobOfferMatch(jobOfferId, talentId);
  }

  loadJobOfferTalentComments(jobOfferId: string, talentId: string): Observable<Array<JobOfferTalentComment>> {
    return this.jobOfferApi.loadJobOfferTalentComments(jobOfferId, talentId).pipe(map((dto) => this.jobOfferSerializer.deserializeComments(dto)));
  }

  sendJobOfferTalentComment(jobOfferId: string, talentId: string, text: string): Observable<JobOfferTalentComment> {
    return this.jobOfferApi.sendJobOfferTalentComment(jobOfferId, talentId, text).pipe(map((dto) => this.jobOfferSerializer.deserializeComment(dto)));
  }

  updateJobOfferTalentComment(commentId: string, text: string): Observable<JobOfferTalentComment> {
    return this.jobOfferApi.updateJobOfferTalentComment(commentId, text).pipe(map((dto) => this.jobOfferSerializer.deserializeComment(dto)));
  }

  deleteJobOfferTalentComment(jobOfferId: string, commentId: string): Observable<boolean> {
    return this.jobOfferApi.deleteJobOfferTalentComments(jobOfferId, commentId);
  }

  setTalentFavourite(jobOfferId: string, talentId: string, setFavourite: boolean): Observable<boolean> {
    return this.jobOfferApi.setTalentFavourite(jobOfferId, talentId, setFavourite);
  }
}
