import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerNotificationsComponent } from "./mission-partner-notifications.component";
import { NotificationSettingsModule } from "../../../../common-componnets/notification-settings/notification-settings.module";

@NgModule({
  declarations: [MissionPartnerNotificationsComponent],
  imports: [
    CommonModule,
    NotificationSettingsModule,
  ],
})
export class MissionPartnerNotificationsModule {}
