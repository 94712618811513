import { AbstractControl, ValidationErrors } from "@angular/forms"

export const SpecialCharacterRequiredValidator = function (control: AbstractControl): ValidationErrors | null {
  const value: string = control.value || '';
  if (!value) {
    return null
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (!specialCharacters.test(value)) {
    return { specialCharacterRequired: `Special char required` };
  }

  return null;
}
