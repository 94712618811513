<nz-page-header [nzTitle]="'profile.family-friendly.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" formGroupName="desiredJob">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.employer-expectations.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <textarea rows="4" formControlName="familyFriendlinessExpectations" nz-input></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save-and-next.button' | translate}}</button>
    </div>
  </div>
</form>
