import { Injectable } from "@angular/core";
import {MaturityAssessment, MaturityAssessmentForm} from "./assessment.types";
import {
  ExternalFormSetup,
  ExternalFormSetupDTO,
  MaturityAssessmentDTO,
  MaturityAssessmentFormDTO
} from "./assessment.api.types";
import {CommonService} from "../common.service";

@Injectable({
  providedIn: "root",
})
export class AssessmentSerializer {

  constructor(private commonService: CommonService) {}
  deserializeMaturityAssessments(dtos: Array<MaturityAssessmentDTO>): Array<MaturityAssessment> {
    return dtos.map(dto => this.deserializeMaturityAssessment(dto));
  }

  deserializeMaturityAssessment(dto: MaturityAssessmentDTO): MaturityAssessment {
    return {
      ...dto,
      missionPartnerId: dto.missionPartnerId?.internalId,
      createdAt: new Date(dto.createdAt),
      resultFile: dto.resultFile?this.commonService.toAbsUrl(dto.resultFile?.fileUrl):undefined
    };
  }

  serializeExternalFormSetUp(request: ExternalFormSetup): ExternalFormSetupDTO {
    return {
      ...request,
    };
  }

  deserializeMaturityAssessmentForm(dto: MaturityAssessmentFormDTO): MaturityAssessmentForm {
    return {
      ...dto,
      startedTimestamp: new Date(dto.startedTimestamp),
      endedTimestamp: dto.endedTimestamp ? new Date(dto.endedTimestamp):null
    }
  }
}
