import { BaseApi } from "../base-api";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { catchError, map, Observable } from "rxjs";
import { FactsAndFigures, MAAnswerSaveRequest } from "./assessment.types";
import { ApiPaginatedResponseDTO, ApiResponseDTO, initialPaginatedContent, PaginatedContent } from "../common.types";
import {
  ExternalFormSetupDTO,
  Hashtags,
  MaturityAssessmentDTO,
  MaturityAssessmentFormDTO,
} from "./assessment.api.types";
import { AccountFilterDTO } from "../../pages/home-admin/home-admin-data/account.types";

@Injectable({ providedIn: "root" })
export class AssessmentApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  //External person started assessment, 1st step email/name/date
  externalFormSetUp(request: ExternalFormSetupDTO): Observable<MaturityAssessmentFormDTO> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/form/set-up`, request).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  //Admin: get assessments
  getMaturityAssessmentList(): Observable<Array<MaturityAssessmentDTO>> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/maturity-assessment/list`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  loadMaturityAssessmentList(filter: AccountFilterDTO): Observable<PaginatedContent<MaturityAssessmentDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<MaturityAssessmentDTO>>(`${this.basePath}/maturity-assessment/list`, { ...filter })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  // Admin: create Assessment for MP
  setUp(missionPartnerId: string): Observable<MaturityAssessmentDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/set-up/${missionPartnerId}`, { missionPartnerId })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  // Admin: delete assessment
  delete(maturityAssessmentId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/maturity-assessment/${maturityAssessmentId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
  // Admin: complete assessment
  complete(maturityAssessmentId: string): Observable<MaturityAssessmentDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/complete/${maturityAssessmentId}`, {
        maturityAssessmentId,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  // Admin: uncomplete assessment
  uncomplete(maturityAssessmentId: string): Observable<MaturityAssessmentDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/uncomplete/${maturityAssessmentId}`, {
        maturityAssessmentId,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  // Admin: delete assessment report
  deleteReport(maturityAssessmentId: string): Observable<boolean> {
    return this.httpClient
      .delete<ApiResponseDTO>(`${this.basePath}/maturity-assessment/report/${maturityAssessmentId}`)
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  // MP: get assessment state
  getMaturityAssessmentState(): Observable<MaturityAssessmentDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/maturity-assessment/state`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  // admin: get assessment state by id
  getMaturityAssessmentStateById(maturityAssessmentId: string): Observable<MaturityAssessmentDTO> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/maturity-assessment/${maturityAssessmentId}/state`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  // External user: get assessment form by token
  getMaturityAssessmentForm(token: string): Observable<MaturityAssessmentFormDTO | string> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/maturity-assessment/form/${token}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else if (response.errorKey) {
          return response.errorKey;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  // External user: get assessment form by token
  checkMaturityAssessmentByToken(token: string): Observable<{ id: string } | string> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/maturity-assessment/by-token/${token}`).pipe(
      map((response: any) => {
        if (response.success) {
          return { id: response.results.data };
        } else if (response.errorKey) {
          return response.errorKey;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  saveAnswer(answer: MAAnswerSaveRequest): Observable<MaturityAssessmentDTO> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/form/give-answer`, answer).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  completeForm(token: string): Observable<MaturityAssessmentFormDTO> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/form/${token}/complete`, { token })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  getMaturityAssessmentResults(maturityAssessmentId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${this.basePath}/maturity-assessment/${maturityAssessmentId}/results/csv`, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  getAllMaturityAssessmentResults(): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(`${this.basePath}/maturity-assessment/results/all/csv`, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  getFactsAndFigures(): Observable<FactsAndFigures> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/maturity-assessment/facts-and-figures`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  saveFactsAndFigures(factsAndFigures: FactsAndFigures): Observable<FactsAndFigures> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/facts-and-figures`, factsAndFigures)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  setHashtags(accountId: string, hashtags: Hashtags): Observable<Hashtags> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/maturity-assessment/hashtags`, { accountId, ...hashtags })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }
}
