<ng-container *nzModalTitle>
  {{'admin.invoices.add.label' | translate}}
</ng-container>

<mh-invoice-form [invoice]="invoice" (formChange)="onFormChange($event)"
                 [missionPartnerEditable]="true"
                 [missionPartners]="missionPartners"></mh-invoice-form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">
    {{ "cancel.button" | translate }}
  </button>
  <button nz-button nzType="primary" [nzLoading]="uploading" (click)="onSave()">
    {{ "add.button" | translate }}
  </button>
</ng-container>
