import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DashboardService } from "./dashboard.service";
import { JobOffersInfo } from "./dashboard.types";

@Injectable({
  providedIn: "root",
})
export class JobOffersInfoResolver {
  constructor(private readonly dashboardService: DashboardService) {}

  resolve(): Observable<JobOffersInfo> {
    return this.dashboardService.getMissionPartnerJobOffers();
  }
}
