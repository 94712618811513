<nz-page-header class="large p-r-0">
  <nz-page-header-title>{{'job-offer.edit-page.title' | translate}}</nz-page-header-title>
  <nz-page-header-extra>
    <nz-space>
      <button *nzSpaceItem nz-button nzType="primary" (click)="onBack()">< {{'job-offer.back.button' | translate}}</button>
    </nz-space>
  </nz-page-header-extra>
</nz-page-header>

<mh-mp-job-offer-form [jobOffer]="jobOffer" [isMobile]="(isMobileView$ | async)" (formChange)="onFormChange($event)"></mh-mp-job-offer-form>

<div nz-row [nzGutter]="24">
  <div nz-col [nzSpan]="24" class="form-footer-buttons">
    <nz-space>
      <button *nzSpaceItem nz-button (click)="onBack()">{{'cancel.button' | translate}}</button>
      <button *nzSpaceItem nz-button [nzType]="'primary'" (click)="onSave()" [disabled]="!(hasReviewedStatus$ | async)"
              [nzLoading]="isSaving">{{'save.button' | translate}}</button>
    </nz-space>
  </div>
</div>


