import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerProfileComponent } from "./mission-partner-profile.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { ProfileAboutModule } from "./steps/profile-about/profile-about.module";
import { ProfileCompanyDetailsModule } from "./steps/profile-company-details/profile-company-details.module";
import { IconModule, NavBarModule, StepsModule } from "ng-zorro-antd-mobile";
import { TranslateModule } from "@ngx-translate/core";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { ProfileDiversitiesModule } from "./steps/profile-diversities/profile-diversities.module";
import { ProfileComplianceKpiModule } from "./steps/profile-compliance-kpi/profile-compliance-kpi.module";
import { ProfileInvoiceAddressModule } from "./steps/profile-invoice-address/profile-invoice-address.module";

@NgModule({
  declarations: [MissionPartnerProfileComponent],
  imports: [
    CommonModule,
    NzGridModule,
    NzStepsModule,
    ProfileAboutModule,
    ProfileCompanyDetailsModule,
    ProfileDiversitiesModule,
    ProfileComplianceKpiModule,
    ProfileInvoiceAddressModule,
    NzProgressModule,
    StepsModule,
    TranslateModule,
    IconModule,
    NavBarModule,
    NzPageHeaderModule,
  ],
})
export class MissionPartnerProfileModule {}
