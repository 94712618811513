import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminJobOfferMatchesComponent } from "./admin-job-offer-matches.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzSelectModule } from "ng-zorro-antd/select";
import { ReactiveFormsModule } from "@angular/forms";
import { AdminJobOfferTalentCommentsDialogModule } from "./admin-job-offer-talent-comments-dialog/admin-job-offer-talent-comments-dialog.module";
import { NzFormModule } from "ng-zorro-antd/form";
import { JobOfferMatchesOfferViewModule } from "../../../../common-componnets/job-offer-mathces-offer-view/job-offer-matches-offer-view.module";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { JobOfferMatchesListModule } from "../../../../common-componnets/job-offer-matches-list/job-offer-matches-list.module";

@NgModule({
  declarations: [AdminJobOfferMatchesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzCardModule,
    NzTableModule,
    NzGridModule,
    NzTagModule,
    NzIconModule,
    NzToolTipModule,
    NzButtonModule,
    RouterLink,
    NzPopoverModule,
    NzSelectModule,
    AdminJobOfferTalentCommentsDialogModule,
    NzFormModule,
    ReactiveFormsModule,
    JobOfferMatchesOfferViewModule,
    NzSpinModule,
    JobOfferMatchesListModule,
  ],
})
export class AdminJobOfferMatchesModule {}
