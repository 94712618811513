import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DashboardService } from "./dashboard.service";
import { MissionPartnerProfileCompleteness } from "./dashboard.types";

@Injectable({
  providedIn: "root",
})
export class MissionPartnerProfileCompletenessResolver {
  constructor(private readonly dashboardService: DashboardService) {}

  resolve(): Observable<MissionPartnerProfileCompleteness> {
    return this.dashboardService.getMissionPartnerProfileCompleteness();
  }
}
