<h1 class="auth__title" nz-col nzSpan="24">{{ "reg.mp.company-details.title" | translate }}</h1>

<form
  nz-form
  class="auth__form"
  nzLayout="vertical"
  [nzAutoTips]="autoTips"
  [formGroup]="formGroup"
  (ngSubmit)="submitForm()"
>
  <ng-container formGroupName="companyOverview">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired>
            <mh-explanation-label [labelKey]="'reg.mp.num-employees.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="numberOfEmployees"
              nzSize="large"
              [nzPlaceHolder]="'select.placeholder' | translate"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                *ngFor="let option of numberOfEmployees$ | async"
                [nzLabel]="option.name | translate"
                [nzValue]="option"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired>
            <mh-explanation-label [labelKey]="'reg.mp.industry.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="companyIndustry"
              nzSize="large"
              [nzPlaceHolder]="'select.placeholder' | translate"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of industry$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired>
            <mh-explanation-label [labelKey]="'reg.mp.sector.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="companySector"
              nzSize="large"
              [compareWith]="compareCmsNameValue"
              [nzPlaceHolder]="'select.placeholder' | translate"
            >
              <nz-option
                [nzLabel]="option.name | translate"
                [nzValue]="option"
                *ngFor="let option of sector$ | async"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired>
            <mh-explanation-label [labelKey]="'reg.mp.company-type.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="companyType"
              nzSize="large"
              [nzPlaceHolder]="'select.placeholder' | translate"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                *ngFor="let option of companyType$ | async"
                [nzLabel]="option.name | translate"
                [nzValue]="option"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired>
            <mh-explanation-label [labelKey]="'reg.mp.company-headquarters.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="companyHeadquarters"
              nzSize="large"
              [nzPlaceHolder]="'select.placeholder' | translate"
              [compareWith]="compareCmsNameValue"
            >
              <nz-option
                *ngFor="let option of companyHeadquarters$ | async"
                [nzLabel]="option.name | translate"
                [nzValue]="option"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item *ngIf="showHeadQuartersTextControl">
          <nz-form-label nzRequired>
            <mh-explanation-label [labelKey]="'reg.mp.company-headquarters-details.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input nzSize="large" formControlName="companyHeadquartersText" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
</form>

<div nz-row [nzGutter]="24">
  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <p>{{ "reg.required.explanation" | translate }}</p>
      <nz-form-control [nzSpan]="24">
        <button nz-button nzType="primary" nzSize="large" nzBlock [disabled]="loading" (click)="submitForm()">
          {{ "next-step.button" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
