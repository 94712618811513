import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PasswordValidationTipsComponent} from "./password-validation-tips.component";
import {NzIconModule} from "ng-zorro-antd/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [PasswordValidationTipsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzIconModule,
    ReactiveFormsModule,
  ],
  exports: [
    PasswordValidationTipsComponent,
  ],
})
export class PasswordValidationTipsModule {
}
