import {Component, forwardRef} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MAAnswerOption, MAAnswerOptionType} from "../../../api/assessment/assessment.types";

@Component({
  selector: "mh-assessment-radio-group-select",
  templateUrl: "./assessment-radio-group-select.component.html",
  styleUrls: ["./assessment-radio-group-select.component.less"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssessmentRadioGroupSelectComponent), multi: true },
  ],
})
export class AssessmentRadioGroupSelectComponent implements ControlValueAccessor {
  selection: Array<MAAnswerOption> = [];
  radioSelected?: MAAnswerOption;
  radioFreeText?: string;
  linkedFreeText?: string;


  get radioFreeTextItem(): MAAnswerOption | undefined {
    return this.selection.find(o => o.type == MAAnswerOptionType.FREE_TEXT);
  }

  get linkedFreeTextOption(): MAAnswerOption | undefined {
    return this.radioSelected?.linkedAnswerOption;
  }

  onRadioGroupModelChange(): void {
    this.selection.forEach((sel) => (sel.selected = sel.id === this.radioSelected?.id));
    this.onChange(this.selection);
  }

  onRadioFreeTextChange(value: string, answerOption: MAAnswerOption): void {
    this.selection.map(sel => {sel.selected = sel.id === answerOption.id});
    const index = this.selection.findIndex((sel) => sel.id === answerOption?.id);
    if (index > -1) {
      this.selection[index] = { ...this.selection[index], value };
    }
    this.radioSelected = answerOption;
    this.onChange(this.selection);
  }

  onLinkedFreeTextChange(value: string, answerOption: MAAnswerOption): void {
    this.onChange([...this.selection, {...answerOption, value}]);
  }

  onChange = (value: Array<MAAnswerOption>) => {};
  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  writeValue(value: Array<MAAnswerOption>) {
    value = value || [];
    this.radioSelected = value.find((val) => val.selected);
    this.selection = value;
    this.linkedFreeText = this.radioSelected?.linkedAnswerOption?.value;
    this.radioFreeText = this.radioFreeTextItem?.value;
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  protected readonly MAAnswerOptionType = MAAnswerOptionType;
}
