import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import {
  NumberRequiredValidator,
  SpecialCharacterRequiredValidator,
  UpperCaseRequiredValidator,
} from "@momhunting/core";
import { LoginService } from "../../../../login-page/login-page-data/login.service";
import { TranslateService } from "@ngx-translate/core";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {catchError} from "rxjs";

@Component({
  selector: "mh-talent-settings-password",
  templateUrl: "./talent-settings-password.component.html",
  styleUrls: ["./talent-settings-password.component.less"],
})
export class TalentSettingsPasswordComponent implements OnInit {
  @ViewChild("passwordInputEl") passwordInputEl?: ElementRef;

  public formGroup!: FormGroup;
  changePasswordMode = false;
  loading = false;

  get newPassword(): FormControl {
    return this.formGroup.get("newPassword") as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      currentPassword: [undefined, [Validators.required]],
      newPassword: [
        undefined,
        [Validators.required, NumberRequiredValidator, SpecialCharacterRequiredValidator, UpperCaseRequiredValidator],
      ],
      newPasswordConfirmation: [
        undefined,
        [
          Validators.required,
          NumberRequiredValidator,
          SpecialCharacterRequiredValidator,
          UpperCaseRequiredValidator,
          this.confirmationValidator,
        ],
      ],
    });
  }

  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.formGroup.get("newPasswordConfirmation")?.updateValueAndValidity());
  }

  confirmationValidator: ValidatorFn = (control: AbstractControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.formGroup.get("newPassword")?.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  onChangePassword() {
    this.changePasswordMode = true;
    setTimeout(() => {
      this.passwordInputEl?.nativeElement.focus();
    }, 0);
  }
  onSavePassword() {
    if (!this.isPasswordsValid()) return;
    const currentPass = this.formGroup.get("currentPassword")?.value;
    const newPass = this.formGroup.get("newPasswordConfirmation")?.value;
    this.loading = true;
    this.loginService.passwordChange(currentPass, newPass).pipe(
      catchError((err) => {
        this.loading = false;
        return ''
      })
    ).subscribe((token) => {
      this.loading = false;
      if (token) {
        this.changePasswordMode = false;
        this.loginService.setToken(token);
        this.notificationService.success(
          this.translateService.instant("settings.password-changed.dialog.title"),
          this.translateService.instant("settings.password-changed.dialog.content"));
      }
    });
  }

  private isPasswordsValid() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return false;
    }
    return true;
  }
}
