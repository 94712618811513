<form class="admin-filters" nz-form [formGroup]="searchForm" (ngSubmit)="submitFilterForm()">
  <div class="admin-filters__fields">
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input type="text" formControlName="searchTerm" nz-input [placeholder]="'admin.filter.search.placeholder' | translate" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span *ngIf="!searchForm.get('searchTerm')?.value" nz-icon nzType="search"></span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchForm.get('searchTerm')?.value"
            (click)="searchForm.get('searchTerm')?.setValue(null)"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select
          class="mission-partner-filter"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'admin.filter.mission-partner.placeholder' | translate"
          formControlName="missionPartnerId"
        >
          <nz-option
            *ngFor="let option of missionPartners"
            [nzLabel]="option.companyName"
            [nzValue]="option.accountId.internalId"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="admin-filters__actions">
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" [disabled]="!searchForm.valid">{{'admin.filter.submit' | translate}}</button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="default" type="button" (click)="openCreateInvoiceModal()">
          {{'admin.invoices.add.label' | translate}}
        </button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="invoiceList?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="invoiceList?.paging?.items || 0"
  [nzPageSize]="invoiceList?.paging?.itemsOnPage || 0"
  [nzPageIndex]="invoiceList?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th>{{'admin.invoices.mp-name.title' | translate}}</th>
    <th>{{'admin.invoices.date.title' | translate}}</th>
    <th>{{'admin.invoices.number.title' | translate}}</th>
    <th>{{'admin.invoices.performance-month.title' | translate}}</th>
    <th [nzAlign]="'right'">{{'admin.invoices.amount.title' | translate}}</th>
    <th>{{'admin.invoices.status.title' | translate}}</th>
    <th>{{'admin.action.title' | translate}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of basicTable.data">
    <td>
      <a [routerLink]="['/admin/mission-partner-view', data.missionPartnerId]" target="_blank">
        {{ data.missionPartnerName }}
      </a>
    </td>
    <td>{{ data.date | date : "dd.MM.yyyy"}}</td>
    <td>{{ data.number }}</td>
    <td>{{ data.performanceMonth | date : "MMM yyyy" }}</td>
    <td [nzAlign]="'right'">{{ data.amount | currency:"EUR" }}</td>
    <td>
      <nz-select [ngModel]="data.invoiceStatus" (ngModelChange)="onChangeStatus($event, data.id)" style="width: 100%;">
        <nz-option [nzLabel]="'invoices.status.new' | translate" nzValue="NEW"></nz-option>
        <nz-option [nzLabel]="'invoices.status.received' | translate" nzValue="RECEIVED"></nz-option>
        <nz-option [nzLabel]="'invoices.status.done' | translate" nzValue="DONE"></nz-option>
      </nz-select>
    </td>

    <td class="nowrap">
      <button
        nz-button
        nzType="link"
        nzSize="small"
        [nzTooltipTitle]="data.missionPartnerId"
        nzTooltipPlacement="top"
        nz-tooltip
        (click)="copyId(data.missionPartnerId)"
      >
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
      </button>
      <a
        *ngIf="data.invoiceFile"
        nz-button
        nzType="link"
        nzSize="small"
        [nzTooltipTitle]="'admin.download-invoice.tooltip' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
        [href]="appConfig.siteUrl + '/' + data.invoiceFile.fileUrl"
        target="_blank"
      >
        <span nz-icon nzType="download" nzTheme="outline"></span>
      </a>

      <button
        nz-button
        nzType="link"
        nzSize="small"
        [nzTooltipTitle]="'admin.delete-invoice.tooltip' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
        (click)="showDeleteConfirm(data)"
      >
        <span nz-icon nzType="delete" nzTheme="outline"></span>
      </button>
    </td>
  </tr>
  </tbody>
</nz-table>
