import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentProfileCompletenessComponent } from "./talent-profile-completeness.component";
import {NzProgressModule} from "ng-zorro-antd/progress";

@NgModule({
  declarations: [TalentProfileCompletenessComponent],
  imports: [CommonModule, NzProgressModule],
  exports: [
    TalentProfileCompletenessComponent
  ]
})
export class TalentProfileCompletenessModule {}
