import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { ApiResponseDTO } from "../common.types";
import { map, Observable } from "rxjs";
import {DocumentFileDTO, MessagingFileDTO} from "./document.types";
import { AudioFile, PresentationMedia } from "../../../../../core/src/lib/recording/recording.types";
import {MaturityAssessmentDTO} from "../assessment/assessment.api.types";

@Injectable({ providedIn: "root" })
export class DocumentApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  loadUserFileList(accountId?: string): Observable<Array<DocumentFileDTO>> {
    const body = {
      filters: [
        {
          field: "TYPE",
          value: "FILE",
        },
      ],
      paging: {
        page: 0,
        itemsOnPage: 100,
      },
      sort: [
        {
          field: "UPDATED",
          order: "DESC",
        },
      ],
      searchTerm: "",
    };
    if (accountId) {
      body.filters.push({ field: 'ACCOUNT', value: accountId });
    }
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/file/list`, body).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data.items;
        } else {
          super.handleErrorResponse(response);
          return [];
        }
      }),
    );
  }

  loadUserCV(accountId?: string): Observable<Array<DocumentFileDTO>> {
    const body = {
      filters: [
        {
          field: "TYPE",
          value: "CV",
        },
      ],
      paging: {
        page: 0,
        itemsOnPage: 100,
      },
      sort: [
        {
          field: "UPDATED",
          order: "DESC",
        },
      ],
      searchTerm: "",
    };
    if (accountId) {
      body.filters.push({ field: 'ACCOUNT', value: accountId });
    }
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/file/list`, body).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data.items;
        } else {
          super.handleErrorResponse(response);
          return [];
        }
      }),
    );
  }

  deleteUserFile(fileId: string): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/file/${fileId}`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  uploadProfileAudio(formData: FormData): Observable<AudioFile> {
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data");
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/file/upload/profile-audio`, formData, {
        headers,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }

  uploadPresentationMedia(formData: FormData): Observable<PresentationMedia> {
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data");
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/file/upload/profile-presentation-media`, formData, {
        headers,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }

  uploadMessagingFile(formData: FormData): Observable<MessagingFileDTO> {
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data");
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/file/upload/file-messaging`, formData, {
        headers,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }

  uploadMAReport(formData: FormData): Observable<MaturityAssessmentDTO> {
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data");
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/file/upload/maturity-assessment-result`, formData, {
        headers,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }
}
