import { Component, OnDestroy, OnInit } from "@angular/core";
import { catchError, EMPTY, Subject, takeUntil } from "rxjs";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReferencesService } from "../../api/references/references.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-make-reference",
  templateUrl: "./make-reference.component.html",
  styleUrls: ["./make-reference.component.less"],
})
export class MakeReferenceComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  public formGroup!: FormGroup;
  private token?: string;

  public authorName = "";
  public targetName = "";

  public referenceComplete = false;
  public referenceRejected = false;

  get referenceTextControl(): AbstractControl | null {
    return this.formGroup.get("referenceText");
  }

  constructor(
    private fb: FormBuilder,
    private referencesService: ReferencesService,
    private route: ActivatedRoute,
    private readonly router: Router,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.translateService.instant('make-reference.reference.label') }));
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      referenceText: [undefined, [Validators.required]],
    });
    this.validationToken();
    this.authorName = decodeURIComponent(this.route.snapshot.queryParamMap.get("author") || "").replace(/ /g, "\u00A0");
    this.targetName = decodeURIComponent(this.route.snapshot.queryParamMap.get("target") || "").replace(/ /g, "\u00A0");
  }

  private validationToken() {
    const referenceToken = this.route.snapshot.queryParamMap.get("referenceToken");
    if (referenceToken) {
      this.referencesService
        .validationReferenceRequest(referenceToken)
        .pipe(takeUntil(this.destroy$))
        .pipe(
          catchError((err) => {
            this.router.navigate(["/expired-token"]);
            return EMPTY;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.token = referenceToken;
          } else {
            this.router.navigate(["/expired-token"]);
          }
        });
    }
  }

  onMake() {
    if (!this.token) return;
    this.referencesService
      .confirmReferenceRequest(this.referenceTextControl?.value, true, this.token)
      .subscribe((result) => {
        this.referenceComplete = result;
      });
  }

  onReject() {
    if (!this.token) return;
    this.referencesService
      .confirmReferenceRequest(this.referenceTextControl?.value, false, this.token)
      .subscribe((result) => {
        this.referenceRejected = result;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
