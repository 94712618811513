<ng-container *ngIf="participantProfile && accountType === AccountType.TALENT">
  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.num-employees.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="participantProfile.numberOfEmployees; else noValueSpecified">{{ participantProfile.numberOfEmployees.name | translate }}</ng-container>
  </p>

  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.industry.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="participantProfile.industry; else noValueSpecified">{{ participantProfile.industry.name | translate }}</ng-container>
  </p>

  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.sector.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="participantProfile.sector; else noValueSpecified">{{ participantProfile.sector.name | translate }}</ng-container>
  </p>

  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.company-type.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="participantProfile.companyType; else noValueSpecified">{{ participantProfile.companyType.name | translate }}</ng-container>
  </p>

  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.company-headquarters.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="participantProfile.companyHeadQuarters; else noValueSpecified">{{ participantProfile.companyHeadQuarters.name | translate }}</ng-container>
  </p>
</ng-container>
<ng-container *ngIf="participantProfile && accountType === AccountType.MISSION_PARTNER">
  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.salary.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="participantProfile.salaryMin || participantProfile.salaryMax; else noValueSpecified">
      {{ participantProfile.salaryMin }}K - {{ participantProfile.salaryMax }}K
    </ng-container>
  </p>

  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.domain-expertise.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="participantProfile.domainExpertises?.length; else noValueSpecified">
      <mh-tags-output [value]="participantProfile.domainExpertises"></mh-tags-output>
    </ng-container>
  </p>

  <h4 class="card-list__item-value">
    <mh-explanation-label [labelKey]="'participant.work-leadership-experience.label'"></mh-explanation-label>
  </h4>
  <p class="card-list__item-label m-b-8">
    <ng-container *ngIf="(participantProfile.workExperience || participantProfile.leadershipExperience); else noValueSpecified">
      {{participantProfile.workExperience}} {{workExperienceYearsLabel | translate}} / {{participantProfile.leadershipExperience}} {{leadershipYearsLabel | translate}}
    </ng-container>
  </p>

  <h4 class="card-list__item-value"><mh-explanation-label [labelKey]="'participant.location.label'"></mh-explanation-label></h4>
  <p class="card-list__item-label m-b-8">
    <mh-location-label [location]="participantProfile.location"></mh-location-label>
  </p>

  <h4 class="card-list__item-value"><mh-explanation-label [labelKey]="'profile.ideal-location.label'"></mh-explanation-label></h4>
  <p class="card-list__item-label m-b-8">
    <mh-location-label [location]="participantProfile.idealWorkingLocation"></mh-location-label>
  </p>
</ng-container>

<ng-template #noValueSpecified> - </ng-template>
