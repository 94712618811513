<!--  Empty sections are hidden in talent-profile-preview.less.-->
<nz-anchor [nzAffix]="false" [nzTargetOffset]="20">
  <nz-link nzHref="#contact-person" [nzTitle]="contactPersonIcon">
    <ng-template #contactPersonIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'microsite.navigation.contact-person' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="user" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>

  <nz-link nzHref="#company-name" [nzTitle]="companyNameIcon">
    <ng-template #companyNameIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'microsite.navigation.company-name' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="bank" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>

  <nz-link nzHref="#diversities" [nzTitle]="diversitiesIcon">
    <ng-template #diversitiesIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'microsite.navigation.diversities' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="global" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>

  <nz-link nzHref="#compliance-kpi" [nzTitle]="complianceKpiIcon">
    <ng-template #complianceKpiIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'microsite.navigation.compliance-kpi' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="line-chart" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>

  <nz-link nzHref="#invoice-details" [nzTitle]="invoiceDetailsIcon">
    <ng-template #invoiceDetailsIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'microsite.navigation.invoice-details' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="credit-card" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>

  <nz-link nzHref="#data-space" [nzTitle]="dataSpaceIcon">
    <ng-template #dataSpaceIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.data-space' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="database" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#profile-info" [nzTitle]="profileInfoIcon">
    <ng-template #profileInfoIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.profile-info' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#comments" [nzTitle]="commentsIcon">
    <ng-template #commentsIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.comments' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="message" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#references" [nzTitle]="referencesIcon">
    <ng-template #referencesIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.testimonials' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="solution" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
</nz-anchor>
