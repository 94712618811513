import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerDashboardNotificationSettingsComponent } from "./mission-partner-dashboard-notification-settings.component";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzButtonModule } from "ng-zorro-antd/button";
import { TranslateModule } from "@ngx-translate/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule } from "@angular/forms";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { WidgetModule } from "libs/common-ui/src/lib/widget/widget.module";

@NgModule({
  declarations: [MissionPartnerDashboardNotificationSettingsComponent],
  imports: [CommonModule, NzCardModule, NzButtonModule, TranslateModule, NzFormModule, FormsModule, NzCheckboxModule, WidgetModule],
  exports: [MissionPartnerDashboardNotificationSettingsComponent],
})
export class TalentDashboardNotificationSettingsModule {}
