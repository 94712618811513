import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DebugComponent } from "./debug.component";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { TranslateModule } from "@ngx-translate/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";

@NgModule({
  declarations: [DebugComponent],
  imports: [
    CommonModule,
    NzDividerModule,
    NzPageHeaderModule,
    TranslateModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    FormsModule,
    NzButtonModule,
    NzWaveModule,
  ],
})
export class DebugModule {}
