import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Talent} from "../../../../../../api/sign-up/signup.types";
import {Observable, Subject, takeUntil} from "rxjs";
import {CmsCachedService} from "../../../../../../api/cms/cms-cached.service";
import {CmsNameValue, compareCmsNameValue} from "../../../../../../api/cms/cms.types";

@Component({
  selector: "mh-profile-education",
  templateUrl: "./profile-education.component.html",
  styleUrls: ["./profile-education.component.less"],
})
export class ProfileEducationComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<boolean>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  public languageOptions$: Observable<Array<CmsNameValue>>;
  public compareCmsNameValue = compareCmsNameValue;

  constructor(private cmsCachedService: CmsCachedService, private fb: FormBuilder, private cd: ChangeDetectorRef) {
    this.languageOptions$ = this.cmsCachedService.getUserDataByName("language");
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      education: [this.talent.education, []],
      qualifications: [this.talent.qualifications || [], []],
      languages: [this.talent.languages || [], []],
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        education: value.education,
        qualifications: value.qualifications,
        languages: value.languages,
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm(skipNavigation = false) {
    if(!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit(skipNavigation);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
