import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NzCheckBoxOptionInterface } from "ng-zorro-antd/checkbox/checkbox-group.component";
import { TranslateService } from "@ngx-translate/core";
import { MAAnswerOption, MAAnswerOptionType } from "../../../api/assessment/assessment.types";
import { NzMarks } from "ng-zorro-antd/slider";

@Component({
  selector: "mh-assessment-number-select",
  templateUrl: "./assessment-number-select.component.html",
  styleUrls: ["./assessment-number-select.component.less"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssessmentNumberSelectComponent), multi: true },
  ],
})
export class AssessmentNumberSelectComponent implements ControlValueAccessor {
  @Input() set options(val: Array<MAAnswerOption>) {
    this._options = val
      .filter((maOpt) => maOpt.type === MAAnswerOptionType.SELECTABLE)
      .map((maOpt) => ({
        label: maOpt.id,
        value: maOpt.id,
      }));
  }
  _options: Array<NzCheckBoxOptionInterface> = [];
  private selection: Array<MAAnswerOption> = [];

  sliderValue?: number;

  numberSliderMarks: NzMarks = {
    1: "1",
    5: "5",
    10: "10",
  };

  constructor(private translate: TranslateService) {}

  onSliderModelChange(): void {
    this.selection.forEach((sel, index) => (sel.selected = index+1 == this.sliderValue));
    this.onChange(this.selection);
  }

  onChange = (value: Array<MAAnswerOption>) => {};
  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  writeValue(value: Array<MAAnswerOption>) {
    value = value || [];
    const selectedIndex = value.findIndex((val) => val.selected);
    this.sliderValue = selectedIndex + 1 || undefined;
    this.selection = value;
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
}
