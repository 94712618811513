import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { JobOfferFormComponent } from "./job-offer-form.component";
import { ExplanationLabelModule } from "../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocationMultipleInputModule } from "../../../../../../../../common-ui/src/lib/location-multiple-input/location-multiple-input.module";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { TranslateModule } from "@ngx-translate/core";
import { SalaryExpectationInputModule } from "../../../../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation-input.module";
import {CountryInputModule} from "../../../../../../../../common-ui/src/lib/country-input/country-input.module";
import {LocationMultipleLargeInputModule} from "@momhunting/common-ui";

@NgModule({
  declarations: [JobOfferFormComponent],
    imports: [
        CommonModule,
        ExplanationLabelModule,
        FormsModule,
        LocationMultipleInputModule,
        NzCheckboxModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        NzInputNumberModule,
        NzSelectModule,
        NzSliderModule,
        ReactiveFormsModule,
        TranslateModule,
        SalaryExpectationInputModule,
        CountryInputModule,
        LocationMultipleLargeInputModule
    ],
  exports: [JobOfferFormComponent],
})
export class JobOfferFormModule {}
