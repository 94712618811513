import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ReferencesService } from "./references.service";
import { Reference } from "./references.types";

@Injectable({
  providedIn: "root",
})
export class ReferencesResolver {
  constructor(private readonly referencesService: ReferencesService) {}

  resolve(): Observable<Array<Reference>> {
    return this.referencesService.loadReferences();
  }
}
