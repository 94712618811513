import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TrackRecord } from "../../api/sign-up/signup.types";

export function TrackRecordsInputValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as Array<TrackRecord>;

    if (!value || value.length == 0) {
      return { required: true };
    }

    const invalidItems = value.filter((item) => !ValidateTrackRecord(item));

    return invalidItems.length > 0 ? { required: true } : null;
  };
}

export function ValidateTrackRecord(trackRecord: TrackRecord): boolean {
  if (!trackRecord) return false;
  const hasUniversityName = trackRecord.companyName?.length > 0;
  const hasDateFrom = !!(trackRecord.employmentDurationRange?.[0]) || (!!trackRecord.workingUntilNow && !!trackRecord.employmentDurationFromToNow)
  const hasDateTo = !!(trackRecord.employmentDurationRange?.[1]) || !!trackRecord.workingUntilNow;

  return hasUniversityName && hasDateFrom && hasDateTo;
}
