import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { MessagesAction } from "./messages.action";
import { MessagesService } from "../../../api/messages/messages.service";
import { ProfileService } from "../../../api/profile/profile.service";

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private messagesService: MessagesService,
    private profileService: ProfileService,
  ) {}

  loadConversationList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.loadConversationList),
      switchMap(({ filter, tab }) => {
        return this.messagesService.loadConversationList(filter, tab).pipe(
          map((response) => MessagesAction.loadConversationListSuccess({ data: response })),
          catchError((error) => of(MessagesAction.loadConversationListError({ error }))),
        );
      }),
    );
  });

  loadConversation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.loadConversation),
      switchMap(({ id }) =>
        this.messagesService.loadConversation(id).pipe(
          map((data) => MessagesAction.loadConversationSuccess({ data })),
          catchError((error) => of(MessagesAction.loadConversationError({ error }))),
        ),
      ),
    );
  });

  updateAgreementTerms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.updateAgreementTerms),
      switchMap(({ id }) =>
        this.messagesService.loadConversation(id).pipe(
          map((data) => MessagesAction.updateAgreementTermsSuccess({ data })),
          catchError((error) => of(MessagesAction.updateAgreementTermsError({ error }))),
        ),
      ),
    );
  });

  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.sendMessage),
      switchMap(({ message }) =>
        this.messagesService.sendMessage(message).pipe(
          map((success) => MessagesAction.sendMessageSuccess({ success })),
          catchError((error) => of(MessagesAction.sendMessageError({ error }))),
        ),
      ),
    );
  });

  readMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.readMessages),
      switchMap(({ ids }) =>
        this.messagesService.readMessage(ids).pipe(
          map((ids) => MessagesAction.readMessagesSuccess({ ids })),
          catchError((error) => of(MessagesAction.readMessagesError({ error }))),
        ),
      ),
    );
  });

  getUnseenMessageCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.getUnseenMessageCount),
      switchMap(() =>
        this.messagesService.getUnseenMessageCount().pipe(
          map((count) => MessagesAction.getUnseenMessageCountSuccess({ count })),
          catchError((error) => of(MessagesAction.readMessagesError({ error }))),
        ),
      ),
    );
  });

  closeConversation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.closeConversation),
      switchMap(({ id }) =>
        this.messagesService.closeConversation(id).pipe(
          map((success) => MessagesAction.closeConversationSuccess({ id, success })),
          catchError((error) => of(MessagesAction.closeConversationError({ error }))),
        ),
      ),
    );
  });

  deleteConversation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.deleteConversation),
      switchMap(({ id }) =>
        this.messagesService.deleteConversation(id).pipe(
          map((success) => MessagesAction.deleteConversationSuccess({ id, success })),
          catchError((error) => of(MessagesAction.deleteConversationError({ error }))),
        ),
      ),
    );
  });

  loadConversationOlderMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.loadOlderMessages),
      switchMap(({ conversationId, lastMsgId }) =>
        this.messagesService.loadOlderMessages(conversationId, lastMsgId).pipe(
          map((response) => MessagesAction.loadOlderMessagesSuccess({ data: response })),
          catchError((error) => of(MessagesAction.loadOlderMessagesError({ error }))),
        ),
      ),
    );
  });

  requestProfileAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.requestProfileAccess),
      switchMap(({ talentId, conversationId }) =>
        this.profileService.requestProfileAccess(talentId).pipe(
          map((data) => MessagesAction.requestProfileAccessSuccess({ data, conversationId })),
          catchError((error) => of(MessagesAction.requestProfileAccessError({ error }))),
        ),
      ),
    );
  });
  grantProfileAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.grantProfileAccess),
      switchMap(({ missionPartnerId }) =>
        this.profileService.grantProfileAccess(missionPartnerId).pipe(
          map((data) => MessagesAction.grantProfileAccessSuccess({ data })),
          catchError((error) => of(MessagesAction.grantProfileAccessError({ error }))),
        ),
      ),
    );
  });
  rejectProfileAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.rejectProfileAccess),
      switchMap(({ missionPartnerId }) =>
        this.profileService.rejectProfileAccess(missionPartnerId).pipe(
          map((data) => MessagesAction.rejectProfileAccessSuccess({ data })),
          catchError((error) => of(MessagesAction.rejectProfileAccessError({ error }))),
        ),
      ),
    );
  });

  proposeAgreementTerms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.proposeAgreementTerms),
      switchMap(({ conversationId, terms }) =>
        this.messagesService.proposeAgreementTerms(conversationId, terms).pipe(
          map((data) => MessagesAction.proposeAgreementTermsSuccess({ data })),
          catchError((error) => of(MessagesAction.proposeAgreementTermsError({ error }))),
        ),
      ),
    );
  });

  respondToAgreementTerms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesAction.respondToAgreementTerms),
      switchMap(({ conversationId, accepted }) =>
        this.messagesService.respondToAgreementTerms(conversationId, accepted).pipe(
          map((data) => MessagesAction.respondToAgreementTermsSuccess({ data })),
          catchError((error) => of(MessagesAction.respondToAgreementTermsError({ error }))),
        ),
      ),
    );
  });
}
