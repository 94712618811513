import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentNotificationsComponent } from "./talent-notifications.component";
import { NotificationSettingsModule } from "../../../../common-componnets/notification-settings/notification-settings.module";

@NgModule({
  declarations: [TalentNotificationsComponent],
  imports: [
    CommonModule,
    NotificationSettingsModule,
  ],
})
export class TalentNotificationsModule {}
