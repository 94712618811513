import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { ApiResponseDTO } from "../common.types";
import { MissionPartnerDTO, TalentDTO } from "./signup-api.types";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";

@Injectable({ providedIn: "root" })
export class SignupApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  registerTalent(talent: TalentDTO): Observable<string> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/registration/register/talent/initial`, talent).pipe(
      map((response) => {
        if (response.success) {
          return response.results.token;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  registerMissionPartner(missionPartner: MissionPartnerDTO): Observable<string> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/registration/register/mission-partner/initial`, missionPartner)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.token;
          } else {
            super.handleErrorResponse(response);
            return "";
          }
        }),
      );
  }

  phoneValidationSend(phoneNumber: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/registration/phone-verification/send`, { phoneNumber })
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  phoneValidationCheck(phoneNumber: string, verificationCode: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/registration/phone-verification/check`, { phoneNumber, verificationCode })
      .pipe(
        map((response) => {
          if (response.success) {
            return true;
          } else {
            super.handleErrorResponse(response);
            return false;
          }
        }),
      );
  }

  resendConfirmationEmail(): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/registration/email-confirmation/resend`, {}).pipe(
      map((response) => {
        if (response.success) {
          return true;
        } else {
          super.handleErrorResponse(response);
          return false;
        }
      }),
    );
  }

  resendConfirmationEmailChange(): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/email-change-confirmation/resend`, {}).pipe(
      map((response) => {
        if (response.success) {
          return true;
        } else {
          super.handleErrorResponse(response);
          return false;
        }
      }),
    );
  }

  completeRegistration(): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/registration/complete`, {}).pipe(
      map((response) => {
        if (response.success) {
          return true;
        } else {
          super.handleErrorResponse(response);
          return false;
        }
      }),
    );
  }


}
