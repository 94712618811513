import { Component } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";

@Component({
  selector: "mh-talent-profile-cv-dialog",
  templateUrl: "./talent-profile-cv-dialog.component.html",
  styleUrls: ["./talent-profile-cv-dialog.component.less"],
})
export class TalentProfileCvDialogComponent {
  constructor(private modal: NzModalRef) {}

  handleOk(): void {
    this.modal.close();
  }
}
