import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AssessmentService } from "./assessment.service";
import {MAAssessmentFormError, MaturityAssessmentForm} from "./assessment.types";
import { ActivatedRouteSnapshot} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AssessmentFormResolver {
  constructor(private assessmentService: AssessmentService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<MaturityAssessmentForm | MAAssessmentFormError> {
    return this.assessmentService.getMaturityAssessmentForm(route.params['token']);
  }
}
