import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { catchError, map, Observable } from "rxjs";
import { ApiPaginatedResponseDTO, ApiResponseDTO, initialPaginatedContent, PaginatedContent } from "../common.types";
import { AccountFilterDTO, NegotiationStatus } from "../../pages/home-admin/home-admin-data/account.types";
import {
  AgreementTermsDTO,
  ConversationDTO,
  InitiateConversationRequest,
  MessagingMessageDTO,
  MissionPartnerMessageSuggest,
  TalentMessageSuggest,
} from "./messages.types";

@Injectable({ providedIn: "root" })
export class MessagesApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  initiateConversation(partnerId: string, messageText: string, jobOpportunityId?: string): Observable<ConversationDTO> {
    const request: InitiateConversationRequest = {
      partnerId: { internalId: partnerId },
      messageText,
      jobOpportunityId,
    };
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/messaging/conversation/initiate`, { ...request })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }

  loadConversationList(filter: AccountFilterDTO, tab?: string): Observable<PaginatedContent<ConversationDTO>> {
    return this.httpClient
      .post<ApiPaginatedResponseDTO<any>>(`${this.basePath}/messaging/conversation/list${tab ? `/${tab}` : ""}`, {
        ...filter.paging,
      })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return initialPaginatedContent;
          }
        }),
        catchError(super.handleHttpError),
      );
  }

  loadConversation(id: string): Observable<ConversationDTO> {
    const amount = 20;
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/messaging/conversation/${id}/${amount}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  closeConversation(conversationId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/messaging/conversation/close/${conversationId}`, { conversationId })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  deleteConversation(conversationId: string): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/messaging/conversation/delete/${conversationId}`, { conversationId })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.success;
        }),
      );
  }

  loadOlderMessages(conversationId: string, lastMsgId: string): Observable<Array<MessagingMessageDTO>> {
    const amount = 20;
    return this.httpClient
      .get<ApiResponseDTO>(
        `${this.basePath}/messaging/conversation/messages/list/${conversationId}/${lastMsgId}/${amount}`,
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
          }
        }),
      );
  }

  getMessageSuggestionTalent(request: TalentMessageSuggest): Observable<Array<string>> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/messaging/message-suggestion/talent`, request).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.results.data;
      }),
    );
  }

  getMessageSuggestionMissionPartner(request: MissionPartnerMessageSuggest): Observable<Array<string>> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/messaging/message-suggestion/mission-partner`, request)
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }

  loadConversationByAccountId(accountId: string): Observable<ConversationDTO | null> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/messaging/conversation/${accountId}`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }

  getUnseenMessageCount(): Observable<number> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/messaging/badges/unseen-messages`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data || 0;
        } else {
          super.handleErrorResponse(response);
        }
      }),
    );
  }
  
  proposeAgreementTerms(conversationId: string, terms: AgreementTermsDTO): Observable<NegotiationStatus> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/messaging/conversation/agreement_termsproposal/${conversationId}`, terms)
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }

  respondToAgreementTerms(conversationId: string, accepted: boolean): Observable<unknown> {
    return this.httpClient
      .post<ApiResponseDTO>(`${this.basePath}/messaging/conversation/agreement_termsresponse/${conversationId}`, {
        accepted,
      })
      .pipe(
        map((response) => {
          if (!response.success) {
            super.handleErrorResponse(response);
          }
          return response.results.data;
        }),
      );
  }
}
