import { Inject, Injectable, Injector } from "@angular/core";
import { BaseApi } from "../base-api";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { map, Observable } from "rxjs";
import { ApiResponseDTO } from "../common.types";
import {
  JobOffersInfo,
  InvoicesInfo,
  TalentProfileCompletenessDTO,
  DashboardConversationInfoDTO,
  MissionPartnerProfileCompletenessDTO,
} from "./dashboard.types";

@Injectable({ providedIn: "root" })
export class DashboardApi extends BaseApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  getTalentProfileCompleteness(): Observable<TalentProfileCompletenessDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/dashboard/talent/profile-completeness`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  getMissionPartnerProfileCompleteness(): Observable<MissionPartnerProfileCompletenessDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/dashboard/mission-partner/profile-completeness`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  getMissionPartnerJobOffers(): Observable<JobOffersInfo> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/dashboard/mission-partner/job-opportunities`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  getTalentLastReplies(): Observable<DashboardConversationInfoDTO> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/dashboard/mission-partner/conversations/talent-last-replies`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return "";
          }
        }),
      );
  }

  getProactiveContactAttempts(): Observable<DashboardConversationInfoDTO> {
    return this.httpClient
      .get<ApiResponseDTO>(`${this.basePath}/dashboard/mission-partner/conversations/proactive-contact-attempts`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.results.data;
          } else {
            super.handleErrorResponse(response);
            return "";
          }
        }),
      );
  }

  getInvoicesNew(): Observable<InvoicesInfo> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/dashboard/mission-partner/invoices`).pipe(
      map((response) => {
        if (response.success) {
          return response.results;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }

  getHirings(): Observable<DashboardConversationInfoDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/dashboard/mission-partner/conversations/hirings`).pipe(
      map((response) => {
        if (response.success) {
          return response.results.data;
        } else {
          super.handleErrorResponse(response);
          return "";
        }
      }),
    );
  }
}
