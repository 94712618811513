import {Injectable} from "@angular/core";
import {JobOffer, JobOpportunitySearchResultStatus} from "../../../../../api/job-offer/job-offer.types";

@Injectable({ providedIn: "root" })
export class JobOfferEntityFactory {
  getNewInstance(): JobOffer {
    return {
      id: "",
      missionPartnerId: '',
      missionPartnerName: '',
      archived: false,
      name: '',
      description: '',
      salaryExpectations: undefined,
      locations: [],
      mode: undefined,
      jobSharing: false,
      domainExpertise: [],
      domainPreference: [],
      workExperience: 0,
      leadershipExperience: 0,
      leadershipTeamSize: undefined,
      sectorFocus: [],
      workLanguage: undefined,
      matchedAmount: 0,
      status: JobOpportunitySearchResultStatus.DONE,
      creationTimestamp: 0,
    };
  }
}
