<div *ngIf="question.type === AssessmentQuestionType.DROPDOWN">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <span class="question-num">{{ question.number }}</span>
          <mh-explanation-label [labelKey]="question.title"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <mh-assessment-dropdown-select
            [options]="question.answerOptions"
            [ngModel]="question.answerOptions"
            (ngModelChange)="onAnswersModelChange($event)"
            [ngModelOptions]="{ standalone: true }"
          ></mh-assessment-dropdown-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</div>

<div *ngIf="question.type === AssessmentQuestionType.RADIO">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <span class="question-num">{{ question.number }}</span>
          <mh-explanation-label [labelKey]="question.title"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <mh-assessment-radio-group-select
            [ngModel]="question.answerOptions"
            (ngModelChange)="onAnswersModelChange($event)"
            [ngModelOptions]="{ standalone: true }"
          ></mh-assessment-radio-group-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</div>

<div *ngIf="question.type === AssessmentQuestionType.CHECKBOX">
  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label>
        <span class="question-num">{{ question.number }}</span>
        <mh-explanation-label [labelKey]="question.title"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control>
        <mh-assessment-check-box-group-select
          [options]="question.answerOptions"
          [ngModel]="question.answerOptions"
          (ngModelChange)="onAnswersModelChange($event)"
          [ngModelOptions]="{ standalone: true }"
        ></mh-assessment-check-box-group-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>

<div *ngIf="question.type === AssessmentQuestionType.NUMBER">
  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label>
        <span class="question-num">{{ question.number }}</span>
        <mh-explanation-label [labelKey]="question.title"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control>
        <mh-assessment-number-select
          [options]="question.answerOptions"
          [ngModel]="question.answerOptions"
          (ngModelChange)="onAnswersModelChange($event)"
          [ngModelOptions]="{ standalone: true }"
        ></mh-assessment-number-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>

<div *ngIf="question.type === AssessmentQuestionType.FREE_TEXT" nz-row [nzGutter]="24">
  <div nz-col [nzSpan]="24">
    <nz-form-item>
      <nz-form-label>
        <span class="question-num">{{ question.number }}</span>
        <mh-explanation-label [labelKey]="question.title"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control>
        <textarea
          rows="3"
          type="text"
          nz-input
          [placeholder]="'assessment.answer.placeholder' | translate"
          [(ngModel)]="question.answerOptions[0].value"
          (ngModelChange)="onFreeTextChange(question.answerOptions[0])"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
