import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { DocumentService } from "../../api/document/document.service";
import { catchError, Subject, takeUntil, throwError } from "rxjs";
import { DocumentSerializer } from "../../api/document/document.serializer";
import { AudioRecordingService } from "../../../../../core/src/lib/recording/audio-recording.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AudioFile } from "../../../../../core/src/lib/recording/recording.types";

@Component({
  selector: "mh-profile-audio-upload",
  templateUrl: "./profile-audio-upload.component.html",
  styleUrls: ["./profile-audio-upload.component.less"],
})
export class ProfileAudioUploadComponent implements OnInit, OnDestroy {
  @Input() profileAudio: AudioFile | null = null;
  @Output() profileAudioChange = new EventEmitter<AudioFile | null>();

  private readonly destroy$ = new Subject<void>();
  isAudioRecording = false;
  audioRecordedTime = '';
  audioBlobUrl?: SafeUrl | null;
  audioBlob?: Blob;
  audioName = '';

  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private documentsService: DocumentService,
    private documentSerializer: DocumentSerializer,
    private audioRecordingService: AudioRecordingService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    this.audioRecordingService.recordingFailed().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isAudioRecording = false;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedTime().pipe(takeUntil(this.destroy$)).subscribe((time) => {
      this.audioRecordedTime = time;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedBlob().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data.blob) {
        this.audioBlob = data.blob;
        this.audioName = data.title;
        this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
        this.ref.detectChanges();
        this.handleUpload();
      }
    });
  }

  ngOnInit() {
    if (this.profileAudio) {
      this.audioName = this.profileAudio.name;
      this.audioBlobUrl = this.profileAudio.fileUrl ? `${this.appConfig.siteUrl}/${this.profileAudio.fileUrl}` : '';
    }
  }

  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      this.isAudioRecording = false;
    }
  }

  handleUpload(): void {
    if (this.audioBlob) {
      const formData = new FormData();
      formData.append("file", this.audioBlob, this.audioName);
      this.documentsService
        .uploadProfileAudio(formData)
        .pipe(
          catchError((error) => {
            return throwError(() => new Error(error));
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((audioFile) => {
          this.profileAudioChange.emit(audioFile);
        });
    }
  }

  handleRemove(): void {
    if (!this.profileAudio?.id) {
      return;
    }
    this.documentsService.deleteUserFile(this.profileAudio.id).pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result) {
        this.audioBlobUrl = null;
        this.profileAudioChange.emit(null);
      }
    });
  };

  ngOnDestroy() {
    this.destroy$.next();
    this.abortAudioRecording();
  }
}
