import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { ChatUserInput } from "../messages-chat.types";
import { NzModalService } from "ng-zorro-antd/modal";
import { MhMessageRecordVideoDialogComponent } from "../mh-message-record-video-dialog/mh-message-record-video-dialog.component";
import { MhMessageRecordAudioDialogComponent } from "../mh-message-record-audio-dialog/mh-message-record-audio-dialog.component";

@Component({
  selector: "mh-message-input",
  templateUrl: "./mh-message-input.component.html",
  styleUrls: ["./mh-message-input.component.less"],
})
export class MhMessageInputComponent implements OnDestroy {
  @Output() sendMessage = new EventEmitter<ChatUserInput>();
  @Input() sending = false;
  @Input() disabled = false;
  @Input() isMobile = false;
  private subscriptions: Subscription[] = [];

  inputValue = "";

  @ViewChild("fileInput") private fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild("textInput") private textInput!: ElementRef<HTMLInputElement>;
  emojiInput$ = new Subject<string>();

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.subscriptions.push(this.emojiInput$.subscribe((input) => (this.inputValue += input)));
  }

  handleSubmit(): void {
    const content = this.inputValue;
    this.inputValue = "";
    this.doSendMessage({ content });
    this.textInput.nativeElement.focus();
  }

  filesSelected(fileList: FileList | null) {
    if (fileList?.length) {
      const content = this.inputValue;
      this.doSendMessage({content, fileList})
      this.inputValue = "";
      this.clearFileInput();
    }
  }

  private doSendMessage(userInput: ChatUserInput) {
    this.sendMessage.emit(userInput);
  }

  private clearFileInput() {
    this.fileInput.nativeElement.value = "";
  }

  openRecordVideoModal() {
    const modal = this.modal.create({
      nzContent: MhMessageRecordVideoDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzBodyStyle: {
        padding: '0',
      },
      nzOnOk: (modalCmp) => {
        if (modalCmp.fileList) {
          this.filesSelected(modalCmp.fileList)
        }
      },
    });
  }

  openRecordAudioModal() {
    const modal = this.modal.create({
      nzContent: MhMessageRecordAudioDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClosable: false,
      nzCentered: true,
      nzFooter: null,
      nzBodyStyle: {
        padding: '0',
      },
      nzOnOk: (modalCmp) => {
        if (modalCmp.fileList) {
          this.filesSelected(modalCmp.fileList)
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
