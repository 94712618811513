<nz-page-header [nzTitle]="'profile.experience.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24" formGroupName="workExperience">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.work-exp.label'">
            {{ "profile.work-exp.label" | translate }} <span [innerHTML]="experienceYears"></span>
          </mh-explanation-label>
        </nz-form-label>
        <div nz-row>
          <div nz-col [nzSpan]="22" nzOffset="1" [nzMd]="{ span: 24, offset: 0 }">
            <nz-form-control>
              <nz-slider
                [nzMin]="0"
                [nzMax]="30"
                nzTooltipVisible="never"
                formControlName="yearsOfExperience"
              ></nz-slider>
            </nz-form-control>
          </div>
        </div>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.leadership-exp.label'">
            {{ "profile.leadership-exp.label" | translate }} <span [innerHTML]="leadershipYears"></span>
          </mh-explanation-label>
        </nz-form-label>
        <div nz-row>
          <div nz-col [nzSpan]="22" nzOffset="1" [nzMd]="{ span: 24, offset: 0 }">
            <nz-form-control>
              <nz-slider
                [nzMin]="0"
                [nzMax]="30"
                nzTooltipVisible="never"
                formControlName="yearsOfLeadership"
              ></nz-slider>
            </nz-form-control>
          </div>
        </div>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8" [nzSm]="6">
      <nz-form-label class="p-lr-0">
        <mh-explanation-label [labelKey]="'profile.leadership-team-size.label'"></mh-explanation-label>
      </nz-form-label>
    </div>
    <div nz-col [nzSpan]="16" [nzSm]="18">
      <nz-form-label class="p-lr-0">
        <mh-explanation-label [labelKey]="'profile.career-level.label'"></mh-explanation-label>
      </nz-form-label>
    </div>
    <div nz-col [nzSpan]="8" [nzSm]="6">
      <nz-form-item>
        <nz-form-control>
          <nz-input-number
            [nzMin]="1"
            [nzStep]="1"
            nzInputMode="numeric"
            formControlName="leadershipTeamSize"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="16" [nzSm]="18">
      <nz-form-item>
        <nz-form-control>
          <nz-select nzSize="default" formControlName="careerLevel" [compareWith]="compareCmsNameValue" nzShowSearch>
            <nz-option
              [nzLabel]="option.name | translate"
              [nzValue]="option"
              *ngFor="let option of careerLevel$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24" formGroupName="workExperience">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.expert-fields.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            [nzMaxMultipleCount]="11"
            (ngModelChange)="onExpertFieldsChange($event)"
            [nzPlaceHolder]="'select.placeholder' | translate"
            [compareWith]="compareCmsNameValue"
            formControlName="expertFields"
          >
            <nz-option
              *ngFor="let option of expertFields$ | async"
              [nzLabel]="option.name | translate"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
        <div class="ant-form-item-explain-error" *ngIf="getExpertFieldsControl()?.invalid">{{'max-allowed-num.warning' | translate:{max: 10}  }}</div>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.expert-fields-other.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <mh-suggestions-input formControlName="otherExpertFields" [fieldName]="'expert-fields'"></mh-suggestions-input>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <!-- profile.skills.title -->
          <mh-explanation-label [labelKey]="'profile.skills.subtitle'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <mh-suggestions-input formControlName="skills" [fieldName]="'skills'"></mh-suggestions-input>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="divider-button-wrapper">
    <mh-explanation-label [labelKey]="'profile.track-record.label'" [divider]="true"></mh-explanation-label>
    <button class="divider-button-wrapper__btn" nz-button nzType="default" nzShape="circle" (click)="trackRecordsInput.addNewTrackRecord()">
      <span nz-icon nzType="plus"></span>
    </button>
  </div>

  <mh-old-track-record-view
    *ngIf="showOldTrackRecord"
    [oldTrackRecord]="$any(talent.oldTrackRecord)"
    (deleteTrackRecord)="onDeleteOldTrackRecord()"
  ></mh-old-track-record-view>

  <div nz-row>
    <div nz-col [nzSpan]="24" style="padding-bottom: 24px">
      <mh-track-records-input formControlName="trackRecord" [value]="talent.trackRecord" [showAddBtn]="false" #trackRecordsInput
        (trackRecordSaved)="submitForm()"></mh-track-records-input>
    </div>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()" [disabled]="trackRecordsInput.editingIndex !== null">
        {{ (talent.filledForTheFirstTime ? "profile.save.button" : "profile.finish.button") | translate }}
      </button>
    </div>
  </div>
</form>
