import { MissionPartnerInboxComponent } from "./mission-partner-inbox.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MissionPartnerConversationComponent } from "./subpages/mission-partner-conversation/mission-partner-conversation.component";
import { MessagesConversationResolver } from "../../../../common-componnets/messages/messages-conversation.resolver";
import { LoginDataResolver } from "../../../login-page/login-page-data/login-data.resolver";

const routes: Routes = [
  {
    path: "",
    component: MissionPartnerInboxComponent,
    resolve: {
      loginData: LoginDataResolver,
    }
  },
  {
    path: "conversation/:id",
    component: MissionPartnerConversationComponent,
    resolve: {
      conversation: MessagesConversationResolver,
    },
    runGuardsAndResolvers: "always"
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MissionPartnerInboxRoutingModule {}
