<nz-layout class="auth auth--reset-password">
  <div nz-row class="auth__container">
    <div nz-col nzSpan="24" class="auth__content">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24" class="auth__logo">
          <div class="auth__logo-wrap">
            <img class="logo-img" src="/assets/images/logo.svg" alt="Momhunting logo" />
          </div>
        </div>
        <div nz-col nzSpan="24">
          <h1 class="auth__title">{{'account-confirmed.title' | translate}}</h1>
        </div>
        <div nz-col nzSpan="24">
          <p class="auth__text">
            {{'account-confirmed.text' | translate:{email: (user$ | async)?.email} }}
          </p>
        </div>
        <div class="auth__form auth__form--center">
          <button nz-button [nzType]="isMdBreakpoint ? 'default' : 'primary'" nzSize="large" class="ant-btn-primary" [routerLink]="['/login']">
            {{'account-confirmed.back' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</nz-layout>
