import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LoginData } from "./login.types";
import { Store } from "@ngrx/store";
import { loginFeatureName, LoginState } from "./store/login.reducer";
import { selectLoginData } from "./store/login.selectors";

@Injectable({
  providedIn: "root",
})
export class LoginDataResolver {
  constructor(private store: Store<{ [loginFeatureName]: LoginState }>) {}

  resolve(): Observable<LoginData> {
    return this.store.select(selectLoginData);
  }
}
