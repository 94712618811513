import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupMissionPartnerGeneralComponent } from "./signup-mission-partner-general.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { NzSelectModule } from "ng-zorro-antd/select";
import { PasswordValidationTipsModule } from "../../../../../../../common-ui/src/lib/password-validation-tips/password-validation-tips.module";
import { ExplanationLabelModule } from "libs/common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [SignupMissionPartnerGeneralComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzCheckboxModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzWaveModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterLink,
    NzSelectModule,
    PasswordValidationTipsModule,
    ExplanationLabelModule,
  ],
  exports: [SignupMissionPartnerGeneralComponent],
})
export class SignupMissionPartnerGeneralModule {}
