import { Injectable } from "@angular/core";
import { Talent } from "../sign-up/signup.types";

@Injectable({ providedIn: "root" })
export class TalentProfileFactory {
  getNewInstance(): Talent {
    return {
      salutation: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reasonWhy: undefined,
      phoneNumber: "",
      education: [],
      workExperience: {},
      trackRecord: [],
      oldTrackRecord: { companyName: "" },
      desiredJob: {},
      desiredPlaceOfWork: {},
      desiredSchedule: {},
      contactData: { postalCode: "", city: "", country: "" },
      profileAudio: null,
      filledForTheFirstTime: false,
      updateTimestamp: 0,
      communityMember: false,
      deanonymized: false,
    };
  }
}
