import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class UserNewsletterSettingResolver {
  constructor(private userService: UserService) {}

  resolve(): Observable<boolean> {
    return this.userService.getMyNewsletter();
  }
}
