<div nz-row [nzGutter]="12" [formGroup]="controlGroup">
  <div nz-col [nzSpan]="zipSpan">
    <nz-form-item>
      <nz-form-label *ngIf="showLabels" nzRequired>{{'reg.talent.zip.label' | translate}}</nz-form-label>
      <nz-form-control [nzErrorTip]="zipErrorTpl">
        <nz-input-group [nzSuffix]="suffixTemplateInfo" [nzSize]="nzSize" [nzStatus]="groupError ? 'error' : ''">
        <input
          nz-input
          [placeholder]="'reg.talent.zip.label' | translate"
          formControlName="postalCode"
          (focus)="handleZipFocus()"
          (blur)="handleZipBlur()"/>
        </nz-input-group>
        <ng-template #suffixTemplateInfo>
          <span *ngIf="loading" nz-icon nzType="loading" nzTheme="outline"></span>
        </ng-template>
      </nz-form-control>
      <ng-template #zipErrorTpl let-control>
        <ng-container *ngIf="control.hasError('minlength')">Invalid code</ng-container>
      </ng-template>
    </nz-form-item>
  </div>
  <div *ngIf="!zipOnly" nz-col [nzSpan]="distanceInput ? 7 : 9">
    <nz-form-item>
      <nz-form-label *ngIf="showLabels">{{'reg.talent.city.label' | translate}}</nz-form-label>
      <nz-form-control>
        <nz-select [nzSize]="nzSize" [nzPlaceHolder]="'reg.talent.city.label' | translate" formControlName="city">
          <nz-option [nzValue]="option" [nzLabel]="option" *ngFor="let option of cityOptions"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div *ngIf="!zipOnly" nz-col [nzSpan]="distanceInput ? 7 : 9">
    <nz-form-item>
      <nz-form-label *ngIf="showLabels">{{'reg.talent.country.label' | translate}}</nz-form-label>
      <nz-form-control>
        <input nz-input [nzSize]="nzSize" [placeholder]="'reg.talent.country.label' | translate" formControlName="country"/>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzSpan]="zipOnly ? 12 : 6" *ngIf="distanceInput">
    <nz-form-item>
      <nz-form-label *ngIf="showLabels">
        <mh-explanation-label [labelKey]="'admin.jo.radius-km.label'"></mh-explanation-label>
      </nz-form-label>
      <nz-form-control>
        <nz-select nzSize="default" formControlName="radius"
                   [nzPlaceHolder]="'admin.jo.radius-km.label' | translate"
                   [compareWith]="compareCmsNameValue"
                   nzAllowClear
        >
          <nz-option
            *ngFor="let option of (radiusOptions$ | async)"
            [nzLabel]="option.name | translate" [nzValue]="option"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
