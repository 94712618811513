import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentDashboardNotificationSettingsComponent } from "./talent-dashboard-notification-settings.component";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzButtonModule } from "ng-zorro-antd/button";
import { TranslateModule } from "@ngx-translate/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule } from "@angular/forms";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";

@NgModule({
  declarations: [TalentDashboardNotificationSettingsComponent],
  imports: [CommonModule, NzCardModule, NzButtonModule, TranslateModule, NzFormModule, FormsModule, NzCheckboxModule],
  exports: [TalentDashboardNotificationSettingsComponent],
})
export class TalentDashboardNotificationSettingsModule {}
