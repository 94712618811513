import { Injectable } from "@angular/core";
import { DocumentApi } from "./document.api";
import { map, Observable } from "rxjs";
import { DocumentSerializer } from "./document.serializer";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { AudioFile, PresentationMedia } from "../../../../../core/src/lib/recording/recording.types";
import {DocumentFileDTO, MessagingFileDTO} from "./document.types";
import {AssessmentSerializer} from "../assessment/assessment.serializer";
import {MaturityAssessment} from "../assessment/assessment.types";

@Injectable({ providedIn: "root" })
export class DocumentService {
  constructor(private documentApi: DocumentApi, private documentSerializer: DocumentSerializer,
              private assessmentSerializer:AssessmentSerializer) {}

  loadAllUserFiles(accountId?: string): Observable<Array<NzUploadFile>> {
    return this.documentApi
      .loadUserFileList(accountId)
      .pipe(map((dtos) => this.documentSerializer.deserializeDocuments(dtos)));
  }

  loadUserCV(accountId?: string): Observable<Array<NzUploadFile>> {
    return this.documentApi
      .loadUserCV(accountId)
      .pipe(map((dtos) => this.documentSerializer.deserializeDocuments(dtos)));
  }

  deleteUserFile(fileId: string): Observable<boolean> {
    return this.documentApi.deleteUserFile(fileId);
  }

  uploadProfileAudio(formData: FormData): Observable<AudioFile> {
    return this.documentApi.uploadProfileAudio(formData);
  }

  uploadPresentationMedia(formData: FormData): Observable<PresentationMedia> {
    return this.documentApi.uploadPresentationMedia(formData);
  }

  uploadMessagingFile(formData: FormData): Observable<MessagingFileDTO> {
    return this.documentApi.uploadMessagingFile(formData);
  }

  uploadMAReport(formData: FormData): Observable<MaturityAssessment> {
    return this.documentApi.uploadMAReport(formData)
      .pipe(map(dto=> this.assessmentSerializer.deserializeMaturityAssessment(dto)));
  }
}
