import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";

@Component({
  selector: "mh-password-validation-tips",
  templateUrl: "./password-validation-tips.component.html",
  styleUrls: ["./password-validation-tips.component.less"],
})
export class PasswordValidationTipsComponent {
  @Input() passwordControl: FormControl = new FormControl();
  @Input() showBackground: boolean = false;

  constructor() { }
}
