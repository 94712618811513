import { Injectable } from "@angular/core";
import { JobOfferSerializer } from "../job-offer/job-offer.serializer";
import { AccountFilter } from "../../pages/home-admin/home-admin-data/account.types";
import { map, Observable } from "rxjs";
import { PaginatedContent } from "../common.types";
import { JobOffer, JobOfferDTO } from "../job-offer/job-offer.types";
import { AccountSerializer } from "../../pages/home-admin/home-admin-data/account.serializer";
import { AdminJobOfferApi } from "./admin-job-offer.api";

@Injectable({ providedIn: "root" })
export class AdminJobOfferService {
  constructor(
    private adminJobOfferApi: AdminJobOfferApi,
    private jobOfferSerializer: JobOfferSerializer,
    private accountSerializer: AccountSerializer,
  ) {}

  loadJobOfferList(filter: AccountFilter): Observable<PaginatedContent<JobOffer>> {
    return this.adminJobOfferApi.loadJobOfferList(this.accountSerializer.serializeFilter(filter)).pipe(
      map((paginatedContent) => {
        return {
          ...paginatedContent,
          items: this.jobOfferSerializer.deserializeJobOffers(paginatedContent.items as Array<JobOfferDTO>),
          timeRange: filter.timeRange,
          paging: {
            page: paginatedContent.paging.page + 1,
            itemsOnPage: paginatedContent.paging.itemsOnPage,
            items: paginatedContent.paging.items,
          },
        };
      }),
    );
  }

  getJobOpportunity(id: string): Observable<JobOffer> {
    return this.adminJobOfferApi
      .getJobOpportunity(id)
      .pipe(map((dto) => this.jobOfferSerializer.deserializeJobOffer(dto)));
  }

  createJobOpportunity(jobOffer: JobOffer): Observable<boolean> {
    return this.adminJobOfferApi.createJobOpportunity(this.jobOfferSerializer.serializeJobOffer(jobOffer));
  }
  updateJobOpportunity(jobOffer: JobOffer): Observable<JobOffer> {
    return this.adminJobOfferApi.updateJobOpportunity(this.jobOfferSerializer.serializeJobOffer(jobOffer)).pipe(map(result => this.jobOfferSerializer.deserializeJobOffer(result)));
  }
  archiveJobOpportunity(jobOfferId: string): Observable<boolean> {
    return this.adminJobOfferApi.archiveJobOpportunity(jobOfferId);
  }
  deleteJobOpportunity(jobOfferId: string): Observable<boolean> {
    return this.adminJobOfferApi.deleteJobOpportunity(jobOfferId);
  }

  archiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    return this.adminJobOfferApi.archiveJobOfferMatch(jobOfferId, talentId);
  }

  unArchiveJobOfferMatch(jobOfferId: string, talentId: string): Observable<boolean> {
    return this.adminJobOfferApi.unArchiveJobOfferMatch(jobOfferId, talentId);
  }

  setTalentFavourite(jobOfferId: string, talentId: string, setFavourite: boolean): Observable<boolean> {
    return this.adminJobOfferApi.setTalentFavourite(jobOfferId, talentId, setFavourite);
  }

  downloadJobOfferList(fileName: string): Observable<void> {
    return this.adminJobOfferApi.downloadJobOfferList(fileName);
  }

  setJobOpportunityStage(jobOpportunityId: string, stage: number): Observable<boolean> {
    return this.adminJobOfferApi.setJobOpportunityStage(jobOpportunityId, stage);
  }

  setTalentStage(jobOpportunityId: string, accountId: string, stage: number): Observable<boolean> {
    return this.adminJobOfferApi.setTalentStage(jobOpportunityId, accountId, stage);
  }

  setJobOpportunityForMissionPartner(jobOpportunityId: string, accountId: string): Observable<boolean> {
    return this.adminJobOfferApi.setJobOpportunityForMissionPartner(jobOpportunityId, accountId);
  }
}
