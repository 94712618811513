import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrackRecordsInputComponent } from "./track-records-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { LocationSelectModule } from "../location-select/location-select.module";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzFormModule } from "ng-zorro-antd/form";
import { ExplanationLabelModule } from "../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { EmploymentDurationLabelModule } from "@momhunting/common-ui";
import { NzSwitchModule } from "ng-zorro-antd/switch";

@NgModule({
  declarations: [TrackRecordsInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    LocationSelectModule,
    NzCollapseModule,
    NzButtonModule,
    NzIconModule,
    NzFormModule,
    ExplanationLabelModule,
    NzInputModule,
    NzSelectModule,
    NzCardModule,
    NzDatePickerModule,
    NzPopconfirmModule,
    EmploymentDurationLabelModule,
    NzSwitchModule,
  ],
  exports: [TrackRecordsInputComponent],
})
export class TrackRecordsInputModule {}
