import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import {ContactPerson, MissionPartner} from "../../../../../api/sign-up/signup.types";
import { SignupService } from "../../../../../api/sign-up/signup.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import {ProfileService} from "../../../../../api/profile/profile.service";

@Component({
  selector: "mh-mission-partner-settings-phone",
  templateUrl: "./mission-partner-settings-phone.component.html",
  styleUrls: ["./mission-partner-settings-phone.component.less"],
})
export class MissionPartnerSettingsPhoneComponent implements OnInit {
  @Input() profile!: MissionPartner;
  public formGroup!: FormGroup;
  changePhoneMode = false;

  get canSubmitPhoneNumber(): boolean {
    return this.profile.contactPerson?.phoneNumber != this.formGroup.get("phoneNumber")?.value;
  }

  get phoneControl(): AbstractControl | null {
    return this.formGroup.get("phoneNumber");
  }

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private modal: NzModalService,
    private signupService: SignupService,
    private profileService : ProfileService,
    private cd: ChangeDetectorRef,
    private notificationService: NzNotificationService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      phoneNumber: [
        { disabled: !this.changePhoneMode, value: this.profile.contactPerson?.phoneNumber },
        [Validators.required],
      ],
    });
  }

  onStartChangePhoneNumber() {
    this.changePhoneMode = true;
    this.phoneControl?.enable();
  }

  submitPhone(): void {
    if (this.formGroup.valid) {
      this.updateProfilePhoneNumber();
    } else {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  updateProfilePhoneNumber() {
    const phoneNumber = this.formGroup.value.phoneNumber;
    this.profileService.missionPartnerUpdate({
      ...this.profile,
      contactPerson: {...this.profile.contactPerson, phoneNumber } as ContactPerson
    }).subscribe((result) => {
      if (result) {

        this.changePhoneMode = false;
        this.phoneControl?.disable();
        this.cd.markForCheck();

        this.notificationService.success(
          this.translateService.instant("settings.phone-changed.title"),
          this.translateService.instant("settings.phone-changed.content"),
        );
      }
    });
  }

}
