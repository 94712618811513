import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MicroSiteComponent } from "./micro-site.component";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzFormModule } from "ng-zorro-antd/form";
import { TranslateModule } from "@ngx-translate/core";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TagsOutputModule } from "../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { MicroSiteNavigationModule } from "./micro-site-navigation/micro-site-navigation.module";
import { LocationLabelModule } from "../../../../../common-ui/src/lib/location-label/location-label.module";
import { MicroSiteTestimonialsModule } from "./micro-site-testimonials/micro-site-testimonials.module";

@NgModule({
  declarations: [MicroSiteComponent],
  imports: [
    CommonModule,
    NzCardModule,
    NzAvatarModule,
    NzFormModule,
    TranslateModule,
    NzDividerModule,
    NzTagModule,
    NzSpaceModule,
    NzIconModule,
    TagsOutputModule,
    NzButtonModule,
    RouterLink,
    NzToolTipModule,
    MicroSiteNavigationModule,
    LocationLabelModule,
    MicroSiteTestimonialsModule,
  ],
  exports: [MicroSiteComponent],
})
export class MicroSiteModule {}
