<ng-container *nzModalTitle>
  {{ "admin.assessment.edit.hashtags.label" | translate }}
</ng-container>

<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <mh-explanation-label [labelKey]="'admin.assessment.hashtags.label'"></mh-explanation-label>
        <nz-form-control>
          <input nz-input formControlName="hashtag1" [placeholder]="'admin.assessment.hashtag1.placeholder' | translate">
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-control>
          <input nz-input formControlName="hashtag2" [placeholder]="'admin.assessment.hashtag2.placeholder' | translate">
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-control>
          <input nz-input formControlName="hashtag3" [placeholder]="'admin.assessment.hashtag3.placeholder' | translate">
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">
    {{ "cancel.button" | translate }}
  </button>
  <button nz-button nzType="primary" [nzLoading]="saving" (click)="onSave(assassement.missionPartnerId)">
    {{ "save.button" | translate }}
  </button>
</ng-container>