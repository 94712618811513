import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessagesInboxComponent } from "./messages-inbox.component";
import { MessagesConversationListModule } from "./messages-conversation-list/messages-conversation-list.module";
import { NzSpinModule } from "ng-zorro-antd/spin";

@NgModule({
  declarations: [MessagesInboxComponent],
  imports: [CommonModule, MessagesConversationListModule, NzSpinModule],
  exports: [MessagesInboxComponent],
})
export class MessagesInboxModule {}
