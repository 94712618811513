import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Talent } from "../../../../../../api/sign-up/signup.types";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {Observable, Subject, takeUntil} from "rxjs";
import { APP_CONFIGURATION } from "../../../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../../../core/src/lib/app-config/config.types";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { differenceInCalendarDays } from "date-fns";
import { CmsNameValue } from "../../../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../../../api/cms/cms-cached.service";
import { AudioFile } from "../../../../../../../../../core/src/lib/recording/recording.types";
import { TranslateService } from "@ngx-translate/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { UrlValidator } from "@momhunting/core";

@Component({
  selector: "mh-profile-about-me",
  templateUrl: "./profile-about-me.component.html",
  styleUrls: ["./profile-about-me.component.less"],
})
export class ProfileAboutMeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() talent!: Talent;
  @Input() userDocuments: Array<NzUploadFile> = [];
  @Input() mobileView!: boolean;

  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<void>();
  @Output() profileAudioChange = new EventEmitter<AudioFile | null>();
  @Output() fileListChange = new EventEmitter<{fileList: NzUploadFile[], uploadCV: boolean}>();

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant('field.required.label'),
      invalid: this.translateService.instant('field.invalid.label'),
    },
  };

  public formGroup!: FormGroup;
  public avatarUrl!: string;
  public talentCvList: Array<NzUploadFile> = [];
  private readonly destroy$ = new Subject<void>();
  private today= new Date();
  public reasonWhy$: Observable<Array<CmsNameValue>>;
  public defaultPickerValue = new Date('1990');

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

  get reasonWhyControl() {
    return this.formGroup.get('reasonWhy');
  }

  constructor(
    private fb: FormBuilder,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private cmsCachedService: CmsCachedService,
    private translateService: TranslateService,
    private notificationService: NzNotificationService,
  ) {
    this.reasonWhy$ = this.cmsCachedService.getUserDataByName("reason-why", false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["talent"]) {
      if (this.talent.avatar) {
        this.avatarUrl = this.talent.avatar.avatarUrl ? `${this.appConfig.siteUrl}/${this.talent.avatar.avatarUrl}` : '';
      }
      if (this.talent.cv) {
        this.talentCvList = [JSON.parse(JSON.stringify(this.talent.cv))];
      }
    }
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      keySummary: [this.talent.keySummary, []],
      birthDate: [this.talent.birthDate, [Validators.required]],
      hashTags: [this.talent.hashTags, []],
      reasonWhy: [this.talent.reasonWhy, [Validators.required]],
      contactData: [this.talent.contactData, [Validators.required, Validators.minLength(4)]],
      salutation: [this.talent.salutation, []],
      firstName: [this.talent.firstName, [Validators.required]],
      lastName: [this.talent.lastName, [Validators.required]],
      profileLink: [this.talent.profileLink, [Validators.required, UrlValidator]],
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        ...value,
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  onPaste(event: ClipboardEvent) {
    let paste = event.clipboardData?.getData("text");
    if(paste?.includes('#') || paste?.includes(' ')) event.preventDefault();
  }
  onKeydown(event: KeyboardEvent) {
    if(event.shiftKey && event.keyCode == 51) event.preventDefault(); //prevent shift + 3 = #
    if(event.keyCode == 32) event.preventDefault(); //prevent space
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateProfileAudio(event: AudioFile | null) {
    this.profileAudioChange.emit(event);
  }

  updateFileList(event: NzUploadFile[], uploadCV = false) {
    this.fileListChange.emit({
      fileList: event,
      uploadCV
    });
  }

  getHashTagsControl(): AbstractControl | null {
    return this.formGroup.get('hashTags');
  }

  onHashTagsChange(event: Array<CmsNameValue>) {
    if(this.getHashTagsControl()?.value.length > 3) {
      this.notificationService.warning('', this.translateService.instant('max-allowed-num.warning', {max: 3}));
      this.getHashTagsControl()?.setValue(event.slice(0, 3));
    }
  }
}
