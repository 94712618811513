import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssessmentRadioGroupSelectComponent } from "./assessment-radio-group-select.component";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NzInputModule } from "ng-zorro-antd/input";
import {NzRadioModule} from "ng-zorro-antd/radio";

@NgModule({
  declarations: [AssessmentRadioGroupSelectComponent],
  imports: [CommonModule, NzCheckboxModule, FormsModule, TranslateModule, NzInputModule, NzRadioModule],
  exports: [AssessmentRadioGroupSelectComponent],
})
export class AssessmentRadioGroupSelectModule {}
