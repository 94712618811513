<div class="media-upload">
  <div class="video" [class.video--show]="(isRecording || blobUrl)">
    <video
      class="video__elem"
      controls
      #videoElement>
      <source [src]="blobUrl"/>
    </video>
  </div>

  <div class="button-box">
    <button nz-button type="button" nzType="default" nzShape="circle" class="cancel-button" (click)="onCancel()">
      <span class="cancel-button__icon"></span>
    </button>
    <button *ngIf="!isRecording && !blobUrl" nz-button type="button" nzType="default" nzShape="circle" class="start-button" (click)="startVideoRecording()">
      <span class="start-button__icon"></span>
    </button>
    <button *ngIf="isRecording" [nzLoading]="recordingLoading" nz-button type="button" nzType="primary" nzShape="circle" class="stop-button" (click)="stopVideoRecording()">
      <span *ngIf="!recordingLoading" class="stop-button__icon"></span>
    </button>
    <button *ngIf="blobUrl" nz-button type="button" nzType="default" nzShape="circle" class="retake-button" (click)="retakeVideo()">
      <span nz-icon nzType="sync" nzTheme="outline"></span>
    </button>
    <button *ngIf="blobUrl" nz-button type="button" nzType="default" nzShape="circle" class="submit-button" (click)="onSubmit()">
      <span nz-icon nzType="send" nzTheme="outline"></span>
    </button>
    <div *ngIf="isRecording && !blobUrl && !recordingLoading" class="timer">{{ recordedTime }}</div>
  </div>
</div>
