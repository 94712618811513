import { Component } from "@angular/core";
import { MissionPartner } from "../../../../api/sign-up/signup.types";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { ActivatedRoute } from "@angular/router";
import { Reference } from "libs/platform-pages/src/lib/api/references/references.types";

@Component({
  selector: "mh-mission-partner-micro-site",
  templateUrl: "./mission-partner-micro-site.component.html",
  styleUrls: ["./mission-partner-micro-site.component.less"],
})
export class MissionPartnerMicroSiteComponent {
  missionPartner: MissionPartner;
  testimonials: Array<Reference> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.missionPartner = this.activatedRoute.snapshot.data["profile"];
    this.testimonials = this.activatedRoute.snapshot.data["testimonials"];
  }



  protected readonly AccountType = AccountType;
}
