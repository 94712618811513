import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssessmentNumberSelectComponent } from "./assessment-number-select.component";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NzInputModule } from "ng-zorro-antd/input";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzSliderModule} from "ng-zorro-antd/slider";

@NgModule({
  declarations: [AssessmentNumberSelectComponent],
  imports: [CommonModule, NzCheckboxModule, FormsModule, TranslateModule, NzInputModule, NzRadioModule, NzSliderModule],
  exports: [AssessmentNumberSelectComponent],
})
export class AssessmentNumberSelectModule {}
