<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{'references.title' | translate}}</nz-page-header-title>
</nz-page-header>

<div class="references">
  <nz-divider *ngIf="references.length" nzPlain [nzText]="'references.my-references.title' | translate" nzOrientation="left"></nz-divider>
  <nz-list nzItemLayout="vertical">
    <nz-list-item *ngFor="let reference of references" class="reference">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          {{ reference.referenceName }}
          <div class="reference__actions">
            <button
              nz-button
              [nzType]="'link'"
              nz-tooltip [nzTooltipTitle]="'references.delete-reference.button' | translate"
              (click)="confirmDeleteReference(reference.id)"
            >
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </div>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <div class="reference__info">
        <span>
          {{reference.referenceCompany}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span>
          {{reference.referencePosition}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <a [href]="'mailto:' + reference.referenceEmail">
          {{reference.referenceEmail}}
        </a>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span class="reference__timestamp"> {{ reference.creationTimestamp }}</span>
      </div>
      <div class="reference__text">
        {{ reference.referenceText }}
      </div>
    </nz-list-item>
  </nz-list>

  <nz-divider *ngIf="referencesRequest.length" nzPlain [nzText]="'references.my-references-requests.title' | translate" nzOrientation="left"></nz-divider>
  <nz-list nzItemLayout="vertical">
    <nz-list-item *ngFor="let reference of referencesRequest" class="reference">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <div>
            <span>{{ reference.referenceName }}</span>
            <div class="reference__actions">
              <button
                nz-button
                [nzType]="'link'"
                nz-tooltip
                [nzTooltipTitle]="'references.resend-reference.button' | translate"
                [nzLoading]="isResendingReference"
                (click)="resendReferenceRequest(reference.id)"
              >
                <span nz-icon nzType="mail" nzTheme="outline"></span>
              </button>
              <nz-divider nzPlain nzType="vertical"></nz-divider>
              <button
                nz-button
                [nzType]="'link'"
                nz-tooltip
                [nzTooltipTitle]="'references.delete-reference.button' | translate"
                (click)="confirmDeleteReferenceRequest(reference.id)"
              >
                <span nz-icon nzType="delete" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <div class="reference__info">
        <span>
          {{reference.referenceCompany}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span>
          {{reference.referencePosition}}
        </span>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <a [href]="'mailto:' + reference.referenceEmail">
          {{reference.referenceEmail}}
        </a>
        <nz-divider nzPlain nzType="vertical"></nz-divider>
        <span class="reference__timestamp"> {{ reference.creationTimestamp }}</span>
      </div>
    </nz-list-item>
  </nz-list>

  <nz-divider nzPlain [nzText]="'references.new-references.title' | translate" nzOrientation="left"></nz-divider>
  <p>{{'references.new-references.description' | translate}}</p>
  <form nz-form [formGroup]="formGroup" nzLayout="vertical" (ngSubmit)="submitReferenceRequest()">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'references.name.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="name" [placeholder]="'references.name.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'references.email.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="email" [placeholder]="'references.email.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'references.company.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="company" [placeholder]="'references.company.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label>
            {{ 'references.position.label' | translate }}
          </nz-form-label>
          <nz-form-control>
            <input nz-input [nzSize]="'default'" formControlName="position" [placeholder]="'references.position.placeholder' | translate"/>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="24" class="references__submit">
        <button nz-button [nzType]="'primary'" [disabled]="isSendingReference">{{ 'references.create-request.button' | translate }}</button>
      </div>
    </div>
  </form>
</div>
