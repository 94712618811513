import { filter, Observable, of, take } from "rxjs";
import { Injectable } from "@angular/core";
import { Talent } from "../sign-up/signup.types";
import { TalentProfileFactory } from "./talent-profile.factory";
import * as TalentActions from "./store/talent-profile.action";
import { LoginService } from "../../pages/login-page/login-page-data/login.service";
import { selectTalentProfile } from "./store/talent-profile.selectors";
import { Store } from "@ngrx/store";
import { TalentProfileState, talentProfileFeatureName } from "./store/talent-profile.reducer";

@Injectable({
  providedIn: "root",
})
export class TalentProfileResolver {
  constructor(
    private loginService: LoginService,
    private factory: TalentProfileFactory,
    private readonly store: Store<{ [talentProfileFeatureName]: TalentProfileState }>,
  ) {}

  resolve(): Observable<Talent | undefined> {
    if (this.loginService.isAuthenticated()) {
      this.store.dispatch(TalentActions.loadTalentProfile());
      return this.getFromStoreOrAPI();
    }
    return of(this.factory.getNewInstance());
  }

  getFromStoreOrAPI(): Observable<Talent | undefined> {
    return this.store.select(selectTalentProfile).pipe(
      filter((profile) => {
        return !!profile;
      }),
      take(1),
    );
  }
}
