import { Component, Inject } from "@angular/core";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.less"],
})
export class LandingPageComponent {
  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.titleService.setTitle(
      this.translateService.instant("page.title", { pageName: translateService.instant("landing.title") }),
    );
  }
}
