<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{'profile.title' | translate}}</nz-page-header-title>
</nz-page-header>

<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>
<ng-template #desktopView>
  <div nz-row class="profile__container">
    <div nz-col nzSpan="8" class="v-stepper__content">
      <mh-talent-profile-completeness [completeness]="$any(profileCompleteness$ | async)"></mh-talent-profile-completeness>

      <!-- vertical stepper -->
      <nz-steps [nzCurrent]="currentStepIndex" (nzIndexChange)="onStepChange($event)" nzDirection="vertical">
        <nz-step [nzTitle]="step.titleKey | translate" [nzStatus]="getStatus(step)" *ngFor="let step of steps"></nz-step>
      </nz-steps>
    </div>
    <div nz-col nzSpan="16" class="main__content">
      <ng-container [ngTemplateOutlet]="stepOutput"></ng-container>
    </div>
  </div>
</ng-template>
<ng-template #mobileView>
  <div nz-row class="mobile_profile__container">
    <div nz-col nzSpan="24" class="mobile_profile__stepper">
      <Navbar [icon]="icon" [rightContent]="rightContent" [mode]="'light'">
        {{'navi.step.label' | translate:{currentStep: this.currentStepIndex + 1, totalSteps: this.steps.length,} }}
      </Navbar>
      <ng-template #icon>
        <Icon [type]="'left'" (click)="onPrevStep()" *ngIf="hasPrevStep"></Icon>
      </ng-template>
      <ng-template #rightContent>
        <Icon [type]="'right'" (click)="onNextStep()" *ngIf="hasNextStep"></Icon>
      </ng-template>
    </div>
    <div nz-col nzSpan="24" class="mobile_profile__step">
      <ng-container [ngTemplateOutlet]="stepOutput"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #stepOutput>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.ABOUT_ME">
    <mh-profile-about-me
      [talent]="talent"
      [userDocuments]="userDocuments"
      [mobileView]="$any(isMobileView$ | async)"
      (formChange)="onFormChange($event)"
      (submitChange)="onSubmitChange()"
      (profileAudioChange)="updateProfileAudio($event)"
      (fileListChange)="updateFileList($event)"
    ></mh-profile-about-me>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.PERSONALITY">
    <mh-profile-personality [talent]="talent" (formChange)="onFormChange($event)" (submitChange)="onSubmitChange()"></mh-profile-personality>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.MOM_SKILLS">
    <mh-profile-mom-skills [talent]="talent" (formChange)="onFormChange($event)" (submitChange)="onSubmitChange($event)"></mh-profile-mom-skills>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.DESIRED_JOB">
    <mh-profile-desired-job [talent]="talent" (formChange)="onFormChange($event)" (submitChange)="onSubmitChange()"></mh-profile-desired-job>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.WILLINGNESS_TO_CHANGE">
    <mh-profile-change-ready [talent]="talent" (formChange)="onFormChange($event)" (submitChange)="onSubmitChange()"></mh-profile-change-ready>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.FAMILY_FRIENDLY_EMPLOYER">
    <mh-profile-family-friendly [talent]="talent" (formChange)="onFormChange($event)" (submitChange)="onSubmitChange()"></mh-profile-family-friendly>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.EDUCATION">
    <mh-profile-education [talent]="talent" (formChange)="onFormChange($event)" (submitChange)="onSubmitChange($event)"></mh-profile-education>
  </ng-container>
  <ng-container *ngIf="currentStepItem.name == TalentProfileSection.TRACK_RECORD_AND_EXPERIENCE">
    <mh-profile-experience
      [talent]="talent"
      (formChange)="onFormChange($event)"
      (submitChange)="onSubmitChange()"
      (lastStepSaved)="onLastStepSaved()"
    ></mh-profile-experience>
  </ng-container>
</ng-template>
