import { Inject, Injectable, OnDestroy } from "@angular/core";
import { Observable, of, ReplaySubject, Subject, takeUntil } from "rxjs";
import { LoginService } from "../../../../platform-pages/src/lib/pages/login-page/login-page-data/login.service";
import { APP_CONFIGURATION } from "../core.di";
import { AppConfig } from "../app-config/config.types";
import {MessagingMessage, SocketAction} from "../../../../platform-pages/src/lib/api/messages/messages.types";
import { AuthorizationService } from "../../../../platform-pages/src/lib/api/authorization/authorization.service";

@Injectable({ providedIn: "root" })
export class SocketService implements OnDestroy {
  private newMessagesSubject!: ReplaySubject<MessageEvent>;
  messages$: Observable<any> = of([]);
  webSocket: WebSocket | null = null;
  keepAliveInterval!: number;
  private destroy$ = new Subject<void>();
  private token: string = "";

  constructor(
    private readonly loginService: LoginService,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private authorizationService: AuthorizationService,
  ) {
    this.authorizationService
      .getAuthorizedUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user.token) {
          if (this.webSocket) this.disconnect();
          this.connect(user.token);
          this.token = user.token;
        } else {
          if (this.webSocket) this.disconnect();
          this.token = "";
        }
      });
  }

  private connect(userToken: string) {
    this.webSocket = new WebSocket(this.appConfig.socketEndpointUrl + "?authToken=" + userToken);
    this.webSocket.addEventListener("open", (event) => {
      this.sendKeepAlive();
    });
    this.webSocket.addEventListener("close", (event) => {
      this.handleClose();
    });
    this.messages$ = this.listenToNativeSocketMessages(this.webSocket);
    this.keepAliveInterval = setInterval(() => this.sendKeepAlive(), 60000) as any;
  }

  private disconnect() {
    this.webSocket?.removeEventListener("open", (event) => {
      this.sendKeepAlive();
    });
    this.webSocket?.removeEventListener("close", (event) => {
      this.handleClose();
    });
    this.webSocket?.close();
    this.webSocket = null;
    clearInterval(this.keepAliveInterval);
  }

  private sendKeepAlive() {
    this.send(JSON.stringify({ action: SocketAction.KEEP_ALIVE }));
  }

  private handleClose() {
    console.error('webSocket: close');
    if (this.token) setTimeout(() => this.connect(this.token), 5000);
  }

  sendMessage(message: MessagingMessage) {
    this.send(JSON.stringify(message));
  }

  readMessages(messageIds: Array<string>) {
    this.send(JSON.stringify({action: SocketAction.READ, messages: messageIds}));
    return messageIds;
  }

  listenToNativeSocketMessages(websocket: WebSocket): Observable<any> {
    this.newMessagesSubject?.complete();

    this.newMessagesSubject = new ReplaySubject<MessageEvent>();

    websocket.onmessage = (event: MessageEvent) => {
      let item = JSON.parse(event.data).results.message;
      //console.log("websocket.onmessage: " + JSON.stringify(event));
      if (item?.action) this.newMessagesSubject.next(item);
    };

    return this.newMessagesSubject.asObservable();
  }

  private send(message: string, callback?: () => {}) {
    this.waitForConnection((): any => {
      this.webSocket?.send(message);
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 5000);
  };

  private waitForConnection(callback: () => {}, interval: number) {
    if (this.webSocket?.readyState === 1) {
      callback();
    } else {
      console.error('webSocket: trying connect, readyState='+this.webSocket?.readyState);
      const that = this;
      setTimeout(function () {
        that.waitForConnection(callback, interval);
      }, interval);
    }
  };

  ngOnDestroy() {
    this.destroy$.next();
  }
}
