<div class="card-list">
  <div class="card-list__item" *ngFor="let offer of jobOfferList">
    <div class="card-list__body" (click)="navigateToMatches(offer.id)">
      <div nz-row>
        <div nz-col [nzSpan]="24">
          <mh-mp-job-offer-item [jobOffer]="offer"></mh-mp-job-offer-item>
        </div>
      </div>
    </div>
    <div class="card-list__actions">
      <!--
      <button nz-button nzType="link" [routerLink]="['/', 'mission-partner', 'job-offers', 'edit', offer.id]">
        <span nz-icon nzType="edit"></span>
      </button>
      <button nz-button nzType="link" [routerLink]="['/', 'mission-partner', 'job-offers', 'matches', offer.id]">
        <span nz-icon nzType="file-done"></span>
      </button>-->
      <button nz-button nzType="link"
              [nz-tooltip]="'Activate'"
              (click)="onUnArchive(offer)">
        <span nz-icon nzType="container"></span>
      </button>
    </div>
  </div>

  <nz-empty *ngIf="!jobOfferList.length" nzNotFoundImage="simple"></nz-empty>
</div>
