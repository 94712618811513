import { Injectable } from "@angular/core";
import { format } from "date-fns";
import { TestimonialDTO, TestimonialRequestDTO } from "./testimonials-api.types";
import { Testimonial, TestimonialRequest } from "./testimonials.types";

@Injectable({ providedIn: "root" })
export class TestimonialsSerializer {
  deserializeTestimonials(dtos: Array<TestimonialDTO>): Array<Testimonial> {
    return dtos.map((dto) => this.deserializeTestimonial(dto));
  }

  deserializeTestimonial(dto: TestimonialDTO): Testimonial {
    return {
      ...dto,
      creationTimestamp: format(new Date(dto.creationTimestamp), 'yyyy-MM-dd HH:mm:ss'),
    };
  }

  deserializeTestimonialsRequest(dtos: Array<TestimonialRequestDTO>): Array<TestimonialRequest> {
    return dtos.map((dto) => this.deserializeTestimonialRequest(dto));
  }

  deserializeTestimonialRequest(dto: TestimonialRequestDTO): TestimonialRequest {
    return {
      ...dto,
      creationTimestamp: format(new Date(dto.creationTimestamp), 'yyyy-MM-dd HH:mm:ss'),
    };
  }
}
