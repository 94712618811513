import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OldTrackRecord } from "../../api/sign-up/signup.types";
import {NzModalService} from "ng-zorro-antd/modal";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "mh-old-track-record-view",
  templateUrl: "./old-track-record-view.component.html",
  styleUrls: ["./old-track-record-view.component.less"],
})
export class OldTrackRecordViewComponent {
  @Input() oldTrackRecord!: OldTrackRecord;
  @Output() deleteTrackRecord = new EventEmitter<void>();

  constructor(private modal: NzModalService, private translate: TranslateService) {}

  onDeleteTrackRecord() {
    this.modal.confirm({
      nzTitle: this.translate.instant('confirm.delete.label'),
      nzOkText: this.translate.instant('ok.button'),
      nzCancelText: this.translate.instant('cancel.button'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.doDeleteTrackRecord(),
    });
  }

  doDeleteTrackRecord() {
    this.deleteTrackRecord.emit();
  }
}
