import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileMomSkillsComponent } from "./profile-mom-skills.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import {ExplanationLabelModule} from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [ProfileMomSkillsComponent],
  imports: [
    CommonModule,
    NzPageHeaderModule,
    FormsModule,
    NzDatePickerModule,
    NzFormModule,
    NzGridModule,
    ReactiveFormsModule,
    NzSelectModule,
    TranslateModule,
    NzButtonModule,
    NzWaveModule,
    ExplanationLabelModule,
  ],
  exports: [ProfileMomSkillsComponent],
})
export class ProfileMomSkillsModule {}
