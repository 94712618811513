import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfilePreviewComponent } from "./profile-preview.component";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzFormModule } from "ng-zorro-antd/form";
import { TranslateModule } from "@ngx-translate/core";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzIconModule } from "ng-zorro-antd/icon";
import { LocationLabelModule } from "../../../../../common-ui/src/lib/location-label/location-label.module";
import { TagsOutputModule } from "../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { ProfilePreviewReferencesModule } from "./profile-preview-references/profile-preview-references.module";
import { EmploymentDurationLabelModule} from "@momhunting/common-ui";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ProfilePreviewNavigationModule } from "./profile-preview-navigation/profile-preview-navigation.module";
import { ProfilePreviewKeySummaryModule } from "./profile-preview-key-summary/profile-preview-key-summary.module";

@NgModule({
  declarations: [ProfilePreviewComponent],
  imports: [
    CommonModule,
    ProfilePreviewReferencesModule,
    ProfilePreviewNavigationModule,
    NzCardModule,
    NzAvatarModule,
    NzFormModule,
    TranslateModule,
    NzDividerModule,
    NzTagModule,
    NzSpaceModule,
    NzIconModule,
    LocationLabelModule,
    TagsOutputModule,
    EmploymentDurationLabelModule,
    NzButtonModule,
    RouterLink,
    NzToolTipModule,
    ProfilePreviewKeySummaryModule,
  ],
  exports: [ProfilePreviewComponent],
})
export class ProfilePreviewModule {}
