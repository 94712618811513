import { Component } from "@angular/core";
import { Conversation } from "../../../../../../api/messages/messages.types";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "mh-talent-conversation",
  templateUrl: "./talent-conversation.component.html",
  styleUrls: ["./talent-conversation.component.less"],
})
export class TalentConversationComponent {
  conversation: Conversation;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.conversation = this.activatedRoute.snapshot.data["conversation"];
  }

  onBack() {
    const origin = this.activatedRoute.snapshot.queryParams["origin"];
    const originId = this.activatedRoute.snapshot.queryParams["originId"];
    if (origin === "talent-mission-partners") {
      this.router.navigate(["/", "talent", "mission-partners"]);
    } else if (origin === "view-micro-site" && originId) {
      this.router.navigate(["/", "talent", "view-micro-site", originId]);
    } else if (origin === "talent-my-visits") {
      this.router.navigate(["/", "talent", "visits"]);
    } else {
      this.router.navigate(["/", "talent", "inbox"]);
    }
  }
}
