import { AbstractControl, ValidationErrors } from "@angular/forms"

export const StrictEmailValidator = function (control: AbstractControl): ValidationErrors | null {
  const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  if (control.value) {
    const matches = control.value.match(emailRegExp);
    return matches ? null : { 'email': true };
  } else {
    return null;
  }
}
