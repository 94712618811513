import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, Observable, take, tap } from "rxjs";
import { JobOffer } from "../../../../../api/job-offer/job-offer.types";
import { jobOfferFeature, JobOfferState } from "./store/job-offer.reducer";
import { JobOfferAction } from "./store/job-offer.action";
import { JobOfferEntityFactory } from "./job-offer-entity.factory";

@Injectable({
  providedIn: "root",
})
export class JobOfferEntityResolver implements Resolve<Partial<JobOffer> | undefined> {
  constructor(
    private readonly jobOfferEntityFactory: JobOfferEntityFactory,
    private readonly store: Store<{ [jobOfferFeature]: JobOfferState }>,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Partial<JobOffer> | undefined> {
    return this.getFromStoreOrAPI(route.params["id"]);
  }

  getFromStoreOrAPI(id: string): Observable<Partial<JobOffer> | undefined> {
    return this.store
      .select((state) => state[jobOfferFeature].entity.data)
      .pipe(
        tap((jobOffer) => {
          if (!jobOffer || jobOffer.id !== id) {
            if (id) {
              this.store.dispatch(JobOfferAction.loadJobOffer({ id }));
            } else {
              this.store.dispatch(
                JobOfferAction.loadJobOfferSuccess({ data: this.jobOfferEntityFactory.getNewInstance() }),
              );
            }
          }
        }),
        filter((jobOffer) => !!jobOffer && (jobOffer.id === id || id === undefined)),
        take(1),
      );
  }
}
