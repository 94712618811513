import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataSpaceAttributeViewComponent } from "./data-space-attribute-view.component";
import { NzGridModule } from "ng-zorro-antd/grid";

@NgModule({
  declarations: [DataSpaceAttributeViewComponent],
  imports: [
    CommonModule,
    NzGridModule,
  ],
  exports: [
    DataSpaceAttributeViewComponent,
  ]
})
export class DataSpaceAttributeViewModule {}
