import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeTalentComponent } from "./home-talent.component";
import { TalentDashboardComponent } from "./subpages/talent-dashboard/talent-dashboard.component";
import { TalentReferencesComponent } from "./subpages/talent-references/talent-references.component";
import { TalentMissionPartnersComponent } from "./subpages/talent-mission-partners/talent-mission-partners.component";
import { TalentProfileComponent } from "./subpages/talent-profile/talent-profile.component";
import { TalentSettingsComponent } from "./subpages/talent-settings/talent-settings.component";
import { TalentNotificationsComponent } from "./subpages/talent-notifications/talent-notifications.component";
import { TalentProfileResolver } from "../../api/profile/talent-profile.resolver";
import { TalentProfilePreviewComponent } from "./subpages/talent-profile-preview/talent-profile-preview.component";
import { LoginDataResolver } from "../login-page/login-page-data/login-data.resolver";
import { UserDocumentsResolver } from "../../api/document/user.documents.resover";
import { DebugComponent } from "../debug/debug.component";
import { ReferencesRequestResolver } from "../../api/references/references-request.resolver";
import { ReferencesResolver } from "../../api/references/references.resolver";
import { TalentProfileAnonymisedResolver } from "../../api/profile/talent-profile-anonymised.resolver";
import { TalentProfileTrustedResolver } from "../../api/profile/talent-profile-trusted.resolver";
import { TalentInboxModule } from "./subpages/talent-inbox/talent-inbox.module";
import { NotificationSettingsResolver } from "../../api/notification/notification-settings.resolver";
import { TalentViewMicroSiteComponent } from "./subpages/talent-view-micro-site/talent-view-micro-site.component";
import { UserNewsletterSettingResolver } from "../../api/user/user-newsletter-setting.resolver";
import { TalentMyVisitsComponent } from "./subpages/talent-my-visits/talent-my-visits.component";

const routes: Routes = [
  {
    path: "",
    component: HomeTalentComponent,
    children: [
      {
        path: "dashboard",
        component: TalentDashboardComponent,
        resolve: {
          loginData: LoginDataResolver,
          profile: TalentProfileResolver,
          notificationsSettings: NotificationSettingsResolver,
        },
      },
      {
        path: "inbox",
        loadChildren: () => TalentInboxModule,
      },
      {
        path: "profile",
        component: TalentProfileComponent,
        resolve: {
          profile: TalentProfileResolver,
          userDocuments: UserDocumentsResolver,
        },
      },
      {
        path: "mission-partners",
        component: TalentMissionPartnersComponent,
      },
      {
        path: "references",
        component: TalentReferencesComponent,
        resolve: {
          references: ReferencesResolver,
          referencesRequest: ReferencesRequestResolver,
        },
      },
      {
        path: "visits",
        component: TalentMyVisitsComponent,
      },
      {
        path: "settings",
        component: TalentSettingsComponent,
        resolve: {
          loginData: LoginDataResolver,
          profile: TalentProfileResolver,
          newsletterEnabled: UserNewsletterSettingResolver,
        },
      },
      {
        path: "debug",
        component: DebugComponent,
      },
      {
        path: "notifications",
        component: TalentNotificationsComponent,
        resolve: {
          notificationsSettings: NotificationSettingsResolver,
        },
      },
      {
        path: "profile-preview-trusted",
        component: TalentProfilePreviewComponent,
        resolve: {
          profile: TalentProfileTrustedResolver,
        },
        data: {
          trusted: true,
        },
      },
      {
        path: "profile-preview-anonymized",
        component: TalentProfilePreviewComponent,
        resolve: {
          profile: TalentProfileAnonymisedResolver,
        },
        data: {
          trusted: false,
        },
      },
      {
        path: "view-micro-site/:id",
        component: TalentViewMicroSiteComponent,
      },
      {
        path: "**",
        redirectTo: "dashboard",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeTalentRoutingModule {}
