import { Injectable } from "@angular/core";
import {
  AccountFilter,
  AccountFilterDTO,
  ActiveConversation,
  ActiveConversationDTO,
  AdminAccount,
  AdminAccountDTO,
  Comment,
  CommentDTO,
  CommentFromJo,
  CommentFromJoDTO,
  FilterRangeDTO,
  LocationFilterDTO,
  MissionPartnerAccount,
  MissionPartnerAccountDTO, Negotiation, NegotiationDTO,
  TalentAccount,
  TalentAccountDTO
} from "./account.types";
import { format, formatDistanceToNowStrict, formatDistanceStrict } from "date-fns";
import { de, enGB } from "date-fns/locale";
import { CmsNameValue } from "../../../api/cms/cms.types";
import { CommonService } from "../../../api/common.service";
import { SimpleLocation } from "../../../api/google-geocoding/google-geocoding.types";
import { LocaleService } from "@momhunting/core";

@Injectable({ providedIn: "root" })
export class AccountSerializer {
  constructor(
    private commonService: CommonService,
    protected readonly localeService: LocaleService,
  ) {}

  deserializeTalentAccounts(dtos: Array<TalentAccountDTO>): Array<TalentAccount> {
    return dtos.map((dto) => this.deserializeTalentAccount(dto));
  }

  deserializeTalentAccount(dto: TalentAccountDTO): TalentAccount {
    return {
      ...dto,
      salaryExpectations: {
        min: this.salaryToCmsValue(dto.salaryExpectationMin),
        max: this.salaryToCmsValue(dto.salaryExpectationMax),
      },
      cvUrl: this.commonService.toAbsUrl(dto.cvUrl),
    };
  }

  deserializeMissionPartnerAccounts(dtos: Array<MissionPartnerAccountDTO>): Array<MissionPartnerAccount> {
    return dtos.map((dto) => this.deserializeMissionPartnerAccount(dto));
  }

  deserializeMissionPartnerAccount(dto: MissionPartnerAccountDTO): MissionPartnerAccount {
    return {
      ...dto,
    };
  }

  deserializeUserAdminAccounts(dtos: Array<AdminAccountDTO>): Array<AdminAccount> {
    return dtos.map((dto) => this.deserializeUserAdminAccount(dto));
  }

  deserializeUserAdminAccount(dto: AdminAccountDTO): AdminAccount {
    return {
      ...dto,
    };
  }

  deserializeActiveConversations(dtos: Array<ActiveConversationDTO>): Array<ActiveConversation> {
    return dtos.map((dto) => this.deserializeActiveConversation(dto));
  }

  deserializeActiveConversation(dto: ActiveConversationDTO): ActiveConversation {
    return {
      ...dto,
      lastMessageDistanceToNow: formatDistanceToNowStrict(new Date(dto.lastMessageTimestamp),
        {
          addSuffix: true,
          locale: this.localeService.currentLocale === 'en' ? enGB : de,
        }),
    };
  }

  deserializeNegotiations(dtos: Array<NegotiationDTO>): Array<Negotiation> {
    return dtos.map((dto) => this.deserializeNegotiation(dto));
  }

  deserializeNegotiation(dto: NegotiationDTO): Negotiation {
    return {
      ...dto,
      formattedDuration: formatDistanceStrict(new Date(0), new Date(dto.duration),
        {
          locale: this.localeService.currentLocale === 'en' ? enGB : de,
        }),
    };
  }

  deserializeRange(range: FilterRangeDTO): Array<Date> {
    return [
      (new Date(range.start)),
      (new Date(range.end))
    ]
  }

  serializeFilter(filter: AccountFilter): AccountFilterDTO {
    const accountFilterDTO: AccountFilterDTO = {
      ...filter,
      paging: {
        page: filter.paging.page == 0 ? 0 : filter.paging.page - 1,
        itemsOnPage: filter.paging.itemsOnPage,
      },
    } as AccountFilterDTO;

    if (filter.timeRange?.length) {
      accountFilterDTO.timeRange = {
        start: new Date(filter.timeRange[0]).getTime(),
        end: new Date(filter.timeRange[1]).getTime(),
      };
    } else {
      accountFilterDTO.timeRange = undefined;
    }

    if (filter.location?.postalCode) {
      accountFilterDTO.location = this.serializeLocation(filter.location)
    } else {
      delete accountFilterDTO.location;
    }

    return accountFilterDTO;
  }

  private serializeLocation(location: SimpleLocation): LocationFilterDTO {
    return {
      location: {
        postalCode: location.postalCode,
        country: location.country,
        city: location.city,
        cityOptions: location.cityOptions,
        location: location.location,
        geocoderResult: location.geocoderResult,
        formattedAddress: location.formattedAddress,
      },
      radius: location.radius,
    };
  }

  deserializeComments(dtos: Array<CommentDTO>): Array<Comment> {
    return dtos.map((dto) => this.deserializeComment(dto));
  }

  deserializeCommentsFromJo(dtos: Array<CommentFromJoDTO>): Array<CommentFromJo> {
    return dtos.map((dto) => this.deserializeCommentFromJo(dto));
  }

  deserializeComment(dto: CommentDTO): Comment {
    return {
      ...dto,
      creationTimestamp: format(new Date(dto.creationTimestamp), 'yyyy-MM-dd HH:mm:ss'),
    };
  }

  deserializeCommentFromJo(dto: CommentFromJoDTO): CommentFromJo {
    return {
      ...dto,
      creationTimestamp: format(new Date(dto.creationTimestamp), 'yyyy-MM-dd HH:mm:ss'),
    };
  }

  private salaryToCmsValue(salary?: number): CmsNameValue | undefined {
    if(!salary) return undefined;
    return { value: salary, name: salary + "K" };
  }
}
