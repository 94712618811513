<div>
  <div *ngIf="!audioBlobUrl" class="button-box">
    <button *ngIf="!isAudioRecording" nz-button nzType="primary" class="start-button" (click)="startAudioRecording()">
      {{'profile.audio.start-recording' | translate}}
      <span nz-icon nzType="audio" nzTheme="outline"></span>
    </button>
    <button *ngIf="isAudioRecording" nz-button nzType="primary" class="stop-button" (click)="stopAudioRecording()">
      {{'profile.audio.stop-recording' | translate}}
      <span class="stop-button__icon"></span>
    </button>
    <div id="audioTimer" *ngIf="isAudioRecording && !audioBlobUrl"> {{ audioRecordedTime }} </div>
  </div>
  <div *ngIf="audioBlobUrl" class="audio-box">
    <audio *ngIf="!isAudioRecording" controls class="audio">
      <source [src]="audioBlobUrl" type="audio/wav">
    </audio>
    <button nz-button nzType="text" class="cancel-button" *ngIf="!isAudioRecording" (click)="handleRemove()">
      <span nz-icon nzType="delete" nzTheme="outline"></span>
    </button>
  </div>
</div>
