import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminAssessmentCreateComponent } from "./admin-assessment-create.component";
import { InvoiceFormModule } from "../../admin-invoices-list/invoice-form/invoice-form.module";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { ExplanationLabelModule } from "../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { ReactiveFormsModule } from "@angular/forms";
import {NzModalModule} from "ng-zorro-antd/modal";

@NgModule({
  declarations: [AdminAssessmentCreateComponent],
  imports: [
    CommonModule,
    InvoiceFormModule,
    NzButtonModule,
    NzWaveModule,
    TranslateModule,
    ExplanationLabelModule,
    NzFormModule,
    NzGridModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzModalModule,
  ],
})
export class AdminAssessmentCreateModule {}
