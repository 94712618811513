<div class="message-input-cnt">
  <nz-form-item>
    <nz-input-group [nzSuffix]="!isMobile ? suffixEmojiPicker: empty">
      <input [disabled]="disabled" [(ngModel)]="inputValue" nz-input #textInput (keydown.enter)="handleSubmit()"/>
    </nz-input-group>
  </nz-form-item>
  <button class="btn-send" nz-button nzType="primary" [nzLoading]="sending" [disabled]="!inputValue" (click)="handleSubmit()">
    <span nz-icon nzType="send"></span>
  </button>
</div>
<div class="message-add-media-cnt">
  <button *ngIf="!isMobile" nz-button nzType="link" nzSize="small" [disabled]="disabled" (click)="openRecordVideoModal()">
    <span nz-icon nzType="video-camera" nzTheme="outline"></span>
  </button>
  <button nz-button nzType="link" nzSize="small" [disabled]="disabled" (click)="openRecordAudioModal()">
    <span nz-icon nzType="audio" nzTheme="outline"></span>
  </button>
  <input type="file" accept=""
         aria-label="File upload"
         class="mh-chat__file-input"
         id="fileInput" #fileInput
         (change)="filesSelected(fileInput.files)">
  <button nz-button nzType="link" nzSize="small" [disabled]="disabled" (click)="fileInput.click()">
    <span nz-icon [nzType]="isMobile ? 'plus':'file'" nzTheme="outline"></span>
  </button>
</div>

<ng-template #suffixEmojiPicker>
  <mh-emoji-picker [emojiInput$]="emojiInput$"></mh-emoji-picker>
</ng-template>
<ng-template #empty></ng-template>
