import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import * as MissionPartnerProfileActions from "./mission-partner-profile.action";
import { DashboardService } from "../../dashboard/dashboard.service";
import { ProfileService } from "../profile.service";

@Injectable()
export class MissionPartnerProfileEffects {
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private profileService: ProfileService,
  ) {}

  loadMissionPartnerProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MissionPartnerProfileActions.loadMissionPartnerProfile),
      switchMap(() =>
        this.profileService.loadMissionPartnerProfile().pipe(
          map((response) => MissionPartnerProfileActions.loadMissionPartnerProfileSuccess({ data: response })),
          catchError((error) => of(MissionPartnerProfileActions.loadMissionPartnerProfileError({ error }))),
        ),
      ),
    );
  });

  updateMissionPartnerProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MissionPartnerProfileActions.updateMissionPartnerProfile),
      switchMap(({data}) =>
        this.profileService.missionPartnerUpdate(data).pipe(
          map((success) => MissionPartnerProfileActions.updateMissionPartnerProfileSuccess({ data })),
          catchError((error) => of(MissionPartnerProfileActions.updateMissionPartnerProfileError({ error }))),
        ),
      ),
    );
  });
}
