import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentReferencesComponent } from "./talent-references.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzFormModule } from "ng-zorro-antd/form";
import { ReactiveFormsModule } from "@angular/forms";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";

@NgModule({
  declarations: [TalentReferencesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzGridModule,
    NzListModule,
    NzCardModule,
    NzButtonModule,
    NzIconModule,
    NzDividerModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    NzTagModule,
    NzSpaceModule,
    NzToolTipModule,
    NzPageHeaderModule,
  ],
})
export class TalentReferencesModule {}
