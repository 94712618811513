import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentMyVisitsComponent } from "./talent-my-visits.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { RouterLink } from "@angular/router";
import { CreateConversationDialogModule } from "../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.module";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzTagModule } from "ng-zorro-antd/tag";
@NgModule({
  declarations: [TalentMyVisitsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzPageHeaderModule,
    NzGridModule,
    NzTableModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    NzAvatarModule,
    NzTagModule,
    RouterLink,
    CreateConversationDialogModule,
  ],
})
export class TalentMyVisitsModule {}
