import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminMissionPartnerViewComponent } from "./admin-mission-partner-view.component";
import { TranslateModule } from "@ngx-translate/core";
import { AccountCommentsModule } from "../../../../../../../common-ui/src/lib/account-comments/account-comments.module";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzCardModule } from "ng-zorro-antd/card";
import { AdminTalentDataspacesModule } from "../../../../common-componnets/admin-talent-dataspaces/admin-talent-dataspaces.module";
import { LocationLabelModule } from "../../../../../../../common-ui/src/lib/location-label/location-label.module";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { RouterLink } from "@angular/router";
import { TagsOutputModule } from "../../../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { MicroSiteModule } from "../../../../common-componnets/micro-site/micro-site.module";


@NgModule({
  declarations: [AdminMissionPartnerViewComponent],
    imports: [
        CommonModule,
        TranslateModule,
        AccountCommentsModule,
        NzGridModule,
        NzCardModule,
        AdminTalentDataspacesModule,
        LocationLabelModule,
        NzMenuModule,
        RouterLink,
        TagsOutputModule,
        NzToolTipModule,
        NzIconModule,
        NzButtonModule,
        MicroSiteModule,
    ],
})
export class AdminMissionPartnerViewModule {}
