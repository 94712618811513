import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminAssessmentListComponent } from "./admin-assessment-list.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AdminAssessmentCreateModule } from "./admin-assessment-create/admin-assessment-create.module";
import { RouterLink } from "@angular/router";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AdminAssessmentHashtagsEditModule } from "./admin-assessment-hashtags-edit/admin-assessment-hashtags-edit.module";

@NgModule({
  declarations: [AdminAssessmentListComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzTableModule,
    NzToolTipModule,
    NzWaveModule,
    ReactiveFormsModule,
    TranslateModule,
    AdminAssessmentCreateModule,
    AdminAssessmentHashtagsEditModule,
    RouterLink,
    ClipboardModule,
  ],
})
export class AdminAssessmentListModule {}
