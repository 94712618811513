<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{'settings.title' | translate}}</nz-page-header-title>
</nz-page-header>

<nz-divider nzPlain [nzText]="'settings.newsletter.title' | translate" nzOrientation="left"></nz-divider>
<mh-mission-partner-settings-newsletter [newsletterEnabled]="newsletterEnabled"></mh-mission-partner-settings-newsletter>

<nz-divider nzPlain [nzText]="'settings.email.title' | translate" nzOrientation="left"></nz-divider>
<mh-mission-partner-settings-email [loginData]="loginData"></mh-mission-partner-settings-email>

<nz-divider nzPlain [nzText]="'settings.password.title' | translate" nzOrientation="left"></nz-divider>
<mh-mission-partner-settings-password></mh-mission-partner-settings-password>

<nz-divider nzPlain [nzText]="'settings.phone.title' | translate" nzOrientation="left"></nz-divider>
<mh-mission-partner-settings-phone [profile]="profile"></mh-mission-partner-settings-phone>

<nz-divider nzPlain [nzText]="'settings.account.title' | translate" nzOrientation="left"></nz-divider>
<div nz-row nzGutter="24">
  <div nz-col [nzSpan]="24" [nzMd]="12">
    <button nz-button nzDanger [nzType]="'primary'" nzBlock (click)="showDeleteConfirm()">
      {{ "account-delete.button" | translate }}
    </button>
  </div>
</div>
