import { Injectable } from "@angular/core";
import { GoogleGeocodingApi } from "./google-geocoding.api";
import { map, Observable } from "rxjs";
import { GoogleGeocodingSerializer } from "./google-geocoding.serializer";
import { SimpleLocation } from "./google-geocoding.types";

@Injectable({ providedIn: "root" })
export class GoogleGeocodingService {
  constructor(private googleGeocodingApi: GoogleGeocodingApi, private serializer: GoogleGeocodingSerializer) {}

  decodeAddress(address: string, locale?:string): Observable<SimpleLocation> {
    return this.googleGeocodingApi
      .decodeAddress(address, 'de')
      .pipe(map((results) => this.serializer.deserializeGeocoderResult(results[0])));
  }
}
