import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WidgetComponent } from "./widget.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";

@NgModule({
  declarations: [WidgetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzIconModule,
    NzButtonModule,
    RouterLink,
  ],
  exports: [WidgetComponent],
})
export class WidgetModule {}
