import { Component, Inject, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AssessmentService } from "../../../../../api/assessment/assessment.service";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { MaturityAssessment } from "libs/platform-pages/src/lib/api/assessment/assessment.types";

@Component({
  selector: "mh-admin-assessment-hashtags-edit",
  templateUrl: "./admin-assessment-hashtags-edit.component.html",
  styleUrls: ["./admin-assessment-hashtags-edit.component.less"],
})
export class AdminAssessmentHashtagsEditComponent implements OnInit {
  assassement!: MaturityAssessment;
  formGroup!: UntypedFormGroup;
  saving = false;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private assessmentService: AssessmentService,
    private notificationService: NzNotificationService,
  ) {
    this.assassement = modalData["assessment"];
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      hashtag1: [this.assassement.hashtags.hashtag1, []],
      hashtag2: [this.assassement.hashtags.hashtag2, []],
      hashtag3: [this.assassement.hashtags.hashtag3, []],
    });
  }

  isValid(): boolean {
    if (!this.formGroup.valid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  onCancel(): void {
    this.modal.close();
  }

  onSave(accountId: string): void {
    if (!this.isValid()) {
      return;
    }
    this.saving = true;
    const hashtags = {
      hashtag1: this.formGroup.get("hashtag1")?.value,
      hashtag2: this.formGroup.get("hashtag2")?.value,
      hashtag3: this.formGroup.get("hashtag3")?.value,
    };
    this.assessmentService.setHashtags(accountId, hashtags).subscribe(
      (result) => {
        this.notificationService.success("", this.translateService.instant("admin.assessment.hashtags.update.success"));
        this.modal.close(result);
      },
    );
  }
}
