<nz-page-header nzTitle="{{'mp.profile.invoice-details.sub-title' | translate}}"></nz-page-header>
<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <div nz-row [nzGutter]="24">
    <ng-container formGroupName="invoiceAddress">
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.company-name.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.company-name.placeholder' | translate" formControlName="companyName"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.contact-person-name.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.contact-person-name.placeholder' | translate" formControlName="contactPersonName"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.reference.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.reference.placeholder' | translate" formControlName="reference"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.cost-center.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.cost-center.placeholder' | translate" formControlName="costCenter"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.address-line-1.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.address-line-1.placeholder' | translate" formControlName="addressLine1"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.address-line-2.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.address-line-2.placeholder' | translate" formControlName="addressLine2"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.postal-code.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.postal-code.placeholder' | translate" formControlName="postalCode"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.city.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.city.placeholder' | translate" formControlName="city"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label>
            <mh-explanation-label [labelKey]="'mp.profile.country.label'"></mh-explanation-label>
          </nz-form-label>
          <nz-form-control>
            <input nz-input [placeholder]="'mp.profile.country.placeholder' | translate" formControlName="country"/>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" (click)="submitForm()">{{'save.button' | translate}}</button>
    </div>
  </div>
</form>
