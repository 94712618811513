import { Component, Inject, OnInit, ViewContainerRef } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import { AccountFilter, MissionPartnerAccount } from "../../home-admin-data/account.types";
import { map, take } from "rxjs";
import { UntypedFormBuilder } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { AdminInvoiceCreateComponent } from "./admin-invoice-create/admin-invoice-create.component";
import { Invoice } from "../../../../api/invoices/invoices.types";
import { InvoicesService } from "../../../../api/invoices/invoices.service";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { AccountService } from "../../home-admin-data/account.service";
import { ActivatedRoute } from "@angular/router";
import { Clipboard } from "@angular/cdk/clipboard";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage, InvoiceForm } from "../../../../api/filter-storage/filter-storage.types";

const FILTERS_PAGE = FiltersPage.INVOICES;

@Component({
  selector: "mh-admin-invoice-list",
  templateUrl: "./admin-invoice-list.component.html",
  styleUrls: ["./admin-invoice-list.component.less"],
})
export class AdminInvoiceListComponent implements OnInit {
  invoiceList?: PaginatedContent<Invoice>;

  loading = true;
  searchForm!: InvoiceForm;
  preventDoubleFetch = true;
  missionPartners?: Array<MissionPartnerAccount>;
  private queryMissionPartnerId: string | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private invoicesService: InvoicesService,
    private accountService: AccountService,
    private filterStorageService: FilterStorageService,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private clipboard: Clipboard,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    this.queryMissionPartnerId = this.activatedRoute.snapshot.queryParams['missionPartnerId'];
    this.fetchMissionPartnerList();
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchTerm: [null],
      missionPartnerId: [this.queryMissionPartnerId || null],
    });

    this.initSavedFilters();
    this.fetchInvoiceList({...this.getDefaultQueryParams(), ...this.getFilterFormValues()});
  }

  private initSavedFilters(): void {
    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE] || {} as InvoiceForm;

    if (storedFilters) {
      if (this.queryMissionPartnerId) {
        storedFilters.missionPartnerId = this.queryMissionPartnerId;
      }
      this.searchForm.patchValue(storedFilters);
      this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    }
  }

  fetchMissionPartnerList() {
    this.accountService
      .loadMissionPartnerList({
        paging: {
          page: 1,
          itemsOnPage: 100,
        },
        searchTerm: "",
      })
      .pipe(map((response) => response.items))
      .subscribe((data) => {
        this.missionPartners = data;
      });
  }

  fetchInvoiceList(filter: AccountFilter) {
    this.loading = true;
    this.invoicesService
      .loadInvoiceList(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.invoiceList = paginatedContent;
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const { pageSize, pageIndex, sort, filter } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      ...this.getFilterFormValues(),
    };

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == "ascend" ? "ASC" : "DESC",
            field: sortField,
          },
        ],
      };
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.invoiceList?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    });
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");
    const missionPartnerId = this.getSearchField("missionPartnerId");

    const filterFormValues: Partial<AccountFilter> = {
      searchTerm: searchField?.value || "",
      filters: [],
      groupFilters: [],
    };

    if (missionPartnerId?.value) {
      filterFormValues.filters?.push({
        value: missionPartnerId.value,
        field: "MISSION_PARTNER",
      });
    }

    return filterFormValues;
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchInvoiceList(filter);
  }

  getDefaultQueryParams() {
    const queryParams: AccountFilter = {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: "",
      filters: [],
    }

    if (this.queryMissionPartnerId) {
      queryParams.filters?.push({
        value: this.queryMissionPartnerId,
        field: "MISSION_PARTNER",
      });
    }
    return queryParams;
  }

  openCreateInvoiceModal() {
    const modal = this.modal.create({
      nzContent: AdminInvoiceCreateComponent,
      nzMaskClosable: false,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 800,
    });

    modal.afterClose.subscribe((result: string) => {
      if (result === "success") {
        this.submitFilterForm();
      }
    });
  }

  showDeleteConfirm(invoice: Invoice) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("admin.invoices.delete.confirm.title"),
      nzContent: `<b>${this.translateService.instant("admin.invoices.delete.confirm.text")}</b>`,
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.deleteInvoice(invoice.id),
    });
  }

  deleteInvoice(invoiceId?: string) {
    if (!invoiceId) {
      return;
    }
    this.invoicesService.deleteInvoice(invoiceId).subscribe((success) => {
      if (success) {
        if (this.invoiceList?.items) {
          this.invoiceList.items = this.invoiceList.items.filter((invoice) => invoice.id !== invoiceId);
        }
        this.notificationService.success("", this.translateService.instant("admin.invoices.delete.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  copyId(id: string) {
    const isCopySuccessful = this.clipboard.copy(id);
    if (isCopySuccessful) {
      this.notificationService.success("", this.translateService.instant("admin.copy-id.success"));
    }
  }

  onChangeStatus(status: string, invoiceId?: string) {
    if (invoiceId) {
      this.accountService.changeInvoiceStatus(invoiceId, status)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.notificationService.success("", this.translateService.instant("invoices.change-status.success"));
          }
        });
    }
  }
}
