<div class="container">
  <div nz-row [nzGutter]="[0, 24]">
    <div nz-col [nzSpan]="24">
      <mh-profile-preview
        *ngIf="talent"
        [talent]="talent"
        [trustedView]="talent.deanonymized"
        [cvList]="cvList"
        [fileList]="fileList"
        [references]="references"
        [referencesRequest]="referencesRequest"
        [avatarUrl]="avatarUrl"
        [jobOfferId]="jobOfferId"
        [conversationId]="conversationId"
        [disableConversation]="(hasReviewedStatus$ | async) === false || (hasSelfServiceStatus$ | async) === false"
        (startConversation)="openCreateConversationModal()"
      ></mh-profile-preview>
    </div>
  </div>
</div>
