import { Component, Inject, ViewChild } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../../../home-admin-data/account.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { compareCmsNameValue } from "../../../../../api/cms/cms.types";
import { catchError, map, throwError } from "rxjs";
import { MissionPartnerAccount } from "../../../home-admin-data/account.types";
import { InvoiceFormComponent } from "../invoice-form/invoice-form.component";
import { InvoicesService } from "../../../../../api/invoices/invoices.service";
import { Invoice } from "../../../../../api/invoices/invoices.types";
import { DocumentFileDTO } from "../../../../../api/document/document.types";

@Component({
  selector: "mh-admin-invoice-create",
  templateUrl: "./admin-invoice-create.component.html",
  styleUrls: ["./admin-invoice-create.component.less"],
})
export class AdminInvoiceCreateComponent {
  invoice!: Invoice;
  @ViewChild(InvoiceFormComponent) form!: InvoiceFormComponent;

  missionPartners!: Array<MissionPartnerAccount>;
  uploading = false;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private readonly translateService: TranslateService,
    private accountService: AccountService,
    private invoicesService: InvoicesService,
    private notificationService: NzNotificationService,
  ) {
    this.accountService
      .loadMissionPartnerList({
        paging: {
          page: 1,
          itemsOnPage: 100,
        },
        searchTerm: "",
      })
      .pipe(map((response) => response.items))
      .subscribe((data) => {
        this.missionPartners = data;
      });
  }

  onFormChange(change: Invoice) {
    this.invoice = change;
  }

  onCancel(): void {
    this.modal.close();
  }

  onSave(): void {
    this.form.formGroup.get('invoiceFileId')?.markAsTouched();
    if (!this.form.isValid() || !this.form.fileList.length) {
      return;
    }
    this.uploadInvoiceFile();
  }

  uploadInvoiceFile(): void {
    const formData = new FormData();
    this.form.fileList.forEach((file: any) => {
      formData.append("file", file);
    });
    this.uploading = true;
    this.invoicesService
      .uploadInvoiceFile(this.form.formGroup.get('missionPartnerId')?.value, formData)
      .pipe(
        catchError((error) => {
          this.uploading = false;
          return throwError(() => new Error(error));
        }),
      )
      .subscribe((file) => {
        this.createInvoice(file);
      });
  }

  createInvoice(file: DocumentFileDTO) {
    this.form.formGroup.get('invoiceFileId')?.setValue(file.id);
    this.invoicesService.createInvoice(this.invoice).subscribe((success) => {
      if (success) {
        this.notificationService.success("", this.translateService.instant("admin.invoices.create.success"));
        this.modal.close("success");
      } else {
        this.notificationService.error('', this.translateService.instant('notification.error'));
      }
    });
    this.uploading = false;
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
