import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import { AccountStatus, LoginData } from "../../../../login-page/login-page-data/login.types";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ProfileService } from "../../../../../api/profile/profile.service";

@Component({
  selector: "mh-talent-dashboard-searching-status",
  templateUrl: "./talent-dashboard-searching-status.component.html",
  styleUrls: ["./talent-dashboard-searching-status.component.less"],
})
export class TalentDashboardSearchingStatusComponent implements OnChanges {
  @Input() loginData!: LoginData;
  public activeStatus = true;
  constructor(private notificationService: NzNotificationService, private profileService: ProfileService) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['loginData']) {
      this.activeStatus = !this.loginData.accountStatuses.includes(AccountStatus.VACATIONING);
    }
  }

  onSave() {
    this.profileService.updateOpenStatus(this.activeStatus).subscribe((result) => {
      if(result) this.notificationService.success("", "Setting saved");
    })
  }
}
