export interface TalentProfileCompletenessDTO {
  data: {
    sections: Array<TalentProfileSectionInfo>;
    totalNumberOfFields: number;
    totalFilledOutNumberOfFields: number;
    completenessPercentage: string;
  };
  isProfileFilledOut: boolean;
}

export interface TalentProfileCompleteness {
  sections: Array<TalentProfileSectionInfo>;
  dictionary?: {[key: string]: TalentProfileSectionInfo};
  totalNumberOfFields: number;
  totalFilledOutNumberOfFields: number;
  completenessPercentage: string;
  isProfileFilledOut: boolean;
}

export interface TalentProfileSectionInfo {
  fieldsInSection: number;
  filledOutFieldsInSection: number;
  filledOutFields: Array<string>;
  unfilledOutFields: Array<string>;
  section: TalentProfileSection;
  completenessPercentage: string;
}
export enum TalentProfileSection {
  ABOUT_ME = "ABOUT_ME",
  EDUCATION = "EDUCATION",
  TRACK_RECORD_AND_EXPERIENCE = "TRACK_RECORD_AND_EXPERIENCE",
  DESIRED_JOB = "DESIRED_JOB",
  JOB_SHARING_TANDEM = "JOB_SHARING_TANDEM",
  FAMILY_FRIENDLY_EMPLOYER = "FAMILY_FRIENDLY_EMPLOYER",
  WILLINGNESS_TO_CHANGE = "WILLINGNESS_TO_CHANGE",
  COMPANY_TYPE = "COMPANY_TYPE",
  PREFERRED_WORKING_LANGUAGE = "PREFERRED_WORKING_LANGUAGE",
  PERSONALITY = "PERSONALITY",
  MOM_SKILLS = "MOM_SKILLS",
}
export interface MissionPartnerProfileCompletenessDTO {
  sections: Array<MissionPartnerProfileSectionInfo>;
  totalNumberOfFields: number;
  totalFilledOutNumberOfFields: number;
  completenessPercentage: string;
}

export interface MissionPartnerProfileCompleteness {
  sections: Array<MissionPartnerProfileSectionInfo>;
  totalNumberOfFields: number;
  totalFilledOutNumberOfFields: number;
  completenessPercentage: number;
}

export interface MissionPartnerProfileSectionInfo {
  fieldsInSection: number;
  filledOutFieldsInSection: number;
  filledOutFields: Array<string>;
  unfilledOutFields: Array<string>;
  section: MissionPartnerProfileSection;
  completenessPercentage: string;
}

export enum MissionPartnerProfileSection {
  ABOUT_ME = "ABOUT_ME",
  COMPANY_DETAILS = "COMPANY_DETAILS",
  DIVERSITIES = "DIVERSITIES",
  COMPLIANCE_KPI = "COMPLIANCE_KPI",
  INVOICE_ADDRESS = "INVOICE_ADDRESS"
}

export interface JobOffersInfo {
  lastJO: LastJobOffers[];
  active: number;
}

export interface LastJobOffers {
  id: string;
  name: string;
  matchesAmount: number;
}

export interface DashboardConversationInfoDTO {
  totalAmount: number;
  conversationsInfo: DashboardConversationDTO[];
}

export interface DashboardConversationDTO {
  conversationId: string;
  fullName: string;
  lastMessageTimestamp: number;
}

export interface DashboardConversationInfo {
  totalAmount: number;
  conversationsInfo: DashboardConversation[];
}

export interface DashboardConversation {
  conversationId: string;
  fullName: string;
  lastMessageTimestamp: Date;
}

export interface InvoicesInfo {
  lastInvoices: LastInvoice[];
  newInvoicesCount: number;
}

export interface LastInvoice {
  id: string;
  performanceMonth: string;
  amount: string;
}
