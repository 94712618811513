import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DashboardService } from "./dashboard.service";
import { InvoicesInfo } from "./dashboard.types";

@Injectable({
  providedIn: "root",
})
export class InvoicesInfoResolver {
  constructor(private readonly dashboardService: DashboardService) {}

  resolve(): Observable<InvoicesInfo> {
    return this.dashboardService.getInvoicesNew();
  }
}
