import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, map, Observable, tap, throwError } from 'rxjs';
import * as actions from "./store/login.action";

import {LoginData, LoginDataDTO, LoginRequest, UserStateDTO} from './login.types';
import { Store } from '@ngrx/store';
import { LoginSerializer } from './login.serializer';
import { LoginApi } from './login.api';
import { ApiResponseDTO } from "../../../api/common.types";

@Injectable({ providedIn: 'root' })
export class LoginService {

  constructor(private readonly loginApi: LoginApi,
              private router: Router,
              private store: Store,
              private readonly loginSerializer: LoginSerializer) {
  }

  public login(loginRequest: LoginRequest): Observable<LoginData> {
    return this.loginApi.doLogin(loginRequest.login, loginRequest.password).pipe(
      map((dto: LoginDataDTO) => this.loginSerializer.deserializeLoginData(dto)),
      tap((loginData)  => this.setToken(loginData.token)),
      catchError((error) => throwError(()=>error))
    );
  }

  public getLoginData(): Observable<LoginData> {
    return this.loginApi.getLoginData().pipe(
      map((dto: UserStateDTO) => this.loginSerializer.deserializeUserState(dto)),
      catchError((response: HttpErrorResponse) => {
        if(response.status == 401) this.logout();
        return EMPTY;
      })
    );
  }

  public getToken(): string {
    return sessionStorage.getItem('token') || localStorage.getItem('token') || '';
  }

  public logout() {
    const isSessionStorage = !!sessionStorage.getItem('token');
    this.store.dispatch(actions.logout());
    this.setToken(undefined, isSessionStorage);
    this.router.navigate(['/login']);
  }

  public doLogout() {
    return this.loginApi.doLogout();
  }

  public isAuthenticated() {
    return !!this.getToken();
  }

  public loginSuccess(response: LoginData) {
    this.store.dispatch(actions.loginSuccess({ data: response }));
  }

  public passwordForgotten(email: string): Observable<ApiResponseDTO> {
    return this.loginApi.passwordForgotten(email).pipe(catchError(this.handleError));
  }

  public passwordForgottenCheck(token: string): Observable<ApiResponseDTO> {
    return this.loginApi.passwordForgottenCheck(token).pipe(catchError(this.handleError));
  }

  public resetPassword(password: string): Observable<ApiResponseDTO> {
    return this.loginApi.resetPassword(password).pipe(catchError(this.handleError));
  }

  public setToken(authToken: string | undefined, isSessionStorage = false) {
    const storage = isSessionStorage ? sessionStorage : localStorage;
    if (authToken) {
      storage.setItem('token', authToken);
    } else {
      storage.removeItem('token');
    }
  }

  private updateToken(token: string) {
    if (token) {
      localStorage.setItem('token', token);
    }
  }

  private handleError(error: any): Observable<any> {
    return throwError(() => {
      return error.status;
    });
  }

  public emailConfirmationCheck(emailToken: string): Observable<string> {
    return this.loginApi.emailConfirmationCheck(emailToken);
  }

  public emailChangedConfirmationCheck(emailToken: string): Observable<string> {
    return this.loginApi.emailChangedConfirmationCheck(emailToken);
  }

  emailChange(email: string): Observable<boolean> {
    return this.loginApi.emailChange(email);
  }

  validateEmailChange(email: string): Observable<ApiResponseDTO> {
    return this.loginApi.validateEmailChange(email);
  }

  passwordChange(oldPassword: string, password: string): Observable<string> {
    return this.loginApi.passwordChange(oldPassword, password);
  }

  accountDelete(): Observable<boolean> {
    return this.loginApi.accountDelete();
  }

  loginFromEmail(): Observable<boolean> {
    return this.loginApi.loginFromEmail();
  }
}
