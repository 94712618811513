import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReviewParsedCvDialogComponent } from "./review-parsed-cv-dialog.component";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzButtonModule} from "ng-zorro-antd/button";
import {EducationsInputModule} from "../../common-componnets/educations-input/educations-input.module";
import {TrackRecordsInputModule} from "../../common-componnets/track-record-input/track-records-input.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [ReviewParsedCvDialogComponent],
    imports: [CommonModule, NzModalModule, NzButtonModule, EducationsInputModule, TrackRecordsInputModule, TranslateModule],
  exports: [ReviewParsedCvDialogComponent]
})
export class ReviewParsedCvDialogModule {}
