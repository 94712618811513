import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccountCommentsDialogComponent } from "./account-comments-dialog.component";
import { NzModalModule } from "ng-zorro-antd/modal";
import { TranslateModule } from "@ngx-translate/core";
import { AccountCommentsModule } from "../account-comments/account-comments.module";

@NgModule({
  declarations: [AccountCommentsDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzModalModule,
    AccountCommentsModule,
  ],
  exports: [AccountCommentsDialogComponent],
})
export class AccountCommentsDialogModule {}
