<form class="admin-filters" nz-form [formGroup]="searchForm" (ngSubmit)="submitFilterForm()">
  <div class="admin-filters__fields">
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input type="text" formControlName="searchTerm" nz-input
                 [placeholder]="'admin.filter.search.placeholder' | translate"/>
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span *ngIf="!searchForm.get('searchTerm')?.value" nz-icon nzType="search"></span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchForm.get('searchTerm')?.value"
            (click)="searchForm.get('searchTerm')?.setValue(null)"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select
          nzMode="multiple"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'admin.filter.expert-fields.placeholder' | translate"
          formControlName="expertFields"
        >
          <nz-option
            [nzLabel]="option.name | translate"
            [nzValue]="option.value"
            *ngFor="let option of (expertFields$ | async)"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select
          nzMode="multiple"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'admin.filter.industry.placeholder' | translate"
          formControlName="industry"
        >
          <nz-option
            [nzLabel]="option.name | translate"
            [nzValue]="option.value"
            *ngFor="let option of (industry$ | async)"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select
          nzMode="multiple"
          nzShowSearch
          nzAllowClear
          [nzPlaceHolder]="'admin.filter.careerLevel.placeholder' | translate"
          formControlName="careerLevel"
        >
          <nz-option
            [nzLabel]="option.name | translate"
            [nzValue]="option.value"
            *ngFor="let option of (careerLevel$ | async)"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <mh-salary-expectation-input
      formControlName="salary"
      [usePlaceholder]="true"
      [limitMaxOptions]="false"
      class="admin-filters__field"
    ></mh-salary-expectation-input>
    <mh-location-input
      formControlName="location"
      [zipOnly]="true"
      [distanceInput]="true"
      [groupValidators]="searchForm.get('location')?.validator"
      [groupError]="!!searchForm.get('location')?.hasError('zip')"
      [mark]="searchForm.get('location')?.dirty || false"
      class="admin-filters__field"
    ></mh-location-input>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-range-picker formControlName="timeRange" class="admin-filters__field"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select nzSize="default" formControlName="profileFilled" nzAllowClear [nzPlaceHolder]="'admin.filter.profile-fill.placeholder' | translate">
          <nz-option [nzLabel]="'admin.filter.profile-fill.filled' | translate" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'admin.filter.profile-fill.not-filled' | translate" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select nzSize="default" formControlName="openStatus" nzAllowClear [nzPlaceHolder]="'admin.filter.open-status.placeholder' | translate">
          <nz-option [nzLabel]="'admin.filter.open-status.searching' | translate" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'admin.filter.open-status.not-searching' | translate" [nzValue]="false"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="admin-filters__actions admin-filters__actions--talent-list">
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzBlock nzType="default" type="button"
                [disabled]="isApiLoading"
                (click)="downloadTalentListCSV()">
          <span nz-icon [nzType]="isApiLoading?'loading':'download'" nzTheme="outline"></span>
          {{'admin.download.csv' | translate}}
        </button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <nz-form-label nzFor="additionalInfo">{{'admin.talent-list.additional-info.switch' | translate}}</nz-form-label>
        <nz-switch formControlName="additionalInfo" id="additionalInfo"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__submit">
      <nz-form-control>
        <button nz-button nzBlock nzType="primary" [disabled]="!searchForm.valid">{{'admin.filter.submit' | translate}}</button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<mh-admin-talent-statistic [stats]="accountList?.additionalData['statistic']"></mh-admin-talent-statistic>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="accountList?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="accountList?.paging?.items || 0"
  [nzPageSize]="accountList?.paging?.itemsOnPage || 0"
  [nzPageIndex]="accountList?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th nzColumnKey="NAME" [nzSortFn]="true">
      <div>
        {{'admin.user-name.title' | translate}}
      </div>
      <div *ngIf="showAdditionalInfo" class="subtitle">
        {{'admin.career-level.title' | translate}} / {{'admin.industries.title' | translate}}
      </div>
    </th>
    <th nzColumnKey="EMAIL" [nzSortFn]="true">
      <div>
        {{'admin.email.title' | translate}}
      </div>
      <div *ngIf="showAdditionalInfo" class="subtitle">
        {{'admin.salary-expectations.title' | translate}} / {{'admin.job-titles.title' | translate}}
      </div>
    </th>
    <th nzColumnKey="STATUS" [nzSortFn]="true">
      <div>
        {{'admin.status.title' | translate}}
      </div>
      <div *ngIf="showAdditionalInfo" class="subtitle">
        {{'admin.sector.title' | translate}} / {{'admin.city.title' | translate}}
      </div>
    </th>
    <th nzColumnKey="REG_TIMESTAMP" [nzSortFn]="true" nzSortOrder="descend">
      <div>
        {{'admin.registered.title' | translate}}
      </div>
      <div *ngIf="showAdditionalInfo" class="subtitle">
        {{'admin.courses-of-study.title' | translate}}
      </div>
    </th>
    <th>
      <div>
        {{'admin.action.title' | translate}}
      </div>
      <div *ngIf="showAdditionalInfo" class="subtitle">
        {{'admin.expert-fields.title' | translate}}
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let data of basicTable.data; let i = index">
    <tr class="main-row" [class.main-row--hover]="hoveredRowIndex === i">
      <td>
        <a [routerLink]="['/admin/talent-view', data.accountId.internalId]" target="_blank">
          {{ data.salutation }} {{ data.firstName }} {{data.lastName}}
        </a>
      </td>
      <td>{{ data.email }}</td>
      <td><span *ngFor="let s of data.statuses" nz-tooltip [nzTooltipTitle]="s">{{getStatusShortName(s)}}</span>
      </td>
      <td>{{ data.registrationTimestamp | date : "yyyy-MM-dd HH:mm:ss" }}</td>
      <td class="nowrap">
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.sign-in-as.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="onSignInAs(data.accountId.internalId)"
        >
          <span nz-icon nzType="login" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="data.accountId.internalId"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="copyId(data.accountId.internalId)"
        >
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="data.communityMember"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.community-member-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="setCommunityMemberStatus(data)"
        >
          <span nz-icon nzType="check-circle" nzTheme="fill"></span>
        </button>
        <button
          *ngIf="!data.communityMember"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.community-member-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="setCommunityMemberStatus(data)"
        >
          <span nz-icon nzType="check-circle" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.confirmed-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="setConfirmedStatus(data)"
        >
          <span nz-icon nzType="mail" [nzTheme]="data.statuses.includes(AccountStatus.CONFIRMED) ? 'fill' : 'outline'"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.password-reset-email.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="sendPasswordResetEmail(data.accountId, data.email)"
        >
          <span nz-icon nzType="send" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.set-password.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="openSetPasswordModal(data)"
        >
          <span nz-icon nzType="lock" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.comment.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="openCommentModal(data)"
        >
          <span nz-icon nzType="message" [nzTheme]="data.commentsExist ? 'fill' : 'outline'" [class.comment-icon--active]="data.commentsExist"></span>
        </button>

        <a
          *ngIf="data.cvExist"
          [href]="data.cvUrl"
          target="_blank"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.cv.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
        >
          <span nz-icon nzType="file-text" nzTheme="fill"></span>
        </a>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.export-data.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="downloadData(data.accountId)"
        >
          <span nz-icon nzType="database" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.delete-account.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="showDeleteConfirm(data)"
        >
          <span nz-icon nzType="delete" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          nz-dropdown
          [nzDropdownMenu]="exportMenu"
          nzPlacement="bottomRight">
          <span nz-icon nzType="export" nzTheme="outline"></span>
        </button>
        <nz-dropdown-menu #exportMenu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="exportTalentToBrevo(data)" (keydown.enter)="exportTalentToBrevo(data)">
              {{ "admin.export-talent-to-brevo.label" | translate }}
            </li>
            <li nz-menu-item (click)="exportTalentToOtys(data)" (keydown.enter)="exportTalentToOtys(data)">
              {{ "admin.export-talent-to-otys.label" | translate }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
    <tr *ngIf="showAdditionalInfo" class="sub-row" (mouseenter)="hoveredRowIndex = i" (mouseleave)="hoveredRowIndex = null">
      <td>
        <nz-tag *ngIf="data.careerLevel?.name" nzColor="#FDF2D0" class="tag"
                [title]="data.careerLevel.name | translate">
          {{data.careerLevel.name | translate}}
        </nz-tag>
        <mh-tags-output [value]="data.industries" [sortABC]="true" nzColor="#D77A6B" tagClass="tag" placeholder=""></mh-tags-output>
      </td>
      <td>
        <mh-tags-output *ngIf="data.salaryExpectations && (data.salaryExpectations.min || data.salaryExpectations.max)"
                        tagClass="tag" nzColor="#DCEAD5"
                        [sortABC]="true"
                        [value]="[getSalaryExpectationLabel(data.salaryExpectations)]" placeholder=""></mh-tags-output>
        <mh-tags-output [value]="data.jobTitles" nzColor="#CA93AF" tagClass="tag" placeholder=""></mh-tags-output>
      </td>
      <td>
        <nz-tag *ngIf="data.sector?.name" nzColor="#A594CC" class="tag"
                [title]="data.sector.name | translate">
          {{data.sector.name | translate}}
        </nz-tag>
        <nz-tag *ngIf="data.city" nzColor="#93B3F1" class="tag"
                [title]="data.city">
          {{data.city}}
        </nz-tag>
      </td>
      <td>
        <mh-tags-output [value]="data.coursesOfStudy" [sortABC]="true" nzColor="#FDE187" tagClass="tag" placeholder=""></mh-tags-output>
      </td>
      <td>
        <mh-tags-output [value]="data.expertFields" [sortABC]="true" tagClass="tag" nzColor="#92B8BD" placeholder=""></mh-tags-output>
      </td>
    </tr>
  </ng-container>
  </tbody>
</nz-table>
