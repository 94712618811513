import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MpJobOfferEditComponent } from "./mp-job-offer-edit.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { RouterLink } from "@angular/router";
import { MpJobOfferFormModule } from "../mp-job-offer-form/mp-job-offer-form.module";
import {NzGridModule} from "ng-zorro-antd/grid";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [MpJobOfferEditComponent],
    imports: [
        CommonModule,
        NzButtonModule,
        NzPageHeaderModule,
        NzSpaceModule,
        NzWaveModule,
        RouterLink,
        MpJobOfferFormModule,
        NzGridModule,
        TranslateModule,
    ],
})
export class MpJobOfferEditModule {}
