<h1 class="auth__title" nz-col nzSpan="24">{{ "reg.talent.phone-verification.title" | translate }}</h1>
<form nz-form class="auth__form" [formGroup]="validateForm">
  <nz-form-item *ngIf="!phoneSent">
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzFor="phoneNumber" nzRequired>
      <mh-explanation-label [labelKey]="'reg.talent.phone-verification.phone.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control
      [nzSpan]="24"
      [nzValidateStatus]="validateForm.controls['phoneNumber']"
      [nzErrorTip]="'reg.talent.phone-verification.phone.error' | translate"
    >
      <mh-nz-intl-tel-input
        [preferredCountries]="['de', 'fr']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        name="phoneNumber"
        describedBy="phoneInput"
        formControlName="phoneNumber"
        id="phoneNumber"
      ></mh-nz-intl-tel-input>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="phoneSent">
    <mh-code-input
      [isCodeHidden]="false"
      [codeLength]="6"
      [initialFocusField]="0"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </mh-code-input>
  </nz-form-item>

  <nz-form-item *ngIf="!phoneSent">
    <nz-form-control [nzSpan]="24">
      <button nz-button nzType="primary" nzSize="large" nzBlock (click)="submitPhone()">
        {{ "next-step.button" | translate }}
      </button>
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="phoneSent && !codeValidated">
    <nz-form-item>
      <nz-form-control [nzSpan]="24">
        <button nz-button nzType="primary" nzSize="large" nzBlock [disabled]="!canSubmitCode" (click)="submitCode()">
          {{ "reg.talent.phone-verification.verify.btn" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSpan]="24">
        <a nz-button nzType="link" nzSize="large" nzBlock (click)="onChangePhoneNumber()">
          {{ "reg.talent.phone-verification.change-number.btn" | translate }}
        </a>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
  <ng-container *ngIf="codeValidated">
    <nz-form-item>
      <nz-form-control [nzSpan]="24">
        <button nz-button nzType="primary" nzSize="large" nzBlock (click)="goToNextStep()">
          {{ "reg.talent.phone-verification.next.btn" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</form>
