import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminCommunicationsComponent } from "./admin-communications.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { NzSelectModule } from "ng-zorro-antd/select";
import {NzSwitchModule} from "ng-zorro-antd/switch";

@NgModule({
  declarations: [AdminCommunicationsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzTableModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    RouterLink,
    NzSelectModule,
    NzSwitchModule,
    FormsModule,
  ],
})
export class AdminCommunicationsModule {}
