<form class="admin-filters" nz-form [formGroup]="searchForm" (ngSubmit)="submitFilterForm()">
  <div class="admin-filters__fields">
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-input-group [nzSuffix]="suffixIconSearch">
          <input
            type="text"
            formControlName="searchTerm"
            nz-input
            [placeholder]="'admin.filter.search.placeholder' | translate"
          />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span *ngIf="!searchForm.get('searchTerm')?.value" nz-icon nzType="search"></span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchForm.get('searchTerm')?.value"
            (click)="searchForm.get('searchTerm')?.setValue(null)"
          ></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-select
          nzAllowClear
          [nzPlaceHolder]="'admin.filter.status.reviewed.placeholder' | translate"
          formControlName="statusReviewed"
        >
          <nz-option [nzLabel]="'admin.filter.status.reviewed' | translate" [nzValue]="'REVIEWED'"></nz-option>
          <nz-option [nzLabel]="'admin.filter.status.not-reviewed' | translate" [nzValue]="'NOT_REVIEWED'"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="admin-filters__field">
      <nz-form-control>
        <nz-range-picker class="admin-filters__field" formControlName="timeRange"></nz-range-picker>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="admin-filters__actions">
    <nz-form-item>
      <nz-form-control>
        <button
          nz-button
          nzBlock
          nzType="default"
          type="button"
          [disabled]="isApiLoading"
          (click)="downloadMissionPartnerListCSV()"
        >
          <span nz-icon [nzType]="isApiLoading ? 'loading' : 'download'" nzTheme="outline"></span>
          {{ "admin.download.csv" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" [disabled]="!searchForm.valid">
          {{ "admin.filter.submit" | translate }}
        </button>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>

<nz-table
  class="sticky-table"
  #basicTable
  [nzData]="accountList?.items || []"
  nzPaginationPosition="both"
  nzShowSizeChanger="true"
  nzSize="small"
  [nzShowTotal]="rangeTemplate"
  nzFrontPagination="false"
  [nzLoading]="loading"
  [nzTotal]="accountList?.paging?.items || 0"
  [nzPageSize]="accountList?.paging?.itemsOnPage || 0"
  [nzPageIndex]="accountList?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
    <tr>
      <th nzColumnKey="COMPANY_NAME" [nzSortFn]="true">{{ "admin.company-name.title" | translate }}</th>
      <th nzColumnKey="STAGE">{{ "admin.stage.title" | translate }}</th>
      <th nzColumnKey="CONTACT_NAME" [nzSortFn]="true">{{ "admin.contact-name.title" | translate }}</th>
      <th nzColumnKey="EMAIL" [nzSortFn]="true">{{ "admin.email.title" | translate }}</th>
      <th nzColumnKey="STATUS" [nzSortFn]="true">{{ "admin.status.title" | translate }}</th>
      <th nzColumnKey="REG_TIMESTAMP" [nzSortFn]="true" nzSortOrder="descend">
        {{ "admin.registered.title" | translate }}
      </th>
      <th>{{ "admin.action.title" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>
        <a [routerLink]="['/admin/mission-partner-view', data.accountId.internalId]" target="_blank">
          {{ data.companyName }}
        </a>
      </td>
      <td>
        <nz-select
          nzShowSearch
          [nzPlaceHolder]="'admin.stage.placeholder' | translate"
          [ngModel]="data.stage"
          (ngModelChange)="setStageMissionPartner(data.accountId.internalId, $event)"
          style="width: 100%"
        >
          <nz-option
            *ngFor="let stage of missionPartnerStage$ | async"
            [nzLabel]="stage.name | translate"
            [nzValue]="stage.value"
          ></nz-option>
        </nz-select>
      </td>
      <td>{{ data.contactName }}</td>
      <td>{{ data.email }}</td>
      <td>
        <span *ngFor="let s of data.statuses" nz-tooltip [nzTooltipTitle]="s.toString()">
          {{ getStatusShortName(s) }}
        </span>
      </td>
      <td>{{ data.registrationTimestamp | date : "yyyy-MM-dd HH:mm:ss" }}</td>
      <td class="nowrap">
        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.sign-in-as.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="onSignInAs(data.accountId.internalId)"
        >
          <span nz-icon nzType="login" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="data.accountId.internalId"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="copyId(data.accountId.internalId)"
        >
          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="!data.statuses.includes(AccountStatus.PASSIVE)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.set-passive-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="setPassiveStatusMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="eye-invisible" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="data.statuses.includes(AccountStatus.PASSIVE)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.remove-passive-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="removePassiveStatusMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="eye-invisible" nzTheme="fill"></span>
        </button>

        <button
          *ngIf="!data.statuses.includes(AccountStatus.REVIEWED)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.review-mission-partner.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="reviewMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="check-circle" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="data.statuses.includes(AccountStatus.REVIEWED)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.discard-review-mission-partner.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="discardReviewMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="check-circle" nzTheme="fill"></span>
        </button>

        <button
          *ngIf="!data.statuses.includes(AccountStatus.SELF_SERVICE)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.set-self-service-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="setSelfServiceStatusMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="tool" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="data.statuses.includes(AccountStatus.SELF_SERVICE)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.remove-self-service-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="removeSelfServiceStatusMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="tool" nzTheme="fill"></span>
        </button>

        <button
          *ngIf="!data.statuses.includes(AccountStatus.PREMIUM)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.set-premium-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="setPremiumStatusMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="star" nzTheme="outline"></span>
        </button>

        <button
          *ngIf="data.statuses.includes(AccountStatus.PREMIUM)"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.remove-premium-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="removePremiumStatusMissionPartner(data.accountId.internalId)"
        >
          <span nz-icon nzType="star" nzTheme="fill"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.confirmed-status.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="setConfirmedStatus(data)"
        >
          <span
            nz-icon
            nzType="mail"
            [nzTheme]="data.statuses.includes(AccountStatus.CONFIRMED) ? 'fill' : 'outline'"
          ></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.password-reset-email.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="sendPasswordResetEmail(data.accountId, data.email)"
        >
          <span nz-icon nzType="send" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.set-password.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="openSetPasswordModal(data)"
        >
          <span nz-icon nzType="lock" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.comment.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="openCommentModal(data)"
        >
          <span
            nz-icon
            nzType="message"
            [nzTheme]="data.commentsExist ? 'fill' : 'outline'"
            [class.comment-icon--active]="data.commentsExist"
          ></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.export-data.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="downloadData(data.accountId)"
        >
          <span nz-icon nzType="database" nzTheme="outline"></span>
        </button>

        <button
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.delete-account.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          (click)="showDeleteConfirm(data)"
        >
          <span nz-icon nzType="delete" nzTheme="outline"></span>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
