import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerAssessmentComponent } from "./mission-partner-assessment.component";
import { RouterModule } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { NzTableModule } from "ng-zorro-antd/table";
import { TranslateModule } from "@ngx-translate/core";
import { NzCardModule } from "ng-zorro-antd/card";
import { ReactiveFormsModule } from "@angular/forms";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzDividerModule } from "ng-zorro-antd/divider";

@NgModule({
  declarations: [MissionPartnerAssessmentComponent],
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
    NzWaveModule,
    NzAlertModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzIconModule,
    ClipboardModule,
    NzTableModule,
    TranslateModule,
    NzCardModule,
    ReactiveFormsModule,
    NzDescriptionsModule,
    NzTagModule,
    NzPageHeaderModule,
    NzDividerModule,
  ],
})
export class MissionPartnerAssessmentModule {}
