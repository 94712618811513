import { Component, EventEmitter, Input, Output } from "@angular/core";
import { JobOffer } from "../../../../../api/job-offer/job-offer.types";

@Component({
  selector: "mh-mp-job-offer-list",
  templateUrl: "./mp-job-offer-list.component.html",
  styleUrls: ["./mp-job-offer-list.component.less"],
})
export class MpJobOfferListComponent {
  @Input() jobOfferListActive: Array<JobOffer> = [];
  @Input() jobOfferListArchived: Array<JobOffer> = [];
  @Input() hasReviewedStatus = false;
  @Output() archiveJobOffer = new EventEmitter<JobOffer>();
  @Output() unArchiveJobOffer = new EventEmitter<JobOffer>();

  onArchive(entity: JobOffer) {
    this.archiveJobOffer.emit(entity);
  }

  onUnArchive(entity: JobOffer) {
    this.unArchiveJobOffer.emit(entity);
  }
}
