<h1 class="auth__title" nz-col nzSpan="24">{{ "reg.mp.title" | translate }}</h1>

<form nz-form class="auth__form" [nzAutoTips]="autoTips" [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.mp.company.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24">
      <input
        nz-input
        nzSize="large"
        [placeholder]="'reg.mp.company.placeholder' | translate"
        formControlName="companyName"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.mp.email.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="emailErrorTpl">
      <input
        nz-input
        nzSize="large"
        [placeholder]="'reg.mp.email.placeholder' | translate"
        formControlName="email"
        autocomplete="email"
        (paste)="onEmailPaste('email')"
      />
      <ng-template #emailErrorTpl let-control>
        <ng-container *ngIf="control.hasError('email')">{{ "field.invalid.label" | translate }}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{ "field.required.label" | translate }}</ng-container>
        <ng-container *ngIf="control.hasError('confirm')">{{ "reg.mp.email-confirm.error" | translate }}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'" nzRequired>
      <mh-explanation-label [labelKey]="'reg.mp.email-confirm.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-form-control [nzSpan]="24" [nzErrorTip]="emailConfirmErrorTpl" [nzDisableAutoTips]="true">
      <input
        nz-input
        nzSize="large"
        [placeholder]="'reg.mp.email.placeholder' | translate"
        formControlName="emailConfirm"
        autocomplete="email"
        (paste)="onEmailPaste('emailConfirm')"
      />
      <ng-template #emailConfirmErrorTpl let-control>
        <ng-container *ngIf="control.hasError('email')">{{ "field.invalid.label" | translate }}</ng-container>
        <ng-container *ngIf="control.hasError('required')">{{ "field.required.label" | translate }}</ng-container>
        <ng-container *ngIf="control.hasError('confirm')">{{ "reg.mp.email-confirm.error" | translate }}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-label [nzSpan]="24" nzRequired>
    <mh-explanation-label [labelKey]="'reg.mp.password.label'"></mh-explanation-label>
  </nz-form-label>
  <nz-form-control [nzSpan]="24" nzDisableAutoTips>
    <input
      nz-input
      nzSize="large"
      type="password"
      [placeholder]="'reg.mp.password.placeholder' | translate"
      formControlName="password"
      minlength="8"
      autocomplete="new-password"
    />
    <mh-password-validation-tips [passwordControl]="password"></mh-password-validation-tips>
  </nz-form-control>
</form>

<form
  nz-form
  class="auth__form"
  nzLayout="vertical"
  [nzAutoTips]="autoTips"
  [formGroup]="knownFromFormGroup"
  (ngSubmit)="submitForm()"
>
  <div nz-row [nzGutter]="12">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'reg.mp.know-from.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzSize="large"
            formControlName="knownFrom"
            [nzPlaceHolder]="'select.placeholder' | translate"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              [nzLabel]="option.name | translate"
              [nzValue]="option"
              *ngFor="let option of knownFrom$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item *ngIf="showKnownFromDetailsControl">
        <nz-form-label>
          <mh-explanation-label [labelKey]="'reg.mp.know-from-details.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="large" formControlName="knownFromText" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<form nz-form class="auth__form" nzLayout="vertical" [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-control [nzSpan]="24" nzDisableAutoTips [nzErrorTip]="termsErrorTpl">
      <label nz-checkbox formControlName="termsAndConditions">
        <span
          (click)="stopPropagation($event)"
          [innerHtml]="'reg.mp.terms.label' | translate : { dataSafetyLink, termsLink }"
        ></span>
        <span class="required-text"></span>
      </label>
      <ng-template #termsErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">{{ "reg.mp.terms.validation" | translate }}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control [nzSpan]="24">
      <label nz-checkbox formControlName="updatesSubscription">
        <span>
          <mh-explanation-label [labelKey]="'reg.mp.subscribe.label'"></mh-explanation-label>
        </span>
      </label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="24" nzDisableAutoTips>
      <label nz-checkbox formControlName="interestedInDadHunting">
        <span>
          <mh-explanation-label [labelKey]="'reg.mp.dad-hunting.label'"></mh-explanation-label>
        </span>
      </label>
    </nz-form-control>
  </nz-form-item>

  <p>{{ "reg.required.explanation" | translate }}</p>

  <nz-form-item>
    <nz-form-control [nzSpan]="24">
      <button nz-button nzType="primary" nzSize="large" nzBlock>{{ "reg.mp.register.btn" | translate }}</button>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control [nzSpan]="24">
      <a [routerLink]="['/login']" nz-button nzType="link" nzSize="large" nzBlock>
        {{ "reg.talent.login.link" | translate }}
      </a>
    </nz-form-control>
  </nz-form-item>
</form>
