<form nz-form nzLayout="vertical" [formGroup]="form" class="card-list">
  <div class="card-list__item">
    <div class="card-list__body">
      <div nz-row [nzGutter]="12">
        <div nz-col [nzSpan]="24" [nzMd]="8">
          <nz-form-item>
            <nz-form-label [nzRequired]="!disabled">
              <mh-explanation-label [labelKey]="'reg.talent.zip.label'"></mh-explanation-label>
            </nz-form-label>
            <nz-form-control [nzErrorTip]="zipErrorTpl">
              <nz-input-group [nzSuffix]="suffixTemplateInfo" [nzStatus]="!countryControl?.getRawValue() && !value?.length ? 'error' : ''">
              <input nz-input [placeholder]="'reg.talent.zip.label' | translate" formControlName="postalCode"
                     (focus)="zipFocused=true" (blur)="handleZipBlur()"/>
              </nz-input-group>
            </nz-form-control>
            <ng-template #zipErrorTpl let-control>
              <ng-container *ngIf="control.hasError('minlength')">Invalid code</ng-container>
              <ng-container *ngIf="control.hasError('duplicatePostalCode')">{{'postal-code.duplicate' | translate}}</ng-container>
            </ng-template>
            <ng-template #suffixTemplateInfo>
              <span *ngIf="loading" nz-icon nzType="loading" nzTheme="outline"></span>
            </ng-template>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" [nzMd]="8">
          <nz-form-item>
            <nz-form-label>
              <mh-explanation-label [labelKey]="'reg.talent.city.label'"></mh-explanation-label>
            </nz-form-label>
            <nz-form-control>
              <nz-select [nzPlaceHolder]="'reg.talent.city.label' | translate" formControlName="city" [nzDisabled]="disabled">
                <nz-option [nzValue]="option" [nzLabel]="option" *ngFor="let option of cityOptions"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" [nzMd]="8">
          <nz-form-item>
            <nz-form-label>
              <mh-explanation-label [labelKey]="'reg.talent.country.label'"></mh-explanation-label>
            </nz-form-label>
            <nz-form-control>
              <input nz-input [placeholder]="'reg.talent.country.label' | translate" formControlName="country"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24" [nzMd]="12">
          <nz-form-item>
            <nz-form-label>
              <mh-explanation-label [labelKey]="'admin.jo.radius-km.label'"></mh-explanation-label>
            </nz-form-label>
            <nz-form-control>
              <nz-select
                nzSize="default"
                formControlName="radius"
                [nzDisabled]="disabled"
                [nzPlaceHolder]="'admin.jo.radius-km.label' | translate"
                [compareWith]="compareCmsNameValue"
                nzAllowClear
              >
                <nz-option
                  *ngFor="let option of radiusOptions$ | async"
                  [nzLabel]="option.name | translate"
                  [nzValue]="option"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="24" [nzMd]="12" class="card-list__form-buttons">
          <nz-form-item style="min-width: 100%">
            <nz-form-control class="flex-end">
              <button nz-button nzBlock type="button" [nzType]="'primary'" [disabled]="disabled || !countryControl?.getRawValue()" (click)="addLocation()">
                {{ "add.button" | translate }}
              </button>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="card-grid" *ngIf="value?.length && !disabled">
  <div class="card-grid__body">
    <div nz-row>
      <div nz-col [nzSpan]="0" [nzMd]="4">
        <div class="card-grid__item-label">{{'reg.talent.zip.label' | translate}}</div>
      </div>
      <div nz-col [nzSpan]="0" [nzMd]="6">
        <div class="card-grid__item-label">{{'reg.talent.city.label' | translate}}</div>
      </div>
      <div nz-col [nzSpan]="0" [nzMd]="4">
        <div class="card-grid__item-label">{{'admin.jo.radius-km.label' | translate}}</div>
      </div>
      <div nz-col [nzSpan]="0" [nzMd]="6">
        <div class="card-grid__item-label">{{'reg.talent.country.label' | translate}}</div>
      </div>
    </div>
    <div class="card-grid__item" *ngFor="let location of value; let index">
      <div nz-row>
        <div nz-col [nzSpan]="8" [nzMd]="0">
          <div class="card-grid__item-label">{{'reg.talent.zip.label' | translate}}</div>
        </div>
        <div nz-col [nzSpan]="16" [nzMd]="4">
          <div class="card-grid__item-value">{{ location.postalCode }}</div>
        </div>
        <div nz-col [nzSpan]="8" [nzMd]="0">
          <div class="card-grid__item-label">{{'reg.talent.city.label' | translate}}</div>
        </div>
        <div nz-col [nzSpan]="16" [nzMd]="6">
          <div class="card-grid__item-value">{{ location.city }}</div>
        </div>
        <div nz-col [nzSpan]="8" [nzMd]="0">
          <div class="card-grid__item-label">{{'admin.jo.radius-km.label' | translate}}</div>
        </div>
        <div nz-col [nzSpan]="16" [nzMd]="4">
          <div class="card-grid__item-value">{{ location.radius?.name! | translate }}</div>
        </div>
        <div nz-col [nzSpan]="8" [nzMd]="0">
          <div class="card-grid__item-label">{{'reg.talent.country.label' | translate}}</div>
        </div>
        <div nz-col [nzSpan]="16" [nzMd]="6">
          <div class="card-grid__item-value">{{ location.country }}</div>
        </div>
      </div>
      <div class="card-grid__actions">
        <button nz-button nzType="link" nzSize="small" nzShape="circle" nzDanger [nz-tooltip]="'Delete'"
                (click)="deleteLocation(location)">
          <span nz-icon nzType="delete"></span>
        </button>
        <button nz-button nzType="link" nzSize="small" nzShape="circle" [nz-tooltip]="'Edit'"
          (click)="editLocation(location)">
          <span nz-icon nzType="edit"></span>
        </button>
      </div>
    </div>
  </div>
</div>
