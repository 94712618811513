import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupTalentGeneralComponent } from "./signup-talent-general.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import {PasswordValidationTipsModule} from "../../../../../../../common-ui/src/lib/password-validation-tips/password-validation-tips.module";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {
    ExplanationLabelModule
} from "../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [SignupTalentGeneralComponent],
    imports: [
        CommonModule,
        NzButtonModule,
        NzCheckboxModule,
        NzFormModule,
        NzGridModule,
        NzInputModule,
        NzWaveModule,
        NzIconModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterLink,
        PasswordValidationTipsModule,
        NzDatePickerModule,
        ExplanationLabelModule,
    ],
  exports: [SignupTalentGeneralComponent],
})
export class SignupTalentGeneralModule {}
