<ng-container *nzModalTitle>
  {{'admin.set-password.confirm.title' | translate}}
</ng-container>

<form nz-form [formGroup]="validateForm">
  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzFor="email">{{'admin.set-password.email.label' | translate}}</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <span id="email">{{account.email}}</span>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24" nzRequired nzFor="email">{{'admin.set-password.password.label' | translate}}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Required">
      <input nz-input formControlName="password" id="password" type="password" [placeholder]="'admin.set-password.password.placeholder' | translate"/>
    </nz-form-control>
  </nz-form-item>
</form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">{{'admin.set-password.confirm.cancel.btn' | translate}}</button>
  <button nz-button nzType="primary" (click)="onSave()">{{'admin.set-password.confirm.ok.btn' | translate}}</button>
</ng-container>
