import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentProfileComponent } from "./talent-profile.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { ProfileEducationModule } from "./steps/profile-education/profile-education.module";
import { ProfileExperienceModule } from "./steps/profile-experience/profile-experience.module";
import { ProfileDesiredJobModule } from "./steps/profile-desired-job/profile-desired-job.module";
import { ProfileFamilyFriendlyModule } from "./steps/profile-family-friendly/profile-family-friendly.module";
import { ProfileChangeReadyModule } from "./steps/profile-change-ready/profile-change-ready.module";
import { ProfilePersonalityModule } from "./steps/profile-personality/profile-personality.module";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { ProfileMomSkillsModule } from "./steps/profile-mom-skills/profile-mom-skills.module";
import { ProfileAboutMeModule } from "./steps/profile-about-me/profile-about-me.module";
import { IconModule, NavBarModule, StepsModule } from "ng-zorro-antd-mobile";
import { TranslateModule } from "@ngx-translate/core";
import { TalentProfileCompletenessModule } from "./talent-profile-completeness/talent-profile-completeness.module";
import { TalentProfileCvDialogModule } from "./talent-profile-cv-dialog/talent-profile-cv-dialog.module";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";

@NgModule({
  declarations: [TalentProfileComponent],
  imports: [
    CommonModule,
    NzGridModule,
    NzStepsModule,
    ProfileEducationModule,
    ProfileExperienceModule,
    ProfileDesiredJobModule,
    ProfileFamilyFriendlyModule,
    ProfileChangeReadyModule,
    ProfilePersonalityModule,
    ProfileMomSkillsModule,
    ProfileAboutMeModule,
    NzProgressModule,
    StepsModule,
    TranslateModule,
    IconModule,
    NavBarModule,
    TalentProfileCompletenessModule,
    TalentProfileCvDialogModule,
    NzPageHeaderModule,
  ],
})
export class TalentProfileModule {}
