import {Component, Input} from "@angular/core";
import {addYears, differenceInMonths, differenceInYears, format} from "date-fns";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "mh-employment-duration-label",
  templateUrl: "./employment-duration-label.component.html",
  styleUrls: ["./employment-duration-label.component.less"],
})
export class EmploymentDurationLabelComponent {
  @Input() employmentDurationRange!: Array<Date | undefined> | undefined;
  @Input() employmentDurationFromToNow?: Date | null;
  @Input() workingUntilNow?: boolean;
  @Input() hideLabel = false;

  get employmentDurationLabel(): string {
    const dateFormat = 'yyyy.MM';

    const startDate = this.workingUntilNow ? this.employmentDurationFromToNow : this.employmentDurationRange?.[0];
    const endDate = this.employmentDurationRange?.[1] || new Date();

    if (!startDate) {
      return '-';
    }

    const deltaYears = differenceInYears(endDate, startDate);
    const deltaMonths = differenceInMonths(endDate, addYears(startDate, deltaYears));

    const yearsSuffixKey = deltaYears > 1 ? 'years.label' : 'year.label';
    const yearsSuffix = this.translateService.instant(yearsSuffixKey);
    const yearsLabel = deltaYears > 0 ? deltaYears + ' '+ yearsSuffix : '';

    const monthsSuffixKey = deltaMonths > 1 ? 'months.label' : 'month.label';
    const monthsSuffix = this.translateService.instant(monthsSuffixKey);
    const monthsLabel = deltaYears && deltaMonths === 0 ? '' : deltaMonths + ' ' + monthsSuffix;

    if (this.workingUntilNow) {
      const label = !this.hideLabel ? `<div class="duration-label">${yearsLabel} ${monthsLabel}</div>` : '';
      const range = `<div class="duration-range">(${format(startDate, dateFormat)} - ${this.translateService.instant('working-until-now.label')})`;
      return label + range;
    }

    const label = !this.hideLabel ? `<div class="duration-label">${yearsLabel} ${monthsLabel}</div>` : '';
    const range = `<div class="duration-range">(${format(startDate, dateFormat)} - ${format(endDate, dateFormat)})</div>`;
    return label + range;
  }

  constructor(private translateService: TranslateService) {}

}
