import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupTalentJobPreferencesComponent } from "./signup-talent-job-preferences.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import {NzSliderModule} from "ng-zorro-antd/slider";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {LocationSelectModule} from "../../../../common-componnets/location-select/location-select.module";
import {NzAutocompleteModule} from "ng-zorro-antd/auto-complete";
import {
  SalaryExpectationInputModule
} from "../../../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation-input.module";
import {LocationInputModule} from "../../../../../../../common-ui/src/lib/location-input/location-input.module";
import {
    ExplanationLabelModule
} from "../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [SignupTalentJobPreferencesComponent],
    imports: [
        CommonModule,
        NzGridModule,
        NzFormModule,
        NzInputModule,
        ReactiveFormsModule,
        TranslateModule,
        NzButtonModule,
        NzWaveModule,
        NzSliderModule,
        NzSelectModule,
        NzInputNumberModule,
        NzRadioModule,
        LocationSelectModule,
        NzAutocompleteModule,
        SalaryExpectationInputModule,
        LocationInputModule,
        FormsModule,
        ExplanationLabelModule,
    ],
  exports: [SignupTalentJobPreferencesComponent],
})
export class SignupTalentJobPreferencesModule {}
