import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef} from "@angular/core";
import { Store } from "@ngrx/store";
import { jobOfferFeature, JobOfferState } from "../job-offer-data/store/job-offer.reducer";
import {
  isLoadingJobOfferMatchedTalentList,
  selectJobOfferEntity,
  selectJobOfferMatchedTalentList,
} from "../job-offer-data/store/job-offer.selectors";
import {map, Observable, Subject, Subscription, takeUntil, tap} from "rxjs";
import { JobOffer, MatchedTalent, MatchedTalentStatus } from "../../../../../api/job-offer/job-offer.types";
import { ActivatedRoute, Router } from "@angular/router";
import { JobOfferAction } from "../job-offer-data/store/job-offer.action";
import { AccountFilter, Filter } from "../../../../home-admin/home-admin-data/account.types";
import { initialPaginatedContent, PaginatedContent } from "../../../../../api/common.types";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  InitiateConversationRequest,
  MessageSubType,
  MissionPartnerMessageSuggest
} from "../../../../../api/messages/messages.types";
import { MessagesService } from "../../../../../api/messages/messages.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { messagesFeature, MessagesState } from "../../../../../common-componnets/messages/store/messages.reducer";
import { MessagesAction } from "../../../../../common-componnets/messages/store/messages.action";
import { CreateConversationDialogComponent } from "../../../../../common-componnets/create-conversation-dialog/create-conversation-dialog.component";
import { JobOfferTalentCommentsDialogComponent } from "./job-offer-talent-comments-dialog/job-offer-talent-comments-dialog.component";
import { JobOfferService } from "../../../../../api/job-offer/job-offer.service";
import { MhNotificationType, MhSocketMessage } from "../../../../../api/notification/notification.types";
import { SocketService } from "../../../../../../../../core/src/lib/soket/socket.service";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountType } from "../../../../login-page/login-page-data/login.types";
import { FilterStorageService } from "../../../../../api/filter-storage/filter-storage.service";
import { FiltersPage } from "../../../../../api/filter-storage/filter-storage.types";
import {BreakpointObserver} from "@angular/cdk/layout";
import {AuthorizationService} from "../../../../../api/authorization/authorization.service";

const FILTERS_PAGE = FiltersPage.JOB_OFFER_MATCHES;

@Component({
  selector: "mh-mp-job-offer-matches",
  templateUrl: "./mp-job-offer-matches.component.html",
  styleUrls: ["./mp-job-offer-matches.component.less"],
})
export class MpJobOfferMatchesComponent implements OnInit, OnDestroy {
  jobOfferId: string | null;
  jobOfferEntity$: Observable<JobOffer | undefined>;
  hasReviewedStatus$: Observable<boolean>;
  hasSelfServiceStatus$: Observable<boolean>;
  jobOfferEntity?: JobOffer;
  jobOfferMatchesList$: Observable<PaginatedContent<MatchedTalent>>;
  loadingMatchesList$: Observable<boolean>;
  currentFilter?: AccountFilter;
  searchForm!: UntypedFormGroup;
  isMobileView$: Observable<boolean>;

  private subscriptions: Subscription[] = [];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<{ [jobOfferFeature]: JobOfferState; [messagesFeature]: MessagesState }>,
    private messagesService: MessagesService,
    private filterStorageService: FilterStorageService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private router: Router,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private jobOfferService: JobOfferService,
    private socketService: SocketService,
    private authorizationService: AuthorizationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.jobOfferId = this.activatedRoute.snapshot.paramMap.get("id");
    this.jobOfferEntity$ = this.store
      .select(selectJobOfferEntity)
      .pipe(tap((jobOffer) => (this.jobOfferEntity = jobOffer)));
    this.jobOfferMatchesList$ = this.store.select(selectJobOfferMatchedTalentList);
    this.loadingMatchesList$ = this.store.select(isLoadingJobOfferMatchedTalentList);
    this.hasReviewedStatus$ = this.authorizationService.hasReviewedStatus().pipe(takeUntil(this.destroy$));
    this.hasSelfServiceStatus$ = this.authorizationService.hasSelfServiceStatus().pipe(takeUntil(this.destroy$));
    this.subscriptions.push(
      this.socketService.messages$.subscribe((message: MhSocketMessage) => {
        if (message.action === MhNotificationType.COMMUNICATION_MESSAGE) {
          if(message.message?.subType === MessageSubType.PROFILE_ACCESS_GRANTED
            || message.message?.subType === MessageSubType.PROFILE_ACCESS_REJECTED){
            //update mission partner name, uncover if access granted, hide if rejected
            this.onFilterChange(this.currentFilter as AccountFilter);
          }
        }
      }),
    );
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 767px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      matchesFilter: [''],
      externalSort: [{ key: 'SCORE', value: 'descend' }],
    });
    this.searchForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.submitFilterForm();
    });

    this.initSavedFilters();
  }

  private initSavedFilters(): void {
    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE] || {};
    if (storedFilters) {
      this.searchForm.patchValue(storedFilters);
    }
  }

  onFilterChange(filter: AccountFilter) {
    const externalSort = this.searchForm.get("externalSort");
    if(externalSort?.value) {
      filter = {
        ...filter,
        sort: [
          {
            order: externalSort.value.value == "ascend" ? "ASC" : "DESC",
            field: externalSort.value.key,
          },
        ],
      }
    }
    filter = {
      ...filter,
      filters: [...(filter.filters || []), ...this.getFilterFormValues()]
    }
    this.currentFilter = filter;
    this.store.dispatch(JobOfferAction.loadJobOfferMatchedTalentList({ filter }));
  }

  onTalentClick(talent: MatchedTalent): void {
    if (talent.status?.includes(MatchedTalentStatus.UNSEEN) && this.jobOfferId) {
      this.jobOfferService.readJobOfferMatch(this.jobOfferId, talent.accountId).subscribe((result) => {
        if (result) {
          this.onReadTalent({
            ...talent,
            status: talent.status.filter(status => status !== MatchedTalentStatus.UNSEEN),
          });
          this.navigateToTalentView(talent.accountId);
        }
      });
      return;
    }
    this.navigateToTalentView(talent.accountId);
  }

  private navigateToTalentView(talentId: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(
      ["/mission-partner/view-talent", talentId],
      {queryParams: { jobOfferId: this.jobOfferId }}
    ));
    window.open(url, "_blank");
  }

  onPageSizeChange(pageSize: number) {
    if (this.currentFilter) {
      const newFilter: AccountFilter = {
        ...this.currentFilter,
        paging: {
          ...this.currentFilter.paging,
          itemsOnPage: pageSize,
        },
      };
      this.currentFilter = newFilter;
      this.store.dispatch(JobOfferAction.loadJobOfferMatchedTalentList({ filter: newFilter }));
    }
  }

  onPageIndexChange(pageIndex: number) {
    if (this.currentFilter) {
      const newFilter: AccountFilter = {
        ...this.currentFilter,
        paging: {
          ...this.currentFilter.paging,
          page: pageIndex,
        },
      };
      this.currentFilter = newFilter;
      this.store.dispatch(JobOfferAction.loadJobOfferMatchedTalentList({ filter: newFilter }));
    }
  }

  onReadTalent(entity: MatchedTalent) {
    this.store.dispatch(JobOfferAction.updateJobOfferMatchedTalentSuccess({ entity }));
  }

  navigateToJOListPage(): Promise<boolean> {
    return this.router.navigate(["/", "mission-partner", "job-offers"]);
  }

  onStartConversation(talent: MatchedTalent): void {
    this.openCreateConversationModal(talent);
  }

  onToConversation(talent: MatchedTalent) {
    return this.router.navigate(["/", "mission-partner", "inbox", "conversation", talent.conversationId], {
      queryParams: { origin: "job-offer-matches", originId: this.jobOfferId },
    });
  }

  onDoArchive(entity: MatchedTalent) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("confirm.archive.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => {
        this.store.dispatch(
          JobOfferAction.archiveJobOfferMatch({ jobOfferId: this.jobOfferEntity?.id as string, entity }),
        );
      },
    });
  }

  onDoUnArchive(entity: MatchedTalent) {
    this.store.dispatch(
      JobOfferAction.unArchiveJobOfferMatch({ jobOfferId: this.jobOfferEntity?.id as string, entity }),
    );
  }

  openCreateConversationModal(talent: MatchedTalent) {
    const modal = this.modal.create({
      nzContent: CreateConversationDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        suggestRequest: {
          talentId: talent.accountId,
          jobOpportunityId: this.jobOfferEntity?.id,
        } as MissionPartnerMessageSuggest,
      },
      nzWidth: 800,
      nzOnOk: (modalCmp) => this.doCreateConversation(talent, modalCmp.formGroup.value),
    });
  }

  doCreateConversation(talent: MatchedTalent, formData: Partial<InitiateConversationRequest>) {
    this.messagesService
      .initiateConversation(talent.accountId, formData.messageText as string, this.jobOfferEntity?.id as string)
      .subscribe((conversation) => {
        if (conversation) {
          this.notificationService.success("", this.translateService.instant("notification.success"));
          this.store.dispatch(MessagesAction.initiateConversationSuccess({ data: conversation }));
        } else {
          this.notificationService.error("", this.translateService.instant("notification.error"));
        }
      });
  }

  submitFilterForm(): void {
    this.store.dispatch(JobOfferAction.loadJobOfferMatchedTalentList({ filter: this.populateAccountFilter() }));
  }

  getFilterFormValues() {
    const matchesFilterField = this.searchForm?.get("matchesFilter");
    const filterFormFilters: Array<Filter> = [];
    if (matchesFilterField?.value) {
      filterFormFilters.push({
        field: matchesFilterField.value,
        value: "true",
      });
    }
    return filterFormFilters;
  }

  private populateAccountFilter(): AccountFilter {
    const externalSort = this.searchForm?.get("externalSort");
    let filter = {
      ...initialPaginatedContent,
      filters: [
        {
          value: this.jobOfferId,
          field: "JOB_OPPORTUNITY",
        },
      ],
    } as AccountFilter;
    if(externalSort?.value?.key) {
      filter = {
        ...filter,
        sort: [
          {
            order: externalSort.value.value == "ascend" ? "ASC" : "DESC",
            field: externalSort.value.key || "",
          },
        ],
      }
    }
    filter = {
      ...filter,
      filters: [...(filter.filters || []), ...this.getFilterFormValues()]
    }
    this.currentFilter = filter;
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    return filter;
  }

  onOpenCommentModal(talent: MatchedTalent) {
    if (!this.jobOfferEntity?.id) {
      return;
    }

    const modal = this.modal.create({
      nzContent: JobOfferTalentCommentsDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: false,
      nzCentered: true,
      nzWidth: "800px",
      nzStyle: { padding: "30px 0" },
      nzBodyStyle: { padding: "24px 24px 0" },
      nzData: {
        jobOfferId: this.jobOfferEntity?.id,
        talent,
      },
      nzFooter: null,
    });
  }

  onSetTalentFavourite(talent: MatchedTalent, event?: Event) {
    if (!this.jobOfferEntity?.id) {
      return;
    }

    this.jobOfferService.setTalentFavourite(this.jobOfferEntity?.id, talent.accountId, !talent.favourite)
      .subscribe((success) => {
        if (success) {
          this.store.dispatch(JobOfferAction.updateJobOfferMatchedTalentSuccess({ entity: { ...talent, favourite: !talent.favourite } }));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  compareExternalSort(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.key === option2.key && option1.value === option2.value : option1 === option2;
  }

  protected readonly AccountType = AccountType;
}
