import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { messagesFeature, MessagesReducer } from "./store/messages.reducer";
import { MessagesEffects } from "./store/messages-effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(messagesFeature, MessagesReducer),
    EffectsModule.forFeature([MessagesEffects]),
  ],
})
export class MessagesDataModule {}
