import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MAAnswerOption, MAQuestion, MAQuestionType } from "../../../../api/assessment/assessment.types";

@Component({
  selector: "mh-assessment-navigator-question",
  templateUrl: "./assessment-navigator-question.component.html",
  styleUrls: ["./assessment-navigator-question.component.less"],
})
export class AssessmentNavigatorQuestionComponent {
  @Input() question!: MAQuestion;
  @Output() answered = new EventEmitter<Array<MAAnswerOption>>();

  protected readonly AssessmentQuestionType = MAQuestionType;

  onAnswersModelChange(value: Array<MAAnswerOption>) {
    this.answered.emit(value);
  }

  onFreeTextChange(answer: MAAnswerOption) {
    this.answered.emit([answer]);
  }
}
