import { JobOffer, MatchedTalent } from "../../../../../../api/job-offer/job-offer.types";
import { jobOfferFeature, JobOfferMatchedTalentListState, JobOfferState } from "./job-offer.reducer";
import { PaginatedContent } from "../../../../../../api/common.types";

export function selectJobOfferList(state: { [jobOfferFeature]: JobOfferState }): Array<JobOffer> {
  return state[jobOfferFeature].list.data;
}

export function selectJobOfferEntity(state: { [jobOfferFeature]: JobOfferState }): JobOffer | undefined {
  return state[jobOfferFeature].entity.data;
}

export function isLoadingJobOfferList(state: { [jobOfferFeature]: JobOfferState }): boolean {
  return state[jobOfferFeature].list.status.loading;
}

export function isLoadingJobOfferMatchedTalentList(state: { [jobOfferFeature]: JobOfferState }): boolean {
  return state[jobOfferFeature].matches.status.loading;
}

export function selectJobOfferMatchedTalentListState(state: {
  [jobOfferFeature]: JobOfferState;
}): JobOfferMatchedTalentListState {
  return state[jobOfferFeature].matches;
}

export function selectJobOfferMatchedTalentList(state: {
  [jobOfferFeature]: JobOfferState;
}): PaginatedContent<MatchedTalent> {
  return state[jobOfferFeature].matches.data;
}
