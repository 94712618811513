import { Component, Input } from "@angular/core";
import { SimpleLocation } from "../../../../platform-pages/src/lib/api/google-geocoding/google-geocoding.types";

@Component({
  selector: "mh-location-label",
  templateUrl: "./location-label.component.html",
  styleUrls: ["./location-label.component.less"],
})
export class LocationLabelComponent {
  @Input() location: SimpleLocation | undefined;
  get locationLabel(): string {
    if(this.location) {
      const zip = this.location.postalCode;
      const city = this.location.city;
      const country = this.location.country;
      const locationParts: Array<string> = [];
      if(zip) locationParts.push(zip);
      if(city) locationParts.push(city);
      if(country) locationParts.push(country);
      return locationParts.join(', ')
    }
    return '-';
  }
}
