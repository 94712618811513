<nz-page-header class="large p-l-8 p-r-0">
  <nz-page-header-title>{{ "mp-list.title" | translate }}</nz-page-header-title>
</nz-page-header>

<nz-table
  class="mission-partner-list"
  nzSize="small"
  nzShowSizeChanger="true"
  nzFrontPagination="false"
  [nzShowTotal]="rangeTemplate"
  [nzData]="missionPartnerList?.items || []"
  [nzLoading]="loading"
  [nzTotal]="missionPartnerList?.paging?.items || 0"
  [nzPageSize]="missionPartnerList?.paging?.itemsOnPage || 0"
  [nzPageIndex]="missionPartnerList?.paging?.page || 0"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
    <tr>
      <th [nzWidth]="'30px'">{{ "mp-list.number.title" | translate }}</th>
      <th>{{ "mp-list.mission-partner.title" | translate }}</th>
      <th [nzWidth]="(isMobileView$ | async) ? '32px' : '60px'"></th>
      <th [nzWidth]="(isMobileView$ | async) ? '32px' : '155px'"></th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let missionPartner of missionPartnerList?.items; let index = index"
      [routerLink]="['/talent/view-micro-site/', missionPartner.accountId?.internalId]"
      class="contact-item"
      [class.contact-item--premium]="missionPartner.statuses?.includes(AccountStatus.PREMIUM)"
    >
      <td>
        {{ calculateNumber(index) }}
      </td>
      <td>
        <div class="contact">
          <nz-avatar class="mh-avatar mh-avatar--logo" [nzSrc]="missionPartner?.avatarUrl" [nzSize]="60"></nz-avatar>
          <div class="contact-name">
            <ng-container *ngIf="missionPartner.companyName">
              <b>{{ missionPartner.companyName }} </b>
              <nz-tag
                *ngIf="missionPartner.statusTag"
                nzColor="#6FC4C2"
                class="contact__tag"
                [title]="missionPartner.statusTag"
              >
                {{ missionPartner.statusTag | translate }}
              </nz-tag>
              <br />
            </ng-container>
            <span class="comma-separated">{{ missionPartner.address?.city }}</span>
            <span class="comma-separated">{{ missionPartner.address?.country }}</span>

            <span
              *ngIf="missionPartner.hashtags?.length && missionPartner.statuses?.includes(AccountStatus.PREMIUM)"
              class="contact__hashtags"
            >
              <nz-tag
                *ngFor="let hashtag of missionPartner.hashtags"
                class="contact__hashtag"
                [title]="hashtag"
              >
                {{ hashtag }}
              </nz-tag>
            </span>
          </div>
        </div>
      </td>
      <td>
        <div class="contact-status">
          <img
            *ngIf="missionPartner.statuses?.includes(AccountStatus.PREMIUM)"
            class="contact-status__icon"
            src="https://api.momhunting.anotheria-services.net/cms/getFile?pName=premium.webp"
            alt="Verified"
          />
        </div>
      </td>
      <td>
        <button
          *ngIf="missionPartner.conversationId"
          class="send-button"
          nz-button
          nzType="primary"
          type="button"
          [routerLink]="['/talent/inbox/conversation/', missionPartner.conversationId]"
          [queryParams]="{ origin: 'talent-mission-partners' }"
          [disabled]="!missionPartner.statuses?.includes(AccountStatus.REVIEWED)"
          (click)="stopPropagation($event)"
        >
          <span *ngIf="(isMobileView$ | async) === false">{{ "mp-list.send-btn" | translate }}</span>
          <span *ngIf="isMobileView$ | async" nz-icon nzType="send"></span>
        </button>
        <button
          *ngIf="!missionPartner.conversationId"
          class="send-button"
          nz-button
          nzType="primary"
          type="button"
          [disabled]="!missionPartner.statuses?.includes(AccountStatus.REVIEWED)"
          (click)="openCreateConversationModal($event, missionPartner)"
        >
          <span *ngIf="(isMobileView$ | async) === false">{{ "mp-list.start-conversation-btn" | translate }}</span>
          <span *ngIf="isMobileView$ | async" nz-icon nzType="send"></span>
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} {{ "of.label" | translate }} {{ total }} items
</ng-template>
