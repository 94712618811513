import { Injectable } from "@angular/core";
import { TalentListStatistic, TalentListStatisticDTO } from "./admin-talent.types";
import { CmsSerializer } from "../cms/cms.serializer";

@Injectable({
  providedIn: "root",
})
export class AdminTalentSerializer {
  constructor(private cmsSerializer: CmsSerializer) {}

  deserializeTalentListStatistic(dto: TalentListStatisticDTO): TalentListStatistic {
    return {
      ...dto,
      topDepartments: this.cmsSerializer.deserializeNameValues(dto.topDepartments),
      topExpertFields: this.cmsSerializer.deserializeNameValues(dto.topExpertFields),
      topIndustries: this.cmsSerializer.deserializeNameValues(dto.topIndustries),
      topSelectedWorkingHoursPerWeek: this.cmsSerializer.deserializeNameValue(dto.topSelectedWorkingHoursPerWeek),
    };
  }
}
