import { NgModule } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { HomeMissionPartnerComponent } from "./home-mission-partner.component";
import { HomeMissionPartnerRoutingModule } from "./home-mission-partner-routing.module";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzCardModule } from "ng-zorro-antd/card";
import { RouterLink } from "@angular/router";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { LocaleSelectModule } from "@momhunting/common-ui";
import { NzImageModule } from "ng-zorro-antd/image";
import {
  BadgeModule,
  DrawerModule,
  IconModule,
  ListModule,
  NavBarModule,
  PopoverItemModule,
  PopoverModule,
  WhiteSpaceModule,
  WingBlankModule,
} from "ng-zorro-antd-mobile";
import { MissionPartnerJobOffersModule } from "./subpages/mission-partner-job-offers/mission-partner-job-offers.module";
import { MissionPartnerViewTalentModule } from "./subpages/mission-partner-view-talent/mission-partner-view-talent.module";
import { MissionPartnerInvoicesModule } from "./subpages/mission-partner-invoices/mission-partner-invoices.module";
import { MissionPartnerSettingsModule } from "./subpages/mission-partner-settings/mission-partner-settings.module";
import { NotificationDropdownModule } from "../../common-componnets/notification-dropdown/notification-dropdown.module";
import { MissionPartnerInboxModule } from "./subpages/mission-partner-inbox/mission-partner-inbox.module";
import { MissionPartnerProfileModule } from "./subpages/mission-partner-profile/mission-partner-profile.module";
import { MissionPartnerDashboardModule } from "./subpages/mission-partner-dashboard/mission-partner-dashboard.module";
import { MissionPartnerNotificationsModule } from "./subpages/mission-partner-notifications/mission-partner-notifications.module";
import { ScrollToValidationErrorModule } from "../../common-componnets/scroll-to-validation-error/scroll-to-validation-error.module";
import { HeaderUnseenMessagesIconModule } from "../../common-componnets/header-unseen-messages-icon/header-unseen-messages-icon.module";
import { HeaderNavItemModule } from "../../common-componnets/header-nav-item/header-nav-item.module";
import { MissionPartnerTestimonialsModule } from "./subpages/mission-partner-testimonials/mission-partner-testimonials.module";
import { MissionPartnerMicroSiteModule } from "./subpages/mission-partner-micro-site/mission-partner-micro-site.module";
import { MissionPartnerAssessmentModule } from "./subpages/mission-partner-assessment/mission-partner-assessment.module";
import { MissionPartnerMyVisitsModule } from "./subpages/mission-partner-my-visits/mission-partner-my-visits.module";

@NgModule({
  imports: [
    CommonModule,
    HomeMissionPartnerRoutingModule,
    MissionPartnerJobOffersModule,
    MissionPartnerViewTalentModule,
    MissionPartnerInvoicesModule,
    MissionPartnerSettingsModule,
    MissionPartnerInboxModule,
    MissionPartnerProfileModule,
    MissionPartnerDashboardModule,
    MissionPartnerNotificationsModule,
    MissionPartnerTestimonialsModule,
    MissionPartnerMicroSiteModule,
    MissionPartnerAssessmentModule,
    MissionPartnerMyVisitsModule,
    TranslateModule,
    NzButtonModule,
    NzLayoutModule,
    NzDividerModule,
    NzFormModule,
    NzCardModule,
    NzMenuModule,
    NzIconModule,
    RouterLink,
    NzPageHeaderModule,
    NzDropDownModule,
    NzBadgeModule,
    LocaleSelectModule,
    NgOptimizedImage,
    NzImageModule,
    BadgeModule,
    IconModule,
    ListModule,
    NavBarModule,
    NavBarModule,
    PopoverItemModule,
    PopoverModule,
    WingBlankModule,
    DrawerModule,
    NotificationDropdownModule,
    WhiteSpaceModule,
    ScrollToValidationErrorModule,
    HeaderUnseenMessagesIconModule,
    HeaderNavItemModule,
  ],
  declarations: [HomeMissionPartnerComponent],
  exports: [HomeMissionPartnerComponent],
})
export class HomeMissionPartnerModule {}
