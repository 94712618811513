import { ChangeDetectorRef, Component, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { Talent } from "../../../../api/sign-up/signup.types";
import { ActivatedRoute, Router } from "@angular/router";
import { ProfileService } from "../../../../api/profile/profile.service";
import { map, Observable, Subject, takeUntil, tap } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { NzUploadFile } from "ng-zorro-antd/upload";
import {
  TalentProfileCompleteness,
  TalentProfileSection,
  TalentProfileSectionInfo,
} from "../../../../api/dashboard/dashboard.types";
import { Store } from "@ngrx/store";
import { talentProfileFeatureName, TalentProfileState } from "../../../../api/profile/store/talent-profile.reducer";
import * as TalentProfileActions from "../../../../api/profile/store/talent-profile.action";
import {
  selectTalentProfile,
  selectTalentProfileCompleteness,
} from "../../../../api/profile/store/talent-profile.selectors";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";
import { AudioFile } from "../../../../../../../core/src/lib/recording/recording.types";
import { ProfilePersonalityComponent } from "./steps/profile-personality/profile-personality.component";
import { ProfileAboutMeComponent } from "./steps/profile-about-me/profile-about-me.component";
import { ProfileDesiredJobComponent } from "./steps/profile-desired-job/profile-desired-job.component";
import { ProfileMomSkillsComponent } from "./steps/profile-mom-skills/profile-mom-skills.component";
import { ProfileChangeReadyComponent } from "./steps/profile-change-ready/profile-change-ready.component";
import { ProfileFamilyFriendlyComponent } from "./steps/profile-family-friendly/profile-family-friendly.component";
import { ProfileEducationComponent } from "./steps/profile-education/profile-education.component";
import { ProfileExperienceComponent } from "./steps/profile-experience/profile-experience.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { TalentProfileCvDialogComponent } from "./talent-profile-cv-dialog/talent-profile-cv-dialog.component";
import { GoogleAnalyticsService } from "@hakimio/ngx-google-analytics";
import { distinctUntilChanged } from "rxjs/operators";

export interface StepItem {
  index: number;
  titleKey: string;
  name: TalentProfileSection;
  component: any;
}

@Component({
  selector: "mh-talent-profile",
  templateUrl: "./talent-profile.component.html",
  styleUrls: ["./talent-profile.component.less"],
})
export class TalentProfileComponent implements OnDestroy {
  talent!: Talent;
  profileCompleteness$: Observable<TalentProfileCompleteness>;
  profileCompleteness: TalentProfileCompleteness | undefined;
  userDocuments: Array<NzUploadFile>;
  isMobileView$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();
  initiallyCompleteProfile: boolean | undefined = undefined;

  trackStep$ = new Subject<StepItem>();

  @ViewChild(ProfileAboutMeComponent) profileAboutMeComponent!: ProfileAboutMeComponent;
  @ViewChild(ProfilePersonalityComponent) profilePersonalityComponent!: ProfilePersonalityComponent;
  @ViewChild(ProfileMomSkillsComponent) profileMomSkillsComponent!: ProfileMomSkillsComponent;
  @ViewChild(ProfileDesiredJobComponent) profileDesiredJobComponent!: ProfileDesiredJobComponent;
  @ViewChild(ProfileChangeReadyComponent) profileChangeReadyComponent!: ProfileChangeReadyComponent;
  @ViewChild(ProfileFamilyFriendlyComponent) profileFamilyFriendlyComponent!: ProfileFamilyFriendlyComponent;
  @ViewChild(ProfileEducationComponent) profileEducationComponent!: ProfileEducationComponent;
  @ViewChild(ProfileExperienceComponent) profileExperienceComponent!: ProfileExperienceComponent;

  steps: Array<StepItem> = [
    {
      index: 0,
      titleKey: "profile.about.title",
      name: TalentProfileSection.ABOUT_ME,
      component: ProfileAboutMeComponent,
    },
    {
      index: 1,
      titleKey: "profile.personality.title",
      name: TalentProfileSection.PERSONALITY,
      component: ProfilePersonalityComponent,
    },
    {
      index: 2,
      titleKey: "profile.mom-skills.title",
      name: TalentProfileSection.MOM_SKILLS,
      component: ProfileMomSkillsComponent,
    },
    {
      index: 3,
      titleKey: "profile.desired-job.title",
      name: TalentProfileSection.DESIRED_JOB,
      component: ProfileDesiredJobComponent,
    },
    {
      index: 4,
      titleKey: "profile.willing-change.title",
      name: TalentProfileSection.WILLINGNESS_TO_CHANGE,
      component: ProfileChangeReadyComponent,
    },
    {
      index: 5,
      titleKey: "profile.family-friendly.title",
      name: TalentProfileSection.FAMILY_FRIENDLY_EMPLOYER,
      component: ProfileFamilyFriendlyComponent,
    },
    {
      index: 6,
      titleKey: "profile.education.title",
      name: TalentProfileSection.EDUCATION,
      component: ProfileEducationComponent,
    },
    {
      index: 7,
      titleKey: "profile.track-record.title",
      name: TalentProfileSection.TRACK_RECORD_AND_EXPERIENCE,
      component: ProfileExperienceComponent,
    },
  ];

  currentStepIndex = this.steps[0].index;

  get currentStepItem(): StepItem {
    return this.getStepItemByIndex(this.currentStepIndex);
  }

  get hasPrevStep(): boolean {
    return this.currentStepIndex > 0;
  }

  get hasNextStep(): boolean {
    return this.currentStepIndex < this.steps.length - 1;
  }

  constructor(
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store<{ [talentProfileFeatureName]: TalentProfileState }>,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private router: Router,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private gaService: GoogleAnalyticsService,
  ) {
    this.store
      .select(selectTalentProfile)
      .pipe(
        takeUntil(this.destroy$),
        tap((talent) => (this.talent = talent as Talent)),
        map((talent) => talent as Talent),
      )
      .subscribe();

    this.profileCompleteness$ = this.store.select(selectTalentProfileCompleteness).pipe(
      tap((completeness) => {
        this.profileCompleteness = completeness;
        const currentStepCompleteness = this.getStepCompletionByIndex(this.currentStepIndex);
        this.handleProfileCompleted(completeness);
        const skipNavigation = currentStepCompleteness?.section === TalentProfileSection.EDUCATION;
        if (currentStepCompleteness && +currentStepCompleteness?.completenessPercentage == 100 && !skipNavigation) {
          this.navigateNextStep();
        } else if (currentStepCompleteness) {
          this.trackCurrentStep(this.getStepItemByIndex(this.currentStepIndex));
        }
      }),
    );
    this.userDocuments = this.activatedRoute.snapshot.data["userDocuments"];
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
    this.store.dispatch(TalentProfileActions.loadTalentProfileCompleteness());
    this.trackStep$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((stepItem) => {
      this.gaService.event("tp_view_section_" + stepItem.index, { category: "talent_profile", label: stepItem.name });
    });
  }

  getStatus(step: StepItem): "wait" | "process" | "finish" | "error" {
    const sectionInfo = this.getStepCompletion(step.name);
    if (step.index === this.currentStepIndex) {
      return "process";
    }
    if (sectionInfo && +sectionInfo.completenessPercentage === 100) {
      return "finish";
    }
    return "wait";
  }

  getStepCompletionByIndex(stepIndex: number): TalentProfileSectionInfo | undefined {
    return this.getStepCompletion(this.getStepItemByIndex(stepIndex).name);
  }

  getStepCompletion(name: TalentProfileSection) {
    return this.profileCompleteness?.sections.find((section) => section.section == name);
  }

  getStepItemByIndex(index: number): StepItem {
    return this.steps[index];
  }

  onStepChange(index: number) {
    if (this.validateCurrentStep()) {
      this.currentStepIndex = index;
      this.trackCurrentStep(this.getStepItemByIndex(this.currentStepIndex));
    }
  }

  onFormChange(change: Talent) {
    this.store.dispatch(TalentProfileActions.updateStoreTalentProfile({ data: change }));
  }
  onSubmitChange(skipNavigation?: boolean) {
    this.profileService.talentUpdate(this.talent).subscribe((result) => {
      const currentStepItem = this.getStepItemByIndex(this.currentStepIndex);
      this.gaService.event("tp_save_section_" + currentStepItem.index, {
        category: "talent_profile",
        label: currentStepItem.name,
      });
      this.store.dispatch(TalentProfileActions.loadTalentProfileCompleteness());
      if (!skipNavigation) this.navigateNextStep();
      this.notificationService.success("", this.translateService.instant("profile.updated.notification"));
    });
  }

  onLastStepSaved() {
    this.onFormChange({ ...this.talent, filledForTheFirstTime: true });
    this.router.navigate(["/talent", "dashboard"]);
  }

  navigateNextStep() {
    let stepCandidate = this.currentStepIndex + 1;
    let sectionInfo;
    while (stepCandidate < this.steps.length) {
      sectionInfo = this.getStepCompletionByIndex(stepCandidate);
      if (sectionInfo && +sectionInfo.completenessPercentage == 100) {
        stepCandidate++;
      } else {
        break;
      }
    }
    if (stepCandidate < this.steps.length) {
      this.currentStepIndex = stepCandidate;
      this.trackCurrentStep(this.getStepItemByIndex(this.currentStepIndex));
    }
  }

  onPrevStep() {
    this.currentStepIndex--;
    this.trackCurrentStep(this.getStepItemByIndex(this.currentStepIndex));
  }
  onNextStep() {
    this.currentStepIndex++;
    this.trackCurrentStep(this.getStepItemByIndex(this.currentStepIndex));
  }

  updateProfileAudio(event: AudioFile | null) {
    this.onFormChange({ ...this.talent, profileAudio: event });
  }

  updateFileList(event: { fileList: NzUploadFile[]; uploadCV: boolean }) {
    if (event.uploadCV) {
      if (event.fileList[0]?.response?.success || !event.fileList[0])
        this.onFormChange({ ...this.talent, cv: event.fileList[0] });

      if (event.fileList[0]?.response?.success) {
        this.modal.create({
          nzContent: TalentProfileCvDialogComponent,
          nzViewContainerRef: this.viewContainerRef,
        });
      }
    } else {
      this.userDocuments = event.fileList;
    }
  }

  handleProfileCompleted(completeness: TalentProfileCompleteness) {
    if (this.initiallyCompleteProfile === undefined && completeness.sections.length > 0)
      this.initiallyCompleteProfile = +completeness.completenessPercentage == 100;
    if (+completeness.completenessPercentage == 100 && !this.initiallyCompleteProfile) {
      this.router
        .navigate(["/talent", "dashboard"])
        .then(() =>
          this.notificationService.success("", this.translateService.instant("profile.completed.notification")),
        );
    }
  }

  validateCurrentStep(): boolean {
    switch (this.currentStepItem.component) {
      case ProfileAboutMeComponent:
        return this.profileAboutMeComponent.isStepValid();
      case ProfilePersonalityComponent:
        return this.profilePersonalityComponent.isStepValid();
      case ProfileMomSkillsComponent:
        return this.profileMomSkillsComponent.isStepValid();
      case ProfileDesiredJobComponent:
        return this.profileDesiredJobComponent.isStepValid();
      case ProfileChangeReadyComponent:
        return this.profileChangeReadyComponent.isStepValid();
      case ProfileFamilyFriendlyComponent:
        return this.profileFamilyFriendlyComponent.isStepValid();
      case ProfileEducationComponent:
        return this.profileEducationComponent.isStepValid();
      case ProfileExperienceComponent:
        return this.profileExperienceComponent.isStepValid();
      default:
        return false;
    }
  }

  trackCurrentStep(stepItem: StepItem) {
    this.trackStep$.next(this.getStepItemByIndex(this.currentStepIndex));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly TalentProfileSection = TalentProfileSection;
}
