import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {catchError, Subject, takeUntil, throwError} from "rxjs";
import {
  NumberRequiredValidator,
  SpecialCharacterRequiredValidator,
  StrictEmailValidator,
  UpperCaseRequiredValidator,
} from "@momhunting/core";
import { SignupService } from "../../../../api/sign-up/signup.service";
import { Talent } from "../../../../api/sign-up/signup.types";
import {LoginService} from "../../../login-page/login-page-data/login.service";
import {TranslateService} from "@ngx-translate/core";
import {differenceInCalendarDays, endOfDay} from "date-fns";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: "mh-signup-talent-general",
  templateUrl: "./signup-talent-general.component.html",
  styleUrls: ["./signup-talent-general.component.less"],
})
export class SignupTalentGeneralComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Output() nextStep = new EventEmitter<void>();
  @Output() formChange = new EventEmitter<Talent>();
  @Output() registered = new EventEmitter<{ token: string; account: Talent }>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();
  loading = false;
  private today = endOfDay(new Date());
  public defaultPickerValue = new Date('1990');

  get dataSafetyLink(): string {
    const linkLabel = this.translateService.instant('reg.talent.datasafety.link');
    return '<a href="/data-protection" target="_blank">'+linkLabel+'</a>';
  }

  get termsLink(): string {
    const linkLabel = this.translateService.instant('reg.talent.terms.link');
    return '<a href="/terms-and-conditions" target="_blank">'+linkLabel+'</a>';
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

  constructor(private signupService: SignupService,
              private loginService: LoginService,
              private translateService: TranslateService,
              private deviceDetectorService: DeviceDetectorService) {}

  get password(): FormControl {
    return this.formGroup.get("password") as FormControl;
  }

  onAlreadyHaveAccount(){
    this.loginService.logout();
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      email: new FormControl(this.talent?.email, {validators: [this.confirmationValidator], updateOn:'blur'}),
      emailConfirm: new FormControl(this.talent?.email, {validators: [this.confirmationValidator], updateOn:'blur'}),
      password: new FormControl("", [
        Validators.required,
        NumberRequiredValidator,
        SpecialCharacterRequiredValidator,
        UpperCaseRequiredValidator,
      ]),
      salutation: new FormControl(this.talent?.salutation, []),
      firstName: new FormControl(this.talent?.firstName, [Validators.required]),
      lastName: new FormControl(this.talent?.lastName, [Validators.required]),
      birthDate: new FormControl(this.talent?.birthDate, [Validators.required]),
      updatesSubscription: new FormControl(false, []),
      termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.formChange.emit(value));
  }

  onEmailPaste(controlName: string) {
    const otherControl = controlName === 'email' ? this.formGroup?.get("emailConfirm") : this.formGroup?.get("email");
    setTimeout(()=>otherControl?.updateValueAndValidity({onlySelf: true}), 0);
  }

  submitForm() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.loading = true;
    this.talent.deviceType = this.deviceDetectorService.deviceType;
    this.talent.userAgent = this.deviceDetectorService.userAgent;
    this.signupService.registerTalent(this.talent)
      .pipe(catchError((error)=> {
        this.loading = false;
        return throwError(error)
      }))
      .subscribe((token) => {
      if(token) {
        this.registered.emit({ token, account: this.formGroup.value });
        this.nextStep.emit();
      }
      this.loading = false;
    });
  }

  confirmationValidator: ValidatorFn = (control: AbstractControl): { [s: string]: boolean } => {
    const otherControl = this.getControlName(control) === 'email' ? this.formGroup?.get("emailConfirm") : this.formGroup?.get("email");
    if (!control.value) {
      return {required: true};
    } else if(StrictEmailValidator(control)) {
        return { 'email': true };
    } else if (control?.value !== otherControl?.value) {
      otherControl?.setErrors(null);
      return { confirm: true, error: true };
    }
    control?.setErrors(null);
    otherControl?.setErrors(null);
    return {};
  };

  private getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent?.controls;
    return formGroup && Object.keys(formGroup).find(name => c === (formGroup as any)[name]) || null;
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
