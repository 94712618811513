import { Component, Input, OnInit } from "@angular/core";
import { AuthorizationService } from "../../../../platform-pages/src/lib/api/authorization/authorization.service";
import { AccountService } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.service";
import {
  Comment,
  CommentFromJo,
} from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";
import { take } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-account-comments-from-jo",
  templateUrl: "./account-comments-from-jo.component.html",
  styleUrls: ["./account-comments-from-jo.component.less"],
})
export class AccountCommentsFromJoComponent implements OnInit {
  @Input() talentId!: string;
  formGroup: FormGroup;
  authorId: string | undefined;
  commentsFromJobOpportunities: CommentFromJo[] = [];
  editingCommentId: string | null = null;

  constructor(
    private authorizationService: AuthorizationService,
    private accountService: AccountService,
    private modal: NzModalService,
    private translateService: TranslateService,
  ) {
    this.authorizationService.getAccountId().subscribe((accountId) => {
      this.authorId = accountId;
    });
    this.formGroup = new FormGroup({
      text: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.loadCommentsFromJobOpportunities();
  }

  loadCommentsFromJobOpportunities() {
    this.accountService
      .loadAccountCommentsFromJobOpportunities(this.talentId)
      .pipe(take(1))
      .subscribe((comments) => {
        this.commentsFromJobOpportunities = comments;
      });
  }

  handleSubmit(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    const text = this.formGroup.get("text")?.value;
    if (this.editingCommentId) {
      this.updateComment(this.editingCommentId, text);
    }
  }

  updateComment(commentId: string, text: string) {
    this.accountService.updateJobOfferTalentComment(commentId, text).subscribe((updatedComment) => {
      if (updatedComment) {
        const index = this.commentsFromJobOpportunities.findIndex((c) => c.commentId === commentId);
        if (index !== -1) {
          this.commentsFromJobOpportunities[index].text = text;
        }
        this.formGroup.reset();
        this.editingCommentId = null;
      }
    });
  }

  startEditing(comment: Comment) {
    this.editingCommentId = comment.commentId;
    this.formGroup.patchValue({ text: comment.text });
  }

  cancelEditing() {
    this.editingCommentId = null;
    this.formGroup.reset();
  }

  confirmDeleteComment(comment: CommentFromJo) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("comment.confirm.delete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.onDeleteComment(comment.jobOpportunityId, comment.commentId),
    });
  }

  onDeleteComment(jobOfferId: string, commentId: string) {
    this.accountService
      .deleteJobOfferTalentComment(jobOfferId, commentId)
      .pipe(take(1))
      .subscribe((success) => {
        if (success) {
          this.commentsFromJobOpportunities = this.commentsFromJobOpportunities.filter(
            (comment) => comment.commentId !== commentId,
          );
        }
      });
  }
}
