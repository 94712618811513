import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Talent } from "../../../../../../api/sign-up/signup.types";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "mh-profile-personality",
  templateUrl: "./profile-personality.component.html",
  styleUrls: ["./profile-personality.component.less"],
})
export class ProfilePersonalityComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      personality: this.fb.group({
        personalityCoreValues: [this.talent.personality?.personalityCoreValues, []],
        personalityDinnerDate: [this.talent.personality?.personalityDinnerDate, []],
        personalitySuperpower: [this.talent.personality?.personalitySuperpower, []],
        personalityBalance: [this.talent.personality?.personalityBalance, []],
        personalityProud: [this.talent.personality?.personalityProud, []],
        personalityElement: [this.talent.personality?.personalityElement, []],
        personalityRoleModel: [this.talent.personality?.personalityRoleModel, []],
        personalityInspiration: [this.talent.personality?.personalityInspiration, []],
        personalityPaths: [this.talent.personality?.personalityPaths, []],
        personalityIrritations: [this.talent.personality?.personalityIrritations, []],
      }),
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        personality: {
          ...this.talent.personality,
          ...value.personality,
        },
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
