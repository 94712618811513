<ng-container *nzModalTitle>
  {{'admin.job-offer.add.label' | translate}}
</ng-container>

<mh-job-offer-form [jobOffer]="jobOffer" (formChange)="onFormChange($event)"
                   [missionPartnerEditable]="true"
                   [missionPartners]="missionPartners"></mh-job-offer-form>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">
    {{ "cancel.button" | translate }}
  </button>
  <button nz-button nzType="primary" (click)="onSave()">
    {{ "save.button" | translate }}
  </button>
</ng-container>
