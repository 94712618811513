import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminJobOfferCreateComponent } from "./admin-job-offer-create.component";
import { JobOfferFormModule } from "../job-offer-form/job-offer-form.module";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { NzModalModule } from "ng-zorro-antd/modal";

@NgModule({
  declarations: [AdminJobOfferCreateComponent],
  imports: [CommonModule, JobOfferFormModule, NzButtonModule, NzWaveModule, TranslateModule, NzModalModule],
})
export class AdminJobOfferCreateModule {}
