<h3>{{ "assessment.section-1.title" | translate }}</h3>

<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="setup-form" (ngSubmit)="onSubmit()">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'mp.profile.company-name.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control [nzErrorTip]="companyNameErrorTpl">
          <input type="text" nz-input formControlName="companyName" [placeholder]="'input.placeholder' | translate" />
          <ng-template #companyNameErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">{{ "field.required.label" | translate }}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'references.position.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control [nzErrorTip]="positionErrorTpl">
          <input type="text" nz-input formControlName="position" [placeholder]="'input.placeholder' | translate" />
          <ng-template #positionErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">{{ "field.required.label" | translate }}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'assessment.email.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control [nzErrorTip]="emailErrorTpl">
          <input
            type="text"
            nz-input
            formControlName="email"
            [placeholder]="'reg.talent.email.placeholder' | translate"
          />
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('email')">{{
              "admin.user-admin.create.error.email" | translate
            }}</ng-container>
            <ng-container *ngIf="control.hasError('required')">{{ "field.required.label" | translate }}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
      <div class="footer-buttons footer-buttons-right">
        <button nz-button nzType="primary">
          <span>{{ "start.button" | translate }}</span>
        </button>
      </div>
      </nz-form-item>
    </div>
  </div>
</form>
