import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { LoginService } from "../../pages/login-page/login-page-data/login.service";
import * as LoginAction from "../../pages/login-page/login-page-data/store/login.action";
import { loginFeatureName, LoginState } from "../../pages/login-page/login-page-data/store/login.reducer";
import {GoogleAnalyticsService} from "@hakimio/ngx-google-analytics";

@Injectable({ providedIn: "root" })
export class RegistrationDoneGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private store: Store<{ [loginFeatureName]: LoginState }>,
    private gaService: GoogleAnalyticsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const emailToken = route.queryParams["token"];
    if (emailToken) {
      this.loginService.emailConfirmationCheck(emailToken).subscribe(
        (token) => {
          this.loginService.setToken(token);
          this.gaService.event('sign_up_email_do_success')
          this.store.dispatch(LoginAction.completeRegistration());
        },
        (error) => {
          return this.router.navigate(["/expired-token"]);
        },
      );
    }
    return this.router.createUrlTree(["/"]);
  }
}
