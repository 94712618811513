import { Component, Inject } from "@angular/core";
import { AccountService } from "../../home-admin-data/account.service";
import { take } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { MissionPartner } from "../../../../api/sign-up/signup.types";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { Reference, ReferenceRequest } from "libs/platform-pages/src/lib/api/references/references.types";

@Component({
  selector: "mh-admin-mission-partner-view",
  templateUrl: "./admin-mission-partner-view.component.html",
  styleUrls: ["./admin-mission-partner-view.component.less"],
})
export class AdminMissionPartnerViewComponent {
  missionPartnerId = "";
  missionPartner: MissionPartner | undefined;
  testimonials: Array<Reference> = [];
  testimonialsRequest: Array<ReferenceRequest> = [];

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    const accountId = this.route.snapshot.paramMap.get("id");
    if (accountId) {
      this.missionPartnerId = accountId;
      this.accountService
        .getMissionPartnerProfile(this.missionPartnerId)
        .pipe(take(1))
        .subscribe((missionPartner) => {
          if (missionPartner) {
            this.missionPartner = missionPartner;
          }
        });

      this.accountService
        .loadReferencesById(this.missionPartnerId)
        .pipe(take(1))
        .subscribe((references) => {
          this.testimonials = references;
        });
      this.accountService
        .loadReferencesRequestById(this.missionPartnerId)
        .pipe(take(1))
        .subscribe((referencesRequest) => {
          this.testimonialsRequest = referencesRequest;
        });
    }
  }

  onSignInAs() {
    this.accountService.signInAs(this.missionPartnerId);
  }

  protected readonly AccountType = AccountType;
}
