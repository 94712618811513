import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentDashboardSearchingStatusComponent } from "./talent-dashboard-searching-status.component";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzButtonModule} from "ng-zorro-antd/button";
import {TranslateModule} from "@ngx-translate/core";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzSelectModule} from "ng-zorro-antd/select";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [TalentDashboardSearchingStatusComponent],
  imports: [CommonModule, NzCardModule, NzButtonModule, TranslateModule, NzFormModule, NzSelectModule, FormsModule],
  exports: [TalentDashboardSearchingStatusComponent],
})
export class TalentDashboardSearchingStatusModule {}
