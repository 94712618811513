import {Component, EventEmitter, Input, Output} from "@angular/core";
import {formatRelative} from "date-fns";
import {de, enGB} from "date-fns/locale";
import {UiMessage, UiMessageAction} from "../messages-chat.types";
import {
  AccessGrant,
  AccessGrantStatus,
  ConversationNegotiation,
  FileSubType,
  MessageSubType,
  MessageType,
  ParticipantInfo
} from "../../../../../api/messages/messages.types";
import {AccountType, LoginData} from "../../../../../pages/login-page/login-page-data/login.types";
import {NegotiationStatus} from "../../../../../pages/home-admin/home-admin-data/account.types";
import {LocaleService} from "@momhunting/core";

@Component({
  selector: "mh-chat-message",
  templateUrl: "./mh-chat-message.component.html",
  styleUrls: ["./mh-chat-message.component.less"],
})
export class MhChatMessageComponent {
  @Input() loginData!: LoginData;
  @Input() item!: UiMessage;
  @Input() accessGrant?: AccessGrant;
  @Input() negotiation?: ConversationNegotiation;
  @Input() recipientInfo?: ParticipantInfo;
  @Input() conversationDisabled = false;
  @Output() messageAction = new EventEmitter<UiMessageAction>();

  get isDocumentAttachment(): boolean {
    return this.item.attachment && !this.item.attachment.subType;
  }

  get isImageAttachment(): boolean {
    return this.item.attachment?.subType == FileSubType.IMAGE;
  }

  get isVideoAttachment(): boolean {
    return this.item.attachment?.subType == FileSubType.VIDEO;
  }

  get isAudioAttachment(): boolean {
    return this.item.attachment?.subType == FileSubType.AUDIO;
  }

  get timeLabel(): string {
    return formatRelative(this.item.created, new Date(), { locale: this.localeService.currentLocale === 'en' ? enGB : de });
  }

  get isActionMessage(): boolean {
    return this.item.subType === MessageSubType.PROFILE_ACCESS_QUESTION
      || this.item.subType === MessageSubType.PROFILE_ACCESS_REJECTED
      || this.item.subType === MessageSubType.PROFILE_ACCESS_GRANTED
      || this.item.subType === MessageSubType.AGREEMENT_REACHED_ONE_SIDE
  }

  get showAgreementReachedAction(): boolean {
    return this.item.subType === MessageSubType.AGREEMENT_REACHED_ONE_SIDE
      && !this.item.ownMessage
      && (this.negotiation?.status === NegotiationStatus.MP_AGREEMENT_REACHED || this.negotiation?.status === NegotiationStatus.TALENT_AGREEMENT_REACHED)
      && this.item.lastActionMessage;
  }

  get showProfileAccessActionButtons(): boolean {
    return this.item.subType === MessageSubType.PROFILE_ACCESS_QUESTION
      && this.accessGrant?.status === AccessGrantStatus.PENDING
      && this.loginData?.accountType === AccountType.TALENT
      && this.item.lastActionMessage;
  }

  get isMessageActionsDisabled(): boolean {
    return this.conversationDisabled;
  }

  get isAgreementReached(): boolean {
    return this.item.subType === MessageSubType.AGREEMENT_TERMS_CONFIRMED;
  }

  constructor(protected readonly localeService: LocaleService) {}

  doMessageAction(action: UiMessageAction) {
    this.messageAction.emit(action);
  }

  protected readonly MessageType = MessageType;
  protected readonly MessageSubType = MessageSubType;
}
