<div nz-row>
  <div *ngIf="!assessment" nz-col nzSpan="24">
    <nz-alert nzType="info" [nzMessage]="'mp.ma-initial.status' | translate" nzShowIcon></nz-alert>
  </div>
  <div *ngIf="assessment && assessment.status === MaturityAssessmentStatus.ACTIVE" nz-col nzSpan="24">
    <nz-alert
      class="m-b-10"
      nzType="info"
      [nzMessage]="'mp.ma-active.status' | translate"
      nzShowIcon
      [nzDescription]="alertTextInput"
    ></nz-alert>
    <ng-template #alertTextInput>
      <div nz-row class="p-t-20">
        <div nz-col nzSpan="24" nzMd="8"></div>
        <div nz-col nzSpan="24" nzMd="16">
          <nz-input-group class="m-b-10" nzSearch [nzAddOnAfter]="suffixCopyButton">
            <input type="text" nz-input [value]="assessment.link" />
          </nz-input-group>
        </div>
      </div>
    </ng-template>
    <ng-template #suffixCopyButton>
      <button nz-button nzType="primary" nzSearch [cdkCopyToClipboard]="assessment.link" (click)="onCopyToClipboard()">
        <ng-container *ngIf="!copied"> <span nz-icon nzType="copy"></span> copy </ng-container>
        <ng-container *ngIf="copied"> <span nz-icon nzType="file-done"></span> copied </ng-container>
      </button>
    </ng-template>
  </div>
  <div *ngIf="assessment && assessment.status === MaturityAssessmentStatus.COMPLETED" nz-col nzSpan="24">
    <p>
      <nz-alert nzType="info" [nzMessage]="'mp.ma-completed.status' | translate" nzShowIcon></nz-alert>
    </p>
  </div>
  <div *ngIf="assessment && assessment.status === MaturityAssessmentStatus.COMPLETED_WITH_RESULT" nz-col nzSpan="24">
    <p>
      <nz-alert nzType="info" [nzMessage]="'mp.ma-completed-with-report.status' | translate" nzShowIcon></nz-alert>
    </p>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="24" *ngIf="assessment?.resultFile">
    <p style="width: 100%; text-align: center">
      <a nz-button nzType="primary" [href]="assessment?.resultFile" target="_blank">{{
        "admin.assessment-download-report.tooltip" | translate
      }}</a>
    </p>
  </div>
</div>
<div nz-row>
  <div *ngIf="assessment" class="p-t-10" nz-col nzSpan="24">
    <ng-template #rangeTemplate let-range="range" let-total>
      {{ range[0] }}-{{ range[1] }} of {{ total }} items
    </ng-template>
    <nz-table
      #basicTable
      [nzFrontPagination]="true"
      [nzPageSize]="10"
      [nzShowTotal]="rangeTemplate"
      [nzHideOnSinglePage]="true"
      [nzData]="assessment.maturityAssessmentParticipants"
    >
      <thead>
        <tr>
          <th>{{ "mp.ma-participant.label" | translate }}</th>
          <th>{{ "mp.ma-started.label" | translate }}</th>
          <th>{{ "mp.ma-completed.label" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{ data.personNum }}</td>
          <td>{{ data.startedTimestamp | date : "dd.MM.YYYY HH:mm" }}</td>
          <td>
            <ng-container *ngIf="data.endedTimestamp">{{
              data.endedTimestamp | date : "dd.MM.YYYY HH:mm"
            }}</ng-container>
            <ng-container *ngIf="!data.endedTimestamp"> - </ng-container>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
