<nz-card class="dashboard__card" [nzTitle]="titleRef" [nzActions]="[actionComplete]">
  <ng-template #titleRef>
    <div class="title">
      <div class="title__text">{{'talent.dashboard.profile-completeness.title' | translate}}</div>
      <div
        *ngIf="completeness.isProfileFilledOut"
        class="title__filled-out"
        [nzTooltipTitle]="'talent.dashboard.profile-filled-out.tooltip' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="check-circle" nzTheme="fill"></span>
      </div>
    </div>
  </ng-template>
  <div class="progress__container">
    <nz-progress class="completeness" [nzPercent]="profilePercentage" nzSize="default"></nz-progress>
  </div>
  {{'talent.dashboard.profile-completeness.filled' | translate : {filled: completeness.totalFilledOutNumberOfFields + ' / ' + completeness.totalNumberOfFields} }}
</nz-card>
<ng-template #actionComplete>
  <button nz-button nzType="primary" [routerLink]="['/talent', 'profile']">{{'talent.dashboard.profile-completeness.update.btn' | translate}}</button>
</ng-template>
