<div nz-row [nzGutter]="[0, 24]" class="references">
  <div nz-col [nzSpan]="24" *ngIf="testimonials.length">
    <h3 *ngIf="viewerAccountType === AccountType.ADMIN">{{'testimonials.testimonials.title' | translate}}</h3>
    <nz-list nzItemLayout="vertical">
      <nz-list-item *ngFor="let testimonial of testimonials" class="reference">
        <nz-list-item-meta>
          <nz-list-item-meta-title>
            {{ testimonial.referenceName }}
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <div class="reference__info">
          {{testimonial.referenceCompany}}
          <nz-divider nzPlain nzType="vertical"></nz-divider>
          {{testimonial.referencePosition}}
          <nz-divider nzPlain nzType="vertical"></nz-divider>
          <a *ngIf="trustedView" [href]="'mailto:' + testimonial.referenceEmail">
            {{testimonial.referenceEmail}}
          </a>
          <span *ngIf="!trustedView">
            {{testimonial.referenceEmail}}
          </span>
          <nz-divider nzPlain nzType="vertical"></nz-divider>
          <span class="reference__timestamp"> {{ testimonial.creationTimestamp }}</span>
        </div>
        <div class="reference__text">
          {{ testimonial.referenceText }}
        </div>
      </nz-list-item>
    </nz-list>
  </div>

  <div nz-col nzSpan="24" *ngIf="testimonialsRequest.length">
    <h3>{{'testimonials.my-testimonials-requests.title' | translate}}</h3>
    <nz-list nzItemLayout="vertical">
      <nz-list-item *ngFor="let testimonial of testimonialsRequest" class="reference">
        <nz-list-item-meta>
          <nz-list-item-meta-title>
            {{ testimonial.referenceName }}
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <div class="reference__info">
          {{testimonial.referenceCompany}}
          <nz-divider nzPlain nzType="vertical"></nz-divider>
          {{testimonial.referencePosition}}
          <nz-divider nzPlain nzType="vertical"></nz-divider>
          <a [href]="'mailto:' + testimonial.referenceEmail">
            {{testimonial.referenceEmail}}
          </a>
          <nz-divider nzPlain nzType="vertical"></nz-divider>
          <span class="reference__timestamp"> {{ testimonial.creationTimestamp }}</span>
        </div>
      </nz-list-item>
    </nz-list>
  </div>
</div>
