import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { talentProfileReducer, talentProfileFeatureName } from "./talent-profile.reducer";
import { TalentProfileEffects } from "./talent-profile-effects.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(talentProfileFeatureName, talentProfileReducer),
    EffectsModule.forFeature([TalentProfileEffects]),
  ],
})
export class TalentProfileStoreModule {}
