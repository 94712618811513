import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ContentPageComponent} from "./content-page.component";
import {TranslateModule} from "@ngx-translate/core";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {RouterLink} from "@angular/router";
import {LocaleSelectModule} from "@momhunting/common-ui";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzImageModule} from "ng-zorro-antd/image";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd/icon";

@NgModule({
  declarations: [ContentPageComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzLayoutModule,
    RouterLink,
    LocaleSelectModule,
    NzDropDownModule,
    NzBadgeModule,
    NzImageModule,
    NzButtonModule,
    NzIconModule,
  ],
  exports: [ContentPageComponent],
})
export class ContentPageModule {
}
