import {Component, Input, OnInit, Optional, Self} from "@angular/core";
import {AbstractControl, ControlValueAccessor, NgControl, ValidatorFn} from "@angular/forms";
import { CountryCode } from "../nz-intl-tel-input/country-code";
import { Country } from "../nz-intl-tel-input/nz-intl-tel-input-types";

@Component({
  selector: "mh-country-input",
  templateUrl: "./country-input.component.html",
  styleUrls: ["./country-input.component.less"],
})
export class CountryInputComponent implements OnInit, ControlValueAccessor {
  selectedValue!: string;
  listOfOption: Array<Country> = [];
  nzFilterOption = (): boolean => true;
  disabled: boolean = false;

  allCountries: Array<Country> = [];
  preferredCountries = ['de'];

  get isError(): boolean | null {
    return this.control.invalid && this.control.dirty;
  }

  constructor(@Self() @Optional() private control: NgControl,
              private countryCodeData: CountryCode) {
    this.control.valueAccessor = this;
    this.fetchCountryData();
    this.listOfOption = this.allCountries;
  }

  search(value: string): void {
    this.listOfOption = this.allCountries.filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
  }

  onLocationSelected(value: string) {
    this.onChange(value);
  }

  ngOnInit() {
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach((iso2) => {
        this.allCountries.sort(function(x,y){ return x.iso2 == iso2 ? -1 : y.iso2 == iso2 ? 1 : 0; });
      });
    }
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: string) {
    this.selectedValue = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  protected fetchCountryData(): void {
    this.countryCodeData.allCountries.forEach((c) => {
      const country: Country = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: (c[4] as string[]) || undefined,
        flagClass: c[1].toString().toUpperCase(),
        placeHolder: "",
      };

      this.allCountries.push(country);
    });
  }
}
