import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {catchError, EMPTY, Subject} from "rxjs";
import {LoginService} from "../login-page/login-page-data/login.service";
import {Router} from "@angular/router";
import {ApiResponseDTO} from "../../api/common.types";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";

const DEFAULT_AUTHENTICATED_ROUTE = "/accounts/manage";

@Component({
  selector: "mh-password-forgotten",
  templateUrl: "./password-forgotten.component.html",
  styleUrls: ["./password-forgotten.component.less"],
})

export class PasswordForgottenComponent implements OnInit, OnDestroy {

  public formGroup!: FormGroup;
  public errorResponse: string | undefined | any = '';
  private readonly destroy$ = new Subject<void>();
  public showResetForm = true;

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant('field.required.label'),
    },
  };

  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: this.translateService.instant('password-forgotten.title') }));
  }

  get email(): FormControl {
    return this.formGroup.get('email') as FormControl;
  }

  ngOnInit() {
    if(this.loginService.isAuthenticated()) {
      this.router.navigate([DEFAULT_AUTHENTICATED_ROUTE]);
    }

    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required]),
    });
  }

  onForgottenPasswordFormSubmit() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.loginService
      .passwordForgotten(this.formGroup.value)
      .pipe(
        catchError((err) => {
          this.errorResponse = err;
          return EMPTY;
        })
      )
      .subscribe((response: ApiResponseDTO) => {
        if(response.success){
          this.showResetForm = false;
          this.errorResponse = null;
        } else {
          this.errorResponse = response.errorKey ?? response.message;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
