import { SimpleLocation } from "../google-geocoding/google-geocoding.types";
import { CmsNameValueDTO } from "../cms/cms-api.types";
import { CmsNameValue } from "../cms/cms.types";
import { SalaryExpectation } from "../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation.type";

export interface JobOffer {
  id: string;
  missionPartnerId: string;
  forMissionPartnerId?: string;
  missionPartnerName: string;
  archived: boolean;
  name: string;
  description: string;
  salaryExpectations?: SalaryExpectation;
  locations: Array<SimpleLocation>;
  mode?: CmsNameValue;
  jobSharing: boolean;
  domainExpertise: Array<CmsNameValue>;
  domainPreference: Array<CmsNameValue>;
  workExperience?: number;
  leadershipExperience?: number;
  leadershipTeamSize?: number;
  sectorFocus: Array<CmsNameValue>;
  workLanguage?: Array<CmsNameValue>;
  spokenLanguages?: Array<CmsNameValue>;
  matchedAmount: number;
  industries?: Array<CmsNameValue>;
  careerLevel?: Array<CmsNameValue>;
  momSkills?: Array<CmsNameValue>;
  hashTags?: Array<string>;
  status: JobOpportunitySearchResultStatus;
  creationTimestamp: number;
  countryCode?: string;
  stage?: number;
}

export interface JobOfferLocationDTO {
  postalCode: string;
  country: string;
  city: string;
  radius: CmsNameValueDTO | undefined;
}

export interface JobOfferDTO {
  id: string;
  missionPartnerId: {internalId: string};
  forMissionPartnerId?: {internalId: string};
  missionPartnerName: string;
  archived: boolean;
  name: string;
  description: string;
  salaryMin?: number;
  salaryMax?: number;
  locations: Array<JobOfferLocationDTO>;
  mode?: CmsNameValueDTO;
  jobSharing: boolean;
  domainExpertise: Array<CmsNameValueDTO>;
  domainPreference: Array<CmsNameValueDTO>;
  workExperience?: number;
  spokenLanguages?: Array<CmsNameValue>;
  leadershipExperience?: number;
  leadershipTeamSize?: number;
  sectorFocus: Array<CmsNameValueDTO>;
  workLanguage?: Array<CmsNameValueDTO>;
  matchedAmount: number;
  industries?: Array<CmsNameValueDTO>;
  careerLevel?: Array<CmsNameValueDTO>;
  status: JobOpportunitySearchResultStatus;
  creationTimestamp: number;
  countryCode?: string;
}

export interface MatchedTalent {
  accountId: string;
  avatarUrl: string;
  salutation: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  status: Array<MatchedTalentStatus>;
  salaryExpectations?: SalaryExpectation;
  salaryExpectationMatch: boolean;
  domainExpertiseMatch: Array<CmsNameValue>;
  domainExpertiseOther: Array<CmsNameValue>;
  industriesMatch: Array<CmsNameValue>;
  industriesOther: Array<CmsNameValue>;
  jobSharing: boolean;
  careerLevelMatch: boolean;
  careerLevel: CmsNameValue;
  willingnessToTravel: number;
  workExperience: number;
  workExperienceMatch: boolean;
  idealWorkingAssignments: Array<CmsNameValue>;
  leadershipExperience: number;
  leadershipExperienceMatch: boolean;
  leadershipTeamSize: number;
  leadershipTeamSizeMatch: boolean;
  locationPostalCode: string;
  locationCity: string;
  locationCountry: string;
  locationMatch: boolean;
  deanonymized: boolean;
  radius: CmsNameValue;
  score: number;
  conversationId?: string;
  registrationTimestamp: number;
  hasComments: boolean;
  momSkills: Array<CmsNameValue>;
  hashTags: Array<string>;
  favourite: boolean;
  stage: number; 
}

export interface MatchedTalentDTO {
  accountId: {
    internalId: string;
  };
  avatar: {
    id: string;
    accountId: {
      internalId: string;
    };
    avatarUrl: string;
    name: string;
  };
  salutation: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  status: Array<MatchedTalentStatus>;
  salaryExpectationMin: number;
  salaryExpectationMax: number;
  salaryExpectationMatch: boolean;
  domainExpertise: Array<CmsNameValueDTO>;
  domainExpertiseMatch: Array<CmsNameValueDTO>;
  industries: Array<CmsNameValueDTO>;
  industriesMatch: Array<CmsNameValueDTO>;
  jobSharing: boolean;
  careerLevelMatch: boolean;
  careerLevel: CmsNameValueDTO;
  willingnessToTravel: number;
  workExperience: number;
  workExperienceMatch: boolean;
  idealWorkingAssignments: Array<CmsNameValueDTO>;
  leadershipExperience: number;
  leadershipExperienceMatch: boolean;
  leadershipTeamSize: number;
  leadershipTeamSizeMatch: boolean;
  locationPostalCode: string;
  locationCity: string;
  locationCountry: string;
  locationMatch: boolean;
  deanonymized: boolean;
  radius: CmsNameValueDTO;
  score: number;
  conversationId?: string;
  registrationTimestamp: number;
  hasComments: boolean;
  momSkills: Array<CmsNameValueDTO>;
  hashTags: Array<string>;
  favourite: boolean;
  stage: number;
}

export interface JobOfferTalentCommentDTO {
  id: string;
  targetAccountId: {
    internalId: string;
  };
  jobOpportunityId: string;
  authorAccountId: {
    internalId: string;
  };
  text: string;
  creationTimestamp: number;
  authorName: string;
}

export interface JobOfferTalentComment {
  id: string;
  targetAccountId: {
    internalId: string;
  };
  jobOpportunityId: string;
  authorAccountId: {
    internalId: string;
  };
  text: string;
  creationTimestamp: string;
  authorName: string;
}

export enum MatchedTalentStatus {

  /**
   * Talent is new in matching result
   */
  UNSEEN = "UNSEEN",

  /**
   * There is communication between Mission Partner and Talent
   */
  IN_COMMUNICATION = "IN_COMMUNICATION",

  /**
   * Mission Partner and Talent have an agreement on Job Opportunity
   */
  CLOSED = "CLOSED",

  /**
   * Talent rejected to show their profile
   */
  REJECTED = "REJECTED",

  /**
   * Archived means that Mission Partner doesn't want to see this Talent in matching list
   */
  ARCHIVED = "ARCHIVED"

}

export enum JobOpportunitySearchResultStatus {
  PENDING = "PENDING",
  DONE = "DONE"
}
