import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateConversationDialogComponent } from "./create-conversation-dialog.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzModalModule } from "ng-zorro-antd/modal";
import { ExplanationLabelModule } from "../../../../../common-ui/src/lib/explanation-label/explanation-label.module";

@NgModule({
  declarations: [CreateConversationDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzWaveModule,
    TranslateModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    ReactiveFormsModule,
    NzModalModule,
    ExplanationLabelModule,
  ],
})
export class CreateConversationDialogModule {}
