import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { APP_CONFIGURATION } from "../core.di";
import { AppConfig } from "../app-config/config.types";
import { ApiResponseDTO } from "../../../../platform-pages/src/lib/api/common.types";

@Injectable({ providedIn: "root" })
export class NotTranslatedApi {
  private basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    this.basePath = appConfig.apiEndpointUrl;
  }

  logMissingKey(key: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/cms-data/log-missing-key`, {key})
      .pipe(
        map((response) => {
          return response.success
        }),
      );
  }
}
