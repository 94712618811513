<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>{{'admin.filter.mission-partner.placeholder' | translate}}</nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            [nzDisabled]="!missionPartnerEditable"
            [nzPlaceHolder]="'select.placeholder' | translate"
            formControlName="missionPartnerId"
          >
            <nz-option
              *ngFor="let option of missionPartners"
              [nzLabel]="option.companyName"
              [nzValue]="option.accountId.internalId"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'admin.jo.name.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="name" [placeholder]="'input.placeholder' | translate" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>
          <mh-explanation-label [labelKey]="'admin.jo.description.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            rows="2"
            formControlName="description"
            [placeholder]="'input.placeholder' | translate"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-control>
          <mh-salary-expectation-input
            formControlName="salaryExpectations"
            [mark]="formGroup.get('salaryExpectations')?.dirty || false"
            [limitMaxOptions]="false"
          ></mh-salary-expectation-input>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label [nzRequired]="!!countryControl?.validator">
          <mh-explanation-label [labelKey]="'admin.jo.country.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <mh-country-input formControlName="countryCode"></mh-country-input>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.locations.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <mh-location-multiple-large-input formControlName="locations"></mh-location-multiple-large-input>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.mode.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="mode"
            [nzPlaceHolder]="'select.placeholder' | translate"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              *ngFor="let option of workAssignment$ | async"
              [nzLabel]="option.name | translate"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.job-sharing.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <label nz-checkbox formControlName="jobSharing">
            <span>{{ "admin.jo.job-sharing-ready.label" | translate }}</span>
          </label>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.domain-expertise.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            [nzMaxMultipleCount]="10"
            formControlName="domainExpertise"
            [nzPlaceHolder]="'select.placeholder' | translate"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              *ngFor="let option of expertFields$ | async"
              [nzLabel]="option.name | translate"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.domain-preference.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            nzSize="default"
            formControlName="domainPreference"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              [nzLabel]="option.name | translate"
              [nzValue]="option"
              *ngFor="let option of expertFields$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.industries.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            nzSize="default"
            formControlName="industries"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              [nzLabel]="option.name | translate"
              [nzValue]="option"
              *ngFor="let option of industry$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.career-level.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            [nzMaxMultipleCount]="10"
            formControlName="careerLevel"
            [nzPlaceHolder]="'select.placeholder' | translate"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              *ngFor="let option of careerLevel$ | async"
              [nzLabel]="option.name | translate"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.work-exp.label'">
            {{ "profile.work-exp.label" | translate }} <span [innerHTML]="experienceYears"></span>
          </mh-explanation-label>
        </nz-form-label>
        <div nz-row>
          <div nz-col [nzSpan]="22" nzOffset="1" [nzMd]="{ span: 24, offset: 0 }">
            <nz-form-control>
              <nz-slider [nzMin]="0" [nzMax]="30" nzTooltipVisible="never" formControlName="workExperience"></nz-slider>
            </nz-form-control>
          </div>
        </div>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.leadership-exp.label'">
            {{ "profile.leadership-exp.label" | translate }} <span [innerHTML]="leadershipYears"></span>
          </mh-explanation-label>
        </nz-form-label>
        <div nz-row>
          <div nz-col [nzSpan]="22" nzOffset="1" [nzMd]="{ span: 24, offset: 0 }">
            <nz-form-control>
              <nz-slider
                [nzMin]="0"
                [nzMax]="30"
                nzTooltipVisible="never"
                formControlName="leadershipExperience"
              ></nz-slider>
            </nz-form-control>
          </div>
        </div>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="6">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.leadership-team-size.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-input-number
            [nzMin]="1"
            [nzStep]="1"
            nzInputMode="numeric"
            formControlName="leadershipTeamSize"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="18"></div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.sector.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzSize="default"
            nzMode="multiple"
            formControlName="sectorFocus"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              [nzLabel]="option.name | translate"
              [nzValue]="option"
              *ngFor="let option of sectors$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.workinglanguages'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            [nzPlaceHolder]="'select.placeholder' | translate"
            formControlName="workLanguage"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              *ngFor="let option of languageOptions$ | async"
              [nzLabel]="option.name | translate"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
     <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'admin.jo.spokenlanguages'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzMode="multiple"
            [nzPlaceHolder]="'select.placeholder' | translate"
            formControlName="spokenLanguages"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              *ngFor="let option of spokenLanguages$ | async"
              [nzLabel]="option.name | translate"
              [nzValue]="option"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
