import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from "@angular/core";
import {APP_CONFIGURATION} from "../../../../../core/src/lib/core.di";
import {AppConfig} from "../../../../../core/src/lib/app-config/config.types";
import {DocumentService} from "../../api/document/document.service";
import {catchError, Subject, takeUntil, throwError} from "rxjs";
import {AudioRecordingService} from "../../../../../core/src/lib/recording/audio-recording.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MediaType, PresentationMedia} from "../../../../../core/src/lib/recording/recording.types";
import {VideoRecordingService} from "../../../../../core/src/lib/recording/video-recording.service";
import {CommonService} from "../../api/common.service";

@Component({
  selector: "mh-profile-media-upload",
  templateUrl: "./profile-media-upload.component.html",
  styleUrls: ["./profile-media-upload.component.less"],
})
export class ProfileMediaUploadComponent implements OnDestroy, AfterViewInit {
  @Input() presentationMedia: PresentationMedia | null = null;
  @Input() mobileView!: boolean;
  @Output() profileMediaChange = new EventEmitter<PresentationMedia | null>();
  @ViewChild('videoElement') videoElement!: ElementRef;

  private readonly destroy$ = new Subject<void>();
  mediaType?: MediaType;
  isRecording = false;
  recordingLoading = false;
  recordedTime = '';
  blobUrl?: SafeUrl | null;
  private blob?: Blob;
  private mediaName = '';
  previewVideoElement: any;
  isUploading = false;

  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private documentsService: DocumentService,
    private audioRecordingService: AudioRecordingService,
    private videoRecordingService: VideoRecordingService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
  ) {
    this.setupAudioRecordingSubscriptions();
    this.setupVideoRecordingSubscriptions();
  }

  private setupAudioRecordingSubscriptions() {
    this.audioRecordingService.recordingFailed().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleRecordingFailure();
    });

    this.audioRecordingService.getRecordedTime().pipe(takeUntil(this.destroy$)).subscribe((time) => {
      this.handleRecordedTime(time);
    });

    this.audioRecordingService.getRecordedBlob().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.handleRecordedBlob(data, () => this.handleAudioUpload());
    });
  }

  private setupVideoRecordingSubscriptions() {
    this.videoRecordingService.recordingFailed().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleRecordingFailure();
    });

    this.videoRecordingService.getRecordedTime().pipe(takeUntil(this.destroy$)).subscribe((time) => {
      this.handleRecordedTime(time);
    });

    this.videoRecordingService.getRecordedBlob().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.handleRecordedBlob(data, () => this.handleVideoUpload());
    });
  }

  private handleRecordingFailure() {
    this.isRecording = false;
    this.ref.detectChanges();
  }

  private handleRecordedTime(time: any) {
    this.recordedTime = time;
    this.ref.detectChanges();
  }

  private handleRecordedBlob(data: any, handleUpload: () => void) {
    if (data.blob) {
      this.blob = data.blob;
      this.mediaName = data.title;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.ref.detectChanges();
      handleUpload();
    }
  }

  ngAfterViewInit() {
    this.previewVideoElement = this.videoElement.nativeElement;
    this.initPresentationMedia();
  }

  private initPresentationMedia() {
    if (!this.presentationMedia) {
      return;
    }

    this.mediaName = this.presentationMedia.name;
    this.blobUrl = this.presentationMedia.fileUrl ? `${this.appConfig.siteUrl}/${this.presentationMedia.fileUrl}` : '';

    this.mediaType = this.commonService.getFileType(this.mediaName);
    if (this.mediaType === MediaType.VIDEO && this.previewVideoElement) {
      this.previewVideoElement.load();
    }
  }

  startAudioRecording() {
    if (!this.isRecording) {
      this.mediaType = MediaType.AUDIO;
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isRecording && this.mediaType === MediaType.AUDIO) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    if (this.isRecording && this.mediaType === MediaType.AUDIO) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  handleAudioUpload(): void {
    if (this.blob && this.mediaType === MediaType.AUDIO) {
      const formData = new FormData();
      formData.append('file', this.blob, this.mediaName);
      this.documentsService
        .uploadPresentationMedia(formData)
        .pipe(
          catchError((error) => {
            return throwError(() => new Error(error));
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((audioFile) => {
          this.profileMediaChange.emit(audioFile);
        });
    }
  }

  startVideoRecording() {
    if (!this.isRecording && this.previewVideoElement) {
      this.mediaType = MediaType.VIDEO;
      this.recordingLoading = true;
      this.isRecording = true;
      this.previewVideoElement.controls = false;
      this.previewVideoElement.muted = true;
      this.videoRecordingService.startRecording().then(stream => {
        this.previewVideoElement.srcObject = stream;
        this.previewVideoElement.play();
        this.recordingLoading = false;
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });
    }
  }

  abortVideoRecording() {
    if (this.isRecording && this.mediaType === MediaType.VIDEO) {
      this.isRecording = false;
      this.videoRecordingService.abortRecording();
    }
  }

  stopVideoRecording() {
    if (this.isRecording && this.mediaType === MediaType.VIDEO) {
      this.videoRecordingService.stopRecording();
      this.isRecording = false;
      this.previewVideoElement.srcObject = this.blobUrl;
      this.previewVideoElement.controls = true;
      this.previewVideoElement.muted = false;
    }
  }

  handleVideoUpload(): void {
    if (this.blob && this.mediaType === MediaType.VIDEO) {
      const formData = new FormData();
      formData.append("file", this.modifyBlobMimeType(this.blob), this.mediaName);
      this.documentsService
        .uploadPresentationMedia(formData)
        .pipe(
          catchError((error) => {
            return throwError(() => new Error(error));
          }),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((videoFile) => {
          this.profileMediaChange.emit(videoFile);
          this.presentationMedia = videoFile;
          this.initPresentationMedia();
        });
    }
  }

  handleMediaRemove(): void {
    if (!this.presentationMedia?.id) {
      return;
    }
    this.documentsService.deleteUserFile(this.presentationMedia.id).pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result) {
        this.mediaType = undefined;
        this.blobUrl = null;
        this.previewVideoElement.srcObject = null;
        this.profileMediaChange.emit(null);
      }
    });
  };

  handleMediaUpload = (file: any): boolean => {
    this.isUploading = true;
    const formData = new FormData();
    formData.append("file", file);

    this.documentsService
      .uploadPresentationMedia(formData)
      .pipe(
        catchError((error) => {
          this.isUploading = false;
          return throwError(() => new Error(error));
        }),
      )
      .subscribe((mediaFile) => {
        this.profileMediaChange.emit(mediaFile);
        this.presentationMedia = mediaFile;
        this.initPresentationMedia();
        this.isUploading = false;
      });
    return false;
  };

  ngOnDestroy() {
    this.destroy$.next();
    this.abortAudioRecording();
    this.abortVideoRecording();
  }

  private getFileExtension(filename: string): string {
    return filename.split(".").pop()?.toLowerCase() || "";
  }

  private isVideoFile(extension: string): boolean {
    const videoExtensions = ["mp4", "avi", "mkv", "mov", "webm"];
    return videoExtensions.includes(extension);
  }

  private isAudioFile(extension: string): boolean {
    const audioExtensions = ["mp3", "wav", "ogg", "aac"];
    return audioExtensions.includes(extension);
  }

  private modifyBlobMimeType(originalBlob: Blob): Blob {
    const codecsMatch = originalBlob.type.match(/codecs=([^;]*)/);
    const codecs = codecsMatch ? codecsMatch[1] : "";

    if (codecs) {
      const modifiedMimeType = originalBlob.type.replace(codecs, `"${codecs}"`);
      return new Blob([originalBlob], { type: modifiedMimeType });
    }

    return originalBlob;
  }

  protected readonly MediaType = MediaType;
}
