import { Injectable } from "@angular/core";
import { DocumentFileDTO } from "./document.types";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { CommonService } from "../common.service";

@Injectable({
  providedIn: "root",
})
export class DocumentSerializer {
  constructor(private commonService: CommonService) {}

  deserializeDocuments(dtos: Array<DocumentFileDTO>): Array<NzUploadFile> {
    return dtos.map((dto) => this.deserializeDocument(dto));
  }

  deserializeDocument(dto: DocumentFileDTO): NzUploadFile {
    return {
      uid: dto.id,
      name: dto.name,
      url: this.commonService.toAbsUrl(dto.fileUrl),
      status: "success",
    };
  }
}
