import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {debounceTime, distinctUntilChanged, Observable, Subject, takeUntil} from "rxjs";
import { DuplicatePostalCodeValidator, ValidateSimpleLocation } from "./location-multiple-large-input.validator";
import { SimpleLocation } from "../../../../platform-pages/src/lib/api/google-geocoding/google-geocoding.types";
import { CmsNameValue, compareCmsNameValue } from "../../../../platform-pages/src/lib/api/cms/cms.types";
import { CmsCachedService } from "../../../../platform-pages/src/lib/api/cms/cms-cached.service";
import { GoogleGeocodingService } from "../../../../platform-pages/src/lib/api/google-geocoding/google-geocoding.service";

@Component({
  selector: "mh-location-multiple-large-input",
  templateUrl: "./location-multiple-large-input.component.html",
  styleUrls: ["./location-multiple-large-input.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LocationMultipleLargeInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: LocationMultipleLargeInputComponent, multi: true },
  ],
})
export class LocationMultipleLargeInputComponent implements OnDestroy, ControlValueAccessor, OnInit, AfterViewInit {
  @Input() value!: Array<SimpleLocation>;
  @Input() showAddBtn = true;
  private destroy$ = new Subject<void>();
  public radiusOptions$: Observable<Array<CmsNameValue>>;
  form!: UntypedFormGroup;
  public cityOptions: string[] = [];
  zipFocused = false;
  disabled = false;
  public loading= false;

  get countryControl(): AbstractControl | null {
    return this.form.get("country");
  }

  constructor(
    private formBuilder: FormBuilder,
    private geocodingService: GoogleGeocodingService,
    private cmsCachedService: CmsCachedService,
    private cd: ChangeDetectorRef,
  ) {
    this.radiusOptions$ = this.cmsCachedService.getUserDataByName("radius", false);
  }

  addLocation() {
    if (this.form.invalid || !this.countryControl?.getRawValue()) {
      Object.values(this.form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.value = [...this.value, { ...this.form.value, country: this.countryControl?.getRawValue() }];
    this.form.reset();
    this.notifyOfChange();
  }

  editLocation(location: SimpleLocation) {
    this.value = [...this.value.filter((l) => l.postalCode !== location.postalCode)];
    this.cityOptions = [location.city as string];
    this.form.reset(location);
    this.notifyOfChange();
  }

  deleteLocation(location: SimpleLocation) {
    this.value = [...this.value.filter((l) => l.postalCode !== location.postalCode)];
    this.notifyOfChange();
  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    this.updatePostalCodeValidator();
  }

  isInvalid(location: SimpleLocation): boolean {
    return !ValidateSimpleLocation(location);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      postalCode: [undefined, [Validators.required, DuplicatePostalCodeValidator(this.value)]],
      city: [undefined, []],
      country: [{ value: undefined, disabled: true }, []],
      radius: [undefined, []],
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged((prev, curr) => prev.postalCode === curr.postalCode)).subscribe((value) => {
      this.handleZipChange();
    });
  }

  private updatePostalCodeValidator() {
    this.form.get('postalCode')?.setValidators([Validators.required, DuplicatePostalCodeValidator(this.value)]);
    this.form.get('postalCode')?.updateValueAndValidity();
  }

  handleZipChange() {
    const zipControl = this.form.get("postalCode");
    const cityControl = this.form.get("city");
    const countryControl = this.countryControl;
    if (zipControl?.valid && this.zipFocused) {
      this.loading = true;
      this.cd.markForCheck();
      this.geocodingService.decodeAddress(zipControl.value).subscribe((location) => {
        countryControl?.patchValue(location.country, { emitEvent: false });
        cityControl?.patchValue(location.city, { emitEvent: false });
        this.cityOptions = [];
        if (location.city) this.cityOptions.push(location.city);
        this.onChange({ ...location, ...this.form.value, country: this.countryControl?.getRawValue() });
        this.loading = false;
      });
    } else if (this.zipFocused) {
      countryControl?.patchValue("", { emitEvent: false });
      cityControl?.patchValue("", { emitEvent: false });
      this.cityOptions = [];
    }
  }

  private notifyOfChange() {
    if (this.onChange) {
      this.updatePostalCodeValidator();
      this.onChange(this.value.filter((location) => !this.isInvalid(location)));
    }
  }

  onChange = (value: Array<SimpleLocation>) => {};
  onTouched = () => {};

  writeValue(value: Array<SimpleLocation>) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if(isDisabled) {
      this.form.get('postalCode')?.setValidators([]);
      this.form.get('postalCode')?.disable();
      this.form.get('postalCode')?.markAsTouched();
      this.form.get('postalCode')?.updateValueAndValidity();
    } else {
      this.form.get('postalCode')?.enable();
      this.updatePostalCodeValidator()
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if ((!this.value || this.value.length == 0) && control.dirty) {
      Object.values(this.form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return { required: true };
    }
    const invalidItems = this.value.filter((item) => !ValidateSimpleLocation(item));
    return invalidItems.length > 0 ? { required: true } : null;
  }

  handleZipBlur() {
    setTimeout(() => {
      this.zipFocused = false;
    }, 1000);
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
