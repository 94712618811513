import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
} from "@angular/core";

import { debounceTime, fromEvent, Subject } from "rxjs";
import { distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { ScrollToValidationErrorComponent } from "./scroll-to-validation-error.component";
import { DOCUMENT } from "@angular/common";

@Directive({
  selector: "[mhScrollToValidationError]",
  exportAs: "mhScrollToValidationError",
})
export class ScrollToValidationErrorDirective implements OnDestroy, AfterViewInit {
  private readonly onDestroy$ = new Subject<void>();
  private scrollToValidationErrorComponentRef!: ComponentRef<ScrollToValidationErrorComponent>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly ngZone: NgZone,
  ) {}

  ngAfterViewInit() {
    if (this.elementRef?.nativeElement) {
      const scrollBodyElement = this.document.documentElement;
      scrollBodyElement.classList.add("validation-scroll-cnt");
      //const hasFormFields = !!this.elementRef.nativeElement.querySelector("nz-form-item");
      if (scrollBodyElement) {
        fromEvent(window, "scroll")
          .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), debounceTime(10))
          .subscribe((e: any) => {
            this.onScrollShowHeaderWarning(scrollBodyElement);
          });

        fromEvent(window, "submit")
          .pipe(takeUntil(this.onDestroy$), distinctUntilChanged(), debounceTime(10))
          .subscribe((e: any) => {
            this.onScrollShowHeaderWarning(scrollBodyElement);
          });
      }

      this.ngZone.onStable.pipe(take(1)).subscribe(() => this.loadWarningComponent(scrollBodyElement));
    }
  }

  ngOnDestroy(): void {
    if (this.scrollToValidationErrorComponentRef) {
      this.scrollToValidationErrorComponentRef.destroy();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private getErrorElements(scrollBodyElement: any) {
    return [...scrollBodyElement.querySelectorAll(".ant-form-item-has-error")];
  }

  onScrollShowHeaderWarning(scrollBodyElement: any) {
    const errorElements = this.getErrorElements(scrollBodyElement);

    if (errorElements.length === 0) {
      this.scrollToValidationErrorComponentRef.instance.hide();
      return;
    }

    const notVisibleErrorElements = errorElements.filter((errorElement) => {
      if (window.getComputedStyle(errorElement).display !== "none") {
        return errorElement.getBoundingClientRect().top < 0;
      } else {
        return false;
      }
    });
    if (notVisibleErrorElements.length === 0) {
      this.scrollToValidationErrorComponentRef.instance.hide();
      return;
    }
    this.scrollToValidationErrorComponentRef.instance.show(notVisibleErrorElements);
  }

  private loadWarningComponent(scrollBodyElement: HTMLElement): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ScrollToValidationErrorComponent);
    this.scrollToValidationErrorComponentRef =
      this.viewContainerRef.createComponent<ScrollToValidationErrorComponent>(componentFactory);
    this.scrollToValidationErrorComponentRef.instance.scrollBodyElement = scrollBodyElement;
    this.renderer.insertBefore(
      scrollBodyElement,
      this.scrollToValidationErrorComponentRef.location.nativeElement,
      scrollBodyElement.firstChild,
    );
  }
}
