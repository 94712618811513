import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit, Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder, FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";
import { ValidateTrackRecord } from "./track-records-input.validator";
import { TrackRecord } from "../../api/sign-up/signup.types";
import { CmsNameValue, compareCmsNameValue } from "../../api/cms/cms.types";
import { CmsCachedService } from "../../api/cms/cms-cached.service";
import { differenceInCalendarDays, endOfDay } from "date-fns";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";

@Component({
  selector: "mh-track-records-input",
  templateUrl: "./track-records-input.component.html",
  styleUrls: ["./track-records-input.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TrackRecordsInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: TrackRecordsInputComponent, multi: true },
  ],
})
export class TrackRecordsInputComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() value!: Array<TrackRecord>;
  @Input() showAddBtn = true;
  @Output() trackRecordSaved = new EventEmitter<void>();
  private destroy$ = new Subject<void>();
  public editingIndex: number | null = null;
  private currentTrackRecord: TrackRecord | null = null;

  public expertFields$: Observable<Array<CmsNameValue>>;
  public sectors$: Observable<Array<CmsNameValue>>;
  public companyTypeOptions$: Observable<Array<CmsNameValue>>;
  public department$: Observable<Array<CmsNameValue>>;
  public industry$: Observable<Array<CmsNameValue>>;
  private today = endOfDay(new Date());

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

  form = this.formBuilder.group({
    trackRecords: this.formBuilder.array([]),
  });

  get trackRecordFormArray() {
    return this.form.controls["trackRecords"] as FormArray;
  }

  constructor(
    private cmsCachedService: CmsCachedService,
    private formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private modal: NzModalService,
  ) {
    this.expertFields$ = this.cmsCachedService.getUserDataByName("expert-field");
    this.sectors$ = this.cmsCachedService.getUserDataByName("sector");
    this.companyTypeOptions$ = this.cmsCachedService.getUserDataByName("company-type");
    this.department$ = this.cmsCachedService.getUserDataByName("expert-field");
    this.industry$ = this.cmsCachedService.getUserDataByName("industry");
  }

  ngOnInit() {
    this.createForm();
  }

  isInvalid(trackRecord: TrackRecord): boolean {
    return !ValidateTrackRecord(trackRecord);
  }

  addTrackRecord(trackRecord?: TrackRecord, emitEvent?: boolean) {
    if (!trackRecord) trackRecord = this.getBlankTrackRecord();
    const educationForm = this.formBuilder.group({
      employmentDurationRange: [trackRecord.employmentDurationRange],
      employmentDurationFromToNow: [trackRecord.employmentDurationFromToNow],
      workingUntilNow: [trackRecord.workingUntilNow],
      companyName: [trackRecord.companyName, Validators.required],
      companyType: [trackRecord.companyType, Validators.required],
      sector: [trackRecord.sector, Validators.required],
      industry: [trackRecord.industry, Validators.required],
      department: [trackRecord.department, Validators.required],
      jobTitle: [trackRecord.jobTitle, Validators.required],
      roleDetails: [trackRecord.roleDetails, Validators.required],
      current: [trackRecord.current]
    });

    this.setEmploymentDurationValidation(educationForm, false);

    educationForm.get('workingUntilNow')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.setEmploymentDurationValidation(educationForm, !!value);
      });

    this.trackRecordFormArray.insert(0, educationForm, { emitEvent });
  }

  setEmploymentDurationValidation(educationForm: FormGroup, value: boolean) {
    const employmentDurationFromToNowControl = educationForm.get('employmentDurationFromToNow');
    const employmentDurationRangeControl = educationForm.get('employmentDurationRange');

    if (value) {
      employmentDurationFromToNowControl!.setValidators([Validators.required]);
      employmentDurationRangeControl!.clearValidators();
    } else {
      employmentDurationRangeControl!.setValidators([Validators.required]);
      employmentDurationFromToNowControl!.clearValidators();
    }

    employmentDurationFromToNowControl!.updateValueAndValidity();
    employmentDurationRangeControl!.updateValueAndValidity();
  }

  addNewTrackRecord() {
    if(this.editingIndex !== null) return;
    this.addTrackRecord();
    this.toggleEditing(0, false);
    this.changeDetectorRef.markForCheck();
  }

  confirmDeleteTrackRecord(index: number) {
    this.modal.confirm({
      nzTitle: this.translateService.instant('confirm.delete.label'),
      nzOkText: this.translateService.instant('ok.button'),
      nzCancelText: this.translateService.instant('cancel.button'),
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.doDeleteTrackRecord(index),
    });
  }

  doDeleteTrackRecord(index: number) {
    this.trackRecordFormArray.removeAt(index);
    this.notifyOfChange();
    this.changeDetectorRef.markForCheck();
  }

  cancelTrackRecord(index: number) {
    if (this.currentTrackRecord) {
      this.trackRecordFormArray.at(index).patchValue(this.currentTrackRecord);
    } else {
      this.doDeleteTrackRecord(index);
    }
    this.toggleEditing(null, false);
  }

  toggleEditing(index: number | null, saveCurrentEducation = true) {
    this.editingIndex = index;
    if (index !== null && saveCurrentEducation) {
      this.currentTrackRecord = this.trackRecordFormArray.at(index).value;
    } else {
      this.currentTrackRecord = null;
    }
    this.notifyOfChange();
  }

  saveTrackRecord(event: Event, index: number) {
    event.preventDefault();
    if(this.trackRecordFormArray.at(index).invalid) {
      Object.values((this.trackRecordFormArray.at(index) as FormGroup)['controls']).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.sortTrackRecordFormArray();
    this.trackRecordFormArray.controls[index].markAllAsTouched();
    if (!this.trackRecordFormArray.controls[index].valid) return;
    if(this.trackRecordFormArray.controls[index].get('current')?.value) {
      this.trackRecordFormArray.controls
        .filter((control, i) => i != index)
        .map((control) => control.get("current")?.patchValue(false));

    }
    this.toggleEditing(null);
    this.trackRecordSaved.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private createForm() {
    const currentTrackRecords = this.value ? this.value : [];
    this.initializeFormArray(currentTrackRecords, false);
    this.trackRecordFormArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: TrackRecord[]) => {
      this.value = value;
    });
  }

  private initializeFormArray(trackRecords: Array<TrackRecord>, emitEvent: boolean) {
    this.trackRecordFormArray.clear({ emitEvent });
    trackRecords.forEach((education: TrackRecord) => {
      this.addTrackRecord(education, emitEvent);
    });
    this.sortTrackRecordFormArray();
    if (!emitEvent) this.changeDetectorRef.markForCheck();
  }

  private sortTrackRecordFormArray() {
    this.trackRecordFormArray.controls = this.trackRecordFormArray.controls.sort((a: AbstractControl, b: AbstractControl) => {
      const aTrackRecord = a.value;
      const bTrackRecord = b.value;

      const getStartTime = (trackRecord: TrackRecord) => trackRecord.employmentDurationFromToNow?.getTime() || 0;
      const getEndTime = (trackRecord: TrackRecord) => trackRecord.employmentDurationRange?.[1]?.getTime() || 0;

      if (aTrackRecord.workingUntilNow !== bTrackRecord.workingUntilNow) {
        return aTrackRecord.workingUntilNow ? -1 : 1;
      }

      if (aTrackRecord.workingUntilNow) {
        return getStartTime(bTrackRecord) - getStartTime(aTrackRecord);
      }

      return getEndTime(bTrackRecord) - getEndTime(aTrackRecord);
    });
  }

  private getBlankTrackRecord(): TrackRecord {
    return {
      employmentDurationRange: [],
      employmentDurationFromToNow: null,
      workingUntilNow: false,
      companyName: "",
      companyType: undefined,
      sector: undefined,
      industry: undefined,
      department: undefined,
      jobTitle: undefined,
      roleDetails: undefined,
      current: false
    };
  }

  private notifyOfChange() {
    if (this.onChange) {
      this.onChange(this.value.filter((trackRecord) => !this.isInvalid(trackRecord)));
    }
  }

  onChange = (value: Array<TrackRecord>) => {};
  onTouched = () => {};

  writeValue(value: Array<TrackRecord>) {
    this.value = value;
    if (this.trackRecordFormArray) {
      this.initializeFormArray(this.value, false);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const companyNameControl = this.trackRecordFormArray.controls.map((control) => control.get("companyName"));

    const invalidControls = [
      companyNameControl,
    ].filter((control) => {
      if (this.editingIndex !== null) {
        return control && !control[this.editingIndex]?.valid;
      }
      return false;
    });

    const allInputsValid = invalidControls.length === 0;
    return control.value && allInputsValid && this.editingIndex === null ? null : { required: true };
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
