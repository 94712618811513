import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import * as Sentry from "@sentry/angular-ivy";
import { AppConfigFromAssets } from "../../../libs/core/src/lib/app-config/config.types";
import { ApiResponseDTO } from "../../../libs/platform-pages/src/lib/api/common.types";
import { CmsFeature, CmsFeatureStatus, CmsFeatureType } from "../../../libs/platform-pages/src/lib/api/cms/cms.types";

fetch("./assets/config.json")
  .then((response) => response.json())
  .then((config: AppConfigFromAssets) => {
    fetch(config.apiEndpointUrl + "/cms-data/features")
      .then((response) => response.json())
      .then((responseJSON) => (responseJSON as ApiResponseDTO).results.data as Array<CmsFeature> | [])
      .then((features) => {
        config.featureStatus = deserializeCmsFeatures(features);
        window.location.host.includes("momhunting") &&
          Sentry.init({
            environment: config.production ? "production" : "test",
            dsn: "https://9ad50bb6d652b689e8554dc7d031a1b5@o1057060.ingest.sentry.io/4506506851450880",
            integrations: [
              new Sentry.BrowserTracing(),
              new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [/^https:\/\/api\.momhunting/],
              }),
            ],
            tracePropagationTargets: [/^https:\/\/api\.momhunting/],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          });

        const AppModuleWithProviders = AppModule.configure(config) as any;

        platformBrowserDynamic(AppModuleWithProviders.providers)
          .bootstrapModule(AppModuleWithProviders.ngModule)
          .catch((err) => console.error(err));
      });
  })
  .catch((_) => {
    console.error("Could not read config.json");
  });

export function deserializeCmsFeatures(dto: Array<CmsFeature>): CmsFeatureStatus {
  return {
    communityLink: dto.find((f) => f.name == CmsFeatureType.COMMUNITY_LINK)?.status || false,
    profilePreviewLink: dto.find((f) => f.name == CmsFeatureType.PROFILE_PREVIEW_LINK)?.status || false,
    englishSite: dto.find((f) => f.name == CmsFeatureType.ENGLISH_SITE)?.status || false,
    mpForMp: dto.find((f) => f.name == CmsFeatureType.MP_FOR_MISSION_PARTNER)?.status || false,
    mpForTalent: dto.find((f) => f.name == CmsFeatureType.MP_FOR_TALENT)?.status || false,
    showKeysSwitch: dto.find((f) => f.name == CmsFeatureType.SHOW_KEYS_SWITCH)?.status || false,
  };
}
