import { APP_INITIALIZER, ErrorHandler, Injector, isDevMode, NgModule } from "@angular/core";
import { CommonModule, DOCUMENT, registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeDe from "@angular/common/locales/de";
import { IconDefinition } from "@ant-design/icons-angular";
import * as AllIcons from "@ant-design/icons-angular/icons";
import { NZ_ICONS } from "ng-zorro-antd/icon";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { de_DE, NZ_DATE_LOCALE, NZ_I18N } from "ng-zorro-antd/i18n";
import { de } from "date-fns/locale";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NzNotificationServiceModule } from "ng-zorro-antd/notification";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { appInitializerFactory, CustomTranslateLoader } from "./custom-translate-loader";
import { CustomMissingTranslationHandler } from "./custom-translate-loader/custom-missing-translation-handler";
import { NotTranslatedService } from "./custom-translate-loader/not-translated.service";
import { AppConfig, AppConfigFromAssets } from "./app-config/config.types";
import { APP_CONFIGURATION, NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN } from "./core.di";
import { LocaleService } from "./locale/locale.service";
import { SocketService } from "./soket/socket.service";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { IGoogleAnalyticsSettings, NGX_GTAG_FN } from "@hakimio/ngx-google-analytics";
import { GoogleAnalyticsInitializer } from "./custom-translate-loader/custom-translate-loader";
import { NotTranslatedApi } from "./custom-translate-loader/not-translated.api";

const langLocales = ["de", "en"];
const keysLocale = "keys";
registerLocaleData(localeEn, keysLocale);
registerLocaleData(localeDe, "de");

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NzNotificationServiceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, APP_CONFIGURATION],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
        deps: [NotTranslatedService, NotTranslatedApi],
      },
    }),
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: NZ_DATE_LOCALE, useValue: de },
    { provide: NZ_I18N, useValue: de_DE },
    { provide: NZ_ICONS, useValue: icons },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, LocaleService, Injector, APP_CONFIGURATION, Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: GoogleAnalyticsInitializer,
      deps: [NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NGX_GTAG_FN, DOCUMENT],
    },
    SocketService,
  ],
})
export class CoreModule {
  static configure(appConfig: AppConfigFromAssets) {
    const staticConfig: AppConfig = {
      translationLocales: !appConfig.featureStatus.englishSite ? ["de"] : [...langLocales],
      appLocales: !appConfig.featureStatus.englishSite ? ["de"] : [...langLocales, keysLocale],
      defaultLocale: "de",
      keysLocale: keysLocale,
      ...appConfig,
    };

    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
          useValue: {
            ga4TagId: staticConfig.googleAnalyticsId,
          } as IGoogleAnalyticsSettings,
        },
        { provide: APP_CONFIGURATION, useValue: staticConfig },
      ],
    };
  }
}
