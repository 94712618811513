import { Inject, Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { ApiResponseDTO } from "../../../api/common.types";
import { LoginDataDTO, UserStateDTO } from "./login.types";
import { BaseApi } from "../../../api/base-api";
import { APP_CONFIGURATION } from "../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../core/src/lib/app-config/config.types";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class LoginApi extends BaseApi {
  private readonly basePath: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly injector: Injector,
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
  ) {
    super(injector);
    this.basePath = appConfig.apiEndpointUrl;
  }

  doLogin(login: string, password: string): Observable<LoginDataDTO> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/login`, { login, password }).pipe(
      map((response) => {
        if (response.success) {
          this.setSentryUser((response.results as LoginDataDTO).user_state);
          return response.results as LoginDataDTO;
        } else {
          super.handleErrorResponse(response);
          return {} as LoginDataDTO;
        }
      }),
    );
  }

  getLoginData(): Observable<UserStateDTO> {
    return this.httpClient.get<ApiResponseDTO>(`${this.basePath}/user/state`).pipe(
      map((response) => {
        if (response.success) {
          this.setSentryUser(response.results.user_state);
          return response.results.user_state as UserStateDTO;
        } else {
          super.handleErrorResponse(response);
          return {} as UserStateDTO;
        }
      }),
    );
  }

  doLogout(): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/logout`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        Sentry.setUser(null);
        return response.success;
      }),
    );
  }

  passwordForgotten(email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.basePath}/auth/password-forgotten`, email);
  }

  passwordForgottenCheck(token: string): Observable<void> {
    return this.httpClient.post<void>(`${this.basePath}/auth/password-forgotten/check`, { token });
  }

  resetPassword(password: string): Observable<void> {
    return this.httpClient.post<void>(`${this.basePath}/auth/password-forgotten/change`, { password });
  }

  emailConfirmationCheck(token: string): Observable<string> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/email-confirmation/check`, { token }).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.results.token;
      }),
    );
  }

  emailChangedConfirmationCheck(token: string): Observable<string> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/email-changed-confirmation/check`, { token }).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.results.token;
      }),
    );
  }

  emailChange(email: string): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/email/change`, { email }).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  validateEmailChange(email: string): Observable<ApiResponseDTO> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/email-change/validate`, { email }).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
          return response;
        }
        return response;
      }),
    );
  }

  passwordChange(oldPassword: string, password: string): Observable<string> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/password/change`, { oldPassword, password }).pipe(
      map((response) => {
        if (!response.success) {
          return super.handleErrorResponse(response);
        }
        return response.results.authToken || '';
      }),
    );
  }

  accountDelete(): Observable<boolean> {
    return this.httpClient.delete<ApiResponseDTO>(`${this.basePath}/auth/account/delete`).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }

  private setSentryUser(userState: UserStateDTO) {
    Sentry.setUser({id: userState.accountId.internalId, email: userState.email, username: userState.accountType + ' ' + userState.email});
  }

  loginFromEmail(): Observable<boolean> {
    return this.httpClient.post<ApiResponseDTO>(`${this.basePath}/auth/login/from-email`, {}).pipe(
      map((response) => {
        if (!response.success) {
          super.handleErrorResponse(response);
        }
        return response.success;
      }),
    );
  }
}
