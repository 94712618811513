<!--  Empty sections are hidden in talent-profile-preview.less.-->
<nz-anchor [nzAffix]="false" [nzTargetOffset]="20">
  <nz-link nzHref="#about-me" [nzTitle]="aboutMeIcon">
    <ng-template #aboutMeIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.about-me' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="user" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#personality" [nzTitle]="personalityIcon">
    <ng-template #personalityIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.personality' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="smile" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#desired-job" [nzTitle]="desiredJobIcon">
    <ng-template #desiredJobIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.desired-job' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="solution" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#education" [nzTitle]="educationIcon">
    <ng-template #educationIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.education' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="book" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#experience" [nzTitle]="experienceIcon">
    <ng-template #experienceIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.experience' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="bar-chart" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#files" [nzTitle]="filesIcon">
    <ng-template #filesIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.files' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="file" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#references" [nzTitle]="referencesIcon">
    <ng-template #referencesIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.references.title' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="solution" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#data-space" [nzTitle]="dataSpaceIcon">
    <ng-template #dataSpaceIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.data-space' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="database" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#profile-info" [nzTitle]="profileInfoIcon">
    <ng-template #profileInfoIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.profile-info' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
  <nz-link nzHref="#comments" [nzTitle]="commentsIcon">
    <ng-template #commentsIcon>
      <button
        nz-button
        nzType="link"
        [nzSize]="isMobile ? 'small' : 'large'"
        [nzTooltipTitle]="'profile.navigation.comments' | translate"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <span nz-icon nzType="message" nzTheme="outline"></span>
      </button>
    </ng-template>
  </nz-link>
</nz-anchor>
