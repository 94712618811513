import { Input, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckBoxGroupSelectComponent } from "./check-box-group-select.component";
import { CmsNameValue } from "../../../../platform-pages/src/lib/api/cms/cms.types";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NzInputModule} from "ng-zorro-antd/input";

@NgModule({
  declarations: [CheckBoxGroupSelectComponent],
  imports: [CommonModule, NzCheckboxModule, FormsModule, TranslateModule, NzInputModule],
  exports: [
    CheckBoxGroupSelectComponent
  ]
})
export class CheckBoxGroupSelectModule {}
