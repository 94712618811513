<nz-layout class="auth">
  <div nz-row class="auth__container">
    <div nz-col nzXs="0" nzMd="12" class="auth__bg">
      <div class="auth__bg-img"></div>
    </div>
    <div nz-col nzXs="24" nzMd="12" class="auth__content">

      <mh-locale-select mode="dropdown"></mh-locale-select>

      <div nz-row nzJustify="center">
        <div nz-col nzSpan="24"  class="auth__logo">
          <img class="logo-img" src="/assets/images/logo.svg" alt="Momhunting logo" />
        </div>
        <h1 class="auth__title" nz-col nzSpan="24">{{'landing.title' | translate}}</h1>
        <form nz-form class="auth__form">
          <nz-form-item *ngIf="appConfig.featureStatus.mpForMp">
            <button [routerLink]="['/sign-up-mission-partner']" nz-button nzType="primary" nzSize="large" nzBlock class="btn-secondary">
              {{'landing.register.mission-partner' | translate}}
            </button>
          </nz-form-item>

          <button [routerLink]="['/sign-up-talent']" nz-button nzType="primary" nzSize="large" nzBlock>{{'landing.register.talent' | translate}}</button>

          <nz-divider [nzText]="'landing.divider' | translate"></nz-divider>

          <nz-form-item>
            <button [routerLink]="['/login']" nz-button nzType="primary" nzSize="large" nzBlock nzGhost>{{'landing.login' | translate}}</button>
          </nz-form-item>
        </form>
      </div>
    </div>
  </div>
</nz-layout>
