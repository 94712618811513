import { createAction, props } from "@ngrx/store";
import {
  AccessGrant,
  AgreementTerms,
  Conversation,
  MessagingMessage
} from "../../../api/messages/messages.types";
import { AccountFilter, NegotiationStatus } from "../../../pages/home-admin/home-admin-data/account.types";
import { PaginatedContent } from "../../../api/common.types";

export const MessagesAction = {
  loadConversationList: createAction("[Messages] Load conversation list", props<{ filter: AccountFilter, tab?: string }>()),
  loadConversationListSuccess: createAction(
    "[Messages][Success] Load conversation list",
    props<{ data: PaginatedContent<Conversation> }>(),
  ),
  loadConversationListError: createAction("[Messages][Error] Load conversation list", props<{ error: unknown }>()),

  //initiateConversation: createAction("[Messages] Initiate conversation", props<{ title: string }>()),
  initiateConversationSuccess: createAction("[Messages][Success] Initiate conversation", props<{ data: Conversation }>()),
  //initiateConversationError: createAction("[Messages][Error] Initiate conversation", props<{ error: unknown }>()),

  loadConversation: createAction("[Messages] Load conversation", props<{ id: string }>()),
  loadConversationSuccess: createAction("[Messages][Success] Load conversation", props<{ data: Conversation }>()),
  loadConversationError: createAction("[Messages][Error] Load conversation", props<{ error: unknown }>()),

  closeConversation:  createAction("[Messages] Close conversation", props<{ id: string }>()),
  closeConversationSuccess: createAction("[Messages][Success] Close conversation", props<{ id: string, success: boolean }>()),
  closeConversationError: createAction("[Messages][Error] Close conversation", props<{ error: unknown }>()),

  deleteConversation:  createAction("[Messages] Delete conversation", props<{ id: string }>()),
  deleteConversationSuccess: createAction("[Messages][Success] Delete conversation", props<{ id: string, success: boolean }>()),
  deleteConversationError: createAction("[Messages][Error] Delete conversation", props<{ error: unknown }>()),

  sendMessage: createAction("[Messages] Send message", props<{ message: MessagingMessage }>()),
  sendMessageSuccess: createAction("[Messages][Success] Send message", props<{ success: boolean }>()),
  sendMessageError: createAction("[Messages][Error] Send message", props<{ error: unknown }>()),

  receiveMessageSuccess: createAction("[Messages][Success] Receive message", props<{ message: MessagingMessage }>()),
  updateMessageVideo: createAction("[Messages] Update message video", props<{ message: Partial<MessagingMessage> }>()),

  increaseConversationMessageCount: createAction("[Messages] Increase conversation message count", props<{ message: MessagingMessage }>()),

  loadOlderMessages: createAction("[Messages] Load older messages", props<{conversationId: string, lastMsgId: string}>()),
  loadOlderMessagesSuccess: createAction("[Messages][Success] Load older messages", props<{ data: Array<MessagingMessage> }>()),
  loadOlderMessagesError: createAction("[Messages][Error] Load more older messages", props<{ error: unknown }>()),

  readMessages: createAction("[Messages] Read messages", props<{ids: Array<string>}>()),
  readMessagesSuccess: createAction("[Messages][Success] Read messages", props<{ ids: Array<string> }>()),
  readMessagesError: createAction("[Messages][Error] Read messages", props<{ error: unknown }>()),

  getUnseenMessageCount: createAction("[Messages] Get unseen message count"),
  getUnseenMessageCountSuccess: createAction("[Messages][Success] Get unseen message count", props<{ count: number }>()),
  getUnseenMessageCountError: createAction("[Messages][Error] Get unseen message count", props<{ error: unknown }>()),

  increaseUnseenMessageCount: createAction("[Messages] Increase unseen message count"),

  requestProfileAccess: createAction("[Messages] Request profile access", props<{talentId: string, conversationId: string}>()),
  requestProfileAccessSuccess: createAction("[Messages][Success] Request profile access", props<{ data: Partial<AccessGrant>, conversationId: string }>()),
  requestProfileAccessError: createAction("[Messages][Error] Request profile access", props<{ error: unknown }>()),

  grantProfileAccess: createAction("[Messages] Grant profile access", props<{missionPartnerId: string}>()),
  grantProfileAccessSuccess: createAction("[Messages][Success] Grant profile access", props<{ data: AccessGrant }>()),
  grantProfileAccessError: createAction("[Messages][Error] Grant profile access", props<{ error: unknown }>()),

  rejectProfileAccess: createAction("[Messages] Reject profile access", props<{missionPartnerId: string}>()),
  rejectProfileAccessSuccess: createAction("[Messages][Success] Reject profile access", props<{ data: AccessGrant }>()),
  rejectProfileAccessError: createAction("[Messages][Error] Reject profile access", props<{ error: unknown }>()),

  updateNegotiationStatus: createAction("[Messages] Update negotiation status", props<{status: NegotiationStatus}>()),

  proposeAgreementTerms: createAction("[Messages] Propose agreement terms", props<{conversationId: string, terms: AgreementTerms}>()),
  proposeAgreementTermsSuccess: createAction("[Messages][Success] Propose agreement terms", props<{ data: unknown }>()),
  proposeAgreementTermsError: createAction("[Messages][Error] Propose agreement terms", props<{ error: unknown }>()),

  respondToAgreementTerms: createAction("[Messages] Respond to agreement terms", props<{conversationId: string, accepted: boolean}>()),
  respondToAgreementTermsSuccess: createAction("[Messages][Success] Respond to agreement terms", props<{ data: unknown }>()),
  respondToAgreementTermsError: createAction("[Messages][Error] Respond to agreement terms", props<{ error: unknown }>()),

  updateAgreementTerms: createAction("[Messages] Update agreement terms", props<{ id: string }>()),
  updateAgreementTermsSuccess: createAction("[Messages][Success] Update agreement terms", props<{ data: Conversation }>()),
  updateAgreementTermsError: createAction("[Messages][Error] Update agreement terms", props<{ error: unknown }>()),
};
