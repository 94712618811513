import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import { Conversation, ConversationStatus, MessageType } from "../../../../api/messages/messages.types";
import { AccountFilter } from "../../../../pages/home-admin/home-admin-data/account.types";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { map, Observable, Subject, takeUntil, tap } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { SocketService } from "../../../../../../../core/src/lib/soket/socket.service";
import { MhNotificationType, MhSocketMessage } from "../../../../api/notification/notification.types";
import { Store } from "@ngrx/store";
import { messagesFeature, MessagesState } from "../../store/messages.reducer";
import { ActivatedRoute } from "@angular/router";
import { AccountType, LoginData } from "../../../../pages/login-page/login-page-data/login.types";
import { MessagesSerializer } from "../../../../api/messages/messages-serializer.service";

@Component({
  selector: "mh-messages-conversation-list",
  templateUrl: "./messages-conversation-list.component.html",
  styleUrls: ["./messages-conversation-list.component.less"],
})
export class MessagesConversationListComponent implements OnDestroy, OnInit {
  @Input() conversations!: PaginatedContent<Conversation>;
  @Input() loading = false;
  @Input() tabActive = true;
  @Output() filterChange = new EventEmitter<AccountFilter>();
  @Output() conversationClick = new EventEmitter<Conversation>();
  @Output() conversationClose = new EventEmitter<Conversation>();
  @Output() conversationDelete = new EventEmitter<Conversation>();

  loginData: LoginData;
  isMobileView$: Observable<boolean>;
  destroy$ = new Subject<void>();
  currentFilter?: AccountFilter;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private modal: NzModalService,
    private socketService: SocketService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<{ [messagesFeature]: MessagesState }>,
    private messagesSerializer: MessagesSerializer,
  ) {
    this.loginData = this.activatedRoute.snapshot.data["loginData"];
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap(() => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit() {
    if (this.tabActive) {
      this.socketService.messages$.pipe(takeUntil(this.destroy$)).subscribe((message: MhSocketMessage) => {
        if (message.action === MhNotificationType.COMMUNICATION_MESSAGE && message.message) {
          // this.store.dispatch(MessagesAction.increaseConversationMessageCount({message: this.messagesSerializer.deserializeMessagingMessage(message.message)}));
          this.onQueryChange(this.currentFilter as AccountFilter);
        }
        if (
          (message.action === MhNotificationType.CONVERSATION_STATUS_UPDATED && message.conversationId) ||
          (message.action === MhNotificationType.COMMUNICATION_MESSAGE &&
            message.message?.messageType === MessageType.SYSTEM_MESSAGE)
        ) {
          this.onQueryChange(this.currentFilter as AccountFilter);
        }
      });
    }
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;

    const { pageSize, pageIndex, sort } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      searchTerm: "",
      filters: [],
      groupFilters: [],
    };

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == "ascend" ? "ASC" : "DESC",
            field: sortField,
          },
        ],
      };
    }

    this.onQueryChange(change);
  }

  onQueryChange(filter: AccountFilter) {
    this.currentFilter = filter;
    this.filterChange.emit(filter);
  }

  onConversationClick(conversation: Conversation) {
    this.conversationClick.emit(conversation);
  }
  onConversationClose(event: Event, conversation: Conversation) {
    event.stopPropagation();
    this.modal.confirm({
      nzTitle: this.translateService.instant("inbox.confirm.close.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.conversationClose.emit(conversation),
    });
  }
  onConversationDelete(event: Event, conversation: Conversation) {
    event.stopPropagation();
    this.modal.confirm({
      nzTitle: this.translateService.instant("inbox.confirm.delete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.conversationDelete.emit(conversation),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly ConversationStatus = ConversationStatus;
  protected readonly AccountType = AccountType;
}
