import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LocationMultipleInputComponent } from "./location-multiple-input.component";
import { LocationInputModule } from "../location-input/location-input.module";
import { ExplanationLabelModule } from "../explanation-label/explanation-label.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [LocationMultipleInputComponent],
  imports: [
    CommonModule,
    LocationInputModule,
    ExplanationLabelModule,
    FormsModule,
    NzFormModule,
    NzGridModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    NzWaveModule,
    TranslateModule,
  ],
  exports: [LocationMultipleInputComponent],
})
export class LocationMultipleInputModule {}
