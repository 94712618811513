export interface NavItem {
  icon: string;
  nameKey: string;
  routerLink: Array<string>;
  hidden?: boolean;
  badgeType?: "unseenMessages" | "profileFilledOut" | undefined;
  queryParams?: {
    tab: string;
  };
}

export interface ForwardPage {
  page: PageName;
  pageParams: { [key: string]: string };
}

export enum PageName {
  TALENT_DASHBOARD = "TALENT_DASHBOARD",
  TALENT_INBOX = "TALENT_INBOX",
  TALENT_INBOX_CONVERSATION = "TALENT_INBOX_CONVERSATION",
  TALENT_PROFILE = "TALENT_PROFILE",
  TALENT_MISSION_PARTNERS = "TALENT_MISSION_PARTNERS",
  TALENT_REFERENCES = "TALENT_REFERENCES",
  MP_DASHBOARD = "MP_DASHBOARD",
  MP_JOB_OFFERS = "MP_JOB_OFFERS",
  MP_JOB_OFFER_MATCHES = "MP_JOB_OFFER_MATCHES",
  MP_VIEW_TALENT = "MP_VIEW_TALENT",
  MP_INBOX = "MP_INBOX",
  MP_INBOX_CONVERSATION = "MP_INBOX_CONVERSATION",
  MP_PROFILE = "MP_PROFILE",
  MP_ASSESSMENT = "MP_ASSESSMENT",
  MP_MICRO_SITE = "MP_MICRO_SITE",
  MP_INVOICES = "MP_INVOICES",
  MP_TESTIMONIALS = "MP_TESTIMONIALS",
}
export enum PageParam {
  CONVERSATION_ID = "CONVERSATION_ID",
  JOB_OFFER_ID = "JOB_OFFER_ID",
  MISSION_PARTNER_ID = "MISSION_PARTNER_ID",
  TALENT_ID = "TALENT_ID",
  OPEN_CONVERSATION = "OPEN_CONVERSATION",
}
