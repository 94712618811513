import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentSettingsNewsletterComponent } from "./talent-settings-newsletter.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzFormModule } from "ng-zorro-antd/form";
import { ReactiveFormsModule } from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";

@NgModule({
  declarations: [TalentSettingsNewsletterComponent],
  imports: [CommonModule, NzGridModule, NzFormModule, ReactiveFormsModule, TranslateModule, NzCheckboxModule],
  exports: [TalentSettingsNewsletterComponent],
})
export class TalentSettingsNewsletterModule {}
