import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OpenDialogComponent } from "@momhunting/common-ui";
import { ReviewParsedCvDialogComponent } from "./review-parsed-cv-dialog/review-parsed-cv-dialog.component";
import { ParsedCVResolver } from "./review-parsed-cv-dialog/parsed-cv.resolver";
import { TalentProfileResolver } from "../api/profile/talent-profile.resolver";

const routes: Routes = [
  {
    path: "parsed-cv",
    component: OpenDialogComponent,
    data: {
      component: ReviewParsedCvDialogComponent,
      width: 800,
    },
    resolve: {
      parsedCV: ParsedCVResolver,
      talent: TalentProfileResolver,
    },
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OverlayRoutingModule {}
