<div class="password-tip-box" [class.password-tip-box--bg]="showBackground">
  <div class="password-tip"
       [class.password-tip--error]="passwordControl.hasError('upperCaseRequired') || (!passwordControl.value && passwordControl.dirty)"
       [class.password-tip--success]="!passwordControl.hasError('upperCaseRequired') && passwordControl.value"
  >
    <span nz-icon [nzType]="passwordControl.hasError('upperCaseRequired') || !passwordControl.value ? 'close' : 'check-circle'" nzTheme="outline"></span>
    {{'password-tip.capital-letter' | translate}}
  </div>
  <div class="password-tip"
       [class.password-tip--error]="passwordControl.hasError('numberRequired') || (!passwordControl.value && passwordControl.dirty)"
       [class.password-tip--success]="!passwordControl.hasError('numberRequired') && passwordControl.value"
  >
    <span nz-icon [nzType]="passwordControl.hasError('numberRequired') || !passwordControl.value ? 'close' : 'check-circle'" nzTheme="outline"></span>
    {{'password-tip.number' | translate}}
  </div>
  <div class="password-tip"
       [class.password-tip--error]="passwordControl.hasError('specialCharacterRequired') || (!passwordControl.value && passwordControl.dirty)"
       [class.password-tip--success]="!passwordControl.hasError('specialCharacterRequired') && passwordControl.value"
  >
    <span nz-icon [nzType]="passwordControl.hasError('specialCharacterRequired') || !passwordControl.value ? 'close' : 'check-circle'" nzTheme="outline"></span>
    {{'password-tip.special-character' | translate}}
  </div>
  <div class="password-tip"
       [class.password-tip--error]="passwordControl.hasError('minlength') || (!passwordControl.value && passwordControl.dirty)"
       [class.password-tip--success]="!passwordControl.hasError('minlength') && passwordControl.value"
  >
    <span nz-icon [nzType]="passwordControl.hasError('minlength') || !passwordControl.value ? 'close' : 'check-circle'" nzTheme="outline"></span>
    {{'password-tip.min-length' | translate}}
  </div>
</div>
