<div class="card-list card-list--yellow">
  <div class="card-list__item">
  <div class="card-list__body">
    <div nz-row>
      <div nz-col [nzSpan]="24" style="margin-top: -10px">
        <p>{{'profile.track-record-old.info' | translate}}</p>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="24" [nzMd]="8">
        <h4 class="card-list__item-label">{{'profile.sector.label' | translate}}</h4>
        <p class="card-list__item-value">
          <ng-container *ngIf="oldTrackRecord?.sector?.length; else noValueSpecified">
            <nz-tag *ngFor="let option of oldTrackRecord.sector">{{option.name | translate}}</nz-tag>
          </ng-container>
        </p>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="8">
        <h4 class="card-list__item-label">{{'profile.industry.label' | translate}}</h4>
        <p class="card-list__item-value">
          <ng-container *ngIf="oldTrackRecord?.industry?.length; else noValueSpecified">
            <nz-tag *ngFor="let option of oldTrackRecord.industry">{{option.name | translate}}</nz-tag>
          </ng-container>
        </p>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="8">
        <h4 class="card-list__item-label">{{'profile.department.label' | translate}}</h4>
        <p class="card-list__item-value">
          <ng-container *ngIf="oldTrackRecord?.department?.length; else noValueSpecified">
            <nz-tag *ngFor="let option of oldTrackRecord.department">{{option.name | translate}}</nz-tag>
          </ng-container>
        </p>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="8">
        <h4 class="card-list__item-label">{{'profile.job-title.label' | translate}}</h4>
        <p class="card-list__item-value">
          <ng-container *ngIf="oldTrackRecord?.jobTitle; else noValueSpecified">
            {{oldTrackRecord.jobTitle}}
          </ng-container>
        </p>
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="8">
        <h4 class="card-list__item-label">{{'profile.company-name.label' | translate}}</h4>
        <p class="card-list__item-value">
          <ng-container *ngIf="oldTrackRecord?.companyName; else noValueSpecified">
            {{oldTrackRecord.companyName}}
          </ng-container>
        </p>
      </div>
    </div>
  </div>
  <div class="card-list__actions">
    <button nz-button nzType="link" nzDanger
            (click)="onDeleteTrackRecord()">
      <span nz-icon nzType="delete"></span>
    </button>
  </div>
</div>
</div>
<ng-template #noValueSpecified>-</ng-template>


