import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { AssessmentService } from "../../../../api/assessment/assessment.service";
import { StrictEmailValidator } from "@momhunting/core";
import { MaturityAssessmentForm } from "../../../../api/assessment/assessment.types";

@Component({
  selector: "mh-assessment-setup-form",
  templateUrl: "./assessment-setup-form.component.html",
  styleUrls: ["./assessment-setup-form.component.less"],
})
export class AssessmentSetupFormComponent implements OnInit, OnDestroy {
  @Input() token!: string;
  @Output() setupResponse = new EventEmitter<MaturityAssessmentForm>();
  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private assessmentService: AssessmentService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      companyName: [undefined, [Validators.required]],
      position: [undefined, [Validators.required]],
      email: [undefined, [Validators.required, StrictEmailValidator]],
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.assessmentService
      .externalFormSetUp({
        token: this.token,
        ...this.formGroup.value,
      })
      .subscribe((response: MaturityAssessmentForm) => this.setupResponse.emit(response));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
