import { Component, Input } from "@angular/core";
import { Reference, ReferenceRequest } from "../../../api/references/references.types";
import { AccountType } from "../../../pages/login-page/login-page-data/login.types";

@Component({
  selector: "mh-profile-preview-references",
  templateUrl: "./profile-preview-references.component.html",
  styleUrls: ["./profile-preview-references.component.less"],
})
export class ProfilePreviewReferencesComponent {
  @Input() trustedView = false;
  @Input() references: Array<Reference> = [];
  @Input() referencesRequest: Array<ReferenceRequest> = [];
  @Input() viewerAccountType?: AccountType;

  protected readonly AccountType = AccountType;
}
