import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {CmsNameValue, compareCmsNameValue} from "../../../../api/cms/cms.types";
import {CmsCachedService} from "../../../../api/cms/cms-cached.service";
import {MissionPartner} from "../../../../api/sign-up/signup.types";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {flattenControls} from "@momhunting/common-ui";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "mh-signup-mission-partner-details",
  templateUrl: "./signup-mission-partner-details.component.html",
  styleUrls: ["./signup-mission-partner-details.component.less"],
})
export class SignupMissionPartnerDetailsComponent implements OnInit, OnDestroy {
  @Input() profile!: MissionPartner;
  @Output() formChange = new EventEmitter<MissionPartner>();
  @Output() updateUser = new EventEmitter<void>();

  private readonly destroy$ = new Subject<void>();
  loading = false;
  public formGroup!: FormGroup;

  public industry$: Observable<Array<CmsNameValue>>;
  public sector$: Observable<Array<CmsNameValue>>;
  public companyType$: Observable<Array<CmsNameValue>>;
  public numberOfEmployees$: Observable<Array<CmsNameValue>>;
  public companyHeadquarters$: Observable<Array<CmsNameValue>>;

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant('field.required.label'),
      invalid: this.translateService.instant('field.invalid.label'),
    },
  };

  get showHeadQuartersTextControl(): boolean {
    const headQuartersControl = this.formGroup.controls["companyOverview"].get("companyHeadquarters");
    return (headQuartersControl?.value as CmsNameValue)?.value == 6;
  }

  constructor(private cmsCachedService: CmsCachedService, private fb: FormBuilder, private translateService: TranslateService) {
    this.industry$ = this.cmsCachedService.getUserDataByName("industry");
    this.sector$ = this.cmsCachedService.getUserDataByName("sector");
    this.companyType$ = this.cmsCachedService.getUserDataByName("company-type");
    this.numberOfEmployees$ = this.cmsCachedService.getUserDataByName("number-of-employees", false);
    this.companyHeadquarters$ = this.cmsCachedService.getUserDataByName("company-headquarters", false);
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      companyOverview: this.fb.group({
        numberOfEmployees: [this.profile.companyOverview?.numberOfEmployees, [Validators.required]],
        companyIndustry: [this.profile.companyOverview?.companyIndustry, [Validators.required]],
        companySector: [this.profile.companyOverview?.companySector, [Validators.required]],
        companyType: [this.profile.companyOverview?.companyType, [Validators.required]],
        companyHeadquarters: [this.profile.companyOverview?.companyHeadquarters, [Validators.required]],
        companyHeadquartersText: [this.profile.companyOverview?.companyHeadquartersText, []],
      }),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.profile,
        ...value,
        companyOverview: {
          ...this.profile.companyOverview,
          ...value.companyOverview,
        },
      }),
    );
  }

  submitForm() {
    if (this.formGroup.invalid) {
      flattenControls(this.formGroup).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.updateUser.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
