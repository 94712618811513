import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentProfilePreviewComponent } from "./talent-profile-preview.component";
import { ProfilePreviewModule } from "../../../../common-componnets/profile-view/profile-preview.module";

@NgModule({
  declarations: [TalentProfilePreviewComponent],
  imports: [
    CommonModule,
    ProfilePreviewModule,
  ],
})
export class TalentProfilePreviewModule {}
