<nz-page-header [nzTitle]="'mp.diversities.title' | translate"></nz-page-header>

<form nz-form [formGroup]="formGroup" nzLayout="vertical" class="profile-form">
  <div *ngIf="!hasReviewedStatus">
    <div class="overlay">
      <div class="overlay-content">
        <h3 class="overlay-title">{{ "mp.reviewed-conversion.title" | translate }}</h3>
        <p class="overlay-text">{{ "mp.reviewed-conversion.text" | translate }}</p>
      </div>
    </div>
  </div>

  <h3>{{ "profile.diversity.subtitle" | translate }}</h3>
  <h4>{{ "profile.diversity.text" | translate }}</h4>

  <div nz-row [nzGutter]="24" formGroupName="complianceKpi">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.work-assignment.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzSize="default"
            nzMode="multiple"
            formControlName="kpiPossibleWorkingAssignments"
            [compareWith]="compareCmsNameValue"
          >
            <nz-option
              [nzLabel]="option.name | translate"
              [nzValue]="option"
              *ngFor="let option of workAssignmentOptions$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.part-time-leadership.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select nzSize="default" formControlName="kpiPercentagePartTimeLeadership" nzShowSearch>
            <nz-option [nzLabel]="'kpi-percentage.unknown' | translate" [nzValue]="-1"></nz-option>
            <nz-option
              *ngFor="let item of [].constructor(100); let i = index"
              [nzLabel]="(i + 1) + ' %'"
              [nzValue]="i + 1"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.compliance-kpi.female-leadership.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <nz-select nzSize="default" formControlName="kpiPercentageFemaleLeadership" nzShowSearch>
            <nz-option [nzLabel]="'kpi-percentage.unknown' | translate" [nzValue]="-1"></nz-option>
            <nz-option
              *ngFor="let item of [].constructor(100); let i = index"
              [nzLabel]="(i + 1) + ' %'"
              [nzValue]="i + 1"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24" formGroupName="diversities">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-1.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity1" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-2.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity2" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-3.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity3" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-4.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity4" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-5.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity5" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-6.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity6" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-7.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity7" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-8.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity8" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-9.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity9" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>
          <mh-explanation-label [labelKey]="'profile.diversity.question-10.label'"></mh-explanation-label>
        </nz-form-label>
        <nz-form-control>
          <input nz-input nzSize="default" formControlName="diversity10" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="24" class="profile-form-buttons">
      <button nz-button [nzType]="'primary'" [disabled]="!hasReviewedStatus" (click)="submitForm()">
        {{ "save-and-next.button" | translate }}
      </button>
    </div>
  </div>
</form>
