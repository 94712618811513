import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { catchError, EMPTY, Subject } from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import { LoginService } from "./login-page-data/login.service";
import { AccountType, LoginData } from "./login-page-data/login.types";
import {APP_CONFIGURATION} from "../../../../../core/src/lib/core.di";
import {AppConfig} from "../../../../../core/src/lib/app-config/config.types";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {CmsFeatureStatus} from "../../api/cms/cms.types";

@Component({
  selector: "mh-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.less"],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public formGroup!: FormGroup;
  public errorResponse: string | undefined | any = "";
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly loginService: LoginService,
              private readonly router: Router,
              @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
              private titleService: Title,
              private translateService: TranslateService,
  ) {
    this.titleService.setTitle(this.translateService.instant('page.title', {pageName: this.translateService.instant('login.title')}));
  }

  get login(): FormControl {
    return this.formGroup.get("login") as FormControl;
  }
  get password(): FormControl {
    return this.formGroup.get("password") as FormControl;
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      login: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    });
  }

  public onLoginFormSubmit() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    this.loginService
      .login(this.formGroup.value)
      .pipe(
        catchError((err) => {
          this.errorResponse = err;
          return EMPTY;
        }),
      )
      .subscribe((response: LoginData) => {
        if (response.token) {
          this.loginService.loginSuccess(response);
          this.navigateByType(response.accountType);
          //this.errorResponse = null;
        } else {
          //this.errorResponse = response.errorKey ?? response.message;
        }
      });
  }

  private navigateByType(type: AccountType | undefined) {
    if (type === AccountType.MISSION_PARTNER) {
      this.router.navigate(["/mission-partner"]);
    }
    if (type === AccountType.TALENT) {
      this.router.navigate(["/talent"]);
    }
    if (type === AccountType.ADMIN) {
      this.router.navigate(["/admin"]);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
