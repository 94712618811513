import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ExpiredTokenComponent} from "./expired-token.component";
import {TranslateModule} from "@ngx-translate/core";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzFormModule} from "ng-zorro-antd/form";
import {RouterLink} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";

@NgModule({
  declarations: [ExpiredTokenComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzLayoutModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    RouterLink,
  ],
  exports: [ExpiredTokenComponent],
})
export class ExpiredTokenModule {
}
