import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentInboxComponent } from "./talent-inbox.component";
import { MessagesInboxModule } from "../../../../common-componnets/messages/messages-inbox/messages-inbox.module";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { TalentInboxRoutingModule } from "./talent-inbox-routing.module";
import { TalentConversationModule } from "./subfolder/talent-conversation/talent-conversation.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [TalentInboxComponent],
  imports: [
    CommonModule,
    TalentInboxRoutingModule,
    TalentConversationModule,
    MessagesInboxModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzPageHeaderModule,
    NzSpaceModule,
    TranslateModule,
  ],
})
export class TalentInboxModule {}
