import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AuthorizationService } from "../../api/authorization/authorization.service";
import { filter, map, Observable, Subject, takeUntil, tap } from "rxjs";
import { LoginData } from "../login-page/login-page-data/login.types";
import { LoginService } from "../login-page/login-page-data/login.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DebugService } from "../debug/debug.service";
import { NavItem } from "../../common-componnets/common-types";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { selectTalentProfileCompleteness } from "../../api/profile/store/talent-profile.selectors";
import { TalentProfileCompleteness } from "../../api/dashboard/dashboard.types";
import { Store } from "@ngrx/store";
import { talentProfileFeatureName, TalentProfileState } from "../../api/profile/store/talent-profile.reducer";
import { APP_CONFIGURATION } from "../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../core/src/lib/app-config/config.types";

@Component({
  selector: "mh-home-talent",
  templateUrl: "./home-talent.component.html",
  styleUrls: ["./home-talent.component.less"],
})
export class HomeTalentComponent implements OnInit, OnDestroy {
  user$!: Observable<LoginData>;
  isMobileView$: Observable<boolean>;
  destroy$ = new Subject<void>();
  profileCompleteness?: TalentProfileCompleteness;
  navigationBar: Array<NavItem> = [];
  navigationDropdown: Array<NavItem> = [];
  navigation: Array<NavItem> = [];
  currentNavItem: NavItem;

  height: number = document.documentElement.clientHeight;
  state = {
    open: false,
  };

  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private readonly store: Store<{ [talentProfileFeatureName]: TalentProfileState }>,
    private loginService: LoginService,
    private authorizationService: AuthorizationService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private debugService: DebugService,
    private titleService: Title,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.user$ = this.authorizationService.getAuthorizedUser();
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        tap((event) => {
          this.handleRouteChange(event as NavigationEnd);
        }),
      )
      .subscribe();
    this.navigationBar = [
      { icon: "appstore", nameKey: "navi.dashboard.label", routerLink: ["dashboard"] },
      {
        icon: "inbox",
        nameKey: "navi.inbox.label",
        routerLink: ["inbox"],
        hidden: !this.appConfig.featureStatus.mpForTalent,
        badgeType: "unseenMessages",
      },
      { icon: "user", nameKey: "talent.my-profile.label", routerLink: ["profile"], badgeType: "profileFilledOut" },
      {
        icon: "eye",
        nameKey: "talent.my-visits.label",
        routerLink: ["visits"],
      },
      {
        icon: "rocket",
        nameKey: "talent.mission-partners.label",
        routerLink: ["mission-partners"],
        hidden: !this.appConfig.featureStatus.mpForTalent,
      },
      { icon: "trophy", nameKey: "talent.references.label", routerLink: ["references"] },
    ];
    this.navigationDropdown = [
      { icon: "", nameKey: "navi.settings.label", routerLink: ["settings"] },
      { icon: "", nameKey: "debug.label", routerLink: ["debug"], hidden: this.debugService.isProduction() },
      {
        icon: "",
        nameKey: "navi.notification-settings.label",
        routerLink: ["notifications"],
        hidden: !this.appConfig.featureStatus.mpForTalent,
      },
    ];
    this.navigation = [...this.navigationBar, ...this.navigationDropdown];
    this.currentNavItem = this.navigation[0];
  }

  ngOnInit() {
    this.store
      .select(selectTalentProfileCompleteness)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profileCompleteness) => (this.profileCompleteness = profileCompleteness));
  }

  onLogout() {
    this.loginService.logout();
  }

  toggleDrawer(navItem?: NavItem) {
    if (navItem) this.currentNavItem = navItem;
    this.state.open = !this.state.open;
  }

  handleRouteChange(event: NavigationEnd) {
    const activeRouteUrl = event.url;
    this.navigation.forEach((navItem) => {
      if (activeRouteUrl.includes(navItem.routerLink.join("/"))) {
        this.currentNavItem = navItem;
        this.titleService.setTitle(
          this.translateService.instant("page.title", { pageName: this.translateService.instant(navItem.nameKey) }),
        );
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
