import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlatformPagesRoutingModule } from "./platform-pages-routing.module";
import { LandingPageModule } from "./pages/landing-page/landing-page.module";
import { LoginPageModule } from "./pages/login-page/login-page.module";
import { RegisterTalentModule } from "./pages/register-talent/register-talent.module";
import { RegisterMissionPartnerModule } from "./pages/register-mission-partner/register-mission-partner.module";
import { PasswordForgottenModule } from "./pages/password-forgotten/password-forgotten.module";
import { PasswordResetModule } from "./pages/password-reset/password-reset.module";
import { ExpiredTokenModule } from "./pages/expired-token/expired-token.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { loginFeatureName, loginReducer } from "./pages/login-page/login-page-data/store/login.reducer";
import { LoginEffects } from "./pages/login-page/login-page-data/store/login.effects";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./api/authorization/token-interceptor";
import { ContentPageModule } from "./pages/content-page/content-page.module";
import { AdminTalentViewModule } from "./pages/home-admin/subpages/admin-talent-view/admin-talent-view.module";
import { AccountDeletedModule } from "./pages/account-deleted/account-deleted.module";
import { AccountConfirmedModule } from "./pages/account-confirmed/account-confirmed.module";
import { MakeReferenceModule } from "./pages/make-reference/make-reference.module";
import { MakeTestimonialsModule } from "./pages/make-testimonials/make-testimonials.module";
import { TalentProfileStoreModule } from "./api/profile/store/talent-profile-store.module";
import { MessagesDataModule } from "./common-componnets/messages/messages-data.module";
import { MissionPartnerProfileStoreModule } from "./api/profile/store/mission-partner-profile-store.module";
import {NgxGoogleAnalyticsModule, provideGoogleAnalytics, provideGoogleAnalyticsRouter} from '@hakimio/ngx-google-analytics';
import {
  ExternalAssessmentQuestionerModule
} from "./pages/external-assessment-pages/external-assessment-questioner.module";

@NgModule({
  imports: [
    CommonModule,
    PlatformPagesRoutingModule,
    TalentProfileStoreModule,
    MissionPartnerProfileStoreModule,
    LandingPageModule,
    LoginPageModule,
    RegisterTalentModule,
    RegisterMissionPartnerModule,
    PasswordForgottenModule,
    PasswordResetModule,
    ExpiredTokenModule,
    MakeReferenceModule,
    MakeTestimonialsModule,
    ContentPageModule,
    AdminTalentViewModule,
    AccountDeletedModule,
    AccountConfirmedModule,
    StoreModule.forFeature(loginFeatureName, loginReducer),
    EffectsModule.forFeature([LoginEffects]),
    MessagesDataModule,
    ExternalAssessmentQuestionerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideGoogleAnalyticsRouter({ exclude: [/^\/admin\/.*/] })
  ],
  declarations: [],
})
export class PlatformPagesModule {}
