import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { compareCmsNameValue } from "../../../../../api/cms/cms.types";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MissionPartnerAccount } from "../../../home-admin-data/account.types";
import { Invoice } from "../../../../../api/invoices/invoices.types";
import { NzUploadFile } from "ng-zorro-antd/upload";

@Component({
  selector: "mh-invoice-form",
  templateUrl: "./invoice-form.component.html",
  styleUrls: ["./invoice-form.component.less"],
})
export class InvoiceFormComponent implements OnInit, OnDestroy {
  @Input() invoice!: Invoice;
  @Input() missionPartners!: Array<MissionPartnerAccount>;
  @Input() missionPartnerEditable = false;
  @Output() formChange = new EventEmitter<Invoice>();

  fileList: NzUploadFile[] = [];

  get uploadDisabled(): boolean {
    return this.fileList.length > 0;
  }

  formGroup!: UntypedFormGroup;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      missionPartnerId: [this.invoice?.missionPartnerId, [Validators.required]],
      date: [this.invoice?.date, [Validators.required]],
      number: [this.invoice?.number, [Validators.required]],
      performanceMonth: [this.invoice?.performanceMonth, [Validators.required]],
      amount: [this.invoice?.amount, [Validators.required]],
      invoiceFileId: [this.invoice?.invoiceFileId, []],
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.formChange.next({...this.invoice, ...value});
    });
  }

  isValid(): boolean {
    if (!this.formGroup.valid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  protected readonly compareCmsNameValue = compareCmsNameValue;
}
