<ng-container *nzModalTitle>{{'modal.cv-uploaded.title' | translate}}</ng-container>

<p>{{'modal.cv-uploaded.info' | translate}}</p>
<div class="content-image">
  <img nz-image [alt]="'modal.cv-uploaded.img.alt' | translate" width="788" height="278" [ngSrc]="'/assets/images/cv_notification.jpg'"/>
</div>

<ng-container *nzModalFooter>
  <button nz-button nzType="primary" (click)="handleOk()">{{'ok.button' | translate}}</button>
</ng-container>
