<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>
<ng-template #desktopView>
  <nz-page-header class="large p-l-8 p-r-0">
    <nz-page-header-title>{{'invoices.title' | translate}}</nz-page-header-title>
  </nz-page-header>

  <div class="invoices">
    <nz-table
      #basicTable
      [nzData]="invoiceList || []"
      nzShowSizeChanger="true"
      nzSize="small"
      nzFrontPagination="false"
      [nzLoading]="loading"
    >
      <thead>
      <tr>
        <th>{{'admin.invoices.date.title' | translate}}</th>
        <th>{{'admin.invoices.number.title' | translate}}</th>
        <th>{{'admin.invoices.performance-month.title' | translate}}</th>
        <th [nzAlign]="'right'">{{'admin.invoices.amount.title' | translate}}</th>
        <th [nzWidth]="'160px'">{{'admin.invoices.status.title' | translate}}</th>
        <th>{{'admin.action.title' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td>{{ data.date | date : "dd.MM.yyyy" }}</td>
        <td>{{ data.number }}</td>
        <td>{{ data.performanceMonth | date : "MMM yyyy" }}</td>
        <td [nzAlign]="'right'">{{ data.amount | currency : "EUR" }}</td>
        <td>
          <nz-select
            *ngIf="!(data.invoiceStatus === 'DONE')"
            [ngModel]="data.invoiceStatus"
            (ngModelChange)="onChangeStatus($event, data.id)"
            class="invoices__status-select"
          >
            <nz-option [nzLabel]="'invoices.status.new' | translate" nzValue="NEW"></nz-option>
            <nz-option [nzLabel]="'invoices.status.received' | translate" nzValue="RECEIVED"></nz-option>
          </nz-select>
          <div *ngIf="data.invoiceStatus === 'DONE'">
            {{'invoices.status.done' | translate}}
          </div>
        </td>
        <td [nzAlign]="'right'" class="nowrap">
          <a
            *ngIf="data.invoiceFile"
            nz-button
            nzType="link"
            nzSize="small"
            [nzTooltipTitle]="'admin.download-invoice.tooltip' | translate"
            nzTooltipPlacement="top"
            nz-tooltip
            [href]="appConfig.siteUrl + '/' + data.invoiceFile.fileUrl"
            target="_blank"
          >
            <span nz-icon nzType="download" nzTheme="outline"></span>
          </a>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>

<ng-template #mobileView>
  <div nz-row [nzGutter]="[24, 24]" class="invoices-mobile">
    <div nz-col [nzSpan]="24" *ngFor="let invoice of invoiceList">
      <div nz-row [nzGutter]="[12, 12]" class="invoices-mobile__item">
        <div nz-col [nzSpan]="12">
          <div class="invoices-mobile__item-label">{{'admin.invoices.date.title' | translate}}</div>
          <div class="invoices-mobile__item-value">{{ invoice.date | date : "dd.MM.yyyy" }}</div>
        </div>
        <div nz-col [nzSpan]="12">
          <div class="invoices-mobile__item-label">{{'admin.invoices.number.title' | translate}}</div>
          <div class="invoices-mobile__item-value">{{ invoice.number }}</div>
        </div>
        <div nz-col [nzSpan]="12">
          <div class="invoices-mobile__item-label">{{'admin.invoices.performance-month.title' | translate}}</div>
          <div class="invoices-mobile__item-value">{{ invoice.performanceMonth | date : "MMM yyyy" }}</div>
        </div>
        <div nz-col [nzSpan]="12">
          <div class="invoices-mobile__item-label">{{'admin.invoices.amount.title' | translate}}</div>
          <div class="invoices-mobile__item-value">{{ invoice.amount | currency : "EUR" }}</div>
        </div>
        <div nz-col [nzSpan]="24">
          <div class="invoices-mobile__item-label">{{'admin.invoices.status.title' | translate}}</div>
          <nz-select
            *ngIf="!(invoice.invoiceStatus === 'DONE')"
            [ngModel]="invoice.invoiceStatus"
            (ngModelChange)="onChangeStatus($event, invoice.id)"
            class="invoices-mobile__status-select"
          >
            <nz-option [nzLabel]="'invoices.status.new' | translate" nzValue="NEW"></nz-option>
            <nz-option [nzLabel]="'invoices.status.received' | translate" nzValue="RECEIVED"></nz-option>
          </nz-select>
          <div *ngIf="invoice.invoiceStatus === 'DONE'" class="invoices-mobile__item-value">
            {{'invoices.status.done' | translate}}
          </div>
        </div>
        <a
          class="invoices-mobile__link"
          *ngIf="invoice.invoiceFile"
          nz-button
          nzType="link"
          nzSize="small"
          [nzTooltipTitle]="'admin.download-invoice.tooltip' | translate"
          nzTooltipPlacement="top"
          nz-tooltip
          [href]="appConfig.siteUrl + '/' + invoice.invoiceFile.fileUrl"
          target="_blank"
        >
          <span nz-icon nzType="download" nzTheme="outline"></span>
        </a>
      </div>
    </div>
  </div>
</ng-template>
