import { Component } from "@angular/core";

@Component({
  selector: "mh-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent {
  title = "Platform";
}
