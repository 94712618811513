import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { TestimonialsService } from "./testimonials.service";
import { Testimonial } from "./testimonials.types";

@Injectable({
  providedIn: "root",
})
export class TestimonialsResolver {
  constructor(private readonly testimonialsService: TestimonialsService) {}

  resolve(): Observable<Array<Testimonial>> {
    return this.testimonialsService.loadTestimonials();
  }
}
