export interface CmsNameValue {
  name: string;
  value: number;
  position?: number;
}

export const compareCmsNameValue = (o1: CmsNameValue, o2: CmsNameValue): boolean =>
  o1 && o2 ? o1.value === o2.value : o1 === o2;

export interface CmsFeatureStatus {
  communityLink: boolean;
  profilePreviewLink: boolean;
  englishSite: boolean;
  mpForMp: boolean;
  mpForTalent: boolean;
  showKeysSwitch: boolean;
}

export interface CmsFeature {
  name: CmsFeatureType;
  status: boolean;
}

export enum CmsFeatureType {
  COMMUNITY_LINK = "CommunityLink",
  ENGLISH_SITE = "EnglishSite",
  MP_FOR_MISSION_PARTNER = "MP_for_MissionPartner",
  MP_FOR_TALENT = "MP_for_Talent",
  PROFILE_PREVIEW_LINK = "ProfilePreviewLink",
  SHOW_KEYS_SWITCH = "ShowKeysSwitch",
}
