<div class="container">
  <div nz-row [nzGutter]="[0, 30]">
    <div nz-col [nzSpan]="24">
      <mh-micro-site
        *ngIf="missionPartner"
        [missionPartner]="missionPartner"
        [viewerAccountType]="AccountType.TALENT"
        [conversationId]="conversationId"
        [testimonials]="testimonials"
        (startConversation)="openCreateConversationModal()"
      ></mh-micro-site>
    </div>
  </div>
</div>
