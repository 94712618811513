<nz-page-header class="large p-r-0">
  <nz-page-header-title>{{ "visit.title" | translate }}</nz-page-header-title>
</nz-page-header>

<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>

<ng-template #desktopView>
  <div nz-col [nzSpan]="24">
    <nz-table
      class="matches-list conversation-list-table"
      nzSize="small"
      nzShowSizeChanger="true"
      nzFrontPagination="false"
      [nzData]="visitsMissionPartnerList || []"
      [nzLoading]="loading"
    >
      <thead>
        <tr>
          <th nzWidth="30px">
            <button
              [class.ant-table-row-expand-icon-expanded]="allExpanded"
              [class.ant-table-row-expand-icon-collapsed]="!allExpanded"
              class="ant-table-row-expand-icon"
              (click)="onAllExpandChange()"
            >
            <span></span>
          </button>
          </th>
          <th>{{ "visit.talent-name.label" | translate }}</th>
          <th>{{ "visit.date-of-last-visit.label" | translate }}</th>
          <th>{{ "visit.number-of-visits.label" | translate }}</th>
          <th nzWidth="30px">{{ "visit.actions.label" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let visit of visitsMissionPartnerList">
          <tr>
            <td
              [nzExpand]="expandSet.has(visit.talentId)"
              (nzExpandChange)="onExpandChange(visit.talentId, $event)"
            ></td>
            <td>
              <div class="cell-content">
                <div class="contact" [routerLink]="['/mission-partner/view-talent/', visit.talentId]">
                  <nz-avatar class="mh-avatar" [nzSrc]="visit.recipientInfo.avatarUrl" [nzSize]="60"></nz-avatar>
                  <div class="contact-name">
                    {{ visit.recipientInfo.name }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              {{ visit.visitTimestamp | date : "dd.MM.YYYY HH:mm" }}
            </td>
            <td>
              {{ visit.visitCount }}
            </td>
            <td>
              <button
                *ngIf="visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.send-btn.label' | translate"
                [routerLink]="['/mission-partner/inbox/conversation/', visit.conversationId]"
                [queryParams]="{ origin: 'mission-partner-my-visits' }"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
              <button
                *ngIf="!visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.start-conversation.label' | translate"
                (click)="openCreateConversationModal($event, visit)"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
            </td>
          </tr>
          <tr *ngIf="expandSet.has(visit.talentId)" class="expanded-row">
            <td colspan="5">
              <div class="expanded-content">
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.salary.label" | translate }}</div>
                  <div>{{ visit.recipientInfo.salaryMin }} - {{ visit.recipientInfo.salaryMax }}</div>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.salary.label" | translate }}</div>
                  <ng-container *ngIf="visit.recipientInfo.domainExpertises">
                    <mh-tags-output [value]="visit.recipientInfo.domainExpertises"></mh-tags-output>
                  </ng-container>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.salary.label" | translate }}</div>
                  <div>
                    {{ visit.recipientInfo.workExperience }}
                    {{ visit.recipientInfo.workExperience === 1 ? "year.label" : ("years.label" | translate) }}
                    /
                    {{ visit.recipientInfo.leadershipExperience }}
                    {{ visit.recipientInfo.leadershipExperience === 1 ? "year.label" : ("years.label" | translate) }}
                  </div>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.location.label" | translate }}</div>
                  <mh-location-label [location]="visit.recipientInfo.location"></mh-location-label>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "profile.ideal-location.label" | translate }}</div>
                  <mh-location-label [location]="visit.recipientInfo.idealWorkingLocation"></mh-location-label>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</ng-template>

<ng-template #mobileView>
  <div nz-col [nzSpan]="24">
    <nz-table
      class="matches-list conversation-list-table"
      nzSize="small"
      nzShowSizeChanger="true"
      nzFrontPagination="false"
      [nzData]="visitsMissionPartnerList || []"
      [nzLoading]="loading"
    >
      <thead>
        <tr>
          <th nzWidth="30px">
            <button
              nz-button
              nzType="link"
              [class.ant-table-row-expand-icon-expanded]="allExpanded"
              [class.ant-table-row-expand-icon-collapsed]="!allExpanded"
              (click)="onAllExpandChange()"
            >
              <span nz-icon [nzType]="allExpanded ? 'minus-circle' : 'plus-circle'" nzTheme="outline"></span>
            </button>
          </th>
          <th>{{ "visit.talent-name.label" | translate }}</th>
          <th nzWidth="30px">{{ "visit.actions.label" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let visit of visitsMissionPartnerList">
          <tr>
            <td
              [nzExpand]="expandSet.has(visit.talentId)"
              (nzExpandChange)="onExpandChange(visit.talentId, $event)"
            ></td>
            <td>
              <div class="cell-content">
                <div class="contact" [routerLink]="['/mission-partner/view-talent/', visit.talentId]">
                  <nz-avatar class="mh-avatar" [nzSrc]="visit.recipientInfo.avatarUrl" [nzSize]="40"></nz-avatar>
                  <div class="contact-name">
                    {{ visit.recipientInfo.name }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <button
                *ngIf="visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.send-btn.label' | translate"
                [routerLink]="['/mission-partner/inbox/conversation/', visit.conversationId]"
                [queryParams]="{ origin: 'mission-partner-my-visits' }"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
              <button
                *ngIf="!visit.conversationId"
                nz-button
                nzType="link"
                nzSize="small"
                [nz-tooltip]="'visit.start-conversation.label' | translate"
                (click)="openCreateConversationModal($event, visit)"
              >
                <span nz-icon nzType="message" nzTheme="outline"></span>
              </button>
            </td>
          </tr>
          <tr *ngIf="expandSet.has(visit.talentId)" class="expanded-row">
            <td colspan="4">
              <div class="expanded-content">
                <div class="expanded-section">
                  <div class="expanded-title">{{ "visit.date-of-last-visit.label" | translate }}</div>
                  <div>{{ visit.visitTimestamp | date : "dd.MM.YYYY HH:mm" }}</div>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "visit.number-of-visits.label" | translate }}</div>
                  <div>{{ visit.visitCount }}</div>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.salary.label" | translate }}</div>
                  <div>{{ visit.recipientInfo.salaryMin }} - {{ visit.recipientInfo.salaryMax }}</div>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.salary.label" | translate }}</div>
                  <ng-container *ngIf="visit.recipientInfo.domainExpertises">
                    <mh-tags-output [value]="visit.recipientInfo.domainExpertises"></mh-tags-output>
                  </ng-container>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.salary.label" | translate }}</div>
                  <div>
                    {{ visit.recipientInfo.workExperience }}
                    {{ visit.recipientInfo.workExperience === 1 ? "year.label" : ("years.label" | translate) }}
                    /
                    {{ visit.recipientInfo.leadershipExperience }}
                    {{ visit.recipientInfo.leadershipExperience === 1 ? "year.label" : ("years.label" | translate) }}
                  </div>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "participant.location.label" | translate }}</div>
                  <mh-location-label [location]="visit.recipientInfo.location"></mh-location-label>
                </div>
                <div class="expanded-section">
                  <div class="expanded-title">{{ "profile.ideal-location.label" | translate }}</div>
                  <mh-location-label [location]="visit.recipientInfo.idealWorkingLocation"></mh-location-label>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</ng-template>
