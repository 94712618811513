import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { Subject, takeUntil } from "rxjs";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AudioRecordingService } from "../../../../../../../../core/src/lib/recording/audio-recording.service";

@Component({
  selector: "mh-message-record-audio-dialog",
  templateUrl: "./mh-message-record-audio-dialog.component.html",
  styleUrls: ["./mh-message-record-audio-dialog.component.less"],
})
export class MhMessageRecordAudioDialogComponent {
  private readonly destroy$ = new Subject<void>();
  isRecording = false;
  recordingLoading = false;
  recordedTime = '00:00';
  blobUrl?: SafeUrl | null;
  private blob?: Blob;
  private mediaName = '';
  previewVideoElement: any;
  fileList: FileList | null | undefined;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private audioRecordingService: AudioRecordingService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    this.setupAudioRecordingSubscriptions();
  }

  private setupAudioRecordingSubscriptions() {
    this.audioRecordingService.recordingFailed().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleRecordingFailure();
    });

    this.audioRecordingService.getRecordedTime().pipe(takeUntil(this.destroy$)).subscribe((time) => {
      this.handleRecordedTime(time);
    });

    this.audioRecordingService.getRecordedBlob().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.handleRecordedBlob(data, () => this.handleAudioUpload());
    });
  }

  private handleRecordingFailure() {
    this.isRecording = false;
    this.ref.detectChanges();
  }

  private handleRecordedTime(time: any) {
    this.recordedTime = time;
    this.ref.detectChanges();
  }

  private handleRecordedBlob(data: any, handleUpload: () => void) {
    if (data.blob) {
      this.blob = data.blob;
      this.mediaName = data.title;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.ref.detectChanges();
      handleUpload();
    }
  }

  startAudioRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  handleAudioUpload(): void {
    if (this.blob) {
      const file = new File([this.blob], this.mediaName, { type: this.blob.type });

      const dt = new DataTransfer();
      dt.items.add(file);
      this.fileList = dt.files;
    }
  }

  retakeVideo(): void {
    this.blobUrl = null;
    this.startAudioRecording();
  };

  onCancel() {
    this.modal.close();
  }

  onSubmit() {
    return this.modal.triggerOk();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.abortAudioRecording();
  }
}
