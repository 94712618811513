<div style="padding-top: 30px">Step {{ currentStepIndex + 1 }} of {{ steps.length }}</div>

<div style="margin-bottom: 20px">
  <nz-progress [nzPercent]="progressPercentage"></nz-progress>
</div>

<form nz-form nzLayout="vertical" class="steps-form">
  <ng-container *ngIf="currentStep">
    <h2 *ngIf="currentStep.section?.type">{{ (currentStep.section?.type || '') | translate }}</h2>
    <div class="question-img-cnt" *ngIf="currentStep.section?.imageUrl" #imgCnt>
      <img class="question-img" [src]="getImageByKey(currentStep.section?.imageUrl)" (error)="imgCnt.style.display='none'"/>
    </div>
    <h3 *ngIf="currentStep.subSection">{{ currentStep.subSection.type | translate }}</h3>

    <div *ngFor="let question of currentStep.subSection.questions">
      <mh-assessment-navigator-question-wrapper [question]="question" (answered)="saveAnswers($event)"></mh-assessment-navigator-question-wrapper>
    </div>

    <p class="footer-buttons footer-buttons-right">

        <button nz-button nzType="default" (click)="onPrevStep()" [disabled]="isPrevDisabled">
          <span>{{ "prev.button" | translate }}</span>
        </button>
        <ng-container *ngIf="!isNextDisabled">
          <button nz-button nzType="primary" (click)="onNextStep()">
            <span>{{ "next-step.button" | translate }}</span>
          </button>
        </ng-container>
        <ng-container *ngIf="isNextDisabled">
          <button nz-button nzType="primary" (click)="onComplete()">
            <span>{{ "complete.button" | translate }}</span>
          </button>
        </ng-container>

    </p>
  </ng-container>
</form>
