<nz-form-control class="ant-select ant-select-in-form-item ant-select-show-search ant-select-multiple">
  <div class="ant-select-selector" (click)="onSelectClick()">
    <nz-select-item class="ant-select-selection-item" (delete)="remove(suggestion)" *ngFor="let suggestion of suggestions" [deletable]="true" [label]="suggestion"></nz-select-item>
    <div class="ant-select-selection-search">
      <input
        class="ant-select-selection-search-input"
        [autocomplete]=""
        #suggestionInput
        [formControl]="suggestionCtrl"
        [nzAutocomplete]="auto"
        (ngModelChange)="onSearch($event)"
        (blur)="handleInputConfirm()"
        (keydown.enter)="handleInputConfirm()"
      />
    </div>
    <nz-autocomplete
      [nzDataSource]="optionList"
      [nzDefaultActiveFirstOption]="false"
      #auto
      (selectionChange)="onAutoCompleteSelect($event)"
    ></nz-autocomplete>
  </div>
</nz-form-control>
