import { Component, Input } from "@angular/core";
import { TalentProfileCompleteness } from "../../../../../api/dashboard/dashboard.types";

@Component({
  selector: "mh-talent-profile-completeness",
  templateUrl: "./talent-profile-completeness.component.html",
  styleUrls: ["./talent-profile-completeness.component.less"],
})
export class TalentProfileCompletenessComponent {
  @Input() completeness!: TalentProfileCompleteness;

  get profilePercentage(): number {
    return Math.ceil(+this.completeness.completenessPercentage);
  }
}
