<ng-container [ngTemplateOutlet]="(isMobileView$ | async) ? mobileView : desktopView"></ng-container>

<ng-template #desktopView>
  <nz-layout class="admin-container">
    <nz-sider *ngIf="!hideSider" nzCollapsible nzWidth="230px" (nzCollapsedChange)="toggleCollapsed($event)">
      <div class="logo">{{'admin.menu.title' | translate}}</div>
      <ng-container [ngTemplateOutlet]="menu"></ng-container>
    </nz-sider>
    <nz-layout class="right-layout">
      <nz-header class="admin-header">
        {{(user$ | async)?.email}}
        <mh-locale-select></mh-locale-select>
      </nz-header>
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
</ng-template>

<ng-template #mobileView>
  <Navbar [icon]="icon" (onLeftClick)="toggleDrawer()">
    {{(user$ | async)?.email}}
  </Navbar>

  <Drawer
    class="my-drawer"
    [ngStyle]="{ minHeight: height + 'px' }"
    [enableDragHandle]="false"
    [sidebar]="sidebar"
    [open]="state.open"
    (onOpenChange)="toggleDrawer()"
  >
    <WingBlank>
      <WhiteSpace [size]="'lg'"></WhiteSpace>
      <router-outlet></router-outlet>
      <WhiteSpace [size]="'lg'"></WhiteSpace>
    </WingBlank>
  </Drawer>

  <ng-template #icon>
    <span nz-icon nzType="menu" nzSize="large" nzTheme="outline"></span>
  </ng-template>
</ng-template>

<ng-template #sidebar>
  <div class="sidebar-container">
    <ng-container [ngTemplateOutlet]="menu"></ng-container>
    <div class="mobile-locale-select">
      <mh-locale-select></mh-locale-select>
    </div>
  </div>
</ng-template>

<ng-template #menu>
  <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['talent-list']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('admin.talent-list.label' | translate) : ''"
    >
      <span nz-icon nzType="user"></span>
      <span>{{'admin.talent-list.label' | translate}}</span>
    </li>
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['mission-partner-list']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('admin.mission-partner-list.label' | translate) : ''"
    >
      <span nz-icon nzType="bank"></span>
      <span>{{'admin.mission-partner-list.label' | translate}}</span>
    </li>
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['user-admin-list']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('admin.user-admin-list.label' | translate) : ''"
    >
      <span nz-icon nzType="safety"></span>
      <span>{{'admin.user-admin-list.label' | translate}}</span>
    </li>
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['job-offer-list']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('admin.job-offer-list.label' | translate) : ''"
    >
      <span nz-icon nzType="dashboard"></span>
      <span>{{'admin.job-offer-list.label' | translate}}</span>
    </li>
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['completion-list']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('admin.completion-list.label' | translate) : ''"
    >
      <span nz-icon nzType="check-square"></span>
      <span>{{'admin.completion-list.label' | translate}}</span>
    </li>
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['communications']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('admin.communications.label' | translate) : ''"
    >
      <span nz-icon nzType="mail"></span>
      <span>{{'admin.communications.label' | translate}}</span>
    </li>
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['invoices']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('admin.invoices.label' | translate) : ''"
    >
      <span nz-icon nzType="credit-card"></span>
      <span>{{'admin.invoices.label' | translate}}</span>
    </li>
    <li nz-menu-item
        [nzMatchRouter]="true"
        [routerLink]="['assessments']"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('mission-partner.assessment.label' | translate) : ''"
    >
      <span nz-icon nzType="trophy"></span>
      <span>{{'mission-partner.assessment.label' | translate}}</span>
    </li>
    <li nz-menu-item
        (click)="onLogout()"
        (mousedown)="onLogout()"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? ('navi.logout.label' | translate) : ''"
    >
      <span nz-icon nzType="poweroff"></span>
      <span>{{'navi.logout.label' | translate}}</span>
    </li>
  </ul>
</ng-template>
