import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentSettingsEmailComponent } from "./talent-settings-email.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzIconModule } from "ng-zorro-antd/icon";

@NgModule({
  declarations: [TalentSettingsEmailComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzWaveModule,
    ReactiveFormsModule,
    TranslateModule,
    NzTagModule,
    NzIconModule,
  ],
  exports: [TalentSettingsEmailComponent],
})
export class TalentSettingsEmailModule {}
