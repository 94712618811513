import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { TestimonialsService } from "./testimonials.service";
import { TestimonialRequest } from "./testimonials.types";

@Injectable({
  providedIn: "root",
})
export class TestimonialsRequestResolver {
  constructor(private readonly testimonialService: TestimonialsService) {}

  resolve(): Observable<Array<TestimonialRequest>> {
    return this.testimonialService.loadTestimonialsRequest();
  }
}
