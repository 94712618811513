import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { LoginGuard } from "./pages/login-page/login-page-data/login.guard";
import { HomeTalentModule } from "./pages/home-talent/home-talent.module";
import { HomeMissionPartnerModule } from "./pages/home-mission-partner/home-mission-partner.module";
import { HomeAdminModule } from "./pages/home-admin/home-admin.module";
import { RegisterTalentComponent } from "./pages/register-talent/register-talent.component";
import { RegisterMissionPartnerComponent } from "./pages/register-mission-partner/register-mission-partner.component";
import { AutoLoginGuard } from "./api/authorization/auto-login-guard.service";
import { PasswordForgottenComponent } from "./pages/password-forgotten/password-forgotten.component";
import { PasswordResetComponent } from "./pages/password-reset/password-reset.component";
import { ExpiredTokenComponent } from "./pages/expired-token/expired-token.component";
import { TalentProfileResolver } from "./api/profile/talent-profile.resolver";
import { MissionPartnerProfileResolver } from "./api/profile/mission-partner-profile.resolver";
import { LoginDataResolver } from "./pages/login-page/login-page-data/login-data.resolver";
import { RegistrationDoneGuard } from "./api/authorization/registration-done.guard";
import { ContentPageComponent } from "./pages/content-page/content-page.component";
import { AccountDeletedComponent } from "./pages/account-deleted/account-deleted.component";
import { AccountConfirmedComponent } from "./pages/account-confirmed/account-confirmed.component";
import { MakeReferenceComponent } from "./pages/make-reference/make-reference.component";
import { MakeTestimonialsComponent } from "./pages/make-testimonials/make-testimonials.component";
import { OverlayModule } from "./overlays/overlay.module";
import {
  ExternalAssessmentQuestionerModule
} from "./pages/external-assessment-pages/external-assessment-questioner.module";

const routes: Routes = [
  {
    path: "landing",
    component: LandingPageComponent,
    canActivate: [AutoLoginGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "login",
    component: LoginPageComponent,
    canActivate: [AutoLoginGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "registration/done",
    canActivate: [RegistrationDoneGuard],
    runGuardsAndResolvers: "always",
    children: [],
  },
  {
    path: "account-confirmed",
    component: AccountConfirmedComponent,
  },
  {
    path: "password-forgotten",
    component: PasswordForgottenComponent,
  },
  {
    path: "password-reset",
    component: PasswordResetComponent,
  },
  {
    path: "expired-token",
    component: ExpiredTokenComponent,
  },
  {
    path: "account-deleted",
    component: AccountDeletedComponent,
  },
  {
    path: "make-reference",
    component: MakeReferenceComponent,
  },
  {
    path: "make-testimonials",
    component: MakeTestimonialsComponent,
  },
  {
    path: "sign-up-talent",
    component: RegisterTalentComponent,
    canActivate: [AutoLoginGuard],
    resolve: {
      profile: TalentProfileResolver,
      loginData: LoginDataResolver,
    },
    runGuardsAndResolvers: "always",
  },
  {
    path: "sign-up-mission-partner",
    component: RegisterMissionPartnerComponent,
    canActivate: [AutoLoginGuard],
    resolve: {
      profile: MissionPartnerProfileResolver,
      loginData: LoginDataResolver,
    },
    runGuardsAndResolvers: "always",
  },
  {
    path: 'loginAndForward',
    canActivate: [AutoLoginGuard],
    runGuardsAndResolvers: "always",
    data: { fromEmail: true },
    children: [],
  },
  {
    path: "talent",
    canActivate: [LoginGuard, AutoLoginGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => HomeTalentModule,
  },
  {
    path: "talent",
    outlet: "overlay",
    loadChildren: () => OverlayModule,
  },
  {
    path: "mission-partner",
    canActivate: [LoginGuard, AutoLoginGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => HomeMissionPartnerModule,
  },
  {
    path: "assessment",
    runGuardsAndResolvers: "always",
    loadChildren: () => ExternalAssessmentQuestionerModule,
  },
  {
    path: "admin",
    canActivate: [LoginGuard, AutoLoginGuard],
    runGuardsAndResolvers: "always",
    loadChildren: () => HomeAdminModule,
  },
  {
    path: "terms-and-conditions",
    component: ContentPageComponent,
    data: {
      boxName: "TermsOfUse",
    },
  },
  {
    path: "data-protection",
    component: ContentPageComponent,
    data: {
      boxName: "DataProtection",
    },
  },
  {
    path: "**",
    redirectTo: "/landing",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class PlatformPagesRoutingModule {}
