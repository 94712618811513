
<div class="scrollable" #scrollContainer style="overscroll-behavior: none">
  <div class="message-list">
    <mh-chat-message [item]="item"
                     [loginData]="loginData"
                     [accessGrant]="accessGrant"
                     [negotiation]="negotiation"
                     [recipientInfo]="recipientInfo"
                     [conversationDisabled]="disabled"
                     (messageAction)="onMessageAction($event)" *ngFor="let item of data"></mh-chat-message>
    <!-- file upload placeholder-->
    <nz-comment *ngIf="uploadingFile" class="own-message">
      <nz-comment-content>
        <div class="message-balloon uploading"><nz-spin nzSimple [nzSize]="'small'"></nz-spin>{{uploadingFile?.name}}</div>
      </nz-comment-content>
    </nz-comment>
    <!-- / file upload placeholder-->
  </div>
</div>

<div class="message-list-footer" *ngIf="!disabled">
  <mh-message-input [isMobile]="isMobile" (sendMessage)="onSendMessage($event)"></mh-message-input>
</div>
