import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Talent } from "../../../api/sign-up/signup.types";

@Component({
  selector: "mh-profile-preview-key-summary",
  templateUrl: "./profile-preview-key-summary.component.html",
  styleUrls: ["./profile-preview-key-summary.component.less"],
})
export class ProfilePreviewKeySummaryComponent {
  @Input() talent!: Talent;
  @Input() profileEditable = false;
  @Output() talentUpdate = new EventEmitter<Talent>();
  @ViewChild('keySummaryTextarea') keySummaryTextarea!: ElementRef<HTMLElement>;

  editKeySummary = false;
  editKeySummaryOriginalVal: string | undefined;

  onEditKeySummary() {
    this.editKeySummaryOriginalVal = this.talent.keySummary;
    this.editKeySummary = true;
    setTimeout(()=>this.calcTextareaHeightKeySummary(), 0);
  }

  onCancelEditKeySummary() {
    this.talent.keySummary = this.editKeySummaryOriginalVal;
    this.editKeySummary = false;
  }

  onUpdateKeySummary() {
    this.talentUpdate.emit(this.talent);
    this.editKeySummary = false;
  }

  calcTextareaHeightKeySummary() {
    const textareaEl = this.keySummaryTextarea.nativeElement;
    if(textareaEl) {
      textareaEl.style.height = "";
      textareaEl.style.height = textareaEl.scrollHeight + "px";
    }
  }

}
