import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuggestionsInputComponent } from './suggestions-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from "@ngx-translate/core";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzTagModule} from "ng-zorro-antd/tag";
import {NzAutocompleteModule} from "ng-zorro-antd/auto-complete";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzSelectModule} from "ng-zorro-antd/select";

@NgModule({
  declarations: [SuggestionsInputComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        NzFormModule,
        NzTagModule,
        NzAutocompleteModule,
        NzInputModule,
        NzSelectModule,
    ],
  exports: [SuggestionsInputComponent],
})
export class SuggestionsInputModule {}
