import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {map, Observable} from "rxjs";
import * as actions from "../../pages/login-page/login-page-data/store/login.action";
import {loginFeatureName, LoginState} from "../../pages/login-page/login-page-data/store/login.reducer";
import {AccountStatus, AccountType} from "../../pages/login-page/login-page-data/login.types";
import {selectLoginData} from "../../pages/login-page/login-page-data/store/login.selectors";
import {LoginService} from "../../pages/login-page/login-page-data/login.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  constructor(private store: Store<{ [loginFeatureName]: LoginState }>, private loginService: LoginService) {}

  getAuthorizedUser() {
    return this.store.select(selectLoginData);
  }

  getAccountType(): Observable<AccountType | undefined> {
    return this.store.select(selectLoginData).pipe(map((userData) => userData.accountType));
  }

  getAccountId(): Observable<string | undefined> {
    return this.store.select(selectLoginData).pipe(map((userData) => userData.accountId));
  }

  updateAuthorizedUser() {
    this.store.dispatch(actions.getUserByToken({ token: this.loginService.getToken() }));
  }

  hasReviewedStatus(): Observable<boolean> {
    return this.getAuthorizedUser().pipe(map( loginData => loginData?.accountStatuses?.includes(AccountStatus.REVIEWED) || false));
  }

  hasSelfServiceStatus(): Observable<boolean> {
    return this.getAuthorizedUser().pipe(map( loginData => loginData?.accountStatuses?.includes(AccountStatus.SELF_SERVICE) || loginData.adminOverrideMode));
  }
}
