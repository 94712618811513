import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerDashboardComponent } from "./mission-partner-dashboard.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { WidgetModule } from "../../../../../../../common-ui/src/lib/widget/widget.module";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { FormsModule } from "@angular/forms";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { TalentDashboardNotificationSettingsModule } from "./mission-partner-dashboard-notification-settings/mission-partner-dashboard-notification-settings.module";

@NgModule({
  declarations: [MissionPartnerDashboardComponent],
  imports: [
    CommonModule,
    NzGridModule,
    NzIconModule,
    WidgetModule,
    TranslateModule,
    RouterLink,
    NzButtonModule,
    NzPageHeaderModule,
    FormsModule,
    NzCheckboxModule,
    TalentDashboardNotificationSettingsModule
],
})
export class MissionPartnerDashboardModule {}
