import {Component, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {catchError, EMPTY, Subject, takeUntil} from "rxjs";
import {LoginService} from "../login-page/login-page-data/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NumberRequiredValidator, SpecialCharacterRequiredValidator, UpperCaseRequiredValidator} from "@momhunting/core";
import {ApiResponseDTO} from "../../api/common.types";
import {gridResponsiveMap, NzBreakpointEnum, NzBreakpointService} from "ng-zorro-antd/core/services";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";

const DEFAULT_AUTHENTICATED_ROUTE = "/accounts/manage";

@Component({
  selector: "mh-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.less"],
})

export class PasswordResetComponent implements OnInit, OnDestroy {

  public formGroup!: FormGroup;
  public errorResponse: string | undefined | any = '';
  private readonly destroy$ = new Subject<void>();
  public showResetForm = true;
  public isMdBreakpoint: boolean = false;
  public email = '';

  autoTips: Record<string, Record<string, string>> = {
    default: {
      required: this.translateService.instant('field.required.label'),
    },
  };

  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointService: NzBreakpointService,
    private translateService: TranslateService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: "Password reset" }));
  }

  get password(): FormControl {
    return this.formGroup.get('password') as FormControl;
  }

  get confirmPassword(): FormControl {
    return this.formGroup.get("confirmPassword") as FormControl;
  }

  ngOnInit() {
    if (this.loginService.isAuthenticated()) {
      this.router.navigate([DEFAULT_AUTHENTICATED_ROUTE]);
    }

    this.breakpointService
      .subscribe(gridResponsiveMap)
      .pipe(takeUntil(this.destroy$))
      .subscribe(breakpoint => {
        this.isMdBreakpoint =
          breakpoint === NzBreakpointEnum.md ||
          breakpoint === NzBreakpointEnum.lg ||
          breakpoint === NzBreakpointEnum.xl ||
          breakpoint === NzBreakpointEnum.xxl;
      });

    this.handleToken();

    this.formGroup = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        NumberRequiredValidator,
        SpecialCharacterRequiredValidator,
        UpperCaseRequiredValidator,
      ]),
      confirmPassword: new FormControl('', [Validators.required, this.passwordMatchValidator]),
    });
  }

  private handleToken() {
    const queryToken = this.activatedRoute.snapshot.queryParams['token'];
    if (queryToken) {
      this.loginService.passwordForgottenCheck(queryToken)
        .pipe(takeUntil(this.destroy$))
        .pipe(
          catchError((err) => {
            this.router.navigate(['/expired-token']);
            return EMPTY;
          })
        )
        .subscribe((response) => {
          if (response.success) {
            this.loginService.setToken(response.results.data);
            this.email = response.results.email;
          } else {
            this.router.navigate(['/expired-token']);
          }
        });
    }
  }

  onResetPasswordFormSubmit() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.loginService
      .resetPassword(this.formGroup.value.password)
      .pipe(
        catchError((err) => {
          this.errorResponse = err;
          return EMPTY;
        })
      )
      .subscribe((response: ApiResponseDTO) => {
        if (response.success) {
          this.showResetForm = false;
          this.errorResponse = null;
        } else {
          this.errorResponse = response.errorKey ?? response.message;
        }
      });
  }

  passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = this.formGroup?.get('password')?.value;
    const confirmPassword = this.formGroup?.get('confirmPassword')?.value;

    return password === confirmPassword ? null : {passwordNotMatch: true};
  };

  ngOnDestroy() {
    this.destroy$.next();
  }
}
