import { Component, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import { AccountFilter, MissionPartnerAccount } from "../../home-admin-data/account.types";
import { map, Observable, Subject, take, takeUntil } from "rxjs";
import { UntypedFormBuilder } from "@angular/forms";
import { JobOffer, JobOpportunitySearchResultStatus } from "../../../../api/job-offer/job-offer.types";
import { AccountService } from "../../home-admin-data/account.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { AdminJobOfferEditComponent } from "./admin-job-offer-edit/admin-job-offer-edit.component";
import { AdminJobOfferCreateComponent } from "./admin-job-offer-create/admin-job-offer-create.component";
import { AdminJobOfferService } from "../../../../api/admin-job-offer/admin-job-offer.service";
import { ActivatedRoute } from "@angular/router";
import { Clipboard } from "@angular/cdk/clipboard";
import { MhNotificationType, MhSocketMessage } from "../../../../api/notification/notification.types";
import { SocketService } from "../../../../../../../core/src/lib/soket/socket.service";
import { updateJOListItem } from "../../../home-mission-partner/subpages/mission-partner-job-offers/job-offer-data/store/job-offer.reducer";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage, JobOfferListForm } from "../../../../api/filter-storage/filter-storage.types";
import { DatePipe } from "@angular/common";
import { CmsCachedService } from "libs/platform-pages/src/lib/api/cms/cms-cached.service";
import { CmsNameValue } from "libs/platform-pages/src/lib/api/cms/cms.types";

const FILTERS_PAGE = FiltersPage.JOB_OFFER_LIST;

@Component({
  selector: "mh-admin-job-offer-list",
  templateUrl: "./admin-job-offer-list.component.html",
  styleUrls: ["./admin-job-offer-list.component.less"],
})
export class AdminJobOfferListComponent implements OnInit, OnDestroy {
  jobOfferList?: PaginatedContent<JobOffer>;
  missionPartners?: Array<MissionPartnerAccount>;
  jobOpportunityStages$: Observable<Array<CmsNameValue>>;

  loading = true;
  isApiLoading = false;
  searchForm!: JobOfferListForm;
  preventDoubleFetch = true;
  private queryMissionPartnerId: string | undefined;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private adminJobOfferService: AdminJobOfferService,
    private filterStorageService: FilterStorageService,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private clipboard: Clipboard,
    private socketService: SocketService,
    private datePipe: DatePipe,
    private cmsCachedService: CmsCachedService,
  ) {
    this.jobOpportunityStages$ = this.cmsCachedService.getUserDataByName("jo-stage");
    this.queryMissionPartnerId = this.activatedRoute.snapshot.queryParams["missionPartnerId"];
    this.fetchMissionPartnerList();
    this.socketService.messages$.pipe(takeUntil(this.destroy$)).subscribe((message: MhSocketMessage) => {
      if (
        message.action === MhNotificationType.TALENTS_SEARCH_STARTED &&
        message.jobOpportunityId &&
        this.jobOfferList?.items
      ) {
        this.jobOfferList.items = updateJOListItem(this.jobOfferList?.items || [], {
          id: message.jobOpportunityId,
          status: JobOpportunitySearchResultStatus.PENDING,
        });
      }
      if (
        message.action === MhNotificationType.TALENTS_SEARCH_FINISHED &&
        message.jobOpportunityId &&
        this.jobOfferList?.items
      ) {
        this.jobOfferList.items = updateJOListItem(this.jobOfferList?.items || [], {
          id: message.jobOpportunityId,
          status: JobOpportunitySearchResultStatus.DONE,
        });
      }
    });
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchTerm: [null],
      archived: false,
      missionPartnerId: [this.queryMissionPartnerId || null],
      forMissionPartnerId: [null],
    });

    this.initSavedFilters();
    this.fetchJobOfferList({ ...this.getDefaultQueryParams(), ...this.getFilterFormValues() });
  }

  private initSavedFilters(): void {
    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE] || ({} as JobOfferListForm);

    if (this.queryMissionPartnerId) {
      storedFilters.missionPartnerId = this.queryMissionPartnerId;
    }
    this.searchForm.patchValue(storedFilters);
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
  }

  fetchJobOfferList(filter: AccountFilter) {
    this.loading = true;
    this.adminJobOfferService
      .loadJobOfferList(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.jobOfferList = paginatedContent;
        this.loading = false;
      });
  }

  fetchMissionPartnerList() {
    this.accountService
      .loadMissionPartnerList({
        paging: {
          page: 1,
          itemsOnPage: 100,
        },
        searchTerm: "",
      })
      .pipe(map((response) => response.items))
      .subscribe((data) => {
        this.missionPartners = data;
      });
  }

  setJobOpportunityStage(jobOpportunityId: string, stage: number) {
    this.adminJobOfferService.setJobOpportunityStage(jobOpportunityId, stage).subscribe((success) => {
      if (success) {
        this.notificationService.success("", this.translateService.instant("admin.jo.stage.set.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  setJobOpportunityForMissionPartner(jobOpportunityId: string, accountId: string) {
    this.adminJobOfferService.setJobOpportunityForMissionPartner(jobOpportunityId, accountId).subscribe((success) => {
      if (success) {
        this.notificationService.success("", this.translateService.instant("admin.jo.set-for-mp.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const { pageSize, pageIndex, sort } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      ...this.getFilterFormValues(),
    };

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == "ascend" ? "ASC" : "DESC",
            field: sortField,
          },
        ],
      };
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.jobOfferList?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    });
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");
    const archivedField = this.getSearchField("archived");
    const missionPartnerId = this.getSearchField("missionPartnerId");
    const forMissionPartnerId = this.getSearchField("forMissionPartnerId");

    const filterFormValues: Partial<AccountFilter> = {
      searchTerm: searchField?.value || "",
      filters: [],
      groupFilters: [],
    };

    if (archivedField?.value) {
      filterFormValues.filters?.push({
        value: archivedField?.value,
        field: "ARCHIVED_FIELD",
      });
    }

    if (missionPartnerId?.value) {
      filterFormValues.filters?.push({
        value: missionPartnerId.value,
        field: "MISSION_PARTNER",
      });
    }

    if (forMissionPartnerId?.value) {
      filterFormValues.filters?.push({
        value: forMissionPartnerId.value,
        field: "FOR_MISSION_PARTNER",
      });
    }

    return filterFormValues;
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchJobOfferList(filter);
  }

  getDefaultQueryParams() {
    const queryParams: AccountFilter = {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: "",
      filters: [],
    };

    if (this.queryMissionPartnerId) {
      queryParams.filters?.push({
        value: this.queryMissionPartnerId,
        field: "MISSION_PARTNER",
      });
    }

    return queryParams;
  }

  openCreateJobOfferModal() {
    const modal = this.modal.create({
      nzContent: AdminJobOfferCreateComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 800,
      nzMaskClosable: false,
    });

    modal.afterClose.subscribe((result: string) => {
      if (result === "success") {
        this.submitFilterForm();
      }
    });
  }

  editJobOffer(id: string): void {
    this.adminJobOfferService.getJobOpportunity(id).subscribe((jo) => this.openEditJobOfferModal(jo));
  }

  openEditJobOfferModal(jobOffer: JobOffer) {
    const modal = this.modal.create({
      nzContent: AdminJobOfferEditComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 800,
      nzData: { jobOffer },
      nzMaskClosable: false,
    });

    modal.afterClose.subscribe((result: JobOffer) => {
      if (result && this.jobOfferList?.items) {
        this.jobOfferList.items = updateJOListItem(this.jobOfferList.items, result);
      }
    });
  }

  showDeleteConfirm(jobOffer: JobOffer) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("admin.jo.delete.confirm.title"),
      nzContent: `<b>${this.translateService.instant("admin.jo.delete.confirm.text")}</b>`,
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.deleteJobOffer(jobOffer.id),
    });
  }

  showArchiveConfirm(jobOffer: JobOffer) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("admin.jo.archive.confirm.title"),
      nzContent: `<b>${this.translateService.instant("admin.jo.archive.confirm.text")}</b>`,
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.archiveJobOffer(jobOffer),
    });
  }

  archiveJobOffer(jobOffer: JobOffer) {
    this.adminJobOfferService.archiveJobOpportunity(jobOffer.id).subscribe((success) => {
      if (success) {
        jobOffer.archived = true;
        this.notificationService.success("", "Job offer archived");
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  deleteJobOffer(jobOfferId: string) {
    this.adminJobOfferService.deleteJobOpportunity(jobOfferId).subscribe((success) => {
      if (success) {
        if (this.jobOfferList?.items) {
          this.jobOfferList.items = this.jobOfferList.items.filter((jo) => jo.id !== jobOfferId);
        }
        this.notificationService.success("", "Job offer deleted");
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  copyId(id: string) {
    const isCopySuccessful = this.clipboard.copy(id);
    if (isCopySuccessful) {
      this.notificationService.success("", this.translateService.instant("admin.copy-id.success"));
    }
  }

  onSignInAs(accountId: string) {
    this.accountService.signInAs(accountId);
  }

  downloadJobOfferListCSV() {
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, "yyyy-MM-dd");
    const fileName = `${formattedDate}_job_offer_list_export.csv`;

    this.isApiLoading = true;
    this.adminJobOfferService.downloadJobOfferList(fileName).subscribe(() => (this.isApiLoading = false));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly JobOpportunitySearchResultStatus = JobOpportunitySearchResultStatus;
}
