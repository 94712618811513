<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div nz-row nzGutter="24">
    <div nz-col [nzSpan]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label nzFor="phoneNumber" nzRequired>{{ 'settings.phone.label' | translate }}</nz-form-label>
        <nz-form-control
          [nzSpan]="24"
          [nzValidateStatus]="formGroup.controls['phoneNumber']"
          [nzErrorTip]="'field.invalid.label' | translate"
        >
          <mh-nz-intl-tel-input
            [preferredCountries]="['de', 'fr']"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            name="phoneNumber"
            describedBy="phoneInput"
            formControlName="phoneNumber"
            id="phoneNumber"
            nzSize="default"
          ></mh-nz-intl-tel-input>
        </nz-form-control>
      </nz-form-item>

      <button nz-button [nzType]="'default'" nzBlock *ngIf="!changePhoneMode"
              (click)="onStartChangePhoneNumber()">
        {{ "change-phone.button" | translate }}
      </button>

      <nz-form-item *ngIf="changePhoneMode">
        <nz-form-control [nzSpan]="24">
          <button nz-button nzType="primary" nzBlock [disabled]="!canSubmitPhoneNumber" (click)="submitPhone()">
            {{'save.button' | translate}}
          </button>
        </nz-form-control>
      </nz-form-item>

    </div>
  </div>
</form>
