import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../../../home-admin-data/account.service";
import { map, Subject } from "rxjs";
import { MissionPartnerAccount } from "../../../home-admin-data/account.types";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AssessmentService } from "../../../../../api/assessment/assessment.service";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: "mh-admin-assessment-create",
  templateUrl: "./admin-assessment-create.component.html",
  styleUrls: ["./admin-assessment-create.component.less"],
})
export class AdminAssessmentCreateComponent implements OnInit, OnDestroy {
  formGroup!: UntypedFormGroup;
  private readonly destroy$ = new Subject<void>();
  missionPartners!: Array<MissionPartnerAccount>;
  uploading = false;

  constructor(
    private modal: NzModalRef,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private accountService: AccountService,
    private assessmentService: AssessmentService,
    private notificationService: NzNotificationService,
  ) {
    this.accountService
      .loadMissionPartnerList({
        paging: {
          page: 1,
          itemsOnPage: 100,
        },
        searchTerm: "",
        filters: [{ field: "HAS_MATURITY_ASSESSMENT", value: "false" }],
      })
      .pipe(map((response) => response.items))
      .subscribe((data) => {
        this.missionPartners = data;
      });
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      missionPartnerId: [null, [Validators.required]],
    });
  }

  isValid(): boolean {
    if (!this.formGroup.valid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  onCancel(): void {
    this.modal.close();
  }

  onSave(): void {
    if (!this.isValid()) {
      return;
    }
    this.assessmentService.setUp(this.formGroup.get("missionPartnerId")?.value).subscribe((result) => {
      this.notificationService.success("", this.translateService.instant("admin.assessment.create.success"));
      this.modal.close(result);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
