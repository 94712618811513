import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  JobOffersInfo,
  InvoicesInfo,
  DashboardConversationInfo,
  MissionPartnerProfileCompleteness,
} from "../../../../api/dashboard/dashboard.types";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { MhNotificationSettings } from "libs/platform-pages/src/lib/api/notification/notification.types";

@Component({
  selector: "mh-mission-partner-dashboard",
  templateUrl: "./mission-partner-dashboard.component.html",
  styleUrls: ["./mission-partner-dashboard.component.less"],
})
export class MissionPartnerDashboardComponent {
  jobOffers: JobOffersInfo;
  talentLastReplies: DashboardConversationInfo;
  proactiveContactAttempts: DashboardConversationInfo;
  hiringConversation: DashboardConversationInfo;
  invoicesInfo: InvoicesInfo;
  notificationsSettings: MhNotificationSettings;
  profileCompleteness: MissionPartnerProfileCompleteness;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: "Dashboard" }));
    this.jobOffers = this.activatedRoute.snapshot.data["jobOffers"];
    this.talentLastReplies = this.activatedRoute.snapshot.data["talentLastReplies"];
    this.proactiveContactAttempts = this.activatedRoute.snapshot.data["proactiveContactAttempts"];
    this.hiringConversation = this.activatedRoute.snapshot.data["hiringConversation"];
    this.invoicesInfo = this.activatedRoute.snapshot.data["invoicesInfo"];
    this.notificationsSettings = this.activatedRoute.snapshot.data["notificationsSettings"];
    this.profileCompleteness = this.activatedRoute.snapshot.data["profileCompleteness"];
  }

  stopPropagation(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
}
