import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentDashboardStatusBoxComponent } from "./talent-dashboard-status-box.component";
import { NzCardModule } from "ng-zorro-antd/card";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [TalentDashboardStatusBoxComponent],
  imports: [
    CommonModule,
    NzCardModule,
    TranslateModule,
  ],
  exports: [
    TalentDashboardStatusBoxComponent
  ]
})
export class TalentDashboardStatusBoxModule {}
