import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExternalAssessmentQuestionerComponent } from "./external-assessment-questioner.component";
import { AssessmentNavigatorModule } from "../assessment-navigator/assessment-navigator.module";
import { LocaleSelectModule } from "@momhunting/common-ui";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { TranslateModule } from "@ngx-translate/core";
import { AssessmentSetupFormComponent } from "./assessment-setup-form/assessment-setup-form.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { ReactiveFormsModule } from "@angular/forms";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { ExplanationLabelModule } from "../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzResultModule} from "ng-zorro-antd/result";

@NgModule({
  declarations: [ExternalAssessmentQuestionerComponent, AssessmentSetupFormComponent],
    imports: [
        CommonModule,
        AssessmentNavigatorModule,
        LocaleSelectModule,
        NzLayoutModule,
        TranslateModule,
        NzFormModule,
        NzInputModule,
        ReactiveFormsModule,
        NzSpaceModule,
        ExplanationLabelModule,
        NzPageHeaderModule,
        NzDatePickerModule,
        NzButtonModule,
        NzResultModule,
    ],
})
export class ExternalAssessmentQuestionertModule {}
