<div class="media-upload">
  <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'">
    <mh-explanation-label [labelKey]="'profile.media.button.label'"></mh-explanation-label>
  </nz-form-label>
  <div *ngIf="!blobUrl || isRecording" class="button-box">
    <button *ngIf="!isRecording" nz-button type="button" nzType="primary" class="start-button" (click)="startAudioRecording()">
      {{ 'profile.audio.start-recording' | translate }}
      <span nz-icon nzType="audio" nzTheme="outline"></span>
    </button>
    <button *ngIf="mediaType === MediaType.AUDIO && isRecording" nz-button type="button" nzType="primary" class="stop-button" (click)="stopAudioRecording()">
      {{ 'profile.audio.stop-recording' | translate }}
      <span class="stop-button__icon"></span>
    </button>
    <button *ngIf="!isRecording" nz-button type="button" nzType="primary" class="start-button" (click)="startVideoRecording()">
      {{ 'profile.video.start-recording' | translate }}
      <span nz-icon nzType="video-camera" nzTheme="outline"></span>
    </button>
    <button *ngIf="mediaType === MediaType.VIDEO && isRecording" [nzLoading]="recordingLoading" nz-button type="button" nzType="primary" class="stop-button" (click)="stopVideoRecording()">
      {{ 'profile.video.stop-recording' | translate }}
      <span class="stop-button__icon"></span>
    </button>
    <div *ngIf="isRecording && !blobUrl">{{ recordedTime }}</div>
  </div>

  <div *ngIf="!blobUrl && !isRecording" class="media-upload__zone">
    <nz-form-label [nzSpan]="24" [nzLabelAlign]="'left'">
      <mh-explanation-label [labelKey]="'profile.media.drop-zone.label'"></mh-explanation-label>
    </nz-form-label>
    <nz-spin [nzSpinning]="isUploading">
      <nz-upload
        class="upload-file"
        [nzType]="mobileView?'select':'drag'"
        nzAccept="audio/*,video/*"
        nzListType="text"
        [nzBeforeUpload]="handleMediaUpload"
      >
        <ng-container *ngIf="!mobileView">
          <p class="ant-upload-drag-icon">
            <span nz-icon nzType="inbox"></span>
          </p>
          <p class="ant-upload-text">{{'upload-drop-area.label' | translate}}</p>
        </ng-container>
        <ng-container *ngIf="mobileView">
          <button nz-button nzType="primary">Upload</button>
        </ng-container>
      </nz-upload>
    </nz-spin>
  </div>

  <div *ngIf="mediaType === MediaType.AUDIO && blobUrl && !isRecording" class="audio-box">
    <audio controls class="audio">
      <source [src]="blobUrl" type="audio/wav">
    </audio>
    <button nz-button nzType="text" type="button" class="cancel-button" (click)="handleMediaRemove()">
      <span nz-icon nzType="delete" nzTheme="outline"></span>
    </button>
  </div>

  <div class="video-box">
    <video
      class="video"
      controls
      #videoElement
      [class.video--show]="mediaType === MediaType.VIDEO && (isRecording || blobUrl)">
      <source [src]="blobUrl" />
    </video>
    <button *ngIf="mediaType === MediaType.VIDEO && blobUrl" nz-button nzType="text" type="button" class="cancel-button" (click)="handleMediaRemove()">
      <span nz-icon nzType="delete" nzTheme="outline"></span>
    </button>
  </div>
</div>
