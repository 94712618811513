import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  AfterViewInit,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { JobOfferTalentComment } from "../../../../platform-pages/src/lib/api/job-offer/job-offer.types";

@Component({
  selector: "mh-job-offer-talent-comments",
  templateUrl: "./job-offer-talent-comments.component.html",
  styleUrls: ["./job-offer-talent-comments.component.less"],
})
export class JobOfferTalentCommentsComponent implements AfterViewInit, OnChanges {
  @Input() comments: JobOfferTalentComment[] = [];
  @Input() scrollable = false;
  @Output() sendComment = new EventEmitter<string>();
  @Output() deleteComment = new EventEmitter<string>();
  @Output() updateComment = new EventEmitter<{ id: string; text: string }>();
  formGroup: FormGroup;
  editingCommentId: string | null = null;

  @ViewChild("scrollContainer") private scrollContainer!: ElementRef<HTMLElement>;

  constructor(private modal: NzModalService, private translateService: TranslateService) {
    this.formGroup = new FormGroup({
      text: new FormControl("", [Validators.required]),
    });
  }

  ngAfterViewInit() {
    this.scrollListBottom();
  }

  ngOnChanges(changes: SimpleChanges) {
    const commentsChanges = changes["comments"];

    if (commentsChanges && commentsChanges.currentValue?.length > commentsChanges.previousValue?.length) {
      setTimeout(() => this.scrollListBottom(), 0);
    }
  }

  handleSubmit(): void {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    const text = this.formGroup.get("text")?.value;
    if (this.editingCommentId) {
      this.updateComment.emit({ id: this.editingCommentId, text });
      this.editingCommentId = null;
    } else {
      this.sendComment.emit(text);
    }
    this.formGroup.reset();
  }

  onDeleteComment(commentId: string): void {
    this.modal.confirm({
      nzTitle: this.translateService.instant("comment.confirm.delete.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.deleteComment.emit(commentId),
    });
  }

  startEditing(comment: JobOfferTalentComment): void {
    this.editingCommentId = comment.id;
    this.formGroup.patchValue({ text: comment.text });
  }

  cancelEditing(): void {
    this.editingCommentId = null;
    this.formGroup.reset();
  }

  scrollListBottom() {
    if (this.scrollContainer?.nativeElement && this.scrollable) {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    }
  }
}