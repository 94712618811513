import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MpJobOfferItemComponent } from "./mp-job-offer-item.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { TagsOutputModule } from "../../../../../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { TranslateModule } from "@ngx-translate/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { LocationsTableListModule } from "@momhunting/common-ui";
import { NzSpinModule } from "ng-zorro-antd/spin";
import {
  ViewJobOfferLocationModule
} from "@momhunting/common-ui";

@NgModule({
  declarations: [MpJobOfferItemComponent],
  imports: [
    CommonModule,
    NzGridModule,
    TagsOutputModule,
    TranslateModule,
    NzGridModule,
    NzIconModule,
    NzButtonModule,
    LocationsTableListModule,
    NzSpinModule,
    ViewJobOfferLocationModule
  ],
  exports: [MpJobOfferItemComponent],
})
export class MpJobOfferItemModule {}
