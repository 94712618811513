import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzIntlTelInputComponent } from "./nz-intl-tel-input.component";
import { FormsModule } from "@angular/forms";
import { CountrySearchPipe } from "./country-search.pipe";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzDividerModule } from "ng-zorro-antd/divider";

@NgModule({
  declarations: [NzIntlTelInputComponent],
  imports: [
    CommonModule,
    CountrySearchPipe,
    FormsModule,
    NzInputNumberModule,
    NzSelectModule,
    NzInputModule,
    NzIconModule,
    NzDividerModule,
  ],
  exports: [NzIntlTelInputComponent],
})
export class NzIntlTelInputModule {}
