import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerJobOffersComponent } from "./mission-partner-job-offers.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { MpJobOfferListModule } from "./mp-job-offer-list/mp-job-offer-list.module";
import { MissionPartnerJobOffersRoutingModule } from "./mission-partner-job-offers-routing.module";
import { JobOfferDataModule } from "./job-offer-data/job-offer-data.module";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { MpJobOfferCreateModule } from "./mp-job-offer-create/mp-job-offer-create.module";
import { MpJobOfferEditModule } from "./mp-job-offer-edit/mp-job-offer-edit.module";
import { MpJobOfferMatchesModule } from "./mp-job-offer-matches/mp-job-offer-matches.module";
import { TranslateModule } from "@ngx-translate/core";
import { NzIconModule } from "ng-zorro-antd/icon";

@NgModule({
  declarations: [MissionPartnerJobOffersComponent],
  imports: [
    CommonModule,
    MissionPartnerJobOffersRoutingModule,
    JobOfferDataModule,
    MpJobOfferCreateModule,
    MpJobOfferEditModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzTabsModule,
    MpJobOfferListModule,
    NzSpinModule,
    MpJobOfferMatchesModule,
    TranslateModule,
    NzIconModule,
  ],
})
export class MissionPartnerJobOffersModule {}
