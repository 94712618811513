import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileAboutComponent } from "./profile-about.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { AvatarUploadModule } from "../../../../../../common-componnets/avatar-upload/avatar-upload.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzInputModule } from "ng-zorro-antd/input";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ProfileDocumentsUploadModule } from "../../../../../../common-componnets/profile-documents-upload/profile-documents-upload.module";
import { ExplanationLabelModule } from "../../../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { LocationInputModule } from "../../../../../../../../../common-ui/src/lib/location-input/location-input.module";
import { ProfileMediaUploadModule } from "../../../../../../common-componnets/profile-media-upload/profile-media-upload.module";
import { NzIconModule } from "ng-zorro-antd/icon";

@NgModule({
  declarations: [ProfileAboutComponent],
  imports: [
    CommonModule,
    NzPageHeaderModule,
    ReactiveFormsModule,
    NzAvatarModule,
    AvatarUploadModule,
    FormsModule,
    NzFormModule,
    NzGridModule,
    NzDividerModule,
    NzInputModule,
    TranslateModule,
    NzButtonModule,
    NzWaveModule,
    ProfileDocumentsUploadModule,
    ExplanationLabelModule,
    NzDatePickerModule,
    NzSelectModule,
    NzRadioModule,
    LocationInputModule,
    ProfileMediaUploadModule,
    NzIconModule,
  ],
  exports: [ProfileAboutComponent],
})
export class ProfileAboutModule {}
