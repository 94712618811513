import {Inject, Injectable} from "@angular/core";
import {APP_CONFIGURATION} from "../../../../../core/src/lib/core.di";
import {AppConfig} from "../../../../../core/src/lib/app-config/config.types";

export const KEY_JOURNEY_NAME = "journeyName";
export const KEY_JOURNEY_ENABLED = "journeyEnabled";

@Injectable({
  providedIn: "root",
})
export class DebugService {

  constructor(@Inject(APP_CONFIGURATION) readonly appConfig: AppConfig) {}

  isProduction(): boolean {
    return this.appConfig.production;
  }

  isJourneyEnabled(): boolean {
    return !!localStorage.getItem(KEY_JOURNEY_ENABLED);
  }
  getJourneyName(): string | null {
    return localStorage.getItem(KEY_JOURNEY_NAME);
  }
  enableNamedJourney(journeyName: string) {
    localStorage.setItem(KEY_JOURNEY_NAME, journeyName);
    localStorage.setItem(KEY_JOURNEY_ENABLED, 'true');
  }
  disableNamedJourney(): void {
    localStorage.setItem(KEY_JOURNEY_ENABLED, '');
  }
}
