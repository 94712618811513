export enum MaturityAssessmentStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  COMPLETED_WITH_RESULT = "COMPLETED_WITH_RESULT",
}

export interface MaturityAssessment {
  id: string;
  missionPartnerId: string;
  missionPartnerName: string;
  status: MaturityAssessmentStatus;
  createdAt: Date;
  resultFile?: string;
  link: string;
  maturityAssessmentParticipants: Array<MaturityAssessmentParticipant>;
  numberOfAssessmentsStarted: number;
  numberOfAssessmentsCompleted: number;
  linkCopied?: boolean;
  hashtags: {
    hashtag1: string;
    hashtag2: string;
    hashtag3: string;
  }
}

export interface MaturityAssessmentParticipant {
  formId: string;
  personNum: string;
  startedTimestamp: number;
  endedTimestamp: number;
}

export interface MaturityAssessmentForm {
  id: string;
  email: string;
  name: string;
  maturityAssessmentId: string;
  completed: boolean;
  startedTimestamp: Date;
  endedTimestamp: Date | null;
  form: { sections: Array<MASection> };
  token: string;
  step: number;
}

export interface MASection {
  type: MASectionType;
  imageUrl: string;
  subsections: Array<MASubSection>;
}

export interface MASubSection {
  type: MASubSectionType;
  questions: Array<MAQuestion>;
}

export interface MAQuestion {
  title: string;
  type: MAQuestionType;
  answerOptions: Array<MAAnswerOption>;
  section: MASubSectionType;
  imageUrl: string;
  number?: string;
}

export interface MAAnswerOption {
  id: string;
  value: string;
  selected: boolean;
  type: MAAnswerOptionType;
  linkedQuestions: Array<MAQuestion>;
  linkedAnswerOption: MAAnswerOption;
}

export interface MAAnswerSaveRequest {
  token: string;
  step: number;
  answers: {
    [key: string]: string;
  };
}

export interface FactsAndFigures {
  staffTurnoverPercentage: number;
  averageAge: number;
  averageLengthOfService: number;
  partTimeEmployeesPercentage: number;
  partTimeEmployeesInManagementPercentage: number;
  womenProportion: number;
  womenInManagementPercentage: number;
}

export enum MASectionType {
  CANDIDATE_JOURNEY = "CANDIDATE_JOURNEY",
  EMPLOYEE_JOURNEY = "EMPLOYEE_JOURNEY",
  COMPATIBILITY = "COMPATIBILITY",
}
export enum MASubSectionType {
  ATTRACTION = "ATTRACTION",
  RECRUITING = "RECRUITING",
  ONBOARDING = "ONBOARDING",
  DEVELOPMENT = "DEVELOPMENT",
  RETENTION = "RETENTION",
  OFFBOARDING_AND_ALUMNI = "OFFBOARDING_AND_ALUMNI",
  STAGES_OF_LIFE = "STAGES_OF_LIFE",
  MENTAL_HEALTH = "MENTAL_HEALTH",
  EMPLOYER_BRANDING = "EMPLOYER_BRANDING",
  CULTURE_AND_VALUES = "CULTURE_AND_VALUES",
  NEW_WORK = "NEW_WORK",
  NEW_LEADERSHIP = "NEW_LEADERSHIP",
  DIVERSITY_EQUITY_AND_INCLUSION = "DIVERSITY_EQUITY_AND_INCLUSION",
}

export enum MAQuestionType {
  RADIO = "RADIO",
  CHECKBOX = "CHECKBOX",
  FREE_TEXT = "FREE_TEXT",
  NUMBER = "NUMBER",
  DROPDOWN = "DROPDOWN",
}

export enum MAAnswerOptionType {
  SELECTABLE = "SELECTABLE",
  FREE_TEXT = "FREE_TEXT",
}

export enum MAAssessmentFormError {
  MATURITY_ASSESSMENT_FORM_NOT_FOUND = "MATURITY_ASSESSMENT_FORM_NOT_FOUND",
  MATURITY_ASSESSMENT_COMPLETED = "MATURITY_ASSESSMENT_COMPLETED",
  MATURITY_ASSESSMENT_NOT_FOUND = "MATURITY_ASSESSMENT_NOT_FOUND",
  MATURITY_ASSESSMENT_INVALID_TOKEN = "MATURITY_ASSESSMENT_INVALID_TOKEN",
}
