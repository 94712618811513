import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupEmailConfirmComponent } from "./signup-email-confirm.component";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { NzIconModule } from "ng-zorro-antd/icon";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [SignupEmailConfirmComponent],
  imports: [CommonModule, NzGridModule, NzButtonModule, NzFormModule, NzWaveModule, ReactiveFormsModule, NzIconModule, NzDividerModule, TranslateModule],
  exports: [SignupEmailConfirmComponent],
})
export class SignupEmailConfirmModule {}
