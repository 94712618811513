<span *ngFor="let holder of placeholders; index as i" [class.code-hidden]="isCodeHidden">
  <input
    #input
    (click)="onClick($event)"
    (paste)="onPaste($event, i)"
    (input)="onInput($event, i)"
    (keydown)="onKeydown($event, i)"
    [type]="inputType"
    [disabled]="disabled"
    [attr.inputmode]="inputMode"
    [attr.autocapitalize]="autocapitalize"
    autocomplete="one-time-code"
  />
</span>
<!-- should be migrated to nz-inputs
<nz-input-number-group nz-row [nzGutter]="20" nzSize="large">
      <div nz-col nzSpan="4">
        <input nz-input maxlength="1" style="width: 100%" nzSize="large" />
      </div>
      <div nz-col nzSpan="4">
        <input nz-input maxlength="1" style="width: 100%" nzSize="large" />
      </div>
      <div nz-col nzSpan="4">
        <input nz-input maxlength="1" style="width: 100%" nzSize="large" />
      </div>
      <div nz-col nzSpan="4">
        <input nz-input maxlength="1" style="width: 100%" nzSize="large" />
      </div>
      <div nz-col nzSpan="4">
        <input nz-input maxlength="1" style="width: 100%" nzSize="large" />
      </div>
      <div nz-col nzSpan="4">
        <input nz-input maxlength="1" style="width: 100%" nzSize="large" />
      </div>
    </nz-input-number-group>
-->
