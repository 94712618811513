import { createAction, props } from "@ngrx/store";
import {JobOffer, MatchedTalent} from "../../../../../../api/job-offer/job-offer.types";
import {AccountFilter} from "../../../../../home-admin/home-admin-data/account.types";
import {PaginatedContent} from "../../../../../../api/common.types";

export const JobOfferAction = {
  loadJobOfferList: createAction("[JobOffer] Load job offer list"),
  loadJobOfferListSuccess: createAction("[JobOffer][Success] Load job offer list", props<{ data: Array<JobOffer> }>()),
  loadJobOfferListSuccessError: createAction("[JobOffer][Error] Load job offer list", props<{ error: unknown }>()),

  loadJobOffer: createAction("[JobOffer] Load job offer", props<{ id: string }>()),
  loadJobOfferSuccess: createAction("[JobOffer][Success] Load job offer", props<{ data: JobOffer }>()),
  loadJobOfferError: createAction("[JobOffer][Error] Load job offer", props<{ error: unknown }>()),

  createJobOffer: createAction("[JobOffer] Create job offer", props<{ entity: JobOffer }>()),
  createJobOfferSuccess: createAction("[JobOffer][Success] Create job offer", props<{ data: JobOffer }>()),
  createJobOfferError: createAction("[JobOffer][Error] Create job offer", props<{ error: unknown }>()),

  updateJobOffer: createAction("[JobOffer] Update job offer", props<{ entity: JobOffer }>()),
  updateJobOfferSuccess: createAction("[JobOffer][Success] Update job offer", props<{ data: JobOffer }>()),
  updateJobOfferError: createAction("[JobOffer][Error] Update job offer", props<{ error: unknown }>()),

  archiveJobOffer: createAction("[JobOffer] Archive job offer", props<{ entity: JobOffer }>()),
  archiveJobOfferSuccess: createAction("[JobOffer][Success] Archive job offer", props<{ entity: JobOffer }>()),
  archiveJobOfferError: createAction("[JobOffer][Error] Archive offer", props<{ error: unknown }>()),

  unArchiveJobOffer: createAction("[JobOffer] Un archive job offer", props<{ entity: JobOffer }>()),
  unArchiveJobOfferSuccess: createAction("[JobOffer][Success] Un archive job offer", props<{ entity: JobOffer }>()),
  unArchiveJobOfferError: createAction("[JobOffer][Error] Un archive offer", props<{ error: unknown }>()),

  loadJobOfferMatchedTalentList: createAction("[JobOffer] Load job offer matched talent list", props<{ filter: AccountFilter }>()),
  loadJobOfferMatchedTalentListSuccess: createAction("[JobOffer][Success] Load job offer matched talent list", props<{ data: PaginatedContent<MatchedTalent> }>()),
  loadJobOfferMatchedTalentListError: createAction("[JobOffer][Error] Load job offer offer matched talent list", props<{ error: unknown }>()),

  updateJobOfferMatchedTalentSuccess: createAction("[JobOffer][Success] Update job offer matched talent", props<{ entity: MatchedTalent }>()),

  archiveJobOfferMatch: createAction("[JobOffer] Archive job offer match", props<{ jobOfferId: string, entity: MatchedTalent }>()),
  archiveJobOfferMatchSuccess: createAction("[JobOffer][Success] Archive job offer match", props<{ entity: MatchedTalent }>()),
  archiveJobOfferMatchError: createAction("[JobOffer][Error] Archive offer match", props<{ error: unknown }>()),

  unArchiveJobOfferMatch: createAction("[JobOffer] Un-archive job offer match", props<{ jobOfferId: string, entity: MatchedTalent }>()),
  unArchiveJobOfferMatchSuccess: createAction("[JobOffer][Success] Un-archive job offer match", props<{ entity: MatchedTalent }>()),
  unArchiveJobOfferMatchError: createAction("[JobOffer][Error] Un-archive offer match", props<{ error: unknown }>()),

  talentMatchSearchStarted: createAction("[JobOffer] Talent match search", props<{ jobOfferId: string }>()),
  talentMatchSearchSuccess: createAction("[JobOffer][Success] Talent match search", props<{ jobOfferId: string }>()),
};
