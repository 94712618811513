import { combineReducers, createReducer, on } from "@ngrx/store";
import * as MissionPartnerProfileActions from "./mission-partner-profile.action";
import { MissionPartner } from "../../sign-up/signup.types";

export const missionPartnerProfileFeatureName = "missionPartnerProfile";

export type StateStatus = {
  loading: boolean;
  loaded: boolean;
  error: unknown;
};

export interface MissionPartnerProfileEntityState {
  status: StateStatus;
  data: MissionPartner | undefined;
}

export interface MissionPartnerProfileState {
  entity: MissionPartnerProfileEntityState;
}

const initialEntityState: MissionPartnerProfileEntityState = {
  status: { loaded: false, loading: false, error: null },
  data: undefined,
};

export const missionPartnerProfileEntityReducer = createReducer(
  initialEntityState,
  on(MissionPartnerProfileActions.loadMissionPartnerProfile, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MissionPartnerProfileActions.loadMissionPartnerProfileSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data,
    };
  }),
  on(MissionPartnerProfileActions.loadMissionPartnerProfileError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
  on(MissionPartnerProfileActions.updateStoreMissionPartnerProfile, (state, action) => {
    return {
      ...state,
      data: action.data,
    };
  }),
  on(MissionPartnerProfileActions.updateMissionPartnerProfile, (state) => {
    return {
      ...state,
      status: { loading: true, loaded: false, error: null },
    };
  }),
  on(MissionPartnerProfileActions.updateMissionPartnerProfileSuccess, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: true, error: null },
      data: action.data
    };
  }),
  on(MissionPartnerProfileActions.updateMissionPartnerProfileError, (state, action) => {
    return {
      ...state,
      status: { loading: false, loaded: false, error: action.error },
    };
  }),
);

export const missionPartnerProfileReducer = combineReducers<MissionPartnerProfileState>({
  entity: missionPartnerProfileEntityReducer,
});
