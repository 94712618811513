import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { JobOfferMatchesListComponent } from "./job-offer-matches-list.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { TranslateModule } from "@ngx-translate/core";
import { NzTagModule } from "ng-zorro-antd/tag";
import { RouterModule } from "@angular/router";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { TagsOutputModule } from "../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
@NgModule({
  declarations: [JobOfferMatchesListComponent],
  imports: [
    CommonModule,
    NzFormModule,
    NzPaginationModule,
    TranslateModule,
    NzTagModule,
    TagsOutputModule,
    RouterModule,
    NzIconModule,
    NzButtonModule,
    NzToolTipModule,
    NzPopoverModule,
    NzSpinModule,
    NzAvatarModule,
    NzEmptyModule,
    NzSelectModule,
    FormsModule,
  ],
  exports: [JobOfferMatchesListComponent],
})
export class JobOfferMatchesListModule {}
