import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EducationsInputComponent } from "./educations-input.component";
import { ExplanationLabelModule } from "../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [EducationsInputComponent],
  imports: [
    CommonModule,
    ExplanationLabelModule,
    NzButtonModule,
    NzDatePickerModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    NzWaveModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    EducationsInputComponent
  ]
})
export class EducationsInputModule {}
