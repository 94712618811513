import { Component, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Talent } from "../../../../api/sign-up/signup.types";
import { APP_CONFIGURATION } from "../../../../../../../core/src/lib/core.di";
import { AppConfig } from "../../../../../../../core/src/lib/app-config/config.types";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Reference } from "../../../../api/references/references.types";

@Component({
  selector: "mh-talent-profile-preview",
  templateUrl: "./talent-profile-preview.component.html",
  styleUrls: ["./talent-profile-preview.component.less"],
})
export class TalentProfilePreviewComponent {
  talent: Talent;
  trustedView!: boolean;
  avatarUrl: string;
  cvList: NzUploadFile[];
  fileList: NzUploadFile[] = [];
  references: Array<Reference> = [];

  constructor(
    @Inject(APP_CONFIGURATION) readonly appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
  ) {
    const snapshotData = this.activatedRoute.snapshot.data;

    this.trustedView = snapshotData['trusted'];
    this.talent = snapshotData["profile"].data;
    this.fileList = snapshotData["profile"].files;
    this.references = snapshotData["profile"].references;
    this.cvList = this.talent.cv ? [this.talent.cv] : [];
    this.avatarUrl = this.talent.avatar?.avatarUrl ? `${this.appConfig.siteUrl}/${this.talent.avatar?.avatarUrl}` : '';
  }
}
