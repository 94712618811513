import { Component, EventEmitter, Input, Output } from "@angular/core";
import { JobOffer } from "../../../../../../api/job-offer/job-offer.types";
import { Router } from "@angular/router";

@Component({
  selector: "mh-job-offer-list-active",
  templateUrl: "./job-offer-list-active.component.html",
  styleUrls: ["./job-offer-list-active.component.less"],
})
export class JobOfferListActiveComponent {
  @Input() jobOfferList: Array<JobOffer> = [];
  @Input() hasReviewedStatus = false;
  @Output() archiveJobOffer = new EventEmitter<JobOffer>();

  constructor(private router: Router) {
  }

  onArchive(entity: JobOffer) {
    this.archiveJobOffer.emit(entity);
  }

  navigateToMatches(offerId: string) {
    this.router.navigate(["/", "mission-partner", "job-offers", "matches", offerId]);
  }
}
