<nz-select
  [nzSize]="nzSize"
  class="phone-code-select"
  nzShowSearch
  [(ngModel)]="selectedCountry"
  (ngModelChange)="onCountrySelect($event, focusable)"
  [nzDropdownMatchSelectWidth]="false"
  [nzCustomTemplate]="defaultTemplate"
  [disabled]="disabled"
>
  <nz-option
    nzCustomContent
    [nzLabel]="country.name"
    [nzValue]="country"
    *ngFor="let country of preferredCountriesInDropDown"
  >
    <span class="country-selector-flag flag" [ngClass]="country.flagClass"></span>
    {{ country.name }} {{ country.dialCode }}
  </nz-option>
  <nz-option nzCustomContent [nzLabel]="country.name" [nzValue]="country" *ngFor="let country of allCountries">
    <span class="country-selector-flag flag" [ngClass]="country.flagClass"></span>
    {{ country.name }} {{ country.dialCode }}
  </nz-option>
</nz-select>
<ng-template #defaultTemplate let-selected>+{{ selected.nzValue.dialCode }}</ng-template>

<input
  [nzSize]="nzSize"
  nz-input
  type="tel"
  autocomplete="tel"
  [ngClass]="$any(cssClass)"
  (blur)="onTouched()"
  (keypress)="onInputKeyPress($event)"
  [(ngModel)]="phoneNumber"
  (ngModelChange)="onPhoneNumberChange()"
  [disabled]="disabled"
  #focusable
/>
