import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TalentSettingsComponent } from "./talent-settings.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { TranslateModule } from "@ngx-translate/core";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TalentSettingsEmailModule } from "./talent-settings-email/talent-settings-email.module";
import { TalentSettingsPasswordModule } from "./talent-settings-password/talent-settings-password.module";
import { TalentSettingsPhoneModule } from "./talent-settings-phone/talent-settings-phone.module";
import { TalentSettingsNewsletterModule } from "./talent-settings-newsletter/talent-settings-newsletter.module";

@NgModule({
  declarations: [TalentSettingsComponent],
  imports: [
    CommonModule,
    NzPageHeaderModule,
    NzDividerModule,
    TranslateModule,
    NzGridModule,
    NzButtonModule,
    NzWaveModule,
    TalentSettingsEmailModule,
    TalentSettingsPasswordModule,
    TalentSettingsPhoneModule,
    TalentSettingsNewsletterModule,
  ],
})
export class TalentSettingsModule {}
