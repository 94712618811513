import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MissionPartnerInboxComponent } from "./mission-partner-inbox.component";
import { MessagesInboxModule } from "../../../../common-componnets/messages/messages-inbox/messages-inbox.module";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { MissionPartnerInboxRoutingModule } from "./mission-partner-inbox-routing.module";
import {
  MissionPartnerConversationModule
} from "./subpages/mission-partner-conversation/mission-partner-conversation.module";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";

@NgModule({
  declarations: [MissionPartnerInboxComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MissionPartnerInboxRoutingModule,
    MissionPartnerConversationModule,
    MessagesInboxModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzTabsModule,
    RouterLink,
  ],
})
export class MissionPartnerInboxModule {}
