import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Talent} from "../../../../../../api/sign-up/signup.types";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: "mh-profile-family-friendly",
  templateUrl: "./profile-family-friendly.component.html",
  styleUrls: ["./profile-family-friendly.component.less"],
})
export class ProfileFamilyFriendlyComponent implements OnInit, OnDestroy {
  @Input() talent!: Talent;
  @Output() formChange = new EventEmitter<Talent>();
  @Output() submitChange = new EventEmitter<void>();

  public formGroup!: FormGroup;
  private readonly destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      desiredJob: this.fb.group({
        familyFriendlinessExpectations: [this.talent.desiredJob?.familyFriendlinessExpectations, []],
      })
    });
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) =>
      this.formChange.emit({
        ...this.talent,
        desiredJob: {
          ...this.talent.desiredJob,
          ...value.desiredJob,
        },
      }),
    );
  }

  isStepValid(): boolean {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    return this.formGroup.valid;
  }

  submitForm() {
    if(!this.isStepValid()) return;
    //this.loading = true;
    this.submitChange.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
