import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminAssessmentViewComponent } from "./admin-assessment-view.component";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { TranslateModule } from "@ngx-translate/core";
import { ClipboardModule } from "@angular/cdk/clipboard";

@NgModule({
  declarations: [AdminAssessmentViewComponent],
  imports: [
    CommonModule,
    NzAlertModule,
    NzButtonModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzTableModule,
    NzWaveModule,
    TranslateModule,
    ClipboardModule,
  ],
})
export class AdminAssessmentViewModule {}
